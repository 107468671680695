lp.pom.updater = {
  minVersion:'0.0',
  checkVersion: function(jso) {
    var current = jso.version || '0';
    
    if (current >= this.version) {
      return;
    }

    if (current < this.minVersion) {
      throw "Page version "+current+" can't be updated to version "+this.version;
    }
    return true;
  }
};
