lp.module.video.VideoElementBuilder = Class.create(
  lp.editor.BuilderModal,
  lp.ModuleComponent,
  {
    type:'lp-pom-video',

    initialize: function($super){
      this.controls = {};
      $super({
        title:'Embed a Video',
        actions: [{
          id: 'done',
          label: 'Done',
          action: this.embed.bind(this)
        },
        {
          id: 'cancel',
          label: 'Cancel',
          action: this.cancel.bind(this)
        }],
        showMaximize: true,
        layoutRules: {
          method: lp.editor.BuilderModal.fitToCanvas,
          options: {
            padding: 12,
            size:{width:1000, height:600}
          }
        }
      });
    },

    installUI: function( $super ) {
      $super();
      this.warn = this.actionBar.insert( new Element('div', {className:'warn'})
        .update('You must enter valid code'));

      var self = this;
      var clearWarning = function() { self.warn.hide(); };

      this.controls.codeEditor = this.contentPanel.insert( new jui.CodeEditor( {
       label: 'Paste your video code below:',
       toggleCheckboxYPosition: '5px',
       onKeyup: clearWarning,
       onMouseup: clearWarning,
       onChange: function() { self.warn.hide(); }
      } ) );

      this.warn.hide();
    },

    requestFocus: function(){
      window.editor.keyController.requestFocus(this);
    },


    onkeydown: function() {},

    onkeyup: function(){
      this.warn.hide();
    },

    blur: function(){
    },

    cancel: function() {
      this.close();
    },

    embed: function() {
      //Called when the embed button is clicked on the input window.
      if (this.options.callback) {
        if( this.validEmbedCode( this.controls.codeEditor.getValue() ) ) {
          this.options.callback({
            html: this.controls.codeEditor.getValue()
          });
          this.close();
          if (typeof eventTracker !== 'undefined') {
            eventTracker.track("Add a Video", "Editor");
          }
        } else {
          this.warn.show();
        }
      }
    },

    open: function($super, elm, options) {
      $super();
      //Called when the video element input window opens.
      if (options && options.callback) {
        this.options.callback = options.callback;
      }

      var content = elm !== null ? elm.model.get('content.html') : '';
      this.controls.codeEditor.setValue(content);
      this.controls.codeEditor.focus();
      this.requestFocus();
      if(!this.validEmbedCode(content) && content.length > 0) {
        this.warn.show();
      }
    },

    close: function($super) {
      delete this.options.callback;
      this.warn.hide();
      $super();
    },

    validEmbedCode: function( embedCode ) {
      if( embedCode.strip().length === 0 ) {
        this.warn.update( 'Please enter your video embed code' );
        return false;
      } else if ( !this.isEmbedable( embedCode )  ) {
        this.warn.
         update('Sorry, the embed code you have entered appears to be invalid');
        return false;
      } else {
        return true;
      }
    },

    isEmbedable: function(embedCode) {
      var temp = new Element('div').update(embedCode);
      var iframe = temp.select('iframe').first();
      var object = temp.select('object').first();
      if( iframe ) {
        return jui.utils.isProperlyFormatedIframe( iframe );
      } else if( object ) {
        return this.isProperlyFormatedObject( object );
      } else {
        return false;
      }
    },

    isProperlyFormatedObject: function( object ) {
      if( object.width.length === 0 || object.height.length === 0 ) {
        return false;
      } else {
        var embedWidth = object.select('embed[width="'+object.width +'"]');
        var embedHeight = object.select('embed[height="'+object.height+'"]');
        var hasSrc = object.childElements().any( function( el ) {
          return typeof el.src !== 'undefined';
        } );
        return embedWidth.length > 0 && embedHeight.length > 0 && hasSrc;
      }
    }
  }
);
