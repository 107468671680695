// require <jui>
jui.ColorPickerDialog = Class.create( jui.ModalDialog, {
  initialize: function($super, options){
    /* jshint unused:vars */
    this.hsv = [0,0,0];
    this.tracking = false;
    this.trackingHue = false;
    this.offset = {left:0, top:0};

    this.mouseMoveListener =    this.mousemove.bindAsEventListener( this );
    this.mouseUpListener =      this.mouseup.bindAsEventListener( this );

    $super({
     title:false,
      actions: [{
        id: 'choose',
        label: 'Choose',
        action: this.pick.bind(this)
      },
      {
        id: 'cancel',
        label: 'Cancel',
        action: this.cancel.bind(this)
      }],
      layoutRules: {
       method: jui.ModalDialog.centerInViewPort,
       options: {
         size:{width:400, height:316}
       }
      }
    });
  },

  installUI: function( $super ) {
    $super();

    var browserOptions = {width:640, height:280};
    if (this.options.nullOption) {
      browserOptions.nullOption = this.options.nullOption;
    }

    this.hue = this.contentPanel.insert( new Element('div', {className:'color_picker_hue'}));
    this.satVal = this.contentPanel.insert( new Element('div', {className:'color_picker_sat_val'}));
    this.sat = this.contentPanel.insert( new Element('div', {className:'color_picker_sat'}));
    this.val = this.contentPanel.insert( new Element('div', {className:'color_picker_val'}));

    this.crosshairs = this.insert(new Element('div', {className:'color-picker-cross-hairs'}));
    this.hueIndicator = this.insert(new Element('div', {className:'color-picker-hue-indicator'}));

    this.after = this.contentPanel.insert( new Element('div', {className:'color_picker_after'}));
    this.before = this.contentPanel.insert( new Element('div', {className:'color_picker_before'}));

    this.crosshairs.observe('mousedown', this.valClicked.bind(this));
    this.val.observe('mousedown', this.valClicked.bind(this));
    this.hue.observe('mousedown', this.hueClicked.bind(this));
    this.hueIndicator.observe('mousedown', this.hueClicked.bind(this));
  },

  valClicked: function(e) {
    this.offset = this.val.cumulativeOffset();

    this.hsv[1] = (e.pointerX() - this.offset.left - 1) / 256.0;
    this.hsv[2] = 1 - ((e.pointerY() - this.offset.top - 1) / 256.0);

    this.updateColor();
    this.setTracking('val');
  },

  hueClicked: function(e) {
    this.offset = this.hue.cumulativeOffset();

    this.hsv[0] = 1 - ((e.pointerY() - this.offset.top- 1) / 256.0);
    this.updateColor();
    this.setTracking('hue');
  },

  mouseup:function( e ){
    Event.stop( e );
    this.setTracking( false );
  },

  mousemove:function( e ){
    Event.stop( e );
    switch (this.tracking) {
      case 'val':
        this.hsv[1] = Math.max(0,Math.min((e.pointerX() - this.offset.left - 1) / 256.0, 1));
        this.hsv[2] = Math.max(0,Math.min(1 - ((e.pointerY() - this.offset.top - 1) / 256.0), 1));
        break;
      case 'hue':
        this.hsv[0] = Math.max(0,Math.min(1 - ((e.pointerY() - this.offset.top - 1) / 256.0), 1));
        break;
    }
    this.updateColor();
  },

  setTracking: function( b ) {
    if ( this.tracking === b ) {
      return;
    }

    this.tracking = b;
    if ( b !== false ) {
      $( window.document ).observe( 'mouseup', this.mouseUpListener );
      $( window.document ).observe( 'mousemove', this.mouseMoveListener );
    } else {
      $( window.document ).stopObserving( 'mouseup', this.mouseUpListener );
      $( window.document ).stopObserving( 'mousemove', this.mouseMoveListener );
    }
  },

  updateColor: function() {
    this.rgb = this.hsvToRgb(this.hsv);
    this.hex = this.rgb.invoke('toColorPart').join('');
    var hueHex = this.hsvToRgb([this.hsv[0], 1, 1]).invoke('toColorPart').join('');

    this.satVal.style.backgroundColor = '#'+hueHex;
    this.after.style.backgroundColor = '#'+this.hex;

    this.crosshairs.style.left = ((this.hsv[1]) * 256 + 3) + 'px';
    this.crosshairs.style.top = ((1 - this.hsv[2]) * 256 + 6) + 'px';

    this.hueIndicator.style.top = ((1 - this.hsv[0]) * 256 + 8) + 'px';
  },

  cancel: function() {
    this.close();
  },

  pick: function() {
    this.close();
    this.options.callback(this.hex);
  },

  open: function($super,hex, options) {
    $super();
    if (options.callback) {
      this.options.callback = options.callback;
    }

    this.hex = hex.strip().length > 0 ? hex : 'ffffff';
    this.rgb = this.hexToRgb(this.hex);
    this.hsv = this.rgbToHsv(this.rgb);

    this.before.style.backgroundColor = '#'+this.hex;
    this.updateColor();
  },

  hexToRgb: function(hex) {
    var r, g, b;

    if (hex.length === 3) {
      r = parseInt((hex).substring(0,1),16);
      g = parseInt((hex).substring(1,2),16);
      b = parseInt((hex).substring(2,3),16);
      r += r * 16;
      g += g * 16;
      b += b * 16;
    } else {
      r = parseInt((hex).substring(0,2),16);
      g = parseInt((hex).substring(2,4),16);
      b = parseInt((hex).substring(4,6),16);
    }

    return [r,g,b];
  },

  hsvToRgb: function(hsv) {
    var h = Math.max(0, Math.min(1, hsv[0])),
        s = Math.max(0, Math.min(1, hsv[1])),
        v = Math.max(0, Math.min(1, hsv[2]));

    var r, g, b;

    var i = Math.floor(h * 6);
    var f = h * 6 - i;
    var p = v * (1 - s);
    var q = v * (1 - f * s);
    var t = v * (1 - (1 - f) * s);
    switch (i % 6) {
      case 0:
        r = v; g = t; b = p; break;
      case 1:
        r = q; g = v; b = p; break;
      case 2:
        r = p; g = v; b = t; break;
      case 3:
        r = p; g = q; b = v; break;
      case 4:
        r = t; g = p; b = v; break;
      case 5:
        r = v; g = p; b = q; break;
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  },

  rgbToHsv: function(rgb) {
    var r = rgb[0]/255, g = rgb[1]/255, b = rgb[2]/255;
    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, v = max;
    var d = max - min;
    var s = max === 0 ? 0 : d / max;

    if (max === min) {
      h = 0; // achromatic
    } else {
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }
      h /= 6;
    }

    return [h, s, v];
  }
});
