lp.pom.updates.add({
  minVersion:'1.0',
  version:'1.1',
  run: function(jso) {
    var current = jso.version || '0';
    if (current >= this.version) {
      return;
    }

    if (current < this.minVersion) {
      throw "Page version "+current+" can't be updated to version "+this.version;
    }

    jso.styles = this.removeRedundantBackgroundRepeatRules(this.flattenRules(jso.styles));

    jso.version = this.version;
  },

  flattenRules: function(rules) {
    var flattenedRules = [];
    rules.each(function(s){
      if (!Object.isUndefined(s.attributes)){
        var attrs = $H(s.attributes);
        attrs.each(function(attr){
          flattenedRules.push({selector:s.selector, attribute:attr.key, value:attr.value});
        });
      } else {
        flattenedRules.push(s);
      }
    });

    return flattenedRules;
  },

  removeRedundantBackgroundRepeatRules: function(rules) {
    var hasBkgImg = rules.any(function(s){
      return (s.selector === "#lp-pom-root" && s.attribute === "background-image");
    });

    var bkgRepeat = null;

    if (hasBkgImg) {
      bkgRepeat = rules.find(function(s) {
        return (s.selector === "#lp-pom-root" && s.attribute === "background-repeat" && s.value !== null);
      }) || {selector:"#lp-pom-root", attribute:"background-repeat", value:"no-repeat"};
    }

    rules = rules.findAll(function(s) {
      return !(s.selector === "#lp-pom-root" && s.attribute === "background-repeat");
    });

    if (bkgRepeat !== null) {
      rules.push(bkgRepeat);
    }

    return rules;
  }
});
