lp.editor.ElementBoundaryControl = Class.create(jui.Component, jui.ControlModel, {
  options: function($super,options){
    return $super($H({attributes:{className:'element-boundary'}}).merge(options));
  },

  initialize: function($super, params){
    $super();
    this.elm = params.element;
    this.manager = params.manager;
    this.onDrag = params.onDrag;
    this.onInitDrag = params.onInitDrag;
    this.onBeforeDrag = params.onBeforeDrag;
    this.actsOn = params.actsOn;
    this.tracking = false;
    this.mouseMoveListener = this.mousemove.bindAsEventListener(this);
    this.mouseUpListener = this.mouseup.bindAsEventListener(this);

    this.e.addClassName(params.actsOn);
    this.e.addClassName(params.axis || 'x');
  },

  installUI: function($super, options) {
    $super(options);
    this.line = this.insert(new Element('div', {className:'element-boundary-line'}));
    this.arrow = this.insert(new Element('div', {className:'element-boundary-arrow'}));


    var mousedown = this.mousedown.bind(this);
    this.arrow.observe('mousedown', mousedown);
    this.e.observe('mousedown', mousedown);
  },

  mousedown: function(e) {
    if (!this.isEnabled()) {
      return;
    }
    Event.stop( e );
    this.startPoint = new jui.Point(Event.pointerX(e), Event.pointerY(e));
    this.onInitDrag();
    this.setTracking( true );
  },

  mousemove: function(e) {
    if (!this.isEnabled()) {
      return;
    }
    Event.stop( e );
    this.onBeforeDrag();
    var offset = jui.Point.subtract({left:Event.pointerX(e), top:Event.pointerY(e)}, this.startPoint);
    this.onDrag(offset);
  },

  mouseup: function( e ){
    if (!this.isEnabled()) {
      return;
    }
    Event.stop( e );
    this.setTracking( false );
  },

  setLineVisibility: function(b) {
    this.line.style.display = b ? 'block' : 'none';
  },

  setTracking: function( b ) {
    if ( this.manager.tracking === b ) {
      return;
    }

    this.manager.tracking = b;
    if ( b ) {
      $( window.document ).observe( 'mouseup', this.mouseUpListener );
      $( window.document ).observe( 'mousemove', this.mouseMoveListener );
      this.fireEvent('startMove', this.elm);
    } else {
      this.manager.undoRegistered = false;
      $( window.document ).stopObserving( 'mouseup', this.mouseUpListener );
      $( window.document ).stopObserving( 'mousemove', this.mouseMoveListener );
      this.fireEvent('stopMove', this.elm);
    }
  }
});
