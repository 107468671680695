lp.ModuleElementMethods = {
  hasPageElement: true,

  getElementClass: function() {
    return this.namespace[this.getElementClassName()];
  },

  getElementClassName: function() {
    return (this.elementClassName ?
            this.elementClassName :
            this.name.replace(/ /g,'') + 'Element');
  },

  getElementDefaults: function() {
    return this.getElementClass().elementDefaults;
  },

  getElementDefaultsCopy: function() {
    return jui.clone(this.getElementClass().elementDefaults);
  },

  elementActivated: function(e) {
    this.activeElement = e.source;
  },

  elementDeactivated: function() {
    this.activeElement = null;
  },

  elementDestroyed: function(e) {
    var elm = e.source;
    this.activeElement = null;
    this.removeElementListeners(elm);
  },

  /* JS:
  This method is called when a new element is dropped onto the page
  */

  addNewElementToPage: function(page, options) {
    /* JS:
    If the module providers a builder dialog then the builder dialog is opened and createAndInsertElement is passed as a callback
    otherwise it is called directly
    */
    var element = null;

    if (this.hasElementBuilder) {
      this.openElementBuilder(null, {callback: this.createAndInsertElement.bind(this, page, options)});
    } else {
      element = this.createAndInsertElement(page, options);
    }

    return element;
  },

  /* JS:
  This creates a new element and inserts into the page. The module can provide beforeCreate and afterCreate methods if necessary
  */
  createAndInsertElement: function(page, options, data) {
    options = options || {};
    options.offset = options.offset || {left:0, top:0};

    var elementDefaults = this.getElementDefaultsCopy();

    if (typeof elementDefaults.geometry === 'object') {
      if (options.center) {
        options.offset.left = options.offset.left - (elementDefaults.geometry.size.width ? Math.round(elementDefaults.geometry.size.width / 2) : 0);
        options.offset.top = options.offset.top - (elementDefaults.geometry.size.height ? Math.round(elementDefaults.geometry.size.height / 2) : 0);
      }

      elementDefaults.geometry.offset = options.offset;
    }

    elementDefaults.name = page.generateDefaultElementName(this.type, this.name);

    if (options.container) {
      elementDefaults.containerId = options.container.id;
    }

    if (this.beforeCreate) {
      this.beforeCreate(page, elementDefaults, options, data);
    }

    var element = this.createElement(page, elementDefaults);

    if (options.dontActivateOnInsert) {
     element.activateOnInsert = false;
    }

    this.insertElement(element, {container:options.container, containerIndex:options.index, center:options.center});

    if (this.afterInsert) {
     this.afterInsert(element, options);
    }

    return element;
  },

  createElement: function(page, jso) {
    jso = jso || this.getElementDefaults();
    var elm = new (this.getElementClass())(page, jso);
    this.addElementListeners(elm);
    return elm;
  },

  addElementListeners: function(elm) {
    this.createElementListeners();
    elm.addListener('activated', this.elementActivatedListener);
    elm.addListener('deactivated', this.elementDeactivatedListener);
    elm.addListener('destroyed', this.elementDestroyedListener);
  },

  removeElementListeners: function(elm) {
    elm.removeListener('activated', this.elementActivatedListener);
    elm.removeListener('deactivated', this.elementDeactivatedListener);
    elm.removeListener('destroyed', this.elementDestroyedListener);
  },

  createElementListeners: function() {
    var self = this;
    this.elementActivatedListener = this.elementActivatedListener || function(e) {self.elementActivated(e);};
    this.elementDeactivatedListener = this.elementDeactivatedListener || function(e) {self.elementDeactivated(e);};
    this.elementDestroyedListener = this.elementDestroyedListener || function(e) {self.elementDestroyed(e);};
  },

  insertElement: function(element, options) {
    element.page.insertElement(element, options);
  },

  // Depracated methods:

  addElementToPage: function(page, options) {
    this.addNewElementToPage(page, options);
  },

  buildPageElement: function(page, jso) {
    return this.createElement(page, jso);
  }
};
