/* globals exports, module, define */
;(function(root, fn) {
  if (typeof exports === 'object') {
    var FieldCleaner = fn();
    exports.FieldCleaner = exports.FieldCleaner || {};
    exports.FieldCleaner = FieldCleaner;
    module.exports = exports;
  } else if (typeof 'define' === 'function' && define.amd) {
    define(fn);
  } else {
    root.lp.module.form.FormElement.FieldCleaner = root.lp.module.form.FormElement.FieldCleaner || {};
    root.lp.module.form.FormElement.FieldCleaner = fn();
  }
})(this, function() {
  var FieldCleaner = function(model, fieldAttributeTypes) {
    this.fieldAttributeTypes = fieldAttributeTypes;
    this.model = model;
  };
  FieldCleaner.prototype =  {

    cleanField: function(field) {
      this._cleanFields([field.id]);
    },

    cleanInvalidFields: function() {
      this._cleanFields(this._findInvalidFields());
    },
    // private:
    _findAccessorsUsedBy: function(ids) {
      var model               = this.model
        , fieldAttributeTypes = this.fieldAttributeTypes;

      return ids.map(function(id) {
        return fieldAttributeTypes.map(function(node) {
          return node + "." + id;
        });
      })
      .flatten()
      .select(function(accessor) {
        return model && model.exists(accessor);
      });
    },

    _cleanFields: function(fieldIds) {
      var model = this.model;
      this._findAccessorsUsedBy(fieldIds).each(function(accessor){
        model.deleteAttr(accessor);
      });
    },

    _getFields: function() {
      return this.model.exists('content.fields') ? this.model.get('content.fields') : [];
    },

    _findInvalidFields: function() {
      var self = this;

      var visibleFieldIds = this._getFields().map(function(field) {
        return field.id;
      });

      var allFieldIds = this.fieldAttributeTypes.collect(function(node) {
        return $H(self.model.modelData[node]).keys();
      }).flatten().uniq();

      return allFieldIds.reject(function(fieldId){
        return visibleFieldIds.include(fieldId);
      }).flatten().uniq();
    }
  };
  return FieldCleaner;
});
