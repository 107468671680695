var LP_PAGE_ELEMENT_TREE_SUBS = [];

lp.editor.PageElementTree = Class.create( jui.Component, {
  options: function($super,options){
    // for now the editor is a jui.Component that grabs the 'content' div as it's element
    return $super($H({attributes:{className:'page-element-tree'}}).merge(options));
  },

  initialize: function($super,editor,options) {
    this.editor = editor;
    editor.addListener('elementMouseover', this);
    editor.addListener('elementMouseout', this);
    editor.addListener('elementActivated', this);
    editor.addListener('elementDeactivated', this);

    var updateFunction = this.updateTree.bind(this);
    editor.addListener('elementInserted', updateFunction);
    editor.addListener('elementRemoved', updateFunction);
    editor.addListener('elementOrderChanged', updateFunction);
    editor.addListener('elementShown', updateFunction);
    editor.addListener('elementHidden', updateFunction);
    editor.addListener('elementContainerChanged', updateFunction);
    editor.addListener('elementZIndexChanged', updateFunction);

    this.modelListener = this.modelChanged.bind(this);
    this.ignoreNameEditBlur = false;

    // we need to clean up the subscriptions when in test mode as the subs would accumulate
    LP_PAGE_ELEMENT_TREE_SUBS.forEach(function (sub) {
      sub.dispose();
    });
    LP_PAGE_ELEMENT_TREE_SUBS = [];
    editor.addListener('pageLoaded', function() {
      var sub = window.rx_switchboard.subjects.jui_events
        .filter(function(ev) {
          return ev.type === 'pageUpdatedAndRefreshed';
        })
        .subscribe(function() {
          //TODO-TR: this should never happen but leave it in until we
          //have a standard exception reporting rx .subscribe wrapper fn
          try {
            updateFunction();
          } catch (e) {
            console.trace(e);
          }
        });
      LP_PAGE_ELEMENT_TREE_SUBS.push(sub);
    });
    $super(options);
  },

  installUI: function($super){
    $super();
    this.nameEditField = this.insert(
      new jui.Component({elementType:'input', attributes:{type:'text', className:'name-edit'}}));
    this.nameEditField.selectedElement = null; // guard against
    // LP-5542, in which clicking on another element while renaming
    // renames the click target rather than the element being renamed
    this.nameEditField.e.observe('blur', this.nameEditBlur.bind(this));
    this.nameEditField.hide();
  },

  treeNodeClicked: function(e,elm){
    Event.stop(e);
    e.stop();
    window.editor.flashBox.flash(elm);
    if($(e.target).hasClassName('visibility-state')) {
      this._handleVisibility(e, elm.model);
    } else {
      this.fireEvent('elementSelected', elm);
    }
  },

  _handleVisibility: function(e, elModel) {
    window.rx_switchboard.fireUIEvent({
      elementId: elModel.element.id,
      type: 'toggleVisibility',
      domTarget: 'contents-panel'
    });
  },

  _isCurrentModelFormButton: function(elModel) {
    return elModel.element.getType() === 'lp-pom-button' &&
      elModel.element.isFormButton();
  },

  treeNodeDblclicked: function(e,elm) {
    if (!elm.is('renamable')) {
      return;
    }

    var node = e.findElement('a');
    var offset = node.positionedOffset();

    offset.left += parseInt(node.getStyle('padding-left'), 10) + 20;
    this.nameEditField.setOffset(offset);
    this.nameEditField.setWidth(this.getWidth() - offset.left - 20);

    this.nameEditField.selectedElement = elm;
    this.nameEditField.e.setValue(elm.model.get('name'));
    this.nameEditField.show();
    this.nameEditField.e.activate();
    this.editor.keyController.requestFocus(this, true);
  },

  onkeydown: function(e) {
    switch (e.keyCode){
      case Event.KEY_RETURN:
        this.nameEditBlur();
        e.stop();
        break;
    }
  },

  blur: function() {
    this.nameEditField.hide();
  },

  nameEditBlur: function() {
    if (this.ignoreNameEditBlur) {
      return;
    }
    this.updateElementName();
    this.nameEditField.hide();
  },

  updateElementName: function() {
    var selectedElement = this.nameEditField.selectedElement;
    if (!selectedElement) {
      return;
    }
    var name = this.nameEditField.e.getValue();

    if (name.strip() === '') {
      this.nameEditField.e.setValue(selectedElement.getName());
      return;
    }
    selectedElement.setName(name, this.editor.activeElement.page.undoManager);
  },

  elementMouseover: function(e) {
    var elm = e.data.source;
    var li = this.getListItem(elm);
    if (li) {
      li.addClassName('over');
    }
  },

  elementMouseout: function(e) {
    var elm = e.data.source;
    var li = this.getListItem(elm);
    if (li) {
      li.removeClassName('over');
    }
  },

  elementActivated: function(e) {
    var elm = e.data;
    var li = this.getListItem(elm);
    if (li) {
      li.addClassName('active');
    }
  },

  elementDeactivated: function(e) {
    var elm = e.data;
    var li = this.getListItem(elm);
    if (li) {
      li.removeClassName('active');
    }
  },

  getListItem: function(elm) {
    //TODO-TR: investigate bug where this.e maybe null
    // https://unbounce.hipchat.com/search?q=TypeError%3A+Cannot+read+property+%27down%27+of+null&t=rid-13895&a=Search#05:59:35
    return this.e.down('li[id="menu-item-'+elm.id+'"]');
  },

  updateTree: function() {
    this.setRoot(this.editor.activeRoot);
  },

  modelChanged: function(e) {
    var accessor = e.data.accessor;

    if (accessor === 'name' || accessor === 'geometry.visible') {
      var a = $('menu-item-'+e.source.element.id).down('a');
      a.update(this.createElementNodeContent(e.source.element.model));
    }
  },

  createElementNodeContent: function(elModel) {
    return  this.getNameNodeContent(elModel) + this.getVisibilityIconNodeString(elModel);
  },

  _shorten: function(name) {
    var maxLength = 15;
    if(name.length > maxLength) {
      return name.substr(0, maxLength - 3) + '...';
    }

    return name;
  },
  getNameNodeContent: function(elModel) {
    var name = elModel.get('name');
    return '<span title="'+name+'"><div class="icon"></div>' + this._shorten(name) + '</span>';
  },

  getVisibilityIconNodeString: function(elModel) {
    var visible       = elModel.isVisible() ? 'visible' : 'lp-hidden';
    return '<span class="visibility-state ' + visible +'"></span>';
  },

  setPage: function(p) {
    this.setRoot(p.getRootElement());
  },

  getElementTypeAsClass: function(el) {
    if(this._isCurrentModelFormButton(el.model)) {
      return el.type + ' lp-pom-button-form';
    }

    return el.type;
  },

  listClosedItems: function() {
    return _.map(jQuery('li.arr.closed'), function(item) {
      return jQuery(item).attr('id');
    });
  },

  handleClosedListItems: function(closedItems, listItemID, li) {
    if(_.contains(closedItems, listItemID)) {
      jQuery(li).addClass('closed');
    }
  },

  setRoot: function(root){
    this.ignoreNameEditBlur = true;
    var closedItems = this.listClosedItems();

    this.clear();
    this.insert(this.nameEditField);
    this.ignoreNameEditBlur = false;

    var tree = this.insert(new Element('ul')),
        self = this;

    var makeElementListItem = function(elm){
      var a = new Element('a',{'href':'#'})
      .update(self.createElementNodeContent(elm.model))
      .observe('click',self.treeNodeClicked.bindAsEventListener(self, elm))
      .observe('dblclick',self.treeNodeDblclicked.bindAsEventListener(self, elm));

      var div = new Element('div', {className:'menu-item'})
        , listItemID = 'menu-item-' + elm.id;

      var li = new Element('li',{
        'class':self.getElementTypeAsClass(elm),
        id: listItemID
      }).insert(div.insert(a));

      self.handleClosedListItems(closedItems, listItemID, li);

      div.observe('click',function(e){
        e.stop();
        e.findElement('div').up('li').toggleClassName('closed');
      });

      a.addClassName(elm.type);

      elm.model.addListener('attributeChanged', self.modelListener);

      var ul = new Element('ul');
      elm.childElements.sortBy(function(e){
          return -e.model.getZIndex();
        }).each(function(c){
            if (c.is('displayable')) {
                ul.insert(makeElementListItem(c));
            }
        });

      if (elm.childElements.length > 0) {
        li.addClassName('arr');
        li.insert(ul);
      }
      if(!elm.model.isVisible()) {
        li.addClassName('lp-hidden');
      }
      return li;
    };

    if (root.childElements.length > 0) {
      root.childElements.each(function(c){
        if (c.is('displayable')) {
            tree.insert(makeElementListItem(c));
        }
      }, this);
    } else {
      this.insert(new Element('p').update('Drag a Page Section onto the page to start adding content to this page'));
    }

    // this.activePage = p;
  }
});
