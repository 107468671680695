jui.QuicksilverAssetSearch = Class.create(jui.Component,{
  initialize: function(field, list, options) {
    this.field = $(field);
    this.list  = $(list);
    this.options = options || {};

    if(this.field && this.list) {
      this.items = $A([]);
      this.cache = $A([]);

      this.setupCache();

      this.field.observe('keyup', this.filter.bindAsEventListener(this));
      this.filter();
    }

    if(this.options.afterDisplayResults){this.addListener('afterDisplayResults', this.options.afterDisplayResults);}
    if(this.options.afterKeyPress){this.addListener('afterKeyPress', this.options.afterKeyPress);}
  },

  setupCache: function() {
    this.list.each(function(asset){
      this.cache.push(asset.down('p').innerHTML.toLowerCase());
      this.items.push(asset);
      this.cache_length = this.cache.length;
    },this);
  },

  filter: function(e) {
    if(!this.field.present()) {this.items.invoke('show'); return;}
    this.displayResults(this.getScores($F(this.field).toLowerCase()));
    this.fireEvent('afterKeyPress', e);
  },

  //Display results hides all elements goes through each scored element
  //and shows anything that has a score greater than zero.
  displayResults: function(scores) {
    this.items.invoke('hide');
    scores.each(function(score){this.items[score[1]].show();},this);
    this.fireEvent('afterDisplayResults', this.items);
  },

  getFilteredItemByIndex: function(index) {
    return this.items[index];
  },

  getScores: function(term) {
    var scores = $A([]),
    i      = 0;
    for(i = 0; i < this.cache_length; i++) {
      var score = this.cache[i].score(term);
      if(score > 0) {scores.push([score, i]);}
    }

    return scores.sort(function(a,b){return b[0] - a[0];});
  }
});
