window.console = window.console || {
  log: function(){},
  error: function(){},
  info: function(){}
};


(function() {
  var modules = [];

  var lp = this.LP = this.lp = {
    pageData: null,
    // put any utility functions etc in here
    //editObj = {id: id, page_uuid: page_uuid, admin: admin, lastUpdatedAt: pageVariantUpdateAt}
    editPage: function(pageData) {
      lp.context = lp.pom.context.EDIT;
      this.pageData = pageData;
      this.loadEditor();
      this.loadAllWebFonts();
    },

    loadEditor: function() {
      window.editor = new lp.Editor({
        page_uuid: this.pageData.page_uuid,
        admin: this.pageData.admin,
        updatedAt: this.pageData.updatedAt,
        onComplete: this.editorLoaded.bind(this)
      });
    },

    editorLoaded: function() {
      $('loading').hide();
    },

    loadAllWebFonts: function() {
      var webFonts = this.getWebFonts();

      webFonts.addListener('allFontsLoaded', this.allWebFontsLoaded.bind(this));
      this.getWebFonts().loadAllFonts();
    },

    allWebFontsLoaded: function() {
      this.loadPage();
    },

    loadPage: function(editObj) {
      /* jshint unused:vars */
      window.editor.loadPage(this.pageData.id, lp.context);
    },

    insertVersionMetaTag: function(){
      var versionMetaTag = document.createElement('meta');
      versionMetaTag.name = "lp-version";
      versionMetaTag.content = window.builderVersion || 'unknown';
      var head = document.getElementsByTagName('head')[0];
      head.insertBefore(versionMetaTag, head.firstChild);
    },

    publishPage: function(pageData, hidePoweredBy, context, previewURL) {
      lp.context = lp.pom.context[context];
      lp.publishLog.start();
      lp.pom.updates.run(pageData.mainPage);
      var page = new lp.pom.Page(
        pageData.mainPage,
        lp.pom.context[context],
        previewURL
      );
      window.page = page;
      page.insertPublishedPage($(document.body));
      this.insertVersionMetaTag();
      this.getPageInsertions = function() {
        return page.getPageInsertions();
      };
      // document.body.appendChild( page.getRootElement().e );
      if (!hidePoweredBy) {
        page.getRootElement().getViewDOMElement().insert( new Element('div', {id:'powered-by-spacer'}));
        page.getRootElement().getViewDOMElement().insert( new Element('div', {id:'powered-by-unbounce', style:'display: block !important;position:absolute !important;visibility:visible !important;text-indent: 0 !important;bottom:0px !important;width:100% !important;height:28px !important;overflow:hidden !important;background:#0d0e0f !important;z-index:999999 !important;text-align:center !important;font-size:11px !important;color:#666 !important;font-weight:bold !important;'}).update('<a href="http://why.unbounce.com/for-building-landing-pages/?utm_source=published_page&utm_medium=powered_by_bar&utm_campaign=branded_footer" rel="nofollow" target="_blank" style="display: inline !important;position:static !important;visibility:visible !important;text-indent: 0 !important;z-index:999999 !important;font-size:11px !important;color:#fff !important;font-family:\'trebuchet ms\' !important;font-weight:bold !important;line-height:26px !important;color:#aaa !important;" title="Build, Publish and Test Landing Pages with Unbounce">Built using the Unbounce Landing Page Platform</a>'));
      }
      if (document.location.href.indexOf('sns-enabled') > 0) {
        // dark launched ^
        try {
          jQuery.ajax({
            type: "POST"
            , url: "//ingest.unbounce.com/builder/pub?topic=page_publish&subject=publish"
            // ^ this will currently fail due to the sns-bridge requiring a webapp cookie session.
            // The platform team is working on that aspect of it.
            , contentType: "application/json; charset=utf-8"
            , dataType: "json"
            , data: JSON.stringify({
              builderVersion: window.builderVersion,
              variantId: pageData.id
              // TODO add in the editor session UUID + save event autoincrement id
              // for cross-reference purposes. ... hasForm, etc.
            })
            , success: null // fire and forget for now
          });
        } catch (e) {
          console.log(e);
          //
        }
      }
    },

    publishLog: {
      startTime:0,
      start: function() {
        this.startTime = Date.now();
      },
      elapsedTime: function() {
        return Date.now() - this.startTime;
      },
      info: function(message){
        if (lp.context === lp.pom.context.PUBLISH) {
          alert('[INFO] '+this.elapsedTime()+' '+message);
        }
      }
    },

    createModule: function(module, options) {
      options = options || {};
      var elementClass = null,
          propertiesPanelClass = null,
          propertiesPanel = null,
          builderClass = null,
          builder = null;

      module.getElementDefaults = function() {
        return this.elementDefaults;
      };

      if (typeof options.createElementClass === 'function') {
        module.activeElement = null;

        var elementActivated = function(e) {
          module.activeElement = e.source;
        };

        var elementDeactivated = function() {
          module.activeElement = null;
        };

        var elementDestroyed = function(e) {
          var elm = e.source;
          module.activeElement = null;
          elm.removeListener('activated', elementActivated);
          elm.removeListener('deactivated', elementDeactivated);
          elm.removeListener('destroyed', elementDestroyed);
        };

        module.addElementToPage = options.addElementToPage || function(page, options) {
          this.createElement(page, options);
        };

        module.addNewElementToPage = options.addElementToPage || function(page, options) {
          return this.createElement(page, options);
        };

        module.createElement = module.createElement || function(page, options) {
          options = options || {};
          options.offset = options.offset || {left:0, top:0};

          if (options.center) {
            options.offset.left = options.offset.left - (module.elementDefaults.geometry.size.width ? Math.round(module.elementDefaults.geometry.size.width / 2) : 0);
            options.offset.top = options.offset.top - (module.elementDefaults.geometry.size.height ? Math.round(module.elementDefaults.geometry.size.height / 2) : 0);
          }

          var defaults = jui.clone(module.elementDefaults);

          defaults.name = page.generateDefaultElementName(this.type, defaults.name);

          if (options.container) {
            defaults.containerId = options.container.id;
          }

          var self = this;
          var createFunction = function(data) {
             if (module.beforeCreate) {
                module.beforeCreate(page, options, defaults, data);
              }
              var element = self.buildPageElement(page, defaults);

              if (options.offset) {
                element.model.setOffset(options.offset);
              }
              if (options.dontActivateOnInsert) {
                element.activateOnInsert = false;
              }
              self.insertElement(element, {container:options.container, containerIndex:options.index, center:options.center});
              if (module.afterInsert) {
                module.afterInsert(element, options);
              }

              if(typeof element.displayWarningIfInvalid === 'function') {
                element.displayWarningIfInvalid(!!data.valid);
              }
              return element;
          };

          if (module.openBuilder) {
            this.openBuilder(null, {callback:createFunction});
          } else {
            return createFunction();
          }

          return null;
        };

        module.insertElement = module.insertElement || function(element, options) {
          element.page.insertElement(element, options);
        };

        module.buildPageElement = module.buildPageElement || function(page, jso) {
          var elm = new (this.getElementClass())(page, jso);
          elm.addListener('activated', elementActivated);
          elm.addListener('deactivated', elementDeactivated);
          elm.addListener('destroyed', elementDestroyed);
          return elm;
        };

        module.getElementClass = module.getElementClass || function() {
          elementClass = elementClass || options.createElementClass(this);
          return elementClass;
        };
      }

      if (typeof options.createPropertiesPanelClass === 'function') {
        module.getPropertiesPanel = module.getPropertiesPanel || function(elm) {
          /* jshint unused:vars */
          propertiesPanel = propertiesPanel || new (this.getPropertiesPanelClass())();
          return propertiesPanel;
        };

        module.getPropertiesPanelClass = module.getPropertiesPanelClass || function() {
          propertiesPanelClass = propertiesPanelClass || options.createPropertiesPanelClass(this);
          return propertiesPanelClass;
        };
      }

      if (typeof options.createBuilderClass === 'function') {
        module.openBuilder = module.openBuilder || function(elm, options) {
          this.getBuilder().open(elm, options);
        };

        module.getBuilder = function(elm) {
          /* jshint unused:vars */
          builder = builder || new (this.getBuilderClass())();
          return builder;
        };

        module.getBuilderClass = function() {
          builderClass = builderClass || options.createBuilderClass(this);
          return builderClass;
        };
      }

      this.addModule(module);
    },

    createModuleNamespace: function(namespace) {
      var namespaceObj = this.module[namespace] = {};
      return namespaceObj;
    },

    addModule: function(module){
      if (lp.getModule(module.type)){
        // JS: module with same key already exist. we should throw some kind of exception
        return;
      }
      modules.push(module);
    },

    getModule: function(type){
      return modules.find(function(m) {return m.type === type;});
      //return modules.get(type);
    },

    getModulesWithUsageType: function(type) {
      return modules.findAll(function(m) {return m.usageType === type;});
    },

    getWebFonts: function() {
     this.webFonts = this.webFonts || new lp.WebFonts();
     return this.webFonts;
    },

    isDynamicTextEnabled: function() {
      if(typeof gon !== 'undefined'){return !!gon.dynamic_text;}
    },

    isResponsiveEnabled: function() {
      var hash = document.location.hash;
      if (hash.indexOf('nonresp') > -1) {
        return false;
      } else if (hash.indexOf('resp') > -1) {
        return true;
      } else {
        return typeof gon !== 'undefined' && !!gon.responsive_layouts;
      }
    },

    isScriptManagerEnabled: function() {
      return typeof gon !== 'undefined' && !!gon.script_manager;
    }
  };
})();

lp.module = {};
