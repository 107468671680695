/* globals exports, module, define */
;(function(root, fn) {
  if (typeof exports === 'object') {
    var TreeToggler = fn();
    exports.TreeToggler = exports.TreeToggler || {};
    exports.TreeToggler = TreeToggler;
    module.exports = exports;
  } else if (typeof 'define' === 'function' && define.amd) {
    define(fn);
  } else {
    root.lp.editor.TreeToggler = fn();
  }
})(this, function() {
  var TreeToggler = function() {};

  TreeToggler.prototype  = (function(){
    var openStateString  = 'open'
      ,closedStateString = 'closed'
      ,storageKey        = 'lp_contentTreeState';

    var getDefaultState = function() {
      return closedStateString;
    };

    var getPersistedState = function() {
      return (window.localStorage && window.localStorage.getItem(storageKey));
    };

    var getState = function() {
      return getPersistedState() || getDefaultState();
    };

    var saveVisibility = function(state) {
      if(window.localStorage) {
        window.localStorage.setItem(storageKey, state);
      }
    };

    return {
      isOpen: function() {
        return getState() === openStateString;
      },

      open: function() {
        saveVisibility(openStateString);
      },

      close: function() {
        saveVisibility(closedStateString);
      }
    };
  })();

  return TreeToggler;
});
