/* globals rx_switchboard */
lp.editor.KeyController = Class.create( jui.EventSource, {
  initialize: function(editor, options) {
    /* jshint unused:vars */
    this.editor = editor;
    this.currentFocus = null;
    this.previousFocus = {obj: null, sendKeyEvents: false};
    this.sendKeyEvents = false;
    document.observe('keydown', this.onkeydown.bindAsEventListener(this));
    document.observe('keyup', this.onkeyup.bindAsEventListener(this));
    this.editor.addListener('pageLoaded', this);

    this._traceKeyboard = rx_switchboard.getPublisher('keyboard_events');
  },

  pageLoaded: function(e) {
    e.data.addListener('beforePageSave', this);
  },

  beforePageSave: function() {
    var previous = this.currentFocus;
    this.requestFocus(null);
    this.requestFocus(previous);
  },

  requestFocus: function(obj, sendKeyEvents) {
    if (!obj || obj === this.currentFocus) {
      return; // already has focus
    }

    var currentBreakpoint        = this.editor.page.getCurrentBreakpoint();
    this.previousFocus           = {obj: this.currentFocus, sendKeyEvents: this.sendKeyEvents};
    this.currentFocus            = obj;
    this.currentFocus.breakpoint = currentBreakpoint;

    if (this.canBlurPreviousFocus()) {
      this.previousFocus.obj.blur();
    }

    this.sendKeyEvents = sendKeyEvents || false;

    this._traceKeyboard({
      type          : 'requestFocus',
      previousFocus : this.previousFocus,
      currentFocus  : this.currentFocus,
      sendKeyEvents : this.sendKeyEvents
    });

    return true;
  },

  canBlurPreviousFocus: function() {
    var currentBreakpoint = this.editor.page.getCurrentBreakpoint()
      , prevFocusObj      = this.previousFocus.obj;

    return prevFocusObj !== null &&
           prevFocusObj.breakpoint === currentBreakpoint &&
           prevFocusObj.blur;
  },

  removeFromFocus: function(obj){
    if (obj !== this.currentFocus) {
      return;
    }

    this.requestFocus(this.previousFocus.obj, this.previousFocus.sendKeyEvents);
  },

  onkeydown: function(e) {
    if (this.sendKeyEvents && this.currentFocus !== null && this.currentFocus.onkeydown) {
      this.currentFocus.onkeydown(e, this);
    }

    switch (e.keyCode){
      case 83: // s
        if (e.metaKey || e.ctrlKey) {
          this.editor.save();
          e.stop();
        }
        break;
      case 90: // z
        //SD: I use the ctrlKey here because on linux or windows we never
        //enter this statement since the metaKey is never triggered. If
        //this causes any problems on the Mac then we will need to revisit
        //how this is handled.
        if (e.metaKey || e.ctrlKey) {
          if (e.shiftKey) {
            this.editor.redo();
          } else {
            this.editor.undo();
          }
          e.stop();
        }
        break;
    }
  },

  onkeyup: function(e) {
    if (this.sendKeyEvents && this.currentFocus !== null && this.currentFocus.onkeyup) {
      this.currentFocus.onkeyup(e,this);
    }
  }
});
