//  require <prototype>
//  require <jui>
//  require "editor"

lp.editor.TransformBoxHandle = Class.create( jui.Component, {
  options: function($super,options){
    return $super($H({
      attributes:{className:'transform-handle'},
      snap:true}).merge(options));
  },

  initialize: function( $super, box, drag, options ){
    $super( options );
    this.box = box;
    this.dragFunction = drag;
    this.tracking = false;
    this.dragStartOffset = {left:0, top:0};
    this.mouseMoveListener = this.mousemove.bindAsEventListener( this );
    this.mouseUpListener = this.mouseup.bindAsEventListener( this );
  },

  installUI: function( $super, options ) {
    $super( options );
    this.e.observe( 'mousedown', this.mousedown.bindAsEventListener( this ) );
  },

  mousedown: function(e) {
    Event.stop( e );
    this.dragStartOffset = {left: Event.pointerX(e), top: Event.pointerY(e)};
    this.box.handleDragStart();
    this.setTracking(true);
  },

  mousemove: function(e) {
    Event.stop( e );
    this.dragFunction({left: Event.pointerX(e) - this.dragStartOffset.left, top: Event.pointerY(e) - this.dragStartOffset.top});
  },

  mouseup:function( e ){
    Event.stop( e );
    this.setTracking( false );
  },

  setTracking: function(b) {
    if (this.box.tracking === b) {
      return;
    }
    this.box.tracking = b;
    if (b) {
      $( window.document ).observe( 'mouseup', this.mouseUpListener );
      $( window.document ).observe( 'mousemove', this.mouseMoveListener );
      this.fireEvent('startTransform', this.elm);
    } else {
      this.box.undoRegistered = false;
      $( window.document ).stopObserving( 'mouseup', this.mouseUpListener );
      $( window.document ).stopObserving( 'mousemove', this.mouseMoveListener );
      this.fireEvent('stopTransform', this.elm);
    }
  }
});
