lp.module.stylesheet.StylesheetElement = Class.create(
  lp.pom.Element,
  lp.ModuleComponent,
  {
    type:'lp-stylesheet',
  
    initialize: function($super, page, jso, context, options) {
      $super(page, jso, context, options);
      if (this.page.context === lp.pom.context.PUBLISH || this.page.context === lp.pom.context.PREVIEW) {
        this.page.addInsertion(this.model.get('content.html'), 'head');
      }
    },

    createDefaultConstraints: function($super) {
      $super();
      this.defaultConstraints.displayable = false;
      this.defaultConstraints.copyable = false;
    }
  }
);

lp.module.stylesheet.StylesheetElement.elementDefaults = {
  name: 'Stylesheet',
  containerId: null,
  content: {
    type: null,
    valid: true,
    errors: []
  }
};