/* globals Rx, LZString */
// TODO: split this module up into a) core interfaces, b) the subjects
// registry, c) the various subscribers

;(function () {
  var switchboard = {subjects:{}};
  window.rx_switchboard = switchboard;
  //TODO: add a per editor session UUID (not a session cookie) + an
  //autoincrement int ID for each save. Use the combo to identify
  //saved versions of this variant's POM. This would be saved on the
  //POM itself and be used to identify the sns save events.
  // Persist the following columns along with the pom content:
  // variantid, timestamp_of_save, editor_session_id, id_of_save
  var subjects = switchboard.subjects;

  //////////////////////////////////////////////////
  // helper code to put into a utils module:
  Rx.Subject.prototype.safePublish = function (x) {
    // an exception catching wrapper around Rx.Subject.onNext
    try {
      this.onNext(x);
    } catch (err) {
      // TODO-SD in dev mode we want to see these.
      // pass
    }
  };

  switchboard.getOrCreateSubject = function (name) {
    var subj = switchboard.subjects[name];
    if (typeof subj === 'undefined') {
      subj = switchboard.subjects[name] = new Rx.Subject();
    }
    return subj;
  };

  switchboard.getPublisher = function (name) {
    // this is the main entry point from client code at the moment
    var subj = switchboard.getOrCreateSubject(name);
    return subj.safePublish.bind(subj);
  };

  var createRingBuffer = function (length) {
    var pointer = 0
        , buffer = [];
    return {
      get  : function get (key) {return buffer[key];},
      push : function push (item) {
        buffer[pointer] = item;
        pointer = (length + pointer +1) % length;
      },
      buffer : buffer};
  };

  //////////////////////////////////////////////////
  // the sockets of our debug switchboard.

  // Future iterations of this will define and lock down the message
  // format for each subject.
  subjects.jui_events = new Rx.Subject();
  subjects.pom_updates = new Rx.Subject();
  subjects.element_set = new Rx.Subject();
  subjects.element_delete_attr = new Rx.Subject();
  subjects.property_panels = new Rx.Subject();
  subjects.undo_manager_execute = new Rx.Subject();
  subjects.window_error = new Rx.Subject();
  subjects.page_saves = new Rx.Subject();
  subjects.editor_errors = new Rx.Subject();
  subjects.keyboard_events = new Rx.Subject();

  //New world order event-sourced UI
  //TODO-SD We'll rename magic later.
  subjects.magic = {};
  subjects.magic.ui_events = new Rx.Subject();
  subjects.magic.domain_events = new Rx.Subject();

  switchboard.fireUIEvent = function(event) {
    //TODO-SD we want to validate that the event is of the correct shape.
    subjects.magic.ui_events.onNext(event);
  };

  // provide a better way of doing this
  subjects.all = Rx.Observable.merge(
    subjects.editor_errors
    , subjects.jui_events
    , subjects.pom_updates
    , subjects.element_set
    , subjects.property_panels
    , subjects.undo_manager_execute
    , subjects.window_error
    , subjects.page_saves
    , subjects.keyboard_events
    , subjects.magic.ui_events
    , subjects.magic.domain_events
  );


  //////////////////////////////////////////////////
  // publish page saves to sns along with a trace of all uses of undo_manager
  // TODO extract this into a separate module

  var extractUndoDetails = function (undoEvent) {
    var actionFn = undoEvent.action.action;
    var trace = {
      actionFnString: actionFn.toString().substring(0, 70)
      //, action: undoEvent.action
    };
    if (actionFn.name) {
      trace.actionFnName = actionFn.name;
    }
    if (undoEvent.receiver.id) {
      trace.receiverId = undoEvent.receiver.id;
    }

    var params = undoEvent.params;
    if (jQuery.isArray(params) && params.length > 0 && params[0].id) {
      trace.firstParamId = params[0].id;
    }
    if (typeof undoEvent.receiver.element !== "undefined") {
      trace.receiverElementId = undoEvent.receiver.element.id;
    }
    return trace;
  };

  var addEditorEventContext = function (ev0) {
    var ev = ev0;
    ev.ts = new Date().toISOString();
    ev.breakpoint = window.editor.page.getCurrentBreakpoint().name;
    ev.page = window.editor.page.page.used_as;
    return ev;
  };

  // track editor errors
  var errorsBuffer = createRingBuffer(50);
  subjects.editor_errors
    .map(addEditorEventContext)
    .subscribe(errorsBuffer.push);

  // track undo/redo usage
  var undoManagerTraceBuffer = createRingBuffer(1000);
  subjects.undo_manager_execute
    .map(extractUndoDetails)
    .map(addEditorEventContext)
    .subscribe(undoManagerTraceBuffer.push);

  var publishSaveAndTraceData = function (saveEv) {
    try {
      var snsSubject = encodeURI("page_save:"+saveEv.id);
      var payload = {
        // for now this payload is application/xml with json embedded inside CDATA
        savePayload: saveEv.data
        , undoTraceEvents: undoManagerTraceBuffer.buffer
        , editorErrors: errorsBuffer.buffer
      };
      jQuery.ajax({
        type: "POST"
        , url: "//ingest.unbounce.com/builder/pub?topic=page_saves&subject="+snsSubject
        , xhrFields: {withCredentials: true}
        , contentType: "application/json; charset=utf-8"
        , dataType: "json"
        , data: JSON.stringify(addEditorEventContext({
          id: saveEv.id
          , url: saveEv.url
          // TODO-TR: editor session uuid, user-agent, etc.
          , builderVersion: window.builderVersion
          , data: LZString.compressToBase64(JSON.stringify(payload))
        }))
        , success: null // fire and forget for now
      });
    } catch (err) {
      if (console.trace) {
        console.trace(err);
      }
    }
  };
  subjects.page_saves.subscribe(publishSaveAndTraceData);

  //////////////////////////////////////////////////
  // console debug tracing that is off by default:

  var getEventId = function (ev) {
    if (ev.id) {
      return ev.id;
    } else if (typeof ev.source === 'object'){
      if (ev.source.id) {
        return ev.source.id;
      } else if (typeof ev.source.element === 'object') {
        return ev.source.element.id;
      }
    }
    return '?';
  };

  switchboard.debugTrace = false;
  switchboard.debugFilter = function () {return true;};
  switchboard.debugOff = function () {
    if (switchboard._debugSubscription) {
      switchboard._debugSubscription.dispose();
      switchboard._debugSubscription = null;
    }
  };
  switchboard.debugOn = function () {
    switchboard.debugOff();
    window.onerror = function (errorMsg, url, lineNumber) {
      subjects.window_error.onNext(
          'Error: ' + errorMsg + ' Script: ' + url + ' Line: ' + lineNumber);
    };
    switchboard._debugSubscription = subjects.all
        .filter(function (ev0) { return switchboard.debugFilter(ev0);})
        .subscribe(function (ev0) {
          var id = getEventId(ev0),
              ev = {type: ev0.type
                    , id: id
                    , ev: ev0};
          if (switchboard.debugTrace) {
            if (console.groupCollapsed ) {
              console.groupCollapsed(ev);
              console.log(ev0);
              console.count(ev.type);
              console.trace();
              console.groupEnd();
            } else {
              console.trace(ev);
            }
          } else {
              console.log(ev);
          }
        });
  };

  // activate with special #hash url
  var hash = document.location.hash;
  if (hash.indexOf("#debug") === 0) {
    if (hash.indexOf("trace") > -1) {
      switchboard.debugTrace = true;
    }
    switchboard.debugOn();
  }
})();
