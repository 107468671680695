lp.editor.AssetView = Class.create( jui.Component, {
  options: function($super,options){
    return $super($H(
      {
        attributes:{className:'asset-view'},
        contextSwitchViews: ['images']
      }).merge(options));
  },

  initialize: function($super, options){
    this.assets = [];
    this.searchTerm = '';
    this.renameFields = {};
    this.field = {};
    this.selectedThumbs = [];
    this.selectedAssets = [];
    this.selectionStart = null;
    this.notice = null;
    this.type = null;
    this.companyId = window.editor.currentCompanyId;
    this.openAssetsCompanyId = this.companyId;
    this.unbounceId = window.editor.unbounceCompanyId;
    this.unbounceAssetsCompanyId = window.editor.unbounceAssetsCompanyId;
    this.sort = {
      by: 'id',
      direction: 'DSC'
    };

    $super(options);
  },

  installUI: function($super) {
    $super();
    this.assetPane = this.insert( new jui.Component({attributes: {className: 'asset-pane'}}));
  },

  clear: function($super) {
    /* jshint unused:vars */
    // $super();
    this.assetPane.clear();
    if (this.toolbar) {
      this.toolbar.remove();
    }
    this.assets = [];
    this.clearSelection();
  },

  prepareForLoading: function() {
    this.clear();
    this.clearSearchField();
  },

  showToolbar: function() {
    this.insert(this.toolbar);
  },

  buildAssetFilterToolbar: function(type) {
    var toolbar = new jui.Component({attributes:{className: 'filter-orderby-toolbar'}});
    this.assetFilterSelect = this.assetFilter();
    this.search = this.search || this.assetSearch();
    this.sortFilter = this.sortFilter || this.buildSortFilter();

    if(this.options.contextSwitchViews.include(type)) {
      toolbar.insert(this.assetFilterSelect);
    }
    toolbar.insert(this.search);
    toolbar.insert(this.sortFilter);
    return toolbar;
  },


  assetSearch: function() {
    return new jui.FormTextInput({
      attributes: {className: 'search-field'},
      label: '',
      placeholder: 'Filter Results...',
      onblur: function(e) {
        e.data.e.removeClassName('focus');
      },
      onfocus: function(e) {
        window.editor.keyController.requestFocus(this);
        e.data.e.addClassName('focus');
      }
    });
  },

  assetFilter: function() {
    var self = this;
    return new jui.FormSelect({
      attributes: {className: 'asset-context-switcher'},
      width: '150px',
      label: '',
      id: 'jui-asset-chooser-select',
      selectOptions: [
        {name: 'My Images', value: self.companyId},
        {name: 'Unbounce Public Images', value: self.unbounceAssetsCompanyId}
      ],
      action: function(e) {
        self.buildSearch();
        self.fireEvent('companyFilterChanged', e.data);
        self.clearSearchField();
      }
    });
  },

  buildSortFilter: function() {
    var self = this;
    this.assetSortTab = new jui.AssetSortTab({
      sortTabButtons: [
        {name: 'Created', id: 'id'},
        {name: 'Name', id: 'content_file_name'},
        {name: 'Filesize', id: 'content_file_size'}
      ],
      toggle: function(e) {
        var assets = self.sortAssets(self.assets, e.data.action, e.data.direction);
        self.loadAssets(assets, self.type, self.openAssetsCompanyId, true);
      }
    });
    return this.assetSortTab;
  },

  initializeToolbar: function() {
    this.clear();
    this.showToolbar();
  },

  initializeAssetsInView: function(assets, type) {
    var self = this;
    this.type = type;

    if (assets.length > 0) {
      this.assetPane.e.removeClassName('empty');
      assets.each(function(asset){
        self.showAsset(asset);
      }, this);

    } else {
      this.assetPane.e.addClassName('empty');
      this.notice = this.assetPane.insert(new Element('p', {className:'note'}).update("There are no "+type+" available. Click the Upload New File button below to upload a new file from your computer"));
    }

    this.assets = assets;
  },

  getAssetThumbElByUUID: function(uuid) {
    return $('thumb_' + uuid);
  },

  updateAssetElLabel: function(thumb, label) {
    thumb.down('p').update(label);
  },

  updateContainerTitle: function(thumb, label) {
    thumb.writeAttribute("title", label) ;
  },

  hideThumbLabelByUUID: function(uuid) {
    this.getAssetThumbElByUUID(uuid).down('p').hide();
  },

  showThumbLabelByUUID: function(uuid) {
    this.getAssetThumbElByUUID(uuid).down('p').show();
  },

  hideThumbRenameFieldByAsset: function(asset) {
    this.renameFields[asset.uuid].hide();
  },

  showThumbRenameFieldByAsset: function(asset) {
    this.renameFields[asset.uuid].show();
    this.field[asset.uuid].focusAndSelect();
  },

  makeAssetRenameable: function(asset) {
    this.hideThumbLabelByUUID(asset.uuid);
    this.showThumbRenameFieldByAsset(asset);
  },

  assetRenamedAction: function(asset) {
    this.hideThumbRenameFieldByAsset(asset);
    var thumb = this.getAssetThumbElByUUID(asset.uuid);
    var renaming = new Element('div', {className: 'renaming-asset'}).update('Renaming...');
    this.updateAssetElLabel(thumb, renaming);
    this.showThumbLabelByUUID(asset.uuid);
  },

  updateAssetImgSrc: function(thumb, asset) {
    if(/image/i.test(asset.content_content_type)) {
      thumb.down('img').setAttribute('src', asset.content_url_small);
    }
  },

  addRenameFieldToThumb: function(asset) {
    var self = this;
    var submitAction = function(newName, nameParts) {
    var assetCurrentFileName = self.assets.find(function(a){
      return a.id === asset.id;
    }).content_file_name;
    if(newName !== assetCurrentFileName.split('.').first()) {
      self.fireEvent('assetRenamed', {
        asset: asset,
        newName: newName,
        ext: nameParts.ext
      });
    } else {
      self.showThumbLabelByUUID(asset.uuid);
      self.hideThumbRenameFieldByAsset(asset);
    }

    };
    this.field[asset.uuid] = new jui.FormTextInput({
      /* jshint unused:vars */
      onkeydown: function(e) {
      },
      onfocus: function(e) {
        var thumb = self.getAssetThumbElByUUID(asset.uuid);
        thumb.up().stopObserving('dblclick');
      },
      onblur: function(e) {
        //var thumb = self.getAssetThumbElByUUID(asset.uuid);
        var nameParts = self.getAssetNameParts(asset);
        var newName = self.field[asset.uuid].getValue().toLowerCase();
        submitAction(newName, nameParts);
      }
    });
    var button = new jui.Button({
      action: function() {
        var nameParts = self.getAssetNameParts(asset);
        var newName = self.field[asset.uuid].getValue().toLowerCase();
        submitAction(newName, nameParts);
      }
    });
    button.setLabel("&#10004;");//Checkmark.
    this.renameFields[asset.uuid] = new jui.Component({attributes: {className: 'asset-rename-field'}});
    this.renameFields[asset.uuid].insert(this.field[asset.uuid]);
    this.renameFields[asset.uuid].insert(button);
    this.field[asset.uuid].setValue(asset.name.split('.').first());
    return this.renameFields[asset.uuid];
  },

  getAssetNameParts: function(asset) {
    var fileNameArray = asset.content_file_name.split('.');
    return {
      name: fileNameArray.first().toLowerCase(),
      ext: fileNameArray.last().toLowerCase()
    };
  },

  updateThumbEl: function(asset) {
    var thumb = this.getAssetThumbElByUUID(asset.uuid);
    this.updateAssetElLabel(thumb, asset.content_file_name);
    this.updateAssetImgSrc(thumb, asset);
    this.addAssetObserver(thumb, asset);
    this.updateContainerTitle(thumb, asset.content_file_name);
  },

  showAsset: function(asset) {
    var thumb = this.addButtonsToThumb(asset);
    this.addAssetObserver(thumb, asset);
    this.assetPane.insert(thumb);
  },

  updateAssetThumb: function(asset) {
    this.updateAssetsArray(asset);
    this.updateThumbEl(asset);
    this.field[asset.uuid].setValue(asset.name.split('.').first());
    this.buildSearch();

    this.reloadSelectedAsset(asset);
  },

  updateAssetsArray: function(asset) {
    var i;
    var index;
    for(i = 0; i <= this.assets.length; i++) {
      if(parseInt(this.assets[i].id,10) === parseInt(asset.id,10)) {
        index = i;
        break;
      }
    }
    this.assets.splice(index, 1, asset);
  },

  addAssetObserver: function(thumb, asset) {
    thumb.stopObserving();
    thumb.observe('click', this.assetClicked.bindAsEventListener(this, asset));
    thumb.observe('dblclick', this.assetDblClicked.bindAsEventListener(this, asset));
  },

  showAssets: function(assets, type) {
    this.initializeToolbar();
    this.initializeAssetsInView(assets, type);
    this.search.setValue(this.searchTerm);
  },

  loadAssets: function(assets, type, companyId) {
    this.clear();
    this.toolbar = this.buildAssetFilterToolbar(type);
    this.selectCurrentCompanyInFilter(companyId);
    this.openAssetsCompanyId = parseInt(companyId, 10);
    this.showAssets(assets, type);
    //Reload Image Cache for the search.
    this.buildSearch();
  },

  getUUIDFromIdString: function(string) {
    return string.split('_')[1];
  },

  buildSearch: function() {
    var self = this;
    var searchField = $$('.search-field').first();

    if(searchField) {
      var searchInputField = searchField.down('input');
      searchInputField.focus();
      this.qsSearch = new jui.QuicksilverAssetSearch(searchInputField, $$('.thumb-container'), {
        afterDisplayResults: function(items) {
          /* jshint unused:vars */

          //Selects the highest scoring asset.
          //if(items.data.length > 0) {
          //var index = items.data[0][1];
          //var uuid = self.getUUIDFromIdString(self.qsSearch.getFilteredItemByIndex(index).identify());
          //self.selectAssetByUuid(uuid);
          //}

          //Selects the first asset
          var firstVisibleAsset = $$('.thumb.small').find(function(asset){
            return $(asset).up('.thumb-container').visible();
          });

          if(firstVisibleAsset) {
            var uuid = self.getUUIDFromIdString(firstVisibleAsset.identify());
            self.selectAssetByUuid(uuid);
          }
        },
        afterKeyPress: function(e) {
          self.searchTerm = self.search.getValue();
          if(13 === e.data.keyCode) {
            var selectedAsset = $$('.thumb.small.selected').first();
            if(selectedAsset) {
              var assetUuid = self.getUUIDFromIdString(selectedAsset.identify());
              var asset = self.assets.find(function(a){
                return a.uuid === assetUuid;
              });
              if(asset) {
                self.fireEvent('assetDblClicked', asset);
              }
            }
          }
        }
      });
    }
  },

  clearSearchField: function() {
    if(this.search) {
      this.search.setValue('');
      this.searchTerm = '';
    }
  },

  sortAssets: function(assets, which, direction) {
    //Sort by content_file_name, content_file_size, content_updated_at
    assets = jui.utils.sort(assets, which, direction);
    return assets;
  },

  isManageable: function() {
    return (this.companyId === this.unbounceAssetsCompanyId) || (this.unbounceAssetsCompanyId !== this.openAssetsCompanyId);
  },

  selectCurrentCompanyInFilter: function(companyId) {
    this.assetFilterSelect.setSelectedValue(companyId);
  },

  addAsset: function(asset) {
    if (this.notice !== null) {
      this.assetPane.e.removeClassName('empty');
      this.notice.remove();
      this.notice = null;
    }

    this.assets.unshift(asset);
    var thumb = this.addButtonsToThumb(asset);
    this.addAssetObserver(thumb, asset);

    this.assetPane.e.insert({top: thumb});
    this.clearSearchField();
    this.buildSearch();
  },

  addButtonsToThumb: function(asset) {
    var src = asset.content_content_type.startsWith('image') ?
      asset.content_url_small :
        '/images/icons/32/page_white_text.png';

    var thumbContainer = new Element('div', {className:'thumb-container', title: asset.content_file_name});
    var thumb = new Element('div', {id:'thumb_'+asset.uuid, className:'thumb small'});
    thumbContainer.insert(thumb);
    var renameButton = new jui.IconButtonWithLabel('rename', {toolTip: "Rename this asset", action: this.renameAsset.bind(this, asset)});
    var deleteButton = new jui.IconButtonWithLabel('delete', {toolTip: "Delete this asset", action: this.deleteAsset.bind(this, asset)});

    thumb.insert(new Element('img', {src: src}));
    thumb.insert(new Element('p').update(asset.content_file_name));
    thumb.insert(this.addRenameFieldToThumb(asset).e);

    this.hideThumbRenameFieldByAsset(asset);

    if(this.isManageable()) {
      thumb.insert(deleteButton.e);
      thumb.insert(renameButton.e);
    }

    return thumbContainer;
  },

  clearSelection: function() {
    this.selectedAssets = [];
    this.updateSelectedThumbs();
    this.fireEvent('assetSelectionChanged');
  },

  selectAssetById: function(id) {
    var asset = this.assets.find(function(a){return a.id === id;});

    if (typeof asset === 'undefined') {
      return;
    }

    this.selectedAssets = [asset];
    this.updateSelectedThumbs();
    this.fireEvent('assetSelectionChanged');

    this.assetPane.e.scrollTop = Math.max(this.selectedThumbs[0].positionedOffset().top + this.selectedThumbs[0].getHeight() - this.getHeight(), 0);
  },

  selectAssetByUuid: function(uuid) {
    var asset = this.assets.find(function(a){return a.uuid === uuid;});

    if (typeof asset === 'undefined') {
      return;
    }

    this.selectedAssets = [asset];
    this.updateSelectedThumbs();
    this.fireEvent('assetSelectionChanged');

    this.assetPane.e.scrollTop = Math.max(this.selectedThumbs[0].positionedOffset().top + this.selectedThumbs[0].getHeight() - this.getHeight(), 0);
  },

  removeAssetsById: function(ids) {
    var assets = this.assets.collect( function(asset) {return ids.indexOf(asset.id * 1) > -1 ? null : asset;}).compact();
    this.buildSearch();
    this.showAssets(assets, this.type);
  },

  updateSelectedThumbs: function() {
    this.selectedThumbs.each(function(thumb) {
      thumb.up().removeClassName('selected');
      thumb.removeClassName('selected');
    });

    this.selectedThumbs = [];

    this.selectedAssets.each(function(asset) {
      var thumb = $('thumb_'+asset.uuid);
      var container = thumb.up();
      if(thumb) {
        thumb.addClassName('selected');
        container.addClassName('selected');
        this.selectedThumbs.push(thumb);
      }
    }, this);

    if (this.selectedAssets.length === 1) {
      this.selectionStart = this.selectedAssets[0];
    } else if (this.selectedAssets.length === 0) {
      this.selectionStart = null;
    }
  },

  findSelectedAssetIndexByUUID: function(uuid) {
    var index = -1;
    this.selectedAssets.each(function(asset, i){
      if(asset.uuid === uuid) {
        index = i;
        return;
      }
    });
    return index;
  },

  reloadSelectedAsset: function(asset) {
    var indexOfAsset = this.findSelectedAssetIndexByUUID(asset.uuid);
    if(indexOfAsset > -1) {
      this.selectedAssets.splice(indexOfAsset, 1, asset);
    }
  },

  assetClicked: function(e, asset) {
    if (e.metaKey || e.ctrlKey) {
      var i = this.selectedAssets.indexOf(asset);
      if (i > -1) {
        this.selectedAssets.splice(i, 1);
        this.updateSelectedThumbs();
        this.fireEvent('assetSelectionChanged');
        return;
      }
      this.selectedAssets.push(asset);
    } else if (e.shiftKey) {
      if (this.selectedAssets.length === 0) {
        this.selectedAssets.push(asset);
      } else if (this.selectedAssets.indexOf(asset) === -1) {
        var from = this.assets.indexOf(this.selectionStart);
        var to =  this.assets.indexOf(asset);
        if (from > to) {
          var temp = to;
          to = from;
          from = temp;
        }

        this.selectedAssets = [];
        var index;
        for (index=from; index<=to; index++) {
          this.selectedAssets.push(this.assets[index]);
        }

      } else {
        // do nothin i guess
      }
    } else {
      this.selectedAssets = [];
      this.selectedAssets.push(asset);
    }


    this.updateSelectedThumbs();
    this.fireEvent('assetSelectionChanged');
  },

  assetDblClicked: function(e, asset) {
    this.fireEvent('assetDblClicked', asset);
  },

  deleteAsset: function(asset) {
    this.fireEvent('assetDeleteClicked', asset);
  },

  renameAsset: function(asset) {
    this.fireEvent('assetRenameClicked', asset);
  }
});
