//  require <jui>
jui.TriPane = Class.create( jui.Component, {
  options: function($super,options){
    return $super($H({
      attributes:{className:'tri-pane'}
    }).merge(options));
  },

  initialize:function($super, options){
    this.widths = options.widths || {};

    this.widths.divider = this.widths.divider || 8;
    this.widths.gutter = this.widths.gutter || 8;
    this.widths.left = this.widths.left || 200;
    this.widths.right = this.widths.right || 200;

    $super(options);
  },

  installUI: function($super) {
    /* jshint maxstatements:44 */
    $super();
    this.makeAbsolute();
    var self = this;
    this.panes = {};
    this.dividers = {};

    this.panes.left     = this.insert(new jui.Component({attributes:{className:'pane left'}})).makeAbsolute();
    this.dividers.left  = this.insert(new jui.Component({attributes:{className:'divider left'}})).makeAbsolute();
    this.panes.center   = this.insert(new jui.Component({attributes:{className:'pane center'}})).makeAbsolute();
    this.dividers.right = this.insert(new jui.Component({attributes:{className:'divider right'}})).makeAbsolute();
    this.panes.right    = this.insert(new jui.Component({attributes:{className:'pane right'}})).makeAbsolute();

    this.dividers.left.observe('click', function() {
      self.toggle('left');
    });

    this.dividers.right.observe('click', function() {
      self.toggle('right');
    });

    var d = this.widths.divider;
    var g = this.widths.gutter;
    var l = this.widths.left;
    var r = this.widths.right;

    this.panes.left.setWidth(l);
    this.dividers.left.setWidth(d);
    this.panes.right.setWidth(r);
    this.dividers.right.setWidth(d);

    this.setLeft(g + (this.options.left || 0));
    this.panes.left.setLeft(0);
    this.dividers.left.setLeft(g + l);
    this.panes.center.setLeft(g + l + d);

    this.setRight(g + (this.options.right || 0));
    this.panes.right.setRight(0);
    this.dividers.right.setRight(g + r);
    this.panes.center.setRight(g + r + d);

    if (typeof this.options.top !== 'undefined') {
      this.setTop(this.options.top);
    }

    if (typeof this.options.bottom !== 'undefined') {
      this.setBottom(this.options.bottom);
    }

    if (typeof this.options.height !== 'undefined') {
      this.setHeight(this.options.height);
    }

    this.panes.left.setTop(0);
    this.panes.left.setBottom(0);
    this.panes.right.setTop(0);
    this.panes.right.setBottom(0);
    this.panes.center.setTop(0);
    this.panes.center.setBottom(0);
    this.dividers.left.setTop(0);
    this.dividers.left.setBottom(0);
    this.dividers.right.setTop(0);
    this.dividers.right.setBottom(0);
  },

  setView: function(view, which) {
    this.panes[which].clear();
    this.panes[which].insert(view);

    if (which === 'left' || which === 'right') {
      this.widths[which] = view.getWidth();
      // console.log()
      if (this.panes[which].visible()) {
        this.showPane(which);
      }
    }

    return view;
  },

  toggle: function(which) {
    this[this.panes[which].visible() ? 'hidePane' : 'showPane'](which);
  },

  toggleLeft: function() {
    this.toggle('left');
  },

  showPane: function(which) {
    var d = this.widths.divider;
    var g = this.widths.gutter;
    var l = this.widths.left;
    var r = this.widths.right;

    switch (which) {
      case 'left':
        this.panes.left.show();
        this.setLeft(g + (this.options.left || 0));
        this.dividers.left.setLeft(l);
        this.dividers.left.e.addClassName('open');
        this.panes.center.setLeft(l + d);
        break;
      case 'right':
        this.panes.right.show();
        this.setRight(g + (this.options.right || 0));
        this.dividers.right.setRight(r);
        this.dividers.right.e.addClassName('open');
        this.panes.center.setRight(r + d);
        break;
    }

    this.fireEvent('layoutChanged');
  },

  hidePane: function(which) {
    var d = this.widths.divider;
    switch (which) {
      case 'left':
        this.panes.left.hide();
        this.setLeft(0 + (this.options.left || 0));
        this.dividers.left.setLeft(0);
        this.dividers.left.e.removeClassName('open');
        this.panes.center.setLeft(d);
        break;
      case 'right':
        this.panes.right.hide();
        this.setRight(0 + (this.options.right || 0));
        this.dividers.right.setRight(0);
        this.dividers.right.e.removeClassName('open');
        this.panes.center.setRight(d);
        break;
    }

    this.fireEvent('layoutChanged');
  }
});
