lp.pom.VisibleElement = Class.create(
  lp.pom.Element,
  lp.pom.VisibleElementViewWrapperMethods, {
  initialize: function($super, page, jso, options){
    $super(page, jso);
    this.view = new (this.getViewClass())(this, options);

    if (typeof this.initView === 'function') {
      this.initView();
    }

    this.rootLayoutListener = this.rootLayoutChanged.bind(this);
    this.installViewObservers();
    this.installModelChangeHandlers();
    this.layout = null;
  },

  getModelClass: function() {
    return lp.pom.VisibleElementModel;
  },

  createDefaultConstraints: function($super) {
    $super();
    this.defaultConstraints.displayable       = true;
    this.defaultConstraints.selectable        = true;
    this.defaultConstraints.width_resizeable  = true;
    this.defaultConstraints.height_resizeable = true;
    this.defaultConstraints.resizeable        = true;
    this.defaultConstraints.offsetable        = true;
    this.defaultConstraints.z_indexable       = true;
  },

  installViewObservers: function() {
    var self = this;

    if (this.page.context === lp.pom.context.EDIT) {
      ['click',
       'mouseover',
       'mouseout',
       'mousedown',
       'dblclick'].each( function(type) {
        self.view.observe(type, function(e) {
          Event.stop(e);
          if (typeof self[type] === 'function') {
            self[type](e);
          }
          self.fireEvent(type, e);
        });
      });
    }
  },

  getParentWidth: function() {
    return this.getParentElement().getWidth();
  },

  getOffsetLeft: function() {
    return this.model.get('geometry.offset.left');
  },

  getOffsetRight: function() {
    return (this.getOffsetLeft() + this.getWidth());
  },

  isPretrudingParentWidth: function() {
    return this.getOffsetLeft() < 0 || this.getOffsetRight() > this.getParentWidth();
  },

  installModelChangeHandlers: function() {
    /* jshint unused:vars */
    this.addModelChangeHandler( function(accessor, value, previous, base, attr) {
      if (accessor.startsWith('geometry.offset')) {
        if (accessor === 'geometry.offset.left') {
          value = {left:value, top:this.model.getTop()};
        }

        if (accessor === 'geometry.offset.top') {
          value = {left:this.model.getLeft(), top:value};
        }

        this.updateOffset(value);
      }
    });

    this.addModelChangeHandler( function(accessor, value, previous, base, attr) {
      if (accessor.startsWith('geometry.size')) {
        this.updateDimensions(value);
      }
    });

    this.addModelChangeHandler( function(accessor, value, previous, base, attr) {
      if (accessor === 'geometry.zIndex') {
        if (typeof value !== 'undefined' && value !== null) {
          this.updateZIndex(value);
        }
      }
    });

    this.addModelChangeHandler( function(accessor, value, previous, base, attr) {
      if (accessor.startsWith('geometry.position')) {
        this.getViewDOMElement().style.position = this.model.get("geometry.position");
      }
    });

    this.addModelChangeHandler( function(accessor, value, previous, base, attr) {
      if (accessor === 'geometry.scale') {
        this.updateScale(value);
      }
    });

    this.addModelChangeHandler( function(accessor, value, previous, base, attr) {
      if (accessor.startsWith('geometry.visible')) {
        this.updateVisibility(value);
        this.applyStyleAttributes();
      }
    });

    this.addModelChangeHandler( function(accessor, value, previous, base, attr) {
      if (accessor.startsWith('geometry.borderLocation') ||
          accessor.startsWith('geometry.borderApply') ||
          accessor.startsWith('geometry.fitWidthToPage')) {

        this.applyBorder();
        this.updateDimensions();
        this.updateOffset();
        this.childElements.each( function(c) {c.updateOffset();});
      }
    });

    this.addModelChangeHandler( function(accessor, value, previous, base, attr) {
      if (accessor === 'geometry.cornerRadius') {
        this.applyCornerRadius();
      }
    });

    this.addModelChangeHandler( function(accessor, value, previous, base, attr) {
      if (accessor.startsWith('style.background')){
        this.applyBackground();
      }
    });

    this.addModelChangeHandler( function(accessor, value, previous, base, attr) {
      if (accessor === 'geometry.padding' ||
          accessor === 'geometry.contentWidth' || accessor === 'geometry'){

        this.applyGeometry({accessor:accessor, value:value, attr:attr});

      }
    });

    this.addModelChangeHandler( function(accessor, value, previous, base, attr) {
      if (accessor.startsWith('style.border')) {
        this.applyBorder(value);
        this.updateDimensions();
        this.updateOffset();
      }
    });

    this.addModelChangeHandler( function(accessor, value, previous, base, attr) {
      if (accessor.startsWith('geometry.margin')) {
        this.applyMargin(value);
      }
    });

    this.addModelChangeHandler( function(accessor, value, previous, base, attr) {
      if (accessor.startsWith('geometry.padding')) {
        this.applyPadding(value);
      }
    });

    this.addModelChangeHandler( function(accessor, value, previous, base, attr) {
      if (accessor === 'geometry.layout') {
        this.setLayout(this.createLayout(value));
      }
    });
  },

  getViewClass: function() {
    return lp.pom.ElementView;
  },

  getView: function() {
    return this.view;
  },

  getViewDOMElement: function() {
    return this.view.e;
  },

  _attach: function($super) {
    var visible = this.view.visible();
    if (!visible) {
      this.view.show();
    }
    $super();
    // if (this.page.context)
    this.applyStyleAttributes();
    if (!visible) {
      this.view.hide();
    }

    this.page.getRootElement().addListener('layoutChanged', this.rootLayoutListener);
  },

  _detach: function($super) {
    $super();
    this.page.getRootElement().removeListener('layoutChanged', this.rootLayoutListener);
  },

  applyLayout: function() {
    var layout = this.model.getLayout() || null;
    this.setLayout(this.createLayout(layout));
  },

  createLayout: function(layout) {
    if (layout === null) { return null;}

    if(layout.type === 'vertical') {
      return new lp.pom.VerticalLayout(this, layout.options);
    } else if(layout.type === 'horizontal') {
      return new lp.pom.HorizontalLayout(this, layout.options);
    } else if(layout.type === 'column') {
      return new lp.pom.ColumnLayout(this, layout.options);
    } else {
      return null;
    }
  },

  setLayout: function(layout) {
    if (this.layout !== null) {
      this.layout.destroy(null);
    }
    this.layout = layout;
    this.updateLayout();
  },

  getLayout: function() {
    return this.layout;
  },

  updateLayout: function() {
    if (this.layout !== null) {
      this.layout.updateLayout();
    }
  },

  rootLayoutChanged: function() {
    if (this.is('offsetable')) {
      this.updateOffset();
    }
  },

  changeParent: function(newParent, zIndex) {
    var p = jui.Point.subtract(this.getPageOffset(), newParent.getPageOffset());
    this.model.setOffset(p);
    if (this.parentElement) {
      this.parentElement.removeChildElement(this);
    } else {
      // NOTE: this block is a hack to work around a bug in the undo
      // manager that results in this method being called on elements with parentElement=null.
      // The two calls below are roughly what .removeChildElement does to the child.
      // See https://unbounce.hipchat.com/search?q=Cannot+read+property+%27removeChildElement%27+of+null&t=rid-13895&a=Search#09:04:55
      // TODO-TR: revisit this once the undo manager has been gutted
      this._detach();
      this.view.remove();
    }

    if (Object.isNumber(zIndex)) {
      this.model.setZIndex(zIndex);
      newParent.insertZIndex(zIndex, this.getDescendants(true).length + 1);
    } else {
      this.model.deleteAttr('geometry.zIndex');
    }

    newParent.insertChildElement(this);
    this.fireEvent('parentChanged', this);
  },

  insertChildElement: function($super, elm, options){
    options = options || {};

    var contentElm = this.getContentElement();
    var zIndex = elm.model.getZIndex();

    if (elm.is('z_indexable') && typeof zIndex === 'undefined') {
      zIndex = this.getNextZIndex();
      elm.model.setZIndex(zIndex);
      this.insertZIndex(zIndex, elm.getDescendants(true).length + 1);
    }

    if (options.insertZIndex) {
      this.insertZIndex(zIndex, elm.getDescendants(true).length + 1);
    }

    if (typeof options.containerIndex !== 'undefined' &&
        options.containerIndex < this.childElements.length) {
      var i = contentElm.children.indexOf(
        this.childElements[options.containerIndex].getView());

      contentElm.insert(elm.getView(), i);
    } else {
      contentElm.insert(elm.getView());
    }

    $super(elm, options);
  },

  removeChildElement: function($super, elm) {
    $super(elm);
    elm.view.remove();
    this.getRootElement().compactZIndexes();
  },

  getVisibleChildElementsByZIndexOrder: function() {
    return this.getElementsByZIndexOrder().findAll( function(elm) {
      return elm.model.isVisible();
    });
  },

  getIndexables: function() {
    return this.childElements.findAll(function(elm){ return elm.is('z_indexable');});
  },

  getFirstElementByZIndex: function() {
    return this.getElementsByZIndexOrder().first();
  },

  getLastElementByZIndex: function() {
    return this.getElementsByZIndexOrder().last();
  },

  getElementsByZIndexOrder: function() {
    return this.getIndexables().sortBy(function(elm) { return elm.model.get('geometry.zIndex')*1; });
  },

  getPageSection: function() {
    var iterator = function(elm) {
      if(!elm){
          return null;
      } else if (elm.type === 'lp-pom-block') {
        return elm;
      } else {
        return iterator(elm.parentElement);
      }
    };
    return iterator(this);
  },

  hide: function() {
    this.childElements.invoke('hide');
    this.view.hide();
    this.fireEvent('hidden');
  },

  show: function() {
    this.childElements
      .filter(function(elm) {
        return elm.model.isVisible();
      })
      .invoke('show');
    this.view.show();
    this.fireEvent('shown');
  },

  changeZIndex: function(direction) {
    this.getParentElement().changeChildZIndex(this, direction);
  },

  changeChildZIndex: function(element, direction) {
    this.page.undoManager.registerUndo({
      action: this.changeChildZIndex,
      receiver: this,
      params:[element, direction*-1]
    });

    var currentZ = element.model.getZIndex();

    var swappy = this.getIndexables().sortBy(function(elm) {
      return elm.model.getZIndex() * direction;
    }).find(function(elm){
      return (elm.model.getZIndex()*direction) > (currentZ * direction);
    });

    if (!Object.isUndefined(swappy)) {
      var newZ = swappy.model.getZIndex();

      if (direction === -1) {
        currentZ = newZ + element.getMaxZIndex() - currentZ + 1;
      } else {
        newZ = currentZ + swappy.getMaxZIndex() - newZ + 1;
      }

      element.model.setZIndex(newZ);
      swappy.model.setZIndex(currentZ);
    }
    element.fireEvent('zIndexChanged');
  },

  changeOrder: function(element, direction) {
    this.page.undoManager.registerUndo({
      action: this.changeOrder,
      receiver: this,
      params:[element, direction*-1]
    });

    var elms = this.childElements.select(function(e) {return e.is('orderable');});
    if (elms.length < 2) {
      return;
    }

    var oldIndex = elms.indexOf(element);
    var swapIndex = oldIndex + direction;

    if (swapIndex < 0 || swapIndex >= elms.length || swapIndex === oldIndex) {
      return;
    }

    var swappy = elms[swapIndex];

    this.page.swapElementOrder(swappy,element);
    this.swapElementOrder(swappy,element);
    element.fireEvent('moved');
    element.fireEvent('orderChanged');
  },

  swapElementOrder: function(a,b) {
    var aIndex = this.childElements.indexOf(a);
    var bIndex = this.childElements.indexOf(b);

    this.childElements[aIndex] = b;
    this.childElements[bIndex] = a;
    // swap the DOM Elements
    if (aIndex < bIndex) {
      a.getViewDOMElement().insert({before:b.view.e});
    } else {
      a.getViewDOMElement().insert({after:b.view.e});
    }
  },

  getDescendants: function(indexable, depth) {
    indexable = indexable || false;
    depth = depth || -1;
    var d = [];
    depth--;

    this.childElements.each(function(e) {
      if (!indexable || e.is('z_indexable')) {
        d.push(e);
      }
      if (depth !== 0) {
        d = d.concat(e.getDescendants(indexable, depth));
      }
    });
    return d;
  },

  containsElement: function(elm) {
    return this.getDescendants().indexOf(elm) > -1;
  },

  getMinZIndex: function() {
    if(this.is('z_indexable')) {
      return this.model.getZIndex() + 1;
    } else {
      return this.parentElement.getMinZIndex();
    }
  },

  getMaxZIndex: function() {
    if(this.is('z_indexable')) {
      return this.getMinZIndex() + this.getDescendants(true).length - 1;
    } else {
      return this.parentElement.getMaxZIndex();
    }
  },

  getNextZIndex: function() {
    return this.getMaxZIndex() + 1;
  },

  insertZIndex: function(minZ, gap) {
    gap = gap || 1;
    this.childElements.each(function(e) {
        if (e.is('z_indexable')) {
          var current = e.model.getZIndex();
          if (current >= minZ) {
            e.model.setZIndex(current + gap);
          }
        }
    });

    this.parentElement.insertZIndex(minZ, gap);
  },

  removeCSSRules: function(selectors) {
    /* jshint unused:vars */
  },

  applyPageStyles: function (rules, options) {
    options = options || {};
    rules.each(function(rule) {
      rule.selector = rule.selector || options.selector || '#'+this.id;
      this.page.style.setCSSRule(rule);
    }, this);

    if (this.page.context === lp.pom.context.EDIT) {
      editor.pages.each(function(page) { page.style.updatePageStyles(); });
    }
  },

  applyStylesToDom: function(rules, options) {
    options = options || {};
    var elm = options.elm || this.getViewDOMElement();
    // TODO-TR: BUG: this is different semantics from applyPageStyles which
    // allows us to target elements within this current view element.
    var cssText = elm.style.cssText;
    var currentRules = cssText === "" ? [] : cssText.split(';').collect( function(r) {
      return r.strip() === '' ? null : r;
    }).compact();

    if (options.removeAttrs) {
      options.exact = options.exact || false;
      currentRules = currentRules.select(function(rule) {
        rule = rule.strip();
        for (var i=0, l=options.removeAttrs.length, attr; i < l; i++) {
          attr = options.removeAttrs[i];
          if (options.exact ? rule === attr : rule.startsWith(attr)) {
            return false;
          }
        }
        return rule !== '';
      });
    }
    rules = currentRules.concat(rules.collect( function(rule) {
      return rule.attribute+':'+rule.value;
    })).join(';') + ';';
    elm.style.cssText = rules;
  },

  normalizedBgColor: function(m){
    var bgColor = m.get('style.background.backgroundColor').replace('#','');
    var defaultColor = m.exists('style.background.savedBackgroundColor') ? m.get('style.background.savedBackgroundColor') : 'ffffff';
    if(bgColor === 'transparent') {
      m.set('style.background.opacity', 0, this.page.undoManager);
      m.set('style.background.backgroundColor', defaultColor);
    }
    return jui.ColorMath.normalizeHexValue(bgColor === 'transparent' ? defaultColor : bgColor);
  },

  getBGColorAsRGBA: function(color) {
      var m = this.model;
      color = color ? color : this.getHexColorAsRgb();
      var opacity = m.exists('style.background.opacity') ? parseInt(m.get('style.background.opacity'), 10)/100:1;
      color.push(opacity);
      return "rgba("+color.join()+")";
  },

  getHexColorAsRgb: function() {
    return jui.ColorMath.hexToRgb(this.normalizedBgColor(this.model));
  },

  applyBackground: function() {
    /* jshint maxcomplexity:17 */
    // TODO: refactor this!!!!
    var m     = this.model
      , rules = []
      , color;

    if (m.exists('style.background.backgroundColor')) {
      color = this.getHexColorAsRgb();
      var rgba = this.getBGColorAsRGBA(color);
      rules.push({attribute: 'background', value: rgba});
    }

    if (m.getCurrentFillType() === 'gradient') {
      var g    = m.getGradient()
        , from = g.from.replace('#', '')
        , to   = g.to.replace('#', '');

      rules.push({attribute:'background', value: '-webkit-linear-gradient(#'+from+', #'+to+')'});
      rules.push({attribute:'background', value: '-moz-linear-gradient(#'+from+', #'+to+')'});
      rules.push({attribute:'background', value: 'linear-gradient(#'+from+', #'+to+')'});

      if (this.page.context !== lp.pom.context.EDIT) {

        rules.push({attribute:'background', value: '-ms-linear-gradient(#'+from+', #'+to+')'});
        rules.push({attribute:'background', value: '-o-linear-gradient(#'+from+', #'+to+')'});
        rules.push({attribute:'-pie-background', value: 'linear-gradient(#'+from+', #'+to+')'});
        rules.push({attribute:'behavior', value:  'url(/PIE.htc)'});
      }

    } else if (m.exists('style.background.image') && m.shouldApplyBackgroundImage()) {
      var img = m.get('style.background.image');
      if (! (img === null || !img.content_url || img.content_url === 'none')) {
        var bgRepeatPath   = 'style.background.backgroundRepeat'
          , bgPositionPath = 'style.background.backgroundPosition';

        rules.push({attribute:'background-image', value: 'url('+this.page.decorateImageSrc(img.content_url)+')'});

        if (m.exists(bgRepeatPath))  {
          rules.push({attribute:'background-repeat', value: m.get(bgRepeatPath)});
        }

        if (m.exists(bgPositionPath))  {
          rules.push({attribute:'background-position', value: m.get(bgPositionPath)});
        }

        if(this.page.context !== lp.pom.context.EDIT) {
          color = this.getBGColorAsRGBA();
          var bgTemplate = new Template("#{bgColor} url(#{backgroundUrl}) #{repeat} #{position}");
          var background = {
            bgColor: color ? color : '',
            backgroundUrl: this.page.decorateImageSrc(img.content_url),
            repeat: m.exists(bgRepeatPath) ? m.get(bgRepeatPath) : '',
            position: m.exists(bgPositionPath) ? m.get(bgPositionPath) : ''
          };
          rules.push({
            attribute: '-pie-background',
            value: bgTemplate.evaluate(background)
          });
        }
      }
    } else {
      // TODO: Fix scope of color
      if(color && this.page.context !== lp.pom.context.EDIT) {
        rules.push({attribute: '-pie-background', value: 'rgba(' + color.join() + ')'});
      }
    }

    if (this.page.context === lp.pom.context.EDIT) {
      this.applyStylesToDom(rules, {removeAttrs:['background']});

    } else {
      this.applyPageStyles(rules);
    }
  },


  applyBorder: function(value) {
    /* jshint maxcomplexity:16 */
    var m = this.model;
    var rules = [];

    if (!m.exists('style.border') && !value) {
      // explicitly set to none to be breakpoint safe
      rules.push({attribute:'border-style', value: 'none'});
    } else {
      value = value || m.get('style.border');
      var borderApply = m.exists('geometry.borderApply') ? m.get('geometry.borderApply') : false;
      var borderStyle = borderApply ?
        (borderApply.top === borderApply.left && borderApply.left === borderApply.bottom && borderApply.bottom === borderApply.right) ?
        borderApply.top ? value.style : 'none' : [
          borderApply.top ? value.style : 'none',
          borderApply.right ? value.style : 'none',
          borderApply.bottom ? value.style : 'none',
          borderApply.left ? value.style : 'none'
        ].join(' ') : value === null ? 'none' : value.style;
      rules.push({attribute:'border-style', value: borderStyle});
      if(borderStyle !== 'none') {
        rules.push({attribute:'border-width', value: value.width+'px'});
        if(value.color && value.color.strip() !== '') {
          rules.push({attribute:'border-color', value: '#'+value.color.replace('#', '')});
        } else {
          //In the off chance that the color is blank explictly set it to none to be breakpoint safe
          rules.push({attribute:'border-color', value: 'none'});
        }
      }

    }

    var options = {};

    if (this.applyBorderTo) {
      options.elm = this.applyBorderTo.elm;
      options.selector = this.applyBorderTo.selector;
    }

    if (this.page.context === lp.pom.context.EDIT) {
      this.applyStylesToDom(rules, options);
    } else {
      this.applyPageStyles(rules, options);
    }

  },

  applyMargin: function(value) {
    var m = this.model;

    if (!m.exists('geometry.margin') && !value) {
      return;
    }

    value = value || m.get('geometry.margin');
    var rules = [];

    if (typeof value === 'object') {
      $H(value).each(function(entry) {
        rules.push({attribute:'margin-'+entry.key, value: typeof entry.value === 'number' ? entry.value+'px' : entry.value});
      });

    } else {
      rules.push({attribute:'margin', value: value});
    }

    if (this.page.context === lp.pom.context.EDIT) {
      this.applyStylesToDom(rules);
    } else {
      this.applyPageStyles(rules);
    }
  },

  applyPadding: function(value) {
    var m = this.model;

    if (!m.exists('geometry.padding') && !value) {
      return;
    }

    value = value || m.get('geometry.padding');
    var rules = [];

    if (typeof value === 'object') {
      $H(value).each(function(entry) {
        rules.push({attribute:'padding-'+entry.key, value: typeof entry.value === 'number' ? entry.value+'px' : entry.value});
      });

    } else {
      rules.push({attribute:'padding', value: value});
    }

    if (this.page.context === lp.pom.context.EDIT) {
      this.applyStylesToDom(rules);
    } else {
      this.applyPageStyles(rules);
    }
  },

  applyGeometry: function() {
    var s = this.getViewDOMElement().style;
    var m = this.model;
    var rules = [];

    if (this.page.context === lp.pom.context.EDIT && m.exists('geometry.position')) {
      s.position = m.get('geometry.position');
    } else {
      if (m.exists('geometry.position')) {
        rules.push({attribute:'position', value:m.get('geometry.position')});
      }
    }

    if (this.is('offsetable')) {
      this.updateOffset();
    }

    if (this.is('z_indexable')) {
      this.applyZIndex();
    }

    if (m.exists("geometry.size")) {
      this.updateDimensions();
    }

    this.updateScale();

    if (!!m.containerId && (!m.isVisible() || !this.isParentPageSectionVisible())) {
      rules.push({attribute:'display', value:'none'});
    }

    if (rules.length > 0) {
      if (this.page.context === lp.pom.context.EDIT) {
        this.applyStylesToDom(rules);
      } else {
        this.applyPageStyles(rules);
      }
    }
  },

  isParentPageSectionVisible: function() {
    return (this.getPageSection() === this.getParentElement() && (this.getPageSection().model.isVisible()));
  },

  updateElementGeometry: function() {
    this.applyLayout();
    this.updateVisibility(this.model.isVisible());
  },

  updateVisibility: function(visible) {
    this.setVisible(visible);
  },

  setVisible: function(visible) {
    if(this.page.context === lp.pom.context.EDIT) {
      this.view.setVisible(visible);
    } else {
      // BUG: this looks like broken, dead code that will never be reached
      var options = {elm: this.getViewDOMElement()};
      var show = visible ? 'block' : 'none';
      var rules = [{attribute: 'display', value: show}];
      this.applyPageStyles(rules, options);
    }
  },

  applyCornerRadius: function() {
    var m = this.model;
    var rules = [];
    var removeAttributes = [];
    var attributeMap = {
      tl:'border-top-left-radius',
      tr:'border-top-right-radius',
      bl:'border-bottom-left-radius',
      br:'border-bottom-right-radius'
    };
    var radius = m.exists('geometry.cornerRadius') ? m.get('geometry.cornerRadius') : 0;

    rules.push({attribute:'behavior', value:  'url(/PIE.htc)'});

    if (Object.isNumber(radius)) {
      rules.push({attribute:'border-radius', value: radius + 'px'});
      removeAttributes = ['border-top-left-radius', 'border-top-right-radius',
                          'border-bottom-left-radius', 'border-bottom-right-radius'];
    } else {
      for (var corner in radius) {
        if (radius[corner] === 0) {
          removeAttributes.push(attributeMap[corner]);
        } else {
          rules.push({attribute:attributeMap[corner], value: radius[corner] + 'px'});
        }
      }
    }

    var options = {};
    if (this.applyBorderTo) {
      options.elm = this.applyBorderTo.elm;
      options.selector = this.applyBorderTo.selector;
    }

    if (this.page.context === lp.pom.context.EDIT) {
      options.removeAttrs = removeAttributes;
      this.applyStylesToDom(rules, options);
    } else {
      this.applyPageStyles(rules, options);
    }
  },

  applyVisibility: function() {
    this.updateVisibility(this.model.isVisible());
  },

  isVisibleOnPage: function() {
    return jQuery('#' + this.id).is(':visible');
  },

  applyStyleAttributes: function() {
    this.applyBackground();
    this.applyBorder();
    this.applyMargin();
    this.applyPadding();
    this.applyGeometry();
    this.applyCornerRadius();
    this.applyVisibility();
  },

  isSettable: function(accessor, value) {
    switch (accessor) {
      case 'style.border.style':
        return (typeof value === 'string');
      default:
        return true;
    }
  },

  hasBorder: function() {
    return this.model.exists('style.border.style') && this.model.get('style.border.style') !== 'none';
  },

  getBorderWidth: function() {
    return this.model.get('style.border.width');
  },

  getOuterBorderOffsetAdjust: function() {
    var m = this.model;
    if (this.hasBorder() && m.get('geometry.borderLocation') === 'outside') {
      return {
        top:(m.exists('geometry.borderApply') ? (m.get('geometry.borderApply.top') ? this.getBorderWidth() : 0 ): this.getBorderWidth()) * 1,
        left:(m.exists('geometry.borderApply') ? (m.get('geometry.borderApply.left') ? this.getBorderWidth() : 0 ): this.getBorderWidth()) * 1
      };
    }

    return {left:0, top:0};
  },

  getInnerBorderOffsetAdjust: function() {
    var m = this.model;
    if (this.hasBorder() && m.get('geometry.borderLocation') === 'inside') {
      return {
        top:(m.exists('geometry.borderApply') ? (m.get('geometry.borderApply.top') ? this.getBorderWidth() : 0 ): this.getBorderWidth()) * 1,
        left:(m.exists('geometry.borderApply') ? (m.get('geometry.borderApply.left') ? this.getBorderWidth() : 0 ): this.getBorderWidth()) * 1
      };
    }

    return {left:0, top:0};
  },

  applyZIndex: function(zIndex) {
    zIndex = zIndex || this.model.getZIndex();
    if (this.page.context === lp.pom.context.EDIT) {
      this.view.setZIndex(zIndex);
    } else {
      this.applyPageStyles([{attribute:'z-index', value: zIndex}]);
    }
  },

  updateZIndex: function(value) {
    var newZ = value || this.model.getZIndex();
    this.applyZIndex(newZ);

    if (this.childElements.length > 0) {
      var elms = this.childElements;
      var oldMinZ = elms.collect(function(elm) {return elm.model.getZIndex();}).sort().first();
      var newMinZ = this.getMinZIndex();

      if (newMinZ !== oldMinZ) {
        this.childElements = [];

        elms.each(function(e) {
          e.rebaseZIndex(oldMinZ, newMinZ);
        });

        this.childElements = elms;
      }
    }
  },

  rebaseZIndex: function(oldBase, newBase) {
    var newZ = newBase - oldBase + this.model.getZIndex();
    this.model.setZIndex(newZ);
  },

  updateOffset: function(value) {
    this.setOffset(value || this.model.getOffset());
  },

  setOffset: function(offset) {
    offset = this.adjustOffset(offset);

    if (this.page.context === lp.pom.context.EDIT) {
      this.view.setOffset(offset);
    } else {
      this.applyPageStyles([{attribute:'left', value:offset.left+'px'}, {attribute:'top', value:offset.top+'px'}]);
    }
  },

  adjustOffset: function(offset) {
    var o = {left:offset.left, top:offset.top};

    if (this.parentElement !== null) {
      if (this.parentElement.type === 'lp-pom-block') {
        jui.Point.add(o, this.parentElement.getRootOffset());
      } else {
        jui.Point.subtract(o, this.parentElement.getInnerBorderOffsetAdjust());
      }
    }

    jui.Point.subtract(o, this.getOuterBorderOffsetAdjust());

    return o;
  },

  updateDimensions: function(/* dims */) {
    // TODO-TR: this is sometimes called with a dims arg, sometimes not.
    // the modelChangeHandler always passes dims in. Be careful about breakpoints.
    // lp-block overrides this to explicitly ignore the dims arg
    // ... this.setDimensions(dims || this.model.getSize()); once we are confident
    //
    this.setDimensions(this.model.getSize());
  },

  setDimensions: function(dims) {
    dims = this.adjustDimensions(dims);
    if (this.page.context === lp.pom.context.EDIT) {
      var view = (this.applyDimensionsTo && this.applyDimensionsTo.juiComponent) ?
        this.applyDimensionsTo.juiComponent : this.getView();
      view.setDimensions(dims);
    } else {
      var options = {};
      if (this.applyDimensionsTo && this.applyDimensionsTo.selector) {
        options.selector = this.applyDimensionsTo.selector;
      }
      this.applyPageStyles([
        {attribute:'width', value:dims.width+'px'},
        {attribute:'height', value:dims.height+'px'}], options);
    }
  },

  adjustDimensions: function(dims) {
    var m = this.model;
    if (this.hasBorder() && this.model.get('geometry.borderLocation') === 'inside') {
      var adjust = {width:0, height:0};
      if (m.exists('geometry.borderApply')) {
        adjust.width += m.get('geometry.borderApply.left') ? this.getBorderWidth() * 1 : 0;
        adjust.width += m.get('geometry.borderApply.right') ? this.getBorderWidth() * 1 : 0;
        adjust.height += m.get('geometry.borderApply.top') ? this.getBorderWidth() * 1 : 0;
        adjust.height += m.get('geometry.borderApply.bottom') ? this.getBorderWidth() * 1 : 0;
      }
      else {
        adjust.width = this.getBorderWidth() * 2;
        adjust.height = this.getBorderWidth() * 2;
      }
      dims = {width:dims.width - adjust.width, height:dims.height - adjust.height};
    }
    return dims;
  },

  updateScale: function(scale) {
    scale = scale || this.model.getScale();
    if (this.page.context === lp.pom.context.EDIT) {
      this.getView().setScale(scale);
    } else if (scale !== 1) {
      this.applyPageStyles([
        {attribute:'transform', value:'scale('+scale+')'},
        {attribute:'transform-origin', value:'0 0'},
        {attribute:'-webkit-transform', value:'scale('+scale+')'},
        {attribute:'-webkit-transform-origin', value:'0 0'}]);
    }
  },

  getParentBorderOffsetAdjust: function() {
    var adjust = {left:0, top:0};
    var pe = this.parentElement;
    if (pe !== null && pe.type !== 'lp-pom-block' && pe.type !== 'lp-pom-root' && pe.type !== 'lp-modal') {
      var pm = pe.model;
      var peAdjust = pe.getParentBorderOffsetAdjust();
      adjust.left += peAdjust.left;
      adjust.top += peAdjust.top;
      if (pe.hasBorder()) {
        adjust.top += (pm.exists('geometry.borderApply') ? (pm.get('geometry.borderApply.top') ? pe.getBorderWidth() : 0 ): pe.getBorderWidth()) * 1;
        adjust.left += (pm.exists('geometry.borderApply') ? (pm.get('geometry.borderApply.left') ? pe.getBorderWidth() : 0 ): pe.getBorderWidth()) * 1;
      }
    }
    return adjust;
  },

  getRootOffset: function(ref) {
    /* jshint unused:vars */
    var m = this.model;
    var mo = m.getOffset();
    var o = {left:mo.left, top:mo.top};
    if (this.parentElement) {
      jui.Point.add(o, this.parentElement.getRootOffset());
    }

    return o;
  },

  getPageOffset: function() {
    var m = this.model;
    var mo = m.getOffset();
    var o = {left:mo.left, top:mo.top};

    if (this.parentElement) {
      jui.Point.add(o, this.parentElement.getPageOffset());
    }

    return o;
  },

  clone: function(container){
    var module = lp.getModule(this.type);
    var model = this.model.clone();

    container = container || this.getParentElement();

    var options = {container: container};

    if(this.type === 'lp-pom-block') {
      options.containerIndex = this.getIndexWithinVisibleElements();
    }

    model.id = null;
    model.name = this.page.generateDefaultElementName(this.type, this.name, true);
    model.geometry.zIndex = container.getNextZIndex();

    var clone = module.buildPageElement(this.page, model);
    this.page.insertElement(clone,options);

    this.childElements.each(function(c){
      c.clone(clone);
    }, this);

    return clone;
  },

  blockChangeUpdate: function() {
    if (this.is('offsetable')) {
      this.updateOffset();
    }
  },

  blockResized: function() {
    this.blockChangeUpdate();
  },

  blockMoved: function() {
    this.blockChangeUpdate();
  },

  blockRemoved: function() {
    this.blockChangeUpdate();
  },

  blockInserted: function() {
    this.blockChangeUpdate();
  },

  blockHidden: function() {
    this.blockChangeUpdate();
  },

  blockShown: function() {
    this.blockChangeUpdate();
  },

  getContentElement: function() {
    return this.view;
  },

  getContentWidth: function() {
    return Math.round(this.model.getWidth() * this.model.getScale());
  },

  getContentHeight: function() {
    return Math.round(this.model.getHeight() * this.model.getScale());
  }

});
