(function(){
  jui.FormTextInput = Class.create(jui.Component, jui.ControlModel, {
    options: function($super, options) {
      return $super($H({
        attributes: {
          className: 'form-elm text-input'
        },
        label:'',
        placeholder: '',
        inputFilters: []
      }).merge(options));

    },

    initialize: function($super, options) {
      $super( options );
      if (this.options.onkeydown) {
          this.addListener('keydown', this.options.onkeydown);
      }
      if (this.options.onkeyup) {
          this.addListener('keyup', this.options.onkeyup);
      }
      if (this.options.onfocus) {
          this.addListener('focus', this.options.onfocus);
      }
      if (this.options.onblur) {
          this.addListener('blur', this.options.onblur);
      }

      if (this.options.tabindex) {
        this.e.down('input').writeAttribute('tabindex', this.options.tabindex);
      }

    },

    installUI: function($super) {
      $super();
      this.label = this.insert(new Element('label').update(this.options.label));
      if (this.options.label === '' || this.options.label === null) {
        this.label.hide();
      }

      this.input = this.insert(new Element('input', {
        type: 'text',
        className: 'text',
        placeholder: this.options.placeholder,
        readonly: this.options.readonly || false
      }));

      this.input.disabled = !!this.options.readonly;

      if (this.options.inputPosition) {
        this.input.addClassName(this.options.inputPosition);
      }

      this.input.observe('focus', this.onFocus.bind(this));
      this.input.observe('blur', this.onBlur.bind(this));
      if (this.options.onkeyup) {
          this.input.observe('keyup', this.onkeyup.bind(this));
      }
      if (this.options.onkeydown) {
          this.input.observe('keydown', this.onkeydown.bind(this));
      }
    },

    setValue: function(value) {
      // TODO: JS: validation
      this.input.value = value;
    },

    getValue: function() {
      return this.input.value;
    },

    focusAndSelect: function() {
      this.input.focus();
      this.input.select();
      window.editor.keyController.requestFocus(this);
    },

    onFocus: function(e) {
      if (this.isEnabled()) {
        e.stop();
        this.fireEvent('focus', this);
      }
    },

    onkeydown: function(e) {
      this.options.inputFilters.invoke('filter', e);
      switch (e.keyCode) {
        case Event.KEY_RETURN:
         this.blur();
         e.stop();
         break;
      }
      this.fireEvent('keydown', e.keyCode);
    },

    onkeyup: function(e) {
      if (!(e.keyCode === Event.KEY_RETURN || e.keyCode === Event.KEY_TAB)) {
        this.fireEvent('keyup', e.keyCode);
      }
    },

    onBlur: function(e) {
      if (this.isEnabled()) {
        e.stop();
        this.fireEvent('blur', this);
      }
    },

    blur: function() {
      this.input.blur();
    }
  });

  jui.FormTextInputWithHint = Class.create(jui.FormTextInput, {
    installUI: function($super) {
      $super();
      this.hint = this._hint();
      if (this.options.hintText === '' || this.options.hintText === null) {
        this.hintText.hide();
      }
    },
    _hint: function() {
      var element = new Element('div', {
        id: 'jui-input-text-desc',
        className: 'input-hint'
      });
      element.update(this.options.hintText);
      return this.insert(element);
    }
  });
})();
