lp.editor.TransformBox = Class.create(
  jui.Component, jui.EventSource, lp.editor.dnd.transformElementDnDSource, {

  options: function($super,options){
    return $super($H({attributes:{className:'transform-box'}}).merge(options));
  },

  initialize: function( $super, editor, options ){
    this.editor = editor;
    this.elm = null;
    this.tracking = false;
    this.handleW = 8;
    this.handleH = 8;
    this.handleNames = ['tlh','trh','blh','brh','tmh','rmh','bmh','lmh'];
    this.moduleUIExtensions = [];

    $super(options);

    this.mouseMoveListener = this.mousemove.bindAsEventListener( this );
    this.mouseUpListener   = this.mouseup.bindAsEventListener( this );

    this.elmModelListener = this.elementModelChanged.bind(this);
    this.undoRegistered = false;

    var self = this;
    var adjustmentListener = function() {
      if (self.elm !== null && self.elm.type !== 'lp-pom-root') {
        self.positionBoundingBox();
      }
    };

    this.editor.addListener('layoutChanged', adjustmentListener);

    this.editor.addListener('elementMousedown', this);
    this.editor.addListener('elementActivated', this);
    this.editor.addListener('elementDeactivated', this);
    this.editor.addListener('activeRootChanged', this);
  },

  activeRootChanged: function() {
    // TODO: what is the point of this method!?
    //var previous = e.data.previous;
    //var root = e.data.current;
  },

  installUI: function( $super ) {
    $super();
    this.installBoundingBox();
    this.installHandles();
    this.extensionsContainer = this.insert( new jui.Component() );
  },

  installModuleUIExtensions: function( elm ) {
    var self = this;
    this.extensionsContainer.clear();
    this.moduleUIExtensions.clear();
    var module = lp.getModule( elm.type );
    if( typeof module.getTransformBoxUIExtensions === 'function' ) {
      this.moduleUIExtensions = module.getTransformBoxUIExtensions(elm);
      this.moduleUIExtensions.each( function( extension ) {
        self.extensionsContainer.insert( extension.component );
        if (typeof extension.component.setElement === 'function'){
          extension.component.setElement(elm);
        }
      });
    }
  },

  positionModuleUIExtensions: function() {
    this.moduleUIExtensions.each( function( extension ) {
      if (typeof extension.placement === 'function') {
        extension.placement(this.elm);
      } else {
        extension.component.makeAbsolute();
        extension.component.setOffset(
          this.calculatePositionByPlacement( extension  )
        );
      }
    }, this);
  },

  calculatePositionByPlacement: function( extension ) {
    var placement = extension.placement;
    var placementParts = placement.split( ' ' );
    this.validatePlacement( placementParts );
    var handle = this[ placementParts[ 1 ] ];
    var offset = handle.getOffset();
    var position = offset;
    var location = placementParts[ 0 ];
    var orientation = placementParts[ 1 ].substr( 0, 2 ).toLowerCase();

    var extensionWidth = extension.component.getWidth();
    var extensionHeight = extension.component.getHeight();

    if( orientation === 'bl' && location === 'outside' ) {
      position.top = position.top + 10;
      position.left = position.left + 4;
    } else if( orientation === 'bl' && location === 'inside' ) {
      position.top = position.top - extensionHeight;
      position.left = position.left + 10;
    } else if( orientation === 'tl' && location === 'outside' ) {
      position.top = position.top - extensionHeight;
      position.left = position.left + 5;
    } else if( orientation === 'tl' && location === 'inside' ) {
      position.top = position.top + 10;
      position.left = position.left + 10;
    } else if( orientation === 'br' && location === 'outside' ) {
      position.left = position.left - extensionWidth;
      position.top = position.top + 10;
    } else if( orientation === 'br' && location === 'inside' ) {
      position.top = position.top + 10;
      position.left = position.left - 10;
    } else if( orientation === 'tr' && location === 'inside' ) {
      position.top = position.top + 10;
      position.left = position.left - extensionWidth;
    } else if( orientation === 'tr' && location === 'outside' ) {
      position.top = position.top - extensionHeight;
      position.left = position.left - extensionWidth;
    }

    return position;
  },

  validatePlacement: function( placementParts ) {
    if( placementParts.length < 2 ) {
      throw "Not a valid placement";
    }
  },

  installBoundingBox: function() {
    this.tbndry = this.insert( new Element('div', {className:'transform-boundary top'}));
    this.rbndry = this.insert( new Element('div', {className:'transform-boundary right'}));
    this.bbndry = this.insert( new Element('div', {className:'transform-boundary bottom'}));
    this.lbndry = this.insert( new Element('div', {className:'transform-boundary left'}));
  },

  installHandles: function(){
    var self = this;

    this.tlh = this.insert( new lp.editor.TransformBoxHandle(this, function(p) {self.handleDragTopLeft(p);}, {classNames: ['tlh']}));
    this.trh = this.insert( new lp.editor.TransformBoxHandle(this, function(p) {self.handleDragTopRight(p);}, {classNames: ['trh']}));
    this.blh = this.insert( new lp.editor.TransformBoxHandle(this, function(p) {self.handleDragBottomLeft(p);}, {classNames: ['blh']}));
    this.brh = this.insert( new lp.editor.TransformBoxHandle(this, function(p) {self.handleDragBottomRight(p);}, {classNames: ['brh']}));

    this.lmh = this.insert( new lp.editor.TransformBoxHandle(this, function(p) {self.handleDragLeft(p);}, {classNames: ['lmh']}));
    this.tmh = this.insert( new lp.editor.TransformBoxHandle(this, function(p) {self.handleDragTop(p);}, {classNames: ['tmh']}));
    this.rmh = this.insert( new lp.editor.TransformBoxHandle(this, function(p) {self.handleDragRight(p);}, {classNames: ['rmh']}));
    this.bmh = this.insert( new lp.editor.TransformBoxHandle(this, function(p) {self.handleDragBottom(p);}, {classNames: ['bmh']}));
  },

  handleDragStart: function() {
    var m = this.elm.model;
    var um = this.elm.page.undoManager;

    this.elmStartRootOffset = this.elm.getRootOffset();
    this.elmStartOffset = m.getOffset();
    this.elmStartSize = m.getSize();
    this.elmMinSize = m.getMinSize();
    this.elmMaxSize = m.getMaxSize();

    if (!this.undoRegistered) {
      this.undoRegistered = true;
      um.startGroup();
      um.registerUndo({
        action:m.setOffset,
        receiver:m,
        params:[{left:this.elmStartOffset.left, top:this.elmStartOffset.top}, um]
      });
      um.registerUndo({
        action:m.setSize,
        receiver:m,
        params:[{width:this.elmStartSize.width, height:this.elmStartSize.height}, um]
      });
      um.endGroup();
    }
  },


  /* Description of some of the variable used in the following 8 TransformBoxHandles drag functions
     These methods are called by the corresponding TransformBoxHandles when they are dragged
     o = the offset from the drag start point. e.g. {left:0, top:0}
     w0 = initial width before the start of the drag operation
     h0 = initial height before the start of the drag operation
     * note these last two are just short hand for this.elmStartSize.width and this.elmStartSize.height
     * this.elmStartSize is just set once at the begiing of the drag operation
     w,h,l,t = final values for width, height, top and left after adjustments for minSize, maxSize and isMaintainAR (aspect ratio)
     minSize = element min size
     maxize = element max size
     * also these to are just short hand for the values already obtained at the start of teh drag operation in handleDragStart
     ar = aspect ration
     d = start distance from page root of the corner or side that the handle corresponds to
     p = final point that the corner or side should be moved to (this is adjusted for snap points)
  */

  handleDragTopLeft: function(o) {
    var m = this.elm.model;
    var w0 = this.elmStartSize.width;
    var h0 = this.elmStartSize.height;
    var w,h,l,t;
    var minSize = this.elmMinSize;
    var maxSize = this.elmMaxSize;
    var ar = w0 / h0;

    var d = {left: this.elmStartRootOffset.left, top: this.elmStartRootOffset.top};
    var p = {left: d.left + o.left, top: d.top + o.top};

    this.editor.snapManager.snapPoint(p);

    p.left -= d.left;
    p.top -= d.top;

    if (m.isMaintainAR()) {
      p = jui.Point.intersectionWithSlope(p, w0, h0);
    }

    w = Math.min(maxSize.width, Math.max(minSize.width, w0 - p.left));
    h = Math.min(maxSize.height, Math.max(minSize.height, h0 - p.top));

    if (m.isMaintainAR()) {
      if  (w === maxSize.width || w === minSize.width) {
        h = Math.min(maxSize.height, Math.max(minSize.height, Math.round(w / ar)));
      }

      if  (h === maxSize.height || h === minSize.height) {
        w = Math.min(maxSize.width, Math.max(minSize.width, Math.round(h * ar)));
      }
    }

    l = this.elmStartOffset.left + (w0 - w);
    t = this.elmStartOffset.top + (h0 - h);

    m.suppressEvents();
    m.setSize({width:w, height:h});
    m.setOffset({left:l, top:t});
    m.releaseEvents();
  },

  handleDragTopRight: function(o) {
    var m = this.elm.model;
    var w0 = this.elmStartSize.width;
    var h0 = this.elmStartSize.height;
    var w,h,t;
    var minSize = this.elmMinSize;
    var maxSize = this.elmMaxSize;
    var ar = w0 / h0;

    var d = {left: this.elmStartRootOffset.left + w0, top: this.elmStartRootOffset.top};
    var p = {left: d.left + o.left, top: d.top + o.top};

    this.editor.snapManager.snapPoint(p);

    p.left -= d.left;
    p.top -= d.top;

    if (m.isMaintainAR()) {
      jui.Point.intersectionWithSlope(p, w0, -h0);
    }

    w = Math.min(maxSize.width, Math.max(minSize.width, w0 + p.left));
    h = Math.min(maxSize.height, Math.max(minSize.height, h0 - p.top));

    if (m.isMaintainAR()) {
      if  (w === maxSize.width || w === minSize.width) {
        h = Math.min(maxSize.height, Math.max(minSize.height, Math.round(w / ar)));
      }

      if  (h === maxSize.height || h === minSize.height) {
        w = Math.min(maxSize.width, Math.max(minSize.width, Math.round(h * ar)));
      }
    }

    t = this.elmStartOffset.top + (h0 - h);

    m.suppressEvents();
    m.setSize({width:w, height:h});
    m.setTop(t);
    m.releaseEvents();
  },

  handleDragBottomLeft: function(o) {
    var m = this.elm.model;
    var w0 = this.elmStartSize.width;
    var h0 = this.elmStartSize.height;
    var w,h,l;
    var minSize = this.elmMinSize;
    var maxSize = this.elmMaxSize;
    var ar = w0 / h0;

    var d = {left: this.elmStartRootOffset.left, top: this.elmStartRootOffset.top + h0};
    var p = {left: d.left + o.left, top: d.top + o.top};

    this.editor.snapManager.snapPoint(p);

    p.left -= d.left;
    p.top -= d.top;

    if (m.isMaintainAR()) {
      jui.Point.intersectionWithSlope(p, -w0, h0);
    }

    w = Math.min(maxSize.width, Math.max(minSize.width, w0 - p.left));
    h = Math.min(maxSize.height, Math.max(minSize.height, h0 + p.top));

    if (m.isMaintainAR()) {
      if  (w === maxSize.width || w === minSize.width) {
        h = Math.min(maxSize.height, Math.max(minSize.height, Math.round(w / ar)));
      }

      if  (h === maxSize.height || h === minSize.height) {
        w = Math.min(maxSize.width, Math.max(minSize.width, Math.round(h * ar)));
      }
    }

    l = this.elmStartOffset.left + (w0 - w);

    m.suppressEvents();
    m.setSize({width:w, height:h});
    m.setLeft(l);
    m.releaseEvents();
  },

  handleDragBottomRight: function(o) {
    var m = this.elm.model;
    var w0 = this.elmStartSize.width;
    var h0 = this.elmStartSize.height;
    var w,h;
    var minSize = this.elmMinSize;
    var maxSize = this.elmMaxSize;
    var ar = w0 / h0;

    var d = {left: this.elmStartRootOffset.left + w0, top: this.elmStartRootOffset.top + h0};
    var p = {left: d.left + o.left, top: d.top + o.top};

    this.editor.snapManager.snapPoint(p);

    p.left -= d.left;
    p.top -= d.top;

    if (m.isMaintainAR()) {
      jui.Point.intersectionWithSlope(p, w0, h0);
    }

    w = Math.min(maxSize.width, Math.max(minSize.width, w0 + p.left));
    h = Math.min(maxSize.height, Math.max(minSize.height, h0 + p.top));

    if (m.isMaintainAR()) {
      if  (w === maxSize.width || w === minSize.width) {
        h = Math.min(maxSize.height, Math.max(minSize.height, Math.round(w / ar)));
      }

      if  (h === maxSize.height || h === minSize.height) {
        w = Math.min(maxSize.width, Math.max(minSize.width, Math.round(h * ar)));
      }
    }

    m.suppressEvents();
    m.setSize({width:w, height:h});
    m.releaseEvents();
  },

  handleDragLeft: function(o) {
    var m = this.elm.model;
    var w0 = this.elmStartSize.width;
    var h0 = this.elmStartSize.height;
    var w,h,l;
    var minSize = this.elmMinSize;
    var maxSize = this.elmMaxSize;
    var ar = w0 / h0;

    var d = {left: this.elmStartRootOffset.left, top:0};
    var p = {left: d.left + o.left, top:0};

    this.editor.snapManager.snapPoint(p, {y:false});

    p.left -= d.left;

    w = Math.min(maxSize.width, Math.max(minSize.width, w0 - Math.round(p.left)));
    if (m.isMaintainAR()) {
      h = Math.round(w / ar);
      if (h >= maxSize.height || h <= minSize.height) {
        h = Math.min(maxSize.height, Math.max(minSize.height, h));
        w = Math.min(maxSize.width, Math.max(minSize.width, Math.round(h * ar)));
      }
    } else {
      h = h0;
    }

    l = this.elmStartOffset.left + Math.round((w0 - w));

    m.suppressEvents();
    m.setSize({width:w, height:h});
    m.setLeft(l);
    m.releaseEvents();
  },

  handleDragTop: function(o) {
    var m = this.elm.model;
    var w0 = this.elmStartSize.width;
    var h0 = this.elmStartSize.height;
    var w,h,t;
    var minSize = this.elmMinSize;
    var maxSize = this.elmMaxSize;
    var ar = w0 / h0;

    var d = {left: 0, top: this.elmStartRootOffset.top};
    var p = {left: 0, top: d.top + o.top};

    this.editor.snapManager.snapPoint(p, {x:false});

    p.top -= d.top;

    h = Math.min(maxSize.height, Math.max(minSize.height, h0 - p.top));
    if (m.isMaintainAR()) {
      w = Math.round(h * ar);
      if (w >= maxSize.width || w <= minSize.width) {
        w = Math.min(maxSize.width, Math.max(minSize.width, w));
        h = Math.min(maxSize.height, Math.max(minSize.height, Math.round(w / ar)));
      }
    } else {
      w = w0;
    }

    w = m.isMaintainAR() ? Math.round(h / (h0 / w0)) : w0;
    t = this.elmStartOffset.top + (h0 - h);

    m.suppressEvents();
    m.setSize({width:w, height:h});
    m.setTop(t);
    m.releaseEvents();
  },

  handleDragRight: function(o) {
    var m = this.elm.model;
    var w0 = this.elmStartSize.width;
    var h0 = this.elmStartSize.height;
    var w,h;
    var minSize = this.elmMinSize;
    var maxSize = this.elmMaxSize;
    var ar = w0 / h0;

    var d = {left: this.elmStartRootOffset.left + Math.round(w0), top: 0};
    var p = {left: d.left + o.left, top: 0};

    this.editor.snapManager.snapPoint(p, {y:false});

    p.left -= d.left;

    w = Math.min(maxSize.width, Math.max(minSize.width, w0 + Math.round(p.left)));
    if (m.isMaintainAR()) {
      h = Math.round(w / ar);
      if (h >= maxSize.height || h <= minSize.height) {
        h = Math.min(maxSize.height, Math.max(minSize.height, h));
        w = Math.min(maxSize.width, Math.max(minSize.width, Math.round(h * ar)));
      }
    } else {
      h = h0;
    }

    m.suppressEvents();
    m.setSize({width:w, height:h});
    m.releaseEvents();
  },

  handleDragBottom: function(o) {
    var m = this.elm.model;
    var w0 = this.elmStartSize.width;
    var h0 = this.elmStartSize.height;
    var w,h;
    var minSize = this.elmMinSize;
    var maxSize = this.elmMaxSize;
    var ar = w0 / h0;

    var d = {left: 0, top: this.elmStartRootOffset.top + h0};
    var p = {left: 0, top: d.top + o.top};

    this.editor.snapManager.snapPoint(p, {x:false});

    p.top -= d.top;

    h = Math.min(maxSize.height, Math.max(minSize.height, h0 + p.top));
    if (m.isMaintainAR()) {
      w = Math.round(h * ar);
      if (w >= maxSize.width || w <= minSize.width) {
        w = Math.min(maxSize.width, Math.max(minSize.width, w));
        h = Math.min(maxSize.height, Math.max(minSize.height, Math.round(w / ar)));
      }
    } else {
      w = w0;
    }

    m.suppressEvents();
    m.setSize({width:w, height:h});
    m.releaseEvents();
  },

  installRegistration: function() {
    this.reg = this.insert(
      new jui.Component({attributes:{className:'registration'}})
    );
  },

  elementActivated: function(e) {
    var elm = e.data;
    if (!elm.isVisibleOnPage()){ return; }
    if (elm.type === 'lp-pom-block' || elm.type === 'lp-pom-image'){ return; }
    this.setElement(elm);
    this.installModuleUIExtensions( elm );
    this.positionModuleUIExtensions();
  },

  elementDeactivated: function() {
    this.setElement(null);
  },

  elementMousedown: function(e) {
    // var elm = e.data.source;
    if (this.elm === null ||
        this.elm.type === 'lp-pom-block' ||
        this.elm.type === 'lp-pom-image'){ return; }

    var event = e.data.data;
    if (this.elm.is('offsetable')) {
      jui.dnd.manager.startTracking(this, e.data.data, {threshhold: true});
      this.startParent = this.elm.getParentElement();
      this.startPoint = new jui.Point(
        Event.pointerX(event),Event.pointerY(event)
      );
      this.startOffset = this.elm.model.get('geometry.offset');
      this.setTracking( true );
      this.extensionsContainer.hide();
      this.editor.fireEvent('elementDragStart');
    }
  },

  mouseup:function( e ){
    Event.stop( e );
    this.setTracking( false );
    this.extensionsContainer.show();
    this.positionModuleUIExtensions();
    this.editor.fireEvent('elementDragStop');
  },

  mousemove:function( e ){
    Event.stop( e );
    var m = this.elm.model;

    if (!this.undoRegistered) {
      this.undoRegistered = true;
      var um = this.elm.page.undoManager;

      var previousOffset = m.getOffset();
      var perviousParent = this.elm.parentElement;
      var previousZIndex = this.elm.model.getZIndex();

      var undoMove = function (elm, previousOffset, previousParent, previousZIndex, um) {
        var currentOffset = elm.model.getOffset();
        var currentParent = elm.parentElement;
        var currentZIndex = elm.model.getZIndex();

        um.registerUndo({
          action: undoMove,
          receiver:{},
          params:[elm, currentOffset, currentParent, currentZIndex, um]
        });

        if (currentParent !== previousParent) {
          elm.changeParent(previousParent, previousZIndex);
        }
        m.setOffset({left:previousOffset.left, top:previousOffset.top});
      };

      um.registerUndo({
        action: undoMove,
        receiver:{},
        params:[this.elm, previousOffset, perviousParent, previousZIndex, um]
      });

    }

    this.lastPoint = {left: Event.pointerX(e), top:Event.pointerY(e)};

    var r = new jui.Rectangle(
      Event.pointerX(e),
      Event.pointerY(e),
      this.elm.getContentWidth(),
      this.elm.getContentHeight()).subtract(this.startPoint).add(this.startOffset);

    // editor.snapManager.snapPoint(p);
    editor.snapManager.snapRectangle(r);
    m.setOffset({left:r.left, top:r.top});
    this.fireEvent('move', this.elm);
  },

  setTracking: function( b ) {
    if ( this.tracking === b ) {
      return;
    }

    this.tracking = b;

    if ( b ) {
      $( window.document ).observe( 'mouseup', this.mouseUpListener );
      $( window.document ).observe( 'mousemove', this.mouseMoveListener );
      this.e.addClassName('tracking');
      this.fireEvent('startMove', this.elm);
    } else {
      this.undoRegistered = false;
      $( window.document ).stopObserving( 'mouseup', this.mouseUpListener );
      $( window.document ).stopObserving( 'mousemove', this.mouseMoveListener );
      this.e.removeClassName('tracking');
      this.fireEvent('stopMove', this.elm);
    }
  },

  setElement: function( elm ) {
    if (this.elm !== null) {
      this.removeElementListeners();
    }

    this.elm = elm;

    if (this.elm && this.elm.is('displayable')) {
      this.addElementListeners();
      this.positionBoundingBox();
      this.positionHandles();
      this.positionModuleUIExtensions();
      this.showHandles();
      this.show();
      this.focus();
    } else {
      this.hide();
    }
  },

  addElementListeners: function(){
    this.elm.model.addListener('attributeChanged', this.elmModelListener);
    this.elm.addListener('parentChanged', this.elmModelListener);
  },

  removeElementListeners: function(){
    this.elm.model.removeListener('attributeChanged', this.elmModelListener);
    this.elm.removeListener('parentChanged', this.elmModelListener);
  },

  elementModelChanged: function() {
    this.positionBoundingBox();
    this.positionHandles();
    this.showHandles();
    // this.positionRegistration();
    this.positionGhost();
    this.positionModuleUIExtensions();
  },

  layoutChanged: function() {
    if (this.elm === null || this.elm.type === 'lp-pom-root') {
      return;
    }
    this.positionBoundingBox();
  },

  showHandles: function(){
    var w = this.elm.is('width_resizeable') && this.elm.is('resizeable');
    var h = this.elm.is('height_resizeable') && this.elm.is('resizeable');
    var a = this.elm.is('offsetable');

    this.tlh.setVisible(w&&h&&a);
    this.trh.setVisible(w&&h&&a);
    this.blh.setVisible(w&&h);
    this.brh.setVisible(w&&h);

    this.tmh.setVisible(h&&a);
    this.rmh.setVisible(w);
    this.bmh.setVisible(h);
    this.lmh.setVisible(w);
  },

  positionBoundingBox: function(offset) {
    /* jshint unused:vars */
    // TODO: offset arg is never used. WHY?
    if (this.elm === null) {
      return;
    }

    this.setOffset(this.elm.getPageOffset());

    var w = this.elm.getContentWidth();
    var h = this.elm.getContentHeight();

    this.tbndry.style.width = w+'px';
    this.rbndry.style.height = h+'px';
    this.rbndry.style.left = (w-1)+'px';
    this.bbndry.style.width = (w-1)+'px';
    this.bbndry.style.top = (h-1)+'px';
    this.lbndry.style.height = (h-1)+'px';
  },

  calculateHandlePositions: function() {
    var width = this.elm.getContentWidth();
    var height = this.elm.getContentHeight();
    var offset = this.elm.getOffset();
    var xMiddle = width / 2;
    var yMiddle = height / 2;
    var tc = ( xMiddle + offset.left );
    var lm = ( yMiddle + offset.top );
    return {
      tl: { left: offset.left, top: offset.top },
      tc: { left: tc, top: offset.top },
      tr: { left: (width + offset.left), top: offset.top },
      ml: { left: offset.left, top: lm },
      bl: { left: offset.left, top: height + offset.top },
      bm: { left: tc, top: lm },
      br: { left: ( width + offset.left ), top: height + offset.top },
      mr: { left: ( width + offset.left ), top: ( yMiddle + offset.top )  }
    };
  },

  positionHandles: function() {
    var offsetX = this.handleW / 2;
    var offsetY = this.handleH / 2;
    var w = this.elm.getContentWidth();
    var h = this.elm.getContentHeight();
    var middleX = (w / 2) - offsetX;
    var middleY = (h / 2) - offsetY;

    this.tlh.setOffset({left:-offsetX, top:-offsetY});
    this.trh.setOffset({left:w-offsetX-1, top:-offsetY});
    this.blh.setOffset({left:-offsetX, top:h-offsetY-1});
    this.brh.setOffset({left:w-offsetX-1, top:h-offsetY-1});

    this.tmh.setOffset({left:middleX, top:-offsetY});
    this.rmh.setOffset({left:w-offsetX-1, top:middleY});
    this.bmh.setOffset({left:middleX, top:h-offsetY-1});
    this.lmh.setOffset({left:-offsetX, top:middleY});
  },

  positionRegistration: function() {
    var offsetX = this.handleW / 2;
    var offsetY = this.handleH / 2;
    this.reg.setOffset({left:-offsetX, top:-offsetY});
  },

  nudge: function(vector, shift) {
    if( this.elm && this.elm.is('offsetable')) {
      var magnitude = shift ? 10 : 1;
      vector = vector.multiplyByScalar(magnitude);
      var m = this.elm.model;
      var o = m.get('geometry.offset');

      var um = this.elm.page.undoManager;
      um.registerUndo({
        action: m.setOffset,
        receiver: m,
        params: [{left:o.left, top:o.top}, um]
      });

      m.setOffset({left:o.left+vector.left, top:o.top+vector.top});
    }
  },

  onkeydown: function(e) {
    switch (e.keyCode){
      case Event.KEY_LEFT:
        this.nudge(new jui.Point(-1,0), e.shiftKey);
        e.stop();
        break;
      case Event.KEY_RIGHT:
        this.nudge(new jui.Point(1,0), e.shiftKey);
        e.stop();
        break;
      case Event.KEY_UP:
        this.nudge(new jui.Point(0,-1), e.shiftKey);
        e.stop();
        break;
      case Event.KEY_DOWN:
        this.nudge(new jui.Point(0,1), e.shiftKey);
        e.stop();
        break;
      case Event.KEY_BACKSPACE:
        this.editor.removeElement();
        e.stop();
        break;
      case Event.KEY_DELETE:
        this.editor.removeElement();
        e.stop();
        break;
      default:
        if (e.metaKey || e.ctrlKey) {
          this.enterOptionMode();
        }
    }
  },

  onkeyup: function(e) {
    if (this.optionMode && !e.metaKey) {
      this.exitOptionMode();
    }
  },

  getModel: function() {
    return this.elm.model;
  },

  getStartLeft: function(){
    return this.elm.model.get('geometry.offset.left');
  },

  getStartTop: function(){
    return this.elm.model.get('geometry.offset.top');
  },

  getStartWidth: function(){
    return this.elm.getContentWidth();
  },

  getStartHeight: function(){
    return this.elm.getContentHeight();
  },

  focus: function() {
    if (!this.editor.isTextEditorOpen()){
      this.editor.keyController.requestFocus(this, true);
    }
    this.setOpacity(1);
  },

  blur: function() {
    this.setOpacity(0.5);
  }
});
