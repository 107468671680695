lp.pom.VisibleElementModel = Class.create( lp.pom.ElementModel, {
  initialize: function($super, element, modelData){
    $super(element, modelData);

    if (typeof this.modelData.geometry.visible === 'undefined') {
      this.modelData.geometry.visible = true;
    }

    if (typeof this.modelData.geometry.scale === 'undefined') {
      this.modelData.geometry.scale = 1;
    }

    // JS: if the visible attribute is not defined on the mobile breakpoint it should be set to true so it does not inherit froom default
    if (!jui.isDefined(this.modelData, 'breakpoints.mobile.geometry.visible')) {
      this.modelData.breakpoints.mobile = this.modelData.breakpoints.mobile || {};
      this.modelData.breakpoints.mobile.geometry = this.modelData.breakpoints.mobile.geometry || {};
      this.modelData.breakpoints.mobile.geometry.visible = true;
    }

    this.addBreakpointModifyableAttributes({
      content: {
        html: true
      },
      geometry:{
        offset:{
          left:true,
          top:true
        },
        size:{
          width:true,
          height:true
        },
        contentWidth:true,
        scale:true,
        aspectRatio: true,
        visible:true,
        keepCircular: true,
        padding: {
          left: true,
          right: true,
          top: true
        },
        borderLocation: false,
        borderApply: {
          top: false,
          right: false,
          left: false,
          bottom: false
        },
        cornerRadius: {
          tl: true,
          tr: true,
          bl: true,
          br: true
        }
      },
      style: {
        background: {
          backgroundColor: true,
          savedBackgroundColor: true,
          gradient: {
            from: true,
            to: true
          },
          fillType: true,
          autoGradient: true,
          reverseGradient: true,
          opacity: true,
          //image: {
            //uuid: true,
            //unique_url: true,
            //content_url: true,
            //name: true
          //},
          //backgroundPosition: true,
          //backgroundRepeat: true
        },
        defaults: {
          linkDecoration: true,
          color: true,
          linkColor: true
        },
        border: {
          style: true,
          width: true,
          color: true
        }
      }
    });

    this.addBreakpointAllowDuplicateAttributes({
      style: {
        autoGradient: true,
        background: {
          reverseGradient: true,
        }
      },
      geometry:{
        visible:true,
        keepCircular: true,
        borderApply: {
          top: true,
          right: true,
          bottom: true,
          left: true
        }
      }
    });
  },

  isSettable: function(accessor, value) {
    if(accessor === 'style.border.style') {
      if(typeof value !== 'string') {
        var errMsg ='Attempting to set "' + accessor +
          '" to type "' + typeof value + '": ' + value;
        throw new TypeError(errMsg);
      }
    }
    return true;
  },

  setSize: function setSize(size, undoManager) {
    var self = this;
    this._setSize({
      undoManager: undoManager,
      size: size,
      setSizeFn: function(elSize) {
        self.setOnWritableModel('geometry.size', elSize);
      }
    });
  },

  setSizeByBreakpoint: function(size, breakpoint, undoManager) {
    var self = this;
    this._setSize({
      undoManager: undoManager,
      size: size,
      setSizeFn: function(elSize) {
        self.setOnWritableModelByBreakpoint('geometry.size', elSize, breakpoint);
      }
    });
  },

  _setSize: function(options) {
    // TODO: refactor
    var readable    = this.getReadableModel()
      , previous    = jui.isDefined(readable, 'geometry.size') ? readable.geometry.size : {}
      , undoManager = options.undoManager
      , size        = options.size;


    if (undoManager) {
      undoManager.registerUndo({
        action:this.setSize,
        receiver:this,
        params:[previous, undoManager]
      });
    }

    if(options.setSizeFn) {
      options.setSizeFn(size);
    }

    if ((!Object.isUndefined(previous.width) && previous.width !== size.width) ||
        (!Object.isUndefined(previous.height) && previous.height !== size.height)) {
      this._fireEvent('attributeChanged', {accessor:'geometry.size', value:size, previous:previous});
    }

    if (this.isKeepCircular()) {
      this.makeCircular(undoManager);
    }
  },

  setWidth: function(width, undoManager) {
    //TODO: Refactor
    var readable = this.getReadableModel();
    var previous = jui.isDefined(readable, 'geometry.size') ? readable.geometry.size : {};

    if (undoManager) {
      undoManager.registerUndo({
        action:this.setWidth,
        receiver:this,
        params:[previous.width, undoManager]
      });
    }

    this.setOnWritableModel('geometry.size.width', width);

    if (previous.width !== width) {
      //TODO: should the event receive previous.width?
      this._fireEvent('attributeChanged', {
        accessor:'geometry.size.width',
        value:width,
        previous:previous
      });
    }

    if (this.isKeepCircular()) {
      this.makeCircular(undoManager);
    }
  },

  setHeight: function(height, undoManager) {
    //TODO: Refactor
    var readable = this.getReadableModel();
    var previous = jui.isDefined(readable, 'geometry.size') ? readable.geometry.size : {};

    if (undoManager) {
      undoManager.registerUndo({
        action:this.setHeight,
        receiver:this,
        params:[previous.height, undoManager]
      });
    }

    this.setOnWritableModel('geometry.size.height', height);

    if (previous.height !== height) {
      //TODO: should the event receive previous.height?
      this._fireEvent('attributeChanged', {accessor:'geometry.size.height', value:height, previous:previous});
    }

    if (this.isKeepCircular()) {
      this.makeCircular(undoManager);
    }
  },

  setOffset: function(offset, undoManager) {
    var self = this;
    this._setOffset({
      offset: offset,
      undoManager: undoManager,
      setOffsetFn: function(elOffset) {
        self.setOnWritableModel('geometry.offset', elOffset);
      }
    });
  },

  setOffsetByBreakpoint: function(offset, breakpoint, undoManager) {
    var self = this;
    this._setOffset({
      offset: offset,
      undoManager: undoManager,
      setOffsetFn: function(elOffset) {
        self.setOnWritableModelByBreakpoint('geometry.offset', elOffset, breakpoint);
      }
    });
  },

  _setOffset: function(options) {
    //TODO: Refactor
    var readable    = this.getReadableModel(),
        previous    = jui.isDefined(readable, 'geometry.offset') ? readable.geometry.offset : {},
        offset      = options.offset,
        undoManager = options.undoManager;

    if (undoManager) {
      undoManager.registerUndo({
        action:this.setOffset,
        receiver:this,
        params: [previous, undoManager]
      });
    }

    if(options.setOffsetFn) {
      options.setOffsetFn(offset);
    }

    if ((!Object.isUndefined(previous.left) && previous.left !== offset.left) ||
        (!Object.isUndefined(previous.top) && previous.top !== offset.top)) {
      this._fireEvent('attributeChanged', {accessor:'geometry.offset', value:offset, previous:previous});
    }
  },

  setLeft: function(left, undoManager) {
    //TODO: Refactor
    var readable = this.getReadableModel();
    var previous = jui.isDefined(readable, 'geometry.offset') ? readable.geometry.offset : {};

    if (undoManager) {
      undoManager.registerUndo({
        action:this.setLeft,
        receiver:this,
        params:[previous.left, undoManager]
      });
    }

    this.setOnWritableModel('geometry.offset.left', left);

    if (previous.left !== left) {
      //TODO: should the event receive previous.left?
      this._fireEvent('attributeChanged', {accessor:'geometry.offset.left', value:left, previous:previous});
    }
  },

  setTop: function(top, undoManager) {
    //TODO: Refactor
    var readable = this.getReadableModel();
    var previous = jui.isDefined(readable, 'geometry.offset') ? readable.geometry.offset : {};

    if (undoManager) {
      undoManager.registerUndo({
        action:this.setTop,
        receiver:this,
        params:[previous.top, undoManager]
      });
    }

    this.setOnWritableModel('geometry.offset.top', top);

    if (previous.top !== top) {
      //TODO: should the event receive previous.top?
      this._fireEvent('attributeChanged', {accessor:'geometry.offset.top', value:top, previous:previous});
    }
  },

  setScale: function(scale, undoManager) {
    //TODO: Refactor
    var readable = this.getReadableModel();
    var previous = jui.isDefined(readable, 'geometry.scale') ? readable.geometry.scale : 1;

    if (undoManager) {
      undoManager.registerUndo({
        action:this.setScale,
        receiver:this,
        params:[previous, undoManager]
      });
    }

    this.setOnWritableModel('geometry.scale', scale);

    if (previous !== scale) {
      this._fireEvent('attributeChanged', {accessor:'geometry.scale', value:scale, previous:previous});
    }
  },

  setZIndex: function(zIndex, undoManager) {
    //TODO: Refactor
    var readable = this.getReadableModel();
    //TODO: should zindex be undefined like in the getter?
    var previous = jui.isDefined(readable, 'geometry.zIndex') ? readable.geometry.zIndex : 0;

    if (undoManager) {
      undoManager.registerUndo({
        action:this.setZIndex,
        receiver:this,
        params:[previous, undoManager]
      });
    }

    this.setOnWritableModel('geometry.zIndex', zIndex);

    if (previous !== zIndex) {
      this._fireEvent('attributeChanged', {accessor:'geometry.zIndex', value:zIndex, previous:previous});
    }
  },

  setMaxSize: function(size, undoManager) {
    //TODO: Refactor
    if (Object.isUndefined(this.modelData.geometry)){ return; }

    this.modelData.geometry.maxSize = this.getMaxSize();
    var previous = {};
    previous.width = this.modelData.geometry.maxSize.width;
    previous.height = this.modelData.geometry.maxSize.height;

    if (undoManager) {
      undoManager.registerUndo({
        action:this.setMaxSize,
        receiver:this,
        params:[previous, undoManager]
      });
    }

    this.modelData.geometry.maxSize = size;
    if (previous.width !== size.width || previous.height !== size.height) {
      this._fireEvent('attributeChanged', {accessor:'geometry.maxSize', value:size, previous:previous});
    }
  },

  setMinSize: function(size, undoManager) {
    //TODO: Refactor
    if (Object.isUndefined(this.modelData.geometry)){
      return;
    }
    this.modelData.geometry.minSize = this.getMinSize();
    var previous = {};
    previous.width = this.modelData.geometry.minSize.width;
    previous.height = this.modelData.geometry.minSize.height;

    if (undoManager) {
      undoManager.registerUndo({
        action:this.setMinSize,
        receiver:this,
        params:[previous, undoManager]
      });
    }

    this.modelData.geometry.minSize = size;
    if (previous.width !== size.width || previous.height !== size.height) {
      this._fireEvent('attributeChanged', {accessor:'geometry.minSize', value:size, previous:previous});
    }
  },


  setMargin: function(margin, undoManager) {
    var propertyName = 'geometry.margin';
    var previous     = jui.deepQuery(this.getReadableModel(), propertyName) || {};
    var um           = undoManager || this.element.page.undoManager;

    this.set(propertyName, margin, um);
    this._fireEvent('attributeChanged', {
      accessor : propertyName,
      value    : margin,
      previous : previous
    });
  },

  // JS: Geometry property getters for better performance
  // Code calling these must be sure they exist first
  getSize: function() {
    return this.getReadableModel().geometry.size;
  },

  getMinSize: function() {
    return this.getReadableModel().geometry.minSize || {width:1, height:1};
  },

  getMaxSize: function() {
    return this.getReadableModel().geometry.maxSize || {width:100000, height:100000};
  },

  getWidth: function() {
    return this.getReadableModel().geometry.size.width;
  },

  getHeight: function(breakpoint) {
    return this.getReadableModel(breakpoint).geometry.size.height;
  },

  getOffset: function() {
    return this.getReadableModel().geometry.offset;
  },

  getLeft: function() {
    return this.getReadableModel().geometry.offset.left;
  },

  getTop: function() {
    return this.getReadableModel().geometry.offset.top;
  },

  getScale: function() {
    if(lp.isResponsiveEnabled() && this.exists('geometry.scale')){
      return this.getReadableModel().geometry.scale;
    } else {
      return 1;
    }
  },

  getZIndex: function() {
    return this.exists('geometry.zIndex') ? this.getReadableModel().geometry.zIndex * 1 : undefined;
  },

  getMargin: function() {
    return this.getReadableModel().geometry.margin;
  },

  isMaintainAR: function() {
    return this.exists('geometry.maintainAR') && this.get('geometry.maintainAR');
  },

  isAutoGradient: function() {
    return this.exists('style.background.autoGradient') ? this.get('style.background.autoGradient'): true;
  },

  setAutoGradient: function(auto) {
    //TODO: Refactor
    var um = this.element.page.undoManager;
    um.startGroup();
    this.set('style.background.autoGradient', auto, um);
    if (auto) {
      if (!(this.exists('style.background.auto') && this.get('style.background.auto'))) {
        var gradient = this.calculateGradient(this.get('style.background.backgroundColor'));
        this.setGradientColor(gradient);
      }
    }
    um.endGroup();
  },

  isReverseGradient: function() {
    return this.exists('style.background.reverseGradient') ? this.get('style.background.reverseGradient'): false;
  },

  setBackgroundColor: function(color) {
    //TODO: Refactor
    var bgColPath = 'style.background.backgroundColor';
    var undoManager = this.element.page.undoManager;

    undoManager.startGroup();
    if(color !== 'transparent') {
      this.set('style.background.savedBackgroundColor', color, undoManager);
    }
    this.set(bgColPath, color, undoManager);
    if (this.isAutoGradient()) {
      var gradient = this.calculateGradient(color === 'transparent' ? this.getSavedBackgroundColor() : color);
      this.setGradientColor(gradient);
    }
    undoManager.endGroup();
  },

  getBackgroundColor: function() {
    return this.exists('style.background.backgroundColor') ? this.get('style.background.backgroundColor') : 'transparent';
  },

  getOpacity: function(){
    if(this.getBackgroundColor() === 'transparent') {
      return 0;
    } else {
      return this.exists('style.background.opacity') ? this.get('style.background.opacity') : 100;
    }
  },

  setOpacity: function(value) {
    this.set('style.background.opacity', value, this.element.page.undoManager);
  },

  shouldApplyBackgroundImage: function() {
    //over ride in child class
    return  true;
  },

  getSavedBackgroundColor: function() {
    //TODO: Refactor
    if (this.exists('style.background.savedBackgroundColor')) {
      return this.get('style.background.savedBackgroundColor');
    }

    var defaultsStyle = this.element.getElementDefaults().style;

    if (defaultsStyle && defaultsStyle.background && defaultsStyle.background.backgroundColor) {
      return this.element.getElementDefaults().style.background.backgroundColor;
    }

    return 'fff';
  },

  getNonTransparentBackgroundColor: function() {
    var color = this.getBackgroundColor();
    if (color === 'transparent') {
      color = this.getSavedBackgroundColor();
    }
    return color;
  },

  getGradient: function() {
    //TODO: Refactor
    var g;
    if (this.isAutoGradient() || !this.exists('style.background.gradient')) {
      g = this.calculateGradient(this.getNonTransparentBackgroundColor());
    } else {
      g = this.getCustomGradient();
    }

    if (this.isReverseGradient()) {
      var to = g.to;
      g.to = g.from;
      g.from = to;
    }

    return g;
  },

  getCustomGradient: function() {
    var g = this.get('style.background.gradient');
    return {from:g.from, to:g.to};
  },

  getCurrentFillType: function() {
    return this.exists('style.background.fillType') ? this.get('style.background.fillType') : 'solid';
  },

  setFillType: function(fillType) {
    //TODO: Refactor
    if (fillType === this.getCurrentFillType()) {
      return;
    }

    var undoManager = this.element.page.undoManager;
    undoManager.startGroup();
    if (fillType === 'gradient') {
      this.set('style.background.backgroundColor', this.getSavedBackgroundColor(), undoManager);
    }
    this.set('style.background.fillType', fillType, undoManager);
    undoManager.endGroup();
  },

  setGradientColor: function(color) {
    this.set('style.background.gradient', {
      from: color.from,
      to: color.to
    }, this.element.page.undoManager);
  },

  calculateGradient: function(color) {
    return jui.ColorMath.calculateGradient(color);
  },

  setBorder: function(border, undoManager) {
    //TODO: Refactor
    if (undoManager) {
      undoManager.startGroup();
    }
    this.set('style.border', border.border, undoManager);
    this.set('geometry.borderApply', border.borderApply, undoManager);
    this.set('geometry.borderLocation', border.borderLocation, undoManager);

    if (this.isKeepCircular()) {
      this.makeCircular(undoManager);
    }

    if (undoManager) {
      undoManager.endGroup();
    }
  },

  setCornerRadius: function(radius, undoManager) {
    //TODO: Refactor
    if (this.isKeepCircular() && undoManager) {
      undoManager.startGroup();
    }
    this.set('geometry.cornerRadius', radius, undoManager);

    if (this.isKeepCircular()) {
      this.keepCircular(false, undoManager);
      if (undoManager) {
        undoManager.endGroup();
      }
    }
  },

  isKeepCircular: function() {
    return this.exists('geometry.keepCircular') && this.get('geometry.keepCircular') === true;
  },

  keepCircular: function(keepCircular, um) {
    //TODO: Refactor
    if (keepCircular) {
      if (um) {
        um.startGroup();
      }

      this.set('geometry.keepCircular', true, um);
      this.makeCircular(um);

      if (um) {
        um.endGroup();
      }
    } else {
      this.set('geometry.keepCircular', false, um);
    }
  },

  makeCircular: function(um) {
    //TODO: Refactor
    var size = this.getSize();
    var radius = Math.round(Math.min(size.width, size.height) * 0.5);
    if (this.exists('geometry.borderLocation') && this.get('geometry.borderLocation') === 'outside' &&  this.exists('style.border.width')) {
      radius += this.get('style.border.width');
    }
    this.set('geometry.cornerRadius', radius, um);
  },

  isVisible: function() {
    return this.exists('geometry.visible') ? this.get('geometry.visible') : true;
  },

  setVisible: function(visible, um) {
    this.set('geometry.visible', visible, um);
  },

  setLayout: function(layout, undoManager) {
    //TODO: Refactor
    var readable = this.getReadableModel();
    var previous = jui.isDefined(readable, 'geometry.layout') ? readable.geometry.layout : {};

    if (undoManager) {
      undoManager.registerUndo({
        action:this.setLayout,
        receiver:this,
        params:[previous, undoManager]
      });
    }

    this.setOnWritableModel('geometry.layout', layout);
    this._fireEvent('attributeChanged', {accessor:'geometry.layout', value:layout, previous:previous});
  },

  getLayout: function() {
    return this.getReadableModel().geometry.layout;
  }

});
