lp.ModuleElementPropertiesPanelMethods = {
  hasPropertiesPanel: true,

  getElementPropertiesPanelClass: function() {
    return this.namespace[this.getElementPropertiesPanelClassName()];
  },

  getElementPropertiesPanelClassName: function() {
    return this.elementPropertiesPanelClassName ?
           this.elementPropertiesPanelClassName :
           this.name.replace(/ /g,'') + 'ElementPropertiesPanel';
  },

  getElementPropertiesPanel: function() {
    this.elementPropertiesPanel = this.elementPropertiesPanel || new (this.getElementPropertiesPanelClass())();
    return this.elementPropertiesPanel;
  },

  // Depracated methods:

  getPropertiesPanel: function() {
    return this.getElementPropertiesPanel();
  }
};
