//  require <jui>
jui.FormLinkInput2 = Class.create( jui.Component, jui.ControlModel, {
  options: function($super,options){
    return $super($H({
      attributes:{className:'form-elm link-input2'},
      label:'Link'
    }).merge(options));

  },

  initialize:function( $super, options ){
    $super( options );
    if (this.options.onkeydown){
      this.addListener('keydown', this.options.onkeydown);
    }
    if (this.options.onkeyup){ this.addListener('keyup', this.options.onkeyup);}
    if (this.options.onfocus){ this.addListener('focus', this.options.onfocus);}
    if (this.options.onblur){ this.addListener('blur', this.options.onblur);}
  },

  installUI: function( $super ) {
    $super();
    var self = this;

    this.label = this.insert( new Element('label').update(this.options.label));
    this.link = this.insert(new Element('input', {
      type:'text',
      className:'text'
    }));

    if(this.options.passparams) {
      this.appendParameters = this.insert(new jui.FormCheckboxInput({
        label: 'Pass through URL Parameters',
        attributes: {
          className: 'form-elm checkbox-input append-params'
        },
        onclick: function(e) {
          if(self.options.onPassParams) {
            self.options.onPassParams(e);
          }
        }
      }));
    }

    if (!this.options.hideTarget) {
      this.target = this.insert(new jui.FormSelect({
        label:'Target',
        id: 'jui-form-target-select',
        dropDownClassName : 'editor-info-panel-select jui-drop-down',
        width: '170px',
        selectOptions:[{name:'Not Set',value:''},
                        {name:'New Window (_blank)',value:'_blank'},
                        {name:'Top Most Window (_top)',value:'_top'},
                        {name:'Same Window (_self)',value:'_self'},
                        {name:'Parent Window (_parent)',value:'_parent'}],
        action: this.onBlur.bind(this)
      }));
    }


    if (this.options.inputPosition) {
      this.input.addClassName(this.options.inputPosition);
    }

    this.link.observe('focus', this.onFocus.bind(this));
    this.link.observe('blur', this.onBlur.bind(this));
    if (this.options.onkeyup){
      this.link.observe('keyup', this.onkeyup.bind(this));
    }
    if (this.options.onkeydown){
      this.link.observe('keydown', this.onkeydown.bind(this));
    }
  },

  setValue: function(value) {
    this.link.value = this.addProtocol(value.url);
    this.setAppendParamsValue(value.passparams);
    if (this.target) {
      this.target.setSelectedValue(value.target);
      this.target.setEnabled(value.url !== '');
    }
  },

  setAppendParamsValue: function(value) {
    if(this.options.passparams) {
      this.appendParameters.setValue(value);
    }
  },

  addProtocol: function(url) {
    /* jshint scripturl:true */
    if (url.startsWith('mailto:') ||
        url.startsWith('tel:') ||
        url.startsWith('sms:') ||
        url.startsWith('javascript:') ||
        url.startsWith('#') ||
        url.include('://') ||
        url === '' ||
        url === null ||
        Object.isUndefined(url)) {
      return url || '';
    }
    return 'http://'+url;
  },

  getPassParamsValue: function() {
    if(this.options.passparams) {
      return this.appendParameters.getValue();
    }
  },

  getValue: function() {
    return {
      url:this.addProtocol( this.link.getValue().strip() ),
      target:this.target ? this.target.getValue() : '',
      passparams: this.getPassParamsValue()
    };
  },

  onFocus: function( e ) {
    if( this.isEnabled() ) {
      e.stop();
      this.fireEvent('focus', this);
    }
  },

  onkeydown: function(e) {
    switch (e.keyCode){
      case Event.KEY_RETURN:
        this.blur();
        e.stop();
        break;
    }
    this.fireEvent('keydown', this);
  },

  onkeyup: function() {
    if (this.target) {
      this.target.setEnabled(this.link.value !== '');
    }
    this.fireEvent('keyup', this);
  },

  onBlur: function() {
    // this.setValue(this.link.getValue());
    this.fireEvent('blur', this);
  },

  blur: function() {
    this.link.blur();
  }

});
