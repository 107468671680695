lp.module.socialWidget = {};

lp.module.socialWidget.SocialWidgetModule = Class.create(
  lp.ModuleBase,
  lp.ModuleElementMethods,
  lp.ModuleElementPropertiesPanelMethods,
  {
    //Restrict the model size based off of the size and orientation of the widget.
    restrictModelToSize: function(model, size, orientation) {
      model.setMinSize(size);
      if('horizontal' === orientation) {
        model.setMaxSize({ width: 10000, height: size.height });
      } else {
        model.setMaxSize({ width: size.width, height: 10000 });
      }

    },

    //Returns the index of a widget button by its name based
    //off of the current widget state.
    getWidgetIndexByName: function(widgets, name) {
      var index = -1;
      widgets.each(function(widget, i) {
        if(widget.name === name) { index = i; }
      });

      return index;
    },

    getDefaultIndexByWidget: function(widgetName) {
      var index   = -1;

      this.widgetDefaults.buttons.each(function(button, i) {
        if(button.name === widgetName) {
          index = i;
          return;
        }
      });
      return index;
    },

    tweetMessageInstructionText: 'Use a Custom Message...'
  }
);


lp.addModule(new lp.module.socialWidget.SocialWidgetModule(
  {
    type:'lp-pom-social-widget',
    version:'1.0',
    name: 'Social Widget',
    usageType:'page-widget',
    namespace: lp.module.socialWidget,
    widgetDefaults: {
      orientation: 'vertical',
      countOrientation: 'top',
      disabledWidgets: [],
      isActive: false,
      buttons: [
        {
          name: 'twitter',
          usePageTitleForTweets: true,
          tweet: "",
          isCustomURL: false,
          url: ''
        },
        {
          name: 'google',
          isCustomURL: false,
          url: ''
        },
        {
          name: 'facebook',
          verb: 'like',
          color: 'light',
          isCustomURL: false,
          url: window.location.href
        }
      ]
    }

  }

));
