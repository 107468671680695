lp.module.multiSelect.MultiSelectElement = Class.create(
  lp.pom.VisibleElement,
  lp.ModuleComponent,
  {
    type:'lp-multi-select',

    initialize: function($super, page, jso) {
      $super(page, jso);
      this.selection = [];
      this.offsets = [];
      this.selectionBoxes = [];
      this.updatingModel = false;
      this.e = new Element('div');

      var self = this;
      this.model.getZIndex = function() {
        return self.selection.collect(function(elm) {return elm.model.getZIndex();});
      };
    },
    isVisibleOnPage: function() {
      return true;
    },
    createDefaultConstraints: function($super) {
      $super();
      this.defaultConstraints.width_resizable = false;
      this.defaultConstraints.height_resizable = false;
      this.defaultConstraints.resizeable = false;
      this.defaultConstraints.z_indexable = false;
    },

    addToSelection: function(elm) {
      if (this.isInSelection(elm)) {
        return this;
      }

      this.selection.push(elm);
      this.selectionBoxes.push(new lp.editor.SelectionBox(elm, window.editor.canvasBody));
      this.fitToSelection();
      this.updateOffsets();
      var hasNonCopyableElement = this.selection.find(function(item) {return !item.isCopyable();});

      if(hasNonCopyableElement) {
        this.model.set('constraints.copyable', false);
      }

      return this;
    },

    removeFromSelection: function(elm) {
      if (!this.isInSelection(elm)) {
        return this;
      }

      this.selection = this.selection.without(elm);
      var box = this.selectionBoxes.find(function(b) { return b.elm === elm;});
      this.selectionBoxes = this.selectionBoxes.without(box);
      box.remove();

      var hasNonCopyableElement = this.selection.find(function(item) {return !item.isCopyable();});

      if(!hasNonCopyableElement) {
        this.model.set('constraints.copyable', true);
      }

      if (this.selection.length === 1) {
        this.selectionBoxes[0].remove();
        var lastElm = this.selection[0];
        this.selection = [];
        this.selectionBoxes = [];
        this.offset = [];
        return lastElm;
      } else {
        this.fitToSelection();
        this.updateOffsets();
        return this;
      }
    },

    getElements: function() {
      return this.selection;
    },

    getGhost: function() {
      var ghost = new Element('div');
      ghost.style.position = 'absolute';
      
      var len = this.selection.length, 
          clone,
          i;

      for (i=0; i < len; i++) {
        clone = this.selection[i].getViewDOMElement().cloneNode(true);
        clone.style.left = (this.offsets[i].left) + 'px';
        clone.style.top = (this.offsets[i].top) + 'px';
        ghost.insert(clone);
        clone.addClassName('dnd-ghost');
      }

      return ghost;
    },

    isInSelection: function(elm) {
      return this.selection.indexOf(elm) > -1;
    },

    containsElement: function(elm) {
      if (this.isInSelection(elm)) {
        return true;
      }
      for (var i=0,len=this.selection.length;i<len;i++) {
        if (this.selection[i].containsElement(elm)) {
          return true;
        }
      }

      return false;
    },

    fitToSelection: function() {
      var t, b, l, r;
      l = t = Math.pow(2,16); // reasonably large number
      r = b = -Math.pow(2,16); // reasonably small number

      for (var i=0,len=this.selection.length,elm,o,s;i<len;i++) {
        elm = this.selection[i];
        o = elm.model.getOffset();

        s = {w: elm.getContentWidth(), h: elm.getContentHeight()};
        l = Math.min(l, o.left);
        t = Math.min(t, o.top);
        r = Math.max(r, o.left + s.w);
        b = Math.max(b, o.top + s.h);
      }

      this.updatingModel = true;
      this.model.setOffset({left:l, top:t});
      this.model.setSize({width:r-l, height:b-t});
      this.updatingModel = false;
    },

    updateOffsets: function() {
      var o1 = this.model.getOffset();
      var o2 = this.getParentElement().getRootOffset();
      var offset = {left:o1.left + o2.left, top:o1.top + o2.top};

      this.offsets = [];

      for (var i=0,len=this.selection.length,elm,o;i<len;i++) {
        elm = this.selection[i];
        o = elm.getRootOffset();
        this.offsets.push({left:o.left - offset.left, top:o.top - offset.top});
      }
    },

    modelChanged: function(e) {
      if (this.updatingModel) {
        return;
      }
      var accessor = e.data.accessor;
      var value = e.data.value;

      if(accessor.startsWith('constraints')) {
        this.fireEvent('elementConstraintsChanged');
      }

      if (accessor === 'geometry.offset') {
        for (var i=0,len=this.selection.length,elm,o;i<len;i++) {
          elm = this.selection[i];
          o = this.offsets[i];
          elm.model.setOffset({left:value.left + o.left, top:value.top + o.top});
        }
      }
    },

    changeParent: function(newParent, zIndex) {
      this.parentElement = newParent;
      for (var i=0, zi,len=this.selection.length,elm;i<len;i++) {
        elm = this.selection[i];
        if (Object.isArray(zIndex)) {
          zi = zIndex[i];
        }
        elm.changeParent(newParent, zi);
      }
    },

    removeFromPage: function() {
      for (var i=0,len=this.selection.length;i<len;i++) {
        this.selection[i].removeFromPage();
      }
      this.page.removeElement(this, this.getParentElement());
    },

    activate: function() {},

    deactivate: function() {
      this.selectionBoxes.each(function(b) {
        b.remove();
      });
    },

    hide: function() {
      this.selection.invoke('hide');
    },

    show: function() {
      this.selection.invoke('show');
    },

    hasAncestor: function() {
      //console.log('hasAncestor');
    },

    getRootOffset: function() {
      var m = this.model;
      var mo = m.getOffset();
      var o = {left:mo.left, top:mo.top};

      return o;
    },

    getPageOffset: function() {
      var m = this.model;
      var mo = m.getOffset();
      var o = {left:mo.left, top:mo.top};

      if (this.parentElement) {
        jui.Point.add(o, this.parentElement.getPageOffset());
      }

      return o;
    },

    getOuterBorderOffsetAdjust: function() {
      return {left:0, top:0};
    },

    getContentWidth: function() {
      return this.model.getWidth();
    },

    getContentHeight: function() {
      return this.model.getHeight();
    },

    cumulativeOffset: function() {
      var offset = this.getRootOffset();
      var root = this.page.getRootElement().cumulativeOffset();

      return {left:offset.left + root.left, top:offset.top + root.top};
    },

    clone: function() {
      var offset = this.model.getOffset();
      var clone = lp.getModule('lp-multi-select').createElement(this.page,
        {
          parentElement:this.getParentElement(),
          offset:{left:offset.left, top:offset.top},
          size:{width:0, height:0}
        }
      );

      for (var i=0, len=this.selection.length; i<len; i++) {
        clone.addToSelection(this.selection[i].clone());
      }

      return clone;
    }
  }
);

lp.module.multiSelect.MultiSelectElement.elementDefaults = {};
