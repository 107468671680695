lp.pom.PageStyle = Class.create(jui.EventSource, {
  initialize: function(page){
    this.page = page;
    this.clearCSSRules();
    this.id = 'page-style-'+page.id;
    this.type = 'lp-pom-page-style';
    this.name = 'Page Settings';
    this.page.addListener('breakpointAdded', this);
  },

  clearCSSRules: function() {
    this.rules = {};

    this.page.breakpoints.each( function(breakpoint){
      this.rules[breakpoint.name] = {};
    }, this);
  },

  isNonDefaultBreakpointVisible: function() {
    return this.page.settings.multipleBreakpointsVisibility;
  },

  buildStylesForPublish: function(breakpoint) {
    if (this.page.isFormConfirmation()) {
      return this.buildFormConformationStyles(breakpoint);
    } else {
      return this.buildMainPageStyles(breakpoint);
    }
  },

  _buildStylesForPublish: function(breakpoint) {
    var originalBreakpoint = this.page.getCurrentBreakpoint();
    try {
      // do not use switchToBreakpoint!
      this.page.currentBreakpoint = breakpoint;
      var cssText    = this.getCssRules()
        , mediaQuery = '@media only screen and (max-width: ' + breakpoint.width + 'px) ' + '{\n' + cssText + '\n}';
      return '\n' + (breakpoint.default ? cssText : mediaQuery) + '\n';
    } finally {
      this.page.currentBreakpoint = originalBreakpoint;
    }
  },

  buildMainPageStyles: function(breakpoint) {
    if (!breakpoint.default && !this.isNonDefaultBreakpointVisible()) {
      // no-op for mobile unless enabled.
      return;
    }
    return this._buildStylesForPublish(breakpoint);
  },

  buildFormConformationStyles: function(breakpoint) {
    return this._buildStylesForPublish(breakpoint);
  },

  createAndGetStyleNode: function(breakpointName) {
    var pageStyleType = this.page.usedAs() + '_' + breakpointName;
    $$('style[title=page-styles]').each(function (node) {
      if (node.getAttribute('data-page-type') === pageStyleType) {
        node.remove();
      }});
    return new Element('style', {title: 'page-styles'
                                 , type:'text/css'
                                 , "data-page-type": pageStyleType});
  },

  injectStyles: function(cssText, breakpointName) {
    var cssNode = this.createAndGetStyleNode(breakpointName);
    cssNode.appendChild(document.createTextNode(cssText));
    document.getElementsByTagName("head")[0].appendChild(cssNode);
    return cssNode;
  },

  createStyleSheets: function() {
    if (this.page.context === lp.pom.context.EDIT) {
      return this.injectStyles(this.getCssRules(), 'page');
    } else if (!this.isNonDefaultBreakpointVisible()) {
      var breakpoint = this.page.breakpoints.find(function(b){ return ( !!b.default ); });
      return this.injectStyles(this.buildStylesForPublish(breakpoint), breakpoint.name);
    } else {
      return this.page.breakpoints.map(function(breakpoint){
        return this.injectStyles(this.buildStylesForPublish(breakpoint), breakpoint.name);
      }, this);
    }
  },

  backgroundRule: function(rule, key) {
    var css = '\n';
    rule.each(function(bg){
      css += ' ' + key + ':' + bg + ';\n';
    });
    return css;
  },

  cssRule: function(rule, key) {
    return ' ' + key + ':' + rule[key] + ';\n';
  },

  getRule: function(rule) {
    var key;
    var css = '\n';
    for(key in rule) {
      if((this[key] === 0 || key === 'selector')) {
        continue;
      } else {
        if(key !== 'background') {
          css += this.cssRule(rule, key);
        } else {
          css += this.backgroundRule(rule[key], key);
        }
      }
    }
    return css;
  },

  isDuplicateRule: function(rule) {
    var key            = this._createKeyFromSelector(rule.selector)
      , duplicate      = this.getCurrentRules()[key]
      , attribute      = rule.attribute
      , isBackground   = attribute === 'background'
      , ruleExists     = duplicate && duplicate[attribute]
      , value          = rule.value;

    if ( !ruleExists ) { return false; }

    var isDuplicateRule      = duplicate[attribute] === value,
        isDupeBackgroundRule = isBackground && duplicate.background.indexOf(value) > -1;

    return (isDupeBackgroundRule || isDuplicateRule) ;
  },

  getCssRules: function() {
    return this.buildCSSRules(this.getCurrentRules());
  },

  buildCSSRules: function(rules) {

    var _getEditModeOverride = function(rule) {

      var isFormConfirmation = (this.page.usedAs() === 'form_confirmation')
        , newRule            = rule;

      if (rule.selector === 'a' && isFormConfirmation) {
        newRule.selector = '.modal a';
      } else if (rule.selector === 'body') {
        newRule.selector = (isFormConfirmation ? '.modal ' : '') + '.lp-pom-root';
      }

      return newRule;
    };

    return $H(rules).map(function(item) {
      var rule  = item.value
        , newRule = this.page.context === lp.pom.context.EDIT ? _getEditModeOverride.apply(this, [rule]) : rule;
      return newRule.selector + ' {' + this.getRule(newRule) + '}';
    }, this).join('\n');

  },

  updatePageStyles: function() {
    this.createStyleSheets();
    if(this.page.usedAs() === 'form_confirmation' && this.isNonDefaultBreakpointVisible()) {
      this.buildMobileBreakpointJavascriptForFormConfirmation();
    }
  },

  buildMobileBreakpointJavascriptForFormConfirmation: function() {
    if(this.page.context !== lp.pom.context.EDIT) {
      if(document.getElementById('UB-mobile-breakpoint-toggle')) {return;}
      document.body.appendChild(this.getUbMobileScript(this.buildMobileBreakpointCode()));
    }
  },

  getUbMobileScript: function(code) {
    var script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.setAttribute("id", "UB-mobile-breakpoint-toggle");
    script.appendChild(document.createTextNode(code));
    return script;
  },

  buildMobileBreakpointCode: function() {
    return "\n" +
      "var toggleMobile = function(isMobile) {\n" +
      "var breakpointStyleNode = lp.jQuery('style[data-page-type=\"form_confirmation_mobile\"]');\n" +
      "  if (isMobile) {\n" +
      "    breakpointStyleNode.removeAttr('disabled');\n" +
      "  } else {\n" +
      "    breakpointStyleNode.attr('disabled', 'disabled');\n" +
      "  }\n" +
      "};\n" +
      "var mq = window.parent.matchMedia('(max-width: 600px)');\n" +
      "toggleMobile(mq.matches);\n" +
      "mq.addListener(function(changed){\n" +
      "  toggleMobile(changed.matches);\n" +
      "});\n";
  },

  breakpointAdded: function(e) {
    this.rules[e.data] = {};
  },

  getCurrentRules: function() {
    return this.rules[this.page.getCurrentBreakpoint().name];
  },

  getCSSRules: function(selector, rules) {
    rules = rules || this.getCurrentRules();
    var foundRules = [];
    rules.each(function(s){
      if(s[1].selector.strip() === selector) {
        foundRules.push(s[1]);
      }
    }, this);
    return foundRules;
  },

  _createKeyFromSelector: function(selector) {
    return selector.trim().replace(/[ \-,#]/g,'');
    // NOTE: the code used to do
    //return selector.replace(/\W/g, '');
    // not sure why but see LP-6413 for the damage it caused
    // leaving this note here till LP-6413 is fully resolved
  },

  _getCSSRule: function(rule) {
    return this.getCurrentRules()[this._createKeyFromSelector(rule.selector)];
  },

  setCSSRule: function(rule) {
    if (this.page.context !== lp.pom.context.EDIT && this.isDuplicateRule(rule)){
      return;
    }

    var key     = this._createKeyFromSelector(rule.selector),
        rules   = this.getCurrentRules(),
        cssRule = rules[key];

    if(typeof cssRule === 'undefined') {
      cssRule = rules[key] = {};
      cssRule.selector = rule.selector;
    }

    if(rule.attribute !== 'background') {
      cssRule[rule.attribute] = rule.value;
    } else {

      if(typeof cssRule.background === 'undefined') {
        cssRule.background = [];
      }

      cssRule.background.push(rule.value);
    }
  },

  removeCSSRule: function(rule){
    var r = this._getCSSRule(rule);
    if (Object.isUndefined(r)) {
      return;
    }
    var key = this._createKeyFromSelector(rule.selector);
    this.getCurrentRules().unset(key);
  },

  removeCSSRules: function(selector){
    var key;
    for(key in this.getCurrentRules()) {
      if(this[key] !== 0) {
        if(this.getCurrentRules()[key].selector.startsWith(selector)) {
          delete this.getCurrentRules()[key];
        }
      }
    }
  }

});
