lp.editor.OffsetIndicators = Class.create( jui.Component, {
  initialize: function($super, transformBox){
    $super();
    transformBox.addListener('startMove', this);
    transformBox.addListener('move', this);
    transformBox.addListener('stopMove', this);
    transformBox.addListener('parentChanged', this);
  },

  installUI: function( $super, options ) {
    $super(options);
    this.offsetParent = null;

    this.lbndry = this.insert( new Element('div', {className: 'offset-indicator-boundary'}));
    this.tbndry = this.insert( new Element('div', {className: 'offset-indicator-boundary'}));
    this.rbndry = this.insert( new Element('div', {className: 'offset-indicator-boundary'}));
    this.bbndry = this.insert( new Element('div', {className: 'offset-indicator-boundary'}));

    this.lvector = this.insert( new Element('div', {className: 'offset-indicator'}));
    this.tvector = this.insert( new Element('div', {className: 'offset-indicator'}));

    this.lextend = this.insert( new Element('div', {className: 'offset-indicator'}));
    this.textend = this.insert( new Element('div', {className: 'offset-indicator'}));

    this.origin = this.insert( new Element('div', {className: 'offset-indicator'}));

    this.lvectorlarrow = new Element('img', {className: 'offset-indicator', src:"/images/ui/editor/icon-vector-arrow-left.gif"});
    this.lvectorrarrow = new Element('img', {className: 'offset-indicator', src:"/images/ui/editor/icon-vector-arrow-right.gif"});

    this.tvectortarrow = new Element('img', {className: 'offset-indicator', src:"/images/ui/editor/icon-vector-arrow-up.gif"});
    this.tvectorbarrow = new Element('img', {className: 'offset-indicator', src:"/images/ui/editor/icon-vector-arrow-down.gif"});

    this.lvector.insert(this.lvectorlarrow);
    this.lvector.insert(this.lvectorrarrow);

    this.tvector.insert(this.tvectortarrow);
    this.tvector.insert(this.tvectorbarrow);

    // this.lout = new Element('div', {className: 'offset-output'});
    // this.tout = new Element('div', {className: 'offset-output'});

    this.lvector.insert(this.lout);
    this.tvector.insert(this.tout);

    this.installCSS();
  },

  startMove: function(e) {
    this.show();
    this.offsetParent = e.data.parentElement;
    this.positionBoundries(this.offsetParent.model.getSize());
    this.positionVectors(e.data);
    // this.updateOutputs(e.data);
  },

  move: function(e) {
    this.positionVectors(e.data);
    // this.updateOutputs(e.data);
  },

  stopMove: function() {
    this.hide();
  },

  parentChanged: function(e) {
    this.offsetParent = e.data.parentElement;
    this.positionBoundries(this.offsetParent.model.getSize());
    this.positionVectors(e.data);
  },

  positionBoundries: function(size) {
    this.setOffset(this.offsetParent.getPageOffset());

    this.tbndry.style.width =  size.width + 'px';
    this.rbndry.style.height = size.height + 'px';
    this.rbndry.style.left = (size.width - 1) + 'px';
    this.bbndry.style.width = (size.width - 1) + 'px';
    this.bbndry.style.top = (size.height - 1) + 'px';
    this.lbndry.style.height = (size.height - 1)+'px';
  },

  positionVectors: function(elm) {
    var offset = elm.model.getOffset();

    var l = offset.left;
    var t = offset.top;
    var lp = l >= 0;
    var tp = t >= 0;

    this.lvector.style.width = Math.abs(l) + 'px';
    this.lvector.style.left = lp ? '0px' : l + 'px';

    this.tvector.style.height = Math.abs(t) + 'px';
    this.tvector.style.top = tp ? '0px' : t + 'px';

    this.lextend.style.height = (Math.abs(t) + 3) + 'px';
    this.lextend.style.top = tp ? '-3px' : t + 'px';
    this.lextend.style.left = l + 'px';

    this.textend.style.width = (Math.abs(l) + 3) + 'px';
    this.textend.style.left = lp ? '-3px' : l + 'px';
    this.textend.style.top = t + 'px';

    this.lvectorlarrow.style.display = lp ? 'none' : 'block';
    this.lvectorrarrow.style.display = lp ? 'block' : 'none';
    this.tvectortarrow.style.display = tp ? 'none' : 'block';
    this.tvectorbarrow.style.display = tp ? 'block' : 'none';
  },

  updateOutputs: function(elm) {
    var offset = elm.model.getOffset();

    this.lout.update(offset.left || '');
    this.tout.update(offset.top || '');
  },

  installCSS: function() {
    /* JS: using javascript to assign styles increases performance over using css selectors plus it's easier to find*/
    this.e.style.position = 'absolute';
    this.e.style.zIndex = 10003;

    this.lbndry.style.backgroundColor =
    this.tbndry.style.backgroundColor =
    this.rbndry.style.backgroundColor =
    this.bbndry.style.backgroundColor = '#0080ff';

    // this.lbndry.style.backgroundColor =
    // this.tbndry.style.backgroundColor =
    // this.rbndry.style.backgroundColor =
    // this.bbndry.style.backgroundColor = '#ffe400';

    // ff8800
    // 00a8ff

    this.lbndry.style.position =
    this.tbndry.style.position =
    this.rbndry.style.position =
    this.bbndry.style.position = 'absolute';

    this.lbndry.style.width =
    this.rbndry.style.width = '1px';
    this.tbndry.style.height =
    this.bbndry.style.height = '1px';

    // this.lbndry.style.left =
    // this.tbndry.style.width = '-1px';

    this.lextend.style.position =
    this.textend.style.position = 'absolute';

    this.lextend.style.borderColor =
    this.textend.style.borderColor = '#ff5500';

    this.lextend.style.borderLeftWidth =
    this.textend.style.borderTopWidth = '1px';

    this.lextend.style.borderLeftStyle =
    this.textend.style.borderTopStyle = 'dashed';

    this.lextend.style.width = '0px';
    this.textend.style.height = '0px';

    this.lvector.style.backgroundColor =
    this.tvector.style.backgroundColor = '#ff5500';

    this.lvector.style.position =
    this.tvector.style.position = 'absolute';

    this.lvector.style.height = '1px';
    this.tvector.style.width = '1px';

    this.lvectorlarrow.style.position =
    this.lvectorrarrow.style.position = 'absolute';
    this.lvectorlarrow.style.left =
    this.lvectorrarrow.style.right = '0px';
    this.lvectorlarrow.style.top =
    this.lvectorrarrow.style.top = '-3px';
    this.lvectorlarrow.style.width =
    this.lvectorrarrow.style.width = '4px';
    this.lvectorlarrow.style.height =
    this.lvectorrarrow.style.height = '7px';

    this.tvectortarrow.style.position =
    this.tvectorbarrow.style.position = 'absolute';
    this.tvectortarrow.style.top =
    this.tvectorbarrow.style.bottom = '0px';
    this.tvectortarrow.style.left =
    this.tvectorbarrow.style.left = '-3px';
    this.tvectortarrow.style.width =
    this.tvectorbarrow.style.width = '7px';
    this.tvectortarrow.style.height =
    this.tvectorbarrow.style.height = '4px';

    this.origin.style.position = 'absolute';
    this.origin.style.left =
    this.origin.style.top = '-2px';
    this.origin.style.width =
    this.origin.style.height = '5px';
    this.origin.style.backgroundColor = '#dd5500';

    // this.lout.style.right = '6px';
    // this.lout.style.bottom = '6px';
    //
    // this.tout.style.left = '6px';
    // this.tout.style.bottom = '6px';
  }
});
