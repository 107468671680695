lp.editor.AssetBrowser = Class.create(jui.TabView, {
  options: function($super,options){
    return $super($H({
      classNames:['asset-browser'],
      viewType: 'grid',
      selectedTab: 'images',
      assetTypes: {
        images: [ "image" ],
        documents: [ "application", "text" ],
        media: [ "video", "audio" ]
      }
    }).merge(options));
  },

  initialize: function($super, options){
    $super(options);
    this.assetsLoaded = false;
    this.openAssetsCompanyId = window.editor.currentCompanyId;
    this.viewType = this.options.viewType;
    this.e.addClassName(this.viewType);
    this.assets = [];
    this.loadCompleteCallback = null;
  },

  installUI: function($super) {
    $super();

    var self = this;
    var settingsBar = this.insert(new jui.Component({attributes:{className:'settings-bar'}}));

    this.deleteButton = settingsBar.insert( new jui.Button({ classNames:['delete'],toolTip: "Delete selected assets", action: this.deleteSelectedAssets.bind(this) } ));
    this.deleteButton.disable();

    settingsBar.insert(new Element('div',{className:'spacer'}));

    var g = new jui.RadioButtonGroup();
    settingsBar.insert( new jui.RadioButton({ classNames:['list'], radioGroup: g, toolTip: "List view", action: this.setViewType.bind( this, 'list') } ));
    var b2 = settingsBar.insert(
        new jui.RadioButton({ classNames:['grid'], radioGroup: g,
                              toolTip: "Grid view", action: this.setViewType.bind( this, 'grid') } ));
    g.setActiveButton(b2);

    $H(this.options.assetTypes).keys().each(function(k) {
      var view = new lp.editor.AssetView();
      this.addAssetViewListeners(view);
      this.addTabViewItem({
        id: k,
        label: k.capitalize(),
        view: view
        // select: selectHandler
      });

    }, this);

    var view = new lp.editor.AssetView({contextSwitcherContainer:this.options.contextSwitcherContainer});
    this.addAssetViewListeners(view);
    this.addTabViewItem({
      id: 'other',
      label: 'Other',
      view: view
      // select: selectHandler
    });

    this.deleteDialog = new jui.ModalSheet();
    this.deleteDialogMessage = this.deleteDialog.insertContent(new jui.Component({classNames:['warn']}));
    this.deleteDialogMessage.setWidth(420);
    this.deleteDialogMessage.setStyle({
      margin:'12px 8px 8px 12px',
      padding:'0 0 0 28px'
    });

    this.deleteDialogActions = this.deleteDialog.insertContent(new jui.Component());
    this.deleteDialogActions.setDimensions({width:448, height:32});
    this.deleteDialogActions.setStyle({
       margin:'12px 8px 8px 12px'
    });

    this.deleteDialogOkButton = this.deleteDialogActions.insert( new jui.Button({label:'Delete'}));
    this.deleteDialogCancelButton = this.deleteDialogActions.insert( new jui.Button({label:'Cancel', action: function() {
      self.deleteDialog.close();
      self.enable();
    }}));

   this.deleteDialogOkButton.setStyle({cssFloat:'right'});
   this.deleteDialogCancelButton.setStyle({cssFloat:'right'});
  },

  addAssetViewListeners: function(view) {
    view.addListener('assetSelectionChanged', this);
    view.addListener('companyFilterChanged', this);
    view.addListener('assetDblClicked', this);
    view.addListener('assetDeleteClicked', this);
    view.addListener('assetRenameClicked', this);
    view.addListener('assetRenamed', this);
  },

  enable: function() {
    this.fireEvent('enabled');
  },

  disable: function() {
    this.fireEvent('disabled');
  },

  clear: function() {
    if (this.enabled) {
      this.enable();
    }

    if (this.deleteDialog.isOpen()) {
      this.deleteDialog.close();
    }

    this.getViews().invoke('clear');
  },

  tabSelected: function($super, id) {
    $super(id);
    this.deleteButton.disable();
  },

  prepareViews: function() {
    this.getViews().invoke('prepareForLoading');
  },

  loadAssetsByCompanyId: function(companyId, options) {
    options = options || {};
    var self      = this,
        url       = window.editor.assetsPathByCompanyId(companyId);

    this.assetsLoaded = false;
    this.prepareViews();
    this.loadCompleteCallback = options.onComplete || null;
    return new Ajax.Request(url, {
      method: 'get',
      contentType:'application/json',
      onSuccess: function(transport) {
        var assets = transport.responseJSON;
        self.loadAssetsSuccess.bind(self, assets, companyId).defer();
        self.openAssetsCompanyId = companyId;
      }
    });
  },

  loadAssetsSuccess: function(data, companyId) {
    var sort = this.getDefaultSortOrderByCompanyId(companyId),
        types = $H(this.options.assetTypes),
        groupedAssets = {};

    this.assetsLoaded = true;
    var view = this.getViews().first();
    this.assets = view.sortAssets(data, sort.name, sort.direction);

    types.keys().each( function(k) {
      groupedAssets[k] = [];
    });

    groupedAssets.other = [];

    this.assets.each( function(a) {
      var grouped = false;
      types.each( function(pair) {
        if (pair.value.any( function(v) {return a.content_content_type.startsWith(v); })){
          groupedAssets[pair.key].push(a);
          grouped = true;
        }
      });

      if (!grouped) {
        groupedAssets.other.push(a);
      }
    }, this);

    $H(groupedAssets).each( function(group) {
      var name = group.key === 'other' ?
        'other files' :
        group.key === 'media' ?
        'media files' :
        group.key;

      this.getView(group.key).loadAssets(group.value, name, this.openAssetsCompanyId);
      var tab = this.getTab(group.key);
      tab.setLabel(tab.options.label +' ('+group.value.length+')');
    }, this);

    if (this.loadCompleteCallback !== null) {
      this.loadCompleteCallback();
    }
    view.sortFilter.selectById(sort.name);
    this.fireEvent('assetsLoaded');
  },

  getDefaultSortOrderByCompanyId: function(id) {
    if(this.isUnbounceAssetCompany(id)) {
      return {name:'content_file_name', direction: 'ASC'};
    } else {
      return {name:'id', direction: 'DSC'};
    }
  },

  isUnbounceAssetCompany: function(id) {
    return parseInt(id, 10) === parseInt(window.editor.unbounceAssetsCompanyId, 10);
  },

  addAsset: function(asset) {
    this.assets.unshift(asset);
    var types = $H(this.options.assetTypes);
    var id = 'other';
    types.each( function(pair) {
      if (pair.value.any( function(v) {return asset.content_content_type.startsWith(v); })) {
        id = pair.key;
      }
    });
    this.getView(id).addAsset(asset);
    this.updateSelectedTabCounter();
  },

  setViewType: function(type) {
    this.e.removeClassName(this.viewType);
    this.e.addClassName(type);
    this.viewType = type;
  },

  showTabWithContentType: function(contentType) {
    var types = $H(this.options.assetTypes);

    var result = types.find( function(pair) {
      return pair.value.any( function(v) {return contentType.startsWith(v); });
    });

    if (result) {
      var id = result[0];
      if (this.getTab(id).visible()) {
        this.setTabViewItem(id);
      } else {
        // console.log('tab not visible');
      }
    }
  },

  setVisibleTabs: function(tabIds) {
    this.tabs.values().invoke('hide');
    this.tabs.each( function(pair) {
      if (tabIds.indexOf(pair.key) > -1) {
        pair.value.show();
      }
    });
  },

  companyFilterChanged: function(e) {
    this.e.addClassName('loading');
    this.switchViewByCompanyId(e.data);
  },

  switchViewByCompanyId: function(companyId) {
    this.openAssetsCompanyId = companyId;
    this.loadAssetsByCompanyId(companyId);
    this.deleteButton.setEnabled(this.isDeleteButtonEnabled());
  },

  isDeleteButtonEnabled: function() {
    return this.getSelectedAssets().length > 0 &&
      this.getViews().first().isManageable();
  },

  getSelectedView: function() {
    return this.selectedTabViewItem.view;
  },

  getSelectedAsset: function() {
    return this.getSelectedAssets()[0];
  },

  getSelectedAssets: function() {
    return this.getSelectedView().selectedAssets;
  },

  getUpdatedAsset: function() {
    return this.getSelectedView().asset;
  },

  assetDblClicked: function(e) {
    this.fireEvent('assetDblClicked', e.data);
  },

  assetSelectionChanged: function(e) {
    this.deleteButton.setEnabled(this.isDeleteButtonEnabled());
    this.fireEvent('assetSelectionChanged', e.data);
  },

  assetDeleteClicked: function(e) {
    this.confirmDeleteAssets([e.data]);
  },

  assetRenameClicked: function(e) {
    var asset = e.data;
    this.addRenameFieldByAsset(asset);
  },

  assetRenamed: function(e) {
    var asset = e.data.asset;
    var newName = e.data.newName;
    var ext = e.data.ext;
    if(newName.length > 0) {
      var view = this.getSelectedView();
      view.assetRenamedAction(asset);
      this.renameAsset(asset, this.sluggifyName(newName) + '.' + ext);
    }
  },

  addRenameFieldByAsset: function(asset) {
    var view = this.getSelectedView();
    view.makeAssetRenameable(asset);
  },

  deleteSelectedAssets: function() {
    this.confirmDeleteAssets(this.getSelectedAssets());
  },

  renameAsset: function(asset, newName) {
    var self = this;
    var postBody = 'asset[name]=' + newName + '&_method=PUT';
    return new Ajax.Request(this.assetPath(asset.id), {
      method: 'post',//Doing a PUT
      postBody: postBody,
      onLoading: function() {
        self.disable();
      },
      onLoaded: function() {
        self.enable();
      },
      onSuccess: function(transport) {
        self.getSelectedView().updateAssetThumb(transport.responseJSON);
        self.fireEvent('updated');
      }
    });
  },

  sluggifyName: function(name) {
    var slugifyStrip = /[^\w\s\-]/g;
    var slugifyHyphenate = /[\-\s]+/g;
    name = name.replace(slugifyStrip, '-').trim().toLowerCase();
    return name.replace(slugifyHyphenate, '-');
  },

  confirmDeleteAssets: function(assets) {
    var message = '<p>Are you sure you want to permanently delete the following assets:</p><ul>';
    var self = this;

    assets.each(function(asset) {
      message += '<li>'+asset.name + '</li>';
    });

    message += '</ul><p>You will not be able to undo this action.</p>';

    this.deleteDialogMessage.update(message);

    this.deleteDialogOkButton.setAction(function() {
      self.deleteDialog.close();
      self.enable();
      self.deleteAssets(assets);
    });

    this.disable();
    this.deleteDialog.open(this);
  },

  deleteAssets: function(assets) {
    var openAssetsCompanyId = this.openAssetsCompanyId;
    var postBody = assets.collect( function(asset) {return 'assets[]='+asset.id;}).join('&');
    var self = this;
    return new Ajax.Request(this.trashAssetsPathByCompanyId(openAssetsCompanyId), {
      method: 'post',
      postBody: postBody,
      onSuccess: function( transport ) { self.deleteAssetsSuccess.bind( self, transport.responseJSON ).defer(); }
    });
  },

  deleteAssetsSuccess: function(data) {
    this.getSelectedView().removeAssetsById(data.collect( function(id) {return id * 1;}));
    this.getSelectedView().buildSearch();
    this.updateSelectedTabCounter();
  },

  updateSelectedTabCounter: function() {
    var tvi =this.selectedTabViewItem;
    var tab = this.getTab(tvi.id);
    var view = tvi.view;

    tab.setLabel(tab.options.label +' ('+view.assets.length+')');
  },

  clearSelection: function() {
    this.selectedTabViewItem.view.clearSelection();
  },

  assetsPath: function() {
   return window.editor.assetsPath();
  },

  imageAssetsPath: function() {
    return this.assetsPath() + '/images';
  },

  trashAssetsPathByCompanyId: function(companyId) {
    return window.editor.assetsPathByCompanyId(companyId) + '/trash';
  },

  trashAssetsPath: function() {
    return this.trashAssetsPathByCompanyId(this.currentCompanyId);
  },

  assetPath: function(assetId) {
    return '/assets/' + assetId;
  }

});
