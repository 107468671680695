lp.editor.ContentPanel = Class.create(jui.Component, {
  options: function($super,options){
    return $super($H({attributes:{className:'editor-content-panel'}}).merge(options));
  },

  initialize: function($super, editor, options) {
    this.editor = editor;
    this.editor.addListener('activeRootChanged', this);
    $super(options);
  },

  installUI: function($super) {
    $super();
    this.insert(new Element('h1').update('Page Contents'));
    this.tree =  this.insert(new lp.editor.PageElementTree(this.editor));
    this.tree.addListener('elementSelected', this.editor);
  },

  activeRootChanged: function(e) {
    var root = e.data.current;
    this.tree.setRoot(root);
  }
});
