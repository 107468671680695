lp.module.video.VideoModel = Class.create(lp.pom.VisibleElementModel, {
  initialize: function($super, element, jso) {
    $super(element, jso);
    this.addBreakpointModifyableAttributes({
      content: {
        html: true
      }
    });
  }
});
