lp.module.image = {};

lp.module.image.ImageModule = Class.create(
  lp.ModuleBase,
  lp.ModuleElementMethods,
  lp.ModuleElementBuilderMethods,
  lp.ModuleElementPropertiesPanelMethods,
  {
    javascripts: [
      '//d2xxq4ijfwetlm.cloudfront.net/m/lp-webapp/matchmedia/matchMedia.js',
      '//d2xxq4ijfwetlm.cloudfront.net/m/lp-webapp/0.0.9/lp-image/0.4/main.js'
    ],
    beforeCreate: function (page, defaults, options, asset) {
      defaults.content = {
        asset: {
          company_id: asset.company_id,
          uuid: asset.uuid,
          unique_url: asset.unique_url,
          content_url: asset.content_url,
          content_content_type: asset.content_content_type,
          name:asset.name
        }
      };
    },

    insertElement: function (element, options) {
      window.editor.wait();
      element.img.observe('load', element.createImageObserver(options.center));
    },

    openElementBuilder: function(elm, options) {
      options.onChoose = options.callback;
      window.editor.openAssetChooser(options);
    }
  }
);

lp.addModule(new lp.module.image.ImageModule(
  {
    type:'lp-pom-image',
    version:'1.0',
    name: 'Image',
    usageType:'page-widget',
    namespace: lp.module.image
  }
));
