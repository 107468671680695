lp.pom.updates.add(Object.extend({
  minVersion:'1.4',
  version:'1.4.1',
  run: function(jso) {
    if (this.checkVersion(jso)) {
      this.updateButtonCSS(jso);
      jso.version = this.version;
    }
  },

  updateButtonCSS: function(jso) {
    jso.styles = jso.styles.collect(function(s) {
      if (s.selector.startsWith('a#lp-pom-button')) {
        s.selector = s.selector.sub('a#lp-pom-button', '#lp-pom-root a#lp-pom-button');
      }
      return s;
    });
  },

  removeRule: function(jso,rule) {
    jso.styles = jso.styles.without(rule);
  }
}, lp.pom.updater));
