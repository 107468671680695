lp.module.form.FormElementPropertiesPanel = Class.create(
  lp.editor.panel.ElementPropertiesPanel,
  lp.editor.panel.ElementGeometryControls,
  lp.editor.panel.ElementBackgroundControls,
  lp.editor.panel.ElementBorderControls,
  lp.ModuleComponent,
  {
    type:'lp-pom-form',

    initialize: function($super) {
      $super(this);
      var self = this;

      this.modelChangeHandlers.push(function(e) {
        /* jshint maxcomplexity:24 */
        var accessor = e.data.accessor;
        var value = e.data.value;
        var m = e.source;

        switch(accessor) {
        case 'geometry.offset':
          break;
        case 'geometry.buttonPlacement':
          self.controls.buttonPlacement.setValue(e.data.value === 'auto');
          break;
        case 'content.confirmAction':
          self.controls.confirmAction.setSelectedValue(value);
          self.showConfirmInputs(value);
          break;
        case 'content.confirmURL':
          self.controls.confirmURL.setValue({url:(value ? value : ''), target:''});
          break;
        case 'content.passParams':
          self.controls.passParams.setValue( value );
          break;
        case 'content.confirmMessage':
          self.controls.confirmMessage.setValue(value ? value : 'Thank you!');
          break;
        case 'geometry.label.alignment':
          self.controls.alignSelect.setSelectedValue(value === 'right' ? 'left' : value);
          break;
        case 'geometry.label':
          self.controls.alignSelect.setSelectedValue(m.get('geometry.label.alignment'));
          break;
        case 'style.label.font.family':
        case 'style.label.font.weight':
          self.controls.labelFont.setValue({
            family: m.exists('style.label.font.family') ? m.get('style.label.font.family') : 'arial',
            weight: m.exists('style.label.font.weight') ? m.get('style.label.font.weight') : 'normal'
          });
          break;
        case 'style.label.color':
          self.controls.labelColor.setValue(value ? value : '');
          break;
        case 'style.label.font.size':
          self.controls.labelFontSize.setValue(value ? value*1 : 0);
          break;
        case 'geometry.field.height':
          self.controls.fieldHeight.setValue(value ? value*1 : 0);
          break;
        case 'geometry.field.fontSize':
          self.controls.fieldFontSize.setValue(value ? value*1 : 12);
          break;
        case 'geometry.field.margin.bottom':
          self.controls.fieldSpacing.setValue(value ? value*1 : 0);
          break;
        case 'style.field.backgroundColor':
          self.controls.fieldBkgColor.setValue(value ? value : 'fff');
          break;
        case 'style.field.color':
          self.controls.fieldColor.setValue(value ? value : '000');
          break;
        case 'geometry.field.border':
          self.controls.fieldBorder.setValue(value ? value : {width:1, style:'solid', color: this.getModule().getElementDefaults().geometry.field.border.color});
          break;
        case 'style.cbxlabel.font.size':
          self.controls.cbxLabelFontSize.setValue(value ? value*1 : 0);
          break;
        case 'style.cbxlabel.font.family':
        case 'style.cbxlabel.font.weight':
          self.controls.cbxLabelFont.setValue({
            family: m.exists('cbxlabel.label.font.family') ? m.get('style.cbxlabel.font.family') : 'arial',
            weight: m.exists('cbxlabel.label.font.weight') ? m.get('style.cbxlabel.font.weight') : 'normal'
          });
          break;
        case 'style.cbxlabel.color':
          self.controls.cbxLabelColor.setValue(value ? value : '');
          break;
        default:
          // reset all properties panel controls
          self.setElement(self.element);
        }
      });
    },

    editButtonAction: function(){
      this.getModule().openBuilder(this.element, {
        callback: this.element.updateContentFields.bind(this.element)
      });
    },

    installUI: function($super){
      /* jshint maxstatements:50 */
      // TODO: gut this! Test and Refactor
      $super();
      var self = this;
      var ctrls = this.controls;


      var formFields = this.insert(new jui.CollapsiblePanel({title:'Form Fields'}));
      // TODO-TR: clean this up and move the styles out to css
      // the left/right padding is a bit off
      formFields.e.style.borderTop = 'none';
      formFields.titleBar.hide();
      formFields.insertContent(new jui.Button({
          label: 'Edit Form Fields',
          id: 'edit-form-fields-button',
          action: this.editButtonAction.bind(this),
          classNames: ['full-width-button']
      }));


      var geometryPanel = this.insert(new jui.CollapsiblePanel({title:'Geometry'}));
      var confirmationPanel = this.insert(new jui.CollapsiblePanel({title:'Form Confirmation'}));
      var labelAttrsPanel = this.insert(new jui.CollapsiblePanel({title:'Label Attributes'}));
      var fieldAttrsPanel = this.insert(new jui.CollapsiblePanel({title:'Field Attributes'}));
      var cbxAttrsPanel = this.insert(new jui.CollapsiblePanel({title:'Checkbox & Radio Button'}));
      var buttonPanel = this.insert(new jui.CollapsiblePanel({title:'Button Settings'}));

      ctrls.applyFieldDefaults = fieldAttrsPanel.titleBar.insert(new jui.Button({
        label:'Use Defaults',
        id:'use-field-defaults-button',
        action:function(){
          self.applyFieldDefaults();
        }
      }));

      ctrls.applyLabelDefaults = labelAttrsPanel.titleBar.insert(new jui.Button({
        label:'Use Defaults',
        id:'use-label-defaults-button',
        action:function(){
          self.applyLabelDefaults();
        }
      }));

      ctrls.applyCheckboxDefaults = cbxAttrsPanel.titleBar.insert(new jui.Button({
        label:'Use Defaults',
        id:'use-checkbox-defaults-button',
        action:function(){
          self.applyCheckboxDefaults();
        }
      }));

      ctrls.confirmAction = confirmationPanel.insertContent(new jui.FormSelect({
        label:'Confirmation',
        id: 'jui-confirm-action-select',
        dropDownClassName : 'editor-info-panel-select jui-drop-down',
        width: '200px',
        selectOptions:[
        {name:'Show form confirmation dialog', value:'modal'},
        {name:'Show alert message', value:'message'},
        {name:'Go to another web page', value:'url'},
        {name:'Post form data to a URL', value:'post'}
        ],
        action: function() {
          self.performModelUpdates( function() {
            var action = ctrls.confirmAction.getValue();
            self.element.model.set('content.confirmAction', action, self.element.page.undoManager);
            self.showConfirmInputs(action);
          });
        }
      }));

      ctrls.confirmURL = confirmationPanel.insertContent(new jui.FormLinkInput2({
        label:'URL',
        onblur: function() {
          self.performModelUpdates( function() {
            self.element.model.set('content.confirmURL', ctrls.confirmURL.getValue().url, self.element.page.undoManager);
          });
        },
        onfocus: function(e) { self.requestFocus(e); },
        hideTarget: true
      }));

      ctrls.passParams = confirmationPanel.insertContent( new jui.FormCheckboxInput({
        label: 'Append form data to URL',
        onclick: function( e ) {
          self.performModelUpdates( function() {
            self.element.model.set( 'content.passParams', e.source.getValue(), self.element.page.undoManager );
          } );
          self.element.model.element.form.method = e.source.getValue() ?
            'GET' : 'POST';
        }
      }) );

      ctrls.confirmMessage = confirmationPanel.insertContent(new jui.FormTextArea({
        label:'Message Text',
        inputPosition:'clear-label',
        onblur: function() {
          self.performModelUpdates( function() {
            self.element.model.set('content.confirmMessage', ctrls.confirmMessage.getValue(), self.element.page.undoManager);
          });
        },
        onfocus: function(e) { self.requestFocus(e); }
      }));

      ctrls.editFormConfirmation = confirmationPanel.insertContent(new jui.Button({
        label:'Edit Confirmation Dialog',
        action:function(){
          window.editor.setActivePage(window.editor.findPagesUsedAs('form_confirmation')[0]);
        },
        classNames:['full-width-button']
      }));

      ctrls.editFormConfirmation.hide();

      ctrls.alignSelect = labelAttrsPanel.insertContent(new jui.FormSelect({
        label:'Label Align',
        id: 'jui-label-align-select',
        dropDownClassName : 'editor-info-panel-select jui-drop-down',
        selectOptions:[{name:'Top', value:'top'}, {name:'Left', value:'left'}],
        action: function() {
          self.performModelUpdates( function() {
            self.element.setLabelAlignment(ctrls.alignSelect.getValue(), self.element.getUndoManager());
          });
          self.controls.labelWidth.setValue({width: self.element.getCalculatedLabelWidth()});
          self.controls.labelWidth.setVisible( ctrls.alignSelect.getValue() === 'left');
        }
      }));

      ctrls.labelFont = labelAttrsPanel.insertContent(new jui.FormFontInput({
          label:'Label Font',
          onblur: function() {
            self.performModelUpdates( function() {
              var font = ctrls.labelFont.getValue();
              var um = self.element.page.undoManager;
              um.startGroup();
              self.element.model.set('style.label.font.family', font.family, um);
              self.element.model.set('style.label.font.weight', font.weight, um);
              um.endGroup();
            });
          },
          onfocus: function(e) { self.requestFocus(e); },
          showSize:false
      }));

      ctrls.labelColor = labelAttrsPanel.insertContent(new jui.FormColorInput({
          label:'Label Color',
          onblur: function() {
            self.performModelUpdates( function() {
              self.element.model.set('style.label.color', ctrls.labelColor.getValue(), self.element.page.undoManager);
            });
          },
          onfocus: function(e) { self.requestFocus(e); }
      }));

      ctrls.labelFontSize = labelAttrsPanel.insertContent(new jui.FormSliderInput({
          label:'Label Size',
          onchange: function(e) {
            self.performModelUpdates(function() {
              self.element.model.set('style.label.font.size', e.data, e.source.slider.tracking ? null : self.element.page.undoManager);
            });
          },
          onfocus: function(e) { self.requestFocus(e); },
          startTracking: function() {
            var m = self.element.model;
            var um = self.element.page.undoManager;
            um.registerUndo({
              action:m.set,
              receiver:m,
              params: ['style.label.font.size', m.get('style.label.font.size'), um]
            });
            self.showSamples();
          },
          stopTracking: function() { self.hideSamples(); },
          slider:{
            min:10,
            max:48,
            increment:1
          }
      }));

      ctrls.labelWidth = labelAttrsPanel.insertContent(new jui.FormSizeInput({
        label: 'Label Width',
        labelW:'',
        hideHeight: true,
        onblur: function(e) {
          self.element.setLabelWidthExplicitly(e.data.getValue().width, self.element.getUndoManager());
        },
        onfocus: function(e) {self.requestFocus(e);}
      }));

      ctrls.labelAutoWidth = ctrls.labelWidth.insert(new jui.FormCheckboxInput({
        label:'Auto',
        onclick: function(e) {
          self.element.setAutoCalculateLabelWidth(e.data, self.element.getUndoManager());
        }
      }));

      ctrls.labelAutoWidth.makeAbsolute();
      ctrls.labelAutoWidth.setOffset({left:140, top:0});
      ctrls.labelAutoWidth.setWidth(80);
      ctrls.labelAutoWidth.label.style.width = 'auto';

      ctrls.labelWidth.hide();

      var getMinFontSize = function(fieldHeight) {
        return Math.floor(parseInt(fieldHeight, 10) * 0.75);
      };
      var setMinFontSize = function(fontSize, fieldHeight, um) {
        var minFontSize = getMinFontSize(fieldHeight);
        if (fontSize > minFontSize) {
          ctrls.fieldFontSize.setValue(minFontSize);
          self.element.model.set('geometry.field.fontSize', minFontSize, um);
        }
      };

      ctrls.fieldHeight = fieldAttrsPanel.insertContent(new jui.FormSliderInput({
          label:'Field Height',
          onchange: function(e) {
            self.performModelUpdates(
              e.source.slider.tracking ?
              function() {
                self.element.model.set('geometry.field.height', e.data);
                var fs = ctrls.fieldFontSize.getValue();
                setMinFontSize(fs, e.data);
              } :
              function() {
                var um = self.element.page.undoManager;
                um.startGroup();

                self.element.model.set('geometry.field.height', e.data, um);
                var fs = ctrls.fieldFontSize.getValue();

                setMinFontSize(fs, e.data, um);

                um.endGroup();
              }
            );
          },
          onfocus: function(e) { self.requestFocus(e); },
          startTracking: function() {
            var m = self.element.model;
            var um = self.element.page.undoManager;
            um.startGroup();

            um.registerUndo({
              action:m.set,
              receiver:m,
              params: ['geometry.field.height', m.get('geometry.field.height'), um]
            });

            um.registerUndo({
              action:m.set,
              receiver:m,
              params: ['geometry.field.fontSize', m.get('geometry.field.fontSize'), um]
            });

            um.endGroup();
            self.showSamples();
          },
          stopTracking: function() { self.hideSamples(); },
          slider:{
            min:15,
            max:48,
            increment:1
          }
      }));

      ctrls.fieldSpacing = fieldAttrsPanel.insertContent(new jui.FormSliderInput({
          label:'Field Spacing',
          onchange: function(e) {
            self.performModelUpdates( function() {
              self.element.model.set('geometry.field.margin.bottom', e.data, e.source.slider.tracking ? null : self.element.page.undoManager);
            });
          },
          onfocus: function(e) { self.requestFocus(e); },
          startTracking: function() {
            var m = self.element.model;
            var um = self.element.page.undoManager;
            um.registerUndo({
              action:m.set,
              receiver:m,
              params: ['geometry.field.margin.bottom', m.get('geometry.field.margin.bottom'), um]
            });
            self.showSamples();
          },
          stopTracking: function() { self.hideSamples(); },
          slider:{
            min:10,
            max:48,
            increment:1
          }
      }));

      ctrls.fieldFontSize = fieldAttrsPanel.insertContent(new jui.FormSliderInput({
          label:'Font Size',
          onchange: function(e) {
            self.performModelUpdates( function() {
              var h = ctrls.fieldHeight.getValue();
              var height = getMinFontSize(h);
              if(e.data > height) {
                ctrls.fieldFontSize.setValue(height);
              } else {
                self.element.model.set('geometry.field.fontSize', Math.min(e.data, h), e.source.slider.tracking ? null : self.element.page.undoManager);

              }
            });
          },
          onfocus: function(e) { self.requestFocus(e); },
          startTracking: function() {
            var m = self.element.model;
            var um = self.element.page.undoManager;
            um.registerUndo({
              action:m.set,
              receiver:m,
              params: ['geometry.field.fontSize', m.get('geometry.field.fontSize'), um]
            });
            self.showSamples();
          },
          stopTracking: function() { self.hideSamples(); },
          slider:{
            min:10,
            max:37,
            increment:1
          }
      }));

      ctrls.fieldBorder = fieldAttrsPanel.insertContent( new jui.FormBorderInput ({
        styleSelectOptions:[
          {name:'None',value:'none'},
          {name:'Solid',value:'solid'},
          {name:'Dotted',value:'dotted'},
          {name:'Dashed',value:'dashed'},
          {name:'Inset',value:'inset'},
          {name:'Outset',value:'outset'}],
        label:'Field Border',
        onblur: function() {
          self.performModelUpdates( function() {
            self.element.model.set('geometry.field.border', ctrls.fieldBorder.getValue(), self.element.page.undoManager);
          });
        },
        onfocus: function(e) { self.requestFocus(e); }
      }));

      ctrls.cornerRadius = fieldAttrsPanel.insertContent(new jui.FormSliderInput({
          label:'Corner Radius',
          onchange: function(e) {
            self.performModelUpdates( function(){
              self.element.model.set('geometry.field.cornerRadius', e.data, e.source.slider.tracking ? null : self.element.page.undoManager);
            });
          },
          onfocus: function(e) {self.requestFocus(e);},
          startTracking: function() {
            var m = self.element.model;
            var um = self.element.page.undoManager;
            um.registerUndo({
              action:m.set,
              receiver:m,
              params: ['geometry.field.cornerRadius', m.exists('geometry.field.cornerRadius') ? m.get('geometry.field.cornerRadius') : 0, um]
            });
            self.showSamples();
          },
          stopTracking: function() { self.hideSamples(); },
          slider:{
            min:0,
            max:20,
            increment:1
          }
      }));

      ctrls.fieldBkgColor = fieldAttrsPanel.insertContent(new jui.FormColorInput({
        label:'Background',
        onblur: function() {
          self.performModelUpdates( function() {
            self.element.model.set('style.field.backgroundColor', ctrls.fieldBkgColor.getValue(), self.element.page.undoManager);
          });
        },
        onfocus: function(e) { self.requestFocus(e); }
      }));

      ctrls.innerShadow = fieldAttrsPanel.insertContent(new jui.FormCheckboxInput({
        label:'Show inner shadow',
        onclick:function(e){
          self.performModelUpdates( function() {
            self.element.model.set('style.field.innerShadow', e.source.getValue(), self.element.page.undoManager);
          });
        }
      }));

      ctrls.fieldColor = fieldAttrsPanel.insertContent(new jui.FormColorInput({
        label:'Text Color',
        onblur: function() {
          self.performModelUpdates( function() {
            self.element.model.set('style.field.color', ctrls.fieldColor.getValue(), self.element.page.undoManager);
          });
        },
        onfocus: function(e) { self.requestFocus(e); }
      }));

      ctrls.cbxLabelFont = cbxAttrsPanel.insertContent(new jui.FormFontInput({
          label:'Font',
          onblur: function() {
            self.performModelUpdates( function() {
              var font = ctrls.cbxLabelFont.getValue();
              var um = self.element.page.undoManager;
              um.startGroup();
              self.element.model.set('style.cbxlabel.font.family', font.family, um);
              self.element.model.set('style.cbxlabel.font.weight', font.weight, um);
              um.endGroup();
            });
          },
          onfocus: function(e) { self.requestFocus(e); },
          showSize:false
      }));

      ctrls.cbxLabelColor = cbxAttrsPanel.insertContent(new jui.FormColorInput({
          label:'Text Color',
          onblur: function() {
            self.performModelUpdates( function() {
              self.element.model.set('style.cbxlabel.color', ctrls.cbxLabelColor.getValue(), self.element.page.undoManager);
            });
          },
          onfocus: function(e) { self.requestFocus(e); }
      }));

      ctrls.cbxLabelFontSize = cbxAttrsPanel.insertContent(new jui.FormSliderInput({
          label:'Text Size',
          onchange: function(e) {
            self.performModelUpdates( function() {
                self.element.model.set('style.cbxlabel.font.size', e.data, e.source.slider.tracking ? null : self.element.page.undoManager);
            });
          },
          onfocus: function(e) { self.requestFocus(e); },
          startTracking: function() {
            var m = self.element.model;
            var um = self.element.page.undoManager;
            um.registerUndo({
              action:m.set,
              receiver:m,
              params: ['style.cbxlabel.font.size', m.get('style.cbxlabel.font.size'), um]
            });
            self.showSamples();
          },
          stopTracking: function() { self.hideSamples(); },
          slider:{
            min:10,
            max:48,
            increment:1
          }
      }));

      this.installGeometryControls({insert:geometryPanel.insertContent.bind(geometryPanel), sizeOptions:{hideHeight:true}});

      ctrls.buttonPlacement = buttonPanel.insertContent(new jui.FormCheckboxInput({
        label:'Position submit button automatically',
        onclick:function(e){
          self.performModelUpdates( function() {
            self.element.set('geometry.buttonPlacement', e.source.getValue() ? 'auto' : 'manual');
          });
        }
      }));
    },

    applyFieldDefaults: function() {
      var m = this.element.model;
      var um = this.element.page.undoManager;
      um.startGroup();
      m.set('geometry.field', jui.clone(this.getModule().getElementDefaults().geometry.field), um);
      m.set('style.field', jui.clone(this.getModule().getElementDefaults().style.field), um);
      um.endGroup();
    },

    applyLabelDefaults: function() {
      var m = this.element.model;
      var um = this.element.page.undoManager;
      um.startGroup();
      m.set('style.label', jui.clone(this.getModule().getElementDefaults().style.label), um);
      m.set('geometry.label', jui.clone(this.getModule().getElementDefaults().geometry.label), um);
      um.endGroup();
    },

    applyCheckboxDefaults: function() {
      this.element.model.set('style.cbxlabel', jui.clone(this.getModule().getElementDefaults().style.cbxlabel), this.element.page.undoManager);
    },

    formFieldsChanged: function() {
      var fields = [];
      $H(this.controls.fields).each(function(f){
        if (f.value.down('input.show').checked) {
          fields.push({
            name:f.value.down('td.name').innerHTML,
            id:f.key,
            type:f.value.down('td.type').innerHTML,
            validations:{
              required:f.value.down('input.required').checked
            }
          });
          f.value.down('input.required').disabled = false;
        } else {
          f.value.down('input.required').disabled = true;
          f.value.down('input.required').checked = false;

        }
      }, this);

      this.element.model.set('content.fields', fields, this.element.page.undoManager);
    },

    showSamples: function() {
      this.element.fieldViewMap.each(function(fv) {
        if (fv.field.lpType === 'single-line-text' || fv.field.lpType === 'email') {
          fv.view.root.down('input').value = fv.field.name;
        } else if (fv.field.lpType === 'multi-line-text') {
          fv.view.root.down('textarea').value = 'line one\nline two\nline three\nline four';
        }
      });
    },

    hideSamples: function() {
      this.element.fieldViewMap.each(function(fv) {
        if (fv.field.lpType === 'single-line-text' || fv.field.lpType === 'email') {
          fv.view.root.down('input').value = '';
        }
      });
    },

    showConfirmInputs: function(action) {
      this.controls.editFormConfirmation[action === 'modal' ? 'show' : 'hide']();
      this.controls.confirmMessage[action === 'message' ? 'show' : 'hide']();
      this.controls.confirmURL[this.isUrlOrPost( action ) ? 'show' : 'hide']();
      this.controls.passParams[ action === 'url' ? 'show' : 'hide' ]();
    },

    isUrlOrPost: function( action ) {
      return action === 'url' || action === 'post';
    },

    syncUIControls: function($super, elm) {
      $super(elm);

      var m = elm.model;
      var labelAlign = elm.model.get('geometry.label.alignment') === 'right' ? 'left' : elm.model.get('geometry.label.alignment');

      this.bindGeometryControlsToElement(elm);
      this.controls.buttonPlacement.setValue(m.exists('geometry.buttonPlacement') ? m.get('geometry.buttonPlacement') === 'auto' : true);

      this.controls.alignSelect.setSelectedValue(labelAlign);
      this.controls.labelFont.setValue({
        family: m.exists('style.label.font.family') ? m.get('style.label.font.family') : 'arial',
        weight: m.exists('style.label.font.weight') ? m.get('style.label.font.weight') : 'normal'
      });
      this.controls.labelFontSize.setValue(m.exists('style.label.font.size') ? m.get('style.label.font.size')*1 : 0);
      this.controls.labelColor.setValue(m.exists('style.label.color') ? m.get('style.label.color') : '');
      this.controls.labelWidth.setValue({width:elm.getCalculatedLabelWidth()});
      this.controls.labelAutoWidth.setValue(elm.isAutoCalculateLabelWidth());

      this.controls.labelWidth.setVisible(labelAlign === 'left');

      this.controls.fieldSpacing.setValue(m.exists('geometry.field.margin.bottom') ? m.get('geometry.field.margin.bottom')*1 : 0);
      this.controls.fieldHeight.setValue(m.exists('geometry.field.height') ? m.get('geometry.field.height')*1 : 0);
      this.controls.fieldFontSize.setValue(m.exists('geometry.field.fontSize') ? m.get('geometry.field.fontSize')*1 : 12);
      this.controls.fieldBkgColor.setValue(m.exists('style.field.backgroundColor') ? m.get('style.field.backgroundColor') : 'fff');
      this.controls.fieldColor.setValue(m.exists('style.field.color') ? m.get('style.field.color') : '000');
      this.controls.fieldBorder.setValue(m.exists('geometry.field.border') ? m.get('geometry.field.border') : {width:1, style:'solid', color: this.getModule().getElementDefaults().geometry.field.border.color});
      this.controls.cornerRadius.setValue(m.exists('geometry.field.cornerRadius') ? m.get('geometry.field.cornerRadius')*1 : 0);

      this.controls.innerShadow.setValue(m.exists('style.field.innerShadow') && m.get('style.field.innerShadow'));

      this.controls.cbxLabelFont.setValue({
        family: m.exists('style.cbxlabel.font.family') ? m.get('style.cbxlabel.font.family') : 'arial',
        weight: m.exists('style.cbxlabel.font.weight') ? m.get('style.cbxlabel.font.weight') : 'normal'
      });

      this.controls.cbxLabelFontSize.setValue(m.exists('style.cbxlabel.font.size') ? m.get('style.cbxlabel.font.size')*1 : 0);
      this.controls.cbxLabelColor.setValue(m.exists('style.cbxlabel.color') ? m.get('style.cbxlabel.color') : '');

      var action = elm.model.exists('content.confirmAction') ? elm.model.get('content.confirmAction') : 'message';
      this.controls.confirmAction.setSelectedValue(action);
      this.showConfirmInputs(action);

      this.controls.confirmURL.setValue({url:(elm.model.exists('content.confirmURL') ? elm.model.get('content.confirmURL') : ''), target:''});
      this.controls.confirmMessage.setValue(elm.model.exists('content.confirmMessage') ? elm.model.get('content.confirmMessage') : 'Thank you!');
      this.controls.passParams.setValue( elm.model.exists( 'content.passParams' ) ? elm.model.get( 'content.passParams' ) : false );
    }
  }
);
