lp.module.root = {};

lp.module.root.RootModule = Class.create(
  lp.ModuleBase,
  lp.ModuleElementMethods,
  lp.ModuleElementPropertiesPanelMethods,
  {
    beforeCreate: function (page, defaults, options, data) {
      /* jshint unused:vars */
      defaults.geometry.contentWidth = page.getDefaultWidth();
    }
  }
);

lp.addModule(new lp.module.root.RootModule(
  {
    type:'lp-pom-root',
    version:'1.0',
    name: 'Root',
    namespace: lp.module.root
  }
));
