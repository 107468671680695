lp.editor.SaveTemplateDialog = Class.create( jui.ModalPanel, {
  initialize: function( $super, options ){
    $super( options );
  },

  installUI: function( $super ) {
    $super();

    this.content.insert( new Element('h1').update('Save a copy of this page as a Template'));
    this.form = this.content.insert(new jui.Component({attributes:{className:'form clearfix'}}));

    this.name = this.form.insert( new jui.FormTextInput({label:'Name'}));
    this.description = this.form.insert( new jui.FormTextArea({label:'Description'}));
    // this.shared = this.form.insert( new jui.FormCheckboxInput({label:'Share this template with the Unbounce community'}));

    this.actions = this.content.insert( new jui.Component({attributes:{className:'actions clearfix'}}));
    this.saveButton = this.actions.insert( new jui.Button({label:'Save as Template', action:this.save.bind(this)}));
    this.cancelButton = this.actions.insert( new jui.Button({label:'Cancel', action:this.cancel.bind(this)}));

  },

  onkeydown: function() {},

  onkeyup: function(){},

  blur: function(){},

  cancel: function() {
    this.close();
    this.options.onCancel();
  },

  save: function() {
    this.close();
    this.options.onSave(this.name.getValue(), this.description.getValue());
  },

  open: function($super, options) {
    $super();
    window.editor.keyController.requestFocus(this);

    this.options.onSave = options.onSave;
    this.options.onCancel = options.onCancel;

    this.name.setValue(options.name);
    this.description.setValue(options.description);
  }
});
