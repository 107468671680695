// require <jui>
// require <jui/color_picker_button>

jui.FormColorInput = Class.create( jui.Component, jui.ControlModel, {
  options: function($super,options){
    return $super($H({
      attributes:{className:'form-elm color-input'}
    }).merge(options));

  },

  initialize:function( $super, options ){
    $super( options );
    if ( this.options.onfocus ) {
      this.addListener( 'focus', this.options.onfocus );
    }

    if ( this.options.onblur ) {
      this.addListener( 'blur', this.options.onblur );
    }
  },

  installUI: function( $super ) {
    $super();
    this.defaultColor = 'ccc';
    this.label = this.insert( new Element('label').update(this.options.label));
    this.label.insert( new Element('span', {className:"hash"}).update("#"));
    this.input = this.insert(new Element('input', {type:'text', className:'text'}));
    this.button = this.insert(new jui.ColorPickerButton({
      action:this.buttonColorChanged.bind(this)
    }));

    if (this.options.inputPosition) {
      this.input.addClassName(this.options.inputPosition);
    }

    this.input.observe('focus', this.onFocus.bind(this));
    this.input.observe('blur', this.onBlur.bind(this));
  },

  enable: function() {
    this.disabled = false;
    if (Object.isElement(this.e)) {
      this.e.removeClassName('dsbl');
    }
    this.input.disabled = false;
    this.button.enable();
  },

  disable: function() {
    this.disabled = true;
    if (Object.isElement(this.e)) {
      this.e.addClassName('dsbl');
    }
    this.input.disabled = true;
    this.button.disable();
  },

  setValue: function(value) {
    // TODO: JS: validation
    value = (value === null || value === '' || value === 'transparent') ? '' : value;

    this.handleTransparencyCheckbox(value);

    if(!this.options.transparency && value === '') {
      value = this.getSavedColor();
    }

    this.button.setColor(value);
    this.input.value = value === '' ? '' : value.sub('#','').strip();
  },

  handleTransparencyCheckbox: function(checkboxValue) {
    if (checkboxValue === '') {
      if (this.transparent) {
        this.transparent.checked = true;
      }
    } else {
      if (this.transparent) {
        this.transparent.checked = false;
      }
    }
  },

  setSavedColor: function(value) {
    this.defaultColor = value;
  },

  getSavedColor: function() {
    return this.defaultColor;
  },

  isTransparent: function() {
    return this.transparent && this.transparent.checked;
  },

  getValue: function() {
    return this.input.value;
  },

  setButtonColor: function(v) {
    this.button.setColor(v);
  },

  onFocus: function( e ) {
    this.memorizeCurrentColor();
    if( this.isEnabled() ) {
      e.stop();
      this.fireEvent('focus', this);
    }
  },

  memorizeCurrentColor: function() {
    if(this.getValue().strip() !== '' && this.getValue() !== 'transparent') {
      this.memorizedColor = this.getValue();
    }
  },

  buttonColorChanged: function(e) {
    this.setValue(e.data);
    this.fireEvent('blur', this);
  },

  onkeydown: function(e, controller) {
    jui.HexidecimalInputFilter.filter(e, controller);
    if(Event.KEY_RETURN === e.keyCode) {
      this.blur();
      e.stop();
    }
  },

  onkeyup: function() {
    this.setButtonColor(this.getValue());
    if (this.transparent) {
      this.transparent.checked = (this.button.getColor() === '');
    }
  },

  onBlur: function( e ) {
    this.resetClearedFieldIfNotTransparentEnabled();
    this.cleanHex();
    if(this.isEnabled() && this.isValidColor()) {
      e.stop();
      this.fireEvent('blur', this);
    } else {
      this.setValue(this.memorizedColor);
    }
  },

  blur: function() {
    this.input.blur();
  },

  cleanHex: function(){
    this.stripHash();
    this.ensureSixCharacterMax();
  },

  stripHash: function() {
    var regex = /^\#/;
    if(regex.test(this.getValue())) {
      this.setValue(this.getValue().replace(regex, '').strip());
    }
  },

  ensureSixCharacterMax: function() {
    if(this.getValue().length > 6) {
      this.setValue(this.getValue().substr(0, 6));
    }
  },

  isValidColor: function() {
    var regex = /(^#?[0-9A-F]{6}$)|(^#?[0-9A-F]{3}$)/i;
    return this.getValue().strip() === '' || regex.test(this.getValue());
  },

  resetClearedFieldIfNotTransparentEnabled: function() {
    if(!this.options.transparency && this.getValue().strip() === '') {
      this.setValue(this.memorizedColor);
    }
  }
});
