lp.module.code.CustomHTMLElementBuilder = Class.create(
  lp.editor.BuilderModal,
  lp.ModuleComponent,
  {
    type:'lp-code',

    initialize: function($super){
      this.controls = {};
      $super({
        title:'Embed Custom HTML Code',
        actions: [{
          id: 'done',
          label: 'Done',
          action: this.embedIfValid.bind(this)
        },
        {
          id: 'cancel',
          label: 'Discard',
          action: this.cancel.bind(this)
        }],
        showMaximize: true,
        layoutRules: {
          method: lp.editor.BuilderModal.fitToCanvas,
          options: {
            padding: 12,
            size:{width:1000, height:600}
          }
        }
      });
    },

    installUI: function( $super ) {
      $super();
      this.warn = this.actionBar.insert( new Element('div', {className:'warn'}).update('You must enter valid code'));
      this.controls.codeEditor = this.contentPanel.insert( new jui.CodeEditor( {
        label: "Enter HTML code that you would like to embed below:",
        toggleCheckboxYPosition: '5px'
      } ) );

      this.warn.hide();
    },

    requestFocus: function(){
      window.editor.keyController.requestFocus(this);
    },

    onkeydown: function() {},

    onkeyup: function(){},

    blur: function(){},

    cancel: function() {
      this.hideWarningDialog();
      this.close();
    },

    embedIfValid: function() {
      this.controls.codeEditor.isValidContentHTML(this, this.embed);
    },

    embed: function(force) {
      if(!!force) {
        this.hideWarningDialog();
        if (this.options.callback) {
          this.options.callback({
            html: this.controls.codeEditor.getValue(),
            valid: this.controls.codeEditor.isValid(),
            errors: this.controls.codeEditor.getErrors()
          });
        }
        this.close();
        if(typeof eventTracker !== 'undefined') {
          eventTracker.track("Add a Custom HTML Object", "Editor");
        }
      }

    },

    open: function($super, elm, options) {
      $super();
      if (options && options.callback) {
        this.options.callback = options.callback;
      }
      var content = '';
      var isValid = true;
      if(elm !== null) {
        var model = elm.model;
        content = model.get( 'content.html' );
        isValid = model.exists('content.valid') ? model.get('content.valid') : true;
      }
      this.controls.codeEditor.setValue(content);
      this.controls.codeEditor.focus();
      var func = this.embedIfValid;
      this.controls.codeEditor.setIsValid(isValid);
      this.controls.codeEditor.showHTMLWarningIfInvalid(this, func, isValid);
      this.requestFocus();
    },

    close: function($super) {
      delete this.options.callback;
      $super();
    }
  }
);
