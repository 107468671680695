/* globals rx_switchboard */
lp.pom.updates = {
  updates: new Hash(),

  add: function(update) {
    this.updates.set(update.version, update);
  },

  run: function(jso) {
    var trace = rx_switchboard.getPublisher('pom_updates');
    var keys  = this.updates.keys().sort();

    keys.each(function(k) {
      trace({type: 'updater', id: k, jso: jso});
      this.updates.get(k).run(jso);
    }, this);
  }
};
