lp.editor.SectionBoundaryManager = Class.create( {
  initialize: function(editor){
    this.editor = editor;
    this.visible = true;
    this.root = null;
    this.boundaries = [];
    this.undoRegistered = false;

    var canvasListener = this.positionBoundaries.bind(this);

    this.editor.addListener('layoutChanged', canvasListener);
    this.editor.canvasBody.observe('scroll', canvasListener);
    this.editor.addListener('activeRootChanged', this);
  },

  activeRootChanged: function(e) {
    var previous = e.data.previous;
    var root = e.data.current;
    if (previous !== null) {
      previous.removeListener('blockInserted', this);
      previous.removeListener('blockRemoved', this);
      previous.removeListener('blockHidden', this);
      previous.removeListener('blockShown', this);
      previous.removeListener('layoutChanged', this);
    }

    root.addListener('blockInserted', this);
    root.addListener('blockRemoved', this);
    root.addListener('blockHidden', this);
    root.addListener('blockShown', this);
    root.addListener('layoutChanged', this);

    this.root = root;
    this.removeAllBoundaries();
    this.editor.page.getElementsByTypeAndContainerId('lp-pom-block', root.id).each(function(section, i) {
      this.createBoundary(section, i);
    }, this);

    this.positionBoundaries();

    this.setVisible(this.editor.page.settings.showSectionBoundaries);
  },

  createBoundary: function(section, i) {
    /* jshint unused:vars */
    var m = section.model;
    var margin = m.get('geometry.margin');
    if (margin === 'auto') {
      margin = {
        left:'auto',
        right:'auto'
      };
    }

    var self = this;

    if (this.root.page.allowSectionMargins()) {
      this.boundaries.push(
        self.editor.canvasBody.insert( new lp.editor.ElementBoundaryControl({
          element: section,
          manager: this,
          onDrag: function(p) {
            margin.bottom = Math.max(0,this.startValue + p.top);
            margin.left = 'auto';
            margin.right = 'auto';
            m.setMargin(margin, section.page.undoManager);
          },
          onInitDrag: function() {
            var margin = m.getMargin();
            this.startValue = typeof margin.bottom === 'undefined' ? 0 : margin.bottom;
          },
          onBeforeDrag: function() {
            if (!self.undoRegistered) {
              self.undoRegistered = true;
              var um = section.page.undoManager;
              margin = m.getMargin();
              um.startGroup();
              um.registerUndo({
                action:m.setMargin,
                receiver:m,
                params:[jui.extend({}, margin), um]
              });
              um.endGroup();
            }
          },
          actsOn: 'margin-bottom'
        }))
      );
    }

    this.boundaries.push(
      self.editor.canvasBody.insert( new lp.editor.ElementBoundaryControl({
        element: section,
        manager: this,
        onDrag: function(p) {
          m.setSize({width: this.startWidth, height: Math.max(0,this.startHeight + p.top)});
        },

        onInitDrag: function() {
          this.startWidth = m.get('geometry.size.width');
          this.startHeight = m.get('geometry.size.height');
        },

        onBeforeDrag: function() {
          if (!self.undoRegistered) {
            self.undoRegistered = true;
            var um = section.page.undoManager;
            um.startGroup();
            um.registerUndo({
              action:m.setSize,
              receiver:m,
              params:[{width: this.startWidth, height: this.startHeight}, um]
            });
            um.endGroup();
          }
        },
        actsOn: 'height'
      }))
    );
  },

  removeBoundary: function(section) {
    var b = this.boundaries.findAll(function(b) {return b.elm === section;});
    b.each(function(b) {
      b.remove();
      this.boundaries = this.boundaries.without(b);
    }, this);
  },

  removeAllBoundaries: function() {
    this.boundaries.each(function(b) {
      b.remove();
    }, this);
    this.boundaries = [];
  },

  layoutChanged: function() {
    this.positionBoundaries();
  },

  blockInserted: function(e) {
    var elm = e.data;
    this.createBoundary(elm);
    this.setBoundaryVisibility();
  },

  blockRemoved: function(e) {
    var elm = e.data;
    this.removeBoundary(elm);
    this.setBoundaryVisibility();
  },

  blockHidden: function() {
    this.setBoundaryVisibility();
  },

  blockShown: function() {
    this.setBoundaryVisibility();
  },

  positionBoundaries: function() {
    var w = this.editor.canvasBody.e.clientWidth;
    var l = this.editor.canvasBody.e.scrollLeft;
    var t;

    this.boundaries.each(function(b) {
      var elm = b.elm;
      var bm = elm.getBottomMargin() + elm.getBottomBorderWidth();
      if (b.actsOn === 'height') {
        t = elm.getContentHeight() + elm.getPageOffset().top - 4;
        b.setHeight(Math.min(9, 5 + elm.getBottomMargin()));

      } else if (b.actsOn === 'margin-bottom'){
        t = elm.getContentHeight() + bm + elm.getPageOffset().top - 4;
        b.setLineVisibility(bm > 0);
      }

      b.setOffset({left: l, top: t});
      b.setWidth(w);
    }, this);
  },

  setBoundaryVisibility: function() {
    this.boundaries.each(function(b) {
      b.setVisible(this.visible && b.elm.visible());
    }, this);
  },

  setVisible: function(visible) {
    this.visible = visible;
    this.setBoundaryVisibility();
  }
});
