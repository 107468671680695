//  require <prototype>
//  require <jui>
//  require "editor"

jui.MultiOptionDialog = Class.create( jui.ModalPanel, {
  initialize: function($super, options){
    $super(options);
    this.e.addClassName('multi-button-dialog');
  },

  installUI: function($super) {
    $super();
    this.message = this.content.insert( new jui.Component({attributes:{className:'message clearfix'}}));
    this.actions = this.content.insert( new jui.Component({attributes:{className:'actions clearfix'}}));

    if (this.options.message) {
      this.updateMessage();
    }

    if (this.options.options) {
      this.updateOptions();
    }
  },

  updateMessage: function() {
    this.message.insert(this.options.message);
  },

  updateOptions: function() {
    var self = this;
    this.options.options.each( function(option) {
      self.actions.insert( new jui.Button({
        label:option.label,
        action:function() {
          option.handler();
          self.close();
        },
        classNames: option.classNames || []
      }));
    });
  },

  close: function($super) {
    $super();
    if (this.options.onClose) {
      this.options.onClose();
    }
  },

  open: function($super, options) {
    if (options && options.onClose) {
      this.options.onClose = options.onClose;
    }
    if (options && options.message) {
      this.options.message = options.message;
      this.updateMessage();
    }
    if (options && options.options) {
      this.options.options = options.options;
      this.updateOptions();
    }
    $super();
  }
});
