//  require <prototype>
//  require <jui>
//  require "editor"
//  require "page_element_tree"

lp.editor.ComponentSideBar = Class.create(jui.Component, {
  options: function($super,options){
    return $super($H({attributes:{id:'component-side-bar'}}).merge(options));
  },

  initialize: function($super, options) {
    options = options || {};
    this.opening = false;

    $super(options);

    var self = this;

    this.e.observe('mouseover', function() {
      if (!self.e.hasClassName('open')) {
        self.e.addClassName('open');
      }
    });

    this.e.observe('mouseout', function() {
      if (self.e.hasClassName('open')) {
        self.e.removeClassName('open');
      }
    });

    if (options.left) {
      this.e.addClassName('left');
    }
  },

  installUI: function($super) {
    $super();
    // this.setOpacity(0.9);
    this.content = this._insert(new jui.Component({attributes:{className:'content'}}));

    // this._insert(new Element('div', {className:'scroll-arrow-up'}));
    // this._insert(new Element('div', {className:'scroll-arrow-dn'}));
  },

  insert: function(c){
    return this.content.insert(c);
  }
});
