lp.module.block.BlockModel = Class.create(lp.pom.VisibleElementModel, {
  initialize: function($super, element, modelData) {
    $super(element, modelData);
    this.addBreakpointModifyableAttributes({
      geometry:{
        visible: true,
        fitWidthToPage: true,
        margin: true,
        size: {
          width: true,
          height: true
        },
        backgroundImageApply: true,
        borderLocation: true,
        borderApply: {
          top: true,
          right: true,
          left: true,
          bottom: true
        },
        savedBorderState: {
          left: true,
          right: true
        },
        keepCircular: true,
        cornerRadius: {
          tl: true,
          tr: true,
          bl: true,
          br: true
        }
      },
      style: {
        background: {
          image: true,
          backgroundColor: true,
          backgroundPosition: true,
          backgroundRepeat: true,
          savedBackgroundColor: true,
          gradient: {
            from: true,
            to: true
          },
          fillType: true,
          autoGradient: true,
          reverseGradient: true,
          opacity: true
        },
        border: true
      }
    });

    this.addBreakpointAllowDuplicateAttributes({
      geometry:{
        visible: true,
        fitWidthToPage: true,
        backgroundImageApply: true,
        borderLocation: true,
        borderApply: {
          top: true,
          right: true,
          left: true,
          bottom: true
        },
        savedBorderState: {
          left: true,
          right: true
        }
      },
      style: {
        background: {
          backgroundRepeat: true,
          savedBackgroundColor: true,
          image: true
        },
        border: true
      }
    });
  },

  getSize: function() {
    return {
      width: this.element.getParentElement().getContentWidth(),
      height: this.getHeight()
    };
  },

  getWidth: function() {
    return this.element.getParentElement() ? this.element.getParentElement().getContentWidth() : 0;
  },

  shouldApplyBackgroundImage: function() {
    if (this.exists('geometry.backgroundImageApply')) {
      return this.get('geometry.backgroundImageApply');
    } else {
      return true; // backwards compat
    }

  }
});
