;(function(){
  var jui_events = window.rx_switchboard.subjects.jui_events,
      msgClass = 'lp-parenting-msg',
      msgParentClass = msgClass+'-container',
      dragStartParent = null;

  var log = function(msg) {
    // console.log(msg);
  };
  var messageDiv = function() {
    return jQuery('<div class="'+msgClass+'"></div>')
      .html('Move + hold &#8984; or \'ctrl\' to ensure moving this element does not affect your desktop page ');
  };
  var reset = function () {
    jQuery('.'+msgClass).remove();
    jQuery('.'+msgParentClass).removeClass(msgParentClass);
  };

  var addIndicatorForReparentedElement = function(el) {
    if (window.editor.page.isMobileBreakpoint()) {
      reset();
      if (el.containerId === dragStartParent.id) {
        // don't display the message if we're moving back into our orig parent
        return;
      }
      // the dnd / reparenting creates an identical clone of the original which gets layered
      // above the orig. We need to add the msg to the clone, which is the visible one.
      var $elems = jQuery('[id="'+ el.id +'"]');
      var $el = (($elems.length === 2) ?
                 jQuery($elems[1])
                 : $elems);
      if ( ! $el.hasClass('lp-protruding')) {
        $el.append(messageDiv).addClass(msgParentClass);
      }
    }
  };

  var watchElements = function() {
    jui_events.filter(function(e){
      return e.type === 'parentChanged';
    })
      .delay(20)
      .map(function (ev) { return ev.data;})
      .subscribe(function(el){
        try {
          addIndicatorForReparentedElement(el);
        } catch (e) {
          log(e);
        }
      });

    // we need to keep a reference to the starting container when
    // dragging / reparenting elements
    jui_events.filter(function (e) {return e.type === 'startMove';})
      .subscribe(function(e) {
        dragStartParent = e.data.getParentElement();
      });
    jui_events.filter(function (e) {return e.type === 'stopMove';})
      .subscribe(function() {
        dragStartParent = null;
      });


    jui_events.filter(function (e) {
      return e.type === 'stopMove' ||
        e.type === 'elementDragStop' ||
        e.type === 'mouseout';
    })
    .delay(25)
    .subscribe(function() {
      try {
        reset();
        // this throws sometimes:
        window.editor.transformBox.elementModelChanged();
      } catch (e) {
        log(e);
      }
    });
  };


  if(window.lp.isResponsiveEnabled()) {
    watchElements();
  }
})();
