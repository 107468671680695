/* globals rx_switchboard */
;(function() {
  var lp = this.lp;
  lp.editor = lp.editor || {};
  lp.Editor = Class.create(jui.Component, lp.editor.EditorElementListeners, {
    options: function($super,options){
      return $super($H({element:'editor', attributes:{className:'lp-editor'}}).merge(options));
    },

    initialize: function($super, options) {
      this.controls = {};
      this.mainPage = null;
      this.pages = [];
      this.page = null;
      this.activeRoot = null;
      this.activeElement = null;
      this.activeUndoManager = null;
      this.lastSavedJSON = null;
      this.showSaveAsTemplate = options.admin;
      this.admin = options.admin;
      this.waiting = false;
      this.loadError = false;
      this.page_uuid = options.page_uuid;
      this.companies = [];
      this.currentCompanyId = null;
      this.blocked = false;
      this.savingInProgress = false;

      this.treeToggler = new lp.editor.TreeToggler();

      this.autoLayout = true;

      this.setUpdatedAt(options.updatedAt);

      this.dynamicTextDialog = null;

      $super(options);

      this.snapManager = new lp.editor.SnapManager(this);
      this.snapManager.addListener('snappedToContainer', this);
      this.snapManager.addListener('notSnappedToContainer', this);

      var self = this;

      this.createElementListeners();

      this.undoListener = function() {
        self.setUndoStates();
      };

      this.rootLayoutListener = this.rootLayoutChanged.bind(this);

      jui.ModalPanel.addListener('modalOpened', function(e) {
        self.setUndoStates();
      });

      jui.ModalPanel.addListener('modalClosed', function() {
        self.setUndoStates();
      });

      this.activeElementConstraintsListener = this.activeElementConstraintsChanged.bind(this);
      this.tracePageSaves = rx_switchboard.getPublisher('page_saves');
      this.traceEditorErrors = rx_switchboard.getPublisher('editor_errors');

    },

    installUI: function($super){
      /*jshint maxstatements:86 */
      $super();

      if(this.options.onLoaded) {
        this.addListener('loaded', this.options.onLoaded);
      }

      if(this.options.onComplete) {
        this.addListener('complete', this.options.onComplete);
      }

      this.clear();
      window.document.body.observe('mousedown', function(e){
        var selectableElement = e.findElement('input, select, textarea, div[contenteditable=true]');
        if (Object.isUndefined(selectableElement)) {
          Event.stop(e);
        }
      });

      var self = this;

      // Key board input controller..
      this.keyController = new lp.editor.KeyController(this);
      this.olarkChatFocusManager = new lp.editor.OlarkChatFocusManager();

      // Primary UI components
      this.toolbar = this.insert(new jui.Component({attributes:{id:'primary-toolbar'}}));
      this.triPane = this.insert(new jui.TriPane({
        top:40,
        bottom:0,
        right:0,
        left:0,
        attributes:{id: 'editor-tri-pane'}}));

      this.pagePanel = this.triPane.setView(this.insert(new jui.Component({attributes:{id: 'page-panel'}})), 'center');
      this.contentPanel = this.triPane.setView(this.insert(new lp.editor.ContentPanel(this, {attributes:{id: 'page-content-panel' }})), 'left');
      this.infoPanel = this.triPane.setView(this.insert(new lp.editor.InfoPanel(this, {attributes:{id: 'editor-info-panel', className:'tab-view' }})), 'right');

      this.handleContentTreePaneVisibility();

      this.pageTabs = this.pagePanel.insert(new lp.editor.PageTabBar(this, {attributes:{id: 'page-tabs', className:'tab-bar'}}));
      this.editingPanel = this.pagePanel.insert(new jui.Component({attributes:{id: 'editing-panel'}}));

      this.editingPanel.insert(new jui.Component({attributes:{id:'editor-canvas-cap'}}));
      this.canvas = this.editingPanel.insert(new jui.Component({attributes:{id: 'editor-canvas'}}));
      this.invisibleElementsShelf = this.editingPanel.insert(new lp.editor.NonVisibleElementsShelf(this));
      this.toggleShelf = this.invisibleElementsShelf.insert(new jui.Component({attributes:{class: 'toggle-shelf'}}));

      this.componentSideBar = this.editingPanel.insert(new lp.editor.ComponentSideBar({left:true}));

      this.insertContentTreeToggleUI();

      this.canvasBody = this.canvas.insert(new jui.Component({attributes:{id: 'canvas-body', className:'lp-pom-root'}}));
      this.pageBody = this.canvasBody.insert(new jui.Component({attributes:{id: 'page-body', className:'lp-pom-body'}}));
      this.pageWarning = this.canvasBody.insert(new jui.Component({attributes:{id: 'page-warning'}}));

      this.pageWarningButton = this.pageWarning.insert(new jui.Button({
        label:'Use this dialog as my<br />Form Confirmation',
        action:function() {
          if (self.mainPage.getElementsByType('lp-pom-form').length > 0) {
            self.mainPage.getElementsByType('lp-pom-form')[0].model.set('content.confirmAction', 'modal', self.mainPage.undoManager);
            self.hidePageWarning();
          }
        }}));

      this.waitPanel = new Element('div', {id:'wait'});
      this.waitPanel.insert( new Element('div', {className: 'wait-loading-image'}).update('<h1>Please wait while the image loads...</h1>'));
      this.waitPanel.setOpacity(0.75);

      this.triPane.addListener('layoutChanged', this.updateCanvasLayout.bind(this));

      Event.observe(window, 'resize', function() {
        self.updateCanvasLayout();
      });

      this.pageBody.e.observe('click', function() {
        self.setActiveElement(self.page.getRootElement());
      });

      this.pageWarning.setOpacity(0.9);
      this.pageWarning.hide();

      // Element selection manipulation components
      this.transformBox = this.canvasBody.insert(new lp.editor.TransformBox(this));
      this.transformBox.hide();

      this.offsetIndicators = this.canvasBody.insert(new lp.editor.OffsetIndicators(this.transformBox));
      this.offsetIndicators.hide();

      // Image selection manipulation components
      this.imageTransformBox = this.canvasBody.insert(new lp.editor.ImageTransformBox(this));
      this.imageTransformBox.hide();

      this.imageOffsetIndicators = this.canvasBody.insert(new lp.editor.OffsetIndicators(this.imageTransformBox));
      this.imageOffsetIndicators.hide();

      // Page & Section manipulation components
      this.sectionTransformBox = this.canvasBody.insert(new lp.editor.SectionTransformBox(this));
      this.sectionTransformBox.hide();

      this.flashBox = new lp.editor.FlashBox(this);

      this.sectionBoundaryManager = new lp.editor.SectionBoundaryManager(this);
      this.pageBoundaryManager = new lp.editor.PageBoundaryManager(this);

      this.elementHilite = this.canvasBody.insert(new lp.editor.ElementHilite(this));
      this.elementHilite.hide();

      this.xSnapGuide = this.canvas.insert(new jui.Component({attributes:{className:'x-snap-guide'}}));
      this.xSnapGuide.hide();
      this.xSnapGuide.setOpacity(0.8);

      this.ySnapGuide = this.canvas.insert(new jui.Component({attributes:{className:'y-snap-guide'}}));
      this.ySnapGuide.hide();
      this.ySnapGuide.setOpacity(0.8);

      // Action Hint - shows information to help user while perfomring an opertation such as dragging
      // this.actionHintBar = this.canvas.insert(new lp.editor.ActionHintBar(this));
      // this.actionHintBar.hide();

      this.addTextEditor();

      // Sub UI components
      this.pageActionPanel = this.toolbar.insert(new jui.Component({attributes:{id: 'editor-page-action-panel' }}));
      this.navPanel = this.toolbar.insert(new jui.Component({attributes:{id: 'editor-nav-panel' }}));

      // Buttons for action panel
      this.saveButton = this.pageActionPanel.insert(new jui.IconButton('save', {label:'Save', action:this.save.bind(this), attributes:{className:'tool-button'}}));
      this.saveAsTemplateButton = this.pageActionPanel.insert(new jui.IconButton('save-as-template', {label:'Save as Template', action:this.saveAsTemplate.bind(this), attributes:{className:'tool-button'}}));
      this.saveAsTemplateButton.setVisible(this.showSaveAsTemplate);
      this.pageActionPanel.insert(new jui.IconButton('preview', {label:'Preview', action:this.preview.bind(this), attributes:{className:'tool-button'}}));

      this.pageActionPanel.insert(new Element('div',{className:'spacer'}));

      this.controls.undo      = this.pageActionPanel.insert(new jui.IconButton('undo', {label:'Undo', action:this.undo.bind(this,-1), attributes:{className:'tool-button'}}));
      this.controls.redo      = this.pageActionPanel.insert(new jui.IconButton('redo', {label:'Redo', action:this.redo.bind(this,1), attributes:{className:'tool-button'}}));

      this.controls.undo.disable();
      this.controls.redo.disable();

      this.pageActionPanel.insert(new Element('div',{className:'spacer'}));

      this.controls.up        = this.pageActionPanel.insert(new jui.IconButton('move_up', {label:'Move Up', action:this.changeOrder.bind(this,-1), attributes:{className:'tool-button'}}));
      this.controls.down      = this.pageActionPanel.insert(new jui.IconButton('move_down', {label:'Move Down', action:this.changeOrder.bind(this,1), attributes:{className:'tool-button'}}));
      this.controls.front     = this.pageActionPanel.insert(new jui.IconButton('front', {label:'Bring Forward', action:this.changeZIndex.bind(this,1), attributes:{className:'tool-button'}}));
      this.controls.back      = this.pageActionPanel.insert(new jui.IconButton('back', {label:'Send Backward', action:this.changeZIndex.bind(this,-1), attributes:{className:'tool-button'}}));

      this.pageActionPanel.insert(new Element('div',{className:'spacer'}));

      this.controls.duplicate = this.pageActionPanel.insert(new jui.IconButton('duplicate', {label:'Duplicate', action:this.duplicateElement.bind(this), attributes:{className:'tool-button'}}));
      this.controls.remove    = this.pageActionPanel.insert(new jui.IconButton('remove', {label:'Delete', action:this.removeElement.bind(this), attributes:{className:'tool-button'}}));

      this.controls.close = this.navPanel.insert(new jui.IconButton('close', {label:'Close Builder', action:this.closeEditor.bind(this), attributes:{className:'tool-button'}}));

      this.controls.widgets = [];

      this.createVisibleWidgets();

      this.updateControlStates();

      // install bubble hints
      this.bubbleTips = {};
      this.bubbleTips.addPageSection = new jui.BubbleTip({
        text     : 'To get started, click this button to add <strong>Page Sections</strong> for content areas like your header, body and footer.',
        arrowPos : 'left-top'
      });

      this.pageLoadErrorMessage = new jui.Component({attributes:{id: 'page-load-error-message'}});

      if (lp.isResponsiveEnabled()) {
        this.insertResponsiveUI();
      }

      this.handleToggleTree();
    },

    insertContentTreeToggleUI: function() {
      this.toggleTreeButton = this.toggleShelf.insert(new Element('a', {
        id:'treeToggle',
        className:'shelf-button'
      }).update('Page Content'));

      this.toggleTreeButton.observe('click', this.toggleTree.bind(this));
      this.triPane.addListener('layoutChanged', this.handleToggleTree.bind(this));
    },

    handleContentTreePaneVisibility: function() {
      if(this.treeToggler.isOpen()) {
        this.triPane.showPane('left');
      } else {
        this.triPane.hidePane('left');
      }
    },

    handleToggleTree: function() {
      if(this.triPane.panes.left.visible()) {
        $(this.toggleTreeButton).addClassName('open');
        this.treeToggler.open();
      } else {
        $(this.toggleTreeButton).removeClassName('open');
        this.treeToggler.close();
      }
    },

    toggleTree: function() {
      this.triPane.toggle('left');
    },

    insertResponsiveUI: function() {

      this.responsiveMobileButton = this.invisibleElementsShelf.insert(new Element('a', {
        id:'responsiveMobileButton',
        className:'device-mode',
        'data-role':'mobile-breakpoint-button'
      }).update('Mobile'));

      this.responsiveDesktopButton = this.invisibleElementsShelf.insert(new Element('a', {
        id:'responsiveDesktopButton',
        className:'active device-mode',
        'data-role':'desktop-breakpoint-button'
      }).update('Desktop'));

      var self = this;

      this.responsiveMobileButton.observe('click', function() {
        self.page.switchToBreakpoint('mobile');
      });

      this.responsiveDesktopButton.observe('click', function() {
        self.page.switchToBreakpoint('desktop');
      });
    },

    setBreakpointUITo: function(breakpoint) {
      var oldBreakpoint = breakpoint === 'mobile' ? 'Desktop' : 'Mobile';
      var oldButton = this['responsive' + oldBreakpoint + 'Button'];
      var currentButton = this['responsive' + breakpoint.capitalize() + 'Button'];
      if(oldButton && currentButton) {
        oldButton.removeClassName('active');
        currentButton.addClassName('active');
      }

      this.setCurrentBreakpoint(breakpoint);
    },

    setCurrentBreakpoint: function(breakpoint) {
      this.currentBreakpoint = breakpoint;
    },

    getCurrentBreakpoint: function() {
      return (this.currentBreakpoint || this.page.getCurrentBreakpoint().name)
        .toLowerCase();
    },

    covertPageSectionsToVerticalLayout: function() {
      this.page.getElementsByType('lp-pom-block').each( function(pageSection) {
        pageSection.model.setLayout({type: 'vertical', options:{gap: 20, margin: {top: 20, right: 20, bottom: 20, left: 20}}});
      });
    },

    addTextEditor: function() {
      // Text Editor Panel
      var self = this;
      this.textEditor = this.editingPanel.insert(new lp.editor.TextEditor(this, {
        attributes:{id: 'editor-text-editor'}
      }));
      this.textEditor.hide();

      this.textEditor.addListener('textEditorOpened', function() {
        self.canvas.setBottom(self.textEditor.getHeight());
        self.setUndoStates();
      });

      this.textEditor.addListener('textEditorClosed', function() {
        self.canvas.setBottom(40);
        self.transformBox.focus();
        self.setUndoStates();
      });
    },

    createVisibleWidgets: function() {
      var modules = lp.getModulesWithUsageType('page-widget');
      var hint = new Element('span', {className:'hint'}).update('click&nbsp;+&nbsp;hold&nbsp;to&nbsp;drag&nbsp;onto&nbsp;page');

      var clickHandler =  function(source) {
        var label = source.elm.down('.widget-label');
        if (hint.parentNode === null) {
          label.insert(hint);
        }
      };

      var mouseoutHandler = function(source) {
        source.elm.down('.widget-label');
        if (hint.parentNode !== null) {
          hint.remove();
        }
      };

      var self = this;

      modules.each(function(module) {
        var button = this.componentSideBar.insert(new jui.Component({attributes:{className:'widget-button dnd '+module.type}}));
        // button.insert(new Element('div', {className: 'widget-grip'}));
        // var icon = button.insert(new jui.Component({attributes:{className:'widget-icon'}}));
        button.insert(new Element('div', {className: 'widget-label'}).update(module.getElementDefaults().name.replace(' ', '&nbsp;')+'<br />'));
        button.insert(new Element('div', {className: 'widget-label-arrow'}));
        Object.extend(button, jui.ControlModel);
        this.controls.widgets.push({
          type: module.type,
          button: button,
          module: module
        });

        var dndGroup;
        if (module.type === 'lp-pom-block') {
          dndGroup = new jui.dnd.Source(button, lp.editor.dnd.sectionDnDGroup,
            {
              type: module.type,
              action: function(index) {
                module.addElementToPage(self.page, {container: self.page.getRootElement(), index:index});
              }
            },
            {
              centerGhost: true,
              click: clickHandler,
              mouseout: mouseoutHandler
            }
          );
        } else {
          dndGroup = new jui.dnd.Source(button, lp.editor.dnd.elementDnDGroup,
            {
              type: module.type,
              action: function(container, offset) {
                module.addElementToPage(self.page, {
                  container: container,
                  offset:offset,
                  center:module.type === 'lp-pom-text' ? false : true,
                  edit:true
                });
              }
            },
            {
              centerGhost: true,
              click: clickHandler,
              mouseout: mouseoutHandler
            }
          );
        }
      }, this);

      this.updateControlStates();

      // install bubble hints
      this.bubbleTips = {};
      this.bubbleTips.addPageSection = new jui.BubbleTip({
        text     : 'To get started, click this button to add <strong>Page Sections</strong> for content areas like your header, body and footer.',
        arrowPos : 'left-top'
      });

      this.pageMessageOverlay = new jui.Component({attributes:{id: 'page-message-overlay'}});
      this.pageMessageOverlay.e.setOpacity(0.5);
      this.pageMessage = new jui.Component({attributes:{id: 'page-message'}});

      this.addPageMessageToEditor();
      this.hidePageMessage();

      this.dynamicTextDialog = new lp.editor.DynamicTextDialog();

      if(typeof eventTracker !== 'undefined') {
        eventTracker.track('Access the Editor', 'Editor');
      }
    },

    assetsPathByCompanyId: function(companyId) {
      return '/' + companyId + '/assets';
    },

    imageAssetsPathByCompanyId: function(companyId) {
      return this.assetsPathByCompanyId(companyId) + '/images';
    },

    setUpdatedAt: function(jsonDate) {
      this.updatedAt = jsonDate;
    },

    getUpdatedAt: function() {
      return this.updatedAt;
    },

    assetsPathByCompanyIdAndType: function(companyId, type) {
      if('images' === type) {
        return this.imageAssetsPathByCompanyId(companyId);
      } else {
        return this.assetsPathByCompanyId(companyId);
      }
    },

    assetsPath: function() {
      return this.assetsPathByCompanyId(this.currentCompanyId);
    },

    loadPage: function(id, context) {
      this.loadPageFromURL('/variants/'+id+'/edit.json', id, context);
    },

    loadPageFromURL: function(url, id, context) {
      var self = this;
      return new Ajax.Request(url , {
        method: 'get',
        contentType:'application/json',
        onComplete: function() {
          self.fireEvent('complete');
        },
        onSuccess: function onSuccess(transport) {
          var data = transport.responseJSON;
          if (!data) {return;} // empty response cannot be handled

          if($H(data).get('updated_at')) {
            self.setUpdatedAt($H(data).get('updated_at'));
          }
          self.loadPageData(data, context);
          self.fireEvent('loaded', data);
          self.checkFormConfirmationDialog(data.mainPage.id, 'ON LOAD');
        },
        onException: function(transport, e) {
          self.pageLoadError(e);
          console.log(e); //JS: please leave in. It's to aid in debugging
          self.reportError({
            error: e,
            id: id,
            message: 'An error occurred while loading page data',
            content: transport.statusText + ': ' + transport.responseText,
            details: e.toString().truncate(300, ''), // this also gets truncated server side for hipchat but do it here anyway to limit data being sent
            userAgent: window.navigator.userAgent
          });
        },
        onFailure: function(transport, e) {
          self.pageLoadError(e);
          self.reportError({
            error: e,
            id: id,
            message: transport.statusText,
            content: transport.responseText,
            details: 'Status: ' + transport.status,
            userAgent: window.navigator.userAgent
          });
          console.log(e, transport);
        }
      });
    },

    addPageMessageToEditor: function() {
      this.insert(this.pageMessageOverlay);
      this.insert(this.pageMessage);
    },

    hidePageMessage: function() {
      this.pageMessage.update('');
      this.pageMessageOverlay.hide();
      this.pageMessage.hide();
    },

    getDynamicTextDialog: function() {
      return this.dynamicTextDialog;
    },

    showDynamicTextDialog: function(defaultText, attributes, callback) {
      this.dynamicTextDialog.open(defaultText, attributes, callback);
    },

    showPageMessage: function() {
      this.pageMessageOverlay.show();
      this.pageMessage.show();
    },

    // TODO: JS: perhaps this could be more generic?
    pageLoadError: function(error) {
      this.loadError = true;
      var message;

      if (error.code && error.code === LP.PAGE_VERSION_NEWER_ERR) {
        message = '<h2>We\'re sorry, we can\'t load your page</h2>' +
        '<p class="error">The page you are trying to load was created with a newer version of Unbounce.<br />'+
        '<a href="/pages/' + this.page_uuid + '">Return to Page Overview</a></p>';
      } else {
        message = '<h2>We\'re sorry, we can\'t load your page</h2>' +
        '<p class="error">An error occurred while trying to load your page.<br />'+
        'We have been notified of the problem and will look into it as soon as possible.<br />'+
        'If you continue to experience problems please have a look at our <a href="/support">Support Page</a><br /><br />'+
        '<a href="/pages/' + this.page_uuid + '">Return to Page Overview</a></p>';
      }

      this.pageMessage.update(message);
      this.showPageMessage();
    },

    saveWarningDialog: function() {
      var self = this;
      var message = new jui.Component({attributes:{className: 'warning'},elementType: 'p'}).
        update('This variant has been modified since you started working on it. ' +
        'Would you like to create a new variant from your version, or save anyways and ' +
        'overwrite any changes?');

      this.pageMessage.insert(message);

      this.blocked = true;

      message.insert(new jui.Button({
        attributes: {className: 'btn dark medium block force-save'},
        label: 'Save &amp; Overwrite Changes',
        action: function(){
          self.hidePageMessage();
          self.forceSave();
          self.blocked = false;
        }
      }));

      message.insert(new Element('span', {className: 'or'}).update('OR'));
      var cancel = new jui.Component({attributes:{className: 'cancel'}, elementType: 'span'}).update('X');

      cancel.e.observe('click', function(){
        self.hidePageMessage();
        self.saveButton.enable();
          self.blocked = false;
      });

      message.insert(cancel);

      message.insert(new jui.Button({
        attributes: {className: 'btn dark medium block new-variant'},
        label: 'Save as new Variant',
        action: function() {
          self.hidePageMessage();
          self.saveAsNewVariant();
          self.blocked = false;
        }
      }));

      this.showPageMessage();
    },

    reportError: function(error) {
      //we may not have access to the errors content at all times.
      var content = error.content ? error.content : '';
      var postBody = '<?xml version="1.0" encoding="utf-8"?>'+
        '<error>'+
          '<id>'+error.id+'</id>'+
          '<error><![CDATA['+error.error+']]></error>'+
          '<message><![CDATA['+error.message+']]></message>'+
          '<details><![CDATA['+error.details.gsub(']]>',']]]]><![CDATA[>')+']]></details>'+
          '<content><![CDATA['+content.gsub(']]>',']]]]><![CDATA[>')+']]></content>'+
          '<user_agent><![CDATA['+error.userAgent+']]></user_agent>'+
        '</error>';
      this.traceEditorErrors(error);

      return new Ajax.Request('/variants/'+error.id+'/error' , {
        method: 'post',
        postBody: postBody,
        contentType:'application/xml'
      });
    },
    barf: function () {
      throw new Error('test error');
    },

    loadPageData: function(pageData, context) {
      this.runPOMUpdaters(pageData);
      this.mainPage = new lp.pom.Page(pageData.mainPage, context);
      this.unbounceCompanyId = pageData.unbounce_company_id;
      this.unbounceAssetsCompanyId = pageData.unbounce_assets_company_id;
      this.currentCompanyId = pageData.current_company_id;
      this.addPage(this.mainPage, 'top');

      pageData.subPages.each( function(subPage) {
        this.addPage(new lp.pom.Page(subPage, context));
      }, this);

      this.setActivePage(this.mainPage);
      this.fireEvent('pageLoaded', this.mainPage);
      this.recordPagesState();

      this.errorNotifier = new lp.ErrorNotifier().install();
    },

    runPOMUpdaters: function(pageData) {
      lp.pom.updates.run(pageData.mainPage);
      var i,
          nPages = pageData.subPages.length;
      for (i=0; i < nPages; i++) {
        lp.pom.updates.run(pageData.subPages[i]);
      }
    },

    addPage: function(page, position) {
      this.pages.push(page);

      page.elements.each(function(elm){
        this.addElementListeners(elm);
      }, this);

      page.addListener('elementInserted', this);
      page.addListener('elementRemoved', this);
      page.addListener('pageUpdatedAndRefreshed', this);
      page.addListener('beforeBreakpointChanged', this);
      page.addListener('breakpointChanged', this);
      this.pageTabs.addPage(page, position);
      this.addPageMessageToEditor();
    },

    removePage: function(page) {
      this.page.undoManager.registerUndo({
        action: this.addPage,
        receiver: this,
        params: [page]
      });
      page.elements.each(function(elm){
        this.removeElementListeners(elm);
      }, this);

      page.removeListener('elementInserted', this);
      page.removeListener('elementRemoved', this);

      this.pageTabs.removePage(page);
      this.pages = this.pages.without(page);
    },

    findPageById: function(id) {
      return this.pages.find( function(page) {return page.id === id;});
    },

    findPagesUsedAs: function(usedAs) {
      return this.pages.findAll( function(page) {return page.page.used_as === usedAs;});
    },

    setActivePage: function(page) {
      var previous = this.page;
      if (previous !== null) {
       previous.scrollPosition = this.canvasBody.e.scrollTop;
      }

      this.page = page;

      this.updateCanvasLayout();

      var mainPageForms   = this.mainPage.getElementsByType('lp-pom-form');
      var mainPageHasForm = mainPageForms.length > 0;
      var confirmAction;
      if (mainPageHasForm) {
        confirmAction = mainPageForms[0].model.get('content.confirmAction') || null;
      }

      if (page.page.used_as === 'form_confirmation' && confirmAction !== 'modal') {
       var warning = '<h2>This Form Confirmation Dialog is not currently in use.</h2> ' +
         '<p>If you would like this to be displayed to your users after they successfully submit a form, ' +
         'either click the button to the left or ' +
         'select the form on the Main Page and choose "Show form confirmation dialog" in the "Confirmation" ' +
         'drop-down menu in the Form\'s properties panel. You can edit this dialog just like any other page</p>';

       this.showPageWarning(warning, confirmAction);
      } else {
       this.hidePageWarning();
      }

      this.fireEvent('beforeActivePageChanged');
      page.insertPage(this.pageBody);
      this.fireEvent('activePageChanged', {current:page, previous:previous});
      this.setActiveUndoManager(page.undoManager);
      this.setActiveRoot(page.getRootElement());

      this.canvasBody.e.scrollTop = page.scrollPosition || 0;
      this.page.switchToBreakpoint(this.getCurrentBreakpoint());
   },

    setActiveUndoManager: function(undoManager) {
      if (this.activeUndoManager !== null) {
        this.activeUndoManager.removeListener('undoRegistered', this.undoListener);
        this.activeUndoManager.removeListener('undoPerformed', this.undoListener);
        this.activeUndoManager.removeListener('redoPerformed', this.undoListener);
      }

      this.activeUndoManager = undoManager;
      this.activeUndoManager.addListener('undoRegistered', this.undoListener);
      this.activeUndoManager.addListener('undoPerformed', this.undoListener);
      this.activeUndoManager.addListener('redoPerformed', this.undoListener);
      this.setUndoStates();
    },

    setActiveRoot: function(elm) {
      var previous = this.activeRoot;
      if (previous !== null) {
        previous.removeListener('layoutChanged', this.rootLayoutListener);
        previous.remove();
      }

      this.activeRoot = elm;
      this.activeRoot.addListener('layoutChanged', this.rootLayoutListener);
      this.setActiveElement(elm);
      this.fireEvent('activeRootChanged', {current:elm, previous:previous});
    },

    activeElementConstraintsChanged: function(){
      this.updateControlStates();
    },

    setActiveElement: function(elm, forceSetActive) {
      if (this.activeElement === elm && !forceSetActive) {
        return;
      }

      if (this.activeElement !== null) {
        this.activeElement.deactivate();
        this.fireEvent( 'elementDeactivated', this.activeElement);
      }

      this.deactivateCurrentActiveElement();
      this.activateCurrentElement(elm);

      if (elm === null) {
        return;
      }

      this.activeElement.addListener(
        'elementConstraintsChanged',
        this.activeElementConstraintsListener
      );

      elm.activate();

      this.fireEvent( 'elementActivated', elm);

      if (this.activeElement.is('displayable') || this.activeElement.type !== 'lp-pom-root') {
        this.scrollToElement(elm);
        this.flashBox.flash(elm);
      }
    },

    activateCurrentElement: function(elm) {
      this.activeElement = elm;
      this.updateControlStates();
      this.setBubbleTipsStates();
    },

    deactivateCurrentActiveElement: function() {
      if(this.activeElement) {
        this.activeElement.removeListener(
          'elementConstraintsChanged',
          this.activeElementConstraintsListener
        );
      }
    },

    updateCanvasLayout: function() {
      var pDims = this.page.getDimensions();
      var cDims = this.pageBody.getDimensions();
      var pageRoot = this.page.getRootElement();
      if (this.page.usedAs() === 'form_confirmation') {
        this.canvasBody.e.addClassName('modal');
        pageRoot.setWidth(pDims.width);
        pageRoot.setOffset({left:(cDims.width - pDims.width) / 2, top:32});
      } else {
        this.canvasBody.e.removeClassName('modal');
        pageRoot.setWidth(cDims.width);
        pageRoot.setOffset({left:0, top:0});
      }

      var w = Math.max((this.page.getContentWidth() + 40), this.canvasBody.e.clientWidth);

      if (this.page.usedAs() !== 'form_confirmation') {
        this.pageBody.setWidth(w);
        this.page.getRootElement().setWidth(w);
      }

      this.fireEvent('layoutChanged');
    },

    closeEditor: function() {
      window.location.href = '/pages/'+this.mainPage.page.uuid;
    },

    recordPagesState: function() {
      this.lastSavedJSON = '';
      this.pages.each( function(page) {
         this.lastSavedJSON += Object.toJSON(page.toJSO());
      }, this);
    },

    hasUnsavedChanges: function() {
      if(!this.mainPage) { return false; }
      var current = this.pages.map(function jsonifyJSO(page){
        return Object.toJSON(page.toJSO());
      }).join('');
      return current !== this.lastSavedJSON;
    },

    forceSave: function() {
      this.sendSaveRequest(true);
    },

    save: function() {
      //Check to see if saving is blocked.  This is to prevent save from being
      //called again if we are waiting for user input such as when there is a
      //save conflict.
      if(!this.blocked && !this.savingInProgress) {
        this.sendSaveRequest();
      }
    },

    saveAsNewVariant: function() {
      this.postSaveData(
        this.buildSaveAsNewVariantUrl(this.mainPage),
        this.mainPage.id,
        this.buildSaveData(),
        function(transport) {
          window.location.href = transport.responseJSON.new_variant_url;
        }
      );
    },

   sendSaveRequest: function(forceSave) {
      var self = this;

      document.activeElement.blur();

      this.errorMsg = 'There was a problem saving your changes. Please try again.';
      this.savingInProgress = true;
      this.saveButton.disable();
      this.saveButton.pulse(250, 0.75);
      this.saveButton.setLabel('Saving...');
      try {
        this.prepareModulesForSave(this.mainPage.id);
        this.validateTitleTag();
        this.postSaveData(
          this.buildSaveUrlForPage(this.mainPage),
          this.mainPage.id,
          this.buildSaveData(forceSave),
          function(transport) {
            var json = $H(transport.responseJSON);
            if (json.get('updated_at')) {
              self.setUpdatedAt(json.get('updated_at'));
              json.unset('updated_at');
            }

            $H(json).each( function(pair) {
              var page     = self.findPageById(pair.key);
              page.page.id = pair.value.page_id;
              page.id      = pair.value.page_variant_id;
            });
          }
        );

        this.recordPagesState();

      } catch(e) {
        this.reenableSave();
        alert(this.errorMsg);
        var message = e.message ? e.message + ': ' : '';
        message += e.stack ? e.stack.toString() : '';
        console.log(message);
        this.reportError({
          error: e,
          id: this.mainPage.id,
          message: message,
          details: 'Problems Saving!',
          userAgent: window.navigator.userAgent
        });
      }
    },

    validateTitleTag: function() {
      var title = window.editor.page.metaData.title;
      if(title.length > 4000) {
        this.errorMsg = this.errorMsg +=
          '\n\nToo many characters used in the page properties title.';
        throw 'TitleTooLongError';
      }
    },

    buildSaveUrlForPage: function(page) {

      var routeName = page.resourceType.underscore() + 's';

      // The rails app uses /variants as the route for PageVariant,
      // handle that special case.
      if(routeName === 'page_variants'){
        routeName = 'variants';
      }

      return '/'+routeName+'/'+page.id+'/save.xml';
    },

    buildSaveAsNewVariantUrl: function(page) {
      return '/variants/' + page.id + '/save_as_new_variant.xml';
    },

    saveAsTemplate: function() {
      var self = this;
      this.saveAsTemplateButton.disable();
      this.postSaveData(
        '/' + this.currentCompanyId + '/page_templates.xml',
        this.mainPage.id,
        this.buildSaveData(),
        function(transport) {
          /*jshint unused:vars */
          self.saveAsTemplateButton.enable();
        }
      );
    },

    buildSaveData: function(forceSave) {
      this.pages.invoke('prepareForSave');

      var mainPageXML  = this.mainPage.toXML(),
          subPagesXML  = '',
          forceSaveStr = forceSave ?  '<force_save>true</force_save>' : '';

      this.pages.without(this.mainPage).each( function(subPage) {
        subPagesXML += subPage.toXML();
      });

      return '<?xml version="1.0" encoding="utf-8"?>'+
        '<data>'+
          '<main_page>'+mainPageXML+'</main_page>'+
          '<sub_pages>'+subPagesXML+'</sub_pages>'+
          '<updated_at>'+this.getUpdatedAt()+'</updated_at>'+
          forceSaveStr +
        '</data>';
    },

    prepareModulesForSave: function(id) {
      this.checkFormSubmitButton(id);
      this.checkFormConfirmationDialog(id, 'DURING SAVE');
    },

    checkFormConfirmationDialog: function(id, when) {
      var form = this.page.getForm();
      //Is it missing a form confirmation dialog?
      if(form && window.editor.pages.length < 2) {
        this.reportError({
          error     : '',
          id        : id,
          message   : '**Missing Form Confirmation on ' + when,
          details   : '**Missing Form Confirmation**',
          userAgent : window.navigator.userAgent
        });
      }
    },

    checkFormSubmitButton: function(id) {
      var form = this.page.getForm();
      if(form && !form.isButtonAttached()) {
        var details = 'The from action was -' + form.submitButtonAction();
        form.reattachDetachedSubmitButton();
        this.reportError({
          error     : '',
          id        : id,
          message   : 'A detached form button has been detected and fixed. ' + details,
          details   : 'Detached Form Error',
          userAgent : window.navigator.userAgent
        });
      }
    },

    reenableSave: function() {
      this.saveButton.stopPulse();
      this.saveButton.enable();
      this.saveButton.setLabel('Save');
      this.savingInProgress = false;
    },

    postSaveData: function(url, id, data, completeAction) {
      this.tracePageSaves({id:id, url:url, data:data});
      var self = this;
      return new Ajax.Request(url, {
        method: 'post',
        postBody: data,
        contentType:'application/xml',
        onComplete: function(){
          //Moved these into onComplete so they happen regardless if
          //the save fails or not.
          self.reenableSave();
        },
        onSuccess: completeAction,
        onFailure: function(transport, e) {
          self.reportError({
            error: e,
            id: id,
            message: 'Problem attempting to save: ' + transport.statusText,
            content: transport.responseText,
            details: 'Status: ' + transport.status,
            userAgent: window.navigator.userAgent
          });
          alert('There was a problem saving your changes. please try again');
          console.log(e);
          self.saveButton.enable();
        },
        on0: function() {
          alert('Your changes were not saved because it appears that you\'ve lost network conectivity. Please re-connect and try again.');
        },
        on409: function() {
          self.saveWarningDialog();
        }
      });
    },

    /* JS: TODO: This doesn't work when previewing a tempate */
    preview: function() {
      window.open(this.mainPage.getPreviewURL());
    },

    setPageWarningContent: function(textMessage) {
      this.pageMessage.clear();
      var message = new jui.Component({attributes:{className: "warning"},elementType: 'p'}).update(textMessage);
      this.pageMessage.insert(message);
    },

    showPageWarning: function(warning, action) {
      var self = this;
      this.pageWarning.update(warning);
      if (action !== 'modal') {
        this.pageWarningButton = this.pageWarning.insert(new jui.Button({
          label:"Use this dialog as my<br />Form Confirmation",
          action:function() {
            if (self.mainPage.getElementsByType('lp-pom-form').length > 0) {
              self.mainPage.getElementsByType('lp-pom-form')[0].model.set('content.confirmAction', 'modal', self.mainPage.undoManager);
              self.hidePageWarning();
            }
          }
        }));
      }
      this.pageWarning.show();
    },

    hidePageWarning: function() {
      this.pageWarning.hide();
    },

    undo: function() {
      if (this.activeUndoManager.canUndo() && !this.isModalOpen() && !this.isTextEditorOpen()) {
        this.page.undoManager.undo();
      }
    },

    redo: function() {
      if (this.activeUndoManager.canRedo() && !this.isModalOpen() && !this.isTextEditorOpen()) {
        this.page.undoManager.redo();
      }
    },

    addScript: function() {
      lp.getModule('lp-script');
    },

    addStylesheet: function() {
      lp.getModule('lp-stylesheet');
    },

    editMetaData: function() {
      var module = lp.getModule('lp-metadata');
      module.openManager(this.page);
    },

    openAssetChooser: function(options) {
      this.assetChooser = this.assetChooser || new lp.editor.AssetChooserDialog();
      this.assetChooser.open(options);
    },

    changeZIndex: function(direction) {
      // JS: TODO: this logic should probably be used to enable/disable buttons
      if (this.activeElement === null || this.activeElement === this.page.getRootElement() || !this.activeElement.is('z_indexable')) {
        return;
      }

      this.activeElement.changeZIndex(direction);
    },

    changeOrder: function(direction) {
      // JS: TODO: this logic should probably be used to enable/disable buttons
      if (this.activeElement === null ||
          this.activeElement === this.page.getRootElement() ||
          !this.activeElement.is('displayable') ||
          !this.activeElement.is('orderable')) {
        return;
      }

      this.activeElement.getParentElement().changeOrder(this.activeElement, direction);
    },

    removeElement: function() {
      if (!this.activeElement.is('removable')) {
        return;
      }

      var self = this;
      var removeFunc = function() {
        if(self.activeELement === null){
          return;
        }

        self.page.undoManager.startGroup();
        self.activeElement.removeFromPage();
        self.page.undoManager.endGroup();
      };

      if (typeof this.activeElement.handleRemoval !== 'function' || !this.activeElement.handleRemoval(removeFunc)) {
        removeFunc();
      }
    },

    duplicateElement: function() {
      if(this.activeELement === null || !this.activeElement.isCopyable()){
        return;
      }

      var isMultiSelect = this.activeElement.type === 'lp-multi-select';

      var offset;
      this.page.undoManager.startGroup();

      if (this.activeElement.model.exists('geometry.offset')) {
        offset = this.activeElement.model.getOffset();
      }

      var clone = this.activeElement.clone();

      if (clone.model.exists('geometry.offset') && clone.type !== 'lp-pom-block') {
        var clonedOffset = {left:offset.left + 10, top:offset.top + 10};
        clone.model.setOffset(clonedOffset);
        if (! this.page.isCurrentBreakpoint('desktop')) {
          clone.model.setOffsetByBreakpoint(clonedOffset, 'desktop');
        }
      }

      if (isMultiSelect) {
        this.page.undoManager.registerUndo({
          action: this.setActiveElement,
          receiver: this,
          params: [this.page.getRootElement()]
        });
      }

      this.setActiveElement(clone);
      this.page.undoManager.endGroup();
    },

    elementInserted: function(e) {
      var elm = e.data;

      this.addElementListeners(elm);
      if (elm.activateOnInsert && elm.getRootElement() === this.activeRoot) {
        this.setActiveElement(elm);
      }

      this.setBubbleTipsStates();
      this.updateControlStates();

      this.fireEvent('elementInserted', elm);
    },

    elementRemoved: function(e) {
      var elm = e.data.element;
      this.removeElementListeners(elm);

      if(this.activeElement===elm){
        this.controls.remove.disable();
        this.controls.duplicate.disable();
        this.setActiveElement(null);
      }
      this.setBubbleTipsStates();
      this.updateControlStates();
      this.fireEvent('elementRemoved', e.data);
    },

    rootLayoutChanged: function() {
      this.updateCanvasLayout();
    },

    editModal: function(id) {
      this.setActiveRoot(this.page.getElementById(id).getRootElement());
    },

    setBubbleTipsStates: function() {
      // this.bubbleTips.addPageSection[this.activeRoot.hasSections() ? 'hide' : 'show']({origin:this.controls.addBlock.cumulativeOffset(), offset:{left:80, top:4}});
    },

    updateControlStates: function() {
      var elm = this.activeElement;
      var baseCriteria = elm !== null && elm.type !== 'lp-pom-root' && elm.parentElement !== null;

      this.controls.remove.setEnabled(baseCriteria && elm.is('removable'));
      this.controls.duplicate.setEnabled(baseCriteria && elm.isCopyable());

      this.controls.widgets.each(function(widget) {
        var module = lp.getModule(widget.type);
        widget.button.setEnabled(
          this.page !== null &&
          (this.page.hasContent() || widget.type === 'lp-pom-block') &&
          !(widget.type === 'lp-pom-form'  && this.page.page.used_as === 'form_confirmation') &&
          (typeof module.limit === 'undefined' || this.page.getElementsByType(module.type).length < module.limit)
        );
      }, this);

      this.controls.back.setEnabled(baseCriteria && elm.is('z_indexable') && elm.parentElement.getFirstElementByZIndex() !== elm);
      this.controls.front.setEnabled(baseCriteria && elm.is('z_indexable') && elm.parentElement.getLastElementByZIndex() !== elm);

      var children = baseCriteria ? elm.parentElement.childElements : [];
      var upable = children.length > 1 && children.first() !== elm;
      var downable = children.length > 1 && children.last() !== elm;

      this.controls.up.setEnabled(baseCriteria && elm.is('displayable') && elm.is('orderable') && upable);
      this.controls.down.setEnabled(baseCriteria && elm.is('displayable') && elm.is('orderable') && downable);
    },

    setUndoStates: function() {
      this.controls.undo.setEnabled(!this.loadError && this.activeUndoManager.canUndo() && !this.isModalOpen() && !this.isTextEditorOpen());
      this.controls.redo.setEnabled(!this.loadError && this.activeUndoManager.canRedo() && !this.isModalOpen() && !this.isTextEditorOpen());
    },

    isModalOpen: function() {
      return jui.ModalPanel.isModalOpen();
    },

    isTextEditorOpen: function() {
      return this.textEditor.isOpen;
    },

    scrollToElement: function(elm) {
      if (!elm.is('displayable') || elm.type === 'lp-pom-root') {
        return;
      }

      var offset = elm.getPageOffset();
      var size = elm.model.getSize();
      var canvasSize = this.canvasBody.getDimensions();
      var scrollTop = this.canvasBody.e.scrollTop;

      if (offset.top > canvasSize.height + scrollTop || offset.top + size.height < scrollTop) {
        var scrollTo = (offset.top < scrollTop) ?
          offset.top :
          ((offset.top + size.height - scrollTop) > canvasSize.height) ?
            Math.max(offset.top + size.height - canvasSize.height, 0) :
            scrollTop;

        if (scrollTop !== scrollTo) {
          this.canvasBody.e.scrollTop = scrollTo;
        }
      }
    },

    elementSelected: function(e){
      var elm = e.data;
      this.setActiveElement(elm);
    },

    wait: function() {
      if (this.waiting) {
        return;
      }

      this.waiting = true;
      this.insert(this.waitPanel);
    },

    stopWaiting: function() {
      this.waiting = false;
      this.waitPanel.remove();
    },

    snappedToContainer: function(e) {
      var o = this.activeElement.getParentElement().getPageOffset();
      var scrollY = editor.canvasBody.e.scrollTop;
      var scrollX = editor.canvasBody.e.scrollLeft;

      switch (e.data.axis) {
        case 'x':
          this.xSnapGuide.setLeft(e.data.snap + o.left - scrollX);
          this.xSnapGuide.setHeight(this.pageBody.getHeight());
          this.xSnapGuide.show();
          break;
        case 'y':
          this.ySnapGuide.setTop(e.data.snap + o.top - scrollY);
          this.ySnapGuide.setWidth(this.pageBody.getWidth());
          this.ySnapGuide.show();
      }
    },

    notSnappedToContainer: function(e) {
      this[e.data.axis === 'x' ? 'xSnapGuide' : 'ySnapGuide'].hide();
    },

    updateTextElementHeights: function() {
      this.page.getElementsByType('lp-pom-text').each( function(textElement) {
        textElement.updateHeight();
      });
    },

    pageUpdatedAndRefreshed: function() {
      this.sectionBoundaryManager.layoutChanged();
      this.pageBoundaryManager.layoutChanged();
      this.sectionBoundaryManager.setBoundaryVisibility();
      if (this.activeElement && ! this.activeElement.isVisibleOnPage()) {
        // NOTE: see LP-6713 and the page section visibility features in Aug 2014.
        this.setActiveElement(this.page.getRootElement());
      }
    },
    beforeBreakpointChanged: function (/*ev*/) {
      // the active element should always be blurred on breakpoint
      // changes to a) avoid model mutation bugs and b) to ensure
      // that the page properties panel and the enable responsive
      // checkbox is visible when a user switches to mobile for the
      // first time
      this.setActiveElement(this.page.getRootElement());
    },
    breakpointChanged: function(event) {
      this.setBreakpointUITo(event.data);
      this.updateCanvasLayout();
    },

    fixStyleBorderIssue: function() {
      var pageElements, brokenElementBorderStyles;

      if (editor.page) {
        pageElements = this.page.model.elements;
        brokenElementBorderStyles = pageElements.select(function(element){
          if(element.model.exists('style.border.style') &&
             typeof element.model.get('style.border.style') !== 'string') {

            console.log('BEFORE:', element.id, element.model.get('style.border'));
            element.model.set('style.border.style', 'none');
            console.log('AFTER:', element.id, element.model.get('style.border'));
            return true;
          }
        });
        return brokenElementBorderStyles;
      } else {
        console.error('THIS PAGE NEEDS HELP!!');
      }
    }

  });
})();
