/* globals rx_switchboard */
lp.pom.ElementModel = Class.create( jui.EventSource, {
  initialize: function(element, modelData){

    this.element   = element;
    this.jso       = modelData;
    this.modelData = modelData;
    this._readableModelCache = {}; // {breakpointName: readModelCache}
    this._traceSet = rx_switchboard.getPublisher('element_set');
    this._traceDeleteAttr = rx_switchboard.getPublisher('element_delete_attr');

    if (typeof this.modelData.breakpoints === 'undefined') {
      this.modelData.breakpoints = {};
    }

    this.breakpointModifyableAttributes        = {};
    this.breakpointAllowDuplicateAttributes    = {};
    this.breakpointShallowModifyableAttributes = {};

    this._suppressEvents  = false;
    this.suppressedEvents = [];
  },

  suppressEvents: function() {
    this._suppressEvents = true;
  },

  releaseEvents: function() {
    this._suppressEvents = false;
    this.suppressedEvents.each(function(e) {
      this._fireEvent(e.type, e.data);
    }, this);
    this.suppressedEvents = [];
  },

  _fireEvent: function(type, data) {
    if (this._suppressEvents) {
      this.suppressedEvents.push({type:type, data:data});
    } else {
      this.fireEvent(type, data);
    }
  },

  addBreakpointShallowModifyableAttributes: function(object) {
    Object.extend(this.breakpointShallowModifyableAttributes, object);
  },

  addBreakpointModifyableAttributes: function(object) {
    // TODO-TR: LP-6616 this Object.extends should be replaced with _.merge
    Object.extend(this.breakpointModifyableAttributes, object);
  },

  addBreakpointAllowDuplicateAttributes: function(object) {
    // TODO-TR: LP-6616 this Object.extends should be replaced with _.merge
    Object.extend(this.breakpointAllowDuplicateAttributes, object);
  },

  isAttributeModifyableForBreakpoint: function(key) {
    return jui.isDefinedAndTruthy(this.breakpointModifyableAttributes,key);
  },

  isAttributeAllowedDuplicateOnBreakpoint: function(key) {
    return jui.isDefinedAndTruthy(this.breakpointAllowDuplicateAttributes,key);
  },

  isAttributeDefinedOnDefaultModelData: function(key) {
    return jui.isDefined(this.modelData, key);
  },

  isAttributeDefinedOnElementDefaults: function(key) {
    return jui.isDefined(this.element.getElementDefaults(), key);
  },

  isAttributeShallowModifyableForBreakpoint: function(key) {
    var shallowLookup = true;
    return jui.isDefinedAndTruthy(
      this.breakpointShallowModifyableAttributes,
      key,
      shallowLookup
    );
  },

  getReadableModel: function(breakpoint) {
    breakpoint = breakpoint || this.element.page.getCurrentBreakpoint();
    if (this._readableModelCache[breakpoint.name]) {
      return this._readableModelCache[breakpoint.name];
    }

    var modelData = this.modelData;
    var breakpointModel = modelData.breakpoints[breakpoint.name];
    var model;
    if (breakpointModel) {
      model = jui.extend(true, {}, modelData, breakpointModel);
    } else {
      // clone it
      model = JSON.parse(JSON.stringify(modelData));
    }
    this._readableModelCache[breakpoint.name] = model;
    return model;
  },


  isModifyable: function(key) {
    return this.isAttributeModifyableForBreakpoint(key) ||
      this.isAttributeShallowModifyableForBreakpoint(key);
  },

  getWritableModel: function(key, breakpoint) {
    breakpoint = breakpoint || this.element.page.getCurrentBreakpoint();
    if (!breakpoint.default && this.isModifyable(key)) {
      if (typeof this.modelData.breakpoints[breakpoint.name] === 'undefined') {
        this.modelData.breakpoints[breakpoint.name] = {};
      }
      return this.modelData.breakpoints[breakpoint.name];
    }

    return this.modelData;
  },
  _clearReadableModelCache: function() {
    delete this._readableModelCache;
    this._readableModelCache = {};
  },

  setOnWritableModel: function(key, value) {
    var breakpoint = this.element.page.getCurrentBreakpoint();
    return this.setOnWritableModelByBreakpoint(key, value, breakpoint);
  },

  setOnWritableModelByBreakpoint: function(key, value, breakpoint) {
    this._clearReadableModelCache();
    if(typeof breakpoint === 'string') {
      breakpoint = this.element.page.getBreakpointByName(breakpoint);
    }

    var nonDefaultBreakpoint = !breakpoint.default;
    var writableModel = this.getWritableModel(key, breakpoint);

    if (nonDefaultBreakpoint && this.isModifyable(key) &&
        this.existsOnDefaultModel(key) && !this.isAttributeAllowedDuplicateOnBreakpoint(key) &&
        JSON.stringify(value) === JSON.stringify(this.getFromDefaultModel(key))) {

      if (jui.isDefined(writableModel, key)) {
        jui.deepDelete(writableModel, key);
      }

      return;
    }

    var returnValue = jui.deepSet(this.getWritableModel(key, breakpoint), key, value);

    if (this.isAttributeDefinedOnElementDefaults(key) &&
        nonDefaultBreakpoint &&
        !this.isAttributeDefinedOnDefaultModelData(key)) {
      jui.deepSet(this.modelData, key, value);
    }

    return returnValue;
  },

  exists: function(accessor) {
    var modelData = this.modelData;
    if (jui.isDefined(modelData, accessor)) {
      return true;
    } else {
      var breakpoint = this.element.page.currentBreakpoint;
      var breakpointModel = modelData.breakpoints[breakpoint.name];
      return breakpointModel && jui.isDefined(breakpointModel, accessor);
    }
  },

  existsOnDefaultModel: function(accessor) {
    return jui.isDefined(this.modelData, accessor);
  },

  get: function(accessor, breakpoint) {
    return this._get(accessor, this.getReadableModel(breakpoint));
  },

  //Return the values from both breakpoints as an array in not paticular order. Returns
  //an empty array if no values are found in the model.
  getValuesFromBothBreakpoints: function(accessor) {
    return _.compact(_.map(this.element.page.getBreakPoints(), function(breakpoint) {
      return this.safeGet(accessor, breakpoint);
    }, this));
  },

  safeGet: function(accessor, breakpoint) {
    return this._safeGet(accessor, this.getReadableModel(breakpoint));
  },

  getFromDefaultModel: function(accessor) {
    return this._get(accessor, this.modelData);
  },

  isSettable: function(accessor, value) {
    // no-op
  },

  reportValidationError: function(error, accessor, value){
    var errorMessage = "setting '" + accessor + "' to '" + JSON.stringify(value) + "'\n" +
      "element: " + this.element.id + "\n" +
      "stack trace: \n" + error.stack;

    var errorObj = {
      id        : window.editor.page.id,
      message   : "Setting invalid property",
      content   : errorMessage,
      details   : errorMessage,
      error     : error,
      userAgent : window.navigator.userAgent
    };

    window.editor.reportError(errorObj);
    console.log(errorObj);
    return errorObj;
  },

  set: function(accessor, value, undoManager) {
    this._traceSet({type: 'element_model.set',
                    id: this.element.id,
                    accessor: accessor,
                    value: value});
    var exists = this.exists(accessor);
    var previous;
    if (exists) {
      previous = Object.toJSON(this.get(accessor)).evalJSON();
    }

    try {
      this.isSettable(accessor, value);
      var attr = this.setOnWritableModel(accessor, value);

      if (undoManager) {
        if (exists) {
          if (previous !== attr) {
            undoManager.registerUndo({
              action:this.set,
              receiver:this,
              params: [accessor, previous, undoManager]
            });
          }
        } else {
          undoManager.registerUndo({
            action:this.deleteAttr,
            receiver:this,
            params:[accessor, undoManager]
          });
        }
      }

      if (previous !== attr && !this.isAttrChangeEventSuppressed(accessor)) {
        // TODO: the line above is incorrect because of object equality **BUT**
        // other bits of the code, such as the forms styling, depend on the bug.
        // This correct implementation causes specs to fail:
        //if (!_.isEqual(previous, attr) && !this.isAttrChangeEventSuppressed(accessor)) {
        this._fireEvent('attributeChanged', {accessor:accessor, value:attr, previous:previous});
      }
      return attr;
    } catch(e) {
      this.reportValidationError(e, accessor, value);
    }

  },

  isAttrChangeEventSuppressed: function(accessor) {
    return !!(typeof this.attributeChangeIgnore === 'function' &&
       this.attributeChangeIgnore(accessor));
  },

  getUndoAttrsFor: function(previous, attr, accessor, undoManager){
    var undoAttrs = {};
    undoAttrs.receiver = this;
    if (attr) {
      if (previous !== attr) {
        undoAttrs = {
          action : this.set,
          params : [accessor, previous, undoManager]
        };
      }
    } else {
      undoAttrs = {
        action : this.deleteAttr,
        params : [accessor, undoManager]
      };
    }
    return undoAttrs;
  },

  fireAttributeChanged: function(attr, previous, accessor){
    if (previous === attr) {
        return false;
    }
    this._fireEvent('attributeChanged', {
      accessor : accessor,
      value    : attr,
      previous : previous
    });
    return true;
  },

  deleteAttr: function(accessor, undoManager) {
    this._traceDeleteAttr({type: 'element.deleteAttr',
                           id: this.element.id,
                           accessor: accessor});
    if (this.exists(accessor)) {
      var previous = Object.toJSON(this.get(accessor)).evalJSON();
      this._clearReadableModelCache();
      jui.deepDelete(this.getWritableModel(accessor), accessor);
      if (undoManager) {
        undoManager.registerUndo({
          action:this.set,
          receiver:this,
          params:[accessor, previous, undoManager]
        });
      }
      this._fireEvent('attributeChanged', {accessor:accessor, value:null, previous:previous});
    }
  },

  isTrue: function(accessor) {
    return this.isEqualTo(accessor, true);
  },

  isEqualTo: function(accessor, value) {
    return this.exists(accessor) && this.get(accessor) === value;
  },

  clone: function() {
    var obj = Object.toJSON(this.modelData).evalJSON();
    obj.id = null;
    return obj;
  },

  _get: function(accessor, attr) {
    var arr = accessor.split('.');
    for (var i=0,l=arr.length;i<l;i++) {
      attr = attr[arr[i]];
      if (typeof attr === "undefined") {
        throw "Element model attribute '"+accessor+"' doesn't exist";
      }
    }

    return attr;
  },

  //Safe get returns undefined rather than throwing an exception.
  _safeGet: function(accessor, attr) {
    var arr = accessor.split('.');
    for (var i=0,l=arr.length;i<l;i++) {
      attr = attr[arr[i]];
    }

    return attr;
  }
});
