//  require <jui>
jui.FormLinkInput = Class.create( jui.Component, jui.ControlModel, {
  options: function($super,options){
    return $super($H({
      attributes:{className:'form-elm link-input'},
      label:'Link',
      selectOptions:[
        {name:'http', value:'http'},
        {name:'https', value:'https'},
        {name:'ftp', value:'ftp'},
        {name:'mailto', value:'mailto'}
        ]
    }).merge(options));

  },

  initialize:function( $super, options ){
    $super( options );

    if ( this.options.onfocus ) {
      this.addListener('focus', this.options.onfocus);
    }

    if ( this.options.onblur ) {
      this.addListener('blur', this.options.onblur);
    }
  },

  installUI: function( $super ) {
    $super();

    this.protocol = this.insert(new jui.FormSelect({
      label: this.options.label,
      attributes: {className: 'form-elm select', style: 'width: 125px;'},
      dropDownClassName : 'editor-info-panel-select jui-drop-down',
      id: 'jui-protocol-form-selector',
      width: '45px',
      selectOptions: this.options.selectOptions
    }));

    this.link = this.insert(new Element('input', {
      type:'text',
      className:'text',
      style: 'margin-top: 4px;'
    }));

    if (this.options.inputPosition) {
      this.input.addClassName(this.options.inputPosition);
    }

    // this.protocol.selectedIndex = 0;

    this.protocol.observe('change',this.onBlur.bind(this));
    this.link.observe('focus', this.onFocus.bind(this));
    this.link.observe('blur', this.onBlur.bind(this));
  },

  setSelectOptions: function(selectOptions) {
    this.select.update(this.createOptionsForSelect(selectOptions));
  },

  setSelectedValue: function(value) {
    var opts = $A(this.protocol.options);
    var match = opts.find(function(opt){
      return opt.value === value;
    });

    this.protocol.selectedIndex = opts.indexOf(match);
  },

  setValue: function(value) {
    var parts = value.startsWith('mailto') ? value.split(':') : value.split('://');
    this.setSelectedValue( parts.length > 1 ? parts[0] : 'http');
    this.link.value = parts.length > 1 ? parts[1] : '';
  },

  getValue: function() {
    var protocol = this.protocol.getValue();
    var link = this.link.getValue();

    return (link === '') ? '' : protocol+(protocol === 'mailto' ? ':' : '://')+link;
  },

  onFocus: function( e ) {
    if( this.isEnabled() ) {
      e.stop();
      this.fireEvent('focus', this);
    }
  },

  onkeydown: function(e) {
    switch (e.keyCode){
      case Event.KEY_RETURN:
        this.blur();
        e.stop();
        break;
    }
  },

  onBlur: function() {
    this.fireEvent('blur', this);
  },

  blur: function() {
    this.link.blur();
  }
});
