lp.module.button.ButtonElementPropertiesPanel = Class.create(
  lp.editor.panel.ElementPropertiesPanel,
  lp.editor.panel.ElementGeometryControls,
  lp.editor.panel.ElementBackgroundControls,
  lp.editor.panel.ElementBorderControls,
  lp.ModuleComponent,
  {
    type:'lp-pom-button',

    initialize: function($super) {
      this.states = ['up', 'hover', 'active'];
      $super();
      var self = this;

      this.modelChangeHandlers.push(function(e) {
        self.syncUIControls(e);
      });
    },

    activate: function($super) {
      $super();
      this.handleVisibilityToggleIcon();
    },

    handleVisibilityToggleIcon: function() {
      if(this.element.isFormButton()) {
        jQuery(this.e).addClass('lp-pom-button-form');
      } else {
        jQuery(this.e).removeClass('lp-pom-button-form');
      }
    },

    installUI: function($super){
      $super();

      var self                  = this;
      var geometryPanel         = this.insert(new jui.CollapsiblePanel({title:'Geometry'}));
      this.controls.actionPanel = this.insert(new jui.CollapsiblePanel({title:'Click Action'}));
      var labelPanel            = this.insert(new jui.CollapsiblePanel({title:'Label'}));
      var appearancePanel       = this.insert(new jui.CollapsiblePanel({title:'Appearance'}));

      this.controls.applyLabelDefaults = labelPanel.titleBar.insert(new jui.Button({
        label:'Use Defaults',
        id:'use-label-defaults-button',
        action:function(){
          self.element.model.applyLabelDefaults();
        }
      }));

      this.controls.applyAppearanceDefaults = appearancePanel.titleBar.insert(new jui.Button({
        label:'Use Defaults',
        id:'use-appearance-defaults-button',
        action:function(){
          self.element.model.applyAppearanceDefaults();
        }
      }));

      this.installGeometryControls({insert:geometryPanel.insertContent.bind(geometryPanel)});

      this.controls.buttonLabel = labelPanel.insertContent(new jui.FormDynamicContenteditable({
        label       : 'Label Text',
        inputStyle  : 'min-height: 48px; resize : vertical; overflow-x: hidden; overflow-y: auto',
        helpText    : 'You can pass in dynamic text values to your button, ' +
                     'through your page\'s URL. This is useful for increasing ' +
                     'message match and quality score of your PPC campaigns.',
        onkeyup     : function() { self.buttonLabelChanged(); },
        onblur      : function() {
          self.buttonLabelChanged();
          self.element.page.undoManager.endGroup();
        },
        onfocus     : function(e) {
          self.element.page.undoManager.startGroup();
          self.requestFocus(e);
        },
        afterInsert : function() { self.buttonLabelChanged(); }
      }));

      this.controls.font = labelPanel.insert(new jui.FormFontInput({
          label:'Label Font',
          onblur: function() {
            self.fontChanged();
          },
          onfocus: function(e) {
            self.requestFocus(e);
          }
        }));

      this.controls.action = this.controls.actionPanel.insertContent(new jui.FormSelect({
        label:'Action',
        id: 'jui-button-action-select',
        dropDownClassName : 'editor-info-panel-select jui-drop-down',
        width: '140px',
        selectOptions:[
          {name:'Go to URL', value:'url'},
          {name:'Download File', value:'dowmload'},
          {name:'Submit Form', value:'form'}
          ],
        action: this.actionChanged.bind(this)
      }));


      /* JS: TODO: the various actions available for a button should be modularized */


      this.activeAction = 'url';
      this.controls.actionModules = {};
      this.controls.actionModules.url = {
        urlField: this.controls.actionPanel.insertContent(function() {
          var cntrl = new jui.FormLinkInput2({
            passparams: true,
            onPassParams: function(e){
              self.element.model.set('action.passparams', e.data);
            },
            onblur: function(e) {
              var link = e.source.getValue();
              self.performModelUpdates( function() {
                self.element.model.set('action', {
                  type       : 'url',
                  url        : link.url,
                  target     : link.target,
                  passparams : link.passparams
                }, self.element.page.undoManager);
              });
            },
            onfocus: function(e) {
              self.requestFocus(e);
            }
          });
          cntrl.hide();
          return cntrl;
        }()),

        activate: function(action) {
          this.urlField.show();
          if (action === null) {
            self.performModelUpdates( function() {
              self.element.model.set('action', {
                type: 'url',
                url: ''
              }, self.element.page.undoManager);
            });
          } else {
            this.urlField.setValue({
              url        : action.url,
              target     : action.target || '',
              passparams : !!action.passparams
            });
          }
        },

        deactivate: function() {
          this.urlField.hide();
          this.urlField.setValue({url: '', target: '', passparams: false});
        }

      };

      this.controls.actionModules.download = {
        chooseDownloadAssetButton: this.controls.actionPanel.insertContent( function() {
          var cntrl = new jui.FormAssetChooser({
            label: 'File',
            hideRemove:true,
            changeAction: function() {
              window.editor.openAssetChooser({
                url: window.editor.assetsPath(),
                activeTab: 'documents',
                visibleTabs: ['images', 'documents', 'media', 'other'],
                onlyImages: false,
                title:'Choose a File',
                uploadButtonLabel:'Upload New File',
                onChoose: function(asset){ self.controls.actionModules.download.setAsset(asset);},
                selectedAsset: self.element.model.exists('action.asset') ?
                  self.element.model.get('action.asset') :
                  null
              });
            }
          });
          cntrl.hide();
          return cntrl;
        }()),

        setAsset: function(asset) {
          this.chooseDownloadAssetButton.setAsset(asset);
          self.performModelUpdates( function() {
            self.element.model.set('action', {
              type: 'download',
              asset: {
                id: asset.id,
                uuid: asset.uuid,
                content_url: asset.content_url,
                unique_url: asset.unique_url,
                content_content_type: asset.content_content_type,
                name: asset.name,
                protected_download: self.element.page.usedAs() === 'form_confirmation'
              }
            }, self.element.page.undoManager);
          });
        },

        activate: function(action, previous) {
          this.chooseDownloadAssetButton.show();
          var actionControl = this;
          if (action === null) {
            window.editor.openAssetChooser({
              url: window.editor.assetsPath(),
              title: 'Choose a File',
              activeTab: 'documents',
              visibleTabs: ['images', 'documents', 'media', 'other'],
              onlyImages: false,
              uploadButtonLabel: 'Upload New File',
              onChoose: actionControl.setAsset.bind(actionControl),
              onCancel: function() {
                self.element.model.set('action', previous);
                if (previous.type !== 'download') {
                  actionControl.chooseDownloadAssetButton.hide();
                  actionControl.chooseDownloadAssetButton.setAsset(null);
                }
              },
              selectedAsset: self.element.model.exists('action.asset') ?
                self.element.model.get('action.asset') :
                null
            });
          } else {
            this.chooseDownloadAssetButton.setAsset(action.asset);
          }
        },
        deactivate: function() {
          this.chooseDownloadAssetButton.hide();
          this.chooseDownloadAssetButton.setAsset(null);
        }
      };

      this.controls.actionModules.form = {
        activate:function(){},
        deactivate:function(){}
      };

      this.controls.fill = appearancePanel.insertContent( new jui.FormSelect ({
        label:'Style',
        id: 'jui-button-style-select',
        dropDownClassName : 'editor-info-panel-select jui-drop-down',
        width: '150px',
        selectOptions:[
          {name:'Solid Color', value:'solid'},
          {name:'Gradient', value:'gradient'},
          {name:'Image', value:'image'}
        ],
        action: self.fillTypeChanged.bind(self)
      }));

      this.controls.tabs = appearancePanel.insertContent(new jui.Component({attributes:{className:'tab-bar clearfix'}}));

      var rg = null;
      var firstState = this.states[0];
      // JS: For each button state add the following ways to change appearance
      this.states.each(function(s) {
        // JS: add the tab button corresponding to this state

        this.controls[s] = {};

        this.controls[s].tab = this.controls.tabs.insert(new jui.RadioButton({
          label:s.capitalize(),
          radioGroup:rg,
          action: this.stateTabClicked.bind(this, s),
          attributes:{className:'tab'}
        }));
        rg = rg || this.controls[s].tab.group;

        var panel = this.controls[s+'Panel'] = appearancePanel.insertContent(new jui.Component({attributes:{className:'special-panel clearfix'}}));


         // = button;

        if (s !== firstState) {
          this.controls[s].auto = panel.insert(new jui.FormCheckboxInput({
            label:'Create appearance based on "Up" state',
            onclick: self.autoGenerateStatesChanged.bind(self, s)
          }));
        }

        this.controls[s].bkgColor = panel.insert(new jui.FormColorInput({
          label   : 'Color',
          onblur  : this.backgroundColorChanged.bind(this, s),
          onfocus : this.requestFocus.bind(this)
        }));

        this.controls[s].opacity = panel.insert(new jui.FormSliderInput ({
          label:'Opacity',
          onfocus: function(e) {self.requestFocus(e);},
          onchange: function(e) {
            self.element.model.setOpacity(e.data, s);
          },
          startTracking: function() {
            var model = self.element.model;
            var undoManager = self.element.page.undoManager;
            var propertyName = 'style.' + s + '.opacity';
            undoManager.registerUndo({
              action: model.set,
              receiver: model,
              params: [
                propertyName,
                model.exists(propertyName) ? model.get(propertyName) : 100,
                undoManager
              ]
            });
          },
          slider: {
            min: 0,
            max: 100,
            increment: 1
          }
        }));

        this.controls[s].baseColor = panel.insert(new jui.FormColorInput({
          label   : 'Base Color',
          onblur  : this.baseColorChanged.bind(this, s),
          onfocus : this.requestFocus.bind(this)
        }));

        this.controls[s].fromColor = panel.insert(new jui.FormColorInput({
          label  : 'Top',
          onblur : function() {
            self.performModelUpdates(self.gradientColorChanged.bind(self, s));
          },
          onfocus    : this.requestFocus.bind(this),
          classNames : ['top-cap']
        }));

        this.controls[s].toColor = panel.insert(new jui.FormColorInput({
          label:'Bottom',
          onblur: function() {
            self.performModelUpdates(self.gradientColorChanged.bind(self, s));
          },
          onfocus: this.requestFocus.bind(this)
        }));

        this.controls[s].reverse = this.controls[s].fromColor.insert(new jui.ToggleButton({
          action:this.reverseGradient.bind(this, s),
          classNames: ['reverse-gradient'],
          toolTip: 'Swap gradient colors'
        }));

        // JS: change the image
        this.controls[s].changeImageButton = panel.insert(new jui.FormAssetChooser({
          label: s.capitalize() + ' Image',
          noFileText: 'Same as Up image',
          changeAction: window.editor.openAssetChooser.bind(window.editor, {
            onChoose:this.setImage.bind(this, s)
          }),
          hideRemove: s === 'up',
          chooseLabel: 'Change Image',
          changeLabel: 'Change Image',
          removeAction: this.setImage.bind(this, s, null)
        }));

        if (s === 'up') {
          this.controls.highlight = panel.insert(new jui.FormCheckboxInput({
            label:'Show inner highlight',
            onclick: this.highlightClicked.bind(this)
          }));

          this.controls.fitToImageCheckbox = panel.insert(new jui.FormCheckboxInput({
            label:'Fit button to image',
            onclick: this.fitToImageCheckboxClicked.bind(this)
          }));
        }

        // JS: change the text color
        this.controls[s].textColor = panel.insert(new jui.FormColorInput({
            label:'Text Color',
            onblur: this.textColorChanged.bind(this, s),
            onfocus: this.requestFocus.bind(this)
          }));

        // JS: change the border style
        this.controls[s].border = panel.insert( new jui.FormBorderInput ({
          onblur: function() {
            self.performModelUpdates(self.borderControlsChanged.bind(self, s));
          },
          onfocus: this.requestFocus.bind(this),
          disableWidth: s !== firstState,
          disableStyle: s !== firstState
        }));

        if (s === 'up'){
          this.controls.cornerRadius = panel.insert(new jui.FormSliderInput({
              label:'Corner Radius',
              onchange: function(e) {
                self.performModelUpdates( function(){
                  self.element.model.set('geometry.cornerRadius', e.data, e.source.slider.tracking ? null : self.element.page.undoManager);
                });
              },
              onfocus: function(e) {self.requestFocus(e);},
              startTracking: function() {
                var m = self.element.model;
                var um = self.element.page.undoManager;
                um.registerUndo({
                  action:m.set,
                  receiver:m,
                  params: ['geometry.cornerRadius', m.exists('geometry.cornerRadius') ? m.get('geometry.cornerRadius') : 0, um]
                });
              },
              slider:{
                min:0,
                max:100,
                increment:1
              }
          }));
        }

        panel.hide();
      }, this);

      // JS: for now the autoGradient property can only be set on the up state and is applied the same to remaining states

      this.controls.autoGradient = this.controls.up.fromColor.insert(new jui.ToggleButton({
        action:function(e) {
          self.element.model.setAutoGradient(!e.source.isSelected());
        },
        classNames : ['lock-gradient'],
        toolTip    : 'Customize gradient colors'
      }));

      this.controls.textShadow = this.controls.up.textColor.insert(new Element('input', {type:'checkbox'}));
      this.controls.textShadowLabel = this.controls.up.textColor.insert(new Element('label', {className:'separate'}).update('Text Shadow'));
      this.controls.textShadow.observe('click', this.textShadowClicked.bind(this));

      this.controls.commonAppearanceControls = appearancePanel.insertContent(new jui.Component());

      this.controls.up.tab.makeActive();
    },

    stateTabClicked: function(state) {
      if (this.controls.currentPanel){
        this.controls.currentPanel.hide();
      }

      this.controls.currentPanel = this.controls[state+'Panel'];
      this.controls.currentPanel.show();
      this.activeState = state;

      if (this.element) {
        this.element.updateCSSRules();
      }

      this.controls[state].fromColor.insert(this.controls.autoGradient);
      this.controls[state].opacity.updateKnob();

      this.controls[state].textColor.insert(this.controls.textShadow);
      this.controls[state].textColor.insert(this.controls.textShadowLabel);

      if (state !== 'up') {
        this.controls.autoGradient.disable();
        this.controls.textShadow.disabled = true;
        this.controls.textShadowLabel.addClassName('disabled');
      } else {
        this.controls.autoGradient.enable();
        this.controls.textShadow.disabled = false;
        this.controls.textShadowLabel.removeClassName('disabled');
      }
    },

    buttonLabelChanged: function(){
      this.updatingModel = true;
      var label = this.controls.buttonLabel.getValue();
      if (label === '') {
        this.element.model.deleteAttr('content.label', this.element.page.undoManager);
      } else {
        this.element.model.set('content.label', label, this.element.page.undoManager);
      }
      this.updatingModel = false;
    },

    fontChanged: function(){
      this.updatingModel = true;
      this.element.model.setFont(this.controls.font.getValue());
      this.updatingModel = false;
    },

    textShadowClicked: function(e) {
      this.element.model.set('style.textShadow', e.target.checked, this.element.page.undoManager);
    },

    highlightClicked: function(e) {
      this.element.model.set('style.highlight', e.source.getValue(), this.element.page.undoManager);
    },

    fitToImageCheckboxClicked: function() {
      this.element.model.fitToImage();
    },

    actionChanged: function() {
      var action = this.controls.action.getValue();
      var previousAction = this.element.model.get('action');

      this.controls.actionModules[this.activeAction].deactivate();
      this.controls.actionModules[action].activate(null, previousAction);
      this.activeAction = action;
    },

    downloadProtectedClicked: function() {
      this.element.model.set('content.downloadProtected', this.controls.downloadProtected.getValue());
    },

    setImage: function(state, imageData) {
      this.element.model.setImage(imageData, state);
    },

    backgroundColorChanged: function(state) {
      var bgCol = this.controls[state].bkgColor.getValue();
      bgCol = bgCol === '' ? 'transparent' : bgCol;
      this.element.model.setBackgroundColor(bgCol, state);
    },

    baseColorChanged: function(state) {
      var bgCol = this.controls[state].baseColor.getValue();
      bgCol = bgCol === '' ? 'transparent' : bgCol;
      this.element.model.setBackgroundColor(bgCol, state);
    },

    gradientColorChanged: function(state) {
      this.element.model.setGradientColor({
        from: this.controls[state].fromColor.getValue(),
        to: this.controls[state].toColor.getValue()
      }, state);
    },

    reverseGradient: function(state) {
      var m = this.element.model;
      var reversed = m.exists('style.'+state+'.reverseGradient') ?
        m.get('style.'+state+'.reverseGradient') : false;

      m.set('style.'+state+'.reverseGradient', !reversed);
    },

    autoGenerateStatesChanged: function(state, e) {
      this.element.model.setAutoGenerateState(e.source.getValue(), state);
    },

    fillTypeChanged: function() {
      var self = this;
      var fillType = this.controls.fill.getValue();

      if (fillType === 'image') {
        editor.openAssetChooser({
          onChoose: function(imageData) {
            self.element.model.setImage(imageData);
            self.controls.up.tab.makeActive();
          },
          onCancel: function() {
            self.controls.fill.setSelectedValue(self.element.getCurrentFillType());
          }
        });
      } else {
        this.element.model.setFillType(fillType);
      }
    },

    textColorChanged: function(state) {
      this.updatingModel = true;
      var col = this.controls[state].textColor.getValue();
      if (col !== '') {
        this.element.model.set('style.'+state+'.color', col, this.element.page.undoManager);
      } else {
        this.element.model.deleteAttr('style.'+state+'.color', this.element.page.undoManager);
      }
      this.updatingModel = false;
    },

    borderControlsChanged: function(state) {
      this.element.model.set('style.'+state+'.border', Object.clone(this.controls[state].border.getValue()), this.element.page.undoManager);
    },

    syncUIControls: function($super) {
      $super();
      var accessor = '';
      if(arguments[1] && arguments[1].data && arguments[1].data.accessor) {
        accessor = arguments[1].data.accessor;
      }
      this.syncAppearanceControls(accessor);
      this.syncActionControls(accessor);
      this.syncLabelControls(accessor);
      this.bindGeometryControlsToElement(this.element);

    },

    syncActionControls: function() {
      if(arguments[0] === 'geometry.offset') { return; }
      var m = this.element.model;
      var options = [];
      var hasForm = window.editor.page.hasForm();
      var action = m.exists('action') ? m.get('action') : {type:'url', url:''};

      if (m.exists('constraints.allowActions')) {
        var allow = m.get('constraints.allowActions');
        // this.controls.action.setOptionEnabled('form', allow.include('form'));
        // this.controls.action.setOptionEnabled('url', allow.include('url'));
        if (allow.include('url')){ options.push({name:'Go to URL', value:'url'});}
        if (hasForm && allow.include('form')){ options.push({name:'Submit Form', value:'form'}); }

        if (allow.length < 2) {
          this.controls.actionPanel.hide();
        } else {
          this.controls.actionPanel.show();
        }
      } else {
        this.controls.actionPanel.show();
        options.push({name:'Go to URL', value:'url'});

        if (this.element.page.usedAs() === 'form_confirmation') {
          options.push({name:'Download File', value:'download'});
        }
       if (hasForm && action === 'form'){ options.push({name:'Submit Form', value:'form'});}
      }

      this.controls.action.setSelectOptions(options);
      this.controls.action.setSelectedValue(action.type);
      if (this.activeAction !== action.type) {
        this.controls.actionModules[this.activeAction].deactivate();
        this.activeAction = action.type;
      }
      this.controls.actionModules[action.type].activate(action);
    },

    syncLabelControls: function() {
      if(arguments[0] === 'geometry.offset') { return; }

      var m = this.element.model;

      this.controls.buttonLabel.setValue(m.exists('content.label') ? m.get('content.label') : '');
      this.controls.font.setValue({
        family: m.exists('style.fontFamily') ? m.get('style.fontFamily') : 'arial',
        weight: m.exists('style.fontWeight') ? m.get('style.fontWeight') : 'normal',
        size: m.exists('style.fontSize') ? m.get('style.fontSize') : ''
      });

      this.controls.textShadow.setValue(m.exists('style.textShadow') && m.get('style.textShadow'));
    },

    setFillControlsVisibility: function(state, fillType, auto) {
      var isSolid = fillType === 'solid';
      var isGradient = fillType === 'gradient';
      var isImage = fillType === 'image';

      if (state !== 'up') {
        this.controls[state].auto.setVisible(true);
      }

      this.controls[state].bkgColor.setVisible((isSolid || isImage) && !auto);
      this.controls[state].opacity.setVisible((isSolid || isImage) && !auto);

      this.controls[state].baseColor.setVisible((isGradient) && !auto);

      this.controls[state].fromColor.setVisible(isGradient && !auto);
      this.controls[state].toColor.setVisible(isGradient && !auto);

      this.controls[state].changeImageButton.setVisible(isImage);
      this.controls[state].textColor.setVisible(!auto || isImage);
      this.controls[state].border.setVisible(!auto || isImage);
      this.controls.highlight.setVisible(!isImage);
      this.controls.textShadow[isImage ? 'hide' : 'show']();
      this.controls.textShadowLabel[isImage ? 'hide' : 'show']();
    },

    syncAppearanceControls: function() {
      if(arguments[0] === 'geometry.offset') { return; }
      var m = this.element.model;
      var fillType = this.element.getCurrentFillType();
      var upBorder = m.exists('style.up.border') ? m.get('style.up.border') : null;

      if (this.controls.fill.getValue() !== fillType) {
        this.controls.fill.setSelectedValue(fillType);
      }

      this.states.each(function(state) {
        var autoGenerate = m.exists('style.'+state+'.auto') && m.get('style.'+state+'.auto');

        if (state !== 'up') {
          this.controls[state].auto.setValue(autoGenerate);
        }

        if (!autoGenerate || fillType === 'image') {
          this['sync'+fillType.capitalize().camelize()+'FillControls'](state);
        }

        this.setFillControlsVisibility(state, fillType, autoGenerate, m);

        this.controls[state].textColor.setValue((m.exists('style.'+state+'.color') ? m.get('style.'+state+'.color') : m.get('style.up.color')));

        //JS: always use the border width from the up border
        if (upBorder === null) {
          this.controls[state].border.setValue(null);
        } else{
          var border = m.exists('style.'+state+'.border') ? m.get('style.'+state+'.border') : null;

          this.controls[state].border.setValue({
              style: upBorder.style,
              width: upBorder.width,
              color: border === null ? upBorder.color : border.color
            });
        }

      }, this);

      this.controls.autoGradient.setSelected(!(m.exists('style.autoGradient') ? m.get('style.autoGradient') : false));
      this.controls.highlight.setValue(m.exists('style.highlight') && m.get('style.highlight'));
      this.controls.cornerRadius.setValue(m.exists('geometry.cornerRadius') ? m.get('geometry.cornerRadius') : 0);

      var self = this;

      if (fillType === 'image') {
        jui.utils.loadImage(m.get('style.up.image.content_url'), function(img) {
          var size = m.get('geometry.size');
          var fitted = img.width === size.width && img.height === size.height;
          self.controls.fitToImageCheckbox.setValue(fitted);
          self.controls.fitToImageCheckbox.checkbox.disabled = fitted;
        });
      }
    },

    syncSolidFillControls: function(state) {
      var m = this.element.model;
      var bgColPath = 'style.'+state+'.backgroundColor';
      var opacityPath = 'style.'+state+'.opacity';
      var bgCol = m.exists(bgColPath) ? m.get(bgColPath) : 'transparent';
      var opacity = m.exists(opacityPath) ? parseInt(m.get(opacityPath), 10) : 100;

      this.controls[state].bkgColor.setValue(bgCol);

      if (bgCol === 'transparent') {
        this.controls[state].bkgColor.setValue(m.getSavedBackgroundColor(state));
        m.set(bgColPath, m.getSavedBackgroundColor(state));
        m.set(opacityPath, 0);
        opacity = 0;
      }
      this.controls[state].opacity.setValue(opacity);
    },

    gradientByStateAndBGColor: function(state, bgCol) {
      return state === 'active' && this.controls.active.auto.checked ?
        this.element.generateGradientByState('active').gradient :
          this.element.model.calculateGradient(bgCol);
    },

    syncGradientFillControls: function(state) {
      var m = this.element.model;
      var bgColPath = 'style.'+state+'.backgroundColor';
      var bgCol = m.exists(bgColPath) && m.get(bgColPath) !== 'transparent' ?
                  m.get(bgColPath) :
                  m.getSavedBackgroundColor(state);
      var autoGradient = m.exists('style.autoGradient') && m.get('style.autoGradient');

      var g = this.gradientByStateAndBGColor(state, bgCol);

      var gradient = autoGradient ? g : m.get('style.'+state+'.gradient');
      var reverse = m.exists('style.'+state+'.reverseGradient') && m.get('style.'+state+'.reverseGradient');

      var from = reverse ? gradient.to : gradient.from;
      var to = reverse ? gradient.from : gradient.to;

      this.controls[state].baseColor.setValue(bgCol);

      this.controls[state].baseColor.setEnabled(autoGradient);


      this.controls[state].fromColor.setValue(from);
      this.controls[state].toColor.setValue(to);

      this.controls[state].fromColor.setEnabled(!autoGradient);
      this.controls[state].toColor.setEnabled(!autoGradient);
      this.controls[state].reverse.checked = reverse;
    },

    syncAutoGradientFillControls: function(state) {
      var m = this.element.model;
      var reversePath = 'style.'+state+'.reverseGradient';
      this.syncSolidFillControls(state);
      this.controls[state].reverse.checked = m.exists(reversePath) && m.get(reversePath);
    },

    syncCustomGradientFillControls: function(state) {
      var m = this.element.model;
      var fill = m.get('style.'+state+'.gradient');
      this.controls[state].fromColor.setValue(fill.from);
      this.controls[state].toColor.setValue(fill.to);
    },

    syncImageFillControls: function(state) {
      var m = this.element.model;
      var imgPath = 'style.'+state+'.image';
      var bgColPath = 'style.'+state+'.backgroundColor';
      var opacityPath = 'style.'+state+'.opacity';

      var button = this.controls[state].changeImageButton;
      var bgCol = m.exists(bgColPath) ? m.get(bgColPath) : 'transparent';
      var opacity = m.exists(opacityPath) ? parseInt(m.get(opacityPath), 10) : 100;

      if (bgCol === 'transparent') {
        this.controls[state].bkgColor.setValue(m.getSavedBackgroundColor(state));
        m.set(bgColPath, m.getSavedBackgroundColor(state));
        m.set(opacityPath, 0);
        opacity = 0;
      }
      this.controls[state].opacity.setValue(opacity);

      if (m.exists(imgPath)) {
        var img =  m.get(imgPath);
        button.setAsset(img);
        this.controls[state].image = img.content_url;
        if (typeof button.removeButton !== 'undefined') {
          button.removeButton.enable();
        }
      } else {
        button.setAsset(null);
        this.controls[state].image = null;

        if (typeof button.removeButton !== 'undefined') {
          button.removeButton.disable();
        }
      }
      this.syncSolidFillControls(state);
    },

    deactivated: function() {
      this.controls.up.tab.makeActive();
    },

    setElement: function($super, elm) {
      if (this.element) {
        this.element.removeListener('deactivated', this);
      }
      $super(elm);
      elm.addListener('deactivated', this);
      this.controls.up.tab.makeActive();
    }
  }
);
