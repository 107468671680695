lp.editor.panel = lp.editor.panel || {};
lp.editor.panel.ElementGeometryControls = {
  createGeometryListeners: function() {
    var self = this;
    this.modelChangeHandlers.push(function(e) {
      var accessor = e.data.accessor;
      var value = e.data.value;

      if (accessor.startsWith('geometry')) {
        self.applyGeometry(accessor, value);
      }
    });
  },

  installGeometryControls: function(options) {
    this.createGeometryListeners();

    options.insert = options.insert || this.insert.bind(this);
    options.change = options.change || this.geometryControlsChanged.bind(this);
    options.maintainARChange = options.maintainARChange || this.maintainARChanged.bind(this);
    options.focus = options.focus || this.requestFocus.bind(this);
    options.sizeOptions = options.sizeOptions || {};

    var self = this;

    this.controls.size = options.insert( new jui.FormSizeInput(Object.extend({
      onblur: function() {
        options.change();
      },
      onfocus: options.focus,
      onmaintainARChange: options.maintainARChange
    }, options.sizeOptions)));

    this.controls.offset = options.insert( new jui.FormOffsetInput({
      onblur:function() {
        self.performModelUpdates(options.change);
      },
      onfocus: options.focus
    }));

    if (lp.isResponsiveEnabled()) {
      this.controls.scale = options.insert(new jui.FormSliderInput({
        label:'Scale',
        onchange: function(e) {
          self.performModelUpdates(function() {
            self.element.model.setScale(e.data / 100, e.source.slider.tracking ? null : self.element.page.undoManager);
          });
        },
        onfocus: function(e) { self.requestFocus(e); },
        startTracking: function() {
          var m = self.element.model;
          var um = self.element.page.undoManager;
          um.registerUndo({
            action:m.setScale,
            receiver:m,
            params: [m.getScale(), um]
          });
        },
        slider:{
          min:50,
          max:150,
          increment:1
        }
      }));

      // TODO: pk - remove if not required
      this.controls.visible = options.insert(new jui.FormCheckboxInput({
        label:'Visible',
        onclick: function(e) {
          self.element.model.setVisible(e.data, self.element.page.undoManager);
          self.element.page.updateAndRefresh();
        }
      }));

      var $visibilityToggle = jQuery(this.controls.visible.e)
        , $panel            = $visibilityToggle.parents('.element-properties-panel')
        , $visibleToggleIcon = jQuery(
          '<div class= "visibility-toggle"><span>Visibility</span> <span class="visibility-state"></span></div>');

      this.$msgPanel = jQuery(
        '<div class="collapsible-panel visibility-toggle-message">' +
          '<div class="panel-content clearfix">'+
          'Hint: Use the "Page Contents" button in the bottom left to find the invisible elements and make them visible again.'+
          '</div></div>');

      $visibilityToggle.addClass('visibility-toggle-hidden');

      $panel.append($visibleToggleIcon);
      $panel.find('.content > .collapsible-panel:first').before(this.$msgPanel);


      $visibleToggleIcon.click(function(){
        window.rx_switchboard.fireUIEvent({
         elementId: self.element.id,
         type: 'toggleVisibility',
         domTarget: 'element-panel'
        });
      });
    }
  },

  geometryControlsChanged: function(){
    try {
      var m = this.element.model;
      var um = this.element.page.undoManager;
      var position = m.exists('geometry.position') ? m.get('geometry.position') : 'absolute';

      var currentOffset = m.getOffset();
      var currentSize = m.getSize();
      var newOffset = this.controls.offset.getValue();
      var newSize = this.controls.size.getValue();

      if (currentOffset.left !== newOffset.left ||
          currentOffset.top !== newOffset.top ||
          currentSize.width !== newSize.width ||
          currentSize.height !== newSize.height) {

        um.startGroup();
        m.setOffset((position === 'absolute'? this.controls.offset.getValue() : {left:0,top:0}), um);
        m.setSize(this.controls.size.getValue(), um);
        um.endGroup();
      }
    } catch(error) {
      var message = error.message ? error.message + ': ' : '';
      message += error.stack ? error.stack.toString() : '';
      window.editor.reportError({
        error: error,
        id: window.editor.mainPage.id,
        message: message,
        details: "An error occured in geometryControlsChanged",
        userAgent: window.navigator.userAgent
      });

    }
  },

  maintainARChanged: function() {
    this.element.model.set('geometry.maintainAR', this.controls.size.isMaintainAspectRatio(), this.element.page.undoManager);
  },

  bindGeometryControlsToElement: function(elm) {
    var m = elm.model;
    // JS: TODO: settinf default values into the controls will cause these defaults to be saved with the element
    // even if they are not needed.. this should be changed somehow

    this.controls.offset.setValue(m.getOffset());
    this.controls.size.setValue(m.getSize());

    var maxSize = m.getMaxSize();
    var minSize = m.getMinSize();

    this.controls.size.options.maxWidth = maxSize.width;
    this.controls.size.options.maxHeight = maxSize.height;

    this.controls.size.options.minWidth = minSize.width;
    this.controls.size.options.minHeight = minSize.height;

    if (lp.isResponsiveEnabled()) {
      this.controls.scale.setValue(m.getScale() * 100);

      this.controls.scale.setVisible(elm.is('scalable') && !elm.page.isDefaultBreakpoint());

      // visibility control
      this.controls.visible.setValue(m.isVisible());
      jQuery('.element-properties-panel .visibility-toggle .visibility-state').
        toggleClass('lp-hidden', !m.isVisible());
      jQuery('.content .visibility-toggle-message').
        toggleClass('closed', m.isVisible());
    }
    if (elm.type === 'lp-multi-select') {
      jQuery('.element-properties-panel .visibility-toggle').hide();
    }

    this.controls.offset.setVisible(elm.is('offsetable'));
  },


  applyGeometry: function(accessor, value){
    var m = this.element.model;
    if (accessor.startsWith('geometry.offset')) {
      this.controls.offset.setValue(m.exists('geometry.offset') ? m.get('geometry.offset') : {left:0,top:0});
    } else if (accessor.startsWith('geometry.size')) {
      this.controls.size.setValue(m.exists('geometry.size') ? m.get('geometry.size') : {width:'',height:''});
    } else if (accessor.startsWith('geometry.maxSize')) {
      this.controls.size.options.maxWidth = value.width;
      this.controls.size.options.maxHeight = value.height;
    } else if (accessor.startsWith('geometry.minSize')) {
      this.controls.size.options.minWidth = value.width;
      this.controls.size.options.minHeight = value.height;
    }
  }
};
