(function(){

  jui.FormContenteditable = Class.create(jui.Component, jui.ControlModel, {

    KEYCODE: {
      undo: 90,
      redo: 89
    },

    options: function($super, options) {
      return $super($H({
        attributes  : {
          className : 'form-elm content-editable'
        },
        label        : '',
        inputStyle   : '',
        placeholder  : '',
        inputFilters : []
      }).merge(options));
    },

    initialize: function($super, options) {
      $super(options);
      this._setupEventListeners();
    },

    installUI: function($super) {
      $super();
      this.label    = this._label();
      this.editable = this._contenteditable();
      this.input    = this._hidden();
    },

    blur: function() {
      this.editable.blur();
    },

    focus: function() {
      this.editable.focus();
      window.editor.keyController.requestFocus(this);
    },

    undo: function(e) {
      e.stop(e);
      document.execCommand('undo', false, null);
    },

    redo: function(e) {
      e.stop(e);
      document.execCommand('redo', false, null);
    },

    setValue: function(value) {
      this._updateHiddenInput(value);
      this.editable.update(value);
    },

    getValue: function() {
      return this.input.getValue();
    },

    focusAndSelect: function() {
      this.editable.focus();
      this.editable.select();
    },

    /**
     * Private
     **/

    onFocus: function(e) {
      if (this.isEnabled()) {
        e.stop();
        this.fireEvent('focus', this);
        this.focus();
      }
    },

    onkeydown: function(e) {
      this.options.inputFilters.invoke('filter', e);
      this.fireEvent('keydown', e.keyCode);
      if ((e.keyCode === Event.KEY_RETURN || e.keyCode === Event.KEY_TAB)) {
        e.stop(e);
        this.blur();
      } else if(this._isRedo(e)) {
        this.redo(e);
      } else if(this._isUndo(e)) {
        this.undo(e);
      }
    },

    _isUndo: function(e) {
      return (e.ctrlKey || e.metaKey) && e.keyCode === this.KEYCODE.undo;
    },

    _isRedo: function(e) {
      return ((e.ctrlKey || e.metaKey) && e.keyCode === this.KEYCODE.redo) ||
        (e.metaKey && e.shiftKey) && e.keyCode === this.KEYCODE.undo;
    },

    onkeyup: function(e) {
      this._updateHiddenInput(this.editable.innerHTML);

      if (!(e.keyCode === Event.KEY_RETURN || e.keyCode === Event.KEY_TAB)) {
        this.fireEvent('keyup', e.keyCode);
      }
    },

    onBlur: function(e) {
      this._updateHiddenInput(this.editable.innerHTML);

      if (this.isEnabled()) {
        e.stop();
        this.fireEvent('blur', this);
      }
    },

    onmouseup: function(e) {
      if (this.isEnabled()) {
        e.stop();
        this.fireEvent('mouseup', this);
      }
    },

    _updateHiddenInput: function(value) {
      var text = value
       .replace(/(\r\n|\n|\r)/gm, ' ')
       .replace(/&nbsp;/gi, ' ')
       .stripScripts()
       .stripTags()
       .trim();

      this.input.setValue(text);
    },

    _setupEventListeners: function() {
      var self = this,
          eventOption;
      ['keydown', 'keyup', 'focus', 'blur', 'mouseup'].each(function(item) {
        eventOption = self.options['on' + item] || false;
        if(eventOption) { self.addListener(item, eventOption); }
      });
    },

    _label: function() {
      var labelEl = new Element('label', {className: 'contenteditable-label'}),
          label   = this.insert(labelEl).update(this.options.label);
      if (this._isOptionSet('label')) {
        label.hide();
      }
      return label;
    },

    _isOptionSet: function(optionName) {
      return (this.options[optionName] === '' ||
              this.options[optionName] === null);
    },

    _contenteditable: function() {
      var el = new Element('div', {
        className       : 'contenteditable',
        style           : this.options.inputStyle || '',
        contenteditable : 'true',
      });

      el.update(this.options.placeholder);

      this.insert(el);

      if (this.options.inputPosition) {
        el.addClassName(this.options.inputPosition);
      }

      el.observe('focus', this.onFocus.bind(this));
      el.observe('blur', this.onBlur.bind(this));
      if(this.options.onkeyup) { el.observe('keyup', this.onkeyup.bind(this)); }
      if(this.options.onkeydown) { el.observe('keydown', this.onkeydown.bind(this)); }
      if(this.options.onmouseup) { el.observe('mouseup', this.onmouseup.bind(this)); }

      return el;
    },

    _hidden: function() {
      var el = new Element('input', {
        type      : 'hidden',
        className : 'hidden',
        value     : this.options.placeholder
      });

      this.insert(el);

      return el;
    }

  });

})();

