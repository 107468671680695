lp.editor.panel = lp.editor.panel || {};
lp.editor.panel.ElementBackgroundControls = {
  installBackgroundControls: function(options) {
    options.insert = options.insert || this.insert.bind(this);
    options.focus  = options.focus || this.requestFocus.bind(this);

    var self = this;

    this.controls.fill = options.insert( new jui.FormSelect ({
      label             : 'Fill',
      width             : '100px',
      id                : 'jui-fill-type-select',
      dropDownClassName : 'editor-info-panel-select jui-drop-down',
      autoWidth         : true,
      selectOptions     : [
        {name           : 'Solid Color',
          value         : 'solid'},
        {name           : 'Gradient',
          value         : 'gradient'}
      ],
      action: this.fillTypeChanged.bind(this)
    }));

    this.controls.bkgColor = options.insert(new jui.FormColorInput({
      label   : 'Color',
      onblur  : this.backgroundColorChanged.bind(this),
      onfocus : options.focus
    }));

    this.controls.opacity = options.insert( new jui.FormSliderInput ({
      label    : 'Opacity',
      onchange : function(e) {
        self.performModelUpdates(function(){
          self.element.model.setOpacity(e.data);
          self.opacityChanged();
        });
      },
      onfocus     : function(e) {self.requestFocus(e);},
      startTracking: function() {
        self.element.page.undoManager.startGroup();
      },
      stopTracking: function() {
        self.element.page.undoManager.endGroup();
      },
      slider      : {
        min       : 0,
        max       : 100,
        increment : 1
      }
    }));

    this.controls.baseColor = options.insert(new jui.FormColorInput({
      label   : 'Base Color',
      onblur  : this.baseColorChanged.bind(this),
      onfocus : options.focus
    }));

    this.controls.fromColor = options.insert(new jui.FormColorInput({
      label:'Top',
      onblur: this.gradientColorChanged.bind(this),
      onfocus: options.focus
    }));

    this.controls.toColor = options.insert(new jui.FormColorInput({
      label:'Bottom',
      onblur: this.gradientColorChanged.bind(this),
      onfocus: options.focus
    }));

    this.controls.reverse = this.controls.fromColor.insert(new jui.ToggleButton({
      action:this.reverseGradient.bind(this),
      classNames: ['reverse-gradient'],
      toolTip: 'Swap gradient colors'
    }));

    this.controls.autoGradient = this.controls.fromColor.insert(new jui.ToggleButton({
      action:function(e) {
        self.element.model.setAutoGradient(!e.source.isSelected());
      },
      classNames: ['lock-gradient'],
      toolTip: 'Customize gradient colors'
    }));

    this.controls.chooseDownloadAssetButton = options.insert(new jui.FormAssetChooser({
      label: 'Bkg. Image',
      noFileText: 'No image specified',
      changeAction: window.editor.openAssetChooser.bind(window.editor, {
        onChoose:this.setBackgroundImage.bind(this)
      }),
      removeAction: function() {
        self.performModelUpdates(self.removeBackgroundImage.bind(self));
      }
    }));

    this.controls.bkgImage = null;

    this.controls.bkgPos = options.insert( new jui.FormAlignSelect ({
      label:'Position',
      action: this.imageChanged.bind(this)
    }));

    this.controls.bkgRepeat = options.insert( new jui.FormSelect ({
      label:'Tiling',
      width: '75px',
      id: 'jui-background-repeat-select',
      dropDownClassName : 'editor-info-panel-select jui-drop-down',
      selectOptions:[
        {name:'none',value:'no-repeat'},
        {name:'horizontal',value:'repeat-x'},
        {name:'vertical',value:'repeat-y'},
        {name:'both',value:'repeat'}
      ],
      action: this.imageChanged.bind(this)
    }));

    this.modelChangeHandlers.push(function(e) {
      var accessor = e.data.accessor;
      var value = e.data.value;
      switch(accessor) {
      case 'style.background.backgroundColor':
        self.controls.bkgColor.setValue(value ? value : 'transparent');
        break;
      case 'style.background.opacity':
        self.controls.opacity.setValue(value ? value : '0');
        break;
      case 'style.background.image':
        if (value) {
          var image = value;
          self.controls.chooseDownloadAssetButton.setAsset(image);
          self.controls.bkgImage = image;
        } else {
          self.controls.chooseDownloadAssetButton.setAsset(null);
          self.controls.bkgImage = null;
        }
        break;
      case 'style.background.backgroundPosition':
        self.controls.bkgPos.setValue(value ? value : 'left top');
        break;
      case 'style.background.backgroundRepeat':
        self.controls.bkgRepeat.setSelectedValue(value ? value : 'no-repeat');
        break;
      }

      if (accessor.startsWith('style.background')) {
        self.syncControls();
      }

    });
  },

  updateBackgroundImage: function(elm, imageAsset) {
    var m = elm.model;
    var um = elm.page.undoManager;

    var newUuid = imageAsset ? imageAsset.uuid : null;
    var oldUuid = m.exists('style.background.image') ?
      (m.get('style.background.image') === null ?
        null : m.get('style.background.image').uuid) : null;

    if (newUuid === oldUuid){
      return;
    }

    um.registerUndo({
      action: this.updateBackgroundImage,
      receiver: this,
      params: [
        elm,
        m.exists('style.background.image') ? m.get('style.background.image') : null
      ]
    });

    if (newUuid === null) {
      m.set('geometry.backgroundImageApply', false);
      m.deleteAttr('style.background.image');
      return;
    }

    window.editor.wait();
    var image = new Image();
    image.onload = function() {
      window.editor.stopWaiting();
    };
    image.src = imageAsset.content_url;

    m.set('geometry.backgroundImageApply', true);
    m.set('style.background.image', imageAsset);
  },

  updateBackgroundImagePositioning: function(m, um) {
    if (m.exists('style.background.image')) {
      m.set('style.background.backgroundPosition', this.controls.bkgPos.getValue(), um);
      m.set('style.background.backgroundRepeat', this.controls.bkgRepeat.getValue(), um);
    } else {
      m.deleteAttr('style.background.backgroundPosition', um);
      m.deleteAttr('style.background.backgroundRepeat', um);
    }
  },

  fillTypeChanged: function() {
    var fillType = this.controls.fill.getValue();
    this.element.model.setFillType(fillType);
  },

  getBackgroundColor: function(m) {
    var savedBGColorPath = 'style.background.savedBackgroundColor';
    if(m.exists(savedBGColorPath)) {
      this.controls.bkgColor.setSavedColor(m.get(savedBGColorPath));
    }
    return this.controls.bkgColor.getValue();
  },

  backgroundColorChanged: function() {
    var bgCol = this.controls.bkgColor.getValue();
    bgCol = bgCol === '' ? 'transparent' : bgCol;
    if (bgCol !== 'transparent') {
      this.controls.bkgColor.setSavedColor(bgCol);
    }
    this.element.model.setBackgroundColor(bgCol);
  },

  opacityChanged: function() {
    var bgCol = this.controls.bkgColor.getValue();
    if(bgCol === '') {
      this.element.model.setBackgroundColor(this.element.model.getSavedBackgroundColor());
    }
  },

  baseColorChanged: function() {
    var bgCol = this.controls.baseColor.getValue();
    bgCol = bgCol === '' ? 'transparent' : bgCol;
    this.element.model.setBackgroundColor(bgCol);
  },

  gradientColorChanged: function() {
    var g = {
      from: this.controls.fromColor.getValue(),
      to: this.controls.toColor.getValue()
    };

    if (this.element.model.isReverseGradient()) {
      var to = g.to;
      g.to = g.from;
      g.from = to;
    }

    this.element.model.setGradientColor(g);
  },

  reverseGradient: function() {
    var m = this.element.model;
    var reversed = m.exists('style.background.reverseGradient') ? m.get('style.background.reverseGradient') : false;
    m.set('style.background.reverseGradient', !reversed);
  },

  imageChanged: function() {
    var um = this.element.page.undoManager;

    um.startGroup();
    this.updateBackgroundImage(this.element, this.controls.bkgImage);
    this.updateBackgroundImagePositioning(this.element.model, um);
    um.endGroup();
  },

  setFillControlsVisibility: function(fill) {
    this.controls.fromColor.setVisible(fill === 'gradient');
    this.controls.toColor.setVisible(fill === 'gradient');
    this.controls.baseColor.setVisible(fill === 'gradient');
    this.controls.bkgColor.setVisible(fill === 'solid');
    var showOpacity = this.element.defaultConstraints.opacityable;
    this.controls.opacity.setVisible(fill === 'solid' && showOpacity);
    this.setFillBkgImageControlsVisibility(fill);
  },

  setFillBkgImageControlsVisibility: function(fill) {
    this.controls.chooseDownloadAssetButton.setVisible(fill === 'solid');
    this.controls.bkgPos.setVisible(fill === 'solid' && this.controls.bkgImage !== null);
    this.controls.bkgRepeat.setVisible(fill === 'solid' &&
      this.controls.bkgImage !== null);
  },

  syncFillControls: function() {
    var m        = this.element.model
      , fillType = m.getCurrentFillType()
      , bkgCol   = m.getBackgroundColor()
      , opacity  = m.getOpacity();


    if(bkgCol === 'transparent') {
      bkgCol = m.getSavedBackgroundColor();
    }

    this.controls.fill.setSelectedValue(fillType);
    this.controls.bkgColor.setValue(bkgCol);
    this.controls.opacity.setValue(opacity);

    if (fillType === 'gradient') {
      var baseColor    = bkgCol
        , gradient     = m.getGradient()
        , autoGradient = m.isAutoGradient();

      this.controls.baseColor.setValue(baseColor);

      this.controls.baseColor.setEnabled(autoGradient);

      this.controls.fromColor.setValue(gradient.from);
      this.controls.toColor.setValue(gradient.to);

      this.controls.fromColor.setEnabled(!autoGradient);
      this.controls.toColor.setEnabled(!autoGradient);
      this.controls.autoGradient.setSelected(!autoGradient);

      this.hideImagePositionControls();
    } else if (m.exists('style.background.image')) {
      this.showImagePositionControls();
    }

    this.setFillControlsVisibility(fillType);
  },

  syncControls: function() {
    this.syncFillControls();
  },

  bindBackgroundControlsToElement: function(elm) {
    var m = elm.model;
    // JS: TODO: setting default values into the controls will cause these defaults to be
    // saved with the element even if they are not needed.. this should be changed somehow
    this.syncFillControls();

    //If a saved background color exists than tell the form color input about it.
    this.controls.bkgColor.setSavedColor(m.getSavedBackgroundColor());

    if (m.exists('style.background.image') && m.shouldApplyBackgroundImage()) {
      var image = m.get('style.background.image');
      this.controls.chooseDownloadAssetButton.setAsset(image);
      this.controls.bkgImage = image;
      this.showImagePositionControls();
    } else {
      this.controls.chooseDownloadAssetButton.setAsset(null);
      this.controls.bkgImage = null;
      this.hideImagePositionControls();
    }

    this.controls.bkgPos.setValue(m.exists('style.background.backgroundPosition') ?
      m.get('style.background.backgroundPosition') : 'left top');

    this.controls.bkgRepeat.
      setSelectedValue(m.exists('style.background.backgroundRepeat') ?
        m.get('style.background.backgroundRepeat') : 'no-repeat');
  },

  showImagePositionControls: function() {
    this.controls.bkgPos.show();
    this.controls.bkgRepeat.show();
  },

  hideImagePositionControls: function() {
    this.controls.bkgPos.hide();
    this.controls.bkgRepeat.hide();
  },

  setBackgroundImage: function(image) {
    this.controls.chooseDownloadAssetButton.setAsset(image);
    this.controls.bkgImage = image ?
      {
        uuid: image.uuid,
        unique_url: image.unique_url,
        content_url:image.content_url,
        name: image.name
      } : null;
    this.imageChanged();
  },

  removeBackgroundImage: function(change) {
    /* jshint unused:vars */
    this.controls.chooseDownloadAssetButton.setAsset(null);
    this.controls.bkgImage = null;
    this.imageChanged();
  }
};
