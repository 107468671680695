//  require <jui>
//  require <jui/color_picker_button>

jui.FormAssetChooser = Class.create( jui.Component, jui.ControlModel, {
  options: function($super,options){
    return $super($H({
      attributes:{className:'form-elm asset-chooser'},
      hideRemove:false
    }).merge(options));

  },

  initialize:function($super, options){
    $super(options);
  },

  installUI: function($super) {
    $super();

    this.label = this.insert(new Element('label').update(this.options.label));
    this.assetName = this.insert(new Element('div', {className:'filename'}).update(this.options.noFileText || 'No file specified'));
    this.chooseButton = this.insert(new jui.Button({
      label:this.options.chooseLabel || 'Choose an Image',
      action:this.options.changeAction
    }));

    if (!this.options.hideRemove) {
      this.removeButton = this.insert(new jui.Button({
        label:this.options.removeLabel || 'Remove',
        action:this.options.removeAction
      }));
    }
  },

  updateAssetName: function(assetName) {
    this.assetName.update(assetName);
  },

  setAsset: function(asset) {
    this.assetName.update(asset === null ? this.options.noFileText || 'No file specified' : asset.name);

    this.chooseButton.setLabel(asset === null ? (this.options.chooseLabel || 'Choose an Image') : (this.options.changeLabel || 'Change'));
    if (this.removeButton) {
      if (asset === null) {
        this.removeButton.hide();
      } else {
        this.removeButton.show();
      }
    }
  }
});
