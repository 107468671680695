lp.module.multiSelect = {};

lp.module.multiSelect.MultiSelectModule = Class.create(
  lp.ModuleBase,
  lp.ModuleElementMethods,
  lp.ModuleElementPropertiesPanelMethods,
  {
    createElement: function(page, options) {
      options = options || {};
      options.offset = options.offset || {left:0, top:0};
      if (options.center) {
        options.offset.left = options.offset.left - 50;
        options.offset.top = options.offset.top - 50;
      }
      
      var elm =  new (this.getElementClass())(page, {
        name: this.defaultName,
        geometry:{
          position:"absolute",
          offset:{top:options.offset.top,left:options.offset.left},
          size:{width:options.size.width,height:options.size.height}
        }
      });

      elm.parentElement = options.parentElement;
      this.addElementListeners(elm);
      return elm;
    }
  }
);

lp.addModule(new lp.module.multiSelect.MultiSelectModule(
  {
    type:'lp-multi-select',
    version:'1.0',
    name: 'Multi Select',
    defaultName: 'Multiple Selection',
    usageType:'editor-extension',
    namespace: lp.module.multiSelect
  }
));