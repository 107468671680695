/* globals exports, module, define */
(function(root, fn) {
  if (typeof exports === 'object') {
    var NewFeatureTourValidationError = fn(require('jQuery'), require('lodash'));
    exports.NewFeatureTourValidationError = exports.NewFeatureTourValidationError || {};
    exports.NewFeatureTourValidationError = NewFeatureTourValidationError;
    module.exports = exports;
  } else if (typeof 'define' === 'function' && define.amd) {
    define(['jQuery', 'lodash'], fn);
  } else {
    root.lp = root.lp || {};
    root.lp.NewFeatureTourValidationError = fn(jQuery, _);
  }
})(this, function(jQuery, _) {
  function NewFeatureTourValidationError(obj) {
    this.name = 'NewFeatureTourValidationError';
    this.message = _.reduce(obj, function(res, val, key) {
      res.push(key + ' ' + val);
      return res;
    }, []).toString();
  }
  NewFeatureTourValidationError.prototype = new Error();
  NewFeatureTourValidationError.prototype.constructor = NewFeatureTourValidationError;

  return NewFeatureTourValidationError;
});
