lp.module.video = {};

lp.module.video.VideoModule = Class.create(
  lp.ModuleBase,
  lp.ModuleElementMethods,
  lp.ModuleElementBuilderMethods,
  lp.ModuleElementPropertiesPanelMethods,
  {
    javascripts: [
      '//d2xxq4ijfwetlm.cloudfront.net/m/lp-webapp/matchmedia/matchMedia.js',
      '//d2xxq4ijfwetlm.cloudfront.net/m/lp-webapp/0.0.9/lp-video/0.1/main.js'
    ],
    NotValidCodeException: function (){},

    beforeCreate: function (page, defaults, options, data) {
      defaults.content.html = data.html;
    },

    afterInsert: function (element, options) {
      /* jshint unused:vars */
      element.resizeBox();
    },

    getTransformBoxUIExtensions: function () {
      var self = this;
      return [
        {
          placement: 'outside blh',
          component: new jui.ToggleButton({
            label: 'View&nbsp;Video',
            classNames: ['dark'],
            action: function (e){
              var button = e.source;
              var state = e.data;
              button.setLabel( state ? 'Move&nbsp;Video' : 'View&nbsp;Video' );
              self.activeElement.overlay.style.display = state ? 'none' : 'block';
              if( !state ) {
                self.activeElement.resetVideo();
              }
            },
            toolTip:'Toggle between view video mode and move video mode'
          })
        }
      ];
    }
  }
);

lp.addModule(new lp.module.video.VideoModule(
  {
    type:'lp-pom-video',
    version:'1.0',
    name: 'Video',
    usageType:'page-widget',
    namespace: lp.module.video,
    overlay: null,
  }
));
