lp.module.text = {};

lp.module.text.TextModule = Class.create(
  lp.ModuleBase,
  lp.ModuleElementMethods,
  lp.ModuleElementPropertiesPanelMethods,
  {
    javascripts: [
      '//d2xxq4ijfwetlm.cloudfront.net/m/lp-webapp/matchmedia/matchMedia.js',
      '//d2xxq4ijfwetlm.cloudfront.net/m/lp-webapp/0.0.9/lp-text/3.0/main.js'
    ]
  }
);

lp.addModule(new lp.module.text.TextModule(
  {
    type:'lp-pom-text',
    version:'1.0',
    name: 'Text',
    usageType:'page-widget',
    namespace: lp.module.text
  }
));
