lp.editor.BuilderModal = Class.create(jui.ModalDialog, { initialize: function($super, options) {
    $super(options);
    this.maximized = false;
    this.initialLayoutRules = this.layoutRules;
  },

  installUI: function($super) {
    $super();
    var self = this;
    if (this.options.showMaximize) {
        this.titleBar.insert(new jui.ToggleButton({classNames: ['modal-min-max'], action: function() {
        if (self.maximized) {
          self.layoutRules = self.initialLayoutRules;
          self.updateLayout();
          jui.ModalPanel.fitShadow(self);
          self.maximized = false;
        } else {
          self.layoutRules = {
            method: jui.ModalDialog.fitToViewPort,
            options: {
              padding: 8
            }
          };
          self.updateLayout();
          jui.ModalPanel.fitShadow(self);
          self.maximized = true;
        }
      }}));
    }
  }
});

lp.editor.BuilderModal.fitToCanvas = function(options) {
  var padding = options.padding || 2;
  var canvasOffset = editor.canvas.cumulativeOffset();
  this.setOffset(jui.Point.add(canvasOffset, {left:padding, top:padding}));
  this.setDimensions(jui.Dimensions.subtract(editor.canvas.getDimensions(), {width:padding*2, height:padding*2}));
};

lp.editor.BuilderModal.fitToEditor = function(options) {
  var headerHeight = $('header').getHeight();
  var padding = options.padding || 2;
  this.setOffset({left:padding, top:padding + headerHeight});
  this.setDimensions({width:document.viewport.getWidth() - padding*2, height:document.viewport.getHeight() - padding*2 - headerHeight});
};
