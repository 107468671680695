lp.editor = lp.editor || {};
lp.editor.EditorElementListeners = {
  addElementListeners: function(elm) {
    elm.addListener('mousedown', this.elementMousedownListener);
    elm.addListener('mouseover', this.elementMouseoverListener);
    elm.addListener('mouseout', this.elementMouseoutListener);
    elm.addListener('dblclick', this.elementDblClickListener);
    elm.addListener('shown', this.elementShownListener);
    elm.addListener('hidden', this.elementHiddenListener);
    elm.addListener('moved', this.elementMoveListener);
    elm.addListener('zIndexChanged', this.elementZIndexChangeListener);
    elm.addListener('parentChanged', this.elementContainerChangeListener);
    elm.addListener('orderChanged', this.elementOrderChangeListener);
  },

  removeElementListeners: function(elm) {
    elm.removeListener('mousedown', this.elementMousedownListener);
    elm.removeListener('mouseover', this.elementMouseoverListener);
    elm.removeListener('mouseout', this.elementMouseoutListener);
    elm.removeListener('dblclick', this.elementDblClickListener);
    elm.removeListener('shown', this.elementShownListener);
    elm.removeListener('hidden', this.elementHiddenListener);
    elm.removeListener('moved', this.elementMoveListener);
    elm.removeListener('zIndexChanged', this.elementZIndexChangeListener);
    elm.removeListener('parentChanged', this.elementContainerChangeListener);
    elm.removeListener('orderChanged', this.elementOrderChangeListener);
  },

  createElementListeners: function() {
    var self  = this;
    Object.extend(this, {
      elementMousedownListener: function(e){
        var elm = e.source;
        var evt = e.data;
        var ae = self.activeElement;

        if (evt.shiftKey && ae.is('offsetable') && ae !== elm) {
          if (ae.type === 'lp-multi-select' && ae.selection.first().parentElement === elm.parentElement) {
            if (ae.isInSelection(elm)) {
              elm = ae.removeFromSelection(elm);
            } else {
              elm = ae.addToSelection(elm);
            }
          } else if (ae.parentElement === elm.parentElement){
            var o1 = ae.model.getOffset();
            var o2 = elm.model.getOffset();

            var offset = {
              left:Math.min(o1.left, o2.left),
              top:Math.min(o1.top, o2.top)};

            var multiSelect = lp.getModule('lp-multi-select').createElement(self.page,
              {
                parentElement:ae.getParentElement(),
                offset:offset,
                size:{width:0, height:0}
              }
            );

            multiSelect.addToSelection(ae);
            multiSelect.addToSelection(elm);

            elm = multiSelect;
          } else {
            return;
          }
        }

        if (self.activeElement === null || !(self.activeElement.type === 'lp-multi-select' && self.activeElement.isInSelection(elm))) {
          self.setActiveElement(elm);
        }

        self.fireEvent('elementMousedown', e);
      },

      elementDblClickListener: function(e){
        var elm = e.source;
        if (elm.type === 'lp-pom-text') {
          self.textEditor.open(elm);
        }
      },

      elementMouseoverListener: function(e){
        var elm = e.source;
        var evt = e.data;
        var ae = self.activeElement;

         if (evt.shiftKey &&
              (!ae.is('offsetable') ||
                ae === elm ||
                (ae.type === 'lp-multi-select' &&
                  (ae.selection.first().parentElement !== elm.parentElement ||
                  ae.isInSelection(elm))) ||
                ae.parentElement !== elm.parentElement)) {
            return;
          }

        self.fireEvent('elementMouseover', e);
      },

      elementMouseoutListener: function(e){
        self.fireEvent('elementMouseout', e);
      },

      elementShownListener: function(e){
        self.fireEvent('elementShown', e);
      },

      elementHiddenListener: function(e){
        self.fireEvent('elementHidden', e);
      },

      elementMoveListener: function(){
        self.updateControlStates();
      },

      elementZIndexChangeListener: function(e){
        self.updateControlStates();
        self.fireEvent('elementZIndexChanged', e);
      },

      elementContainerChangeListener: function(e){
        self.fireEvent('elementContainerChanged', e);
      },

      elementOrderChangeListener: function(e){
        self.page.updateAndRefresh();
        self.fireEvent('elementOrderChanged', e);
      }
    });
  }
};
