// require <prototype>
// require <jui>
// require "editor"
// require "page_element_tree"
lp.editor.NonVisibleElementsShelf = Class.create(jui.Component, {
  options: function($super,options){
    return $super($H({attributes:{id:'non-visible-elements-shelf'}}).merge(options));
  },

  initialize: function($super, editor, options) {
    $super(options);
    this.editor = editor;

    editor.addListener('activeRootChanged', this);
    editor.addListener('elementInserted', this);
    editor.addListener('elementRemoved', this);
    editor.addListener('updateWarning', this);
    this.invisibles = {};
  },

  installUI: function($super) {
    $super();
    this.shelf = this.insert(new jui.Component({attributes: {className: 'shelf'}}));
  },

  activeRootChanged: function() {
    this.updateShelf();
  },

  elementInserted: function() {
    this.updateShelf();
  },

  elementRemoved: function() {
    this.updateShelf();
  },

  makeZeroElementButton: function(type) {
    var module = lp.getModule(type);
    var button = new jui.Button({
      label: module.name + (module.limit === 1 ? '' : 's'),
      elementType:'span',
      attributes: {className: 'shelf-button '+type},
      action: function() {
        module.openBuilder(null);
      }
    });

    button.insert(new Element('div', {className: 'add'}));
    this.shelf.insert(button);
  },

  makeSingleElementButton: function(elm, type) {
    var module = lp.getModule(type);
    var button = new jui.Button({
      label: elm.name,
      attributes: {className: 'shelf-button '+type},
      action: function() {
        module.openBuilder(elm);
      }
    });

    this.shelf.insert(button);
  },

  makeMultiElementButton: function(elms, type) {
    var module = lp.getModule(type);

    var menuItems = elms.collect( function(elm) {
      var model = elm.model;
      var hasError = model.exists('content.valid') && !model.get('content.valid');
      var errorClass = hasError  ? 'error' : '';
      var el = "<span class='menu-label " + errorClass + "'>" + elm.name + '</span>';
      if(hasError) {el += ' <span class="invalid">(Invalid)</span>';}
      return {type:'item', label:el, tag:elm, data:elm, warning: hasError};
    });

    menuItems.push({type:'divider'});

    menuItems.push({type:'item', label:'Add New '+module.name, data:null });
    var button = new jui.PopUpButton(
      new jui.Menu({
        items: menuItems,
        attributes: {className: 'menu scrollable shelf'},
        action:function(e) {
          var elm = e.data.selectedItem.data;
          module.openBuilder(elm);
        }
      }),
      {
        label: module.name + 's',
        elementType:'span',
        above: true,
        attributes: {className: 'shelf-button multi '+type}
      }
    );

    var validElements = elms.find(function(e){
      return e.model.exists('content.valid') && e.model.get('content.valid') === false;
    });

    var errorCountCls = validElements ? 'count-error' : '';
    button.insert(new Element('div', {className: 'count ' + errorCountCls}).update(elms.length));
    button.insert(new Element('div', {className: 'arrow'}));
    this.shelf.insert(button);
  },

  elementAttributeChanged: function(e) {
    if (e.data.accessor === 'name') {
      this.updateShelf();
    }
  },

  updateShelf: function() {
    this.shelf.clear();
    var groups = {};
    groups['lp-script'] = [];

    // var groups = [{type:'lp-script', elements:[]}];
    // JS: create groups by module type

    lp.getModulesWithUsageType('non-visible-page-widget').each( function(module) {
      groups[module.type] = this.editor.page.getElementsByType(module.type).findAll( function(elm) {
        elm.model.addListener('attributeChanged', this.elementAttributeChanged.bind(this));
        return !elm.exists('predefined') || !elm.get('predefined');
      }, this);
    }, this);

    var type, elms, len;
    for (type in groups) {
      elms = groups[type];
      len = elms.length;
      this.shelf.insert(new Element('div', {className: 'spacer'}));
      if (len === 0) {
        this.makeZeroElementButton(type);
      } else {
        this.makeMultiElementButton(elms, type);
      }
    }
    this.shelf.insert(new Element('div', {className: 'spacer'}));
  }
});
