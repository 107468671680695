/* globals Flog */
lp.pom.PageGoals = Class.create(jui.EventSource, {
  initialize: function(page){
    this.page = page;
    this.type = 'lp-pom-page-goals';
    this.name = 'Conversion Goals';

    page.addListener('POMReady', this);
    page.addListener('elementInserted', this);
    page.addListener('elementRemoved', this);
    page.addListener('beforePageSave', this);
  },

  POMReady: function() {
    this.page.elements.each(function(e) {
      if (e.canHaveGoals()) {
        e.addListener('goalChanged', this);
      }
    }, this);
  },

  elementInserted: function(e) {
    var elm = e.data;
    if (elm.type === 'lp-pom-form') {
      if (this.getActiveGoals().length === 0) {
        this.addActiveGoal(elm.getGoals()[0]);
      }
    }
    if (elm.canHaveGoals()) {
      elm.addListener('goalChanged', this);
    }
  },

  elementRemoved: function(e) {
    var elm = e.data.element;
    if (elm.type === 'lp-pom-form') {
      this.removeActiveGoal(elm.getGoals()[0]);
    }
    this.removeUnusedActiveGoals();
    if (elm.canHaveGoals()) {
      elm.removeListener('goalChanged', this);
    }
  },

  removeUnusedActiveGoals: function() {
    var possibleGoals = this.getGoals();
    var activeGoals = this.page.settings.activeGoals.clone();
    var change = false;
    this.page.settings.activeGoals.each(function(ag) {
      if (!possibleGoals.any(function(g) {return ag.url === g.url;})) {
        activeGoals = activeGoals.without(ag);
        change = true;
      }
    });

    if (change) {
      this.page.settings.activeGoals = activeGoals;
      this.fireEvent('activeGoalsChanged');
    }
  },

  goalChanged: function() {
    this.removeUnusedActiveGoals();
    this.fireEvent('availableGoalsChanged');
  },

  beforePageSave: function() {
    this.removeUnusedActiveGoals();
  },

  getGoals: function() {
    var goals = [];
    var unique = [];
    this.page.elements.each(function(e){
      if (e.canHaveGoals()) {
        goals = goals.concat(e.getGoals());
      }
    });

    goals.each(function(g){
      // JS: normalize the URL
      if (g.type === 'form') {
        unique.push(g);
      } else {
        var uri = new Flog.UriParser(g.url);
        if (uri.pathname === null && uri.protocol !== null) {
          g.url = g.url + '/';
        }
        if (Object.isUndefined(unique.find(function(u){return u.url === g.url;}))){
          unique.push(g);
        }
      }
    });

    goals = unique.sortBy(function(g) {
      return g.type;
    });

    return goals;
  },

  getActiveGoals: function() {
    return this.page.settings.activeGoals;
  },

  urlIsActiveGoal: function(url) {
    return this.getActiveGoals().any(function(ag) {
      return this.urlsMatch(this.stripTrailingSlash(ag.url),this.stripTrailingSlash(url));
    }, this);
  },

  stripTrailingSlash: function(url) {
    if(url.substr(-1) === '/') {
      return url.substr(0, url.length - 1);
    }
    return url;
  },

  isActiveGoal: function(goal) {
    return this.page.settings.activeGoals.any(function(activeGoal) {
      return activeGoal.type === goal.type && this.urlsMatch(activeGoal.url, goal.url);
    }, this);
  },

  addActiveGoal: function(g) {
    if (!this.isActiveGoal(g)) {
      this.page.settings.activeGoals.push(g);
      this.fireEvent('activeGoalsChanged');
      if(typeof eventTracker !== 'undefined' && typeof g.type !== 'undefined') {
        eventTracker.track("Set a Conversion Goal", "Editor");
      }
    }
  },

  urlsMatch: function(url1, url2) {
    return url1.toLowerCase() === url2.toLowerCase();
  },

  removeActiveGoal: function(g) {
    var found = this.page.settings.activeGoals.find(function(ag) {return ag.type === g.type && ag.url === g.url;});

    if (!Object.isUndefined(found)) {
      this.page.settings.activeGoals = this.page.settings.activeGoals.without(found);
      this.fireEvent('activeGoalsChanged');
    }

    if(typeof eventTracker !== 'undefined' && 0 === this.page.settings.activeGoals.length) {
      eventTracker.track("Disable Conversion Goals", "Editor");
    }
  },

  getPropertiesPanel: function(infoPanel) {
    return lp.pom.PageGoals.getPropertiesPanel(this, infoPanel);
  }
});


lp.pom.PageGoals.getPropertiesPanel = function(elm, infoPanel){
  lp.pom.PageGoals.elementInfoPanel = lp.pom.PageGoals.elementInfoPanel ||
  new (Class.create(
    lp.editor.panel.ElementPropertiesPanel, {type:this.type}, {
    initialize: function($super, infoPanel) {
      this.infoPanel = infoPanel;
      this.updating = false;
      $super();
      this.advancedPanel.hide();
    },

    installUI: function($super){
      $super();
      this.note = this.insert(new Element('div', {className:'note'}));
      // this.note.update('<p>Check at least one of the possible goals below to track conversions for this page.</p>')
      this.goalsList = this.insert(new jui.Component({attributes:{className:'goals-list clearfix'}}));
      this.note2 = this.insert(new Element('div', {className:'note'}).update(
        "<h3>External Conversion Tracking</h3>" +
        "<p>If you're using one of our click-through templates to push your visitors further into the sales funnel, you can use our external tracking script to measure the real conversion at the final destination point (e.g. after registration or sign up). If you use this method, then we recommend that you uncheck all other goals above to avoid duplicate conversions.</p>"+
        "<a href=\"http://support.unbounce.com/entries/334187-external-conversion-tracking\" target=\"_blank\">Read the full details here</a>"
      ));
    },

    setElement: function($super, goals) {
      this.element = goals;
      this.title.update(goals.name);
      this.title.className = goals.type;

      goals.addListener('activeGoalsChanged', this);
      goals.addListener('availableGoalsChanged', this);

      this.updateUI();
    },

    activeGoalsChanged: function() {
      if (!this.updating) {
        this.updateUI();
      }
    },

    availableGoalsChanged: function() {
      if (!this.updating) {
        this.updateUI();
      }
    },

    goalClicked: function(g, e) {
      var goals = this.element;
      var goalsTab = this.infoPanel.tabs.get('goals');

      this.updating = true;
      if (e.source.getValue()) {
        this.element.addActiveGoal(g);
      } else {
        this.element.removeActiveGoal(g);
      }

      if (goals.getActiveGoals().length === 0){
        if (!goalsTab.e.hasClassName('warn')) {
          goalsTab.e.addClassName('warn');
        }
      } else {
        goalsTab.e.removeClassName('warn');
      }

      this.updating = false;
    },

    updateUI: function() {
      var goals = this.element;
      var possibleGoals = goals.getGoals();
      var goalsTab = this.infoPanel.tabs.get('goals');

      this.goalsList.clear();

      if (possibleGoals.length === 0) {
        if (!goalsTab.e.hasClassName('warn')) {
          goalsTab.e.addClassName('warn');
          // this.title.addClassName('warn');
        }
        this.note.update('<p>There are currently no items on the page that can be selected as conversion goals. To create a conversion goal add a Form, Buttons, Images with links or Text links to the page then check off which ones you would like to count as a conversion goal.</p>');
        return;
      }

      if (goals.getActiveGoals().length === 0){
        if (!goalsTab.e.hasClassName('warn')) {
          goalsTab.e.addClassName('warn');
          // this.title.addClassName('warn');
        }
      } else {
        goalsTab.e.removeClassName('warn');
        // this.title.removeClassName('warn');
      }

      this.note.update('<p>Check at least one of the possible items below for it to be tracked as a conversion goal for this page.</p>');

      possibleGoals.each(function(g) {
        this.goalsList.insert(new jui.FormCheckboxInput({
          label:(( g.type === 'form' ) ? 'Form Submission' : '<span class="type">'+g.type.capitalize()+':</span>'+g.url),
          onclick: this.goalClicked.bind(this, g),
          checked:goals.isActiveGoal(g)
        }));

      }, this);
    },

    activate: function($super) {
      $super();
      this.updateUI();
    }
  }))(infoPanel);

  this.elementInfoPanel.setElement(elm);
  return this.elementInfoPanel;
};
