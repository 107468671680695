//  require <jui>
//
jui.ModalSheet = Class.create( jui.Component, {
  options: function($super, options) {
    return $super($H({
      position: {
        x: 'center',
        y: 0
      },
      clickOutsideWillClose:false,
      attributes: {
        className: 'modal-sheet'
      }
    }).merge(options));
  },

  initialize:function($super, parent, options){
    $super(options);
    this.animator = null;
  },

  installUI: function($super) {
    $super();
    this.screen = new Element('div', {className:'modal-sheet-screen'});
    // this.screen.setOpacity(0.3);
    this.content = this.insert(new jui.Component({attributes:{className:'content clearfix'}}));
    this.content.setOpacity(0.94);
  },

  insertContent: function(content) {
    return this.content.insert(content);
  },

  isOpen: function() {
    return this.parent !== null;
  },

  open: function(parent, options) {
    /* jshint unused:vars */
    parent.insert(this.screen);
    parent.insert(this);

    var dims = this.content.getDimensions();
    var w = dims.width + 20;
    var h = dims.height + 10;
    var startH = 0;
    var self = this;

    this.setDimensions({width:w, height:0});
    this.setOffset({left:Math.round((parent.getWidth() - w) / 2), top:0});

    this.animator = new PeriodicalExecuter( function(pe) {
      if (startH >= h) {
        self.setHeight(h);
        pe.stop();
        return;
      }
      // self.content.setTop(startH-h);
      self.setHeight(startH);
      startH += 20;
    }, 0.02);


    this.fireEvent('modalSheetOpened');
  },

  close: function() {
    this.screen.remove();
    this.remove();
    this.fireEvent('modalSheetClosed');
  }
});
