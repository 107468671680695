(function(){
  jui.DynamicTagAttributeManager = {

    _addTitleToDynamicTags: function(tags) {
      jQuery(tags).each(function(i, tag){
        $(tag).setAttribute('title', 'Parameter: ' + $(tag).readAttribute('parameter'));
      });
    },

    _getDynamicAttributes: function(element) {
      if (this.isDynamicElement(element.parentNode)) {
        return this.getAttributesFromElement(element.parentNode);
      } else if (this.isDynamicElement(element)) {
        return this.getAttributesFromElement(element);
      } else {
        // this is a fall-back to accomodate for inconsistency between
        // Firefox and Chrome. Firefox selects outside the element.
        var el = jQuery(this.getRange().cloneContents()).children('ub\\:dynamic')[0];
        return this.getAttributesFromElement(el) || {};
      }

    },

    _sanitizeUneededAttributesForSave: function(tag) {
      tag.removeAttribute('contenteditable');
      tag.removeAttribute('title');
      if(!tag.readAttribute('method') || tag.readAttribute('method') === '')  {
        tag.removeAttribute('method');
      }
    }
  };
})();
