lp.module.block.PageSectionElementPropertiesPanel = Class.create(
  lp.editor.panel.ElementPropertiesPanel,
  lp.editor.panel.ElementGeometryControls,
  lp.editor.panel.ElementBackgroundControls,
  lp.editor.panel.ElementBorderControls,
  lp.ModuleComponent,
  {
    type:'lp-pom-block',

   initialize: function($super) {
      $super();
      var self = this;

      this.modelChangeHandlers.push(function(e) {

        var accessor = e.data.accessor;
        var value = e.data.value;
        switch(accessor) {
        case 'geometry.fitWidthToPage':
          value = value || false;
          self.controls.fitWidthToPage.setValue(value);
          self.controls.apply.checkboxes.left.disabled = value;
          self.controls.apply.checkboxes.right.disabled = value;
          break;
        case 'geometry.margin.bottom':
          self.controls.marginBottom.setValue(value);
          break;
        case 'geometry.margin':
          // TODO-TR: what about value= 'auto'
          self.controls.marginBottom.setValue(
            (value && value.bottm) ? value.bottom : 0);
          break;
        }
      });
    },

    installUI: function($super){
      $super();
      var self = this;
      var geometryPanel = this.insert(new jui.CollapsiblePanel({title:'Geometry'}));
      var borderPanel = this.insert(new jui.CollapsiblePanel({title:'Border Properties'}));
      var backgroundPanel = this.insert(new jui.CollapsiblePanel({title:'Background Properties'}));

      this.installGeometryControls({
        insert:geometryPanel.insertContent.bind(geometryPanel),
        sizeOptions:{disableWidth:true}
      });

      this.controls.marginBottom = geometryPanel.insertContent( new jui.FormNumericInput({
        label:"Gap Below",
        onfocus: this.requestFocus.bind(this),
        onblur: function(e) {
          var marginBottom = e.source.getValue() * 1;
          self.performModelUpdates( function() {
              self.element.model.setMargin({
                left:'auto',
                right:'auto',
                bottom:marginBottom
              },
              self.element.page.undoManager);
          });
        }
      }));


      this.installBorderControls({insert:borderPanel.insertContent.bind(borderPanel)});

      this.controls.fitWidthToPage = backgroundPanel.insertContent(new jui.FormCheckboxInput({
        label:'Stretch background to page edges',
        onclick: function(e) {
          self.performModelUpdates( function() {
            if (e.data) {
              self.element.model.set('geometry.fitWidthToPage', true, self.element.page.undoManager);
            } else {
              self.element.model.set('geometry.fitWidthToPage', false, self.element.page.undoManager);
            }
            self.handleBorderCheckboxes(e.data);
          });
        }
      }));
      this.installBackgroundControls({insert:backgroundPanel.insertContent.bind(backgroundPanel)});
    },

    handleBorderCheckboxes: function(isDisabled) {
      var checkboxes = this.controls.apply.checkboxes;
      checkboxes.left.disabled = isDisabled;
      checkboxes.right.disabled = isDisabled;
      var model = this.element.model;

      if(isDisabled) {
        checkboxes.left.checked = false;
        checkboxes.right.checked = false;

        model.set(
          'geometry.savedBorderState.left',
          model.get('geometry.borderApply.left')
        );
        model.set(
          'geometry.savedBorderState.right',
          model.get('geometry.borderApply.right')
        );

        model.set('geometry.borderApply.left', false);
        model.set('geometry.borderApply.right', false);
      } else {
        if(model.exists('geometry.savedBorderState.left')) {
          model.set(
            'geometry.borderApply.left',
            model.get('geometry.savedBorderState.left')
          );
        } else {
          model.set('geometry.borderApply.left', true);
          model.set('geometry.savedBorderState.left', true);
        }

        if(model.exists('geometry.savedBorderState.right')) {
          model.set(
            'geometry.borderApply.right',
            model.get('geometry.savedBorderState.right')
          );
        } else {
          model.set('geometry.borderApply.right', true);
          model.set('geometry.savedBorderState.right', true);
        }

        checkboxes.left.checked = model.get('geometry.borderApply.left');
        checkboxes.right.checked = model.get('geometry.borderApply.right');

      }
    },

    syncUIControls: function($super, elm) {
      //TODO: Add spec and refactor;
      $super(elm);
      this.bindGeometryControlsToElement(elm);
      this.bindBackgroundControlsToElement(elm);
      this.bindBorderControlsToElement(elm);

      var fwtp = elm.model.exists('geometry.fitWidthToPage') &&
        elm.model.get('geometry.fitWidthToPage');
      this.controls.fitWidthToPage.setValue(fwtp);
      this.controls.apply.checkboxes.left.disabled = fwtp;
      this.controls.apply.checkboxes.right.disabled = fwtp;

      var margin = elm.model.getMargin();
      var marginBottom = typeof margin.bottom === 'undefined' ? 0 : margin.bottom;
      this.controls.marginBottom.setValue(marginBottom);
      this.controls.marginBottom.setVisible(elm.page.allowSectionMargins());
    }
  }
);
