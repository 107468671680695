// require <prototype>
(function() {
  /* jshint maxstatements:48 */
  var window = this;
  var traceEvent = rx_switchboard.getPublisher('jui_events');

  this.jui = {
    isComponent: function(obj) {
      return obj instanceof jui.Component;
    }
  };

  jui.EventSource = {
    getListenerList: function() {
      return (this.listenerList || (this.listenerList = {}));
    },

    getListeners: function(t) {
      // returns a clone so that operations such as removeListener can be performed within event handler
      return this.hasListeners(t) ? (this.getListenerList())[t].clone() : [];
    },

    hasListeners: function(t) {
      return typeof this.getListenerList()[t] !== 'undefined';
    },

    hasListener: function(t, l) {
      var ll = this.getListenerList();
      if (typeof ll[t] === 'undefined') { return false; }
      return !Object.isUndefined(ll[t].find(function(li) {
        return li.target === l;
      }));
    },

    addListener: function(t, l, h) {
      var ll = this.getListenerList();
      (ll[t] || (ll[t] = [])).push({
        target: l,
        handler: h
      });
      return this;
    },

    removeListener: function(t, l) {
      var ll = this.getListenerList();
      if (typeof ll[t] === 'undefined') { return; }
      ll[t] = ll[t].reject(function(li) {
        return li.target === l;
      });
      return this;
    },

    fireEvent: function(t, d) {
      if (!this.hasListeners(t)) { return; }
      var e = {};
      e.source = this;
      e.type = t;
      e.data = d;

      // rx.js debug trace
      traceEvent(e);

      var list = this.getListeners(t),
        li;
      for (var i = 0, l = list.length; i < l; i++) {
        li = list[i];
        if (Object.isFunction(li.target)) {
          li.target(e);
          continue;
        }
        li.target[t](e);
      }
    }
  };

  // this method allows you to test whether or not a property is defined in a deeply nested object
  // eg.:
  // jui.isDefined({a:{b:{}}, 'a.b.c') -> false
  // jui.isDefined({a:{b:{c:0}}, 'a.b.c') -> true
  /*
   * TODO - Test
   */
  jui.isDefined = function(obj, key) {
    return typeof jui.deepQuery(obj, key) === 'undefined' ? false : true;
  };

  /*
   * TODO - Test
   */
  jui.isDefinedAndTruthy = function(obj, key, isShallow) {
    if(isShallow) {
      return !!jui.shallowQuery(obj, key);
    }
    return !!jui.deepQuery(obj, key);
  };

  jui.shallowQuery = function(obj, key) {
    var key = key.split('.').first();
    return !!obj[key];
  };

  jui.deepQuery = function(obj, key) {
    var arr = key.split('.');
    for (var i=0,l=arr.length; i < l; i++) {
      obj = obj[arr[i]];
      if (typeof obj === "undefined" || (obj === null && i < (l-1))) {
        return;
      }
    }
    return obj;
  };

  // this method allows you to set a property whether or not the property is defined in a deeply nested object
  // eg.:
  // jui.deepSet({a:{b:{}}, 'a.b.c', 4)
  // jui.deepSet({a:{b:{c:0}}, 'a.b.c', 4)
  // both result in {a:{b:{c:4}}
  /*
   * TODO - Test
   */

  jui.deepSet = function(obj, key, value) {
    var arr = key.split('.');
    for (var i=0,l=arr.length;i < l;i++) {
      var obj2 = obj[arr[i]];
      obj = (typeof obj2 !== "object" || obj2 === null) ? (obj[arr[i]] = (i < l-1) ? {} : value) : (i < l-1) ? obj2 : obj[arr[i]] = value;
    }
    return obj;
  };

  jui.deepDelete = function(obj, key) {
    if (!jui.isDefined(obj, key)) {
      return;
    }
    var arr = key.split('.');
    for (var i=0,l=arr.length-1;i < l;i++) {
      obj = obj[arr[i]];
    }
    return delete obj[arr[l]];
  };

  // JS: modifiewd version of JQuery's extend function
  // because neither prototype.js Hash#marge nor Object#extend is recursive
  /*
   * TODO - Test
   * TODO - Refactor for clarity
   */
  jui.extend = function() {
    /* jshint maxcomplexity:20 */
    var options, name, src, copy, copyIsArray, clone,
        target = arguments[0] || {},
        i      = 1,
        length = arguments.length,
        deep   = false;

    // Handle a deep copy situation
    if ( typeof target === "boolean" ) {
      deep = target;
      target = arguments[1] || {};
      // skip the boolean and the target
      i = 2;
    }

    // Handle case when target is a string or something (possible in deep copy)
    if ( typeof target !== "object" && !Object.isFunction(target) ) {
      target = {};
    }


    // extend jQuery itself if only one argument is passed
    if ( length === i ) {
      target = this;
      --i;
    }

    for ( ; i < length; i++ ) {
      // Only deal with non-null/undefined values
      if ( (options = arguments[ i ]) !== null ) {
        // Extend the base object
        for (name in options) {

          if(!options.hasOwnProperty(name)){continue;}

          src = target[name];
          copy = options[name];

          // Prevent never-ending loop
          if ( target === copy ) {
            continue;
          }

          //If the copy is an array make sure we set the copyIsArray to a flag.
          if(Object.isArray(copy)){copyIsArray = true;}

          // Recurse if we're merging plain objects or arrays
          if ( deep && copy && (copy !== null && typeof copy === 'object')) {
            /* jshint maxdepth:5 */
            if ( copyIsArray ) {
              copyIsArray = false;
              clone = src && Object.isArray(src) ? src : [];
            } else {
              clone = src && copy !== null && typeof copy === 'object' ? src : {};
            }
            // Never move original objects, clone them
            target[ name ] = jui.extend( deep, clone, copy );

            // Don't bring in undefined values
          } else if ( copy !== undefined ) {
            target[ name ] = copy;
          }
        }
      }
    }

    // Return the modified object
    return target;
  };

  jui.clone = function(obj) {
    return jui.extend(true, {}, obj);
  };

  jui.capitalizeAll = function(string) {
    return string.split(' ').collect(function(s) {return s.capitalize();}).join(' ');
  };

  jui.Options = {
    setOptions: function(options) {
      this.options = (this.options().merge($H(options))).toObject();
    }
  };

  jui.Point = Class.create({
    initialize: function() {
      if (arguments.length === 1) {
        var p = arguments[0];
        this.left = p.left;
        this.top = p.top;
      } else {
        this.left = arguments[0];
        this.top = arguments[1];
      }
    },

    pointify: function(args) {
      if (args.length === 1) {
        return args;
      }
      return {
        x: args[0],
        y: args[1]
      };
    },

    moveTo: function(p) {
      //var p = this.pointify(arguments);
      this.left = p.left;
      this.top = p.top;
      return this;
    },

    translate: function(p) {
      return this.add(p);
    },

    add: function(p) {
      this.left += p.left;
      this.top += p.top;
      return this;
    },

    subtract: function(p) {
      this.left -= p.left;
      this.top -= p.top;
      return this;
    },

    multiply: function(p) {
      this.left *= p.left;
      this.top *= p.top;
      return this;
    },

    multiplyByScalar: function(v) {
      this.left *= v;
      this.top *= v;
      return this;
    },

    min: function(p) {
      this.left = Math.min(this.left, p.left);
      this.top = Math.min(this.top, p.top);
      return this;
    },

    max: function(p) {
      this.left = Math.max(this.left, p.left);
      this.top = Math.max(this.top, p.top);
      return this;
    },

    clone: function() {
      return new jui.Point(this.left, this.top);
    }
  });

  Object.extend(jui.Point, {
    add: function(p1, p2) {
      p1.left += p2.left;
      p1.top += p2.top;
      return p1;
    },

    subtract: function(p1, p2) {
      p1.left -= p2.left;
      p1.top -= p2.top;
      return p1;
    },

    multiply: function(p1, p2) {
      p1.left *= p2.left;
      p1.top *= p2.top;
      return p1;
    },

    multiplyByScalar: function(p1, v) {
      p1.left *= v;
      p1.top *= v;
      return p1;
    },

    min: function(p1, p2) {
      p1.left = Math.min(p1.left, p2.left);
      p1.top = Math.min(p1.top, p2.top);
      return p1;
    },

    max: function(p1, p2) {
      p1.left = Math.max(p1.left, p2.left);
      p1.top = Math.max(p1.top, p2.top);
      return this;
    },

    clone: function(p1) {
      return {left:p1.left, top:p1.top};
    },

    intersectionWithSlope: function(p, w, h) {
      /* JS: TODO: this can probably be reduced further */
      /* inverse slope of line through [w,h] and [0,0] */
      var s = w / h;
      /* distance from [w,h] to line through [x,y] which is perpendicular to line through [0,0] and [w,h] */
      var d = (s * p.left + p.top) / (Math.sqrt(s * s + 1));
      /* it's angle */
      var a = Math.atan(s);
      /* intersection point */
      p.left = Math.round(Math.sin(a) * d);
      p.top = Math.round(Math.cos(a) * d);
      return p;
    }
  });

  jui.Dimensions = Class.create({
    initialize: function() {
      if (arguments.length === 1) {
        var r = arguments[0];
        this.width = r.width || r[2] || 0;
        this.height = r.height || r[3] || 0;
      } else {
        this.width = arguments[2] || 0;
        this.height = arguments[3] || 0;
      }
    },

    add: function(d) {
      this.width += d.width;
      this.height += d.height;
      return this;
    },

    subtract: function(d) {
      this.width -= d.width;
      this.height -= d.height;
      return this;
    },

    multiply: function(d) {
      this.width *= d.width;
      this.height *= d.height;
      return this;
    },

    min: function(d) {
      this.width = Math.min(this.width, d.width);
      this.height = Math.min(this.height, d.height);
      return this;
    },

    max: function(d) {
      this.width = Math.max(this.width, d.width);
      this.height = Math.max(this.height, d.height);
      return this;
    },

    clone: function() {
      return new jui.Dimension(this.width, this.height);
    }
  });

  Object.extend(jui.Dimensions, {
    add: function(p1, p2) {
      p1.width += p2.width;
      p1.height += p2.height;
      return p1;
    },

    subtract: function(p1, p2) {
      p1.width -= p2.width;
      p1.height -= p2.height;
      return p1;
    },

    multiply: function(p1, p2) {
      p1.width *= p2.width;
      p1.height *= p2.height;
      return p1;
    },

    multiplyByScalar: function(p1, v) {
      p1.width *= v;
      p1.height *= v;
      return p1;
    },

    min: function(p1, p2) {
      p1.width = Math.min(p1.width, p2.width);
      p1.height = Math.min(p1.height, p2.height);
      return p1;
    },

    max: function(p1, p2) {
      p1.width = Math.max(p1.width, p2.width);
      p1.height = Math.max(p1.height, p2.height);
      return this;
    },

    clone: function(p1) {
      return {left:p1.width, top:p1.height};
    }
  });

  jui.Rectangle = Class.create({
    initialize: function() {
      if (arguments.length === 1) {
        var r = arguments[0];
        this.left = r.left || r[0] || 0;
        this.top = r.top || r[1] || 0;
        this.width = r.width || r[2] || 0;
        this.height = r.height || r[3] || 0;
      } else {
        this.left = arguments[0] || 0;
        this.top = arguments[1] || 0;
        this.width = arguments[2] || 0;
        this.height = arguments[3] || 0;
      }
    },

    moveTo: function(x, y) {
      this.left = x;
      this.top = y;
      return this;
    },

    translate: function(x, y) {
      this.left += x;
      this.top += y;
      return this;
    },

    add: function(p) {
      this.left += p.left;
      this.top += p.top;
      return this;
    },

    subtract: function(p) {
      this.left -= p.left;
      this.top -= p.top;
      return this;
    },

    multiply: function(p) {
      this.left *= p.left;
      this.top *= p.top;
      return this;
    },

    isWithin: function(p) {
      return p.left >= this.left && p.top >= this.top && p.left < (this.left + this.width) && p.top < (this.top + this.height);
    },

    clone: function() {
      return new jui.Rectangle(this.left, this.top, this.width, this.height);
    }
  });

  Object.extend(jui.Rectangle, {
    isWithin: function(r, p) {
      return p.left >= r.left && p.top >= r.top && p.left < (r.left + r.width) && p.top < (r.top + r.height);
    },

    intersect: function(r1, r2) {
      var left = Math.max(r1.left, r2.left);
      var top = Math.max(r1.top, r2.top);

      return {
        left: left,
        top: top,
        width: Math.min(r1.left + r1.width, r2.left + r2.width) - left,
        height: Math.min(r1.top + r1.height, r2.top + r2.height) - top
      };
    }
  });

  jui.Component = Class.create(jui.EventSource, jui.Options, {
    options: function(options) {
      return $H({
        elementType: 'div',
        attributes: $H({
          me: 1
        })
      }).merge(options);
    },

    initialize: function(options) {
      this.setOptions(options);
      // this.options = options = this.createDefaultOptions( options );
      // options.attributes.className =
      //   ( options.attributes.className ? options.attributes.className +' ' : '' ) +
      //   ( options.classNames ? options.classNames.join(' ') : '' );
      // options.elementType = options.elementType || 'div';
      // this.toolTip = options.toolTip || null;
      this.parent = null;
      this.children = $A([]);
      this.installUI();
      this.id = this.id || this.e.id;
    },

    installUI: function() {
      this.e = this.options.element ? $(this.options.element).writeAttribute(this.options.attributes) : new Element(this.options.elementType, this.options.attributes);
      if (this.options.classNames) {
        $A(this.options.classNames).each(function(c) {
          this.e.addClassName(c);
        },
        this);
      }

      if (this.options.toolTip) {
        this.e.observe('mouseover', jui.Component.Tooltip.show.bind(jui.Component.Tooltip, this));
        this.e.observe('mouseout', jui.Component.Tooltip.hide.bind(jui.Component.Tooltip, this));
      }
    },

    doWith: function(f) {
      f(this);
    },

    observe: function(eventName, handler) {
      this.e.observe(eventName, handler);
    },

    _insert: function(obj, position) {
      try {
        var e = Object.isElement(obj) ? obj : obj.e;
        var insertion;
        if (Object.isNumber(position) && position < this.children.length) {
          this.children[position].insert(e, 'before');
        } else if (Object.isString(position)) {
          insertion = {};
          insertion[position] = e;
          this.e.insert(insertion);
        } else {
          this.e.insert(e);
        }

        this.children.push(obj);
        if (typeof obj.setParent === 'function') {
          obj.setParent(this);
        }
        this.fireEvent('childInserted', obj);
        return obj;
      } catch(err) {
        console.log("jui.Component insert error "+obj);
      }
    },

    insert: function(obj, position) {
      return this._insert(obj, position);
    },

    _remove: function(obj) {
      if (Object.isUndefined(obj) || obj === this || obj === this.e) {
        if (this.parent !== null) {
          // let the jui.Component parent do the removal so that it can modify it's children property
          return this.parent.remove(this);
        }
        // there is no jui.Component parent
        if (this.e.parentNode === null) {
            return;
        }
        this.e.remove();
        return this;
      }

      var e = Object.isElement(obj) ? obj : obj.e;
      if (e.parentNode !== null) {
       e.remove();
      }
      this.children = this.children.without(obj);
      if (typeof obj.setParent === 'function') {
        obj.setParent(null);
      }
      this.fireEvent('childRemoved', obj);
      return obj;
    },

    remove: function(obj) {
      return this._remove(obj);
    },

    setParent: function(parent) {
      this.parent = parent;
    },

    _clear: function() {
      // this.e.update();
      var self = this;
      this.children.each(function(c) {
        self.remove(c);
      });
      this.e.update();
      this.children = [];
      this.fireEvent('cleared');
    },

    clear: function() {
      this._clear();
    },

    update: function(content) {
      this.e.update(content);
      this.fireEvent('contentUpdated');
      return this;
    },

    addClassName: function(name) {
      this.e.addClassName(name);
    },

    removeClassName: function(name) {
      this.e.removeClassName(name);
    },

    visible: function() {
      return this.e.visible();
    },

    setVisible: function(b) {
      this[b ? 'show' : 'hide']();
    },

    hide: function() {
      this.e.hide();
      this.fireEvent('hidden');
      return this;
    },

    show: function() {
      this.e.show();
      this.fireEvent('shown');
      return this;
    },

    toggleVisibility: function() {
      this.e.toggle();
    },

    setStyle: function(styles) {
      this.e.setStyle(styles);
    },

    setOpacity: function(o) {
      this.e.setOpacity(o);
    },

    makeAbsolute: function() {
      this.e.style.position = 'absolute';
      return this;
    },

    makeRelative: function() {
      this.e.style.position = 'relative';
      return this;
    },

    getOffset: function() {
      return this.e.positionedOffset();
    },

    setOffset: function(point) {
      if (typeof  point.left === 'undefined' || typeof  point.top === 'undefined') {
        throw "Point not properly defined: " + Object.toJSON(point);
      }
      var old = this.getOffset();
      this.e.style.left = point.left + 'px';
      this.e.style.top = point.top + 'px';
      if(!(old.left === point.left && old.top === point.top)) {
        this.fireEvent('moved', point);
      }
    },

    moveBy: function(point) {
      return this.setOffset(new jui.Point(this.getOffset()).add(point));
    },

    cumulativeOffset: function() {
      return this.e.cumulativeOffset();
    },

    getPageRect: function() {
      var offset = this.e.cumulativeOffset();
      return {
        left: offset.left,
        top: offset.top,
        width: this.e.getWidth(),
        height: this.e.getHeight()
      };
    },

    setLeft: function(v) {
      var old = this.getOffset();
      this.e.style.left = v + 'px';
      var point = this.getOffset();

      if(!(old.left === point.left && old.top === point.top)) {
        this.fireEvent('moved', point);
      }
    },

    getLeft: function() {
      return this.getOffset().left;
    },

    setTop: function(v) {
      var old = this.getOffset();
      this.e.style.top = v + 'px';
      var point = this.getOffset();

      if(!(old.left === point.left && old.top === point.top)) {
        this.fireEvent('moved', point);
      }
    },

    getTop: function() {
      return this.getOffset().top;
    },

    setRight: function(v) {
      this.e.style.right = v + 'px';
      this.fireEvent('resized', v);
    },

    getRight: function() {
      return this.getOffset().left + this.getWidth();
    },

    setBottom: function(v) {
      this.e.style.bottom = v + 'px';
      this.fireEvent('resized', v);
    },

    getBottom: function() {
      return this.getOffset().top + this.getHeight();
    },

    setDimensions: function(dim, unit) {
      //TODO: refactor this confusion.
      // why are we alerting? why do we accept both width,height and w,h?

      if (!Object.isUndefined(dim.w)) {
        alert(dim.w);
      }
      var w = (dim.w || dim.width);
      var h = (dim.h || dim.height);

      this.e.style.width = (Object.isUndefined(w) || w === 0 || w === '' || w === null) ? null : w + (unit || 'px');
      this.e.style.height = (Object.isUndefined(h) || h === 0 || h === '' || h === null) ? null : h + (unit || 'px');
      this.fireEvent('resized', dim);
    },

    getDimensions: function() {
      return this.e.getDimensions();
    },

    setWidth: function(w, unit) {
      this.e.style.width = (Object.isNumber(w)) ? w + (unit || 'px') : null;
      this.fireEvent('resized', w);
    },

    getWidth: function() {
      return this.e.getWidth();
    },

    getInnerWidth: function() {
      return this.getWidth();
    },

    setHeight: function(h, unit) {
      this.e.style.height = (Object.isUndefined(h) || h === '' || h === null) ? null : h + (unit || 'px');
      this.fireEvent('resized', h);
    },

    getHeight: function() {
      return this.e.getHeight();
    },

    setScale: function(scale, origin) {
      if (scale === 1) {
        this.e.style.webkitTransform       = '';
        this.e.style.webkitTransformOrigin = '';
        this.e.style.transform             = '';
        this.e.style.transformOrigin       = '';
        return;
      }

      var newScale  = 'scale('+scale+')';
      var newOrigin = origin || '0 0';

      this.e.style.webkitTransform       = newScale;
      this.e.style.transform             = newScale;
      this.e.style.webkitTransformOrigin = newOrigin;
      this.e.style.transformOrigin       = newOrigin;
    },

    setZIndex: function(v) {
      this.e.style.zIndex = v;
    },

    getZIndex: function() {
      var z = this.e.style.zIndex * 1;
      return Object.isNumber(z) ? z : 0;
    },

    pulse: function(cycleTime, minOpacity, duration) {
      var startTime = Date.now();
      var self = this;
      this.pulseInterval = setInterval(function() {
        var timeElapsed = Date.now() - startTime;
        if (typeof duration === 'number' && timeElapsed >= duration) {
          self.stopPulse();
          return;
        }

        var opacity = (1 - minOpacity) * Math.sin(timeElapsed / cycleTime * Math.PI) + minOpacity;
        self.setOpacity(opacity);

      }, 5);
    },

    stopPulse: function() {
      clearInterval(this.pulseInterval);
      this.setOpacity(1);
    }
  });

  jui.Component.Tooltip = {
    show: function(c) {
      // if (c.isEnabled && c.isEnabled() === false) return;
      if (!this.e) {
          this.createElement();
      }
      this.timeoutID = ( function() {
        $(window.document.body).insert(this.e);
        var co = c.e.cumulativeOffset();
        var cso = c.e.cumulativeScrollOffset();
        var pos = {left:co.left - cso.left, top: co.top - cso.top};
        var dim = c.getDimensions();
        this.label.update(c.options.toolTip);

        var w = this.e.getWidth();
        var ah = this.arrow.getHeight();
        var th = this.e.getHeight();
        var h = th + ah;

        // this.label.style.position = 'absolute';
        var x = Math.min(Math.max(0, pos.left + (dim.width / 2) - (w / 2)), $(window.document.body).getWidth() - w);
        var y, arrowy;
        if (pos.top < (h+3)) {
          y = pos.top + dim.height + ah;
          arrowy = -ah;
          this.e.addClassName('under');
        } else {
          y = pos.top - (h+3);
          arrowy = th;
          this.e.removeClassName('under');
        }

        var arrowx = (w - this.arrow.getWidth()) / 2;

        this.e.style.left = x + 'px';
        this.e.style.top = y + 'px';
        this.arrow.style.left = arrowx + 'px';
        this.arrow.style.top = arrowy + 'px';
      }).bind(this).delay(0.7);
    },

    hide: function() {
      if (!this.e) {
          return;
      }
      window.clearTimeout(this.timeoutID);
      if (this.e.parentNode) {
        this.e.remove();
      }
    },

    createElement: function() {
      this.e = new Element('div', {className: 'jui-tooltip'});
      this.label = new Element('span');
      this.arrow = new Element('div', {className: 'arrow'});
      this.e.insert(this.label);
      this.e.insert(this.arrow);
    }
  };

  //------------------------------------------------------------------------------
  jui.ControlModel = {
    isEnabled: function() {
      // return !this.e.hasClassName('dsbl');
      return !this.disabled;
    },

    setEnabled: function(b) {
      this[b ? 'enable' : 'disable']();
    },

    enable: function() {
      this.disabled = false;
      if (Object.isElement(this.e)) {
        this.e.removeClassName('dsbl');
      }
    },

    disable: function() {
      this.disabled = true;
      if (Object.isElement(this.e)) {
        this.e.addClassName('dsbl');
      }
    }
  };

  //------------------------------------------------------------------------------
  jui.ToggleModel = {
    toggle: function() {
      this.e.toggleClassName('on');
    },

    toggleOn: function() {
      this.e.addClassName('on');
    },

    toggleOff: function() {
      this.e.removeClassName('on');
    },

    isSelected: function() {
      return this.e.hasClassName('on');
    },

    setSelected: function(bool) {
      this[bool ? 'toggleOn' : 'toggleOff']();
    }
  };

  //------------------------------------------------------------------------------
  jui.Button = Class.create(jui.Component, jui.ControlModel, {
    options: function($super, options) {
      return $super($H({
        elementType: 'a',
        attributes: {
          className: 'button'
        }
      }).merge(options));
    },

    initialize: function($super, options) {
      $super(options);

      if (this.options.action) {
        this.addListener('actionPerformed', this.options.action);
      }
    },

    installUI: function($super) {
      if (this.options.elementType === 'a') {
        this.options.attributes.href = this.options.attributes.href || '#';
      }

      $super();

      this.e.observe('click', this.onClick.bindAsEventListener(this));
      this.e.observe( 'mousedown', function(e) {e.stop();} );
      // this.label = this.e.select('span')[0] || ((this.options.label) ? this.createLabel(this.options.label) : null);
      this.label = this.e.select('span')[0] || this.createLabel(this.options.label);
    },

    setAction: function(action){
      if (this.options.action) {
        this.removeListener('actionPerformed', this.options.action);
      }

      this.options.action = action;
      this.addListener('actionPerformed', this.options.action);
    },

    createLabel: function() {
      return this.insert(new Element('span', {
        className: 'label'
      }).update(this.options.label || ''));
    },

    setLabel: function(text) {
      if (!this.label) {
        this.createLabel();
      }

      this.label.update(text);
    },

    getLabel: function() {
      return this.label ? this.label.innerHTML : null;
    },

    onClick: function(e) {
      if (this.isEnabled()) {
        e.stop();
        this.fireEvent('actionPerformed', this);
      }
    }
  });

  //------------------------------------------------------------------------------
  jui.RadioButtonGroup = Class.create({
    initialize: function() {
      this.activeButton = null;
    },

    setActiveButton: function(b) {
      if (this.activeButton) {
        this.activeButton.toggleOff();
      }
      b.toggleOn();
      this.activeButton = b;
    },

    actionPerformed: function(e) {
      this.setActiveButton(e.source);
    }
  });

  //------------------------------------------------------------------------------
  jui.ToggleButton = Class.create(jui.Button, jui.ToggleModel, {
    initialize: function($super, options) {
      $super(options);
    },

    onClick: function(e) {
      if (this.isEnabled()) {
        e.stop();
        this.toggle();
        this.fireEvent('actionPerformed', this.isSelected());
      }
    }
  });

  //------------------------------------------------------------------------------
  jui.RadioButton = Class.create(jui.Button, jui.ToggleModel, {
    initialize: function($super, options) {
      $super(options);

      // JS: I create an instance of jui.RadioButtonGroup here if one isn't passed in options to allow code like this:
      // var rb1 = new jui.RadioButton({label:'one'});
      // var rb2 = new jui.RadioButton({label:'two', radioGroup:rb1.group});
      // var rb3 = new jui.RadioButton({label:'three', radioGroup:rb1.group});
      this.setGroup(this.options.radioGroup || new jui.RadioButtonGroup());
    },

    setGroup: function(g) {
      if (this.group) {
        this.removeListener('actionPerformed', this.group);
      }
      this.group = g;
      this.addListener('actionPerformed', g);
    },

    makeActive: function() {
      this.fireEvent('actionPerformed');
    },

    onClick: function(e) {
      if (this.isEnabled() && !this.isSelected()) {
        e.stop();
        this.fireEvent('actionPerformed');
      }
    }
  });

  //------------------------------------------------------------------------------
  jui.IconButton = Class.create(jui.Button, {
    options: function($super, options) {
      return $super($H({
        attributes: {
          className: 'button icon'
        }
      }).merge(options));
    },

    initialize: function($super, name, options) {
      this.name = name;
      $super(options);
    },

    installUI: function($super) {
      this.options.attributes.className += ' '+this.name;
      $super();
    }
  });

  //------------------------------------------------------------------------------
  jui.IconButtonWithLabel = Class.create(jui.Button, {
    options: function($super, options) {
      return $super($H({
        attributes: {
          className: 'capsule icon'
        }
      }).merge(options));
    },

    initialize: function($super, name, options) {
      this.name = name;
      $super(options);
    },

    installUI: function($super) {
      this.options.attributes.className += ' '+this.name;
      $super();
      this.insert(new Element('div', {className:'icon'}).update(this.name.capitalize()));
    }
  });

  //------------------------------------------------------------------------------
  jui.IconToggle = Class.create(jui.ToggleButton, {
    options: function($super, options) {
      return $super($H({
        attributes: {
          className: 'button icon '
        }
      }).merge(options));
    },

    initialize: function($super, name, options) {
      this.name = name;
      $super(options);
    },

    installUI: function($super) {
      this.options.attributes.className += this.name;
      $super();
    }
  });


  //------------------------------------------------------------------------------
  jui.IconRadio = Class.create(jui.RadioButton, {
    options: function($super, options) {
      return $super($H({
        attributes: {
          className: 'button icon '
        }
      }).merge(options));
    },

    initialize: function($super, name, options) {
      this.name = name;
      $super(options);
    },

    installUI: function($super) {
      this.options.attributes.className += this.name;
      $super();
    }
  });


  //------------------------------------------------------------------------------
  jui.PopUpButton = Class.create(jui.Button, {
    options: function($super, options) {
      return $super($H({
        attributes: {
          className: 'button popup '
        }
      }).merge(options));
    },

    initialize: function($super, menu, options) {
      this.menu = menu;
      menu.parent = this;
      $super(options);
    },

    installUI: function($super) {
      $super();
      this.insert(new Element('div', {className:'arrow'}));

      var self = this;
      this.menu.addListener('menuDeactiavted', function() {
        self.e.removeClassName('active');
      });
      this.menu.addListener('menuActiavted', function() {
        self.e.addClassName('active');
      });
      this.e.observe('mousedown', function() {
        var offset = self.cumulativeOffset();
        jui.Menu.activate(self.menu);

        self.menu.setOffset({
          left: offset.left,
          top: self.options.above ?
              offset.top - self.menu.getHeight() - 1 :
              offset.top + self.getHeight() + 1
        });
      });
    }
  });

  //------------------------------------------------------------------------------
  jui.SplitMenuButton = Class.create(jui.Component, {
    options: function($super, options) {
      return $super($H({
        attributes: {
          className: 'split_menu_button'
        }
      }).merge(options));
    },

    initialize: function($super, options) {
      this.button = null;
      this.menuButton = null;
      this.menu = null;
      $super(options);
    },

    installUI: function($super, options) {
      $super(options);
      this.button = this.insert(new jui.Button(options.buttonOptions));
      this.menuButton = this.insert(new jui.IconRadio('menu_arrow', options.menuArrowOptions));
    }
  });

  //------------------------------------------------------------------------------
  jui.SplitMenuIconButton = Class.create(jui.Component, {
    options: function($super, options) {
      return $super($H({
        attributes: {
          className: 'split_menu_icon_button'
        }
      }).merge(options));
    },

    initialize: function($super, iconName, options) {
      this.options.iconName = iconName;
      this.button = null;
      this.menuButton = null;
      this.menu = null;
      $super(options);
    },

    setButton: function(b) {
      this.update();
      this.button = this.insert(b);
      this.insert(this.menuButton);
    },

    installUI: function($super, options) {
      $super(options);
      this.button = this.insert(new jui.IconButton(options.iconName, options.buttonOptions));
      this.menuButton = this.insert(new jui.IconRadio('menu_arrow', options.menuArrowOptions));
    }
  });

  //------------------------------------------------------------------------------
  jui.AlignSelect = Class.create(jui.Component, {
    options: function($super, options) {
      return $super($H({
        attributes: {
          className: 'align-select'
        }
      }).merge(options));
    },

    initialize: function($super, options) {
      $super(options);
      this.selectedValue = null;
      if (this.options.action) {
        this.addListener('select', this.options.action);
      }
    },

    installUI: function($super) {
      $super();
      this.selectListener = this.onSelect;
      this.leftTop = this.insert(new jui.RadioButton({
        action: this.onSelect.bind(this, 'left top')
      }));
      var rg = this.leftTop.group;
      this.centerTop = this.insert(new jui.RadioButton({
        radioGroup: rg,
        action: this.onSelect.bind(this, 'center top')
      }));
      this.rightTop = this.insert(new jui.RadioButton({
        radioGroup: rg,
        action: this.onSelect.bind(this, 'right top')
      }));

      this.leftCenter = this.insert(new jui.RadioButton({
        radioGroup: rg,
        action: this.onSelect.bind(this, 'left center')
      }));
      this.centerCenter = this.insert(new jui.RadioButton({
        radioGroup: rg,
        action: this.onSelect.bind(this, 'center center')
      }));
      this.rightCenter = this.insert(new jui.RadioButton({
        radioGroup: rg,
        action: this.onSelect.bind(this, 'right center')
      }));

      this.leftBottom = this.insert(new jui.RadioButton({
        radioGroup: rg,
        action: this.onSelect.bind(this, 'left bottom')
      }));
      this.centerBottom = this.insert(new jui.RadioButton({
        radioGroup: rg,
        action: this.onSelect.bind(this, 'center bottom')
      }));
      this.rightBottom = this.insert(new jui.RadioButton({
        radioGroup: rg,
        action: this.onSelect.bind(this, 'right bottom')
      }));
    },

    setValue: function(value) {
      this.selectedValue = value;
      this.leftTop.group.setActiveButton(this[value.gsub(' ', '-').camelize()]);
    },

    getValue: function() {
      return this.selectedValue;
    },

    onSelect: function(value) {
      this.selectedValue = value;
      this.fireEvent('select', value);
    }
  });

  //------------------------------------------------------------------------------
  jui.SliderModel = {
    // requires jui.EventSource
    ROLLOVER: 1 << 5,

    initializeModel: function(options) {
      options = options || {};
      this.value = 0;
      this.min = options.min || 0;
      this.max = options.max || 1;
      this.increment = options.increment || 0;

      this.clickOffset = 0;
    },

    setValue: function(value) {
      this.value = value;
      if (!this.options.allowOutOfRangeMaxValues) {
        this.value = Math.min(1, this.value);
      }
      if (!this.options.allowOutOfRangeMinValues) {
        this.value = Math.max(0, this.value);
      }

      if (this.increment > 0) {
        var range = this.max - this.min;
        var inc = this.increment;
        if (inc > 0) {
          this.value = (Math.round((this.min + this.value * range) / inc) * inc - this.min) / (range * 1.0);
        } else {
          this.value = (this.min + this.value - this.min) / (range * 1.0);
        }

      }
      this.fireEvent('valueChanged', this.value);
    },

    adjustValueToRange: function(value) {
      return Math.min(1, Math.max(0, value));
    },

    setRealValue: function(value) {
      var range = this.max - this.min;
      this.setValue((value - this.min) / (range * 1.0));
    },

    getValue: function() {
      return this.value;
    },

    getRealValue: function() {
      var range = this.max - this.min;
      var rv = this.value * range + this.min;
      if (this.increment > 0) {
        rv = Math.round(rv);
      }
      return rv;
    }
  };

  //------------------------------------------------------------------------------
  jui.Slider = Class.create(jui.Component, jui.ControlModel, jui.SliderModel, {
    options: function($super, options) {
      return $super($H({
        attributes: {
          className: 'slider'
        }
      }).merge(options));
    },

    initialize: function($super, options) {
      $super(options);
      this.initializeModel(this.options);

      this.tracking = false;

      this.mouseMoveListener = this.mousemove.bindAsEventListener(this);
      this.mouseUpListener = this.mouseup.bindAsEventListener(this);

      this.addListener('valueChanged', this.valueChanged.bind(this));
    },

    installUI: function($super) {
      $super();
      this.observe('mousedown', this.mousedown.bindAsEventListener(this));

      this.track = this.insert(new jui.Component({attributes: {className: 'track'}}));
      this.groove = this.track.insert(new jui.Component({attributes: {className: 'groove'}}));
      this.knob = this.track.insert(new jui.Component({attributes: {className: 'knob'}}));
    },

    setTracking: function(b) {
      if (this.tracking === b) {
        return;
      }

      if (b) {
        $(window.document).observe('mouseup', this.mouseUpListener);
        $(window.document).observe('mousemove', this.mouseMoveListener);
      } else {
        $(window.document).stopObserving('mouseup', this.mouseUpListener);
        $(window.document).stopObserving('mousemove', this.mouseMoveListener);
      }

      this.tracking = b;
    },

    valueChanged: function(e) {
      var kw = this.knob.getWidth();
      var w = this.track.getWidth() - kw;
      this.knob.setLeft(Math.round((this.adjustValueToRange(e.data) * w)));
    },

    show: function($super) {
      var r =  $super();
      this.updateKnob();
      return r;
    },

    updateKnob: function() {
      var kw = this.knob.getWidth();
      var w = this.track.getWidth() - kw;
      this.knob.setLeft(Math.round((this.adjustValueToRange(this.value) * w)));
    },

    mousedown: function(e) {
      if (this.isEnabled()) {
        Event.stop(e);
        var kw = this.knob.getWidth();
        var w = this.track.getWidth() - kw;
        var x = Event.pointerX(e) - this.cumulativeOffset().left - (kw / 2);
        this.setTracking(true);
        this.fireEvent('startTracking');
        this.setValue(x / w);
      }
    },

    mouseup: function(e) {
      Event.stop(e);
      this.setTracking(false);
      this.fireEvent('stopTracking');
    },

    mousemove: function(e) {
      if (this.isEnabled()) {
        Event.stop(e);
        var kw = this.knob.getWidth();
        var w = this.track.getWidth() - kw;
        var x = Event.pointerX(e) - this.cumulativeOffset().left - (kw / 2);

        this.setValue(x / w);
      }
    },

    updateAppearance: function(c) {
      var m = c.getModel();
      var e = c.getElement();
      if (c.isEnabled()) {
        if (m.isPressed() && m.isArmed()) {
          e.src = c.down.src;
        } else if (m.isRollover()) {
          e.src = c.over.src;
        } else {
          e.src = c.norm.src;
        }
      } else {
        e.src = c.dsbl.src;
      }
    }
  });

  //------------------------------------------------------------------------------
  jui.SliderKnob = Class.create(jui.Component, {
    options: function($super, options) {
      return $super($H({
        attributes: {
          className: 'slider_knob'
        }
      }).merge(options));
    },

    initialize: function($super, options) {
      $super(options);
    }
  });

  //------------------------------------------------------------------------------
  jui.ModalPanel = Class.create(jui.Component, {
    options: function($super, options) {
      return $super($H({
        position: {
          x: 'center',
          y: 'center'
        },
        clickOutsideWillClose:true,
        attributes: {
          className: 'modal-panel'
        }
      }).merge(options));
    },

    initialize: function($super, options) {
      this.mouseMoveListener = this.mousemove.bindAsEventListener(this);
      this.mouseUpListener = this.mouseup.bindAsEventListener(this);
      this.mouseDownListener = this.mousedown.bindAsEventListener(this);

      $super(options);
      var f = this.content.e.firstDescendant();
      if (f && (f.tagName.toLowerCase() === 'h1' || f.tagName.toLowerCase() === 'h2')) {
        f.observe('mousedown', this.mousedown.bindAsEventListener(this));
      }
      this.position = this.options.position;
    },

    installUI: function($super) {
      $super();
      this.content = this.insert(new jui.Component({
        attributes: {
          className: 'content clearfix'
        }
      }));
    },

    mousedown: function(e) {
      this.offset = new jui.Point(Event.pointerX(e), Event.pointerY(e)).subtract(this.getOffset());
      this.setTracking(true);
    },

    mouseup: function(e) {
      Event.stop(e);
      this.setTracking(false);
    },

    mousemove: function(e) {
      Event.stop(e);
      this.setOffset({
        left: Math.min(Math.max(Event.pointerX(e) - this.offset.left, 20 - this.getWidth()), window.innerWidth - 20),
        top: Math.min(Math.max(Event.pointerY(e) - this.offset.top, -20), window.innerHeight - 20)
      });
      jui.ModalPanel.fitShadow(this);
    },

    setTracking: function(b) {
      if (this.tracking === b) {
        return;
      }
      this.tracking = b;
      if (b) {
        $(window.document).observe('mouseup', this.mouseUpListener);
        $(window.document).observe('mousemove', this.mouseMoveListener);
      } else {
        $(window.document).stopObserving('mouseup', this.mouseUpListener);
        $(window.document).stopObserving('mousemove', this.mouseMoveListener);
      }
    },

    open: function(animate) {
      /* jshint unused:vars */
      jui.ModalPanel.modalOpened(this);
      this.updateLayout();
      jui.ModalPanel.fitShadow(this);
      this.fireEvent('modalPanelOpened');
    },

    updateLayout: function() {
      var loc = {
       left: this.position.x === 'center' ? (document.viewport.getWidth() - this.getWidth()) / 2 : this.position.x,
       top: this.position.y === 'center' ? (document.viewport.getHeight() - this.getHeight()) / 2 : this.position.y
      };
      this.setOffset(loc);
    },

    close: function() {
      jui.ModalPanel.modalClosed(this);
      this.fireEvent('modalPanelClosed');
    }
  });

  (function() {
    var activeModal = null;
    var _screen = null;
    var _shadow = null;

    var screen = function() {
      return _screen || (function() {
        _screen = new Element('div', {className: 'modal-screen'});
        _screen.observe('mousedown', jui.ModalPanel.closeActiveModal);
        return _screen;
      })();
    };

    var shadow = function() {
      return _shadow || (function() {
        _shadow = new Element('div', {className: 'modal-shadow'});
        _shadow.setOpacity(0.2);
        return _shadow;
      })();
    };

    Object.extend(jui.ModalPanel, jui.EventSource);

    Object.extend(jui.ModalPanel, {
      modalOpened: function(modal) {
        activeModal = modal;
        $(document.body).insert(modal.e);

        if (shadow().parentNode !== document.body) {
          document.body.appendChild(shadow());
        }

        if (screen().parentNode !== document.body) {
          document.body.appendChild(screen());
        }

        jui.ModalPanel.fireEvent('modalOpened');
      },

      modalClosed: function(modal) {
        shadow().remove();
        screen().remove();
        modal.e.remove();
        activeModal = null;

        jui.ModalPanel.fireEvent('modalClosed');
      },

      fitShadow: function(modal) {
        shadow().style.width = (modal.getWidth() + 12) + 'px';
        shadow().style.height = (modal.getHeight() + 12) + 'px';

        shadow().style.left = (modal.getLeft() - 6) + 'px';
        shadow().style.top = (modal.getTop() - 6) + 'px';
      },

      isModalOpen: function() {
        return activeModal !== null;
      },

      closeActiveModal: function() {
        if (activeModal.options.clickOutsideWillClose) {
          if (Object.isFunction(activeModal.cancel)) {
            activeModal.cancel();
          } else {
            activeModal.close();
          }
        }
      }
    });
  })();

  //------------------------------------------------------------------------------
  jui.FormSelect = Class.create(jui.Component, {
    options: function($super, options) {
      return $super($H({
        attributes: {
          className: 'form-elm select'
        },
        dropDownClassName: 'jui-drop-down' ,
        id: null,
        width: 'off',
        selectOptions: $A([])
      }).merge(options));

    },

    initialize: function($super, options) {
      $super(options);

      if (this.options.action) {
        this.addListener('select', this.options.action);
      }
    },

    installUI: function($super) {
      $super();
      this.label = this.insert(new Element('label').update(this.options.label));

      if (this.options.label === '' || this.options.label === null) {
        this.label.hide();
      }

      this.select = this.insert(new jui.FormSelectInput({
        attributes: {
          className: this.options.dropDownClassName,
          id: this.options.id
        },
        selectOptions: this.options.selectOptions,
        width: this.options.width,
        onChange: this.onSelect.bind(this)
      }));

    },

    isEnabled: function() {
      return !this.e.hasClassName('dsbl');
    },

    setEnabled: function(b) {
      this[b ? 'enable' : 'disable']();
    },

    enable: function() {
      this.e.removeClassName('dsbl');
      this.select.enable();
    },

    disable: function() {
      this.e.addClassName('dsbl');
      this.select.disable();
    },

    setSelectOptions: function(selectOptions) {
      this.select.setSelectOptions(selectOptions);
    },

    setOptionEnabled: function(value, enabled) {
      var opts = $A(this.select.options);
      var match = opts.find(function(opt) {
        return opt.value === value;
      });

      match.disabled = !enabled;
    },

    setSelectedValue: function(value) {
      this.currentValue = value;
      this.select.setValue(value);
    },

    getValue: function() {
      return this.select.getValue();
    },

    onSelect: function() {
      if (this.isEnabled() && this.select.getValue() !== this.currentValue) {

        this.fireEvent('select', this.select.getValue());
        this.currentValue = this.select.getValue();
      }
    }
  });

  // --------------------------------------------------------------------------
  jui.FormSelectWithHint = Class.create(jui.FormSelect, {
    installUI: function($super) {
      $super();
      this.hint = this._hint();
      if (this.options.hintText === '' || this.options.hintText === null) {
        this.hintText.hide();
      }
    },
    onSelect: function() {
      if (this.isEnabled() && this.select.getValue() !== this.currentValue) {

        this.fireEvent('select', this.select.getValue());
        this.currentValue = this.select.getValue();
      }

    },
    _hint: function() {
      var element = new Element('div', {
        id: 'jui-input-text-desc',
        className: 'input-hint clearfix'
      });
      element.update(this.options.hintText);
      return this.insert(element);
    }
  });

  //------------------------------------------------------------------------------
  jui.FormAlignSelect = Class.create(jui.Component, jui.ControlModel, {
    options: function($super, options) {
      return $super($H({
        attributes: {
          className: 'form-elm align-select'
        },
        selectOptions: $A([])
      }).merge(options));

    },

    initialize: function($super, options) {
      $super(options);
      if (this.options.action) {
        this.addListener('select', this.options.action);
      }
    },

    installUI: function($super) {
      $super();
      this.label = this.insert(new Element('label').update(this.options.label));
      this.select = this.insert(new jui.AlignSelect({
        action: this.onSelect.bind(this)
      }));
    },

    setValue: function(value) {
      this.select.setValue(value);
    },

    getValue: function() {
      return this.select.getValue();
    },

    onSelect: function(e) {
      this.fireEvent('select', e.data);
    }
  });


  jui.FormTextAreaInput = Class.create(jui.Component, jui.ControlModel, {
    options: function($super, options) {
      return $super($H({
        attributes: {
          className: 'form-elm text-area'
        },
        label:'',
        inputFilters: []
      }).merge(options));

    },

    initialize: function($super, options) {
      $super( options );
      if (this.options.onkeydown) {
          this.addListener('keydown', this.options.onkeydown);
      }
      if (this.options.onkeyup) {
          this.addListener('keyup', this.options.onkeyup);
      }
      if (this.options.onfocus) {
          this.addListener('focus', this.options.onfocus);
      }
      if (this.options.onblur) {
          this.addListener('blur', this.options.onblur);
      }
      if (this.options.onmouseup) {
          this.addListener('mouseup', this.options.onmouseup);
      }
    },

    installUI: function($super) {
      $super();
      this.label = this.insert(new Element('label', {className: 'textarea-label'})
        .update(this.options.label));
      if (this.options.label === '' || this.options.label === null) {
        this.label.hide();
      }

      this.input = this.insert(new Element('textarea', {
        className: 'textarea'
      }));

      if (this.options.inputPosition) {
        this.input.addClassName(this.options.inputPosition);
      }

      this.input.observe('focus', this.onFocus.bind(this));
      this.input.observe('blur', this.onBlur.bind(this));
      if (this.options.onkeyup) {
          this.input.observe('keyup', this.onkeyup.bind(this));
      }
      if (this.options.onkeydown) {
          this.input.observe('keydown', this.onkeydown.bind(this));
      }
      if (this.options.onmouseup) {
          this.input.observe('mouseup', this.onmouseup.bind(this));
      }
    },

    setValue: function(value) {
      this.input.value = value;
    },

    getValue: function() {
      return this.input.value;
    },

    onFocus: function(e) {
      if (this.isEnabled()) {
        e.stop();
        this.fireEvent('focus', this);
      }
    },

    onkeydown: function(e) {
      this.fireEvent('keydown', e.keyCode);
    },

    onkeyup: function(e) {
      if (!(e.keyCode === Event.KEY_RETURN || e.keyCode === Event.KEY_TAB)) {
        this.fireEvent('keyup', e.keyCode);
      }
    },

    onBlur: function(e) {
      if (this.isEnabled()) {
        e.stop();
        this.fireEvent('blur', this);
      }
    },

    onmouseup: function(e) {
      if (this.isEnabled()) {
        e.stop();
        this.fireEvent('mouseup', this);
      }
    },

    blur: function() {
      this.input.blur();
    }
  });

  jui.NumericInputFilter = {
    filter: function(e, controller) {
      /* jshint unused:vars */
      if (e.metaKey) {
        return;
      }

      if (! ((e.keyCode >= 48 && e.keyCode <= 57) || //keyboard numbers
      (e.keyCode >= 96 && e.keyCode <= 105) || //keypad numbers
      (e.keyCode === 8) || // backspace
      (e.keyCode === 9) || // tab
      (e.keyCode === 12) || // clear
      (e.keyCode === 13) || // return
      (e.keyCode === 27) || // escape
      (e.keyCode === 37) || // left
      (e.keyCode === 39) || // right
      (e.keyCode === 46))) // delete
      {
        e.stop();
      }
    }
  };

  jui.HexidecimalInputFilter = {
    filter: function(e, controller) {
      /* jshint unused:vars */
      if (e.metaKey) {
        return;
      }
      if (! ((e.keyCode >= 48 && e.keyCode <= 57) || //keyboard numbers
      (e.keyCode >= 96 && e.keyCode <= 105) || //keypad numbers
      (e.keyCode >= 65 && e.keyCode <= 70) || //a,b,c,d,e,f
      (e.keyCode === 8) || // backspace
      (e.keyCode === 9) || // tab
      (e.keyCode === 12) || // clear
      (e.keyCode === 13) || // return
      (e.keyCode === 16) || // shift
      (e.keyCode === 27) || // escape
      (e.keyCode === 37) || // left
      (e.keyCode === 39) || // right
      (e.keyCode === 46))) // delete
      {
        e.stop();
      }
    }
  };

  //------------------------------------------------------------------------------
  jui.FormTextArea = Class.create(jui.Component, jui.ControlModel, {
    options: function($super, options) {
      return $super($H({
        attributes: {
          className: 'form-elm text-area'
        }
      }).merge(options));

    },

    initialize: function($super, options) {
      $super( options );
      if (this.options.onkeydown) {
          this.addListener('keydown', this.options.onkeydown);
      }
      if (this.options.onkeyup) {
          this.addListener('keyup', this.options.onkeyup);
      }
      if (this.options.onfocus) {
          this.addListener('focus', this.options.onfocus);
      }
      if (this.options.onblur) {
          this.addListener('blur', this.options.onblur);
      }
    },

    installUI: function($super) {
      $super();
      this.label = this.insert(new Element('label').update(this.options.label));
      this.input = this.insert(new Element('textarea'));

      if (this.options.inputPosition) {
        this.input.addClassName(this.options.inputPosition);
      }

      if (this.options.text) {
        this.input.value = this.options.text;
      }

      this.input.observe('focus', this.onFocus.bind(this));
      this.input.observe('blur', this.onBlur.bind(this));
      if (this.options.onkeyup) {
          this.input.observe('keyup', this.onkeyup.bind(this));
      }
      if (this.options.onkeydown) {
          this.input.observe('keydown', this.onkeydown.bind(this));
      }
    },

    setValue: function(value) {
      // TODO: JS: validation
      this.input.value = value;
    },

    getValue: function() {
      return this.input.value;
    },

    onFocus: function(e) {
      if (this.isEnabled()) {
        e.stop();
        this.fireEvent('focus', this);
      }
    },

    onkeydown: function(e) {
      switch (e.keyCode) {
      case Event.KEY_RETURN:
        this.blur();
        e.stop();
        break;
      }
      this.fireEvent('keydown', this);
    },

    onkeyup: function() {
      this.fireEvent('keyup', this);
    },

    onBlur: function(e) {
      if (this.isEnabled()) {
        e.stop();
        this.fireEvent('blur', this);
      }
    },

    blur: function() {
      this.input.blur();
    }
  });

  //------------------------------------------------------------------------------
  jui.CollapsiblePanel = Class.create(jui.Component, {
    options: function($super, options) {
      return $super($H({
        expand: true,
        title: '',
        titleTag: 'h3',
        attributes: {
          className: 'collapsible-panel'
        }
      }).merge(options));
    },

    initialize: function($super, options) {
      $super(options);
      this.position = this.options.position;
    },

    installUI: function($super) {
      $super();
      this.titleBar = this._insert(new jui.Component({
        attributes: {
          className: 'title-bar clearfix'
        }
      }).update(new Element(this.options.titleTag).update(this.options.title)));
      this.content = this._insert(new jui.Component({
        attributes: {
          className: 'panel-content clearfix'
        }
      }));

      this.titleBar.observe('click', this.toggle.bindAsEventListener(this));
      if (this.options.expand === false) {
        this.collapse();
      }
    },

    insertContent: function(c) {
      this.content.insert(c);
      return c;
    },

    insert: function(c) {
      return this.insertContent(c);
    },

    removeContent: function(c) {
      c.remove();
      return c;
    },

    remove: function(c) {
      return this.removeContent(c);
    },

    clearContent: function() {
      this.content.clear();
    },

    setTitle: function(t) {
      this.title = t;
      this.titleBar.getElementsBySelector(this.options.titleTag)[0].update(t);
    },

    isExpanded: function() {
      return !this.isCollapsed();
    },

    isCollapsed: function() {
      return this.e.hasClassName('closed');
    },

    toggle: function() {
      this[this.isCollapsed() ? 'expand' : 'collapse']();
    },

    expand: function() {
      if (this.isExpanded()) {
        return;
      }
      this.e.removeClassName('closed');
      this.fireEvent('expanded');
    },

    collapse: function() {
      if (this.isCollapsed()) {
        return;
      }
      this.e.addClassName('closed');
      this.fireEvent('collapsed');
    }
  });

  //------------------------------------------------------------------------------
  jui.DimmablePanel = Class.create(jui.Component, {
    options: function($super, options) {
      return $super($H({
        title: '',
        dim: false,
        titleTag: 'h3',
        attributes: {
          className: 'dimmable-panel'
        }
      }).merge(options));
    },

    initialize: function($super, options) {
      $super(options);
      this.position = this.options.position;
      if(this.options.dimmed) {
        this.addListener('dimmed', this.options.dimmed);
      }
      if(this.options.distinctive) {
        this.addListener('distinctive', this.options.distinctive);
      }
    },

    installUI: function($super) {
      $super();

      this.content = this._insert(new jui.Component({
        attributes: {
          className: 'panel-content clearfix'
        }
      }));

      this.titleBar = this.content._insert(new jui.Component({
        attributes: {
          className: 'panel-section '
        }
      }));

      if(this.options.dim === true) {
        this.dim();
      }
    },

    insertContent: function(c) {
      this.content.insert(c);
      return c;
    },

    insert: function(c) {
      return this.insertContent(c);
    },

    removeContent: function(c) {
      c.remove();
      return c;
    },

    remove: function(c) {
      return this.removeContent(c);
    },

    clearContent: function() {
      this.content.clear();
    },

    setTitle: function(t) {
      this.title = t;
      this.titleBar.getElementsBySelector(this.options.titleTag)[0].update(t);
    },

    isDimmed: function() {
      return this.e.hasClassName('dim');
    },

    isDistinct: function() {
      return !this.isDimmed();
    },

    toggle: function() {
      this[this.isDimmed() ? 'dim' : 'distinct']();
    },

    dim: function() {
      if(this.isDimmed()) {return;}
      this.e.addClassName('dim');
      this.fireEvent('dimmed', this);
    },

    distinct: function() {
      if(this.isDistinct()) {return;}
      this.e.removeClassName('dim');
      this.fireEvent('distinctive', this);
    }
  });
})();
