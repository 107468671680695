jui.social_widget_twitter = {

  id: 'twitter-wjs',
  name: 'twitter',

  getWidgetHtml: function( twitterObj ) {
    this.validate( twitterObj );
    return this.twitterTemplate().evaluate( this.getCountLayout( twitterObj ) );
  },

  getScript: function() {
    return '<script type="text/javascript">!function(d,s,id) {var js,fjs=d.getElementsByTagName(s)[0];if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src="//platform.twitter.com/widgets.js";fjs.parentNode.insertBefore(js,fjs);}}(document,"script","twitter-wjs");</script>';
  },

  getStyle: function() {
    return '';
  },

  getSizeByLayout: function(layout) {
    var size = {
      top: {
        width: 64,
        height: 66
      },
      right: {
        width: 107,
        height: 24
      },
      none: {
        width: 56,
        height: 24
      }
    };
    return size[ layout.countOrientation ];
  },

  validate: function( twitterObj  ) {
    this.validateKeys( twitterObj );
  },

  cleanup: function() {
    var jsNode = $( this.id );
    if( jsNode ) {
      jsNode.remove();
    }

  },

  validateKeys: function( twitterObj ) {
    var keys         = $H( twitterObj ).keys(),
        requiredKeys = [ 'orientation', 'countOrientation', 'name', 'tweet'  ];

    requiredKeys.each( function( key ) {
      if( !keys.include( key ) ) {
        throw key + ': Key is missing form social_widget_twitter.js';
      }
    } );
  },

  twitterTemplate: function() {
    return new Template( '<a href="https://twitter.com/share" class="twitter-share-button" data-url="#{url}" data-text="#{tweet}" data-count="#{count-layout}" >Tweet</a>' );
  },

  getCountLayout: function( twitterObj )  {
    twitterObj = $H( twitterObj );
    if( 'right' === twitterObj.get( 'countOrientation' ) ) {
      twitterObj.set( 'count-layout', 'horizontal' );
    } else if( 'top' === twitterObj.get( 'countOrientation' ) ) {
      twitterObj.set( 'count-layout', 'vertical' );
    } else {
      twitterObj.set( 'count-layout', 'none' );
    }
    return twitterObj;
  }

};

