lp.pom.updates.add( Object.extend( {
  minVersion: '2.4',
  version: '2.5',
  run: function(jso) {
    if( this.checkVersion( jso ) ) {
      var self = this;
      jso.elements.each( function( el ) {
        if( el.type === 'lp-pom-text' ) {
          el = self.addStyle(el);
        }
      } );
      jso.version = this.version;
    }
  },

  addStyle: function(e) {
    if(!e.style) {e.style = {};}
    if(!e.style.background) {
      e.style.background = {
        backgroundColor: 'fff',
        opacity: 0
      };
    }
    return e ;
  }


}, lp.pom.updater ) );
