lp.pom.updates.add(Object.extend({
  minVersion:'1.3',
  version:'1.4',
  run: function(jso) {
    if (this.checkVersion(jso)) {
      this.makeRootElementRulesInLine(jso);
      this.removeUnnecessaryRules(jso);
      this.updateRemainingRules(jso);
      jso.version = this.version;
    }
  },

  makeRootElementRulesInLine: function(jso) {
    if (typeof jso.styles === 'undefined') {
      return;
    }

    var styles = jso.styles.clone();
    var root = jso.elements.find(function(e) {
      return e.id === 'lp-pom-root';
    });

    root.style.background = root.style.background || {};
    root.style.defaults = root.style.defaults || {};


    styles.each(function(rule) {
      if (rule.selector === '#lp-pom-root') {
        switch(rule.attribute) {
          case 'background-color':
          root.style.background.backgroundColor = rule.value.sub('#', '').strip();
          this.removeRule(jso, rule);
          break;
          case 'background-image':
          root.style.background.backgroundImage = rule.value.strip().sub(/url\(['"]?(.*?)['"]?\)/, '#{1}');
          this.removeRule(jso, rule);
          break;
          case 'background-repeat':
          root.style.background.backgroundRepeat = rule.value.strip();
          this.removeRule(jso, rule);
          break;
          case 'background-position':
          root.style.background.backgroundPosition = rule.value.strip();
          this.removeRule(jso, rule);
          break;
          case 'color':
          root.style.defaults.color = rule.value.sub('#', '').strip();
          // this.removeRule(jso, rule);
          break;
        }
      }
      else if (rule.selector === '#lp-pom-root a') {
        switch(rule.attribute) {
          case 'color':
          root.style.defaults.linkColor = rule.value.sub('#', '').strip();
          // this.removeRule(jso, rule);
          break;
          case 'text-decoration':
          root.style.defaults.linkDecoration = rule.value.strip();
          // this.removeRule(jso, rule);
          break;
        }
      }

      root.style.defaults.linkDecoration = root.style.defaults.linkDecoration || 'none';

    }, this);
  },

  removeUnnecessaryRules: function(jso) {
    this.removeRule(jso, jso.styles.find(function(rule) {return rule.selector === '#lp-pom-root' && rule.attribute === 'font-size';}));
    this.removeRule(jso, jso.styles.find(function(rule) {return rule.selector === '#lp-pom-root' && rule.attribute === 'line-height';}));
  },

  updateRemainingRules: function(jso) {
    jso.styles = jso.styles.collect(function(s) {
      if (s.selector.startsWith('#lp-pom-root #lp-pom-button')) {
        s.selector = s.selector.sub('#lp-pom-root #', 'a#');
      }
      return s;
    });
  },

  removeRule: function(jso,rule) {
    jso.styles = jso.styles.without(rule);
  }
}, lp.pom.updater));
