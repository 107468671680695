lp.module.multiSelect.MultiSelectElementPropertiesPanel = Class.create(
  lp.editor.panel.ElementPropertiesPanel,
  lp.editor.panel.ElementGeometryControls,
  lp.ModuleComponent,
  {
    type:'lp-multi-select',

    installUI: function($super){
      $super();

      var geometryPanel = this.insert(new jui.CollapsiblePanel({title:'Geometry'}));
      this.installGeometryControls({insert:geometryPanel.insertContent.bind(geometryPanel)});
    },

    syncUIControls: function($super, elm) {
      $super(elm);
      this.bindGeometryControlsToElement(elm);
    }
  }
);