lp.module.code = {};

lp.module.code.CustomeHTMLModule = Class.create(
  lp.ModuleBase,
  lp.ModuleElementMethods,
  lp.ModuleElementBuilderMethods,
  lp.ModuleElementPropertiesPanelMethods,
  {
    beforeCreate: function (page, defaults, options, data) {
      defaults.content.html = data.html;
      defaults.content.valid = data.valid;
      defaults.content.errors = data.errors;
    }
  }
);

lp.addModule(new lp.module.code.CustomeHTMLModule(
  {
    type:'lp-code',
    version:'1.0',
    name: 'Custom HTML',
    usageType:'page-widget',
    namespace: lp.module.code
  }
));