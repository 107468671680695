lp.editor.TransformBoxBoundary = Class.create( jui.Component, {
  options: function($super,options){
    return $super($H({attributes:{className:'transform-boundary'}}).merge(options));
  },

  initialize: function( $super, box, onDrag, onInitDrag, onBeforeDrag, options ){
    $super( options );
    this.box = box;
    this.onDrag = onDrag;
    this.onInitDrag = onInitDrag;
    this.onBeforeDrag = onBeforeDrag;
    this.tracking = false;
    this.mouseMoveListener = this.mousemove.bindAsEventListener( this );
    this.mouseUpListener = this.mouseup.bindAsEventListener( this );
  },

  installUI: function( $super, options ) {
    $super(options);
    if (this.options.live) {
      this.e.observe('mousedown', this.mousedown.bindAsEventListener(this));
    }
  },

  mousedown: function(e) {
    Event.stop( e );
    this.startLeft = this.box.getStartLeft();
    this.startTop = this.box.getStartTop();
    this.startWidth = this.box.getStartWidth();
    this.startHeight = this.box.getStartHeight();
    this.offset = new jui.Point(Event.pointerX(e), Event.pointerY(e));

    this.onInitDrag(this.box.getModel());

    this.setTracking( true );
  },

  mousemove: function(e) {
    Event.stop( e );
    this.onBeforeDrag();

    var p = new jui.Point(Event.pointerX(e), Event.pointerY(e)).subtract(this.offset);

    var p2 = p.clone().add(this.initPoint);
    var p3 = p2.clone();

    if (this.options.snap){
      editor.snapManager.snapPoint(p3);
    }

    this.onDrag(p.add(p3.subtract(p2)), this.box.getModel());
  },

  mouseup:function( e ){
    Event.stop( e );
    this.setTracking( false );
  },

  setTracking: function( b ) {
    if ( this.box.tracking === b ) {
      return;
    }

    this.box.tracking = b;

    if ( b ) {
      $( window.document ).observe( 'mouseup', this.mouseUpListener );
      $( window.document ).observe( 'mousemove', this.mouseMoveListener );
      this.fireEvent('startMove', this.elm);
    } else {
      this.box.undoRegistered = false;
      $( window.document ).stopObserving( 'mouseup', this.mouseUpListener );
      $( window.document ).stopObserving( 'mousemove', this.mouseMoveListener );
      this.fireEvent('stopMove', this.elm);
    }
  }
});
