//  require <jui>
jui.FormOffsetInput = Class.create(jui.Component, jui.ControlModel, {
  options: function($super,options){
    return $super($H({
      attributes:{className:'form-elm offset-input'},
      label:'Position',
      labelL:'L:',
      labelT:'T:'
    }).merge(options));

  },

  initialize:function( $super, options ){
    this.focusField = null;
    $super( options );

    if (this.options.onfocus) {
      this.addListener('focus', this.options.onfocus);
    }

    if (this.options.onblur) {
      this.addListener('blur', this.options.onblur);
    }
  },

  installUI: function($super) {
    $super();
    var self = this;

    this.insertOffsetFields();

    [this.left, this.top].each(function(field){
      field.observe('focus', self.onFocus.bind(self));
      field.observe('blur', self.onBlur.bind(self));
    });
  },

  insertOffsetFields: function() {
    this.insert( new Element('label').update(this.options.label));
    this.insert( new Element('label', {className:'mini'}).update(this.options.labelL));
    this.left = this.insert(new Element('input', {type:'text', className:'text left'}));
    this.insert( new Element('div', {className:'spacer'}));
    this.insert( new Element('label', {className:'mini'}).update(this.options.labelT));
    this.top = this.insert(new Element('input', {type:'text', className:'text top'}));
  },

  setValue: function(value) {
    // TODO: JS: validation
    this.left.value = value.left;
    this.top.value = value.top;
  },

  getValue: function() {
    return {left:this.left.value * 1, top:this.top.value * 1};
  },

  onFocus: function(e) {
    if( this.isEnabled() ) {
      e.stop();
      this.fireEvent('focus', this);
      this.focusField = e.findElement('input');
    }
  },

  onkeydown: function(e, controller) {
    jui.NumericInputFilter.filter(e, controller);
    if(Event.KEY_RETURN === e.keyCode) {
      this.blur();
      e.stop();
    }
  },

  onBlur: function(e) {
    if(this.isEnabled()) {
      e.stop();
      this.fireEvent('blur', this);
      this.focusField = null;
    }
  },

  //It appears that firefox will call this when the blur action triggers.  What makes this
  //confusing is why the onBlur function never gets called.
  blur: function() {
    if (this.focusField !== null) {
      this.fireEvent('blur', this);
    }
  }
});
