(function() {
  var dnd = lp.editor.dnd = {};
  var targets = [];
  var hilite = new Element('div', {className:'elm-dnd-target-hilite'});
  hilite.setOpacity(0.4);
  var scrollAreas = [];
  var pageRect = null;
  var pbHeight = 0;

  var dummySection = new jui.Component({attributes:{id:'dummy-section'}});

  var emptyFunction = function() {};
  Object.extend(dummySection, {
    type:'lp-pom-block',
    id:'dummy-section',
    constraints: {
      displayable:false,
      offsetable:false
    },
    is: function(constraint) {
      return this.constraints[constraint] || false;
    },
    getView: function() {return this;},
    hasGeometry: function(){return true;},
    applyGeometry: emptyFunction,
    model: {
      addListener: emptyFunction,
      removeListener: emptyFunction,
      getZIndex: emptyFunction,
      getHeight: function(){ return 0;},
      exists: function() {return true;},//exists: function(){return false;}
      get: function() {return 0;}
    },
    hasBorder: function(){return false;},
    getContentHeight: function(){return 25;},
    getRootOffset: function() {
      var o = this.e.cumulativeOffset();
      var ro = editor.page.getRootElement().getOffset();
      var po = editor.page.getRootElement().cumulativeOffset();
      return {left:o.left-po.left+ro.left,
              top:o.top-po.top // +ro.top
              // the ro.top above was always 0 on desktop and
              // incorrectly proportional to horizontal margin between
              // the root element and the canvas boundary on mobile.
              // See LP-6306. TODO-TR: figure out why getOffset().top
              // is incorrect in that context.
             };
      // return this.e.cumulativeOffset();
    },
    getPageOffset: function() {
      return this.getRootOffset();
    },
    getBottomMargin: function() {return 0;},
    getBottomBorderWidth: function() {return 0;},
    afterInsert: emptyFunction,
    canHaveGoals: function(){return false;},
    isDisplayable: function(){return false;},
    isAbsolute: function(){return false;},
    getDescendants: function(){return [];},
    _attach: emptyFunction,
    _detach: emptyFunction,
    setParentElement: function(elm) {
      this.parentElement = elm;
    },
    removeFromPage: function() {
      var fromParent = editor.page.getRootElement();
      if (this.parentElement !== null) {
        this.parentElement.removeChildElement(this);
      }
      editor.page.removeElement(this, fromParent);
    }
  });

  dummySection.setOpacity(0.4);
  dummySection.view = dummySection;

  function createElementTargets(source) {
    targets = [];

    var e = window.editor.canvasBody.e;
    var canvasOffset = e.cumulativeOffset();

    canvasOffset.left = canvasOffset.left - e.scrollLeft;
    canvasOffset.top = canvasOffset.top - e.scrollTop;

    var page = window.editor.page;
    var base = {
      /*jshint unused:false */

      topMost: function(target) {
        return page.topMost(target.elm, this.elm) === this.elm ? this : target;
      },

      isWithin: function(point, source) {
        return jui.Rectangle.isWithin(this.rect, point) && jui.Rectangle.isWithin(pageRect, point);
      },

      dragEnter: function(point, source) {
        if (source.accepts(this)) {
          showElementTargetHilite(this);
        }
      },

      dragLeave: function(point, source) {
        hideElementTargetHilite(this);
      },

      dragOver: function(point, source) {
        //
      },

      drop: function(point, source) {
        hideElementTargetHilite(this);
        if (source.accepts(this)) {
          var l = point.left - this.rect.left;
          var t = point.top - this.rect.top;
          source.payload.action(this.elm, {left:l, top:t});
        }
      }
    };

    window.editor.page.elements.each(function(elm) {
      if (elm.is('container') &&
          elm !== source.elm &&
          (source.elm.containsElement ?
          !source.elm.containsElement(elm) : !elm.hasAncestor(source.elm))) {

        var po = elm.getPageOffset();
        var l = po.left;
        var t = po.top;
        var target = Object.extend({
          elm: elm,
          rect: {
            width: elm.getContentWidth(),
            height: elm.getContentHeight(),
            left: l + canvasOffset.left,
            top: t + canvasOffset.top,
            pageLeft: l,
            pageTop: t
          }
        }, base);

        targets.push(target);
      }
    }, this);
  }

  function createSectionTargets(source) {
    targets = [];

    var e = window.editor.canvasBody.e;
    var canvasOffset = e.cumulativeOffset();
    var w = e.getWidth();

    canvasOffset.left = canvasOffset.left - e.scrollLeft;
    canvasOffset.top = canvasOffset.top - e.scrollTop;

    var sections = editor.page.getElementsByType('lp-pom-block');
    var t = 0;
    var base = {
        /*jshint unused:false */

      topMost: function(target) {
        return this.index > target.index ? this : target;
      },

      isWithin: function(point, source) {
        return jui.Rectangle.isWithin(this.rect, point) && jui.Rectangle.isWithin(pageRect, point);
      },

      dragEnter: function(point, source) {
        showSectionTargetHilite(this);
      },

      dragLeave: function(point, source) {
        hideSectionTargetHilite(this);
      },

      dragOver: function(point, source) {},

      drop: function(point, source) {
        hideSectionTargetHilite(this);
        source.payload.action(this.index);
      }
    };

    var index = 0;

    sections.each(function(elm, i) {
      if (!elm.visible()) {
        return;
      }

      var po = elm.getPageOffset();
      var l = po.left;
      t = po.top;
      var h = 0;

      if (i > 0) {
        var aboveElm = sections[i-1];
        if (!aboveElm.visible() && i > 1) {
          aboveElm = sections[i-2];
        }

        h = Math.round(aboveElm.getContentHeight() / 2);
        t = t - h + 20;
      }

      targets.push(Object.extend({
        rect: {
          width:w,
          // height:Math.min(80, elm.getContentHeight()),
          height: h + Math.round(elm.getContentHeight() / 2) + 20,
          left: canvasOffset.left,
          top: t + canvasOffset.top,
          pageLeft: 0,
          pageTop: t
        },
        index:i
      }, base));
    }, this);

    var len = sections.length;

    if (sections.length > 0) {
      t = (sections[len - 1].visible() ?
            Math.round(sections[len - 1].getContentHeight() / 2) + sections[len - 1].getRootOffset().top :
            Math.round(sections[len - 2].getContentHeight() / 2) + sections[len - 2].getRootOffset().top) + 20;
    }

    t += editor.page.getRootElement().getTopPadding();

    targets.push(Object.extend({
      rect: {
        width:w,
        height: sections.length > 0 ?
            (sections[sections.length-1].getContentHeight() / 2) + 60 :
            Math.max(e.getHeight() - t, 100),

        left: canvasOffset.left + 1,
        top: t + canvasOffset.top,
        pageLeft: 0,
        pageTop: t
      },
      index:sections.length
    }, base));
  }

  function createScrollAreas() {
    scrollAreas = [];
    var r = pageRect;

    var canvas = window.editor.canvasBody.e;
    var height = 40;

    var base = Object.extend({
      active: false,
      tid: null,
      lastPoint: null,
      isScrollArea:true,
      topMost: function(target) {
        return this.canScroll() ? this : target;
      },

      isWithin: function(point, source) {
        /*jshint unused:vars */
        var test = jui.Rectangle.isWithin(this.rect, point) && jui.Rectangle.isWithin(pageRect, point) && this.canScroll();
        return test;
      },

      canScroll: function() {
        return this.vector.top < 0 ? canvas.scrollTop > 0 : canvas.scrollTop < (canvas.scrollHeight - canvas.clientHeight + 25);
      },

      scroll: function(point, source) {
        if (this.active && this.canScroll()) {
          var scrollBy = (Math.abs(this.lastPoint.top - this.depthStart.top) * this.vector.top);
          var before = canvas.scrollTop;
          var after = Math.min(Math.max(0,canvas.scrollTop + scrollBy), (canvas.scrollHeight) - canvas.clientHeight);

          if (source.scroll) {
            source.scroll(before, after);
          }

          canvas.scrollTop = after;

          adjustTargetRectsToSrollOffsets();
          window.setTimeout(this.scroll.bind(this, point, source), 20);
        }
      },

      dragEnter: function(point, source) {
        this.active = true;
        this.lastPoint = point;
        window.setTimeout(this.scroll.bind(this, point, source), 20);
      },

      dragLeave: function(point, source) {
        /*jshint unused:vars */
        this.active = false;
      },

      dragOver: function(point, source) {
        /*jshint unused:vars */
        this.lastPoint = point;
      },

      drop: function() {
        this.active = false;
      }
    });

    scrollAreas.push(Object.extend({
      rect: {
        left: r.left,
        top: r.top + r.height - height,
        width: r.width,
        height: height
      },
      depthStart: {left:0, top: r.top + r.height - height},
      vector: {left:0, top:1}
    }, base));

    scrollAreas.push(Object.extend({
      rect: {
        left: r.left,
        top: r.top,
        width: r.width,
        height: height
      },
      depthStart: {left:0, top: r.top + height},
      vector: {left:0, top:-1}
    }, base));
  }

  function adjustTargetRectsToSrollOffsets() {
    var i, l= null;
    var e = window.editor.canvasBody.e;
    var canvasOffset = e.cumulativeOffset();

    canvasOffset.left = canvasOffset.left - e.scrollLeft;
    canvasOffset.top = canvasOffset.top - e.scrollTop;

    for (i=0,l=targets.length; i<l; i++) {
      var r = targets[i].rect;
      r.left = r.pageLeft + canvasOffset.left;
      r.top = r.pageTop + canvasOffset.top;
    }
  }

  function showElementTargetHilite(target) {
    if (hilite.parentNode === null) {
      window.editor.canvasBody.insert(hilite);
    }

    var rect = jui.Rectangle.intersect(target.rect, pageRect);

    var e = window.editor.canvasBody.e;
    var canvasOffset = e.cumulativeOffset();

    canvasOffset.left = canvasOffset.left - e.scrollLeft;
    canvasOffset.top = canvasOffset.top - e.scrollTop;


    hilite.style.width = rect.width + 'px';
    hilite.style.height = rect.height + 'px';
    hilite.style.left = (rect.left - canvasOffset.left) + 'px';
    hilite.style.top = (rect.top - canvasOffset.top) + 'px';
  }

  function hideElementTargetHilite(target) {
    /*jshint unused:vars */
    if (hilite.parentNode !== null) {
      hilite.parentNode.removeChild(hilite);
    }
  }

  function showSectionTargetHilite(target) {
    var p = window.editor.page;
    p.undoManager.disable();
    if (typeof target.index !== 'undefined') {
      p.insertElement(dummySection, {container:p.getRootElement(), containerIndex:target.index});
    } else {
      p.insertElement(dummySection, {container:p.getRootElement()});
    }
    p.undoManager.enable();
  }

  function hideSectionTargetHilite(target) {
    var p = window.editor.page;
    if (target !== null) {
      p.undoManager.disable();
      dummySection.removeFromPage();
      p.undoManager.enable();
    }
  }

  function dragEnd(point, source) {
    /*jshint unused:vars */
    window.editor.pageBody.e.style.height = pbHeight;
  }

  function getTargets(source) {
    /*jshint unused:vars */
    return scrollAreas.concat(targets);
  }


  dnd.elementDnDGroup = {
    dragStart: function(point, source) {
      pbHeight = window.editor.pageBody.e.style.height;
      pageRect = window.editor.pageBody.getPageRect();
      createElementTargets(source);
      createScrollAreas();
    },
    dragEnd: dragEnd,
    getTargets: getTargets
  };

  dnd.sectionDnDGroup = {
    dragStart: function(point, source) {
      pbHeight = window.editor.pageBody.e.style.height;
      pageRect = window.editor.pageBody.getPageRect();
      createScrollAreas();
      createSectionTargets(source);

    },
    dragEnd: dragEnd,
    getTargets: getTargets
  };

  dnd.transformElementDnDSource = {

     payload: {
       action: function(target, point) {
           /*jshint unused:vars */
       }
     },

     getGroup: function() {
       return lp.editor.dnd.elementDnDGroup;
     },

     accepts: function(target) {
       return target.elm !== this.startParent && !this.optionMode && this.elm.is('removable');
     },

     dragStart: function() {
       this.startZIndex = this.elm.model.getZIndex();
       this.createGhost();
       this.fireEvent('dragStart', {elm: this.elm, startParent: this.startParent});
     },

     dragStop: function() {
       if (this.ghostMode) {
         this.ghostMode = false;
         this.ghost.remove();
         this.elm.show();
       }
       this.fireEvent('dragStop');
     },

     dragEnter: function(point, target) {
       if (target.elm && target.elm !== this.elm.parentElement && !this.optionMode && this.elm.is('removable')) {
         this.changeParent(target.elm);

         if (target.elm !== this.startParent) {
           if (!this.ghostMode) {
             this.enterGhostMode();
           }
         } else if (this.ghostMode) {
           this.exitGhostMode();
         }
       }
     },

     enterGhostMode: function() {
       this.ghostMode = true;
       window.editor.canvasBody.insert(this.ghost);
       this.elm.hide();
       this.positionGhost();
     },

     exitGhostMode: function() {
       this.ghostMode = false;
       this.ghost.remove();
       this.elm.show();
     },

     enterOptionMode: function() {
       this.optionMode = true;
       if (this.tracking && this.ghostMode) {
         jui.dnd.manager.leaveCurrentTarget(this, this.lastPoint);
         this.changeParent(this.startParent);
         this.exitGhostMode();
       }
     },

     exitOptionMode: function() {
       this.optionMode = false;
       if (this.tracking) {
         jui.dnd.manager.retestTargets(this, this.lastPoint);
       }
     },

     changeParent: function(newParent) {
       var zIndex;

       if (newParent === this.startParent) {
         zIndex = this.startZIndex;
       }

       jui.Point.subtract(this.startPoint, this.elm.parentElement.getPageOffset());
       jui.Point.add(this.startPoint, newParent.getPageOffset());

       this.elm.changeParent(newParent, zIndex);
       this.fireEvent('parentChanged', this.elm);
     },

     createGhost: function() {
       this.ghost = this.elm.getGhost ? this.elm.getGhost() : this.elm.view.e.cloneNode(true);
       this.ghost.addClassName('dnd-ghost');
       this.ghost.style.zIndex = 10002;
     },

     scroll: function(before, after) {
       jui.Point.subtract(this.startPoint, {left:0, top:after - before});
       var m = this.elm.model;
       var p = {left: this.lastPoint.left, top: this.lastPoint.top};

       jui.Point.subtract(p, this.startPoint);
       jui.Point.add(p, this.startOffset);
       m.setOffset(p);
       this.fireEvent('move', this.elm);
     },

     positionGhost: function() {
       if (this.ghostMode) {
         var offset = this.elm.getPageOffset();
         jui.Point.subtract(offset, this.elm.getOuterBorderOffsetAdjust());
         this.ghost.style.left = offset.left + 'px';
         this.ghost.style.top = offset.top + 'px';
       }
     }
  };

  dnd.transformSectionDnDSource = {

     payload: {
       action: function(target, point) {
         /*jshint unused:vars */
       }
     },

     getGroup: function() {
       return lp.editor.dnd.sectionDnDGroup;
     },

     accepts: function(target) {
       return target.elm !== this.startParent && !this.optionMode && this.elm.is('removable');
     },

     dragStart: function(point) {
       this.createGhost(this.elm);
       this.elm.hide();
       createSectionTargets();
       this.elmHeight = this.elm.model.getHeight();
       this.startZIndex = this.elm.model.getZIndex();

       this.positionGhost(point);
       this.fireEvent('dragStart', {elm: this.elm, startParent: this.startParent});
     },

     dragStop: function(obj) {
       /*jshint unused:vars */
       this.ghost.remove();
       this.ghost = null;
       this.elm.show();
       if (this.activeTarget) {
         var currentIndex = this.elm.getRootElement().childElements.indexOf(this.elm);
         var newIndex = this.activeTarget.index;


         if (newIndex < currentIndex) {
           this.editor.page.undoManager.startGroup();
           (Math.abs(newIndex - currentIndex)).times(function() {
             this.elm.getRootElement().changeOrder(this.elm, -1);
           }, this);
           this.editor.page.undoManager.endGroup();
         } else if (newIndex > currentIndex + 1) {
           this.editor.page.undoManager.startGroup();
           (Math.abs((newIndex - 1) - currentIndex)).times(function() {
             this.elm.getRootElement().changeOrder(this.elm, 1);
           }, this);
           this.editor.page.undoManager.endGroup();
         } else {
           // dropped on in same place
         }
       }

       this.fireEvent('dragStop');

     },

     dragEnter: function(point, target) {
       this.activeTarget = target;
     },

     dragLeave: function(point, target) {
       /*jshint unused:vars */
       this.activeTarget = null;
     },

     createGhost: function(elm) {
       this.ghost = elm.getViewDOMElement().cloneNode(true);
       this.ghost.addClassName('dnd-ghost');
       this.ghost.style.zIndex = 10002;
       this.ghost.setOpacity(0.7);

       elm.childElements.each(function(c) {
         var child = c.getViewDOMElement().cloneNode(true);
         child.style.left = c.model.getLeft() + 'px';
         child.style.top = c.model.getTop() + 'px';
         this.ghost.appendChild(child);
       }, this);
       window.editor.canvasBody.insert(this.ghost);

       this.ghost.insert(new Element('div', {className:'section-drag-tab'}));
     },

     scroll: function(before, after) {
       /*jshint unused:vars */
       this.positionGhost();
     },

     positionGhost: function(point) {
       point = point || this.lastPoint;
       this.lastPoint = point;

       if (this.ghost) {
         this.ghost.style.left = '0px';
         // this.ghost.style.top = (window.editor.canvasBody.e.scrollTop + point.top - window.editor.canvasBody.cumulativeOffset().top - this.elmHeight / 2) + 'px';
         this.ghost.style.top = (window.editor.canvasBody.e.scrollTop + point.top - window.editor.canvasBody.cumulativeOffset().top) + 'px';
       }
     }
  };
})();
