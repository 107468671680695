lp.module.image.ImageElementPropertiesPanel = Class.create(
  lp.editor.panel.ElementPropertiesPanel,
  lp.editor.panel.ElementGeometryControls,
  lp.editor.panel.ElementBorderControls,
  lp.ModuleComponent,
  {
    type:'lp-pom-image',

    initialize: function($super) {
      $super();
    },

    installUI: function($super) {
      $super();
      var self = this;

      var geometryPanel = this.insert(new jui.CollapsiblePanel({title:'Geometry'}));
      var generalPanel = this.insert(new jui.CollapsiblePanel({title:'Image Properties'}));
      var borderPanel = this.insert(new jui.CollapsiblePanel({title:'Border Properties'}));

      this.installGeometryControls({
        insert:geometryPanel.insertContent.bind(geometryPanel),
        sizeOptions:{
          showMaintainAR:true
        }
      });

      this.installBorderControls({insert:borderPanel.insertContent.bind(borderPanel)});

      var requestFocus = this.requestFocus.bind(this);

      this.controls.changeImageButton = generalPanel.insertContent(new jui.FormAssetChooser({
        label: 'Image',
        noFileText: 'No image specified',
        hideRemove: true,
        changeAction: function() {
          self.getModule().openElementBuilder(this.element, {
          callback: function(asset) {
            if (asset.uuid !== self.element.model.get('content.asset.uuid')) {
              self.element.replaceImage(asset);
            }
          },
          selectedAsset:self.element.model.get('content.asset')
        });}
      }));

      this.controls.altField = generalPanel.insertContent(new jui.FormTextArea({
        label:'Alt Text',
        onblur: function() {
          self.performModelUpdates( function() {
            var alt = self.controls.altField.getValue();
            var um = self.element.page.undoManager;

            if (alt === '') {
              self.element.model.deleteAttr('content.alt', um);
            } else {
              self.element.model.set('content.alt', alt, um);
            }
          });
        },
        onfocus: requestFocus
      }));

      this.controls.titleField = generalPanel.insertContent(new jui.FormTextInput({
        label:'Title',
        onblur: function() {
          self.performModelUpdates( function() {
            var title = self.controls.titleField.getValue();
            var um = self.element.page.undoManager;

            if (title === '') {
              self.element.model.deleteAttr('content.title', um);
            } else {
              self.element.model.set('content.title', title, um);
            }
          });
        },
        onfocus: requestFocus
      }));

      this.controls.linkField = generalPanel.insertContent(new jui.FormLinkInput2({
        onblur: function() {
          self.performModelUpdates( function() {
            var link = self.controls.linkField.getValue();
            var um = self.element.page.undoManager;
            um.startGroup();
            if (link.url === '') {
              self.element.model.deleteAttr('content.link', um);
            } else {
              self.element.model.set('content.link', link.url, um);
            }

            if (link.target === '') {
              self.element.model.deleteAttr('content.target', um);
            } else {
              self.element.model.set('content.target', link.target, um);
            }
            um.endGroup();
          });
        },
        onfocus: requestFocus
      }));
    },

    geometryControlsChanged: function() {
      var m = this.element.model;
      var um = this.element.page.undoManager;
      var newSize = this.controls.size.getValue();
      var startImageOffset = Object.clone(m.get('geometry.transform.offset'));
      var startImageSize = Object.clone(m.get('geometry.transform.size'));

      um.startGroup();

      if (window.editor.imageTransformBox.mode === 'scale') {
        var startSize = m.getSize();
        var xScale = newSize.width / startSize.width;
        var yScale = newSize.height / startSize.height;
        m.set('geometry.transform.size', {width:Math.round(startImageSize.width * xScale), height:Math.round(startImageSize.height * yScale)}, um);
        m.set('geometry.transform.offset', {left:Math.round(startImageOffset.left * xScale), top:Math.round(startImageOffset.top * yScale)}, um);
      } else {
        newSize = {
          width: Math.min(startImageSize.width + startImageOffset.left, newSize.width),
          height: Math.min(startImageSize.height + startImageOffset.top, newSize.height)
        };
      }

      m.setOffset(this.controls.offset.getValue(), um);
      m.setSize(newSize, um);

      um.endGroup();
    },

    restoreSizeClicked: function(selected) {
      /* jshint unused:vars */
      this.element.removeTransformations();
      window.editor.imageTransformBox.setMode('scale');
    },

    setElement: function($super, elm) {
      var m = elm.model;
      var self = this;

      this.modelChangeHandlers.push( function(e) {
        var accessor = e.data.accessor;

        if (accessor.startsWith('geometry.size')) {
          // updateRestoreSize(elm.model);
        } else if (accessor === 'geometry.aspectRatio') {
          var size = m.getSize();
          self.controls.size.setAspectRatio(size.width / size.height);
        } else if (accessor === 'geometry.maintainAR') {
          self.controls.size.setMaintainAspectRatio(e.data.value);
        } else if (accessor === 'content.alt') {
           self.controls.altField.setValue(e.data.value ? e.data.value : '');
        } else if (accessor === 'content.title') {
           self.controls.titleField.setValue(e.data.value ? e.data.value : '');
        } else if (accessor === 'content.link' || accessor === 'content.target') {
          // console.log(e.data.value);
          if (m.exists('content.link')) {
            self.controls.linkField.setValue({url: m.get('content.link'), target:(m.exists('content.target') ? m.get('content.target') : '')});
          } else {
            self.controls.linkField.setValue({url: '', target: ''});
          }
        }
      });

      $super(elm);
    },

    syncUIControls: function($super, elm) {
      $super(elm);

      var m = elm.model;

      this.bindGeometryControlsToElement(elm);
      this.bindBorderControlsToElement(elm);

      if (m.exists('content.asset.size')) {
        var assetSize = m.get('content.asset.size');
        this.controls.size.setAspectRatio(assetSize.width / assetSize.height);
      }
      this.controls.size.setMaintainAspectRatio(m.exists('geometry.maintainAR') ? m.get('geometry.maintainAR') : true);

      if (m.exists('content.link')) {
        this.controls.linkField.setValue({url: m.get('content.link'), target:(m.exists('content.target') ? m.get('content.target') : '')});
      } else {
        this.controls.linkField.setValue({url: '', target: ''});
      }

      this.controls.changeImageButton.setAsset(m.get('content.asset'));
      this.controls.altField.setValue(elm.model.exists('content.alt') ? elm.model.get('content.alt') : '');
      this.controls.titleField.setValue(elm.model.exists('content.title') ? elm.model.get('content.title') : '');
    }
  }
);
