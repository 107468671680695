/* globals WebFont */
lp.WebFonts = Class.create(jui.EventSource, {
  fonts: [
    'Abril Fatface',
    'Allan',
    'Arvo',
    'Cabin',
    'Dancing Script',
    'Droid Sans',
    'Gravitas One',
    'Josefin Sans',
    'Josefin Slab',
    'Lato',
    'Lobster',
    'Merriweather',
    'Old Standard TT',
    'Open Sans',
    'Playfair Display',
    'PT Sans',
    'PT Serif',
    'Quantico',
    'Ubuntu',
    'Vollkorn'
  ],

  initialize: function(){
    this.buildFontList();
  },

  getFonts: function() {
    return this.fonts;
  },

  getSortedFonts: function() {
    return this.getFonts().sort();
  },

  fontsURL: function(fonts) {
    return this.buildFontList(fonts);
  },

  googleWebFontsLinkElement: function() {
    return new Element('link', {
      rel: 'stylesheet',
      type: 'text/css',
      href: this.fontsURL()
    });
  },

  getWebFontsLinkTag: function(fonts) {
    var href = this.fontsURL(fonts);
    if(href.length > 0) {
      return '<link rel="stylesheet" type="text/css" href="'+href+'" />';
    }
    return '';
  },
  //
  //buildFontList generates a url that will look like the following:
  // //fonts.googleapis.com/css?family=font+1|cool+font|another+cool+font|
  buildFontList: function(fontList) {
    var googleURL = '//fonts.googleapis.com/css?family=';
    fontList = fontList ? this.generateFontList(fontList) : this.getFonts();
    fontList.each(function(font) {
      font = font.replace(/\s/g, '+');
      googleURL = googleURL.concat(font + '|');
    }, this);
    return fontList.size() > 0 ? googleURL : '';
  },

  generateFontList: function(fontList) {
    var fonts = [];
    fontList.each(function(font){
      var foundFont = this.fonts.detect(function(f){
        return f.toLowerCase() === this.cleanQuotes(font.toLowerCase());
      }, this);
      if(foundFont) {fonts.push(foundFont);}
    }, this);

    return fonts;
  },

  cleanQuotes: function(fontString) {
    return fontString.replace(/'|"/g,'');
  },

  generateFontLoadScriptContent: function(fonts){
    fonts = fonts || this.getFonts();
    var script = '';
    var webFonts = this.getFontFamilies(this.generateFontList(fonts));
    if(webFonts.length > 0) {
      script += 'lp = lp || {};\n';
      script += 'lp.webFontLoad = {\n';
      script += '  google: {\n';
      script += '    families: [' + webFonts.join(',') +']\n';
      script += '  },\n';
      script += '  active: function() {\n';
      script += '   if(lp && lp.text && typeof lp.text.fixTextHeights === "function")  {\n';
      script += '     lp.text.fixTextHeights();\n';
      script += '   }\n';
      script += '  }\n';
      script += '};\n';
      script += 'WebFont.load(lp.webFontLoad);\n';
    }
    return script;
  },

  getFontFamilies: function(fonts) {
    fonts = fonts.collect(function(font){
      if(this.isWebFont(font)) {
        return "'"+font+"'";
      }
    }, this);
    return fonts.compact();
  },

  isWebFont: function(font) {
    var found = this.fonts.find(function(webFont) {
      return jui.capitalizeAll(webFont) === jui.capitalizeAll(font);
    });

    return typeof found !== 'undefined';
  },

  getGoogleWebFontsLoaderScriptTag: function() {
    return '<script src="//ajax.googleapis.com/ajax/libs/webfont/1.4.7/webfont.js"></script>';
  },

  getWebFontsLoadScript: function(fonts) {
    return '<script>\n' + this.generateFontLoadScriptContent(fonts) + '</script>';
  },

  loadAllFonts: function() {
    WebFont.load({
      google: {
        families: this.fonts
      },
      active: this.allFontsLoaded.bind(this)
    });
  },

  allFontsLoaded: function() {
    this.fireEvent('allFontsLoaded');
  },

  insertWebFontsIntoDocument: function() {
    var head = window.document.getElementsByTagName('head');
    head[0].insert(this.googleWebFontsLinkElement());
  }

});
