jui.social_widget_google = {

  id: 'plusone',
  name: 'google',

  getWidgetHtml: function( googleObj ) {
    this.validate( googleObj );
    return this.googleTemplate().
      evaluate( this.getGoogleLayoutObj( googleObj ) );
  },

  getScript: function() {
    return "<script type=\"text/javascript\"> \n (function() {\n" +
        "var po = document.createElement('script'); po.type = 'text/javascript'; po.async = true;\n" +
        "po.src = 'https://apis.google.com/js/plusone.js';\n" +
        "var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s);\n" +
      "})();\n</script>";
  },

  cleanup: function() {
    var jsNode = $( this.id );
    var jsNodes = $$("script[src*='"+this.id+"']");
    if( jsNode ) {
      jsNode.remove();
    }

    if(jsNodes) {
      jsNodes.each(function(el){
        el.remove();
      });
    }
  },

  getStyle: function() {
    return '';
  },

  getSizeByLayout: function(layout) {
    var size = {
      top: {
        width: 50,
        height: 64
      },
      right: {
        width: 90,
        height: 24
      },
      none: {
        width: 32,
        height: 24
      }
    };
    return size[ layout.countOrientation ];
  },

  validate: function( googleObj  ) {
    this.validateKeys( googleObj );
  },

  validateKeys: function( googleObj ) {
    var keys         = $H( googleObj ).keys(),
        requiredKeys = [ 'orientation', 'countOrientation', 'name' ];

    requiredKeys.each( function( key ) {
      if( !keys.include( key ) ) {
        throw key + ': Key is missing form social_widget_google.js';
      }
    } );
  },

  googleTemplate: function() {
    return new Template( '<div class="g-plusone" data-annotation="#{annotation}" data-size="#{size}" data-href="#{url}"></div>' );
  },

  getGoogleLayoutObj: function( googleObj ) {
    googleObj = $H( googleObj );
    switch( googleObj.get( 'countOrientation' ) ) {
      case 'top':
        googleObj.set( 'size', 'tall' );
        googleObj.set( 'annotation', 'bubble' );
        break;
      case 'right':
        googleObj.set( 'size', 'medium' );
        googleObj.set( 'annotation', 'bubble' );
        break;
      default:
        googleObj.set( 'size', 'medium' );
        googleObj.set( 'annotation', 'none' );
    }
    return googleObj;
  }

};
