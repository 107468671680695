/* globals lp */ ;
(function(root) {
  var config = [{
    name: 'lp-mobile-tour',
    options: {
      openSelectorRole: 'mobile-breakpoint-button',
      closeSelectorRole: 'close-mobile-tour-button',
      inputSelectorRole: 'seen-mobile-tour-checkbox',
      containerSelectorRole: 'mobile-tour-overlay',
      cssClass: 'show-mobile-tour',
      templateBuilderConstructor: lp.MobileTourTemplateBuilder
    },
    enabled: function() {
      return lp.isResponsiveEnabled();
    }
  }];

  function _getDisabledTours() {
    return _.map(_.filter(config, function(obj) {
      return !obj.enabled();
    }), function(obj) {
      return obj.name;
    });
  }

  var initiateTours = function() {
    var disabledTours = _getDisabledTours();
    if (!_.isEmpty(disabledTours)) {
      console.warn('Disabled tours : ', disabledTours.toString());
    }

    var tourMaps = _.map(config, function(obj) {
      var tour = new lp.NewFeatureTour(obj.name, obj.options).init();
      return {
        tour: tour,
        enabled: obj.enabled()
      };
    });

    var validTourMaps = _.select(tourMaps, function(tourMap) {
      return tourMap.tour.isValid();
    });

    var inValidTourMaps = _.select(tourMaps, function(tourMap) {
      return !tourMap.tour.isValid();
    });

    _.each(validTourMaps, function(validTourMap) {
      return validTourMap.enabled ? validTourMap.tour.enable() : validTourMap.tour.disable();
    });
  };

  root.rx_switchboard.subjects.jui_events
    .filter(function(ev) {
      return ev.type === 'pageLoaded';
    })
    .take(1)
    .subscribe(initiateTours);
})(this);
