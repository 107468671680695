/* globals exports, module, define */
;(function(root, fn) {
  if (typeof exports === 'object') {
    var Getter = fn();
    exports.Styler = exports.Styler || {};
    exports.Styler.Getter = Getter;
    module.exports = exports;
  } else if (typeof 'define' === 'function' && define.amd) {
    define(fn);
  } else {
    root.lp.module.form.FormElement.Styler = root.lp.module.form.FormElement.Styler || {};
    root.lp.module.form.FormElement.Styler.Getter = fn();
  }
})(this, function() {
  var Getter = function(id) {
    this.id = id;
  };
  Getter.prototype = {
    selectorName: function (idPrefix, key) {
      if (idPrefix === "") {
        // this is the field input element itself (tag=input, select, etc.)
        return '#' + this.id + ' .form_elem_'+ key;
      } else {
        return '#' + this.id + ' #' + idPrefix + key;
      }
    },
    getRuleForStyle: function(key, attribute, value, idPrefix) {
      return {
        selector: this.selectorName(idPrefix, key),
        attribute: attribute,
        value: value
      };
    },

    getRulesForStyles: function(key, styles, idPrefix) {
      return $H(styles).map(function(item) {
        return this.getRuleForStyle(key, item.key, item.value, idPrefix);
      }, this);
    },

    getRulesForElementType: function(elements, idPrefix) {
      return $H(elements).map(function(item) {
        return this.getRulesForStyles(item.key, item.value.style, idPrefix);
      }, this).flatten();
    },

    getRulesForElementTypes: function(args) {
      return args.map(function(transformedObj) {
        return this.getRulesForElementType(
          transformedObj.elements,
          transformedObj.idPrefix
        );
      }, this).flatten();
    }
  };
  return Getter;
});
