lp.editor.ImageTransformBox = Class.create( jui.Component, lp.editor.dnd.transformElementDnDSource, {
  options: function($super,options){
    return $super($H({
        attributes:{className:'transform-box'}
      }).merge(options));
  },

  initialize: function( $super, editor, options ){
    this.editor = editor;
    this.elm = null;
    this.tracking = false;
    this.boxOffset = [];
    this.handleW = 8;
    this.handleH = 8;
    this.handleNames = ['tlh','trh','blh','brh','tmh','rmh','bmh','lmh'];
    this.mode = 'scale';
    this.maintainARState = null;
    this.maskInset = 15;

    $super( options );

    this.mouseMoveListener =    this.mousemove.bindAsEventListener( this );
    this.mouseUpListener =      this.mouseup.bindAsEventListener( this );

    this.elmModelListener = this.elementModelChanged.bind(this);
    this.editor.addListener('rootElementLoaded', this);
    this.undoRegistered = false;

    var self = this;
    var adjustmentListener = function() {
      if (self.elm !== null && self.elm.type !== 'lp-pom-root') {
        self.updatePositioning();
      }
    };

    this.editor.addListener('layoutChanged', adjustmentListener);
    this.editor.addListener('elementMousedown', this);
    this.editor.addListener('elementActivated', this);
    this.editor.addListener('elementDeactivated', this);
    this.editor.addListener('activeRootChanged', this);
  },

  activeRootChanged: function(e) {
    var previous = e.data.previous;
    var root = e.data.current;
    if (previous !== null) {
      previous.removeListener('layoutChanged', this);
    }

    root.addListener('layoutChanged', this);
  },

  installUI: function( $super ) {
    $super();
    this.installGhostImage();
    this.installBoundingBox();
    this.installHandles();
    this.installRegistration();
    this.installTools();
  },

  installGhostImage: function() {
    var self = this;
    this.ghostImage = this.insert( new Element('img', {className:'ghost-image'}));
    this.ghostImage.setOpacity(0.4);
    this.ghostImage.observe('mousedown', function(e) {
      e.stop();
      self.focus();
      self.startPoint = new jui.Point(Event.pointerX(e),Event.pointerY(e));
      self.startOffset = self.getMaskOffset();
      self.startSize = self.getMaskSize();
      self.startImageOffset = self.getImageOffsetRelToMask();
      self.startImageSize = self.getImageSize();
      self.setTracking(true);
    });
    this.ghostImage.hide();
  },

  installRegistration: function() {
    this.reg = this.insert(new jui.Component({attributes: {className: 'registration'}}));
  },

  installTools: function() {
    var self = this;

    this.maskPanel = this.insert(new jui.Component({attributes: {className: 'mask-panel'}}));
    this.maskPanel.setOpacity(0.75);
    this.maskPanel.e.observe('mousedown', function(e) {
      Event.stop(e);
    });

    this.maskToggle = this.maskPanel.insert(new jui.IconToggle('mask', {
      label:'Edit Mask',
      action: function(e) {
        self.setMode(e.source.isSelected() ? 'mask' : 'scale');
      },
      toolTip:"Adjust image cropping"
    }));

    this.scaleSlider = this.maskPanel.insert(new jui.Slider({
      min:0,
      max:1
    }));

    this.scaleSlider.addListener('valueChanged', function(e) {
      if (self.sliderUpdating) {
        return;
      }
      self.scaleChanged(e);
    });


    this.scaleSlider.addListener('startTracking', function() {
      self.registerFullUndo();
    });

    this.maskPanel.insert(new Element('img', {className:'down', src: '/images/ui/editor/icon-scale-down.png'}));
    this.maskPanel.insert(new Element('img', {className:'up', src: '/images/ui/editor/icon-scale-up.png'}));

    this.removeMaskButton = this.maskPanel.insert(new jui.Button({
      classNames: ['overlay remove-mask'],
      label:'Remove Mask',
      action: function() {
        var um = self.elm.page.undoManager;
        var offset = self.getMaskOffset();
        var imgOffset = self.getImageOffsetRelToMask();
        var imgSize = self.getImageSize();

        um.startGroup();
        self.setMaskOffset({
          left:offset.left + imgOffset.left,
          top:offset.top + imgOffset.top
        }, um);
        self.setImageOffsetRelToMask({left: 0, top: 0}, um);
        self.setMaskSize({width: imgSize.width, height: imgSize.height}, um);
        self.setMode('scale');

        um.endGroup();
      }
    }));

    this.restoreSizeButton = this.maskPanel.insert(new jui.Button({
      classNames: ['overlay restore-size'],
      label:'Restore Size',
      action: function() {
        self.restoreImageSize();
      }
    }));

    this.doneButton = this.maskPanel.insert(new jui.Button({
      classNames: ['overlay done'],
      label:'Done',
      action: function() {
        self.setMode('scale');
      }
    }));
  },

  installBoundingBox: function() {
    this.tbndry = this.insert( new Element('div', {className:'transform-boundary top'}));
    this.rbndry = this.insert( new Element('div', {className:'transform-boundary right'}));
    this.bbndry = this.insert( new Element('div', {className:'transform-boundary bottom'}));
    this.lbndry = this.insert( new Element('div', {className:'transform-boundary left'}));
  },

  registerFullUndo: function() {
    var m = this.elm.model;
    var um = this.elm.page.undoManager;
    var previousMaskOffset = m.getMaskOffset();
    var previousMaskSize = m.getMaskSize();
    um.startGroup();
    um.registerUndo({
      action:m.setMaskOffset,
      receiver:m,
      params:[{left:previousMaskOffset.left, top:previousMaskOffset.top}, um]
    });
    um.registerUndo({
      action:m.setMaskSize,
      receiver:m,
      params:[{width:previousMaskSize.width, height:previousMaskSize.height}, um]
    });

    if (m.exists('geometry.transform.offset')) {
      var previousImageOffset = m.getImageOffsetRelToMask();
      um.registerUndo({
        action:m.setImageOffsetRelToMask,
        receiver:m,
        params:[{left:previousImageOffset.left, top:previousImageOffset.top}, um]
      });
    }

    if (m.exists('geometry.transform.size')) {
      var previousImageSize = m.getImageSize();
      um.registerUndo({
        action:m.setImageSize,
          receiver:m,
        params:[{width:previousImageSize.width, height:previousImageSize.height}, um]
      });
    }
    um.endGroup();
  },

  installHandles: function(){
    var intersectionPoint = function(w, h) {
      /* JS: TODO: this can probably be reduced further */
      /* inverse slope of line through [w,h] and [0,0] */
      var s = w / h;
      /* distance from [w,h] to line through [x,y] which is perpendicular to line through [0,0] and [w,h] */
      var d = (s * this.left + this.top) / (Math.sqrt(s * s + 1));
      /* it's angle */
      var a = Math.atan(s);
      /* intersection point */
      this.left = Math.round(Math.sin(a) * d);
      this.top = Math.round(Math.cos(a) * d);
      // return {left: Math.round(Math.sin(a) * d), top: Math.round(Math.cos(a) * d)};
    };

    var constrainPointToAR = function(offset, ar, sign) {
      sign = sign || {left:1, top:1};
      if (this.left * sign.left < offset.left * sign.left) {
        var x = offset.left;
        this.left = x;
        this.top = Math.round(x / ar);
      } else if (this.top * sign.top < offset.top * sign.top) {
        var y = offset.top;
        this.left = Math.round(y * ar);
        this.top = y;
      }
    };

    var self = this;
    var onBeforeDrag = function() {
      if (!self.undoRegistered) {
        self.undoRegistered = true;
        self.registerFullUndo();
      }
    };

    /* ===================================================================== */
    /* insert top left handle with functions for (mousemove, mousedown) */
    this.tlh = this.insert( new lp.editor.ImageTransformBoxHandle( this, function(p, m){
      m.suppressEvents();
      var maintainAR = m.exists('geometry.maintainAR') && m.get('geometry.maintainAR');
      /* if aspect ratio is to be maintained then we will recalculate the input point to the intersection of
       * the line through the active handle and the opposite handle and the line perpendicular to this which
       * goes through the inout point */
      if (maintainAR) {
        intersectionPoint.call(p, this.startWidth, this.startHeight);
      }
      /* restrict the input point to within the bounds of the image if we are in maskping mode */
      if (self.getMode() === 'mask') {
        if (maintainAR) {
          constrainPointToAR.call(p, this.startImageOffset, this.startWidth / this.startHeight);
        } else {
          p.max({left:this.startImageOffset.left, top:this.startImageOffset.top});
        }
      }
      /* restrict the input point from a position where width or height of box would be negative */
      p.min({left: this.startWidth-1, top: this.startHeight-1});

      var w = this.startWidth - p.left;
      var h = this.startHeight - p.top;
      var l = this.startLeft + p.left;
      var t = this.startTop + p.top;
      var x = this.startImageOffset.left - p.left;
      var y = this.startImageOffset.top - p.top;

      self.setMaskSize({width:w, height:h});
      self.setMaskOffset({left:l,top:t});

      switch (self.getMode()) {
        case 'scale':
          var xScale = w / this.startWidth;
          var yScale = h / this.startHeight;
          self.setImageSize({width:Math.round(this.startImageSize.width * xScale),
                             height:Math.round(this.startImageSize.height * yScale)});
          self.setImageOffsetRelToMask({left:Math.round(this.startImageOffset.left * xScale),
                                        top:Math.round(this.startImageOffset.top * yScale)});
          break;
        case 'mask':
          self.setImageOffsetRelToMask({left:x, top:y});
          self.ghostImage.style.left = x + 'px';
          self.ghostImage.style.top = y + 'px';
          break;
      }

      m.releaseEvents();
    }, function(m){
      this.initPoint = new jui.Point(m.getMaskOffset());
      this.startImageOffset = Object.clone(m.getImageOffsetRelToMask());
      this.startImageSize = Object.clone(m.getImageSize());
    }, onBeforeDrag, { classNames: ['tlh']} ) );

    /* ===================================================================== */
    /* insert top right handle with functions for (mousemove, mousedown) */
    this.trh = this.insert( new lp.editor.ImageTransformBoxHandle( this, function(p, m){
      m.suppressEvents();
      var maintainAR = m.exists('geometry.maintainAR') && m.get('geometry.maintainAR');
      /* if aspect ratio is to be maintained then we will recalculate the input point to the intersection of
       * the line through the active handle and the opposite handle and the line perpendicular to this which
       * goes through the inout point */
      if (maintainAR) {
        intersectionPoint.call(p, this.startWidth, -this.startHeight);
      }
      /* restrict the input point to within the bounds of the image if we are in maskping mode */
      if (self.getMode() === 'mask') {
        if (maintainAR) {
          constrainPointToAR.call(p, {left:this.startImageSize.width - this.startWidth + this.startImageOffset.left, top:this.startImageOffset.top}, -this.startWidth / this.startHeight, {left:-1,top:1});
        } else {
          p = { left: Math.min(this.startImageSize.width - this.startWidth + this.startImageOffset.left, p.left),
                top: Math.max(this.startImageOffset.top, p.top)};
        }

      }
      /* restrict the input point from a position where width or height of box would be negative */
      p = { left: Math.max(1-this.startWidth, p.left),
            top: Math.min(this.startHeight-1, p.top)};

      var w = this.startWidth + p.left;
      var h = this.startHeight - p.top;
      var t = this.startTop + p.top;
      var y = this.startImageOffset.top - p.top;

      self.setMaskSize({width:w, height:h});
      self.setMaskOffset({top: t});

      switch (self.getMode()) {
        case 'scale':
          var xScale = w / this.startWidth;
          var yScale = h / this.startHeight;
          self.setImageSize({width:Math.round(this.startImageSize.width * xScale),
                             height:Math.round(this.startImageSize.height * yScale)});
          self.setImageOffsetRelToMask({left:Math.round(this.startImageOffset.left * xScale),
                                        top:Math.round(this.startImageOffset.top * yScale)});
          break;
        case 'mask':
          self.setImageOffsetRelToMask({top: y});
          self.ghostImage.style.top = y + 'px';
          break;
      }

      m.releaseEvents();
    }, function(m){
      this.initPoint = new jui.Point(m.getMaskOffset())
        .add({left:m.getMaskSize().width,top:0});
      this.startImageOffset = Object.clone(m.getImageOffsetRelToMask());
      this.startImageSize = Object.clone(m.getImageSize());
    }, onBeforeDrag, { classNames: ['trh']} ) );

    /* ===================================================================== */
    /* insert bottom left handle with functions for (mousemove, mousedown) */
    this.blh = this.insert( new lp.editor.ImageTransformBoxHandle( this, function(p, m){
      m.suppressEvents();
      var maintainAR = m.exists('geometry.maintainAR') && m.get('geometry.maintainAR');
      /* if aspect ratio is to be maintained then we will recalculate the input point to the intersection of
       * the line through the active handle and the opposite handle and the line perpendicular to this which
       * goes through the inout point */
      if (maintainAR) {
        intersectionPoint.call(p, -this.startWidth, this.startHeight);
      }
      /* restrict the input point to within the bounds of the image if we are in maskping mode */
      if (self.getMode() === 'mask') {
        if (maintainAR) {
          constrainPointToAR.call(p, {left:this.startImageOffset.left, top:this.startImageSize.height - this.startHeight + this.startImageOffset.top}, this.startWidth / -this.startHeight, {left:1,top:-1});
        } else {
          p = { left: Math.max(this.startImageOffset.left, p.left),
                top: Math.min(this.startImageSize.height - this.startHeight + this.startImageOffset.top, p.top)};
        }
      }
      /* restrict the input point from a position where width or height of box would be negative */
      p = { left: Math.min(this.startWidth-1, p.left),
            top: Math.max(1-this.startHeight, p.top)};

      var w = this.startWidth - p.left;
      var h = this.startHeight + p.top;
      var l = this.startLeft + p.left;
      var x = this.startImageOffset.left - p.left;

      self.setMaskSize({width:w, height:h});
      self.setMaskOffset({left:l});

      switch (self.getMode()) {
        case 'scale':
          var xScale = w / this.startWidth;
          var yScale = h / this.startHeight;
          self.setImageSize({width:Math.round(this.startImageSize.width * xScale),
                             height:Math.round(this.startImageSize.height * yScale)});
          self.setImageOffsetRelToMask({left:Math.round(this.startImageOffset.left * xScale),
                                        top:Math.round(this.startImageOffset.top * yScale)});
          break;
        case 'mask':
          self.setImageOffsetRelToMask({left: x});
          self.ghostImage.style.left = x + 'px';
          break;
      }

      m.releaseEvents();
    }, function(m){
        this.initPoint = new jui.Point(m.getMaskOffset())
          .add({left:0,top:m.getMaskSize().height});
        this.startImageOffset = Object.clone(m.getImageOffsetRelToMask());
        this.startImageSize = Object.clone(m.getImageSize());
    }, onBeforeDrag, { classNames: ['blh']} ) );

    /* ===================================================================== */
    /* insert bottom right handle with functions for (mousemove, mousedown) */
    this.brh = this.insert( new lp.editor.ImageTransformBoxHandle( this, function(p, m){
      m.suppressEvents();
      var maintainAR = m.exists('geometry.maintainAR') && m.get('geometry.maintainAR');
      /* if aspect ratio is to be maintained then we will recalculate the input point to the intersection of
       * the line through the active handle and the opposite handle and the line perpendicular to this which
       * goes through the inout point */
      if (maintainAR) {
        intersectionPoint.call(p, this.startWidth, this.startHeight);
      }
      /* restrict the input point to within the bounds of the image if we are in maskping mode */
      if (self.getMode() === 'mask') {
        if (maintainAR) {
          constrainPointToAR.call(p, {left:this.startImageSize.width - this.startWidth + this.startImageOffset.left, top:this.startImageSize.height - this.startHeight + this.startImageOffset.top}, this.startWidth / this.startHeight, {left:-1,top:-1});
        } else {
          p.min({left:this.startImageSize.width - this.startWidth + this.startImageOffset.left, top:this.startImageSize.height - this.startHeight + this.startImageOffset.top});
        }
      }
      /* restrict the input point from a position where width or height of box would be negative */
      p.max({left:1-this.startWidth, top:1-this.startHeight});

      var  w = this.startWidth + p.left;
      var  h = this.startHeight + p.top;

      self.setMaskSize({width:w, height:h});

      if (self.getMode() === 'scale') {
        var xScale = w / this.startWidth;
        var yScale = h / this.startHeight;
        self.setImageSize({width:Math.round(this.startImageSize.width * xScale),
                           height:Math.round(this.startImageSize.height * yScale)});
        self.setImageOffsetRelToMask({left:Math.round(this.startImageOffset.left * xScale),
                                      top:Math.round(this.startImageOffset.top * yScale)});
      }

      m.releaseEvents();
    }, function(m){
      var maskSize = m.getMaskSize();
      this.initPoint = new jui.Point(m.getMaskOffset())
        .add({left:maskSize.width, top: maskSize.height});
      this.startImageOffset = Object.clone(m.getImageOffsetRelToMask());
      this.startImageSize = Object.clone(m.getImageSize());
    }, onBeforeDrag, { classNames: ['brh']} ) );

    /* ===================================================================== */
    /* insert top middle handle with functions for (mousemove, mousedown) */
    this.tmh = this.insert( new lp.editor.ImageTransformBoxHandle( this, function(p, m){
      m.suppressEvents();
      var ar;
      var maintainAR = m.exists('geometry.maintainAR') && m.get('geometry.maintainAR');
      /* restrict the input point to within the bounds of the image if we are in maskping mode */
      if (self.getMode() === 'mask') {
        p.top = Math.max(this.startImageOffset.top, p.top);
        // if maintainAR restrict p.top so width is in bounds too
        if (maintainAR) {
          ar = this.startWidth / this.startHeight;
          var w = (this.startHeight - p.top) * ar;
          var wMax = (this.startImageSize.width + this.startImageOffset.left);
          if (w > wMax) {
            p.top = Math.round(this.startHeight - (wMax / ar));
          }
        }
      }
      /* restrict the input point from a position where width or height of box would be negative */
      p.top = Math.min(this.startHeight-1, p.top);

      var h = this.startHeight - p.top;
      var t = this.startTop + p.top;

      m.setMaskSize({height: h});
      m.setMaskOffset({top: t});

      if (maintainAR) {
        ar = this.startWidth / this.startHeight;
        m.setMaskSize({width: Math.round(h * ar)});
      }

      switch (self.getMode()) {
        case 'scale':
          var scale = h / this.startHeight;
          m.setImageOffsetRelToMask({top: Math.round(this.startImageOffset.top * scale)});
          m.setImageSize({height: Math.round(this.startImageSize.height * scale)});
          if (maintainAR) {
            m.setImageOffsetRelToMask({left: Math.round(this.startImageOffset.left * scale)});
            m.setImageSize({width: Math.round(this.startImageSize.width * scale)});
          }
          break;
        case 'mask':
          var y = this.startImageOffset.top - p.top;
          m.setImageOffsetRelToMask({top: y});
          self.ghostImage.style.top = y + 'px';
          break;
      }

      m.releaseEvents();
    }, function(m){
      this.initPoint = new jui.Point(m.getMaskOffset());
      this.startImageOffset = Object.clone(m.getImageOffsetRelToMask());
      this.startImageSize = Object.clone(m.getImageSize());
    }, onBeforeDrag, { classNames: ['tmh']} ) );

    /* ===================================================================== */
    /* insert right middle handle with functions for (mousemove, mousedown) */
    this.rmh = this.insert( new lp.editor.ImageTransformBoxHandle( this, function(p, m){
      m.suppressEvents();
      var ar,h;
      var maintainAR = m.exists('geometry.maintainAR') && m.get('geometry.maintainAR');
      /* restrict the point to within the bounds of the image if we are in maskping mode */
      if (self.getMode() === 'mask') {
        p.left = Math.min(this.startImageSize.width - this.startWidth + this.startImageOffset.left, p.left);
        // if maintainAR restrict p.left so height is in bounds too
        if (maintainAR) {
          ar = this.startWidth / this.startHeight;
          h = (this.startWidth + p.left) / ar;
          var hMax = (this.startImageSize.height + this.startImageOffset.top);
          if (h > hMax) {
            p.left = Math.round((hMax * ar) - this.startWidth);
          }
        }
      }
      /* restrict the input point from a position where width or height of box would be negative */
      p.left = Math.max(1-this.startWidth, p.left);

      var w = this.startWidth + p.left;

      if (maintainAR) {
        h = Math.round(w / (this.startWidth / this.startHeight));
        self.setMaskSize({width:w, height:h});
      } else {
        self.setMaskSize({width:w});
      }

      if (self.getMode() === 'scale') {
        var xScale = w / this.startWidth;
        self.setImageOffsetRelToMask({left: Math.round(this.startImageOffset.left * xScale)});
        self.setImageSize({width: Math.round(this.startImageSize.width * xScale)});
        if (maintainAR) {
          var yScale = h / this.startHeight;
          self.setImageOffsetRelToMask({top: Math.round(this.startImageOffset.top * yScale)});
          self.setImageSize({height: Math.round(this.startImageSize.height * yScale)});
        }
      }
      m.releaseEvents();
    }, function(m){
      this.initPoint = new jui.Point(m.getMaskOffset())
        .add({left:m.getMaskSize().width, top:0});
      this.startImageOffset = Object.clone(m.getImageOffsetRelToMask());
      this.startImageSize = Object.clone(m.getImageSize());
    }, onBeforeDrag, { classNames: ['rmh']} ) );

    /* ===================================================================== */
    /* insert bottom middle handle with functions for (mousemove, mousedown) */
    this.bmh = this.insert( new lp.editor.ImageTransformBoxHandle( this, function(p, m){
      m.suppressEvents();
      // TODO-TR: remove direct model access here
      var maintainAR = m.exists('geometry.maintainAR') && m.get('geometry.maintainAR');
      var w, ar;
      /* restrict the point to within the bounds of the image if we are in maskping mode */
      if (self.getMode() === 'mask') {
        p.top = Math.min(this.startImageSize.height - this.startHeight + this.startImageOffset.top, p.top);
        // if maintainAR restrict p.top so width is in bounds too
        if (maintainAR) {
          ar = this.startWidth / this.startHeight;
          w = (this.startHeight + p.top) * ar;
          var wMax = (this.startImageSize.width + this.startImageOffset.left);
          if (w > wMax) {
            p.top = Math.round((wMax / ar) - this.startHeight);
          }
        }
      }
      /* restrict the input point from a position where width or height of box would be negative */
      p.top = Math.max(1-this.startHeight, p.top);

      var h = this.startHeight + p.top;

      if (maintainAR) {
        w = Math.round(h * m.get('geometry.aspectRatio'));
        self.setMaskSize({width:w, height:h});
      } else {
        self.setMaskSize({height: h});
      }

      if (self.getMode() === 'scale') {
        var yScale = h / this.startHeight;
        self.setImageOffsetRelToMask({top: Math.round(this.startImageOffset.top * yScale)});
        self.setImageSize({height: Math.round(this.startImageSize.height * yScale)});
        if (maintainAR) {
          var xScale = w / this.startWidth;
          self.setImageOffsetRelToMask({left: Math.round(this.startImageOffset.left * xScale)});
          self.setImageSize({width: Math.round(this.startImageSize.width * xScale)});
        }
      }
      m.releaseEvents();
    }, function(m) {
      this.initPoint = new jui.Point(m.getMaskOffset())
        .add({left:0,top:m.getMaskSize().height});
      this.startImageOffset = Object.clone(m.getImageOffsetRelToMask());
      this.startImageSize = Object.clone(m.getImageSize());
    }, onBeforeDrag, { classNames: ['bmh']} ) );

    /* ===================================================================== */
    /* insert left middle handle with functions for (mousemove, mousedown) */
    this.lmh = this.insert( new lp.editor.ImageTransformBoxHandle( this, function(p, m){
      m.suppressEvents();
      var maintainAR = m.exists('geometry.maintainAR') && m.get('geometry.maintainAR');
      /* restrict the point to within the bounds of the image if we are in maskping mode */
      if (self.getMode() === 'mask') {
        p.left = Math.max(this.startImageOffset.left, p.left);
        // if maintainAR restrict p.left so height is in bounds too
        if (maintainAR) {
          var ar = this.startWidth / this.startHeight;
          var h = (this.startWidth - p.left) / ar;
          var hMax = (this.startImageSize.height + this.startImageOffset.top);
          if (h > hMax) {
            p.left = Math.round(this.startWidth - (hMax * ar));
          }
        }
      }
      /* restrict the input point from a position where width or height of box would be negative */
      p.left = Math.min(this.startWidth-1, p.left);

      var w = this.startWidth - p.left;
      var l = this.startLeft + p.left;

      self.setMaskSize({width:w});
      self.setMaskOffset({left:l});

      if (maintainAR) {
        self.setMaskSize({height: Math.round(w / m.get('geometry.aspectRatio'))});
      }

      switch (self.getMode()) {
        case 'scale':
          var scale = w / this.startWidth;
          self.setImageSize({width: Math.round(this.startImageSize.width * scale)});
          self.setImageOffsetRelToMask({left: Math.round(this.startImageOffset.left * scale)});
          if (maintainAR) {
            self.setImageSize({height: Math.round(this.startImageSize.height * scale)});
            self.setImageOffsetRelToMask({top: Math.round(this.startImageOffset.top * scale)});
          }
          break;
        case 'mask':
          var x = this.startImageOffset.left - p.left;
          self.setImageOffsetRelToMask({left: x});
          self.ghostImage.style.left = x + 'px';
          break;
      }

      m.releaseEvents();
    }, function(m){
      this.initPoint = new jui.Point(m.getMaskOffset());
      this.startImageOffset = Object.clone(m.getImageOffsetRelToMask());
      this.startImageSize = Object.clone(m.getImageSize());
    }, onBeforeDrag, { classNames: ['lmh']} ) );
  },

  getMode: function() {
    return this.mode;
  },

  setMode: function(mode) {
    this.mode = mode;
    var m;
    if (mode === 'mask') {
      if (!this.maskToggle.isSelected()) {
        this.maskToggle.toggleOn();
      }

      this.createMask();
      this.updateMaskScaleRange();
      this.e.addClassName('mask');
      if (this.elm !== null) {
        m = this.elm.model;
        this.maintainARState = m.get('geometry.maintainAR');
        m.set('geometry.maintainAR', false);
        this.updateGhostImageGeometry();
      }

      this.ghostImage.show();
    } else { //mode === scale
      if (this.maskToggle.isSelected()) {
        this.maskToggle.toggleOff();
      }

      this.e.removeClassName('mask');
      if (this.elm !== null) {
        this.elm.model.set('geometry.maintainAR', this.maintainARState);
      }
      this.ghostImage.hide();
    }
  },

  rootElementLoaded: function(e) {
    var root = e.data;
    root.addListener('contentWidthChanged', this);
  },

  elementActivated: function(e) {
    var elm = e.data;
    if (!elm.isVisibleOnPage()){ return; }
    if (elm.type !== 'lp-pom-image'){ return; }
    this.setElement(elm);
    this.maintainARState = elm.model.get('geometry.maintainAR');
  },

  imageChanged: function() {
    this.setMode('scale');
    this.updateGhostImage();
  },

  elementDeactivated: function() {
    this.setMode('scale');
    this.setElement(null);
  },

  elementMousedown: function( e ) {
    if (this.elm === null || this.elm.type !== 'lp-pom-image'){ return; }
    var event = e.data.data;

    if (this.elm.is('offsetable')) {
      jui.dnd.manager.startTracking(this, e.data.data, {threshhold: true});
      this.startParent = this.elm.parentElement;
      this.startPoint = new jui.Point(Event.pointerX(event),Event.pointerY(event));
      this.startOffset = this.getMaskOffset();
      this.startImageOffset = this.getImageOffsetRelToMask();
      this.setTracking( true );
      this.editor.fireEvent('elementDragStart');
    }
  },

  mouseup:function( e ){
    Event.stop( e );
    this.setTracking( false );
    this.editor.fireEvent('elementDragStop');
  },

  mousemove:function( e ){
    Event.stop( e );
    var m = this.elm.model;
    var um = this.elm.page.undoManager;
    var previousOffset;

    this.lastPoint = {left: Event.pointerX(e), top:Event.pointerY(e)};

    if (this.mode === 'scale') {
      if (!this.undoRegistered) {
        this.undoRegistered = true;
        um = this.elm.page.undoManager;

        previousOffset = m.getOffset();
        var perviousParent = this.elm.parentElement;
        var previousZIndex = this.elm.model.getZIndex();
        var undoMove = function (elm, previousOffset, previousParent, previousZIndex, um) {
          var currentOffset = elm.model.getOffset();
          var currentParent = elm.parentElement;
          var currentZIndex = elm.model.getZIndex();
          um.registerUndo({
            action: undoMove,
            receiver:{},
            params:[elm, currentOffset, currentParent, currentZIndex, um]
          });
          if (currentParent !== previousParent) {
            elm.changeParent(previousParent, previousZIndex);
          }
          elm.model.setMaskOffset({left:previousOffset.left, top:previousOffset.top}, um);
        };

        um.registerUndo({
          action: undoMove,
          receiver:{},
          params:[this.elm, previousOffset, perviousParent, previousZIndex, um]
        });
      }

      var r = new jui.Rectangle(
        Event.pointerX(e),
        Event.pointerY(e),
        this.getMaskSize().width,
        this.getMaskSize().height).subtract(this.startPoint).add(this.startOffset);

      // editor.snapManager.snapPoint(p);
      editor.snapManager.snapRectangle(r);
      m.setOffset({left:r.left, top:r.top});
      this.fireEvent('move', this.elm);
    } else {
      if (!this.undoRegistered) {
        this.undoRegistered = true;
        previousOffset = m.getOffset();
        um.registerUndo({
          action: m.setOffset,
          receiver: m,
          params: [{left:previousOffset.left, top:previousOffset.top}, um]
        });
      }

      var p = new jui.Point(Event.pointerX(e), Event.pointerY(e))
        .subtract(this.startPoint)
        .add(this.startImageOffset)
        .min({left:0, top:0})
        .max({left:this.startSize.width - this.startImageSize.width, top:this.startSize.height - this.startImageSize.height});
      this.setImageOffsetRelToMask(p);
      this.ghostImage.style.left = p.left +'px';
      this.ghostImage.style.top = p.top +'px';
    }
  },

  setTracking: function( b ) {
    if ( this.tracking === b ) {
      return;
    }

    this.tracking = b;
    if ( b ) {
      this.registerFullUndo();
      $( window.document ).observe( 'mouseup', this.mouseUpListener );
      $( window.document ).observe( 'mousemove', this.mouseMoveListener );
      this.e.addClassName('tracking');
      this.fireEvent('startMove', this.elm);
    } else {
      this.undoRegistered = false;
      $( window.document ).stopObserving( 'mouseup', this.mouseUpListener );
      $( window.document ).stopObserving( 'mousemove', this.mouseMoveListener );
      this.e.removeClassName('tracking');
      this.fireEvent('stopMove', this.elm);
    }
  },

  scaleChanged: function(e) {
    var scale = e.source.getRealValue();
    var originalSize = this.getImageContentSize();
    var maskSize = this.getMaskSize();
    var slider = e.source;

    var w = scale * originalSize.width;
    var h = scale * originalSize.height;

    this.setImageSize({
      width: Math.round(w),
      height: Math.round(h)
    });
    this.setImageOffsetRelToMask({
      left: Math.round(slider.originLeft * (w - maskSize.width)),
      top: Math.round(slider.originTop * (h - maskSize.height))
    });
  },

  getImageContentSize: function() {
    return this.elm.model.getImageContentSize();
  },

  getImageSize: function() {
    return this.elm.model.getImageSize();
  },

  setImageSize: function(size, um) {
    this.elm.model.setImageSize(size, um);
  },

  getImageOffsetRelToMask: function() {
    return this.elm.model.getImageOffsetRelToMask();
  },

  setImageOffsetRelToMask: function(offset, um) {
    this.elm.model.setImageOffsetRelToMask(offset, um);
  },

  getMaskSize: function() {
    return this.elm.model.getMaskSize();
  },

  setMaskSize: function(size, um) {
    this.elm.model.setMaskSize(size, um);
  },

  getMaskOffset: function() {
    return this.elm.model.getMaskOffset();
  },

  setMaskOffset: function(offset, um) {
    this.elm.model.setMaskOffset(offset, um);
  },


  restoreImageSize: function() {
    this.registerFullUndo();
    this.scaleSlider.setRealValue(1);
    this.setImageSize(this.getImageContentSize());
    this.setImageOffsetRelToMask({left: -this.maskInset,
                                  top: -this.maskInset});
    this.setMaskSize(this.getImageContentSize());
    var maskSize = this.getMaskSize();
    this.setMaskSize({width: maskSize.width - this.maskInset,
                      height: maskSize.height - this.maskInset});
    // TODO-TR: figure out why the following has a different effect:
    //this.createMask();
  },

  createMask: function() {
    var um = this.elm.page.undoManager;
    var maskOffset = this.getMaskOffset();
    var imageSize = this.getImageSize();

    // clip the mask if out of bounds:
    this.setMaskSize(this.getMaskSize());
    var maskSize = this.getMaskSize();
    if (imageSize.width === maskSize.width &&
        imageSize.height === maskSize.height &&
        imageSize.width > 40 && imageSize.height > 40) {
      um.startGroup();
      // TODO-TR: this doesn't work on restore-size
      this.setImageOffsetRelToMask({left: -this.maskInset,
                                    top: -this.maskInset}, um);
      this.setMaskOffset({left: maskOffset.left + this.maskInset,
                          top: maskOffset.top + this.maskInset}, um);
      this.setMaskSize({width: maskSize.width - (this.maskInset * 2),
                        height: maskSize.height - (this.maskInset * 2)},
                       um);

      um.endGroup();
    }
  },

  updateMaskScaleRange: function() {
    var originalSize = this.getImageContentSize();
    var imageOffset = this.getImageOffsetRelToMask();
    var imageSize = this.getImageSize();
    var maskSize = this.getMaskSize();

    var imageAspect = imageSize.width / imageSize.height;
    var maskAspect = maskSize.width / maskSize.height;

    this.scaleSlider.min = (imageAspect > maskAspect) ?
      maskSize.height / originalSize.height :
      maskSize.width / originalSize.width;
    this.scaleSlider.max = 2;

    this.scaleSlider.originLeft = imageSize.width === maskSize.width ?
      -0.5 :
      imageOffset.left / (imageSize.width - maskSize.width);

    this.scaleSlider.originTop = imageSize.height === maskSize.height ?
      -0.5 :
      imageOffset.top / (imageSize.height - maskSize.height);

    this.sliderUpdating = true;
    this.scaleSlider.setRealValue(imageSize.width / originalSize.width);
    this.sliderUpdating = false;
  },

  updateGhostImage: function() {
    var imageOffset = this.getImageOffsetRelToMask();
    this.ghostImage.src = this.elm.img.src;
    this.ghostImage.style.left = imageOffset.left + 'px';
    this.ghostImage.style.top = imageOffset.top + 'px';
  },

  updateGhostImageGeometry: function() {
    var size = this.getImageSize();
    var offset = this.getImageOffsetRelToMask();
    this.ghostImage.style.width = size.width + 'px';
    this.ghostImage.style.height = size.height + 'px';
    this.ghostImage.style.left = offset.left + 'px';
    this.ghostImage.style.top = offset.top + 'px';
  },

  setElement: function( elm ) {
    if (this.elm !== null) {
      this.removeElementListeners();
    }

    this.elm = elm;

    if (this.elm) {
      this.maintainARState = elm.model.get('geometry.maintainAR');
      this.updateGhostImage();
      this.addElementListeners();
      this.updatePositioning();
      this.showHandles();
      this.show();
      this.focus();
    } else {
      this.hide();
    }
  },

  addElementListeners: function(){
    this.elm.model.addListener('attributeChanged', this.elmModelListener);
    this.elm.addListener('imageChanged', this);
  },

  removeElementListeners: function(){
    this.elm.model.removeListener('attributeChanged', this.elmModelListener);
    this.elm.removeListener('imageChanged', this);
  },

  elementModelChanged: function() {
    if (this.mode === 'mask' && this.elm.exists('content.asset.size')) {
      this.updateGhostImageGeometry();
      if (!this.scaleSlider.tracking) {
        this.updateMaskScaleRange();
      }
    }

    this.positionGhost();
    this.updatePositioning();
  },

  layoutChanged: function() {
    if (this.elm === null || this.elm.type === 'lp-pom-root') {
      return;
    }
    this.updatePositioning();
  },

  contentWidthChanged: function() {
    if (this.elm === null || this.elm.type === 'lp-pom-root') {
      return;
    }
    this.updatePositioning();
  },

  showHandles: function(){
    var w = this.elm.is('width_resizeable') && this.elm.is('resizeable');
    var h = this.elm.is('height_resizeable') && this.elm.is('resizeable');
    var a = this.elm.is('offsetable');

    this.tlh.setVisible(w&&h&&a);
    this.trh.setVisible(w&&h&&a);
    this.blh.setVisible(w&&h);
    this.brh.setVisible(w&&h);

    this.tmh.setVisible(h&&a);
    this.rmh.setVisible(w);
    this.bmh.setVisible(h);
    this.lmh.setVisible(w);
  },

  updatePositioning: function() {
    var maskSize = this.getMaskSize();
    var w = maskSize.width;
    var h = maskSize.height;

    this.positionBoundingBox(w, h);
    this.positionHandles(w, h);
    this.positionRegistration(w, h);
    this.positionTools(w, h);
  },

  positionBoundingBox: function(w, h) {
    if (this.elm === null) {
      return;
    }

    this.setOffset(this.elm.getPageOffset());

    this.tbndry.style.width = w+'px';
    this.rbndry.style.height = h+'px';
    this.rbndry.style.left = (w-1)+'px';
    this.bbndry.style.width = (w-1)+'px';
    this.bbndry.style.top = (h-1)+'px';
    this.lbndry.style.height = (h-1)+'px';
  },

  positionHandles: function(w, h) {
    var offsetX = this.handleW / 2;
    var offsetY = this.handleH / 2;
    var middleX = (w / 2) - offsetX;
    var middleY = (h / 2) - offsetY;

    this.tlh.setOffset({left:-offsetX, top:-offsetY});
    this.trh.setOffset({left:w-offsetX-1, top:-offsetY});
    this.blh.setOffset({left:-offsetX, top:h-offsetY-1});
    this.brh.setOffset({left:w-offsetX-1, top:h-offsetY-1});

    this.tmh.setOffset({left:middleX, top:-offsetY});
    this.rmh.setOffset({left:w-offsetX-1, top:middleY});
    this.bmh.setOffset({left:middleX, top:h-offsetY-1});
    this.lmh.setOffset({left:-offsetX, top:middleY});
  },

  positionRegistration: function(w, h) {
    /*jshint unused:false */
    var offsetX = this.handleW / 2;
    var offsetY = this.handleH / 2;
    this.reg.setOffset({left:-offsetX, top:-offsetY});
  },

  positionTools: function(w, h) {
    this.maskPanel.setTop(h+6);
  },

  nudge: function(vector0, shift) {
    if( this.elm && this.elm.is('offsetable')) {
      var magnitude = shift ? 10 : 1;
      var vector = vector0.multiplyByScalar(magnitude);
      var maskOffset = this.getMaskOffset();

      var m = this.elm.model;
      var um = this.elm.page.undoManager;
      um.registerUndo({
        action: m.setMaskOffset,
        receiver: m,
        params: [{left:maskOffset.left, top:maskOffset.top}, um]
      });
      m.setMaskOffset({left:maskOffset.left+vector.left, top:maskOffset.top+vector.top});
    }
  },

  onkeydown: function(e) {
    switch (e.keyCode){
      case Event.KEY_LEFT:
        this.nudge(new jui.Point(-1,0), e.shiftKey);
        e.stop();
        break;
      case Event.KEY_RIGHT:
        this.nudge(new jui.Point(1,0), e.shiftKey);
        e.stop();
        break;
      case Event.KEY_UP:
        this.nudge(new jui.Point(0,-1), e.shiftKey);
        e.stop();
        break;
      case Event.KEY_DOWN:
        this.nudge(new jui.Point(0,1), e.shiftKey);
        e.stop();
        break;
      case Event.KEY_BACKSPACE:
        this.editor.removeElement();
        e.stop();
        break;
      case Event.KEY_DELETE:
        this.editor.removeElement();
        e.stop();
        break;
      case Event.KEY_RETURN:
        this.setMode('scale');
        e.stop();
        break;
      default:
        if (e.metaKey) {
          this.enterOptionMode();
        }
    }
  },

  onkeyup: function(e) {
    if (this.optionMode && !e.metaKey) {
      this.exitOptionMode();
    }
  },

  getModel: function() {
    return this.elm.model;
  },

  getStartLeft: function(){
    return this.getMaskOffset().left;
  },

  getStartTop: function(){
    return this.getMaskOffset().top;
  },

  getStartWidth: function(){
    return this.elm.getContentWidth();
  },

  getStartHeight: function(){
    return this.elm.getContentHeight();
  },

  focus: function() {
    this.editor.keyController.requestFocus(this, true);
    // this.setOpacity(1);
  },

  blur: function() {
    // this.setOpacity(0.5);
  }
});
