lp.pom.Page = Class.create( jui.EventSource, {
  version:'2.5',
  type:'lp-pom-page',
  lastTempId:0,
  javascripts:['//ajax.googleapis.com/ajax/libs/jquery/1.4.2/jquery.min.js'],
  webFonts: [],

  initialize: function(jso, context, previewURL){
    if (jso.version !== this.version) {
      throw new LP.POMError(LP.PAGE_VERSION_NEWER_ERR, '(page version is'+jso.version+' version '+this.version+'expected)');
      // throw "Incorrect page version "+jso.version+" Current version is "+this.version;
    }

    this.bodyElement = null;
    this.context = context; // edit || preview || publish
    this.previewURL = previewURL;
    this.id = jso.id === null ? lp.pom.Page.getTempId() : jso.id;
    this.page = jso.page;
    this.name = jso.name;
    this.resourceType = jso.type;
    this.shared = jso.shared;
    this.variant_id = jso.variant_id;
    this.has_form = jso.has_form;
    this.insertions = [];
    this.model = this;
    this.uuid = jso.page.uuid;

    this.maxDefault = 10000;
    this.minDefault = 16;

    this.undoManager = new jui.UndoManager();

    this.metaData = {
      type: 'lp-pom-page-meta-data',
      name:'SEO',
      title:jso.title,
      description:jso.description,
      keywords:jso.keywords
    };

    this.last_element_id = jso.last_element_id;

    var defaultSettings = {
      defaultWidth:760,
      showPageTransformBox:true,
      showSectionBoundaries:true,
      showPageSectionProtrusion: false,
      multipleBreakpointsEnabled: false
    };

    this.settings = Object.extend(defaultSettings, jso.settings);
    this.settings.activeGoals = this.settings.activeGoals || [];

    this.breakpoints = [
      {name:'desktop', default:true},
      {
        name:'mobile',
        width:600,
        minPageWidth:320,
        maxPageWidth:320,
        maxPageWidthConfirmation: 240,
        minPageWidthConfirmation: 240,
      }
    ];

    //TODO-SD Could we use swithToBreakPoint('desktop') here?
    this.currentBreakpoint = this.breakpoints.find( function(breakpoint) {
      return breakpoint.name === 'desktop';
    });

    this.styles = [];
    this.elements = jso.elements;

    this.style = new lp.pom.PageStyle(this);
    this.goals = new lp.pom.PageGoals(this);
    if (jso.goal_type !== null && jso.goal_type !== '') {
      this.goals.addActiveGoal({type:jso.goal_type, url:jso.goal_url});
    }

    this.pomReady = false;
    this.buildPOM();
  },

  buildPOM: function(jso,context) {
    var buildPageElements = function(p){
      p.elements = ( p.elements.collect( function(e) {
        var module = lp.getModule(e.type);
        if (!Object.isUndefined(module)) {
          return module.buildPageElement(p, e);
        }
        return null;
      })).compact();
    };

    var buildPageDocTree = function(p) {
      //JS: iterate through all the page elements and insert them into any containers
      p.elements.each(function(e){
        var container = p.getElementById(e.containerId);
        if( container ){
          container.insertChildElement(e);
        }
      });
    };

    buildPageElements(this,context);
    buildPageDocTree(this);
    this.pomReady = true;
    this.fireEvent('POMReady');
  },

  allowViewportMetaTag: function() {
    return lp.isResponsiveEnabled() &&
      this.settings.multipleBreakpointsVisibility ||
      !!this.settings.mobilePage;
  },

  insertPage: function(container) {
    var root = this.getRootElement();
    container.insert(root.view.e);
    if ((this.context === lp.pom.context.PUBLISH || this.context === lp.pom.context.PREVIEW)) {
      if (!!this.settings.noRobots) {
        this.insertNoRobots();
      }

      if(this.allowViewportMetaTag()) {
        this.insertViewportMetaTag();
      }
    }

    root._attach(); // if this is an _ method, why is it being called here?
    this.fireEvent('pageInserted');
    this.style.updatePageStyles();
  },

  isEditMode: function() {
    return this.context === lp.pom.context.EDIT;
  },

  isPreviewMode: function() {
    return this.context === lp.pom.context.PREVIEW;
  },

  isPublishMode: function() {
    return this.context === lp.pom.context.PUBLISH;
  },

  isPublishOrPreviewMode: function() {
    return this.isPublishMode() || this.isPreviewMode();
  },

  insertPublishedPage: function(container) {
    var root = this.getRootElement();
    container.insert(root.view.e);

    if (!!this.settings.noRobots) {
      this.insertNoRobots();
    }

    if(this.allowViewportMetaTag()) {
      this.insertViewportMetaTag();
    }

    this.getBreakPoints().each( function(breakpoint) {
      this.switchToBreakpoint(breakpoint.name);
      root._attach();
    }, this);

    this.fireEvent('pageInserted');
    this.style.updatePageStyles();
  },

  getMaxWidthForCurrentBreakpoint: function() {
    var breakpoint = this.getCurrentBreakpoint();
    if(this.usedAs() === 'form_confirmation')   {
      return breakpoint.maxPageWidthConfirmation || this.maxDefault;
    } else {
      return breakpoint.maxPageWidth || this.maxDefault;
    }
  },

  getMinWidthForCurrentBreakpoint: function() {
    var breakpoint = this.getCurrentBreakpoint();
    if(this.usedAs() === 'form_confirmation')   {
      return breakpoint.minPageWidthConfirmation || this.minDefault;
    } else {
      return breakpoint.minPageWidth || this.minDefault;
    }
  },

  getBreakPoints: function() {
    if(!lp.isResponsiveEnabled()) {
      this.breakpoints = this.breakpoints.filter(function(breakpoint){
        return breakpoint.name === 'desktop';
      });
    }
    return this.breakpoints;
  },

  getAppendParamsInsertions: function() {
    var passParamsIDs = [];
    this.elements.each(function(e){
      if(e.appendURLParams && e.appendURLParams()) {
        passParamsIDs.push(e.id);
      }
    });
    return passParamsIDs.length > 0 ?
      [{
       content: this.getAppendURLScript(passParamsIDs),
       placement: 'head'
      }] : [];
  },

  getAppendURLScript: function(idList) {
    var str = '<script type="text/javascript">\n';
    str += 'lp.jQuery(document).ready(function(){\n';
    str += "\tvar query = window.location.search.replace('?', '');\n";
    str += "\t" + this.stringifyIdArray(idList) + ".each(function(i, id) {\n";
      str += "\t\tvar target = lp.jQuery(id);\n";
      str += "\t\tvar currentHref=target.attr('href');\n";
      str += "\t\tvar modifier = new RegExp(/\\?/).test(currentHref) ? '&' : '?';\n";
      str += "\t\tvar href = currentHref+modifier+query;\n";
      str += "\t\ttarget.attr('href', href);\n";
    str += "\t});\n";
    str += "});\n";
    str += '</script>\n';
    return str;
  },

  stringifyIdArray: function(array) {
    var a = array.map(function(item){return '"#' + item + '"';});
    return 'lp.jQuery([' + a.join(',') + '])';
  },

  insertWebFonts: function() {
    return lp.getWebFonts().getWebFontsLinkTag(this.getFontsFromElements());
  },

  getWebFontsInsertions: function() {
    var fonts = this.getFontsFromElements();
    if (fonts.length === 0) {
      return [];
    }

    var fontsManager = lp.getWebFonts();

    return [
      {
        content: fontsManager.getGoogleWebFontsLoaderScriptTag(),
        placement: 'head'
      },
      {
        content: fontsManager.getWebFontsLoadScript(fonts),
        placement: 'head'
      }
    ];
  },

  removePage: function() {
    var root = this.getRootElement();
    root.view.e.remove();
    root._detach();
    this.fireEvent('pageRemoved');
    this.style.updatePageStyles();
  },

  isInserted: function() {
    return this.pomReady && this.getRootElement().isInserted();
  },

  insertNoRobots: function() {
    var head = document.getElementsByTagName("head")[0];
    var node = document.createElement('meta');
    node.setAttribute('name', 'robots');
    node.setAttribute('content', 'noindex, nofollow');
    head.appendChild(node);
  },

  insertViewportMetaTag: function() {
    var head = document.getElementsByTagName("head")[0];
    var node = document.createElement('meta');
    node.setAttribute('name', 'viewport');
    node.setAttribute('content', 'width=device-width, initial-scale=1.0');
    head.appendChild(node);
  },

  addBreakpoint: function(breakpoint) {
    this.breakpoints.push(breakpoint);
    this.fireEvent('breakpointAdded', breakpoint);
  },

  hasManyBreakpoints: function() {
    return this.breakpoints.length > 1;
  },

  hasBreakpoint: function(name) {
    return typeof this.breakpoints.find( function(breakpoint) {return breakpoint.name === name;}) !== 'undefined';
  },

  switchToBreakpoint: function(name) {
    this.fireEvent('beforeBreakpointChanged', name);

    var breakpoint = this.getBreakpointByName(name);
    if (typeof breakpoint === 'undefined') {
      return;
    }

    this.undoManager.registerUndo({
      action:this.switchToBreakpoint,
      receiver:this,
      params: [this.currentBreakpoint.name]
    });

    this.currentBreakpoint = breakpoint;
    this.updateAndRefresh();
    this.fireEvent('breakpointChanged', name);
  },

  updateAndRefresh: function() {
    document.activeElement.blur();
    var currentWidth = this.getRootElement().model.get('geometry.contentWidth');
    var maxPageWidth = this.getMaxWidthForCurrentBreakpoint();
    var minPageWidth = this.getMinWidthForCurrentBreakpoint();

    if (currentWidth < minPageWidth) {
      this.getRootElement().model.set('geometry.contentWidth', this.getMinWidthForCurrentBreakpoint());
    } else if (currentWidth > maxPageWidth) {
      this.getRootElement().model.set('geometry.contentWidth', this.getMaxWidthForCurrentBreakpoint());
    }

    this.updateElementGeometry();
    this.updateElementStyles();
    this.fireEvent('pageUpdatedAndRefreshed');
  },

  isDefaultBreakpoint: function(){
    return this.currentBreakpoint.default === true;
  },

  //TODO: Grep for all uses of this and replace with isMobileBreakpoint() or
  //isDesktopBreakpoint()
  getCurrentBreakpoint: function() {
    return this.currentBreakpoint;
  },

  //TODO: Make private
  isCurrentBreakpoint: function(name) {
    return this.getCurrentBreakpoint().name === name;
  },

  isMobileBreakpoint: function() {
    return this.isCurrentBreakpoint('mobile');
  },

  isDesktopBreakpoint: function() {
    return this.isCurrentBreakpoint('desktop');
  },

  getBreakpointByName: function(name) {
    return this.breakpoints.find( function(breakpoint){return breakpoint.name === name;});
  },

  updateElementStyles: function() {
    this.elements.each(function(elm) {
      if (typeof elm.applyStyleAttributes === 'function') {
        elm.applyStyleAttributes();
      }
    });
    this.getRootElement().applyDefaultStyles();
  },

  updateElementGeometry: function() {
    this.elements.each(function(elm) {
      if (typeof elm.updateElementGeometry === 'function') {
        elm.updateElementGeometry();
      }
    });
  },

  getRootElement: function(){
    return this.getElementById('lp-pom-root');
  },

  getElementByIndex: function(index) {
    return this.elements[index];
  },

  getNextElementId: function() {
    this.last_element_id += 1;
    return this.last_element_id;
  },

  insertElement: function(elm, options){
    this.undoManager.registerUndo({
      action:elm.removeFromPage,
      receiver:elm
    });

    options = options || {};
    elm.id = elm.id || elm.type+"-"+this.getNextElementId();

    if (typeof options.index !== 'undefined' && options.index < this.elements.length) {
      this.elements.splice(options.index, 0, elm);
    } else if (options.container &&
               typeof options.containerIndex !== 'undefined' &&
              options.containerIndex < options.container.childElements.length) {

      var i = this.elements.indexOf(
        options.container.childElements[options.containerIndex]
      );

      this.elements.splice(i, 0, elm);

    } else {
      this.elements.push(elm);
    }


    if (options.container){
      options.container.insertChildElement(elm, options);
    }
    // elm.afterInsert();

    if (elm.type === 'lp-pom-form') {
      this.has_form = true;
    }
    if(options.silent){
      return;
    }

    this.fireEvent('elementInserted', elm);
  },

  updateElement: function(elm) {
    this.fireEvent('elementInserted', elm);
  },

  removeElement: function(elm, fromParent){
    this.elements = this.elements.without(elm);
    if (elm.type === 'lp-pom-form') {
      this.has_form = false;
    }
    this.fireEvent('elementRemoved', {element:elm, parentElement:fromParent});
  },

  swapElementOrder: function(a,b) {
    var aIndex = this.elements.indexOf(a);
    var bIndex = this.elements.indexOf(b);
    this.elements[aIndex] = b;
    this.elements[bIndex] = a;
  },

  hasForm: function() {
    return this.has_form;
  },

  hasContent: function() {
    return this.getRootElement().childElements.length > 0;
  },

  getPreviewURL: function(){
    return this.page.preview_url;
  },

  getDefaultWidth: function() {
    return this.settings.defaultWidth * 1;
  },

  isLinkUnmodifiable: function(link){
    /* jshint scripturl:true */
    return link.startsWith('mailto:') || link.startsWith('javascript:') || link.startsWith('#');
  },

  isLinkModifiable: function(link) {
    return !this.isLinkUnmodifiable(link);
  },

  isLinkTelephoneOrSms: function(link) {
    return link.startsWith('tel:') || link.startsWith('sms:');
  },

  getClickTypeForLink: function(link) {
    return this.goals.urlIsActiveGoal(link) ? 'clkg' : 'clkn';
  },

  reformatUrl: function(link, delimiter){
    var parts, path, protocol;
    parts = link.split(delimiter);
    path = parts.slice(1).join(delimiter);
    protocol = parts[0];
    return '/'+protocol+'/'+path;
  },

  decorateUrlForCta: function(link) {
    if(this.isLinkUnmodifiable(link)) { return link; }
    var formattedLink;
    if(this.isLinkTelephoneOrSms(link)) {
      formattedLink = this.reformatUrl(link,':');
    } else {
      formattedLink = this.reformatUrl(link,'://');
    }
    return this.getClickTypeForLink(link)+formattedLink;
  },

  CTAifyLink: function(link) {
    if (link.startsWith(window.location.href+'#')) {
      return link.substr(link.indexOf('#'));
    }

    if(this.context !== lp.pom.context.PUBLISH || this.isLinkUnmodifiable(link)) {
      return link;
    }

    return this.decorateUrlForCta(link);
  },

  decorateImageSrc: function(src) {
    return (this.context === lp.pom.context.PUBLISH || this.context === lp.pom.context.PREVIEW) ? '/publish'+src : src;
  },

  getElementCount: function() {
    return this.elements.length;
  },

  getLastElement: function() {
    return this.elements.last();
  },

  getElementTypes: function() {
    return this.elements.pluck('type').uniq();
  },

  getElementById: function(id) {
    return this.elements.find(function(e){return e.id === id;});
  },

  getElementsByType: function(type) {
    return this.elements.select(function(e){return e.type === type;});
  },

  getForm: function() {
    return this.getElementsByType('lp-pom-form').first();
  },

  getElementsByTypeAndContainerId: function(type, id){
    return this.elements.select(function(e){return e.containerId === id && e.type === type;});
  },

  indexOfElement: function(elm) {
    return this.elements.indexOf(elm);
  },

  getVisibleElements: function() {
   return this.elements.select(function(e){return e.view;});
  },

  getElementsWithFonts: function() {
    //If the element responds to getFonts return it.
    return this.elements.select(function(e) {
      return typeof e.getFonts === 'function';
    });
  },

  getFontsFromElements: function() {
    var fontList = [];
    this.getElementsWithFonts().each(function(e) {
      fontList.push(e.getFonts());
    });
    return fontList.flatten().sort().uniq(true);
  },

  generateDefaultElementName: function(type, base, copy) {
    copy = copy || false;

    var withDefaultNames;
    var copyIndex = base.indexOf('copy');
    if (copyIndex > -1) {
      base = base.substr(0, copyIndex-1);
    }

    withDefaultNames = this.getElementsByType(type)
      .pluck('name').grep(new RegExp(base+(copy ? "\\scopy" : '')+"\\s\\d+"));

    var next;

    if (withDefaultNames.length > 0) {
      next = withDefaultNames.max(function(name) {
        var arr = name.split(' ');
        return arr[arr.length-1] * 1 + 1;
      });
    } else {
      next = 1;
      base = base.sub(/copy\s\d+/, '');
    }

    return base+' '+(copy ? 'copy ' : '')+next;
  },

  hasSections: function() {
    return this.getElementsByType('lp-pom-block').length > 0;
  },

  addInsertion: function(content, placement) {
    this.insertions.push({
      content: content,
      placement: placement
    });
  },

  getInsertions: function() {
    return this.insertions;
  },

  getJavascriptApiCode: function() {
    var str = '<script type="text/javascript" src="' + gon.javascript_api_url + '"></script>';
    str += '<script type="text/javascript">\n';
    str += 'window.ub.page.id = "' + this.uuid + '";\n';
    str += 'window.ub.page.variantId = "' + this.variant_id + '";\n';
    str += 'window.ub.page.name = "' + gon.page_name + '";\n';
    str += '</script>\n';

    return str;
  },

  getJavascriptApi: function() {
    return {
      content: this.getJavascriptApiCode(),
      placement: 'head'
    };
  },

  getNoConflictModeCode: function() {
    var str = '<script type="text/javascript">\n';
    str += 'var lp = lp || {};\n';
    str += 'lp.jQuery = jQuery.noConflict( true );\n';
    str += '</script>\n';
    return str;
  },

  getNoConflictMode: function() {
    return {
      content: this.getNoConflictModeCode(),
      placement: 'head'
    };
  },

  getIE7SpanRuleInsertion: function() {
    var content = '<!--[if IE 7]>\n';
    content += '<script>\n';
    content += '(function() {\n';
    content += '  var sheet = document.styleSheets[1];\n';
    content += '  var rules = sheet.rules;\n';
    content += '  var rule;\n';
    content += '  var index = -1;\n';

    content += '  for (var i=0, l=rules.length; i<l; i++){\n';
    content += '    rule = rules[i];\n';

    content += '    if (rule.selectorText.toLowerCase() == \'div.lp-pom-root .lp-pom-text span\') {\n';
    content += '      index = i;\n';
    content += '      break;\n';
    content += '    }\n';
    content += '  }\n';

    content += '  if (index > -1) {\n';
    content += '    sheet.removeRule(index);\n';
    content += '    sheet.addRule(\'div.lp-pom-root .lp-pom-text span\', \'line-height:inherit\');\n';
    content += '  }\n';
    content += '})();\n';
    content += '</script>\n';

    content += '<![endif]-->\n';

    return {
      content: content,
      placement: 'head'
    };
  },

  debugPageInsertions: function() {
    this.getPageInsertions().each( function(i) {
      console.log(i);
    });
  },

  getPageInsertions: function() {
    // TODO-TR: split getPageInsertions out into a separate utils module.
    var insertions = [].concat(
      this.getModuleStylesheetInsertions(),

      this.getPageJavascriptInsertions(),
      this.getJQueryPlugins(),

      this.getJavascriptApi(),
      this.getNoConflictMode(),

      this.getModuleJavascriptInsertions(),
      this.getInsertions(),

      this.getWebFontsInsertions(),
      this.getAppendParamsInsertions(),

      this.getIE7SpanRuleInsertion());

    // add html comment markers denoting the start and end of each placement:
    var groupedInsertions = {'head': [], 'body:before':[], 'body:after':[]};
    var addToGroup = function (insertion) {
      groupedInsertions[insertion.placement] = groupedInsertions[insertion.placement] || [];
      groupedInsertions[insertion.placement].push({
        content: insertion.content,
        placement: insertion.placement
      });
    };
    // add all our standard insertions to groupedInsertions:
    insertions.forEach(addToGroup);

    // add comment markers around each 'placement' of insertions:
    var flattenInsertions = function(insertions) {
      var flattenedInsertions = [];
      var add = function (elem) {
          flattenedInsertions.push(elem);
      };
      var startMarker, endMarker;
      for (var placement in insertions) {
        startMarker = 'start';
        endMarker = 'end';
        if (placement.indexOf('before') !== -1) {
          // The lp-publisher code reverses these.
          // It *prepends* elements to the top of body:before.
          startMarker = 'end';
          endMarker = 'start';
        }
        flattenedInsertions.push(
          {placement: placement,
           content: '<!-- lp:insertions ' + startMarker + ' ' + placement + ' -->'});
        insertions[placement].forEach(add);
        flattenedInsertions.push(
          {placement: placement,
           content: '<!-- lp:insertions ' + endMarker + ' ' + placement + ' -->'});
      }
      return flattenedInsertions;
    };
    //
    return flattenInsertions(groupedInsertions);
  },

  topMost: function(elm1, elm2) {
    if (elm1.page !== elm2.page || elm1.parentElement === null || elm2.parentElement === null) {
      return;
    }

    var e1 = elm1;
    var e2 = elm2;
    var d1 = elm1.getRootDistance();
    var d2 = elm2.getRootDistance();

    if (d1 < d2) {
      e2 = elm2.getAncestorAtDistance(d1);
      if (e2 === e1 || d1 < 2) {
        return elm2;
      }
    } else if (d2 < d1) {
      e1 = elm1.getAncestorAtDistance(d2);
      if (e2 === e1 || d2 < 2) {
        return elm1;
      }
    }

    var z1 = e1.model.getZIndex() || 0;
    var z2 = e2.model.getZIndex() || 0;

    if (z1 > z2) {
      return elm1;
    }

    if (z2 > z1) {
      return elm2;
    }

    var i1 = this.elements.indexOf(e1);
    var i2 = this.elements.indexOf(e2);

    return (i1 > i2) ? elm1 : elm2;
  },

  getPageJavascriptInsertions: function() {
    var urls = [];

    this.javascripts.each(function(url) {
      urls.push(url);
    });

    urls = urls.uniq();

    return urls.collect( function(url) {
      return {
        placement: 'head',
        content: '<script src="'+url+'" type="text/javascript"></script>'
      };
    });
  },

  getJQueryPlugins: function() {
    var urls = [];
    this.getElementTypes().each( function( type ) {
      var module = lp.getModule( type );
      if( Object.isArray( module.jQueryPlugins ) ) {
        module.jQueryPlugins.each( function( url ) {
          urls.push( url );
        } );
      }
    } );

    urls = urls.uniq();

    return urls.collect( function( url )  {
      return {
        placement: 'head',
        content: '<script src="'+ url +'" type="text/javascript"></script>'
      };
    } );
  },

  getModuleJavascriptInsertions: function() {
    var urls = [];

    this.getElementTypes().each(function(type) {
      var module = lp.getModule(type);
      if (Object.isArray(module.javascripts)) {
        module.javascripts.each(function(url) {
          urls.push( url );
        });
      }
    });

    urls = urls.uniq();

    return urls.collect( function(url) {
      return {
        placement: 'head',
        content: '<script src="'+url+'" type="text/javascript"></script>'
      };
    });
  },

  getModuleStylesheetInsertions: function() {
    var urls = [];

    this.getElementTypes().each( function(type) {
      var module = lp.getModule(type);
      if (Object.isArray(module.stylesheets)) {
        module.stylesheets.each( function(url) {
          urls.push(url);
        });
      }
    });

    urls = urls.uniq();

    return urls.collect( function(url) {
      return {
        placement: 'head',
        content: '<link rel="stylesheet" media="screen" href="'+url+'" type="text/css" />'
      };
    });
  },

  getAttachments: function() {
    var attachments = [];
    this.getElementsByType("lp-pom-button").each( function(b) {
      if (b.model.exists("action") && b.model.get("action.type") === "download") {
        attachments.push(b.model.get("action.asset"));
      }
    });
    return attachments;
  },

  getDimensions: function(breakPointName) {
    var breakpoint = breakPointName ? this.getBreakpointByName(breakPointName) :
      this.getCurrentBreakpoint();

    var w     = this.getRootElement().model.get('geometry.contentWidth', breakpoint)
      , baseW = w
      , h     = 0;
    this.getRootElement().childElements.each( function(section) {
      h += section.model.getHeight(breakpoint);

      if (section.hasBorder() && section.model.get('geometry.borderLocation', breakpoint) === 'outside') {
        var sectionW = baseW;
        sectionW += (section.exists('geometry.borderApply') ? (section.get('geometry.borderApply.left', breakpoint) ? section.getBorderWidth() : 0 ): section.getBorderWidth()) * 1;
        sectionW += (section.exists('geometry.borderApply') ? (section.get('geometry.borderApply.right', breakpoint) ? section.getBorderWidth() : 0 ): section.getBorderWidth()) * 1;
        h += (section.exists('geometry.borderApply') ? (section.get('geometry.borderApply.top', breakpoint) ? section.getBorderWidth() : 0 ): section.getBorderWidth()) * 1;
        h += (section.exists('geometry.borderApply') ? (section.get('geometry.borderApply.bottom', breakpoint) ? section.getBorderWidth() : 0 ): section.getBorderWidth()) * 1;
        w = Math.max(w, sectionW);
      }
    });

    return {width:w, height:h};
  },

  getContentWidth: function() {
    return this.getRootElement().getContentWidth();
  },

  usedAs: function() {
    return this.page.used_as;
  },

  isMain: function() {
    return this.usedAs() === 'main';
  },

  isFormConfirmation: function() {
    return this.usedAs() === 'form_confirmation';
  },

  isMobileFormConfirmation: function() {
    return this.isFormConfirmation() && this.isCurrentBreakpoint('mobile');
  },

  getUsedAsText: function() {
    if (Object.isUndefined(this.page)) {
      return 'Main Page';
    }

    return this.page.used_as.split('_').collect( function(word) {
      return word.capitalize();
    }).join(' ')+' '+(this.page.used_as === 'form_confirmation' ? 'Dialog' : 'Page');
  },

  allowSectionMargins: function() {
    return this.usedAs() !== 'form_confirmation';
  },

  allowTopPadding: function() {
    return this.usedAs() !== 'form_confirmation';
  },

  toJSO: function() {
    var cta = this.cta;
    if (cta === '' || cta === null) {
      var urls = this.getURLs('link');
      if (urls.length > 0) {
        cta = urls[0];
      }
    }

    var jso = {
      version: this.version,
      name: this.name,
      shared: this.shared,
      last_element_id:this.last_element_id,
      goal_url:null,
      goal_type:null,
      has_form:this.hasForm(),
      title: this.metaData.title,
      description: this.metaData.description,
      keywords: this.metaData.keywords,
      settings: this.settings,
      styles: this.styles,
      elements:[]
    };

    this.elements.each(function(e){
      jso.elements.push(e.toJSO());
    });

    return jso;
  },

  prepareForSave: function() {
    this.fireEvent('beforePageSave', this.page);
  },

  toXML: function() {
    var elements = [];
    var attachments = this.getAttachments();

    this.elements.each(function(e){
      elements.push(e.toJSO());
    });

    /* jshint multistr:true */
    var xml = '<'+this.resourceType.underscore()+'>'+
        (Object.isUndefined(this.page) ?
        '' :
        '<page> \
          <id>'+this.page.id+'</id> \
          <name>'+this.page.name+'</name> \
          <used_as>'+this.page.used_as+'</used_as>'+
          (Object.isUndefined(this.page.path_name) ? '' : '<path_name>'+this.page.path_name+'</path_name>')+
        '</page>')+
        '<id>'+this.id+'</id> \
         <version>'+this.version+'</version> \
         <name><![CDATA['+this.name+']]></name> \
         <last_element_id>'+this.last_element_id+'</last_element_id> \
         <has_form>'+this.has_form+'</has_form> \
         <title><![CDATA['+this.metaData.title+']]></title> \
         <description><![CDATA['+this.metaData.description+']]></description> \
         <keywords><![CDATA['+this.metaData.keywords+']]></keywords> \
         <settings><![CDATA['+Object.toJSON(this.settings)+']]></settings> \
         <elements><![CDATA['+Object.toJSON(elements).gsub(']]>',']]]]><![CDATA[>')+']]></elements>';

                  // <styles><![CDATA['+this.styles.toJSON()+']]></styles> \

    if (attachments.length > 0) {
      attachments.each( function(a) {
        xml += '<add_attachment> \
                <asset_id>'+a.id+'</asset_id> \
                <protected_download>'+a.protected_download+'</protected_download> \
                </add_attachment>';
      });
    }

    xml += '</'+this.resourceType.underscore()+'>';

    return xml;
  }
});

lp.pom.Page.lastTempId = 0;

lp.pom.Page.getTempId = function() {
  lp.pom.Page.lastTempId += 1;
  return 'temp_id_'+lp.pom.Page.lastTempId;
};
