lp.module.box = {};

lp.module.box.BoxModule = Class.create(
  lp.ModuleBase,
  lp.ModuleElementMethods,
  lp.ModuleElementPropertiesPanelMethods,
  {

    
    beforeCreate: function() {
      // console.log('beforeCreate');
    },
    
    afterInsert: function() {
      // console.log('afterInsert');
    }
  }
);

lp.addModule(new lp.module.box.BoxModule(
  {
    type:'lp-pom-box',
    version:'1.0',
    name: 'Box',
    usageType:'page-widget',
    namespace: lp.module.box
  }
));