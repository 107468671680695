/* globals exports, module, define */
 (function(root, fn) {
   if (typeof exports === 'object') {
     var ModelAttributeExtractor = fn();
     exports.ModelAttributeExtractor = ModelAttributeExtractor;
     module.exports = ModelAttributeExtractor;
   } else if (typeof 'define' === 'function' && define.amd) {
     define(fn);
   } else {
     root.lp.module.form.FormElement.ModelAttributeExtractor = fn();
   }
 })(this, function() {
   var ModelAttributeExtractor = function(model) {
     this.model = model;
   };

   ModelAttributeExtractor.TransformationMap = {
     fields: '',
     groups: 'group_',
     labels: 'label_',
     containers: 'container_'
   };

   ModelAttributeExtractor.prototype = (function() {
     var _toStyler = function(model) {
       return $H(ModelAttributeExtractor.TransformationMap).map(function(item) {
         if (model.exists(item.key)) {
           return {
             elements: model.get(item.key),
             idPrefix: item.value
           };
         }
       }).compact();
     };

     return {
       toStyler: function() {
         return _toStyler(this.model);
       }
     };
   })();

   return ModelAttributeExtractor;
 });
