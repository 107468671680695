lp.editor.ContainerHilite = Class.create( jui.Component, {
  options: function($super,options){
    // for now the editor is a jui.Component that grabs the 'content' div as it's element
    return $super($H({attributes:{className:'container-hilite'}}).merge(options));
  },

  initialize: function( $super, editor, options ){
    this.editor = editor;
    $super( options );
    this.xIndicator = new Element( 'div', {className:'x-indicator'});
    this.yIndicator = new Element( 'div', {className:'y-indicator'});

    this.editor.transformBox.addListener('startMove', this);
    this.editor.transformBox.addListener('move', this);
    this.editor.transformBox.addListener('stopMove', this);
  },

  installUI: function( $super, options ) {
    $super( options );
    this.tbndry = this.insert( new Element('div', {className:'hilite-boundary top'}));
    this.rbndry = this.insert( new Element('div', {className:'hilite-boundary right'}));
    this.bbndry = this.insert( new Element('div', {className:'hilite-boundary bottom'}));
    this.lbndry = this.insert( new Element('div', {className:'hilite-boundary left'}));
    this.setOpacity( 0.5 );
  },

  startMove: function(e) {
    this.positionBoundingBox(e.data);
    this.show();
  },

  move: function(e) {
    this.positionBoundingBox(e.data);
  },

  stopMove: function() {
    this.hide();
  },

  positionBoundingBox: function(elm) {
    var container = elm.getParentElement();
    this.setOffset(container.getPageOffset());

    var w = container.getContentWidth();
    var h = container.getContentHeight();

    this.tbndry.style.width = (w+8)+'px';
    this.tbndry.style.top = '-4px';
    this.tbndry.style.left = '-4px';
    this.rbndry.style.height = h+'px';
    this.rbndry.style.left = (w)+'px';
    this.bbndry.style.width = (w+8)+'px';
    this.bbndry.style.top = (h)+'px';
    this.bbndry.style.left = '-4px';
    this.lbndry.style.height = (h)+'px';
    this.lbndry.style.left = (-4)+'px';
  }
});
