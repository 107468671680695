lp.module.block = {};

lp.module.block.BlockModule = Class.create(
  lp.ModuleBase,
  lp.ModuleElementMethods,
  lp.ModuleElementPropertiesPanelMethods,
  {
    beforeCreate: function(page, defaults, options) {
      defaults.geometry.size = options.container.getDefaultSectionSize();
    }
  }
);

lp.addModule(new lp.module.block.BlockModule(
  {
    type:'lp-pom-block',
    version:'1.0',
    name: 'Page Section',
    usageType:'page-widget',
    namespace: lp.module.block
  }
));