lp.pom.updates.add(Object.extend({
  minVersion:'1.4',
  version:'1.5',
  run: function(jso) {
    if (this.checkVersion(jso)) {
      this.correctAssetData(jso);
      jso.version = this.version;
    }
  },

  correctAssetData: function(jso) {
    jso.elements.each(function(e) {
      var url;
      switch (e.type) {
        case 'lp-pom-image':
          if (!Object.isUndefined(e.content.src)) {
            url = e.content.src.replace('image_assets', 'assets');

            e.content.asset = {
              uuid: this.getAssetIdFromURL(url),
              content_url: url,
              name: this.getAssetNameFromURL(url)
            };

            delete e.content.assetId;
            delete e.content.src;
          }
          break;
        case 'lp-pom-button':
          if (e.style.up && e.style.up.image) {
            url = e.style.up.image.replace('image_assets', 'assets');
            e.style.up.image = {
              uuid: this.getAssetIdFromURL(url),
              content_url: url,
              name: this.getAssetNameFromURL(url)
            };
          }

          if (e.style.hover && e.style.hover.image) {
            url = e.style.hover.image.replace('image_assets', 'assets');
            e.style.hover.image = {
              uuid: this.getAssetIdFromURL(url),
              content_url: url,
              name: this.getAssetNameFromURL(url)
            };
          }

          if (e.style.active && e.style.active.image) {
            url = e.style.active.image.replace('image_assets', 'assets');
            e.style.active.image = {
              uuid: this.getAssetIdFromURL(url),
              content_url: url,
              name: this.getAssetNameFromURL(url)
            };
          }

          if (Object.isUndefined(e.content.action)) {
            e.content.action = 'url';
          }

          break;

        default:
          if (e.style && e.style.background && e.style.background.backgroundImage) {
            url = e.style.background.backgroundImage.replace('image_assets', 'assets');
            url = '"' + url +'"';
            while (url.indexOf('"') > -1) {
              url = url.replace('"', '');
            }
            e.style.background.image = {
              uuid: this.getAssetIdFromURL(url),
              content_url: url,
              name: this.getAssetNameFromURL(url)
            };
            delete e.style.background.backgroundImage;
          }


      }
    }, this);
  },

  getAssetNameFromURL: function(url) {
    var arr = url.split('/');
    return arr[arr.length-1].split('?')[0].replace('.original','');
  },

  getAssetIdFromURL: function(url) {
    var arr = url.split('/');
    return arr[2];
  }
}, lp.pom.updater));
