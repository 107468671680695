lp.pom.updates.add(Object.extend({
  minVersion:'1.5',
  version:'1.6',
  run: function(jso) {
    if (this.checkVersion(jso)) {
      this.correctButtonData(jso);
      jso.version = this.version;
    }
  },

  correctButtonData: function(jso) {
    jso.elements.each(function(e) {
      if (e.type === 'lp-pom-button') {
        e.action = {};
        e.action.type = e.content.action;

        delete e.content.action;
        delete e.content.transform;

        switch (e.action.type) {
        case 'url':
          e.action.url = e.content.link || '';
          delete e.content.link;
          break;
        case 'form':
          delete e.content.link;
          break;
        case 'download':
          e.action.asset = e.content.downloadAsset;
          delete e.content.downloadAsset;
          delete e.content.link;
          break;
        }
      }
    }, this);
  }
}, lp.pom.updater));
