lp.module.button = {};

lp.module.button.ButtonModule = Class.create(
  lp.ModuleBase,
  lp.ModuleElementMethods,
  lp.ModuleElementPropertiesPanelMethods,
  {
    javascripts: ['//d2xxq4ijfwetlm.cloudfront.net/m/lp-webapp/0.0.9/lp-button/2.1/main.js']
  }
);

lp.addModule(new lp.module.button.ButtonModule(
  {
    type:'lp-pom-button',
    version:'1.0',
    name: 'Button',
    usageType:'page-widget',
    namespace: lp.module.button
  }
));
