lp.module.form.LabelWidthControlTransformBoxExtension = Class.create(jui.Component, {
  options: function($super,options){
    return $super($H({attributes:{className:'form-label-width-indicator'}}).merge(options));
  },

  initialize: function($super) {
    this.elm = null;
    this.tracking = false;
    this.startOffset = 0;
    this.undoRegistered = false;

    $super();

    this.observe('mousedown', this.mousedown.bindAsEventListener(this));
    this.mouseMoveListener = this.mousemove.bindAsEventListener(this);
    this.mouseUpListener = this.mouseup.bindAsEventListener(this);
  },

  installUI: function($super) {
    $super();
    this.arrow = this.insert(new Element('div', {className:'arrow'}));
  },

  updateUI: function() {
    if (this.elm.model.get('geometry.label.alignment') === 'left') {
      this.setOffset({left: this.elm.getCalculatedLabelWidth() - 4, top: -5});
      this.setHeight(this.elm.model.getHeight() + 2);
      this.show();
    } else {
      this.hide();
    }
  },

  mousedown: function(e) {
    this.tracking = true;
    this.startOffset = Event.pointerX(e) - this.elm.getCalculatedLabelWidth();

    if (!this.undoRegistered) {
      this.undoRegistered = true;
      if (this.elm.isAutoCalculateLabelWidth()) {
        this.elm.page.undoManager.registerUndo({
          action:this.elm.setAutoCalculateLabelWidth,
          receiver:this.elm,
          params:[this.elm.getUndoManager()]
        });
      } else {
        this.elm.getUndoManager().registerUndo({
          action:this.elm.setLabelWidthExplicitly,
          receiver:this.elm,
          params:[this.elm.getCalculatedLabelWidth(), this.elm.getUndoManager()]
        });
      }
    }

    $(document).observe( 'mouseup', this.mouseUpListener);
    $(document).observe( 'mousemove', this.mouseMoveListener);
  },

  mouseup: function(e) {
    Event.stop(e);
    this.tracking = false;
    this.undoRegistered = false;
    $(document).stopObserving( 'mouseup', this.mouseUpListener);
    $(document).stopObserving( 'mousemove', this.mouseMoveListener);
  },

  mousemove: function(e) {
    this.elm.setLabelWidthExplicitly(Event.pointerX(e) - this.startOffset);
  },

  setElement: function(elm){
    this.elm = elm;
    this.updateUI();
  }
});