// require <jui>
jui.FormNumericInput = Class.create( jui.FormTextInput, {
  options: function($super, options) {
    return $super($H({
      attributes: {
        className: 'form-elm numeric-input'
      },
      label:'',
      inputFilters: [jui.NumericInputFilter]
    }).merge(options));

  }
});
