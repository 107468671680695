/* globals exports, module, define*/
(function(root, fn) {
  if (typeof exports === 'object') {
    var Views = fn();
    exports.FormElementViews = exports.FormElementViews || {};
    exports.FormElementViews = Views;
    module.exports = exports;
  } else if (typeof 'define' === 'function' && define.amd) {
    define(fn);
  } else {
    root.lp.module.form.FormElementViews = fn();
  }
})(this, function() {
  var FormElementViews = function(formElement) {
    this.formElement = formElement;
  };

  FormElementViews.prototype = {
    all: function() {
      var formElement = this.formElement;

      var fieldCleaner = new lp.module.form.FormElement.FieldCleaner(this.formElement.model, this.formElement.fieldAttributeTypes());

      var calcLabelHeight = function(label, g, f, forcePreviewPublishMode) {
        if(f.hideLabel) {
          return 0;
        } else if(!!forcePreviewPublishMode || formElement.page.isPublishOrPreviewMode()) {
          return f.labelHeight + g.labelMargin.bottom;
        } else {
          return label.getHeight() + g.labelMargin.bottom;
        }
      };

      var fieldViewBase = {
        clearLabelSize: function(f) {
          if (!(f.hideLabel || f.lpType === 'hidden')) {
            var label = this.root.down('label');
            label.style.width = 'auto';
            label.style.height = 'auto';
          }
        },
        getUnwrappedLabelWidth: function(f) {
          if (f.hideLabel || f.lpType === 'hidden') {
            return 0;
          } else {
            var fieldWidth = this.root.style.width;
            /* JS: set the field width to an arbritrary large number
               so that the label can find its natural size
               */
            this.root.style.width = '10000px';
            var width = this.root.down('label').getWidth();
            this.root.style.width = fieldWidth;

            return width;
          }
        },

        getLabelHeight: function(f) {
          return f.hideLabel ||
            f.lpType === 'hidden' ? 0 : this.root.down('label').getHeight();
        }
      };

      var _geometryStyleWhitelist = function() {
        return [
          'width',
          'height',
          'top',
          'left',
          'font-size',
          'line-height',
          'padding',
          'padding-left',
          'padding-right',
          'padding-top',
          'padding-bottom',
          'margin',
          'margin-left',
          'margin-right',
          'margin-top',
          'margin-bottom'
        ];
      };

      var _setStyle = function(id, model, mappings) {
        //mappings is an object with a value which are the inline styles on the dom
        //elements. Key is the label "prefix" to be used in the setter to generate the
        //style accessor.
        var setter = new lp.module.form.FormElement.Styler.Setter(id, _geometryStyleWhitelist())
        .onSet(function(arg){
          if (arg.hasKeyValue) {
            var undoManager = null;
            if(formElement.modelUpdateable) {
              model.set(arg.accessor,arg.value,undoManager);
            }
          }
        });

        $H(mappings).each(function(item) {
          if(item.value) {
            setter.execute(item.value, item.key);
          }
        });
      };

      var _getStyle = function(id, model) {
        return new lp.module.form.FormElement.Styler.Getter(id)
          .getRulesForElementTypes(new lp.module.form.FormElement.ModelAttributeExtractor(model).toStyler());
      };

      var extraClassName = function (field) {
        return 'form_elem_' + field.id;
      };

      return [{
          types: ['single-line-text', 'email'],
          view: function(f) {
            var root = new Element('div', {
              className: 'lp-pom-form-field clearfix'
            });
            if (!f.hideLabel) {
              root.insert(new Element('label', {
                'for': f.id,
                className: 'main'
              }).update(
                f.validations.required ? f.name + '&nbsp;*' : f.name
              ));
            }
            root.insert(new Element('input', {
              id: f.id,
              name: f.id,
              type: 'text',
              className: 'text ' + extraClassName(f)
            }));
            return Object.extend({
              root: root,
              adjustGeometry: function(g, f) {

                if (formElement.isAdjustGeometryNeeded()) {

                  var top = g.labelAlign === 'top';
                  var label = f.hideLabel ? null : this.root.down('label.main');
                  var lblHeight = calcLabelHeight(label, g, f);
                  var input = this.root.down('input');

                  this.root.style.width = g.formWidth + 'px';

                  if (label !== null) {
                    var leftLabelWidth = g.leftLabelWidth;
                    var labelWidth = leftLabelWidth > 0 ? leftLabelWidth + 'px' : 'auto';
                    label.style.top = '0px';
                    label.style.width = labelWidth;
                  }

                  var calculateInputWidth = function(geometry, top, marginRight) {
                    var padding = geometry.fieldPaddingTop,
                      formWidth = geometry.formWidth,
                      fieldBorder = geometry.fieldBorder,
                      labelWidth = geometry.leftLabelWidth,
                      borderAndPadding = fieldBorder + (padding * 2);

                    if (top) {
                      return formWidth - fieldBorder - (padding * 2);
                    } else {
                      return formWidth - (borderAndPadding + labelWidth + marginRight);
                    }
                  };

                  var marginRight = (g.leftLabelWidth === 0 ? 0 : g.labelMargin.right);

                  input.style.top = (top ? lblHeight : 0) + 'px';
                  input.style.left = (top ? 0 : (g.leftLabelWidth + marginRight)) + 'px';
                  input.style.width = calculateInputWidth(g, top, marginRight) + 'px';
                  input.style.fontSize = g.fieldFontSize + 'px';
                  input.style.lineHeight = g.fieldFontSize + 'px';
                  input.style.height = g.fieldHeight + 'px';
                  input.style.paddingLeft = g.fieldPaddingTop + 'px';
                  input.style.paddingRight = g.fieldPaddingTop + 'px';

                  var buffer = 4;

                  this.root.style.height = (top ?
                    (lblHeight + g.fieldHeight) : (g.fieldHeight + buffer)) + 'px';

                  this.root.style.top = g.y + 'px';

                  var mappings = {
                    fields: input,
                    labels: label,
                    containers: this.root
                  };

                  fieldCleaner.cleanField(f);
                  _setStyle(f.id, formElement.model, mappings);

                  g.y += g.fieldHeight + g.fieldBorder + g.fieldMargin.bottom;

                  if (top) {
                    g.y += lblHeight;
                  }
                } else {
                  var options;

                  this.root.id = 'container_' + f.id;
                  [this.root.down('label')].compact().each(function(elm) {
                    elm.id = 'label_' + f.id;
                  });

                  formElement.applyPageStyles(_getStyle(formElement.id, formElement.model), options);
                }
              }
            }, fieldViewBase);
          }
        }, {
          types: ['multi-line-text'],
          view: function(f) {
            var root = new Element('div', {
              className: 'lp-pom-form-field clearfix'
            });
            if (!f.hideLabel) {
              root.insert(new Element('label', {
                'for': f.id,
                className: 'main'
              }).update(
                f.validations.required ? f.name + ' *' : f.name
              ));
            }
            root.insert(new Element('textarea', {
              id: f.id,
              name: f.id,
              className: 'text ' + extraClassName(f)
            }));
            return Object.extend({
              root: root,
              adjustGeometry: function(g, f) {
                /* jshint maxcomplexity: 12 */
                if (formElement.isAdjustGeometryNeeded()) {
                  var top = g.labelAlign === 'top';
                  var label = f.hideLabel ? null : this.root.down('label.main');
                  var input = this.root.down('textarea');

                  this.root.style.width = g.formWidth + 'px';
                  if (label !== null) {
                    label.style.top = '0px';
                    label.style.width = g.leftLabelWidth > 0 ? g.leftLabelWidth + 'px' : 'auto';
                  }

                  var lblHeight = calcLabelHeight(label, g, f);
                  var marginRight = (g.leftLabelWidth === 0 ? 0 : g.labelMargin.right);
                  var lineHeight = Math.round(g.fieldFontSize * 1.2);

                  input.style.top = (top ? lblHeight : 0) + 'px';
                  input.style.left = (top ? 0 : (g.leftLabelWidth + marginRight)) + 'px';
                  input.style.width = (top ? (g.formWidth - g.fieldBorder - (g.fieldPaddingTop * 2)) : (g.formWidth - (g.fieldBorder + (g.fieldPaddingTop * 2) + g.leftLabelWidth + marginRight))) + 'px';

                  input.style.fontSize = g.fieldFontSize + 'px';
                  input.style.lineHeight = lineHeight + 'px';
                  var height = f.heightUnits === 'lines' ?
                    lineHeight * f.numberOfLines :
                    f.pixelHeight;

                  input.style.height = height + 'px';
                  input.style.paddingTop = g.fieldPaddingTop + 'px';
                  input.style.paddingBottom = g.fieldPaddingBottom + 'px';
                  input.style.paddingLeft = g.fieldPaddingTop + 'px';
                  input.style.paddingRight = g.fieldPaddingTop + 'px';

                  var totalHeight = height + g.fieldPaddingTop + g.fieldPaddingBottom;

                  this.root.style.height = (top ? (lblHeight + totalHeight) : (totalHeight + 4)) + 'px';

                  this.root.style.top = g.y + 'px';

                  var mappings = {
                    fields: input,
                    labels: label,
                    containers: this.root
                  };

                  fieldCleaner.cleanField(f);
                  _setStyle(f.id, formElement.model, mappings);

                  g.y += totalHeight + g.fieldBorder + g.fieldMargin.bottom;
                  if (top) {
                    g.y += lblHeight;
                  }
                } else {
                  var options;

                  this.root.id = 'container_' + f.id;
                  [this.root.down('label')].compact().each(function(elm) {
                    elm.id = 'label_' + f.id;
                  });

                  formElement.applyPageStyles(_getStyle(formElement.id, formElement.model), options);
                }
              }
            }, fieldViewBase);
          }
        }, {
          types: ['hidden'],
          view: function(f) {
            var root = new Element('input', {
              id: f.id,
              name: f.id,
              type: 'hidden',
              className: 'hidden',
              value: f.value
            });
            // root.insert();
            return Object.extend({
              root: root,
              adjustGeometry: function() {}
            }, fieldViewBase);
          }
        }, {
          types: ['drop-down'],
          view: function(f) {
            var root = new Element('div', {
              className: 'lp-pom-form-field clearfix'
            });
            if (!f.hideLabel) {
              root.insert(new Element('label', {
                'for': f.id,
                className: 'main'
              }).update(
                f.validations.required ? f.name + ' *' : f.name
              ));
            }
            var html = '';
            f.selectOptions.each(function(o, i) {
              var v = (f.invalidOptions && f.invalidOptions.include(i)) ? '' : o;
              html += '<option value="' + v + '">' + o + '</options>';
            });
            root.insert(new Element('select', {
              id: f.id,
              name: f.id,
              className: 'text ' + extraClassName(f)
            }).update(html));

            return Object.extend({
              root: root,
              adjustGeometry: function(g, f) {
                /* jshint maxcomplexity: 12 */
                if (formElement.isAdjustGeometryNeeded()) {
                  var top = g.labelAlign === 'top';
                  var label = f.hideLabel ? null : this.root.down('label.main');
                  var input = this.root.down('select');

                  this.root.style.width = g.formWidth + 'px';
                  if (label !== null) {
                    label.style.top = '0px';
                    label.style.width = g.leftLabelWidth > 0 ? g.leftLabelWidth + 'px' : 'auto';
                  }

                  var lblHeight = calcLabelHeight(label, g, f);
                  var marginRight = (g.leftLabelWidth === 0 ? 0 : g.labelMargin.right);

                  input.style.top = (top ? lblHeight : 0) + 'px';
                  input.style.left = (top ? 0 : (g.leftLabelWidth + (g.leftLabelWidth === 0 ? 0 : marginRight))) + 'px';
                  input.style.width = (top ? (g.formWidth) : (g.formWidth - (g.leftLabelWidth + marginRight))) + 'px';

                  input.style.fontSize = g.fieldFontSize + 'px';
                  input.style.lineHeight = g.fieldFontSize + 'px';
                  input.style.height = g.fieldHeight + g.fieldBorder + 'px';

                  this.root.style.height = (top ? (lblHeight + g.fieldHeight) : (g.fieldHeight + 4)) + 'px';

                  this.root.style.top = g.y + 'px';

                  var mappings = {
                    fields: input,
                    labels: label,
                    containers: this.root
                  };

                  fieldCleaner.cleanField(f);
                  _setStyle(f.id, formElement.model, mappings);

                  g.y += g.fieldHeight + g.fieldBorder + g.fieldMargin.bottom;
                  if (top) {
                    g.y += lblHeight;
                  }
                } else {
                  var options;

                  this.root.id = 'container_' + f.id;
                  [this.root.down('label')].compact().each(function(elm) {
                    elm.id = 'label_' + f.id;
                  });

                  formElement.applyPageStyles(_getStyle(formElement.id, formElement.model), options);
                }
              }
            }, fieldViewBase);
          }
        },

        {
          types: ['checkbox-group'],

          view: function(f) {
            var root = new Element('div', {
              className: 'lp-pom-form-field clearfix'
            });

            if (!f.hideLabel) {
              root.insert(new Element('label', {
                'for': f.id,
                className: 'main'
              }).update(
                f.validations.required ? f.name + ' *' : f.name
              ));
            }

            var optionsList = new Element('div', {
              className: 'optionsList',
              id: 'group_' + f.id
            });

            f.options.each(function(o) {
              var option = new Element('div', {
                className: 'option'
              });
              var cbxAttrs = {
                type: 'checkbox',
                id: f.id + '_' + o.value,
                name: f.id,
                value: o.value,
                className: 'checkbox ' + extraClassName(f)
              };

              if (o.checked) {
                cbxAttrs.checked = true;
              }

              option.insert(new Element('input', cbxAttrs));
              option.insert(new Element('label', {
                'for': f.id + '_' + o.value
              }).update(o.label));
              optionsList.insert(option);
            });

            root.insert(optionsList);

            return Object.extend({
              root: root,

              getOptionsListHeight: function() {
                return this.root.down('div.optionsList').getHeight();
              },

              adjustGeometry: function(g, f) {
                /* jshint maxcomplexity: 13 */
                if (formElement.isAdjustGeometryNeeded()) {
                  var top = g.labelAlign === 'top';
                  var label = f.hideLabel ? null : this.root.down('label.main');
                  this.root.style.width = g.formWidth + 'px';
                  if (label !== null) {
                    label.style.top = '0px';
                    label.style.width = g.leftLabelWidth > 0 ? g.leftLabelWidth + 'px' : 'auto';
                  }

                  var lblHeight = calcLabelHeight(label, g, f);
                  var marginRight = (g.leftLabelWidth === 0 ? 0 : g.labelMargin.right);

                  optionsList.style.top = (top ? lblHeight : 0) + 'px';
                  optionsList.style.left = (top ? 0 : (g.leftLabelWidth + (g.leftLabelWidth === 0 ? 0 : marginRight))) + 'px';
                  optionsList.style.width = (top ? (g.formWidth) : (g.formWidth - (g.leftLabelWidth + marginRight))) + 'px';

                  var optionsHeight = (formElement.page.isPublishOrPreviewMode()) ? f.optionsListHeight : optionsList.getHeight();
                  this.root.style.height = (top ? (lblHeight + optionsHeight) : (optionsHeight + 4)) + 'px';

                  this.root.style.top = g.y + 'px';

                  var mappings = {
                    groups: optionsList,
                    labels: label,
                    containers: this.root
                  };

                  fieldCleaner.cleanField(f);
                  _setStyle(f.id, formElement.model, mappings);

                  g.y += optionsHeight + g.fieldMargin.bottom;
                  if (top) {
                    g.y += lblHeight;
                  }
                } else {
                  var options;

                  this.root.id = 'container_' + f.id;
                  [this.root.down('label')].compact().each(function(elm) {
                    elm.id = 'label_' + f.id;
                  });

                  formElement.applyPageStyles(_getStyle(formElement.id, formElement.model), options);
                }
              }
            }, fieldViewBase);
          }
        }, {
          types: ['radio-group'],
          view: function(f) {
            var root = new Element('div', {
              className: 'lp-pom-form-field clearfix'
            });
            if (!f.hideLabel) {
              root.insert(new Element('label', {
                'for': f.id,
                className: 'main'
              }).update(
                f.validations.required ? f.name + ' *' : f.name
              ));
            }
            var optionsList = new Element('div', {
              className: 'optionsList',
              id: 'group_' + f.id
            });
            f.options.each(function(o) {
              var option = new Element('div', {
                className: 'option'
              });
              var cbxAttrs = {
                type: 'radio',
                id: f.id + '_' + o.value,
                name: f.id,
                value: o.value,
                className: 'radio ' + extraClassName(f)
              };
              if (o.checked) {
                cbxAttrs.checked = true;
              }
              option.insert(new Element('input', cbxAttrs));
              option.insert(new Element('label', {
                'for': f.id + '_' + o.value
              }).update(o.label));
              optionsList.insert(option);
            });

            root.insert(optionsList);

            return Object.extend({
              root: root,
              getOptionsListHeight: function() {
                return this.root.down('div.optionsList').getHeight();
              },
              adjustGeometry: function(g, f) {
                /* jshint maxcomplexity: 13 */

                if (formElement.isAdjustGeometryNeeded()) {
                  var top = g.labelAlign === 'top';
                  var label = f.hideLabel ? null : this.root.down('label.main');
                  this.root.style.width = g.formWidth + 'px';
                  if (label !== null) {
                    label.style.top = '0px';
                    label.style.width = g.leftLabelWidth > 0 ? g.leftLabelWidth + 'px' : 'auto';
                  }

                  var lblHeight = calcLabelHeight(label, g, f);
                  var marginRight = (g.leftLabelWidth === 0 ? 0 : g.labelMargin.right);

                  optionsList.style.top = (top ? lblHeight : 0) + 'px';
                  optionsList.style.left = (top ? 0 : (g.leftLabelWidth + (g.leftLabelWidth === 0 ? 0 : marginRight))) + 'px';
                  optionsList.style.width = (top ? (g.formWidth) : (g.formWidth - (g.leftLabelWidth + marginRight))) + 'px';

                  var optionsHeight = (formElement.page.isPublishOrPreviewMode()) ? f.optionsListHeight : optionsList.getHeight();
                  this.root.style.height = (top ? (lblHeight + optionsHeight) : (optionsHeight + 4)) + 'px';

                  this.root.style.top = g.y + 'px';

                  var mappings = {
                    groups: optionsList,
                    labels: label,
                    containers: this.root
                  };

                  fieldCleaner.cleanField(f);
                  _setStyle(f.id, formElement.model, mappings);

                  g.y += optionsHeight + g.fieldMargin.bottom;
                  if (top) {
                    g.y += lblHeight;
                  }
                } else {
                  var options;

                  this.root.id = 'container_' + f.id;
                  [this.root.down('label')].compact().each(function(elm) {
                    elm.id = 'label_' + f.id;
                  });

                  formElement.applyPageStyles(_getStyle(formElement.id, formElement.model), options);
                }
              }
            }, fieldViewBase);
          }
        }
      ];
    }
  };

  return FormElementViews;
});
