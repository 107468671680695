lp.editor.SectionTransformBox = Class.create( jui.Component, jui.EventSource, lp.editor.dnd.transformSectionDnDSource, {

  options: function($super,options){
    return $super($H({attributes:{className:'section-transform-box'}}).merge(options));
  },

  initialize: function( $super, editor, options ){
    this.editor = editor;
    this.elm = null;
    this.tracking = false;
    this.offset = [];
    this.boxOffset = [];
    this.handleW = 13;
    this.handleH = 13;
    this.handleNames = ['bmh'];
    this.dragThreshold = 3;
    this.startIndex = null;
    this.startPoint = null;

    this.undoRegistered = false;

    $super( options );

    var self = this;

    this.elmModelListener = this.elementModelChanged.bind(this);

    this.elementMoveListener = this.elementMoved.bindAsEventListener( this );

    this.elementVisibilityListener = function(e) {
      self[e.source.visible() ? 'show' : 'hide']();
    };

    this.adjustmentListener = function() {
      if (self.elm !== null && self.elm.type !== 'lp-pom-root') {
        self.positionBoundingBox();
      }
    };

    this.editor.addListener('layoutChanged', this.adjustmentListener);
    this.editor.addListener('elementActivated', this);
    this.editor.addListener('elementDeactivated', this);
    this.editor.addListener('activeRootChanged', this);

    this.mouseMoveListener = this.mousemove.bindAsEventListener( this );
    this.mouseUpListener   = this.mouseup.bindAsEventListener( this );
  },

  activeRootChanged: function(e) {
    var previous = e.data.previous;
    var root = e.data.current;
    if (previous !== null) {
      previous.removeListener('blockInserted', this.adjustmentListener);
      previous.removeListener('blockRemoved', this.adjustmentListener);
    }

    root.addListener('blockInserted', this.adjustmentListener);
    root.addListener('blockRemoved', this.adjustmentListener);
  },

  installUI: function( $super, options ) {
    $super( options );
    this.installBoundingBox(options);
    this.installHandles(options);
  },

  installBoundingBox: function(options) {
    /* jshint unused:vars */
    this.tbndry = this.insert( new Element('div', {className:'transform-boundary top'}));
    this.rbndry = this.insert( new Element('div', {className:'transform-boundary right'}));
    this.bbndry = this.insert( new Element('div', {className:'transform-boundary bottom'}));
    this.lbndry = this.insert( new Element('div', {className:'transform-boundary left'}));
  },

  installHandles: function(options){
    /* jshint unused:vars */
    var self = this;
    this.bmh = this.insert( new lp.editor.TransformBoxHandle(this, function(p) {self.handleDragBottom(p);}, {classNames: ['bmh']}));
    this.dragTab = this.insert( new Element('div', {className:'section-drag-tab'}));

    this.dragTab.observe('mousedown', function(e) {
      self.startPoint = new jui.Point(Event.pointerX(e),Event.pointerY(e));
      jui.dnd.manager.startTracking(self, e);
      self.setTracking(true);
      Event.stop(e);
    });
  },

  handleDragStart: function() {
    var m = this.elm.model;
    var um = this.elm.page.undoManager;

    this.elmStartRootOffset = this.elm.getRootOffset();
    this.elmStartOffset = m.getOffset();
    this.elmStartSize = m.getSize();
    this.elmMinSize = m.getMinSize();
    this.elmMaxSize = m.getMaxSize();

    if (!this.undoRegistered) {
      this.undoRegistered = true;
      um.startGroup();
      um.registerUndo({
        action:m.setOffset,
        receiver:m,
        params:[{left:this.elmStartOffset.left, top:this.elmStartOffset.top}, um]
      });
      um.registerUndo({
        action:m.setSize,
        receiver:m,
        params:[{width:this.elmStartSize.width, height:this.elmStartSize.height}, um]
      });
      um.endGroup();
    }
  },

  handleDragBottom: function(o) {
    var m = this.elm.model;
    var w0 = this.elmStartSize.width;
    var h0 = this.elmStartSize.height;
    var w,h;
    var minSize = this.elmMinSize;
    var maxSize = this.elmMaxSize;
    var ar = w0 / h0;

    var d = {left: this.elmStartRootOffset.left, top: this.elmStartRootOffset.top + h0};
    var p = {left: d.left + o.left, top: d.top + o.top};

    this.editor.snapManager.snapPoint(p);

    p.top -= d.top;

    h = Math.min(maxSize.height, Math.max(minSize.height, h0 + p.top));
    if (m.isMaintainAR()) {
      w = Math.round(h * ar);
      if (w >= maxSize.width || w <= minSize.width) {
        w = Math.min(maxSize.width, Math.max(minSize.width, w));
        h = Math.min(maxSize.height, Math.max(minSize.height, Math.round(w / ar)));
      }
    } else {
      w = w0;
    }

    m.suppressEvents();
    m.setSize({width:w, height:h});
    m.releaseEvents();
  },

  elementActivated: function(e) {
    var elm = e.data;
    if (!elm.isVisibleOnPage()){ return; }
    if (elm.type !== 'lp-pom-block'){return;}
    this.setElement(elm);
  },

  elementDeactivated: function() {
    this.setElement(null);
  },

  elementMoved: function() {
    this.positionBoundingBox();
    this.positionHandles();
  },

  mouseup:function(e){
    Event.stop(e);
    this.setTracking( false );
    // this.editor.fireEvent('elementDragStop');
  },

  mousemove:function( e ){
    Event.stop(e);
    this.lastPoint = {left: Event.pointerX(e), top:Event.pointerY(e)};
    this.positionGhost({left: Event.pointerX(e), top:Event.pointerY(e)});
  },

  setTracking: function(b) {
    if (this.tracking === b) {
      return;
    }

    this.tracking = b;
    if ( b ) {
      $( window.document ).observe( 'mouseup', this.mouseUpListener );
      $( window.document ).observe( 'mousemove', this.mouseMoveListener );
      this.fireEvent('startMove', this.elm);
    } else {
      this.undoRegistered = false;
      $( window.document ).stopObserving( 'mouseup', this.mouseUpListener );
      $( window.document ).stopObserving( 'mousemove', this.mouseMoveListener );
      this.fireEvent('stopMove', this.elm);
    }
  },

  setElement: function( elm ) {
    if (this.elm !== null) {
      this.removeElementListeners();
    }

    this.elm = elm;

    if (this.elm && this.elm.is('displayable')) {
      this.addElementListeners();
      this.positionBoundingBox();
      this.positionHandles();
      this.show();
      this.focus();
    } else {
      this.hide();
    }
  },

  addElementListeners: function(){
    this.elm.model.addListener('attributeChanged', this.elmModelListener);
    this.elm.addListener('moved', this.elementMoveListener);
    this.elm.addListener('hidden', this.elementVisibilityListener);
    this.elm.addListener('shown', this.elementVisibilityListener);
  },

  removeElementListeners: function(){
    this.elm.model.removeListener('attributeChanged', this.elmModelListener);
    this.elm.removeListener('moved', this.elementMoveListener);
    this.elm.removeListener('hidden', this.elementVisibilityListener);
    this.elm.removeListener('shown', this.elementVisibilityListener);
  },

  elementModelChanged: function() {
    this.positionBoundingBox();
    this.positionHandles();
  },

  positionBoundingBox: function() {
    if (this.elm === null) {
      return;
    }

    this.setOffset(this.elm.getPageOffset());

    var w = this.elm.getContentWidth();
    var h = this.elm.getContentHeight();

    this.tbndry.style.width = w+'px';
    this.rbndry.style.height = h+'px';
    this.rbndry.style.left = (w-1)+'px';
    this.bbndry.style.width = (w-1)+'px';
    this.bbndry.style.top = (h-1)+'px';
    this.lbndry.style.height = (h-1)+'px';
  },

  positionHandles: function() {
    var offsetX = this.handleW / 2;
    var w = this.elm.getContentWidth();
    var h = this.elm.getContentHeight();
    var middleX = (w / 2) - offsetX;

    this.bmh.setOffset({left:middleX, top:h});

    // this.dragTab.style.top = (h/2 - 9) + 'px';

    this.dragTab.style.display = (this.elm.getRootElement().childElements.length > 1) ? 'block' : 'none';
  },

  getStartLeft: function(){
    return this.elm.getLeft();
  },

  getStartTop: function(){
    return this.elm.getTop();
  },

  getStartWidth: function(){
    return this.elm.getWidth();
  },

  getStartHeight: function(){
    return this.elm.getHeight();
  },

  getModel: function() {
    return this.elm.model;
  },

  onkeydown: function( e ) {
    if( e.keyCode === Event.KEY_DELETE || e.keyCode === Event.KEY_BACKSPACE ) {
      this.editor.removeElement();
      e.stop();
    }
  },

  focus: function() {
    this.editor.keyController.requestFocus(this, true);
  },

  blur: function() {
    //this.setOpacity(0.3);
  }
});
