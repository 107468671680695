lp.editor.ElementHilite = Class.create( jui.Component, jui.ControlModel, {
  options: function($super,options){
    // for now the editor is a jui.Component that grabs the 'content' div as it's element
    return $super($H({attributes:{className:'element-hilite'}}).merge(options));
  },

  initialize: function( $super, editor, options ){
    this.editor = editor;
    $super( options );
    this.editor.addListener('elementMouseover', this);
    this.editor.addListener('elementMouseout', this);
    this.editor.addListener('elementActivated', this);
    this.editor.transformBox.addListener('startMove', this);
    this.editor.transformBox.addListener('stopMove', this);
  },

  installUI: function( $super, options ) {
    $super( options );
    this.tbndry = this.insert( new Element('div', {className:'hilite-boundary top'}));
    this.rbndry = this.insert( new Element('div', {className:'hilite-boundary right'}));
    this.bbndry = this.insert( new Element('div', {className:'hilite-boundary bottom'}));
    this.lbndry = this.insert( new Element('div', {className:'hilite-boundary left'}));
    this.setOpacity( 0.5 );
  },

  startMove: function() {
    this.disable();
    this.hide();
  },

  stopMove: function() {
    this.enable();
  },

  elementMouseover: function(e) {
    var elm = e.data.source;
    if (elm.type === 'lp-pom-root') {
      return;
    }
    // JS: TODO: hmmm... there is probably a better way to disable th element hilte while these other guys are doing there thing
    if (this.editor.transformBox.tracking || this.editor.sectionTransformBox.tracking || this.editor.pageBoundaryManager.tracking || this.editor.sectionBoundaryManager.tracking) {
      return;
    }
    if (elm !== this.editor.activeElement && this.isEnabled()) {
      this.positionBoundingBox(e.data.source);
      this.show();
    }
  },

  elementMouseout: function(e) {
    var elm = e.data.source;
    if (elm.type === 'lp-pom-root') {
      return;
    }
    this.hide();
  },

  elementActivated: function() {
    this.hide();
  },

  positionBoundingBox: function(elm) {
    var po = elm.getPageOffset();

    this.setOffset({left: po.left-2, top: po.top-2});

    var w = elm.getContentWidth();
    var h = elm.getContentHeight();

    this.tbndry.style.width = (w)+'px';
    this.rbndry.style.height = (h-2)+'px';
    this.rbndry.style.left = (w+1)+'px';
    this.rbndry.style.top = '3px';
    this.bbndry.style.width = (w)+'px';
    this.bbndry.style.top = (h+1)+'px';
    this.lbndry.style.height = (h-2)+'px';
    this.lbndry.style.top = '3px';
  }
});
