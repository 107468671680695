/* globals CodeMirror */
jui.CodeEditor = Class.create(jui.Component, jui.ControlModel, {
  options: function($super, options) {
    return $super($H({
      onMouseup: function(){},
      onKeyup: function(){},
      onChange: function(){},
      toggleCheckboxYPosition: '1px',
      attributes: {
        className: 'jui-code-editor'
      }
    }).merge(options));

  },

  initialize: function($super, options) {
    $super( options );
  },

  installUI: function($super) {
    $super();
    this.label = this.insert(new Element('label').update(this.options.label));
    this.input = this.insert(new Element('textarea'));
    this.errors = [];
    this.isValidContent = true;

    var self = this;

    this.codeMirror = CodeMirror( function(elt) {
      elt.observe( 'mouseup', self.options.onMouseup );
      elt.observe( 'keyup', self.options.onKeyup );
      self.e.replaceChild( elt, self.input );
    }, {
      mode: self.options.mode || 'text/html',
      tabMode: self.options.tabMode || 'indent',
      lineNumbers: self.options.lineNumbers ? self.options.lineNumbers : true,
      lineWrapping: self.options.lineWrapping ? self.options.lineWrapping : true,
      gutter: self.options.gutter ? self.options.gutter : true,
      onChange: self.options.onChange
    } );

    this.toggleWrapping = this.insert( new jui.FormCheckboxInput( {
      label: "Soft Wrap",
      checked: self.options.lineWrapping ? self.options.lineWrapping : true,
      onclick: function( e ) {
        var wrap = e.source.getValue();
        self.codeMirror.setOption( 'lineWrapping', wrap );
      }
    } ) );

    this.toggleWrapping.makeAbsolute();
    this.toggleWrapping.e.style.right = '0px';
    this.toggleWrapping.e.style.top = this.options.toggleCheckboxYPosition;
  },

  focus: function() {
    this.codeMirror.focus();
  },

  setValue: function(value) {
    this.codeMirror.setValue(value);
  },

  getValue: function() {
    return this.codeMirror.getValue();
  },

  isValid: function() {
    return !!this.isValidContent;
  },

  setIsValid: function(isValid) {
    this.isValidContent = isValid;
  },

  getErrors: function() {
    return this.errors;
  },

  showHTMLWarningIfInvalid: function(model, doneFunction, isValidContent){
    //severity is always set to warning until we get errors back from the publisher;
    isValidContent = isValidContent || this.getSeverityLevel() !== 'Error';
    if(isValidContent) {
      model.updateButton('done', 'Done', doneFunction.bind(model, isValidContent));
      model.hideWarningDialog();
    } else {
      model.showWarningDialog('This code appears to be invalid which might result in a broken published page.');
      model.updateButton('done', 'Save Anyway', doneFunction.bind(model, true));
    }
  },

  isNotEmpty: function(text) {
    return text.strip().length > 0;
  },

  getSeverityLevel: function() {
    //For now always return Warning.  Once we get errors from
    //the publisher we can return different severity levels such as error.
    return 'Warning';
  },

  isValidContentHTML: function(model, doneFunction) {
    var self = this;
    if(this.isNotEmpty(this.getValue())) {
      var close = false;
      return new Ajax.Request('/validator/html', {
        method: 'post',
        parameters: {html: this.getValue()},
        asynchronous: true,
        onLoading: function() {
          model.showWaiter('Validating');
          model.hideWarningDialog();
        },
        onLoaded: function() {
          model.hideWaiter();
        },
        onSuccess: function(transport) {
          var json = transport.responseJSON;
          var severity = self.getSeverityLevel();
          if(severity !== 'Error' || (!self.isValid() && !json.valid)){close = true;}
          self.isValidContent = json.valid;
          self.errors  = json.errors;
          if(close) {
            doneFunction.call(model, true);
          }
          else if(self.isValidContent) {
            doneFunction.call(model, true);
          } else {
            self.showHTMLWarningIfInvalid(model, doneFunction, false);
          }
        },
        onFailure: function() {
          //If we do not get a resonse from the server call the doneFunction
          //any way so not to ruin user experiance if we cannot validate.
          doneFunction.call(model, true);
        }
      });
    } else {
      this.isValidContent = true;
      this.errors = [];
      doneFunction.call(model, true);
    }
  }

});
