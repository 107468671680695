lp.module.code.CustomHTMLElementPropertiesPanel = Class.create(
  lp.editor.panel.ElementPropertiesPanel,
  lp.editor.panel.ElementGeometryControls,
  lp.ModuleComponent,
  {
    type:'lp-code',

    initialize: function($super) {
      $super();
    },

    installUI: function($super){
      $super();

      var geometryPanel = this.insert(new jui.CollapsiblePanel({title:'Geometry'}));
      this.installGeometryControls({insert:geometryPanel.insertContent.bind(geometryPanel)});

      var contentPanel = this.insert(new jui.CollapsiblePanel({title:'Content'}));
      var self = this;
      this.controls.editButton = contentPanel.insertContent(new jui.Button({
        label:'Edit Code',
        action:function(){
          self.getModule().openBuilder(self.element, {
            callback:function(data){
              self.element.model.set('content.html', data.html, self.element.page.undoManager);
              self.element.model.set('content.valid', data.valid, self.element.page.undoManager);
              self.element.model.set('content.errors', data.errors, self.element.page.undoManager);
            }});
        }
      }));
    },

    syncUIControls: function($super, elm) {
      $super(elm);
      this.bindGeometryControlsToElement(elm);
    }
  }
);