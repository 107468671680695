lp.module.root.RootElementPropertiesPanel = Class.create(
  lp.editor.panel.ElementPropertiesPanel,
  lp.editor.panel.ElementGeometryControls,
  lp.editor.panel.ElementBackgroundControls,
  lp.ModuleComponent,
  {
    type:'lp-pom-root',

    initialize: function($super) {
      $super();
      this.updatingStyles = false;
    },

    installUI: function($super){
      $super();
      var self = this;
      this.modelChangeHandlers.push(function(e) {
        var accessor = e.data.accessor,
            value    = e.data.value;

        if (accessor === 'geometry.contentWidth') {
          self.controls.size.setValue({width:value});
        } else if (!self.updatingStyles && accessor === 'style.defaults.color') {
          self.controls.textColor.setValue(value);
        } else if (!self.updatingStyles && accessor === 'style.defaults.linkColor') {
          self.controls.linkColor.setValue(value);
        } else if (accessor === 'geometry.padding') {
          self.controls.paddingTop.setValue(
            (value && value.top) ? value.top : 0);
        }
      });

      this.focusListener        = this.requestFocus.bind(this);
      this.sizeControlsListener = this.sizeControlsChanged.bind(this);

      if (lp.isResponsiveEnabled()) {
        this.mobilePagePanel = this.insert(new jui.CollapsiblePanel({
          title: 'Mobile Page View'
        }));
      }

      this.geometryPanel = this.insert(new jui.CollapsiblePanel({
        title:'Geometry'
      }));

      this.controls.size = this.geometryPanel.insertContent( new jui.FormSizeInput({
        label      : 'Size',
        onblur     : this.sizeControlsListener,
        onfocus    : this.focusListener,
        hideHeight : true
      }));

      if (lp.isResponsiveEnabled()) {
        this.controls.breakpointSize = this.geometryPanel.insertContent( new jui.FormTextInput({
          label       : 'Breakpoint',
          readonly    : true,
          attributes  : {
            className : 'form-elm breakpoint-size breakpoint-input'
          }
        }));

        this.controls.multiBreakpointToggle = new jui.FormCheckboxInput({
          label: 'Show Mobile Version to Visitors<br />' +
            '<span class="note">Note: Unchecked when your mobile page ' +
            'is not ready to be seen by your visitors.</span>',
          onclick: this.multipleBreakpointToggleChanged.bind(this)
        });
        this.mobilePagePanel.insert(this.controls.multiBreakpointToggle);

        this.addListener('breakpointChanged', this.handleBreakpointControlsVisibility.bind(this));
      }

      this.controls.paddingTop = this.geometryPanel.insertContent( new jui.FormNumericInput({
        label   : 'Top Margin',
        onfocus : this.requestFocus.bind(this),
        onblur  : function(e) {
          var paddingBottom = e.source.getValue() * 1;
          self.performModelUpdates( function() {
            self.element.model.set('geometry.padding', {
              left  : 'auto',
              right : 'auto',
              top   : paddingBottom
            },
            self.element.page.undoManager);
          });
        }
      }));

      this.titleAndMeta = this.insert(new jui.CollapsiblePanel({
        title       : 'Title & Meta Data',
        attributes  : {
          className : 'collapsible-panel meta-data'
        }
      }));

      var metaStyleOverride = 'min-height: 48px; resize : vertical; overflow-x: hidden; overflow-y: auto';
      this.controls.title = this.titleAndMeta.insert(new jui.FormDynamicContenteditable({
        label      : 'Title',
        inputStyle : metaStyleOverride,
        helpText   : 'You can pass in dynamic text values to your title, ' +
                     'through your page\'s URL. This is useful for increasing ' +
                     'message match and quality score of your PPC campaigns.',
        onfocus    : this.requestFocus.bind(this),
        onblur     : this.metaDataChanged.bind(this),
        onkeyup    : this.metaDataChanged.bind(this)
      }));

      this.controls.keywords = this.titleAndMeta.insert(new jui.FormDynamicContenteditable({
        label      : 'Keywords',
        inputStyle : metaStyleOverride,
        helpText   : 'You can pass in dynamic text values to your keywords, ' +
                     'through your page\'s URL. This is useful for increasing ' +
                     'message match and quality score of your PPC campaigns.',
        onfocus    : this.requestFocus.bind(this),
        onblur     : this.metaDataChanged.bind(this),
        onkeyup    : this.metaDataChanged.bind(this)
      }));

      this.controls.description = this.titleAndMeta.insert(new jui.FormDynamicContenteditable({
        label      : 'Description',
        inputStyle : metaStyleOverride,
        helpText   : 'You can pass in dynamic text values to your description, ' +
                     'through your page\'s URL. This is useful for increasing ' +
                     'message match and quality score of your PPC campaigns.',
        onfocus    : this.requestFocus.bind(this),
        onblur     : this.metaDataChanged.bind(this),
        onkeyup    : this.metaDataChanged.bind(this)
      }));

      this.controls.noRobots = this.titleAndMeta.insert(new jui.FormCheckboxInput({
        label: 'Hide my page from search engines<br /><span class="note">Note: '+
          'make sure this is set the same for all variants</span>',
        onclick: this.metaDataChanged.bind(this)
      }));

        this.controls.mobilePageCheckbox = this.advancedPanel.insert(new jui.FormCheckboxInput({
          label: 'Include "viewport" meta tag for mobile.',
          onclick: this.mobilePageCheckboxChanged.bind(this)
        }));
        this.controls.mobilePageCheckbox.insert(new jui.HelpButton('http://support.unbounce.com/entries/22096080', 'Get more information about what this setting does'));


      this.backgroundPanel = this.insert(new jui.CollapsiblePanel({title:'Background Properties'}));
      this.installBackgroundControls({insert:this.backgroundPanel.insertContent.bind(this.backgroundPanel)});

      var textDefaults = this.insert(new jui.CollapsiblePanel({title:'Default Text Style'}));

      this.controls.textColor = textDefaults.insert( new jui.FormColorInput ({
        label   : 'Text Color',
        onblur  : this.textDefaultsChanged.bind(this),
        onfocus : this.requestFocus.bind(this)
      }));

      this.controls.linkColor = textDefaults.insert( new jui.FormColorInput ({
        label   : 'Link Color',
        onblur  : this.textDefaultsChanged.bind(this),
        onfocus : this.requestFocus.bind(this)
      }));
    },

    multipleBreakpointToggleChanged: function(){
      this.setMultiBreakpointToggle(this.controls.multiBreakpointToggle.getValue());
      this.handleMobilePageToggleControlVisibility();
    },

    setMultiBreakpointToggle: function(newMultipleBreakpointsVisibility) {
      var page                          = this.element.page,
          multipleBreakpointsVisibility = !!page.settings.multipleBreakpointsVisibility;

      if (multipleBreakpointsVisibility !== newMultipleBreakpointsVisibility) {
        window.editor.pages.each(function updateMultipleBreakpointVisibility(subPage){
          subPage.settings.multipleBreakpointsVisibility = newMultipleBreakpointsVisibility;
        });
      }
    },

    metaDataChanged: function() {
      this.setMetaData(
        this.controls.title.getValue(),
        this.controls.keywords.getValue(),
        this.controls.description.getValue(),
        this.controls.noRobots.getValue()
      );
    },

    setMetaData: function(title, keywords, description, noRobots) {
      var page               = this.element.page,
          currentTitle       = page.metaData.title,
          currentKeywords    = page.metaData.keywords,
          currentDescription = page.metaData.description,
          currentNoRobots    = !!page.settings.noRobots;


      if (currentTitle !== title || currentKeywords !== keywords ||
          currentDescription !== description || currentNoRobots !== noRobots) {
        page.undoManager.registerUndo({
          action   : this.setMetaData,
          receiver : this,
          params   : [
            currentTitle,
            currentKeywords,
            currentDescription,
            currentNoRobots
          ]
        });

        page.metaData.title       = title;
        page.metaData.keywords    = keywords;
        page.metaData.description = description;
        page.settings.noRobots    = noRobots;

      }
    },

    mobilePageCheckboxChanged: function(e) {
      if(e.data) {
        this.element.page.settings.mobilePage = true;
      } else {
        delete this.element.page.settings.mobilePage;
      }
    },

    textDefaultsChanged: function() {
      this.updateTextDefaults();
    },

    updateTextDefaults: function() {
      this.updatingStyles = true;

      var page      = this.element.page,
          textColor = this.controls.textColor.getValue(),
          linkColor = this.controls.linkColor.getValue(),
          um        = page.undoManager;
      um.startGroup();
      this.element.model.set('style.defaults.color', textColor, um);
      this.element.model.set('style.defaults.linkColor', linkColor, um);
      um.endGroup();
      this.updatingStyles = false;
    },

    syncUIControls: function($super, elm) {
      $super(elm);
      var page = elm.page;

      this.titleAndMeta.setVisible(page !== null && page.usedAs() !== 'form_confirmation');

      var minWidth = 16;
      var maxWidth = 100000;

      if (lp.isResponsiveEnabled()) {
        var width = page.getCurrentBreakpoint().width || '';
        this.controls.breakpointSize.setValue(width);

        minWidth = elm.page.getMinWidthForCurrentBreakpoint();
        maxWidth = elm.page.getMaxWidthForCurrentBreakpoint();

        this.controls.multiBreakpointToggle.setValue(page.settings.multipleBreakpointsVisibility);

        this.handleMultiBreakpointToggleControlVisibility();
      }
      this.handleMobilePageToggleControlVisibility();
      this.controls.size.options.minWidth = minWidth;
      this.controls.size.options.maxWidth = maxWidth;

      this.controls.size.setValue({width:elm.model.get('geometry.contentWidth')});

      this.controls.paddingTop.setValue(elm.model.exists('geometry.padding.top') ? elm.model.get('geometry.padding.top') : 0);
      this.controls.paddingTop.setVisible(elm.page.allowTopPadding());


      this.controls.title.setValue(page.metaData.title);
      this.controls.keywords.setValue(page.metaData.keywords);
      this.controls.description.setValue(page.metaData.description);
      this.controls.noRobots.setValue(!!page.settings.noRobots);

      this.controls.mobilePageCheckbox.setValue(!!page.settings.mobilePage);

      this.controls.size.width.disabled = this.isWidthDisabled(minWidth, maxWidth);

      if (this.element.page.page.used_as === 'form_confirmation') {
        this.backgroundPanel.hide();
      } else {
        this.backgroundPanel.show();
        this.bindBackgroundControlsToElement(elm);
      }

      this.controls.textColor.setValue(elm.model.exists('style.defaults.color') ? elm.model.get('style.defaults.color') : '000');
      this.controls.linkColor.setValue(elm.model.exists('style.defaults.linkColor') ? elm.model.get('style.defaults.linkColor') : '00f');
      this.title.update('Page');
    },

    handleMultiBreakpointToggleControlVisibility: function() {
      this.mobilePagePanel.setVisible(this.isMultiBreakpointToggleVisible());
    },

    isMultiBreakpointToggleVisible: function() {
      var page = this.element.page;
      return page.isCurrentBreakpoint('mobile') && page.usedAs() !== 'form_confirmation';
    },

    handleMobilePageToggleControlVisibility: function() {
      this.controls.mobilePageCheckbox.setVisible(this.isMobilePageCheckboxVisible());
    },

    isMobilePageCheckboxVisible: function(){
      var page = this.element.page;
      if(page === null) { return; }

      return  page.usedAs() !== 'form_confirmation' &&
              !page.settings.multipleBreakpointsVisibility &&
              !page.isCurrentBreakpoint('mobile');
    },

    handleBreakpointControlsVisibility: function() {
      this.handleMultiBreakpointToggleControlVisibility();
      this.handleMobilePageToggleControlVisibility();
    },

    isWidthDisabled: function(minWidth, maxWidth) {
      return this.element.page.isMobileFormConfirmation() ||
        (minWidth === maxWidth) && !this.element.page.isFormConfirmation();
    },

    sizeControlsChanged: function() {
      var v = this.controls.size.getValue().width;
      if (v < 16) {
        v = 16;
        this.controls.size.setValue({width:16});
      }
      this.element.model.set('geometry.contentWidth', v, this.element.page.undoManager);
    }
  }
);
