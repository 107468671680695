lp.editor.PageTabBar = Class.create(jui.Component, {
  options: function($super,options){
    return $super($H({attributes:{className:'tab-bar'}}).merge(options));
  },

  initialize: function($super, editor, options) {
    this.editor = editor;
    this.tabs = [];
    this.tabGroup = new jui.RadioButtonGroup();
    this.controls = {};
    this.editor.addListener('activePageChanged', this);
    $super(options);
  },

  installUI: function($super) {
    $super();
    var self = this;
    var settingsBar = this.insert(new jui.Component({attributes:{id:'settings-bar'}}));

    if (lp.isResponsiveEnabled()) {
      this.controls.showPageSectionProtrusion = settingsBar.insert(new jui.FormCheckboxInput({
        label:'Out-of-bound warnings',
        onclick:function() {
          var show = self.controls.showPageSectionProtrusion.getValue();
          self.editor.page.settings.showPageSectionProtrusion = show;
          window.pageSectionProtrusionUI.setVisible(show);
        }
      }));
    }

    this.controls.showSectionBoundaries = settingsBar.insert(new jui.FormCheckboxInput({
      label:'Section guides',
      onclick:function() {
        var show = self.controls.showSectionBoundaries.getValue();
        self.editor.page.settings.showSectionBoundaries = show;
        self.editor.sectionBoundaryManager.setVisible(show);
      }
    }));

    this.controls.showPageTransformBox = settingsBar.insert(new jui.FormCheckboxInput({
      label:'Page guides',
      onclick:function() {
        var show = self.controls.showPageTransformBox.getValue();
        self.editor.page.settings.showPageTransformBox = show;
        self.editor.pageBoundaryManager.setVisible(show);
      }
    }));

    settingsBar.insert(new Element('p',{className:'label'}).update('Show:'));
  },

  activePageChanged: function(e) {
    var page = e.data.current;
    var tab = this.tabs.find( function(tab) {return tab.page === page;});
    if (!Object.isUndefined(tab)) {
      this.tabGroup.setActiveButton(tab.tab);
      this.changeSettingsControls(page);
    }
  },

  changeSettingsControls: function(page) {
    if (lp.isResponsiveEnabled()) {
      var showPageSectionProtrusion = page.settings.showPageSectionProtrusion;
      this.controls.showPageSectionProtrusion.setValue(showPageSectionProtrusion);
      window.pageSectionProtrusionUI.setVisible(showPageSectionProtrusion);
    }
    this.controls.showSectionBoundaries.setValue(page.settings.showSectionBoundaries);
    this.controls.showPageTransformBox.setValue(page.settings.showPageTransformBox);
  },

  addPage: function(page, position) {
    var label = page.getUsedAsText()
      , pageTypeClass = page.isMain() ? 'lp-main' : 'lp-form';

    var tab = this.insert(new jui.RadioButton({
      label:label,
      radioGroup:this.tabGroup,
      action:this.editor.setActivePage.bind(this.editor,page),
      attributes:{className:'tab ' + pageTypeClass}
    }), position);

    this.tabs.push({page:page, tab:tab});
  },

  removePage: function(page) {
    var tab = this.tabs.find( function(tab) {return tab.page === page;});
    if (!Object.isUndefined(tab)) {
      this.tabs = this.tabs.without(tab);
      tab.tab.remove();
    }
  }
});
