/*global olark */
lp.editor.OlarkChatFocusManager = Class.create({
  initialize: function($super){
    /*jshint unused:false */
    if (typeof olark !== 'undefined') {
      /* JS: check if olark exists.
         even if it does it has not rendered its dom elements at this point so
         we have to set a callback to listen on a couple of events
      */
      olark('api.box.onShow', this.olarkChatShown.bind(this));
      olark('api.box.onHide', this.olarkChatHidden.bind(this));
    }
  },

  olarkChatShown: function() {
    this.bindToOlarkChatInput();
  },

  olarkChatHidden: function() {
    this.bindToOlarkChatInput();
    this.blur();
  },

  bindToOlarkChatInput: function() {
    if (typeof this.chatInput === 'undefined') {
      this.chatInput = document.getElementById('habla_wcsend_input');
      this.chatInput.observe('focus', this.olarkChatFocus.bind(this));
    }
  },

  olarkChatFocus: function() {
    editor.keyController.requestFocus(this);
  },

  focus: function() {
    this.chatInput.focus();
  },

  blur: function() {
    editor.keyController.removeFromFocus(this);
    this.chatInput.blur();
  }
});
