;(function(){

function NotValidCodeException(){}

lp.module.video.VideoElement = Class.create(
  lp.pom.VisibleElement,
  lp.ModuleComponent,
  {
    type:'lp-pom-video',

    initView: function() {
      this.createEmbedContainer();
      this.createOverlay();

      this.insertEmbedCode();

      var size = this.model.getSize();

      this.model.set( 'geometry.maintainAR', true );
      this.model.set( 'geometry.aspectRatioa', size.width/size.height);

      if(this.page.isPublishOrPreviewMode()) {
        this.page.addInsertion(this.insertVideoJSNode(), 'head');
      }
    },

    insertVideoJSNode: function() {
      var obj = {};
      this.page.getBreakPoints().each(function(breakpoint){
        if(this.model.exists('geometry.size')) {
          obj[breakpoint.name] = this.model.getReadableModel(breakpoint).geometry.size;
        }
      }, this);

      return '<script>\n'+
        'window.lp = window.lp || {};\n' +
        'window.lp.video = window.lp.video || {};\n' +
        'window.lp.video.videos = window.lp.video.videos || {};\n' +
        'window.lp.video.videos["'+this.id+'"] = window.lp.video.videos["'+this.id+'"] || {};\n' +
        'window.lp.video.videos["'+this.id+'"].dimensions ='+Object.toJSON(obj)+' ;\n' +
        '</script>';
    },

    createEmbedContainer: function() {
      this.content = new Element(
        'div', { className: 'lp-video-container' }
      );
      this.getViewDOMElement().insert(this.content);
    },

    createOverlay: function() {
      if( this.page.context === lp.pom.context.EDIT ) {
        this.overlay = new Element(
          'div', { className: 'lp-video-overlay' }
        ).setOpacity( 0.75 );
        this.insert( this.overlay );
      }
    },

    dblclick: function(e) {
      Event.stop(e);
      var self = this;
      this.getModule().openBuilder( this, {
        callback: function(data) {
          self.model.set('content.html', data.html, self.page.undoManager);
        }
      } );
    },

    getModelClass: function() {
      return lp.module.video.VideoModel;
    },

    //Insert the embed code into the element.  If we have the embed code
    //then use that other wise ask the model for it.
    insertEmbedCode: function(contentHTML) {
      try {
        contentHTML = contentHTML || this.model.get('content.html');
        if(this.page.context !== lp.pom.context.EDIT) {
          this.getViewDOMElement().innerHTML = '';
          this.page.addInsertion(contentHTML, 'div#' + this.model.get('id'));
        } else {
          this.content.update(contentHTML);
        }
        this.setWmode();
        this.contentInserted = true;
        this.resizeBox();
      } catch(e) {
        if(e instanceof NotValidCodeException) {
          window.editor.reportError({
            error: e,
            id: window.editor.page.id,
            message: 'Not valid video content',
            details: e.toString().truncate(300, ''),
            userAgent: window.navigator.userAgent
          });
        }
      }
    },

    //Handles the add wmode to both object and iframe elements.  setting wmode
    //to opaque will make these two elements and flash behave properly
    //regarding z-index.
    setWmode: function() {
      var videoObj = this.getVideo();
      var tag = videoObj.nodeName.toUpperCase();
      if( tag === 'IFRAME' ) {
        this.setWmodeIframe(videoObj);
      } else if( tag === 'OBJECT' ) {
        var obj = this.setWmodeObject(videoObj);
        var temp = new Element( 'div' ).insert(obj);
        this.content.update(temp.innerHTML);
      } else {
        throw new NotValidCodeException();
      }
    },

    //Adds wmode to the iframe element so that way the z-index of videos
    //works as expected.
    setWmodeIframe: function( videoObj ) {
      if(!this.hasWmode( videoObj ) ) {
        var paramPattern = new RegExp( /\?/g );
        var l = paramPattern.test( videoObj.src ) ? '&' : '?';
        var newSrc = decodeURIComponent(videoObj.src) + l + 'wmode=opaque';
        videoObj.src = newSrc;
      }
    },

    //Adds wmode to the object element so that way the z-index of videos
    //works as expected.
    setWmodeObject: function( videoObj ) {
      if( !this.hasWmode( videoObj ) ) {
        var embedObj = videoObj.select( 'embed' ).first();

        var paramTag = new Element(
          'param', { name: 'wmode', value: 'opaque' }
        );

        embedObj.writeAttribute( 'wmode', 'opaque' );
        videoObj.insert( paramTag, 'top' );
      }
      return videoObj;
    },

    hasWmode: function( videoObject ) {
      if( videoObject.nodeName.toLowerCase() === 'object' ) {
        return this.hasWmodeObj( videoObject );
      } else if( videoObject.nodeName.toLowerCase() === 'iframe' ) {
        return this.hasWmodeIframe( videoObject );
      }
    },

    //Determines if the wmode has been injected into the object element yet
    //or not.
    hasWmodeObj: function( videoObj ) {
      var eles = videoObj.childElements();
      var found = false;
      eles.each( function(ele) {
        if( ele.nodeName.toUpperCase() === 'PARAM' &&
         ele.readAttribute( 'name' ).toLowerCase() === 'wmode' ) {
          found = true;
          return;
        }
      } );
      return found;
    },

    hasWmodeIframe: function( videoObj ) {
      var wmodePattern = new RegExp( /wmode/gi );
      return wmodePattern.test( videoObj.src );
    },

    resizeBox: function() {
      if( this.page.context === lp.pom.context.EDIT ) {
        //TODO-SD: This is to solve a problem where <object> does not return
        //height. being that this is hackey this should be investigated more.
        this.model.setSize(this.getVideoDimensions());
      }
    },

    getVideoDimensions: function() {
        var videoObj = this.getVideo();
        var temp =  new Element( 'div' ).insert( videoObj.clone() );
        var dimensions;

        temp.setStyle({
          position: 'absolute',
          left: '-999999px',
          visibility: 'hidden'
        });

        document.body.insert(temp);
        //This is a horrible way of doing things, for now it will do.
        //The problem that I am having is that object does not return
        //it's dimensions even after putting it into the DOM.  This
        //entire function needs to be looked at.
        if( videoObj.nodeName.toUpperCase() === 'IFRAME' ) {
          dimensions = temp.select( 'iframe' ).first().getDimensions();
        } else if( videoObj.nodeName.toUpperCase() === 'OBJECT' ) {
          var obj = temp.select( 'object' ).first();
          if( obj.readAttribute( 'width' ) && obj.readAttribute( 'height' ) ){
            dimensions = {
              width: parseInt( obj.readAttribute( 'width' ), 10 ),
              height: parseInt( obj.readAttribute( 'height' ), 10 )
            };
          } else {
            dimensions = {
              width: parseInt( obj.getStyle( 'width' ), 10 ),
              height: parseInt( obj.getStyle( 'height' ), 10 )
            };
          }
        }

        temp.remove();
        return dimensions;
    },

    getVideo: function() {
      var videoObj = this.content.select( 'iframe' ).first() ||
        this.content.select( 'object' ).first();
      if(!videoObj){throw 'Not a valid video type';}
      return videoObj;
    },

    setDimensions: function($super, value) {
      $super(value);

      if(this.page.isPublishOrPreviewMode()){return;}

      var embed;
      if(value) {
        var obj = this.getVideo();

        if( obj.getAttribute( 'style' ) ){
          obj.setStyle({
            width: value.width + 'px',
            height: value.height + 'px'
          });
        } else {
          obj.writeAttribute( 'width', value.width );
          obj.writeAttribute( 'height', value.height );
        }

        if( obj.nodeName.toUpperCase() === 'OBJECT' ) {
          embed = obj.select( 'embed' ).first();
          embed.writeAttribute( 'width', value.width );
          embed.writeAttribute( 'height', value.height );
        }
      }
    },

    updateData: function() {
      if (this.content) {
        this.model.set('content.html', this.content.innerHTML);
      }
    },

    modelChanged: function($super, e) {
      var details = $super( e );
      if (details.accessor === 'content.html') {
        this.insertEmbedCode(details.value);
      } else {
        this.updateData();
      }
    },

    deactivate: function( $super ) {
      if( this.overlay.getStyle( 'display' ) !== 'block' ) {
        this.resetVideo();
        this.overlay.style.display = 'block';
      }
     $super();
    },

    resetVideo: function() {
      var obj = this.getVideo();
      obj.src = obj.src;
    }
  }
);

lp.module.video.VideoElement.elementDefaults = {
  name: 'Embed Video',
  geometry:{
    position:"absolute",
    offset:{top:0,left:0},
    size: {width: 400, height: 300}
  },
  content:{
    html: ''
  }
};
})();
