//  require <jui>
jui.ColorPickerButton = Class.create(jui.Component, jui.ControlModel, {
  options: function($super,options){
    return $super($H({attributes:{className:'color_picker_button'}}).merge(options));
  },

  initialize: function($super, options) {
    $super(options );
    jui.ColorPickerButton.colorPickerDialog = jui.ColorPickerButton.colorPickerDialog || new jui.ColorPickerDialog();


    if (this.options.action) {
      this.addListener('actionPerformed', this.options.action);
    }
  },

  installUI: function($super) {
    $super();
    this.swatch = this.insert(new Element('div', {className:'swatch'}));
    this.arrow = this.insert(new Element('div', {className:'arrow'}));
    this.e.observe('click', this.onClick.bindAsEventListener(this));
  },

  setColor: function(hex) {
    this.swatch.style.backgroundColor = hex && hex.length > 2 ? '#'+hex : '';
  },

  getColor: function(text) {
    /* jshint unused:vars */
    return this.toValidHexColor(this.swatch.style.backgroundColor);
  },

  colorPicked: function(hex) {
    this.setColor(hex);
    this.fireEvent( 'actionPerformed', this.getColor());
  },

  toValidHexColor: function(value) {
    if (value.startsWith('#')) {
      return value.sub('#', '');
    } else if (value.startsWith('rgb')) {
      // WTF? TODO replace this
      return eval(value.sub('rgb(', '[').sub(')', ']')).invoke('toColorPart').join('');
    } else {
      return value;
    }
  },

  onClick: function(e) {
    if(this.isEnabled()) {
      e.stop();
      jui.ColorPickerButton.colorPickerDialog.open(this.getColor(), {callback:this.colorPicked.bind(this)});
    }
  }
});

jui.ColorPickerButton.colorPickerDialog = null;
