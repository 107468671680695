/* globals Raven */
;(function() {
  window.RavenBaseContext = {};
  var RavenDsn = 'https://0eb46e736b37496dbcd77fd3d8685794@app.getsentry.com/31180';
  var ravenOptions = {
    // http://raven-js.readthedocs.org/en/latest/tips/index.html
    // http://raven-js.readthedocs.org/en/latest/config/index.html
    // see https://gist.github.com/impressiver/5092952 for ideas
    // Will cause a deprecation warning, but the demise of `ignoreErrors` is still under discussion.
    // See: https://github.com/getsentry/raven-js/issues/73
    whitelistUrls: [
      /undefined/ // for console log messages, etc
      , /.*-dev/
      , /localhost/
      , /.*\.unbounce\.com/
      , /s3\.amazonaws\.com/
      , /d2xxq4ijfwetlm.\cloudfront\.net/
    ],
    //ignoreErrors: [    ],
    ignoreUrls: [
      // Facebook flakiness
        /graph\.facebook\.com/i,
      // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i
    ],
    fetchContext: true,
    collectWindowErrors: true
  };
  if (window.RavenStub) {
    // loaded in jasmine tests
    window.Raven = new window.RavenStub();
  } else {
    try {
      Raven.config(RavenDsn, ravenOptions).install();
      if (window.gon) {
        var context = _.merge({}, gon, gon.server_metadata || {});
        context.editor_version = window.builderVersion || 'dev';
        delete context.server_metadata;
        delete context.user;
        window.RavenBaseContext = context;
        Raven.setTagsContext(context);
        if (gon.user) {
          Raven.setUserContext(gon.user);
        }
      }
    } catch (e) {
      if (console) {
        console.error(e);
      }
    }
  }

})();
