lp.module.button.ButtonModel = Class.create(lp.pom.VisibleElementModel, {
  initialize: function($super, element, jso) {
    this.states = ['up', 'hover', 'active'];
    $super(element, jso);

    this.addBreakpointModifyableAttributes({
      geometry:{
        offset:{
          left:true,
          top:true
        },
        size:{
          width:true,
          height:true
        },
        contentWidth:true,
        scale:true,
        aspectRatio: true,
        visible:true
      },
      computations: {
        labelHeight: true
      }});
      this.addBreakpointModifyableAttributes({
        style: {// merged with base props from visible_element
          up: true,
          hover: true,
          active: true,
          fillType: true,
          border: true,
          textShadow: true,
          highlight: true,
          fontFamily: true,
          fontSize: true,
          fontWeight: true
        }
      });

    this.addBreakpointAllowDuplicateAttributes({
      geometry:{
        visible:true
      }
    });
  },

  isAttributeModifyableForBreakpoint: function($super, key) {
    // NOTE: This was required as part of LP-6559 as the base class
    // implementation requires absolute matches rather than prefix
    // matches. TODO-TR: review other modules for bugs that are
    // related to this absolute matching
    if ((key.startsWith('style.up') ||
          key.startsWith('style.hover') ||
          key.startsWith('style.active') )) {
      return true;
    } else {
      return $super(key);
    }
  },

  // TODO-TR: LP-6616 move this up to element_model
  addBreakpointModifyableAttributes: function(object) {
    _.merge(this.breakpointModifyableAttributes, object);
  },

  addBreakpointAllowDuplicateAttributes: function(object) {
    _.merge(this.breakpointAllowDuplicateAttributes, object);
  },

  getCurrentFillType: function() {
    if (this.exists('style.fillType')) {
      return this.get('style.fillType');
    }

    if (this.exists('style.up.image')) {
      return 'image';
    }

    if (this.exists('style.up.gradient')) {
      return 'gradient';
    }

    return 'solid';
  },

  setFont: function(font){
    var style = jui.clone(this.get('style'));

    style.fontFamily = font.family;
    style.fontWeight = font.weight;
    style.fontSize = font.size;

    this.set('style', style, this.element.page.undoManager);
  },

  setFillType: function(fillType) {
    if (fillType === this.getCurrentFillType()) {
      return;
    }

    var undoManager = this.element.page.undoManager;
    undoManager.startGroup();
    this.set('style.fillType', fillType, undoManager);

    if (fillType === 'gradient') {
      this.set(
        'style.up.backgroundColor',
        this.getSavedBackgroundColor('up'),
        undoManager
      );
    }

    undoManager.endGroup();
  },


  setBackgroundColor: function(color, state) {
    var bgColPath = 'style.'+state+'.backgroundColor';
    var undoManager = this.element.page.undoManager;

    undoManager.startGroup();
    if (color === 'transparent') {
      // if the new color is being set to transparent then save the current color
      if(this.get(bgColPath).strip() !== '' && this.get(bgColPath) !== 'transparent') {
        this.set('style.'+state+'.savedBackgroundColor', this.get(bgColPath), undoManager);
      }
    } else {
      // else make sure saved color is same as new color
      this.set('style.'+state+'.savedBackgroundColor', color, undoManager);
    }

    this.set(bgColPath, color, undoManager);
    if (this.isAutoGradient()) {
      var gradient = this.calculateGradient(color === 'transparent' ?
                                            this.getSavedBackgroundColor() : color);
      this.setGradientColor(gradient, state);
    }
    undoManager.endGroup();
  },

  setOpacity: function(opacity, state) {
    this.set('style.' + state + '.opacity', opacity, this.element.page.undoManger);
  },

  getSavedBackgroundColor: function(state) {
    if (this.exists('style.'+state+'.savedBackgroundColor')) {
      return this.get('style.'+state+'.savedBackgroundColor');
    }

    var bgColor = this.element.getElementDefaults().style.up.backgroundColor;

    if (state && state !== 'up') {
      bgColor = this['calculate'+state.capitalize()+'Color'](bgColor);
    }

    return bgColor;
  },

  setImage: function(imageData, state) {
    state = state || 'up';
    if (imageData !== null) {
      jui.utils.loadImage(imageData.content_url, this.imageLoaded.bind(this, imageData, state));
    } else {
      var style = jui.clone(this.get('style'));
      style[state].auto = true;
      delete style[state].image;
      this.set('style', style, this.element.page.undoManager);
    }
  },

  imageLoaded: function(imageData, state, imageObj) {
    var style = jui.clone(this.get('style'));

    style.fillType = 'image';

    if (imageData !== null) {
      if (state !== 'up') {
        style[state] = this['generate'+state.capitalize()+'State']();
      }
      style[state].image = jui.clone(imageData);
      delete style[state].auto;
    } else {
      style[state].auto = true;
      delete style[state].image;
    }

    var undoManager = this.element.page.undoManager;

    undoManager.startGroup();
    this.set('style', style, undoManager);
    if (state === 'up') {
      this.updateImageDimensions(imageObj.width, imageObj.height, undoManager);
    }
    undoManager.endGroup();
  },

  fitToImage: function() {
    if (!this.exists('style.up.image')) {
      return;
    }

    var self = this;

    jui.utils.loadImage(this.get('style.up.image.content_url'), function(img) {
      self.updateImageDimensions(img.width, img.height, self.element.page.undoManager);
    });
  },

  updateImageDimensions: function(w, h, um) {
    this.set('geometry.size', {width:w, height:h}, um);
  },

  setAutoGradient: function(auto) {
    var um = this.element.page.undoManager;
    um.startGroup();
    this.set('style.autoGradient', auto, um);
    if (auto) {
      this.states.each( function(state) {
        if (!(this.exists('style.'+state+'.auto') && this.get('style.'+state+'.auto'))) {
          var gradient = this.calculateGradient(this.get('style.'+state+'.backgroundColor'));
          this.setGradientColor(gradient, state);
        }
      }, this);
    }
    um.endGroup();
  },

  setGradientColor: function(color, state) {
    var reverseSelector = 'style.' + state + '.reverseGradient';
    if(this.exists(reverseSelector) && this.get(reverseSelector)) {
      var to = color.to;
      color.to = color.from;
      color.from = to;
    }

    this.set('style.'+state+'.gradient', {
      from: color.from,
      to: color.to
    }, this.element.page.undoManager);
  },

  textShadowClicked: function(bool) {
    var um = this.element.page.undoManager;
    if (bool) {
      this.element.model.set('style.textShadow', this.calculateTextShadow(), um);
    } else {
      this.element.model.deleteAttr('style.textShadow', um);
    }
  },

  highlightClicked: function(bool) {
    var um = this.element.page.undoManager;
    if (bool) {
      this.element.model.set('style.highlight', this.calculateHighlight(), um);
    } else {
      this.element.model.deleteAttr('style.highlight', um);
    }
  },

  calculateHighlight: function(fromColor, toColor) {
    var htr = jui.ColorMath.hexToRgb;
    var rth = jui.ColorMath.rgbToHex;
    var shadowColor;
    fromColor = htr(fromColor);
    var c_hsv = jui.ColorMath.rgbToHsv(fromColor);
    // the highlight will be a version of the original colour that has 30%
    // less saturation and 10% more brightness
    var highlightColor = jui.ColorMath.hsvToRgb([
      c_hsv[0],
      Math.max(0, c_hsv[1] - 0.3),
      Math.min(1, c_hsv[2] + 0.1)
    ]);
    // the shadow will be a version of the original colour (if only a fromColor is passed
    // in than that color is used otherwise we create the shadow from the toColor) that
    // has 50% less brightness.
    if(toColor) {
      toColor = htr(toColor);
      shadowColor = this.getShadowColorForHighlight(jui.ColorMath.rgbToHsv(toColor));
    } else {
      shadowColor = this.getShadowColorForHighlight(jui.ColorMath.rgbToHsv(fromColor));
    }

    var highlight = 'inset 0px 1px 0px #' + rth(highlightColor);
    var shadow = 'inset 0 -1px 2px #' + rth(shadowColor);
    return highlight + ', ' + shadow;
  },

  getShadowColorForHighlight: function(hsvColor) {
    return jui.ColorMath.hsvToRgb([
      hsvColor[0],
      hsvColor[1],
      Math.max(0, hsvColor[2] - 0.2)
    ]);
  },

  calculateShadow: function(color) {
    var htr = jui.ColorMath.hexToRgb;
    var rth = jui.ColorMath.rgbToHex;
    color = htr(color);
    var c_hsv = jui.ColorMath.rgbToHsv(color);
    // the highlight will be a version of the original colour that has 50%
    // less brightness (so it is now a shadow - for active state)
    var c_highlight = jui.ColorMath.hsvToRgb([c_hsv[0], c_hsv[1], Math.max(0, c_hsv[2] - 0.5)]);

    var highlight = 'inset 0px 2px 4px #' + rth(c_highlight);
    return highlight;
  },

  calculateTextShadow: function(color) {
    var htr = jui.ColorMath.hexToRgb;
    var c_hsv = jui.ColorMath.rgbToHsv(htr(color));
    var c_blend = jui.ColorMath.hsvToRgb([c_hsv[0], c_hsv[1], Math.max(0, c_hsv[2] - 0.5)]);
    return '1px 1px #'+jui.ColorMath.rgbToHex(jui.ColorMath.blend("multiply", htr(color), c_blend));
  },

  hasTextShadow: function(){
    return this.exists('style.textShadow') && this.get('style.textShadow');
  },

  hasHighlight: function(){
    return this.exists('style.highlight') && this.get('style.highlight');
  },

  applyAppearanceDefaults: function() {
    var um = this.element.page.undoManager;
    var defaults = this.element.getElementDefaults();
    var defaultStyle = jui.clone(defaults.style);
    // JS: Keep the font size the same because of the relationship to size
    defaultStyle.fontSize = this.get('style.fontSize');
    defaultStyle.fontWeight = this.exists('style.fontWeight') ? this.get('style.fontWeight') : 'normal';

    um.startGroup();
    this.set('geometry.borderLocation', defaults.geometry.borderLocation, um);
    this.set('geometry.cornerRadius', defaults.geometry.cornerRadius, um);
    this.set('style', defaultStyle, um);
    um.endGroup();
  },

  applyLabelDefaults: function() {
    var um = this.element.page.undoManager;
    var defaults = this.element.getElementDefaults();

    um.startGroup();
    this.set('style.fontSize', defaults.style.fontSize, um);
    this.set('style.fontWeight', defaults.style.fontWeight, um);
    this.set('style.fontFamily', defaults.style.fontFamily, um);
    um.endGroup();
  },

  setAutoGenerateState: function(value, state) {
    if (value) {
      this.set('style.'+state, {auto: true}, this.element.page.undoManager);
    } else {
      var style = this['generate'+state.capitalize()+'State'](value);
      style.auto = false;
      this.set('style.'+state, style, this.element.page.undoManager);
    }
  },

  calculateHoverColor: function(orig) {
    var htr = jui.ColorMath.hexToRgb;
    var rth = jui.ColorMath.rgbToHsv;
    var orig_rgb = htr(orig);
    var orig_hsv = rth(orig_rgb);
    // original hue with 20% saturation and 95% brightness
    var blend_with = jui.ColorMath.hsvToRgb([orig_hsv[0], 0.2, 0.95]);
    return jui.ColorMath.rgbToHex(jui.ColorMath.blend("multiply", orig_rgb, blend_with));
  },

  calculateActiveColor: function(orig) {
    var htr = jui.ColorMath.hexToRgb;
    var rth = jui.ColorMath.rgbToHsv;
    var orig_rgb = htr(orig);
    var orig_hsv = rth(orig_rgb);
    // original hue with 30% saturation and 90% brightness
    var blend_with = jui.ColorMath.hsvToRgb([orig_hsv[0], 0.3, 0.90]);
    return jui.ColorMath.rgbToHex(jui.ColorMath.blend("multiply", orig_rgb, blend_with));
  },

  generateHoverState: function() {
    var upState = this.get('style.up');
    var bgCol = upState.backgroundColor && upState.backgroundColor !== 'transparent' ?
      this.calculateHoverColor(upState.backgroundColor) :
      'transparent' ;
    var hasBorder = this.exists('style.hover.border') &&
      this.exists('style.hover.border.color');
    var hoverState = {
      backgroundColor: bgCol,
      color: this.exists('style.hover.color') ? this.get('style.hover.color') : upState.color,
      opacity: upState.opacity,
      border:{
        border: upState.border.style,
        color: hasBorder ? this.get('style.hover.border.color') : upState.border.color,
        width: upState.border.width
      }
    };

    if (upState.gradient) {
      hoverState.gradient = {
        type: upState.gradient.type,
        from: this.calculateHoverColor(upState.gradient.from),
        to: this.calculateHoverColor(upState.gradient.to)
      };
    }

    hoverState.image = upState.image;

    return hoverState;
  },

  generateActiveState: function(auto) {
    var upState = this.get('style.up');
    var bgCol;
    bgCol = upState.backgroundColor && upState.backgroundColor !== 'transparent' ?
      this.calculateActiveColor(upState.backgroundColor) : 'transparent';
    var styleActiveDefined = this.exists('style.active');
    var hasBorder = this.exists('style.active.border') &&
      this.exists('style.active.border.color');
    var activeState = {
      backgroundColor: bgCol,
      opacity: upState.opacity,
      color: styleActiveDefined && this.exists('style.active.color') ?
        this.get('style.active.color') : upState.color,
      border:{
        border: upState.border.style,
        color: styleActiveDefined && hasBorder ?
          this.get('style.active.border.color') : upState.border.color,
        width: upState.border.width
      }
    };

    if (auto && upState.gradient) {
      activeState.gradient = {
        type: upState.gradient.type,
        from: upState.gradient.to,
        to: upState.gradient.to
      };
    } else if(upState.gradient) {
      activeState.gradient = {
        type: upState.gradient.type,
        from: upState.gradient.from,
        to: upState.gradient.to
      };
      activeState.reverseGradient = true;
    }

    activeState.image = upState.image;
    return activeState;
  }
});
