lp.pom.updates.add({
  minVersion:'1.2',
  version:'1.3',
  run: function(jso) {
    var current = jso.version || '0';
    if (current >= this.version) {
      return;
    }

    if (current < this.minVersion) {
      throw "Page version "+current+" can't be updated to version "+this.version;
    }

    // fixes form ids
    jso.elements.each(function(e) {
      switch (e.type) {
        case 'lp-pom-form':
          e.content.fields.each( function(f) {
            f.id = f.id.gsub('_',' ').stripTags().gsub(' ','_');
          });
          break;
      }
    }, this);

    jso.version = this.version;
  }
});
