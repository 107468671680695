lp.module.text.TextElementPropertiesPanel = Class.create(
  lp.editor.panel.ElementPropertiesPanel,
  lp.editor.panel.ElementGeometryControls,
  lp.editor.panel.ElementBackgroundControls,
  lp.editor.panel.ElementBorderControls,
  lp.ModuleComponent,
  {
    type:'lp-pom-text',

    initialize: function($super) {
      $super();
    },

    installUI: function($super){
      $super();
      var geometryPanel = this.insert(new jui.CollapsiblePanel({title:'Geometry'}));
      this.installGeometryControls({
        insert:geometryPanel.insertContent.bind(geometryPanel),
        sizeOptions:{disableHeight:true}
        });
      var borderPanel = this.insert(new jui.CollapsiblePanel({title:'Border Properties'}));
      this.installBorderControls({insert:borderPanel.insertContent.bind(borderPanel), hasRadiusControl: false });
      var backgroundPanel = this.insert(new jui.CollapsiblePanel({title:'Background Properties'}));
      this.installBackgroundControls({insert:backgroundPanel.insertContent.bind(backgroundPanel)});
    },

    geometryControlsChanged: function(){
      this.updatingGeometry = true;
      var m = this.element.model;
      var position = m.exists('geometry.position') ? m.get('geometry.position') : 'absolute';

      var um = m.element.page.undoManager;
      um.startGroup();
      var offset = position === 'absolute'? this.controls.offset.getValue() : {left:0,top:0};
      m.setOffset(offset, um);
      this.element.view.e.style.height = '';
      m.setWidth(this.controls.size.getValue().width, um);
      // m.setHeight(this.element.getHeight());
      um.endGroup();

      this.updatingGeometry = false;
      this.controls.size.setValue(m.get('geometry.size'));
    },

    syncUIControls: function($super, elm) {
      $super(elm);
      this.bindGeometryControlsToElement(elm);
      this.bindBackgroundControlsToElement(elm);
      this.bindBorderControlsToElement(elm);
    }
  }
);
