(function(){
  var $ = jQuery;

  lp.editor.DynamicTextDialog = Class.create(jui.ModalPanel, {

    /**
     *
     * Public
     *
     **/

    KEYCODES : {
      enter:  13,
      esc  :  27
    },

    options: function($super, options) {
      return $super($H({attributes:{id:'dynamic-text-overlays'}}).merge(options));
    },

    initialize: function($super, options){
      $super(options);
      this._clearErrors();
      this._addEvents();
    },

    installUI: function($super) {
      $super();
      this.dialogWrapper    = this._dialogWrapper();
      this.dialogWindow     = this._dialogWindow();

      this.header           = this._header();
      this.formContainer    = this._formContainer();

      this.dialogFeatureInfo = this._dialogFeatureInfo();

      this.parameter        = this._parameter();
      this.textDefaultText  = this._textDefaultText();

      this.textTransform    = this._textTransform();

      this.footer           = this._footer();

      this.okButton         = this._okButton();
      this.cancelButton     = this._cancelButton();
      this.closeButton      = this._closeButton();

      this.errorMsg         = {};
      this.errorMsg.textDefaultText = new jui.FieldErrorMessage({
        field: this.textDefaultText
      });
      this.errorMsg.parameter       = new jui.FieldErrorMessage({field: this.parameter});

      this._hideFormErrors();
    },

    insertDynamicText: function() {
      if (this._formIsValid()) {
        this._insertDynamicTextElement();
      } else {
        this._showFormErrors();
      }
    },

    open: function($super, defaultText, attributes, callback) {
      this._hideFormErrors();
      this.insertDynamicTextCallback = callback;
      this._syncControls(defaultText, attributes);
      $super();
      this.parameter.focusAndSelect();
    },

    close: function($super) {
      delete this.textSelection;
      $super();
      this._hideFormErrors();
      jQuery('#text-transform-type').html('&nbsp;');
      if (this.options.onClose) { this.options.onClose(); }
      this.fireEvent('onDynamicTextDialogWindowClose', this);
    },

    /**
     *
     * Private
     *
     **/

    _addEvents: function() {
      var self = this;
      this.e.observe('keydown', function(e){
        if(e.keyCode === self.KEYCODES.esc) {
          self.close();
        } else if(e.keyCode === self.KEYCODES.enter) {
          e.stop();
          self.insertDynamicText();
        }
      }, this);
    },

    _clearErrors: function() {
      this.errors = [];
      this.textDefaultText.e.removeClassName('error');
      this.parameter.e.removeClassName('error');
      this.errorMsg.parameter.hide();
      this.errorMsg.textDefaultText.hide();
    },


    _dialogWrapper: function() {
      return this.content.insert(new jui.Component({
        attributes: {className: 'dynamic-wrapper'}
      }));
    },

    _dialogWindow: function() {
      return this.dialogWrapper.insert(new jui.Component({
        attributes : {className : 'clearfix dynamic-text-dialog condensed'}
      }));
    },

    _formInputWidth: '302px',

    _header: function() {
      var header = this.dialogWindow.insert(
        new Element('header', { className: 'dynamic-dialog-title' })
      );

      this.title = new Element('h2');
      this.title.innerHTML = 'Dynamic Text';

      header.insert(this.title);
      return header;
    },

    _formContainer: function() {
      return this.dialogWindow.insert(new jui.Component({
        attributes: { className: 'formContainer clearfix' }
      }));
    },

    _dialogFeatureInfo: function() {
      var dialogFeatureInfo = this.formContainer.insert(new jui.Component({
            attributes: { className: 'dialog-feature-info' }
          })),
          knowledgeIcon = new Element ('img', {
            alt:"Knowledgebase_icon",
            src:'/images/knowledge_base_icon.png'
          }),
          paragraph = new Element('p')
            .update("Increase the quality score, ad rank and message match " +
                    "of your paid advertising by passing in text "+
                    "dynamically using URL parameters. "),
          moreInfoLink = new Element('a', {
              href:'http://support.unbounce.com/entries/26045335-1-What-Can-I-Do-With-Dynamic-Text-Replacement',
              target: '_blank'
            }).update('Learn more about dynamic text replacement »');

      paragraph.insert(moreInfoLink);

      dialogFeatureInfo.insert(knowledgeIcon);
      dialogFeatureInfo.insert(paragraph);
      return dialogFeatureInfo;
    },

    _textDefaultText: function() {
      return this.formContainer.insert(new jui.FormTextInputWithHint({
        label       : 'Default Text',
        tabindex  : 2,
        attributes  : {
          className : 'default-text text-input-with-hint-container',
          id        : 'jui-dynamic-default-text'
        },
        hintText    : 'Default text will be applied when there is no URL parameter defined',
        width       : this._formInputWidth
      }));

    },

    _parameter: function() {
      return this.formContainer.insert(new jui.FormTextInput({
        label      : 'URL Parameter',
        tabindex  : 1,
        attributes : {
          id : 'jui-parameter-name',
          className: 'text-input-container'
        }
      }));
    },

    _selectOptions : [
      {name : 'Select Text Style', value   : ''},
      {name : 'UPPERCASE',         value   : 'uppercase'},
      {name : 'lowercase',         value   : 'lowercase'},
      {name : 'Title Case',        value   : 'titlecase'},
      {name : 'Sentence case',     value   : 'capitalized'}
    ],

    _textTransform: function() {
      return this.formContainer.insert(new jui.FormSelectWithHint({
        label         : 'Text Style',
        tabindex      : 3,
        attributes    : {
          className   : 'text-transform jui-form-select',
        },
        id            : 'jui-dynamic-text-transform',
        width         : this._formInputWidth,
        selectOptions : this._selectOptions,
        hintText      : '<span id="text-transform-type">&nbsp;</span>',
        action        : this._updateTypeHint.bind(this)
      }));
    },

    _updateTypeHint: function(e){
      var exampleText,
          message;

      switch(e.data) {
        case 'uppercase':
          exampleText = 'YOUR TEXT';
        break;
        case 'titlecase':
          exampleText = 'Your Text';
        break;
        case 'capitalized':
          exampleText = 'Your text';
        break;
        case 'lowercase':
          exampleText = 'your text';
        break;
        default:
          exampleText = '';
      }
      if (exampleText.length > 0) {
        message = 'Example: ' + exampleText;
      } else {
        message = '&nbsp;';
      }

      this.textTransform.hint.update(message);
    },

    _footer: function() {
      var footer = this.dialogWindow.insert(new jui.Component({
        attributes: {
          className: 'dialog-footer clearfix'
        }
      }));
      var controls = footer.insert(
        new jui.Component({
          attributes: {
            className: 'right-controls'
          }
      }));
      return controls;
    },

    _okButton: function() {
      return this.footer.insert(new jui.Button({
        label  : 'Save Dynamic Text',
        attributes: {
          tabindex: 5,
          className: 'right btn medium margin-left save blue light'
        },
        action : this.insertDynamicText.bind(this)
      }));
    },

    _cancelButton: function() {
      return this.footer.insert(new jui.Button({
        label  : 'Cancel',
        attributes: {
          tabindex: 4,
          className: 'right btn medium light cancel'
        },
        action : this.close.bind(this)
      }));
    },

    _closeButton: function() {
      return this.footer.insert(new jui.Button({
       label       : 'Close',
        attributes  : {
          className : 'dynamic-close'
        },
        action      : this.close.bind(this)
      }));
    },

    _syncControls: function(defaultText, attributes) {
      this.textTransform.select.setValue(attributes.method || '');

      this._updateTypeHint({data: attributes.method});

      this.parameter.setValue(attributes.parameter || '');
      this.textDefaultText.setValue(defaultText.trim() || '');
    },

    _showFormErrors: function() {
      this.errors.each(function(error) {
        this[error.field].e.addClassName('error');
        this.errorMsg[error.field].show(error.message);
      }, this);
    },

    _hideFormErrors: function() {
      this._clearErrors();
    },

    _getFormFieldValue: function(selector) {
      return $(selector).val().trim();
    },

    _matchesRegex: function(field, regex) {
      return this._getFormFieldValue(field).match(regex);
    },

    _isBadParameterName: function(){
      var goodNamePattern = /^([a-zA-Z0-9_\-]+)$/,
      isBad               = false,
      fieldName           = '#'+this.parameter.options.attributes.id+' input';

      if (!this._matchesRegex(fieldName, goodNamePattern) ) { isBad = true; }
      return isBad;
    },

    _isBadDefaultText: function() {
      return this.textDefaultText.getValue().trim().length === 0;
    },

    _formIsValid: function() {
      this._clearErrors();
      var isValid = true;
      this.errors = [];

      if (this._isBadParameterName()) {
        isValid          = false;
        var errorMessage =
          'URL Parameter should only have ' +
          'letters, <br /> numbers, underscores and dashes.';

        this.errors.push({
          field: 'parameter',
          message: errorMessage
        });
      }

      if(this._isBadDefaultText()) {
        isValid = false;
        this.errors.push({
          field: 'textDefaultText',
          message: 'Default text is required.'
        });
      }
      return isValid;
    },

    _getDefaultText: function() {
      return this.textDefaultText.getValue();
    },

    _getAttributes: function() {
      return {
        method: this.textTransform.getValue(),
        parameter: $.trim(this.parameter.getValue())
      };
    },

    _insertDynamicTextElement: function() {
      this.insertDynamicTextCallback(
        this._getDefaultText().trim(),
        this._getAttributes()
      );
      this.close();
    },

  });

})();
