lp.editor.InfoPanel = Class.create(jui.TabView, {
  initialize: function($super, editor, options) {
    this.editor = editor;
    this.editor.addListener('activePageChanged', this);
    this.editor.addListener('pageRootSelected', this);
    this.editor.addListener('elementActivated', this);
    this.editor.addListener('elementDeactivated', this);
    this.editor.addListener('elementRemoved', this);
    this.open = true;
    $super(options);
  },

  isTabLoaded: function() {
    return this.selectedTabViewItem && this.selectedTabViewItem.view;
  },

  installUI: function($super) {
    $super();

    var self = this;
    var selectHandler = function() {
      if (self.isTabLoaded() && Object.isFunction(self.selectedTabViewItem.view.activate)) {
        self.selectedTabViewItem.view.activate();
      }
    };

    this.addTabViewItem({
      id: 'properties',
      label: 'Properties',
      view: null,
      select: selectHandler
    });

    this.addTabViewItem({
      id: 'page',
      label: 'Page Properties',
      view: null,
      select: selectHandler
    });

    this.addTabViewItem({
      id: 'goals',
      label: 'Conversion Goals',
      view: null,
      select: selectHandler
    });
  },

  activePageChanged: function(e) {
    var page = e.data.current;
    if (page === this.editor.mainPage) {
      this.tabs.get('goals').enable();
      this.replaceTabViewItemView('goals', page.goals.getPropertiesPanel(this));
    } else {
      this.tabs.get('goals').disable();
    }
  },

  elementActivated: function(e) {
    var elm = e.data;
    var module = lp.getModule(elm.type);

    if (elm.type === 'lp-pom-root') {
      this.replaceTabViewItemView('page', module.getPropertiesPanel(elm));
      this.setTabViewItem('page');
      this.tabs.get('properties').disable();
    } else {
      if (module && module.getPropertiesPanel) {
        this.replaceTabViewItemView('properties', module.getPropertiesPanel(elm));
      }

      this.setTabViewItem('properties');
      this.tabs.get('properties').enable();
    }
  },

  elementDeactivated: function() {
    this.tabs.get('properties').disable();
  },

  elementRemoved: function(e) {
    var elm = e.data.element;
    if (this.getView('properties') !==  null && this.getView('properties').element === elm) {
      this.editor.setActiveElement(this.editor.page.getRootElement());
    }
  }
});
