lp.module.stylesheet.StylesheetElementBuilder = Class.create(
  lp.editor.BuilderModal,
  lp.ModuleComponent,
  {
    type:'lp-stylesheet',

    initialize: function($super){
      this.controls = {};
      this.currentStylesheets = [];
      this.newStylesheets = [];
      this.selectedStylesheet = null;
      this.undoGroupStarted = false;

      $super({
        actions: [{
          id: 'done',
          label: 'Done',
          action: this.done.bind(this)
        }],
        showMaximize: true,
        layoutRules: {
          method: lp.editor.BuilderModal.fitToCanvas,
          options: {
            padding: 12,
            size:{width:1000, height:600}
          }
        }
      });
    },

    installUI: function( $super ) {
      $super();
      var updateView = this.updateView.bind(this);
      this.setTitle('Manage Stylesheets');
      // var note = this.content.insert(new jui.Component({attributes:{className:'modal-dialog-note'}}));
      // note.update('<p><strong>Please Note:</strong> This is an advanced feature intended to allow you to add some custom CSS styles to external widgets you embed or any custom HTML code you place on the page via the text editor. The purpose is not to facilitate integrating the CSS from your main website.</p>');

      var manager = this.contentPanel.insert(new jui.Component({
        attributes: {
          className: 'stylesheets-manager'
        }
      }));

      this.toolBar = manager.insert(new jui.Component({attributes:{className:'toolbar'}}));
      this.controls.addStylesheet = this.toolBar.insert(new jui.Button({
        label:'Add',
        action:this.addStylesheet.bind(this)}));

      this.controls.removeStylesheet = this.toolBar.insert(new jui.Button({
        label:'Remove',
        action:this.removeSelectedStylesheet.bind(this)}));

      this.list = manager.insert(new jui.Component({attributes:{className:'list'}}));

      this.details = manager.insert(new jui.Component({attributes:{className:'details modal-right-panel'}}));
      this.controls.stylesheetName = this.details.insert(new jui.FormTextInput({
        label:'Name',
        onkeyup:updateView
      }));

      this.controls.codeEditor = this.details.insert(new jui.CodeEditor({
        label:'Paste or type stylesheets below. &lt;style&gt; or &lt;link&gt; tags must be included'
        }));

      this.warn = this.actionBar.insert(new Element('div', {
        className: 'warn'
      }).update('You must enter a valid stylesheet'));
      this.warn.hide();
    },

    updateList: function() {
      this.selectedStylesheet = null;
      this.currentStylesheets = window.editor.page.getElementsByType('lp-stylesheet').clone();
      this.list.clear();
      var ul = this.list.insert(new Element('ul'));
      var stylesheets = this.currentStylesheets.concat(this.newStylesheets);

      if (stylesheets.length === 0) {
        this.addStylesheet();
        return;
      }

      stylesheets.each(function(s){
        var styleTitle = s.model.get('name');
        var li = new Element('li',{id:'item-'+s.id, title: styleTitle}).insert(new Element('div').insert(
          new Element('a',{'href':'#'}).update(styleTitle.truncate(25))
          .observe('click',this.listItemClicked.bind(this, s))));

        ul.insert(li);
      }, this);

      this.selectStylesheet(stylesheets.last());
    },

    listItemClicked: function(stylesheet) {
      if (stylesheet === this.selectedStylesheet){ return; }
      var originalScrollPosition = this.list.e.down('ul').scrollTop;
      this.updateSelectedStylesheet();
      if (!this.validateStylesheet(this.selectedStylesheet)) {
        this.removeStylesheet(this.selectedStylesheet);
      }
      this.updateList();
      this.selectStylesheet(stylesheet);
      this.list.e.down('ul').scrollTop = originalScrollPosition;
    },

    selectStylesheet: function(stylesheet) {
      if (this.selectedStylesheet !== null){
        this.updateSelectedStylesheet();
        this.list.e.down('li#item-'+this.selectedStylesheet.id).removeClassName('active');
      }

      this.controls.stylesheetName.setValue(stylesheet.model.get('name'));
      this.controls.codeEditor.setValue( stylesheet.model.get( 'content.html' ) );
      this.controls.codeEditor.focus();
      this.list.e.down('li#item-'+stylesheet.id).addClassName('active');

      this.selectedStylesheet = stylesheet;

      var editor = this.controls.codeEditor,
          valid = stylesheet.model.exists('content.valid') ? stylesheet.model.get('content.valid') : true;
      editor.showHTMLWarningIfInvalid(this, this.closeIfValid, valid);
    },

    addStylesheet: function() {
      this.updateSelectedStylesheet();

      if (this.selectedStylesheet !== null && !this.validateStylesheet(this.selectedStylesheet)) {
        return;
      }

      var module = this.getModule();

      var newStylesheet = module.buildPageElement(window.editor.page, {
        name: window.editor.page.generateDefaultElementName(module.type, module.defaultName),
        containerId:null,
        placement:'body:after',
        content:{
          type:null,
          html:''
        }
      });

      this.newStylesheets.push(newStylesheet);
      this.selectedStylesheet = null;
      this.updateList();
      this.list.e.down('ul').scrollTop = this.list.e.down('ul').scrollHeight;
    },

    removeStylesheet: function(stylesheet) {
      window.editor.page.undoManager.startGroup();
      stylesheet.removeFromPage();
      window.editor.page.undoManager.endGroup();
    },

    removeSelectedStylesheet: function() {
      if (this.selectedStylesheet === null) {
        return;
      }

      if (this.currentStylesheets.indexOf(this.selectedStylesheet) > -1) {
        this.removeStylesheet(this.selectedStylesheet);
      } else {
        this.newStylesheets = this.newStylesheets.without(this.selectedStylesheet);
      }

      this.selectedStylesheet = null;
      this.updateList();
    },

    validateStylesheet: function(stylesheet) {
      return stylesheet !== null && stylesheet.model.get('content.html') !== '';
    },

    updateSelectedStylesheet: function() {
      var s = this.selectedStylesheet;

      if (s === null) {
        return;
      }

      var isNew = this.currentStylesheets.indexOf(s) === -1;
      var um = isNew ? null : window.editor.activeUndoManager;

      var change = s.model.get( 'content.html' ) !== this.controls.codeEditor.getValue() ||
        s.model.get( 'name' ) !== this.controls.stylesheetName.getValue();

      if (!isNew && change) {
        this.startUndoGroup();
      }

      if (change) {
        s.model.set('name', this.controls.stylesheetName.getValue(), um);
        s.model.set( 'content.html', this.controls.codeEditor.getValue() );
        s.model.set('content.html', this.controls.codeEditor.getValue(), um);
        s.model.set('content.valid', this.controls.codeEditor.isValid(), um);
        s.model.set('content.errors', this.controls.codeEditor.getErrors(), um);
      }

      if (isNew) {
        if (this.validateStylesheet(s)) {
          this.startUndoGroup();
          window.editor.page.insertElement(s);
          this.newStylesheets = [];
          this.updateList();
        } else {
          this.newStylesheets = this.newStylesheets.without(s);
        }
      } else {
        window.editor.page.updateElement(s);
      }
    },

    updateView: function() {
      var listItem = this.list.e.down('li#item-'+this.selectedStylesheet.id + ' a');
      var stylesheetTitle = this.controls.stylesheetName.getValue();
      listItem.update(stylesheetTitle.truncate(25));
      listItem.setAttribute('title', stylesheetTitle);
    },

    requestFocus: function(){
      window.editor.keyController.requestFocus(this);
    },

    startUndoGroup: function() {
      if (this.undoGroupStarted) {
        return;
      }
      this.undoGroupStarted = true;
      window.editor.activeUndoManager.startGroup();
    },

    cancel: function() {
      this.closeIfValid();
    },

    done: function() {
      this.closeIfValid();
    },

    open: function($super,elm, options) {
      $super();
      if (options && options.callback) {
        this.options.callback = options.callback;
      }
      this.requestFocus();
      this.title.update('Manage Stylesheets: <em>' + window.editor.page.getUsedAsText()+'</em>');
      this.newStylesheets = [];
      this.currentStylesheets = [];
      this.selectedStylesheet = null;

      // BUG what is the desired behaviour here. newStylesheet is not used
      var newStylesheet = elm || this.addStylesheet();

      if (elm) {
        this.updateList(false);
        this.selectStylesheet(elm);
        var model = elm.model;
        var valid =  model.exists('content.valid') ? model.get('content.valid') : true;
        var closeFunction = this.closeIfValid;
        this.controls.codeEditor.showHTMLWarningIfInvalid(this, closeFunction, valid);
      } else {
        this.addStylesheet();
      }
    },

    closeIfValid: function() {
      this.controls.codeEditor.isValidContentHTML(this, this.close);
    },

    close: function($super, force) {
      if(!!force) {
        this.updateSelectedStylesheet(this);
        if (this.undoGroupStarted) {
          window.editor.activeUndoManager.endGroup();
          this.undoGroupStarted = false;
        }
        $super();
      }
    }
  }
);
