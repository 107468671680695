(function() {
  var type = 'lp-metadata',
      version = '1.0';

  function openManager(page) {
    this.manager = this.manager ||
    new (Class.create( jui.ModalPanel, {
      initialize: function($super, page){
        this.page = page;
        this.controls = {};
        $super();
      },

      installUI: function( $super ) {
        $super();
        this.content.insert( new Element('h1').update('Edit Page Title, Keywords &amp; Description'));
        var manager = this.content.insert(new jui.Component({attributes:{className:'metadata-editor clearfix'}}));
        this.controls.title = manager.insert(new jui.FormTextInput({
            label:'Title',
            onfocus: this.requestFocus.bind(this)
        }));

        this.controls.keywords = manager.insert(new jui.FormTextArea({
          label:'Keywords',
          onfocus: this.requestFocus.bind(this)
        }));

        this.controls.description = manager.insert(new jui.FormTextArea({
          label:'Description',
          onfocus: this.requestFocus.bind(this)
        }));

        this.controls.noRobots = manager.insert(new jui.FormCheckboxInput({
          label:'Hide my page from search engines<br /><span class="note">Note: make sure this is set the same for all variants</span>'
        }));

        this.actions = this.content.insert( new jui.Component({attributes:{className:'actions clearfix'}}));
        this.doneButton = this.actions.insert( new jui.Button({label:'Done', action:this.done.bind(this)}));
      },

      updateFields: function() {
        this.controls.title.setValue(this.page.metaData.title);
        this.controls.keywords.setValue(this.page.metaData.keywords);
        this.controls.description.setValue(this.page.metaData.description);
        this.controls.noRobots.setValue(!!this.page.settings.noRobots);
      },

      requestFocus: function(){
        window.editor.keyController.requestFocus(this);
      },

      cleanKeywords: function(keywords) {
        return keywords.gsub('\n', ',').gsub('\r', ',').split(',').collect(function(k){return k==='' ? null : k;}).compact().join(',');
      },

      cleanReturns: function(keywords) {
        return keywords.gsub('\n', ' ').gsub('\r', ' ');
      },

      cancel: function() {
        this.close();
      },

      done: function() {
        this.close();
      },

      setMetaData: function(title, keywords, description, noRobots) {
        var currentTitle = this.page.metaData.title;
        var currentKeywords = this.page.metaData.keywords;
        var currentDescription = this.page.metaData.description;
        var currentNoRobots = !!this.page.settings.noRobots;

        if (currentTitle !== title ||
            currentKeywords !== keywords ||
            currentDescription !== description ||
            currentNoRobots !== noRobots) {
          var um = this.page.undoManager;
          um.registerUndo({
            action: this.setMetaData,
            receiver:this,
            params:[currentTitle, currentKeywords, currentDescription, currentNoRobots]
          });

          this.page.metaData.title = title;
          this.page.metaData.keywords = keywords;
          this.page.metaData.description = description;
          this.page.settings.noRobots = noRobots;
        }

        if(typeof eventTracker !== 'undefined') {
          eventTracker.track("Add Meta-Data", "Editor");
        }
      },

      open: function($super,options) {
        $super();
        if (options && options.callback) {
          this.options.callback = options.callback;
        }

        this.requestFocus();
        this.updateFields();
      },

      close: function($super) {
        this.setMetaData(
          this.cleanReturns(this.controls.title.getValue()),
          this.cleanKeywords(this.controls.keywords.getValue()),
          this.cleanReturns(this.controls.description.getValue()),
          this.controls.noRobots.getValue()
        );
        $super();
      }
    }))(page);

    this.manager.open();
  }

  lp.createModule(
    {
      type: type,
      version: version,
      openManager: openManager
    }
  );
})();
