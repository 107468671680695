/* globals Rx */
;(function(){
  var juiEvents = window.rx_switchboard.subjects.jui_events;
  var activeElementClass = 'lp-active-element';
  var overlayDivHTML = '<div class="lp-protruding-overlay"></div>';
  var log = function (msg) {
    // console.log(msg);
  };

  var updateTransformBox = function () {
    try {
      // Fix the height of the transform box when the message is added/removed.
      // TODO-TR: remove try catch once we understand when this can be safely called
      window.editor.transformBox.elementModelChanged();
    } catch (e) {
      log(e);
    }
  };
  var resetUI = function() {
    jQuery('.lp-protruding').removeClass('lp-protruding');
    jQuery('.lp-protrusion-msg').remove();
    jQuery('.lp-protruding-overlay').remove();
    updateTransformBox();
  };

  var handlePageSectionProtrusion = function(pageSection) {
    var data = calculateBoundingBoxInput(pageSection);

    var protrudingElements = new lp.editor.ElementProtrusionDetection
      .findProtrudingElements(data, { l: 0, r: window.editor.page.getDimensions().width });

    try {
      addIndicatorForProdtrudingElements(protrudingElements);
    } catch(err) {
      window.editor.reportError({
        error: err,
        id: window.editor.page.id,
        message: 'Error with page section protrusion detection',
        details: err.toString().truncate(400, ''),
        userAgent: window.navigator.userAgent
      });
    }
  };

  var addIndicatorForProdtrudingElements = function(protrudingElements) {

    protrudingElements.forEach(function(el){
      var protrudingEl = window.editor.page.getElementById(el.id);
      // TODO-TR: remove this hack collision avoidance somehow:
      var $el = jQuery(protrudingEl.view.e);
      if ($el.find('.lp-parenting-msg').length === 0 ) {
        // we want to avoid clashing with the re-parenting tooltip
        // A temporary hack till we work out something better.
        $el.addClass('lp-protruding');
      }
    });
  };

  var calculateBoundingBoxInput = function(element) {
    if(element && element.id !== 'dummy-section') {
      var elements = element.getChildElements();
      return elements.map(function(el) {
        var width = el.model.get('geometry.size.width');
        if (el.model.exists('geometry.scale')) {
          width = el.model.get('geometry.size.width') * el.model.get('geometry.scale');
        }
        return {
          l: el.model.get('geometry.offset.left'),
          w: width,
          id: el.id,
          children: calculateBoundingBoxInput(el)
        };
      });
    }

  };

  var messageDiv = function() {
    return jQuery('<div class="lp-protrusion-msg"></div>')
      .text('Resize or move this element to fit the page width');
  };

  var updateProtrusionUI = function() {
    //remove the lp-protruding class from children who have a parent that is also
    //protruding.
    //TODO-SD optimize this by chaining.
    resetUI();

    window.editor.page.getElementsByType('lp-pom-block')
    .each(handlePageSectionProtrusion);

    jQuery('.lp-protruding .lp-protruding').removeClass('lp-protruding');

    jQuery('.lp-protruding')
    .map(function(_i, el){
      var $el = jQuery(el);
      if ($el.hasClass('lp-pom-image')) {
        $el.find('.lp-pom-image-container') .append(overlayDivHTML);
      } else {
        $el.append(overlayDivHTML);
      }

      // TODO-TR: show an indicator on the page contents tree
      // jQuery('#menu-item-'+ $el[0].id + ' .icon').append(overlayDivHTML);
      $el.append(messageDiv());
    });
    updateTransformBox();
  };

  var activateDependencies = function () {
    var disposable = new Rx.CompositeDisposable();
    //For images we want to know if the element is active so that we can style around
    //the mask button.
    disposable.add(
      juiEvents.filter(function(e) {
        return e.type === 'activated';
      }).map(function(e){
        return e.source.id;
      })
        .subscribe(function(elementId){
          jQuery('.' + activeElementClass).removeClass(activeElementClass);
          jQuery('#' + elementId).addClass(activeElementClass);
        }));

    // work-around a browser css reflow issue. When the active element
    // is reparented on the dom level the display:block rule for the
    // message isn't triggered unless we force a repaint.
    disposable.add(
      juiEvents
        .filter(function(e){
          return e.type === 'parentChanged';
        })
        .delay(5)
        .subscribe(function(){
          jQuery('.lp-active-element').show();
        }));
    return disposable;
  };

  var activate = function() {
    updateProtrusionUI();
    var disposable = new Rx.CompositeDisposable();
    disposable.add(new Rx.Disposable.create(resetUI));
    //Any time geometry changes we check for protrusions and then draw the warning message
    //for that element
    disposable.add(
      juiEvents.filter(function(e){
        return e.type === 'breakpointChanged' || e.type === 'elementInserted' ||
          (e.type === 'attributeChanged' &&
           e.data && (e.data.accessor.startsWith('geometry')));
      })
        .sample(120)
        .subscribe(updateProtrusionUI));

    return disposable;
  };

  // TODO-TR: it's time to convert this to an object ('new' etc.)
  var pageSectionProtrusionUI = {
    _disposable: null,
    resetUI: resetUI
  };
  pageSectionProtrusionUI.activate = function() {
    pageSectionProtrusionUI.deactivate();
    pageSectionProtrusionUI._disposable = activate();
  };
  pageSectionProtrusionUI.deactivate = function () {
    if (pageSectionProtrusionUI._disposable &&
        ! pageSectionProtrusionUI._disposable.isDisposed) {
      pageSectionProtrusionUI._disposable.dispose();
    }
  };
  pageSectionProtrusionUI.setVisible = function (isVisible) {
    if (isVisible) {
      pageSectionProtrusionUI.activate();
    } else {
      pageSectionProtrusionUI.deactivate();
    }
  };
  activateDependencies();
  window.pageSectionProtrusionUI = pageSectionProtrusionUI;

})();
