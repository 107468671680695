lp.module.image.ImageModel = Class.create(lp.pom.VisibleElementModel, {
  initialize: function($super, element, modelData) {
    $super(element, modelData);

    this.addBreakpointModifyableAttributes({
      geometry:{
        offset: {
          left:true,
          top:true
        },
        size:{
          width:true,
          height:true
        },
        transform: {
          offset: {
            left:true,
            top:true
          },
          size: {
            width:true,
            height:true
          }
        },
        visible:true,
        borderLocation: true,
        borderApply: {
          top: true,
          right: true,
          left: true,
          bottom: true
        }
      },
      style: {
        border: {
          style: true,
          width: true,
          color: true
        }
      }
    });

    this.addBreakpointAllowDuplicateAttributes({
      geometry:{
        borderApply: {
          top: true,
          right: true,
          bottom: true,
          left: true
        }
      }
    });
  },

  syncDimensions: function(size) {
    jui.deepSet(this.modelData, 'geometry.size', size);
    jui.deepSet(this.modelData, 'geometry.transform.size', size);
    jui.deepSet(this.modelData, 'content.asset.size', size);
  },

  getImageContentSize: function() {
    return this.get('content.asset.size');
  },

  getImageSize: function() {
    return this.get('geometry.transform.size');
  },

  setImageSize: function(size0, um) {
    var size = JSON.parse(JSON.stringify(size0));
    if (typeof size.width === 'undefined') {
      size.width = this.get('geometry.transform.size.width');
    }
    if (typeof size.height === 'undefined') {
      size.height = this.get('geometry.transform.size.height');
    }
    this.set('geometry.transform.size', {
      width: Math.round(size.width),
      height: Math.round(size.height)
    }, um);
  },

  getImageOffsetRelToMask: function() {
    return this.get('geometry.transform.offset');
  },

  setImageOffsetRelToMask: function(offset0, um) {
    var offset = JSON.parse(JSON.stringify(offset0));
    if (typeof offset.top === 'undefined') {
      offset.top = this.get('geometry.transform.offset.top');
    }
    if (typeof offset.left === 'undefined') {
      offset.left = this.get('geometry.transform.offset.left');
    }
    var currentImageSize = this.getImageSize();
    this.set('geometry.transform.offset',
             {left: Math.max(Math.min(offset.left, 0), -currentImageSize.width),
              top: Math.max(Math.min(offset.top, 0), -currentImageSize.height)},
             um);
  },

  getMaskSize: function() {
    return this.get('geometry.size');
  },

  setMaskSize: function(size0, um) {
    var size = JSON.parse(JSON.stringify(size0));
    if (typeof size.width === 'undefined') {
      size.width = this.get('geometry.size.width');
    }
    if (typeof size.height === 'undefined') {
      size.height = this.get('geometry.size.height');
    }
    // with safeguard to prevent the mask being larger than the image itself
      var imageSize = this.getImageSize();
    var offset = this.getImageOffsetRelToMask(); // .top, .left are always <=0
    var width = Math.min(size.width, imageSize.width + offset.left);
    var height = Math.min(size.height, imageSize.height + offset.top);
    this.set('geometry.size',
             {width: width, height: height},
             um);
  },

  getMaskOffset: function() {
    return this.get('geometry.offset');
  },

  setMaskOffset: function(offset0, um) {
    var offset = JSON.parse(JSON.stringify(offset0));
    if (typeof offset.top === 'undefined') {
      offset.top = this.get('geometry.offset.top');
    }
    if (typeof offset.left === 'undefined') {
      offset.left = this.get('geometry.offset.left');
    }
    // this is the offset of the mask on the canvas relative to its parent
    this.set('geometry.offset',
             {top: offset.top, left: offset.left},
             um);
  },

  hasBreakpointSizeOrTransformationDifferences: function() {
    var desktop    = this.element.page.getBreakpointByName('desktop')
      , mobile     = this.element.page.getBreakpointByName('mobile')
      , desktopImg = this.get('geometry', desktop)
      , mobileImg  = this.get('geometry', mobile);

    var difference = function(prev, now) {
        var changes = {};
        for(var prop in now) {
          if (!prev || prev[prop] !== now[prop]) {
            if (_.isObject(now[prop])) {
              var c = difference(prev[prop], now[prop]);
              if (!_.isEmpty(c) ) {changes[prop] = c;}
            } else {
              changes[prop] = now[prop];
            }
          }
        }
        return changes;
    };

    return !_.isEmpty(difference(desktopImg.transform, mobileImg.transform)) ||
           !_.isEmpty(difference(desktopImg.size, mobileImg.size));
  }


});
