lp.editor.BasicDialog = Class.create( jui.ModalPanel, {
  initialize: function( $super, options ){
    $super( options );
  },

  installUI: function( $super ) {
    $super();

    // if (this.options.heading) {
    //   this.content.insert( new Element('h1').update(this.options.heading));
    // }
    //
    // if (this.options.message) {
    //   this.content.insert( new Element('div', {className:'basic-dialog'}).update(this.options.message));
    // }

    this.heading = this.content.insert( new Element('h1'));
    this.message = this.content.insert( new Element('div', {className:'basic-dialog'}));
    this.actions = this.content.insert( new jui.Component({attributes:{className:'actions clearfix'}}));
    this.okButton = this.actions.insert( new jui.Button({label:'Ok', action:this.close.bind(this)}));
  },

  close: function($super) {
    $super();
    if (this.options.onClose) {
      this.options.onClose();
    }
  },

  open: function($super, options) {
    $super();

    if (typeof options.heading !== 'undefined') {
      this.heading.update(options.heading);
      this.heading.show();
    } else {
      this.heading.hide();
    }

    if (typeof options.message !== 'undefined') {
      this.message.update(options.message);
    } else {
      this.heading.update('');
    }

    if (typeof options.className !== 'undefined') {
      this.message.className = 'basic-dialog '+options.className;
    } else {
      this.message.className = 'basic-dialog';
    }

    if (options && options.onClose) {
      this.options.onClose = options.onClose;
    }
  }
});
