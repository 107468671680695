lp.editor.panel = lp.editor.panel || {};
lp.editor.panel.ElementBorderControls = {
  /* Methods required in implementing object: */
  /* backgroundControlChanged */

  installBorderControls: function(options) {
    options.insert = options.insert || this.insert.bind(this);
    options.change = options.change || this.borderControlsChanged.bind(this);
    options.hasRadiusControl = typeof options.hasRadiusControl !== 'undefined' ?
      options.hasRadiusControl : true;
    options.radiusChange = options.radiusChange || this.applyRadiusChange.bind(this);
    options.focus = options.focus || this.requestFocus.bind(this);

    this.adjustingCornerRadius = false;
    var self = this;

    this.controls.border = options.insert( new jui.FormBorderInput ({
      onblur: function() {
        self.performModelUpdates(options.change);
      },
      onfocus: options.focus
    }));

    this.controls.location = options.insert( new jui.FormSelect ({
      label:'Location',
      width: '75px',
      id: 'jui-border-location-select',
      dropDownClassName : 'editor-info-panel-select jui-drop-down',
      selectOptions:[{name:'Inside',value:'inside'}, {name:'Outside',value:'outside'}],
      action: function() {
        self.performModelUpdates(options.change);
      }
    }));

    this.controls.apply = options.insert( new jui.FormMultiCheckboxInput ({
      label:'Apply to',
      checkboxes:[
        {id:'top', label:'Top:', checked:true},
        {id:'right', label:'Right:', checked:true},
        {id:'bottom', label:'Bottom:', checked:true},
        {id:'left', label:'Left:', checked:true}
      ],
      change: function() {
        self.performModelUpdates(options.change);
      }
    }));

    if (options.hasRadiusControl) {
      this.installRadiusControls(options);
    }

    this.modelChangeHandlers.push(function(e) {
      var accessor = e.data.accessor;
      var value = e.data.value;
      var m = e.source;

      switch(accessor) {
      case 'geometry.borderApply':
        self.controls.apply.setValue(value);
        break;
      case 'style.border':
        self.controls.border.setValue(value ? value : null );
        break;
      case 'geometry.borderLocation':
        self.controls.location.setSelectedValue(value);
        break;
      case 'geometry.cornerRadius':
        if (!this.adjustingCornerRadius) {
          self.setRadiusSliders(m);
        }
        break;
      case 'geometry.keepCircular':
        self.controls.keepCircular.setValue(value);
        break;
      }
    });
  },

  installRadiusControls: function(options) {
    var self = this;
    var radiusControls = new jui.Component();
    radiusControls.e
      .style.cssText = 'position: relative; float: left; clear: both; width: 100%';

    options.insert(radiusControls);
    var radiusSeparateControls = new jui.Component();
    radiusSeparateControls.e.style.position = 'position: relative; float: left; clear: both';
    radiusControls.insert(radiusSeparateControls);

    this.controls.radius = {};

    var stopTracking = function() {
      self.element.page.undoManager.endGroup();
    };

    var startTracking = function() {
      self.element.page.undoManager.startGroup();
    };

    var sliderOptions = {
      min:0,
      max:100,
      allowOutOfRangeMaxValues:true,
      increment:1
    };

    this.controls.radius.tl = radiusControls.insert(new jui.FormSliderInput({
      label:'Corner Radius',
      onchange: function() {options.radiusChange();},
      onfocus: function(e) {self.requestFocus(e);},
      startTracking: startTracking,
      stopTracking: stopTracking,
      slider:sliderOptions
    }));

    this.controls.radius.tr = radiusControls.insert(new jui.FormSliderInput({
      label:'&nbsp;',
      onchange: function() {options.radiusChange();},
      onfocus: function(e) {self.requestFocus(e);},
      startTracking: startTracking,
      stopTracking: stopTracking,
      slider:sliderOptions
    }));

    this.controls.radius.bl = radiusControls.insert(new jui.FormSliderInput({
      label:'&nbsp;',
      onchange: function() {options.radiusChange();},
      onfocus: function(e) {self.requestFocus(e);},
      startTracking: startTracking,
      stopTracking: stopTracking,
      slider:sliderOptions
    }));

    this.controls.radius.br = radiusControls.insert(new jui.FormSliderInput({
      label:'&nbsp;',
      onchange: function() {options.radiusChange();},
      onfocus: function(e) {self.requestFocus(e);},
      startTracking: startTracking,
      stopTracking: stopTracking,
      slider:sliderOptions
    }));


    this.controls.radiusExpand = new jui.IconToggle('expander', {
      classNames:['radius'],
      action: function(e) {
        self.setCornerControlsVisibility(e.data);
        if (!e.data) {
          self.resetRadius();
        } else {
          var r = self.controls.radius.tl.getValue();
          self.controls.radius.tr.setValue(r);
          self.controls.radius.bl.setValue(r);
          self.controls.radius.br.setValue(r);
        }
      }
    });

    this.controls.radius.tl.slider.insert(this.controls.radiusExpand, 'before');

    this.controls.radius.tr.slider.insert(new Element('div', {className:'icon corner tr'}), 'before');
    this.controls.radius.bl.slider.insert(new Element('div', {className:'icon corner bl'}), 'before');
    this.controls.radius.br.slider.insert(new Element('div', {className:'icon corner br'}), 'before');

    this.controls.keepCircular = radiusControls.insert(new jui.FormCheckboxInput({
      label:'Ensure maximum curvature!',
      onclick: function(e) {
        self.element.model.keepCircular(e.data, self.element.page.undoManager);
      }
    }));
  },

  borderControlsChanged: function() {
    this.element.model.setBorder({
      border: this.controls.border.getValue(),
      borderApply: this.controls.apply.getValue(),
      borderLocation: this.controls.location.getValue()
    }, this.element.page.undoManager);
  },

  applyRadiusChange: function() {
    this.adjustingCornerRadius = true;
    var m = this.element.model;
    var um = this.element.page.undoManager;

    var cr = {
      tl: Math.round(this.controls.radius.tl.getValue()),
      tr: Math.round(this.controls.radius.tr.getValue()),
      bl: Math.round(this.controls.radius.bl.getValue()),
      br: Math.round(this.controls.radius.br.getValue())
    };

    if (!this.controls.radiusExpand.isSelected() || (cr.tl === cr.tr && cr.tr === cr.bl && cr.bl === cr.br)) {
      m.setCornerRadius(cr.tl, um);
    } else {
      m.setCornerRadius(cr, um);
    }
    this.adjustingCornerRadius = false;
  },

  checkIfKeepCircular: function() {
    var m = this.element.model;
    if (m.isKeepCircular()) {
      m.makeCircular();
    }
  },

  resetRadius: function() {
    if(this.controls.radius) {
      var um = this.element.page.undoManager;
      var m = this.element.model;
      var cr = this.controls.radius.tl.getValue();
      m.setCornerRadius(cr, um);
      this.controls.radius.tr.setValue(cr);
      this.controls.radius.bl.setValue(cr);
      this.controls.radius.br.setValue(cr);
    }
  },

  setCornerControlsVisibility: function(isVisible) {
    if(this.controls.radius) {
      this.controls.radius.tr.setVisible(isVisible);
      this.controls.radius.bl.setVisible(isVisible);
      this.controls.radius.br.setVisible(isVisible);
      this.isVisible = isVisible;
    }
  },

  setRadiusSliders: function(m) {
    if(this.controls.radius) {
      var radius = m.exists('geometry.cornerRadius') ? m.get('geometry.cornerRadius') : 0;

      if (Object.isNumber(radius)) {
        this.controls.radius.tl.setValue(radius);
        this.controls.radius.tr.setValue(radius);
        this.controls.radius.bl.setValue(radius);
        this.controls.radius.br.setValue(radius);
        this.setCornerControlsVisibility(!!this.isVisible);
        this.controls.radiusExpand['toggle' + (!!this.isVisible ? 'On' : 'Off')]();
      } else {
        this.controls.radius.tl.setValue(radius.tl);
        this.controls.radius.tr.setValue(radius.tr);
        this.controls.radius.bl.setValue(radius.bl);
        this.controls.radius.br.setValue(radius.br);
        this.setCornerControlsVisibility(true);
        this.controls.radiusExpand.toggleOn();
      }

    }
  },

  bindBorderControlsToElement: function(elm) {
    var m = elm.model;
    this.controls.border.setValue(m.exists('style.border') ? m.get('style.border') : null);
    this.controls.location.setSelectedValue(m.get('geometry.borderLocation'));
    if (this.controls.keepCircular) {
      this.controls.keepCircular.setValue(m.isKeepCircular());
    }
    if (m.exists('geometry.borderApply')) {
      this.controls.apply.setValue(m.get('geometry.borderApply'));
    } else {
      this.controls.apply.setValue({top:true, right:true, bottom: true, left:true});
    }
    this.isVisible = false;
    this.setRadiusSliders(m);
  }
};
