lp.module.box.BoxElementPropertiesPanel = Class.create(
  lp.editor.panel.ElementPropertiesPanel,
  lp.editor.panel.ElementGeometryControls,
  lp.editor.panel.ElementBackgroundControls,
  lp.editor.panel.ElementBorderControls,
  lp.ModuleComponent,
  {
    type:'lp-pom-box',

    installUI: function($super){
      $super();

      var geometryPanel = this.insert(new jui.CollapsiblePanel({title:'Geometry'}));
      this.installGeometryControls({insert:geometryPanel.insertContent.bind(geometryPanel)});
      var borderPanel = this.insert(new jui.CollapsiblePanel({title:'Border Properties'}));
      this.installBorderControls({insert:borderPanel.insertContent.bind(borderPanel)});
      var backgroundPanel = this.insert(new jui.CollapsiblePanel({title:'Background Properties'}));
      this.installBackgroundControls({insert:backgroundPanel.insertContent.bind(backgroundPanel)});
    },

    syncUIControls: function($super, elm) {
      $super(elm);
      this.bindGeometryControlsToElement(elm);
      this.bindBackgroundControlsToElement(elm);
      this.bindBorderControlsToElement(elm);
    }
  }
);
