lp.editor.AssetChooserDialog = Class.create( lp.editor.BuilderModal, {
  initialize: function( $super, options ){
    /* jshint unused:vars */
    this.controlStates = {choose:false, cancel:true, upload:true};
    this.lastUploadId = null;
    this.enabled = true;
    // $super( options );

    $super({
      title:'Choose Image',
      actions: [{
        id: 'choose',
        label: 'Choose',
        action: this.add.bind(this)
      },
      {
        id: 'cancel',
        label: 'Cancel',
        action: this.cancel.bind(this)
      }],
      layoutRules: {
        method: jui.ModalDialog.centerInViewPort,
        options: {
          size:{width:640, height:468}
        }
      }
    });
  },

  installUI: function( $super ) {
    $super();

    var browserOptions = {
      size:{width:640,height:400},
      views:[
      {label:'Images', id:'images'}
      ],
      change: function(e) {
        e.data.view.buildSearch();
      }
    };

    this.assetBrowser = this.contentPanel.insert( new lp.editor.AssetBrowser(browserOptions));
    this.assetUploader = this.actionBar.insert( new lp.editor.AssetUploader(browserOptions));

    var self = this;
    this.assetBrowser.addListener('assetSelectionChanged', function(){
      self.controlStates.choose = (self.assetBrowser.getSelectedAssets().length === 1);
      self.updateControlStates();
    });

    this.assetBrowser.addListener('assetDblClicked', function(asset) {
      self.addByAsset(asset.data);
    });

    this.assetBrowser.addListener('assetsLoaded', this);
    this.assetUploader.addListener('assetUploaded', this);
    this.assetUploader.addListener('assetUploadFailed', this);
    this.assetBrowser.addListener('enabled', function(){
      self.enable();
    });
    this.assetBrowser.addListener('disabled', function(){
      self.disable();
    });
    this.assetBrowser.addListener('updated', function() {
      self.update();
    });

    this.updateControlStates();
  },

  enable: function() {
    this.enabled = true;
    this.updateControlStates();
  },

  disable: function() {
    this.enabled = false;
    this.updateControlStates();
  },

  setEnabled: function(enable) {
    this[enable ? 'enable' : 'disable']();
  },

  updateControlStates: function() {
    this.actionButtons.choose.setEnabled(this.enabled && this.controlStates.choose);
    this.actionButtons.cancel.setEnabled(this.enabled && this.controlStates.cancel);
    this.assetUploader.setEnabled(this.enabled && this.controlStates.upload);
  },

  assetsLoaded: function() {
    if (this.options.selectedAsset && this.options.selectedAsset.content_content_type) {
      var asset = this.options.selectedAsset;
      this.assetBrowser.showTabWithContentType(asset.content_content_type);
      this.assetBrowser.selectedTabViewItem.view.selectAssetByUuid(asset.uuid);
    }
    this.assetBrowser.e.removeClassName('loading');
  },

  assetUploaded: function(e) {
    var asset            = e.data,
        currentCompanyId = window.editor.currentCompanyId;

    this.assetUploader.reset();
    this.assetBrowser.showTabWithContentType(asset.content_content_type);
    this.assetBrowser.addAsset(asset);
    if(this.assetBrowser.openAssetsCompanyId === currentCompanyId) {
      this.assetBrowser.selectedTabViewItem.view.selectAssetById(asset.id);
    } else {
      this.assetBrowser.loadAssetsByCompanyId(currentCompanyId);
      this.options.selectedAsset = asset;
    }
  },

  assetUploadFailed: function(e) {
    var error = e.data;
    this.showErrorDialog(error);
  },

  dismissErrorDialog: function($super) {
    $super();
    this.assetUploader.reset();
  },

  cancel: function() {
    this.assetBrowser.clearSelection();
    if (Object.isFunction(this.options.onCancel)){
      this.options.onCancel();
    }
    this.assetUploader.cancel();
    this.close();
  },

  add: function() {
    var asset = this.assetBrowser.getSelectedAsset();
    this.addByAsset(asset);
  },

  addByAsset: function(asset) {
    if(asset) {
      this.assetBrowser.clearSelection();
      if (Object.isFunction(this.options.onChoose)){
        this.options.onChoose(asset);
      }
      this.close();
    }

  },

  update: function() {
    var asset = this.assetBrowser.getUpdatedAsset();
    if(Object.isFunction(this.options.onUpdate)) {
      this.options.onUpdate(asset);
    }
  },

  open: function($super, options) {
    options = options || {};

    this.options.onChoose = options.onChoose;
    this.options.onCancel = options.onCancel;
    this.options.selectedAsset = options.selectedAsset;

    window.editor.activeAssetUploader = this.assetUploader;
    this.config(options);
    this.controlStates.choose = false;
    this.updateControlStates();
    this.assetUploader.reset();
    this.assetBrowser.e.addClassName('loading');


    $super();
  },

  close: function($super) {
    this.assetBrowser.clear();
    this.options.onChoose = null;
    this.options.onCancel = null;
    this.options.selectedAsset = null;
    $super();
  },

  config: function(options) {
    /* JS: defaults to images for convenience */
    this.url = options.url || this.assetBrowser.imageAssetsPath();
    this.setTitle(options.title || "Choose Image");
    var companyId = window.editor.currentCompanyId;
    if(options.selectedAsset) {
      //If the asset does not have a company id try to get one by uuid.  If it still does not have a company id
      //than use the current companyId for that asset.
      var currentAssetCompanyId = options.selectedAsset.company_id;
      if(currentAssetCompanyId && this.isValidCompany(currentAssetCompanyId)) {
        companyId = currentAssetCompanyId;
      } else {
        companyId = this.companyIdByAssetUUID(options.selectedAsset.uuid);
      }
    }
    this.assetUploader.setButtonLabel(options.uploadButtonLabel || "Upload New Image");
    this.assetUploader.setOnlyImages(Object.isUndefined(options.onlyImages) ? true : options.onlyImages);
    this.assetBrowser.setVisibleTabs(options.visibleTabs || 'images');
    this.assetBrowser.setTabViewItem(options.activeTab || 'images');
    this.assetBrowser.loadAssetsByCompanyId(companyId, options);
  },

  isValidCompany: function(companyId) {
    return companyId === window.editor.currentCompanyId || companyId === window.editor.unbounceAssetsCompanyId;
  },

  //If the asset does not have a company id than get one from the server.
  companyIdByAssetUUID: function(uuid) {
    var companyId = window.editor.currentCompanyId;
    var response = new Ajax.Request('/assets/' + uuid,{
      method: 'get',
      contentType: 'application/json',
      asynchronous: false
    }).transport.response.evalJSON();

    if(response) {
      companyId = response.company_id;
    }

    if(window.editor.unbounceCompanyId === companyId) {
      companyId = window.editor.unbounceAssetsCompanyId;
    }
    return companyId;
  }
});
