lp.editor.AssetUploader = Class.create(jui.Component, {
  options: function($super,options){
    return $super($H({
      attributes:{className:'asset-uploader'}
    }).merge(options));
  },

  initialize: function($super, options){
    $super(options);
    this.enabled = true;
    this.buttonEnabled = true;
    this.onlyImages = false;
    this.imageFileExtensions = ['jpg', 'jpeg', 'gif', 'png', 'bmp'];
  },

  installUI: function($super) {
    $super();
    var self = this;
    var csrf_meta_tag = $$('meta[name=csrf-token]')[0];
    var authenticity_token = csrf_meta_tag ? csrf_meta_tag.readAttribute('content') : '';

    this.uploadButton = this.insert( new jui.Button({label:'Upload New Image', action:this.upload.bind(this), classNames:['left']}));
    this.uploadForm = this.insert( new Element('div', {className:'image-upload-form'}).insert(
      '<form action="' + window.editor.assetsPath() + '" class="new_image_asset" enctype="multipart/form-data" id="new_image_asset" method="post" target="image_asset_upload_iframe">'+
      '<input name="authenticity_token" type="hidden" value="' + authenticity_token + '" />'+
      '<input id="only_images" name="only_images" type="hidden" value="false" />'+
      '<input id="asset_content" name="asset[content]" size="30" type="file" value="Upload File"/>'+
      '</form>'
    ));

    this.uploadProgress = this.insert( new Element('div', {className:'progress'}).update('This might take a while...'));
    this.uploadProgress.hide();

    if ($('image_asset_upload_iframe') === null) {
      window.document.body.insert( new Element('iframe', {name:'image_asset_upload_iframe', id:'image_asset_upload_iframe'}));
    }

    this.fileUpload = this.uploadForm.down('input#asset_content');
    var form = this.uploadForm.down('form');
    this.fileUpload.observe('change', function(){
      if(self.validateFile()) {
        self.uploadProgress.show();
        self.buttonEnabled = false;
        self.setControlStates();
        self.buttonLabel = self.uploadButton.getLabel();
        self.uploadButton.setLabel('Uploading File...');
        form.submit();
        self.toggleUploadButton(true);
      } else {
        self.assetUploadFailed(['That looks like an invalid file type.  We were expecting ' +
                               self.imageFileExtensions.join(', ') + '.']);
      }
    });

    form.setOpacity(0);
  },

  validateFile: function() {
    var pass = true;
    var file = this.uploadForm.down('input#asset_content').value;
    if(this.onlyImages) {
      var fileExtension = file.split('.').last().toLowerCase();
      //If this is not a file fail the validation.
      if(!this.imageFileExtensions.include(fileExtension)) {
        pass = false;
      }
    }
    return pass;
  },

  enable: function() {
    this.enabled = true;
    this.setControlStates();
    this.toggleUploadButton(false);
  },

  toggleUploadButton: function(state) {
    this.fileUpload.disabled = state;
  },

  disable: function() {
    this.enabled = false;
    this.setControlStates();
  },

  setEnabled: function(enable) {
    this[enable ? 'enable' : 'disable']();
  },

  cancel: function() {
    //This cancels the actual post in turn stopping the upload from happening.
    $('image_asset_upload_iframe').src = 'about:blank';
    this.enable();
    this.reset();
    this.uploadProgress.hide();
    this.buttonEnabled = true;
  },

  setControlStates: function() {
    this.uploadButton.setEnabled(this.buttonEnabled && this.enabled);
  },

  setButtonLabel: function(label) {
    this.buttonLabel = label;
    this.uploadButton.setLabel(this.buttonLabel);
  },

  reset: function() {
    this.uploadForm.down('form').reset();
    this.uploadButton.show();
    this.uploadButton.setLabel(this.buttonLabel);
    this.uploadForm.show();
    this.toggleUploadButton(false);
  },

  upload: function() {
    this.fileUpload.click();
  },

  assetUploaded: function(asset) {
    this.buttonEnabled = true;
    this.setControlStates();
    this.uploadProgress.hide();
    this.fireEvent('assetUploaded', asset);
  },

  assetUploadFailed: function(errors) {
    this.buttonEnabled = true;
    this.setControlStates();
    this.uploadProgress.hide();

    var error = 'There was an error uploading the file:<br />';
    error += errors.length > 0 ?
      errors[0] :
      'Please try again';

    this.uploadButton.setLabel(this.buttonLabel);
    this.uploadForm.hide();
    this.uploadButton.disable();
    this.fireEvent('assetUploadFailed', error);
  },

  setOnlyImages: function(bool) {
    this.onlyImages = bool;
    this.uploadForm.down('input#only_images').value = bool;
  }
});
