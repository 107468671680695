lp.module.form.FormElement = Class.create(
  lp.pom.VisibleElement,
  lp.ModuleComponent,
  {
    type:'lp-pom-form',

    initialize: function($super, page, jso, context, options) {
      this.updatingButtonModel = false;
      this.fieldViewMap = [];
      this.createFieldViews();
      this.adjustingGeometry = false;
      this.minFieldWidth = 40;
      this.minLabelWidth = 40;
      $super(page, jso, context, options);

      this.page.addListener('beforePageSave', this);
      var self = this;
      this.buttonMoveListener = function(e){
        var accessor = e.data.accessor;
        if (accessor.startsWith('geometry.offset')) {
          self.model.set('geometry.buttonPlacement', 'manual');
        }
      };
    },

    regenerateGeometry: function() {
      if(this.page.isPublishOrPreviewMode() && !this.hasExpectedModelAttributes()) {
        this.updaterRunning = true;
        this.setFormGeometryToModel(this.adjustFormGeometry);
        this.updaterRunning = false;
      } else {
        this.adjustFormGeometry();
      }
    },

    hasExpectedModelAttributes: function() {
      return this.model.exists('containers') && this.model.exists('fields');
    },

    isAdjustGeometryNeeded: function() {
      return this.updaterRunning || this.page.isEditMode();
    },

    getModelClass: function() {
      return lp.module.form.FormModel;
    },

    //Check to see if the submit button is currently attached to the form.
    isButtonAttached: function() {
      return this.submitButtonAction() === 'form';
    },

    submitButtonAction: function() {
      return this.submitButton.get('action.type');
    },

    //The reason for this method is because for an unknown reason the form button
    //can become detached from the form causing the form button to not be able to
    //submit the form.  This is to ensure that the form button remains attached.
    reattachDetachedSubmitButton: function() {
      this.submitButton.set('action.type', 'form');
    },

    fixLeftLabelAlignedForm: function(form) {
      if (form.geometry.label.alignment === "left" &&
          form.geometry.label.minWidth === form.geometry.size.width){

        form.geometry.size.width = (form.geometry.label.minWidth * 2) +
          form.geometry.label.margin.right;

        form.geometry.label.width = form.geometry.label.minWidth;

      }
    },

    addCalculatedWidthAttributeToFormLabel: function(form) {
      if(!form.geometry.label.calculatedWidth && form.geometry.label.minWidth) {
        form.geometry.label.calculatedWidth = form.geometry.label.minWidth;
      }
    },

    createDefaultConstraints: function($super) {
      $super();
      this.defaultConstraints.height_resizeable = false;
      this.defaultConstraints.copyable = false;
    },

    fixLabelColorValues: function(form) {
      if(form.style.label.color && /^\#/.test(form.style.label.color)) {
        form.style.label.color = form.style.label.color.replace(/^\#/, '');
      }
    },

    initView: function() {
      var page = this.page;
      var action;
      if (page.goals.urlIsActiveGoal('/fs')) {
        action = '/fsg';
      } else {
        action = '/fsn';
      }

      action = action + '?pageId=' + page.page.uuid + '&variant=' + page.variant_id.toLowerCase();

      this.form = this.insert(new Element('form', {action:action, method: 'POST'}));
      if (page.resourceType === "PageVariant") {
        this.form.insert(new Element('input', {type:'hidden', name:'pageId', value:page.page.uuid}));
        this.form.insert(new Element('input', {type:'hidden', name:'pageVariant', value:(page.variant_id).toLowerCase()}));
      }

      this.fieldset = new jui.Component({elementType:'fieldset', attributes:{className:'clearfix'}});
      this.form.insert(this.fieldset.e);

      if (page.context === lp.pom.context.PUBLISH || page.context === lp.pom.context.PREVIEW) {
        this.insertFormDataNode();
      }

      this.page.addListener(
        'beforeBreakpointChanged',
        this.setFormGeometryToModel.bind(this, this.adjustFormGeometry)
      );

      if(this.page.isEditMode()) {
        window.editor.addListener(
          'beforeActivePageChanged',
           this.setFormGeometryToModelForBreakpoints.bind(this)
        );
      }
      this.view.addListener('shown', this.shownHandler.bind(this));
    },

    shownHandler: function() {
      var _handleShowButton = function(button) {
        button.applyStyleAttributes();
      };

      if(this.submitButton) { _handleShowButton(this.submitButton); }
    },

    insertFormDataNode: function() {
      var data = {};
      data.formContainerId = this.id;
      data.errorContainerId = this.id+'-errors';

      this.page.addInsertion(
        '<div id="'+data.errorContainerId+'" class="lp-form-errors"><div class="content"><div class="error">Please correct the following errors:</div><ul></ul></div></div>',
        'body:after'
      );

      data.confirmAction = this.model.exists('content.confirmAction') ? this.model.get('content.confirmAction') : 'message';
      switch (data.confirmAction) {
        case 'url':
          data.passParams = this.model.exists( 'content.passParams' ) ? this.model.get( 'content.passParams' ) : false;
        data.confirmData = this.model.exists('content.confirmURL') ? this.model.get('content.confirmURL') : '';
        break;
        case 'post':
          data.confirmData = this.model.exists('content.confirmURL') ? this.model.get('content.confirmURL') : '';
        break;
        case 'message':
          data.confirmData = this.model.exists('content.confirmMessage') ? this.model.get('content.confirmMessage') : 'Thank you!';
        break;
        case 'modal':
          data.confirmData = {
          url:this.page.context === lp.pom.context.PUBLISH ?
            this.page.variant_id+'-form_confirmation.html' :
            (this.page.previewURL+'?sub_page=form_confirmation'),
          size: this.model.exists('content.confirmationPageSize') ?
            this.model.get('content.confirmationPageSize') : {
            desktop: {
              width:0,
              height:0
            },
            mobile: {
              width:0,
              height:0
            }
          }
        };
        break;
      }

      data.validationRules = {};
      data.validationMessages = {};
      data.formButtonId = this.model.get('content.buttonId');

      this.model.get('content.fields').each(function(f){
        if (f.validations) {
          data.validationRules[f.id] = f.validations;
          data.validationMessages[f.id] = {};

          if(f.validationType) {data.validationType = f.validationType;}

          if (f.validations.required) {
            data.validationMessages[f.id].required = f.name+' is required';
          }
        }
      });

      var js = '<script>\n'+
        'window.module = window.module || {};\n' +
        'window.module.lp = window.module.lp || {};\n' +
        'window.module.lp.form = window.module.lp.form || {};\n' +
        'window.module.lp.form.data = '+Object.toJSON(data)+';\n' +
        '</script>';

      this.page.addInsertion(js, 'head');
    },

    getContentHeight: function() {
      return this.model.getHeight();
    },

    createDependantElements: function() {
      if (!this.model.exists('content.buttonId') || this.model.get('content.buttonId') === null) {
        this.createButton();
      } else {
        this.submitButton = this.page.getElementById(this.model.get('content.buttonId'));
      }

      if (!this.model.exists('content.confirmationPage')) {
        this.createConfirmationPage();
      }
      this.adjustFormGeometry();
    },

    createButton: function() {
      try {
        var button = this.submitButton = lp.getModule('lp-pom-button')
        .addNewElementToPage(this.page, {container: this, dontActivateOnInsert: true});
        this.model.set('content.buttonId', button.id);
        button.set('name', 'Form Button');
        button.set('content.label', this.model.exists('content.submitButtonText') ?
                   this.model.get('content.submitButtonText') : 'Form Button');
        button.set('constraints.removable', false);
        button.set('constraints.copyable', false);
        button.set('constraints.allowActions', ['form']);
        button.set('action.type', 'form');
      } catch(e) {
        var action = this.submitButton.exists('action.type') ?
          this.submitButton.get('action.type') : 'Not defined';

        var details = "Form Action Type is currently: " + action;

        window.editor.reportError({
          error: e,
          id: window.editor.mainPage.id,
          message: "An error occured creating a form. " + details,
          details: "Error creating form",
          userAgent: window.navigator.userAgent
        });
      }
    },

    createSuccessModal: function() {
      var modal = lp.getModule('lp-modal').createElement(this.page, {name:'Form Confirmation Dialog'});
      this.model.set('content.successModalId', modal.id);
      /* globals userMessage */
      if (!Object.isUndefined(userMessage)) {
        userMessage.open();
      }

    },

    createConfirmationPage: function() {
      this.model.set('content.confirmationPage', this.page.variant_id+'-form-confirmation.html');

      var page = new lp.pom.Page({
        page:{
          id:null,
          name:'Form Confirmation Page',
          used_as:'form_confirmation',
          path_name:this.page.variant_id+'-form-confirmation.html'
        },
        id:null,
        version:this.page.version,
        variant_id:'a',
        type:'PageVariant',
        has_form:false,
        name:'Form Confirmation Page',
        title:'',
        description:'',
        keywords:'',
        last_element_id:0,
        settings:{
          defaultWidth : 512,
          showPageTransformBox : true,
          showSectionBoundaries : true,
          activeGoals : [],
          multipleBreakpointsVisibility: this.page.settings.multipleBreakpointsVisibility
        },
        elements:[
          {
          id:"lp-pom-root",
          type: "lp-pom-root",
          name: "Page Root",
          containerId: null,
          style: {
            background: {backgroundColor: "eee"},
            defaults: {
              color: "000",
              linkColor: "0000ff",
              linkDecoration: "none"
            }
          },
          geometry: {
            position: "relative",
            margin: "auto",
            contentWidth: 512
          }
        }
        ],
        styles:[]
      }, this.page.context);

      var root = page.getRootElement();

      page.undoManager.disable();

      var section = lp.getModule('lp-pom-block').addNewElementToPage(page, {container:root});
      var defaultTextGeometry = {
        desktop: {
          width: section.model.getWidth() - 40,
          left: 20
        },
        mobile: {
          width: 200,
          left: 20
        }
      };
      section.set('geometry.size.height', 320);

      var text = lp.getModule('lp-pom-text').addNewElementToPage(page, {container:section});
      text.setText('<h1 style="text-align: center; ">Thank You!</h1><p style="text-align: center;">Your form has been submitted.</p>');
      text.autoUpdateHeight = false;
      text.autoUpdateHeight = true;

      document.body.insert(root.getViewDOMElement());
      var textH = text.getHeight();
      var sectionH = Math.max(180, textH + 40);

      root.remove();

      section.model.setHeight(sectionH);
      page.getBreakPoints().each(function(breakpoint) {
        var defaultGeometry = defaultTextGeometry[breakpoint.name];

        text.model.setSizeByBreakpoint({
          width  : defaultGeometry.width,
          height : 86
        }, breakpoint);

        text.model.setOffsetByBreakpoint({
          left : defaultGeometry.left,
          top  : (sectionH - textH) / 2
        }, breakpoint);

      });

      page.undoManager.enable();

      window.editor.addPage(page);
    },

    POMReady: function($super, e) {
      $super(e);
      if (this.page.context === lp.pom.context.EDIT) {
        this.createDependantElements();
      }
    },

    updateContentFields: function(fields){
      this.model.set('content.fields', fields, this.page.undoManager);
      this.adjustFormGeometry();
    },

    dblclick: function(e){
      // TODO: Test and Refactor
      Event.stop(e);
      this.getModule().openBuilder(this, {
        callback: this.updateContentFields.bind(this)
      });
      this.fireEvent('dblclick', e);
    },

    getFonts: function() {
      return (
        [
         this.model.getValuesFromBothBreakpoints('style.cbxlabel.font.family'),
         this.model.getValuesFromBothBreakpoints('style.label.font.family')
        ].
        flatten().
        uniq(true)
      );
    },

    modelChanged: function($super, e) {
      var details = $super(e);
      var accessor = details.accessor;

      var accessorListToIgnore = [
        'geometry.label.calculatedWidth',
        'geometry.size.height',
        'content.confirmAction',
        'name'
      ];

      if(this.model.attributeChangeIgnore(accessor, accessorListToIgnore)) {
        return;
      } else if (accessor === 'content.fields') {
        this.updateFields();
      } else if (accessor === ('style.label.color') ||
                 accessor === ('style.cbxlabel.color') ||
                 accessor.startsWith('style.field' ||
                  accessor === 'geometry.field.cornerRadius')
              ) {
                this.updateCSSRules();
              }
              else {
                this.updateCSSRules({accessor:accessor});
                if(!accessor.startsWith('content.confirmationPageSize')) {
                  this.adjustFormGeometry(accessor);
                }
              }
    },

    updateCSSRules: function(options) {
      /* jshint maxstatements:62 */
      if (options && options.accessor && options.accessor === 'geometry.offset') {
        this.updateElementGeometry();
        return;
      }
      var rules = [];
      var self = this;
      var m = this.model;
      var label = m.exists('style.label') ? m.get('style.label') :
        {font:{size:14, family:'arial', weight:'normal'}, color:'000'};
      var selField = '#'+this.id+' .lp-pom-form-field';
      var selLabel = selField+' label';
      var selText = selField+' input.text';
      var selTextarea = selField+' textarea';
      var selOptLabel = selField+' .option label';
      var selOptInput = selField+' .option input';
      var rule = function(s, a, v) {
        rules.push({ selector: s, attribute: a, value: v });
      };

      this.page.style.removeCSSRules('#'+this.id);

      var lineHeight = Math.round(label.font.size*1.1);
      var fieldHeight = m.get('geometry.field.height');
      var borderWidth = m.exists('geometry.field.border.width') ? m.get('geometry.field.border.width') : 1;

      var isMarginTopSettable = function(m) {
       return (
         m.get('geometry.label.alignment') === 'left' &&
         m.exists('style.label.centerAlign') &&
         m.get('style.label.centerAlign') === true
       );
      };

      var getMarginTop = function(fieldHeight, label, borderWidth) {
        return Math.max(0, Math.floor((fieldHeight - label.font.size) / 2) + borderWidth);
      };

      if(isMarginTopSettable(m)) {
        rule(selLabel, 'margin-top', getMarginTop(fieldHeight, label, borderWidth)+'px');
      } else {
        rule(selLabel, 'margin-top', '0px');
      }

      rule(selLabel, 'font-family', label.font.family);
      rule(selLabel, 'font-weight', label.font.weight);
      rule(selLabel, 'font-size', label.font.size+'px');
      rule(selLabel, 'line-height', lineHeight+'px');
      rule(selLabel, 'color', '#'+label.color);

      var cbxlabelFont = m.exists('style.cbxlabel.font') ?
        m.get('style.cbxlabel.font') :
        {size:14, family:'arial', weight:'normal'};

      var cbxlabelColor= m.exists('style.cbxlabel.color') ?
        m.get('style.cbxlabel.color') :
        '000';

      rule(selOptLabel, 'font-family', cbxlabelFont.family);
      rule(selOptLabel, 'font-weight', cbxlabelFont.weight);
      rule(selOptLabel, 'font-size', cbxlabelFont.size+'px');
      rule(selOptLabel, 'line-height', Math.round(cbxlabelFont.size*1.16)+'px');
      rule(selOptLabel, 'left', Math.max(18, cbxlabelFont.size)+'px');
      rule(selOptLabel, 'color', '#'+cbxlabelColor);

      rule(selOptInput, 'top', Math.max(0, Math.round((cbxlabelFont.size*1.16 - 12) / 2))+'px');

      if (m.exists('style.field.backgroundColor')) {
        rule(selText, 'background-color', '#'+m.get('style.field.backgroundColor'));
        rule(selTextarea, 'background-color', '#'+m.get('style.field.backgroundColor'));
      }

      if (m.exists('style.field.color')) {
        var color = '#'+m.get('style.field.color');
        rule(selText, 'color', color);
        rule(selTextarea, 'color', color);
      }

      if (m.exists('style.field.innerShadow') && m.get('style.field.innerShadow')) {
        var shadowColor = this.calculateShadowColor(
          m.exists('style.field.backgroundColor') ?
          m.get('style.field.backgroundColor') : 'ffffff');

          var shadow = 'inset 0px 2px 3px #'+ shadowColor;

          rule(selText, 'box-shadow', shadow);
          rule(selTextarea, 'box-shadow', shadow);
          rule(selText, '-webkit-box-shadow', shadow);
          rule(selTextarea, '-webkit-box-shadow', shadow);
          rule(selText, '-moz-box-shadow', shadow);
          rule(selTextarea, '-moz-box-shadow', shadow);
      }

      if (m.exists('geometry.field.border')) {
        var border = m.get('geometry.field.border');
        var fields = ['input[type=text]', 'textarea', 'select'];
        fields.each(function(field){
          rules.push({selector:'#'+self.id+' .lp-pom-form-field ' + field, attribute:'border-style', value:border.style});
          if(border.style !== 'none') {
            rules.push({selector:'#'+self.id+' .lp-pom-form-field ' + field, attribute:'border-width', value: border.width+'px'});
            if(border.color && border.color.strip() !== '') {
              rules.push({selector:'#'+self.id+' .lp-pom-form-field ' + field, attribute:'border-color', value:'#'+border.color});
            }
          }
        });
      }

      if (m.exists('geometry.field.cornerRadius')) {
        var radius = m.get('geometry.field.cornerRadius');
        rule(selText, 'border-radius', radius+'px');
        rule(selTextarea, 'border-radius', radius+'px');
      }

      this.updateElementGeometry();
      this.applyPageStyles(rules, options);
    },

    calculateShadowColor: function(color) {
      return jui.ColorMath.rgbToHex(jui.ColorMath.blend("multiply", jui.ColorMath.hexToRgb(color), jui.ColorMath.hexToRgb("dddddd")));
    },

    setDimensions: function($super, dims) {
      $super(dims);
      this.fieldset.setWidth(this.model.get('geometry.size.width'));
      if(this.model.exists('geometry.size.height')) {
        this.fieldset.setHeight(this.model.get('geometry.size.height'));
      }

      if (!(this.parentElement === null || this.adjustingGeometry) && this.getRootElement().isInserted()) {
        this.adjustFormGeometry();
      }
    },

    adjustFormGeometry: function() {
      /* jshint maxstatements:54 */
      /* jshint maxcomplexity:13 */
      if(arguments[0] === 'geometry.offset') {
        return;
      }

      if (this.adjustingGeometry) {
        return;
      }

      this.adjustingGeometry = true;
      var m = this.model;
      var visible = this.visible();
      var positionSubmit = this.canAutoPositionSubmitButton();
      var fieldViewCount = this.fieldViewMap.length;
      var i, f;

      if (!visible) {
        this.view.show();
      }

      if (positionSubmit) {
        this.submitButton.model.removeListener('attributeChanged', this.buttonMoveListener);
      }

      var g = {}; // this will hold all the calulated geometry information that each field needs to adjustGeometry

      g.leftLabelWidth = 0;
      g.x = 0;
      g.y = 0;

      g.labelMargin = m.get('geometry.label.margin');
      g.fieldHeight = m.get('geometry.field.height');
      g.fieldMargin = m.get('geometry.field.margin');
      g.fieldFontSize = Math.min((m.exists('geometry.field.fontSize') ? m.get('geometry.field.fontSize') :  12), g.fieldHeight);
      g.fieldBorder = m.exists('geometry.field.border.width') ? m.get('geometry.field.border.width')*2 : 2;

      var padding = Math.max(0, g.fieldHeight - g.fieldFontSize);

      g.fieldPaddingTop = Math.floor(padding / 2);
      g.fieldPaddingBottom = padding - g.fieldPaddingTop;
      g.labelAlign = m.get('geometry.label.alignment');

      g.formWidth = m.get('geometry.size.width');

      var minFormWidth = this.minFieldWidth;

      if (this.page.context === lp.pom.context.EDIT) {
        this.calculateAndStoreCurrentLabelWidth();
      }

      g.leftLabelWidth = this.getCalculatedLabelWidth();

      if (g.leftLabelWidth > 0) {
        minFormWidth += g.leftLabelWidth + g.labelMargin.right;
      }

      m.setMinSize({
        width: minFormWidth,
        height: 0
      });

      if (g.formWidth < minFormWidth) {
        g.formWidth = minFormWidth;
        m.setWidth(minFormWidth);
      }

      for (i=0; i < fieldViewCount; i++) {
        f = this.fieldViewMap[i];
        f.view.adjustGeometry(g, f.field, f);

        if (this.page.context === lp.pom.context.EDIT) {
          f.field.labelHeight = f.view.getLabelHeight(f.field);
          m.set('content.fields.' + i + '.labelHeight', f.field.labelHeight);
          if (f.field.lpType === 'checkbox-group' || f.field.lpType === 'radio-group') {
            m.set('content.fields.' + i + '.optionsListHeight', f.view.getOptionsListHeight());
          }
        }
      }

      this.model.setHeight(g.y - g.fieldMargin.bottom);

      if (positionSubmit === 'auto') {
        this.submitButton.model.setOffset({
          left: g.leftLabelWidth === 0 ? 0: g.leftLabelWidth + g.labelMargin.right,
          top: g.y
        });

        this.submitButton.model.addListener('attributeChanged', this.buttonMoveListener);
      }

      if (!visible) {
        this.view.hide();
      }

      this.adjustingGeometry = false;

    },

    //TODO-refactor: how does this method work? Is it named correctly? I expect that if the model
    //is set to manual buttonPlacement than this would return false but as we can see it
    //returns true even if the placement is set to manual.
    canAutoPositionSubmitButton: function() {
      return !Object.isUndefined(this.submitButton) &&
        (!this.model.exists('geometry.buttonPlacement') || this.model.get('geometry.buttonPlacement'));
    },

    clearLabelSizeAttributes: function() {
      for (var i=0, len=this.fieldViewMap.length; i < len; i++) {
        var f = this.fieldViewMap[i];
        f.view.clearLabelSize(f.field);
      }
    },

    setLabelWidthExplicitly: function(width, undoManager) {
      width = Math.max(this.minLabelWidth, Math.min(width, this.getMaxLabelWidth()));

      if (undoManager) {
        undoManager.startGroup();
      }

      this.model.set('geometry.label.width', width, undoManager);

      if (undoManager) {
        undoManager.endGroup();
      }
    },

    setAutoCalculateLabelWidth: function(auto, undoManager) {
      if (auto) {
        this.model.deleteAttr('geometry.label.width',undoManager);
      } else {
        this.setLabelWidthExplicitly(this.getCalculatedLabelWidth(), undoManager);
      }
    },

    isAutoCalculateLabelWidth: function() {
      return !this.model.exists('geometry.label.width');
    },

    setLabelAlignment: function(value, undoManager) {
      this.model.set('geometry.label.alignment', value, undoManager);
    },

    getLabelAlignment: function() {
      return this.model.get('geometry.label.alignment');
    },

    isLabelAlignTop: function() {
      return this.getLabelAlignment() === 'top';
    },

    isLabelAlignLeft: function() {
      return this.getLabelAlignment() === 'left';
    },

    setCalculatedLabelWidth: function(width, undoManager) {
      this.model.set('geometry.label.calculatedWidth', width, undoManager);
    },

    getCalculatedLabelWidth: function() {
      return this.isLabelAlignLeft() ? this.model.get('geometry.label.calculatedWidth') : 0;
    },

    calculateAndStoreCurrentLabelWidth: function() {
      var width = 0;

      if (this.isLabelAlignLeft()) {
        width = this.isAutoCalculateLabelWidth() ?
          this.autoCalculateLabelWidth() : this.model.get('geometry.label.width');
        this.setCalculatedLabelWidth(width);
      }

      return width;
    },

    getMaxLabelWidth: function() {
      return this.model.getWidth() - this.minFieldWidth - this.model.get('geometry.label.margin.right');
    },

    getMinLabelWidth: function() {
      return this.minLabelWidth;
    },

    getLongestLabelWidth: function() {
      var width = 0;
      this.clearLabelSizeAttributes();
      for (var i=0, len=this.fieldViewMap.length, field; i < len; i++) {
        field = this.fieldViewMap[i];
        width = Math.max(width, field.view.getUnwrappedLabelWidth(field.field));
      }
      return width;
    },

    autoCalculateLabelWidth: function() {
      var width = Math.min(this.getLongestLabelWidth(), this.getMaxLabelWidth());
      if (width === 0) { // JS: then there were no visible labels
        return 0;
      }

      return Math.max(width, this.minLabelWidth);
    },

    //TODO-refactor
    createFieldViews: function() {
      this.fieldViews = (new lp.module.form.FormElementViews(this)).all();
    },

    getFieldsFromModel: function() {
      return this.model.exists('content.fields') ? this.model.get('content.fields') : [];
    },

    fieldAttributeTypes: function() {
      return $H(lp.module.form.FormElement.ModelAttributeExtractor.TransformationMap).keys();
    },

    cleanInvalidFields: function() {
      var fieldCleaner = new lp.module.form.FormElement.FieldCleaner(this.model, this.fieldAttributeTypes());
      fieldCleaner.cleanInvalidFields();
    },

    updateFields: function(options) {
      var fields = this.getFieldsFromModel();
      this.fieldViewMap = [];
      this.fieldset.clear();
      fields.each(function(f){
        var v = this.fieldViews.find(function(view){
          return view.types.indexOf(f.lpType) > -1;
        }).view(f);
        this.fieldset.insert(v.root);
        this.fieldViewMap.push({view:v, field:f});
      }, this);

      this.updateCSSRules(options);

      if (this.parentElement !== null) {
        this.regenerateGeometry();
      }
    },

    getViewForField: function(f) {
      return this.fieldViewMap.find(function(fv) {return fv.field === f;});
    },

    canHaveGoals: function() {
      return true;
    },

    getGoals: function() {
      return [{type:'form', url:'/fs' }];
    },

    setFormGeometryToModel: function(callback) {
      this.modelUpdateable = true;
      callback.apply(this);
      this.modelUpdateable = false;
    },

    setFormGeometryToModelForBreakpoints: function() {
      if(_.isUndefined(this.page)) { return; }

      var form = this
        , page = form.page
        , breakpoints = page.getBreakPoints()
        , currentBreakpoint = page.getCurrentBreakpoint();

      var retainActiveElement = function(callback) {
        var elm = window.editor.activeElement;
        callback()
        window.editor.setActiveElement(elm);
      };

      var rearrange = function(breakpoints, currentBreakpoint) {
        var rearrangedBreakpoints = _.reject(breakpoints, function(breakpoint) {
          return breakpoint.name === currentBreakpoint.name;
        });
        rearrangedBreakpoints.push(currentBreakpoint);
        return rearrangedBreakpoints;
      };

      var draw = function(page, breakpoint) {
        page.currentBreakpoint = breakpoint;
        page.updateAndRefresh();
      };

      _.each(rearrange(breakpoints, currentBreakpoint), function(breakpoint) {
        retainActiveElement(function() { draw(page, breakpoint) });
        form.isVisibleOnPage() && form.setFormGeometryToModel(form.adjustFormGeometry);
      });
    },

    beforePageSave: function(){
      this.cleanInvalidFields();

      if(editor.page.isMain()) {
        this.setFormGeometryToModelForBreakpoints();
      }

      if (this.model.exists('content.confirmAction') && this.model.get('content.confirmAction') === 'modal') {
        this.page.getBreakPoints().each( function(breakpoint) {
          var confirmPage = window.editor.findPagesUsedAs('form_confirmation')[0];
            this.model.set(
              'content.confirmationPageSize.' + breakpoint.name,
               confirmPage.getDimensions(breakpoint.name)
            );
        }, this);
      }
    },

    applyStyleAttributes: function($super) {
      this.updateFields();
      $super();
    },

    _detach: function($super) {
      $super();
      this.page.style.removeCSSRules('#'+this.id+' .lp-pom-form-field');
      this.page.style.updatePageStyles();
    },

    destroy: function($super) {
      $super();
      if (this.model.exists('content.confirmationPage')) {
        var confirmationPage = window.editor.pages.find( function(page) {return page.name === 'Form Confirmation Page';});
        window.editor.removePage(confirmationPage);
      }
      this.page.removeListener('beforePageSave', this);
    }
  }
);

lp.module.form.FormElement.elementDefaults = {
  name:'Form',
  style:{
    label:{
      font:{size:14, family:'arial', weight:'bold'},
      color:'000',
      centerAlign: true
    },

    cbxlabel:{
      font:{size:13, family:'arial', weight:'normal'},
      color:'000'
    },
    field:{
      innerShadow: true,
      backgroundColor: 'fff',
      color: '000'
    }
  },
  geometry:{
    position:"absolute",
    offset:{top:0,left:0},
    size:{width:240},
    label:{
      height:14,
      calculatedWidth: 0,
      margin:{bottom:4,right:12},
      alignment:'top'
    },
    field:{
      height:32,
      margin:{bottom:18},
      fontSize:15,
      cornerRadius:5,
      border: {
        color: 'bbbbbb',
        style: 'solid',
        width: 1
      }
    },
    buttonPlacement:'auto'
  },
  content:{
    submitButtonText: 'Form Button',
    confirmAction:'modal',
    confirmMessage:'Thank you!',
    passParams: false,
    buttonId:null
  }
};
