(function(){

  var lp      = this.lp || {};
  lp.Geometry = lp.Geometry || {};

  lp.Geometry.Comparison = function(previous, newSize) {

    var hasHeightChanged = function() {
      return typeof previous.height === 'number' && previous.height !== newSize.height;
    };

    var hasWidthChanged = function() {
      return typeof previous.width === 'number' && previous.width !== newSize.width;
    };

    var hasSizeChanged = function() {
      return hasWidthChanged() || hasHeightChanged();
    };

    return {
      hasHeightChanged: hasHeightChanged,
      hasWidthChanged: hasWidthChanged,
      hasSizeChanged: hasSizeChanged
    };

  };
})();
