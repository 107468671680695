lp.module.socialWidget.SocialWidgetElement = Class.create(
  lp.pom.VisibleElement,
  lp.ModuleComponent,
  {
    type:'lp-pom-social-widget',

    initialize: function($super, page, jso){
      $super(page, jso);

      //We may need to reload the widgets so if we are in edit mode and we switch
      //subpage then we reload the widgets.
      if(this.page.context === lp.pom.context.EDIT) {
        editor.addListener('activePageChanged', this.updateWidget.bind(this));
        this.page.addListener('breakpointChanged', this.updateWidget.bind(this));
      }
    },

    getModelClass: function() {
      return lp.module.socialWidget.SocialWidgetModel;
    },

    initView: function() {
      var page    = this.page,
      widgets = this.getModule().widgetDefaults;

      this.widget = new lp.module.SocialWidget(
        widgets, this.model.get('content.widget.disabledWidgets')
      );

      this.updateWidget() ;
      this.handleURL();
      this.handleTweetMessage();
      //Let the widget know if we are in edit mode or not.
      this.model.set('content.widget.isActive', page.context !== lp.pom.context.EDIT);
      this.updateConstraints();
    },

    createDefaultConstraints: function($super) {
      $super();
      this.defaultConstraints.width_resizeable = this.model.exists('constraints.widthResizeable')&& this.model.get('constraints.widthResizeable');
      this.defaultConstraints.height_resizeable = this.model.exists('constraints.heightResizeable')&& this.model.get('constraints.heightResizeable');
    },

    modelChanged: function($super, e) {
      var details = $super(e);
      if(details.accessor.include('content.widget')) {
        this.updateWidgetDimensionAndSize();
      } else if(details.accessor.include('geometry.size')) {
        this.setMargins();
      } else if (details.accessor.include('constraints')) {
        this.defaultConstraints.width_resizeable = this.model.exists('constraints.widthResizeable')&& this.model.get('constraints.widthResizeable');
        this.defaultConstraints.height_resizeable = this.model.exists('constraints.heightResizeable')&& this.model.get('constraints.heightResizeable');
      }
    },

    updateWidgetDimensionAndSize: function() {
      this.updateWidget();
      this.updateDimensions();
      this.updateSize();
    },

    //Goes through all the necessary processes to update the widget on the page.
    updateWidget: function() {
      this.widget.setWidget(this.model.get('content.widget'));
      var widgetBlockHtml = this.widget.getWidgetBlockHtml();
      this.insertWidgetStylesIntoPage();
      this.insertWidgetScriptsIntoPage();
      this.update(widgetBlockHtml);
      this.createOverlay();
      this.setMargins();
    },

    //The constraints are determine by the orientation.  If horizontal than make only the width
    //resizeable and if vertical than only the height can change.
    updateConstraints: function() {
      if('horizontal' === this.widget.getOrientation()) {
        this.model.set('constraints.heightResizeable', false);
        this.model.set('constraints.widthResizeable', true);
      } else {
        this.model.set('constraints.heightResizeable', true);
        this.model.set('constraints.widthResizeable', false);
      }
    },

    //The published url is determined by the user, if they select a custom url nothing
    //happens here otherwise if they have kept the "use published url" than we set the
    //url of the published page to the button.
    handleURL: function() {
      this.widget.getWidget().buttons.each(function(b) {
        if(!b.isCustomURL) {
          this.widget.setURLByWidgetName(b.name, this._getPublishedUrl());
        }
      },this);
      this.model.set('content.widget.buttons', this.widget.getWidget().buttons);
    },

    _getPublishedUrl: function() {
      // the form_confirmation page doesn't have a published_url
      // until the page is saved and reloaded so fall back to the
      // mainPage one which is the same anyway

      var pagePublishedUrl = this.page && this.page.page && this.page.page.published_url;
      var mainPagePublishedUrl = window.editor && window.editor.mainPage && window.editor.mainPage.page.published_url;

      return pagePublishedUrl || mainPagePublishedUrl;
    },

    handleTweetMessage: function() {
      var msg      = this.widget.getValueByWidgetNameAndField('twitter', 'tweet')
        , useTitle = this.widget.getValueByWidgetNameAndField('twitter', 'usePageTitleForTweets')
        , tweet    = useTitle ? this.page.metaData.title : msg;

      //If we are in edit mode check to see if we first need to load the widget to use the title
      //for the tweet message, otherwise use the custom message.
      if(lp.pom.context.PUBLISH !== this.page.context) {
        tweet = useTitle && tweet === this.page.metaData.title ? this.getModule().tweetMessageInstructionText : tweet;
      }

      this.widget.setValueByWidgetNameAndField('twitter', 'tweet', tweet);
      this.model.set('content.widget.buttons', this.widget.getWidget().buttons);
    },

    //Calculate the size of the widget.  This is needed because a button can be
    //added or removed and this should trigger a new size for the widget.
    updateSize: function() {
      var widget    = this.model.get('content.widget')
        , modelSize = this.model.get('geometry.size')
        , size      = this.widget.getCalculatedSize()
        , height    = modelSize.height > size.height ? modelSize.height : size.height
        , width     = modelSize.width > size.width ? modelSize.width : size.width;

      this.updateingHeight = false;
      this.getModule().restrictModelToSize(this.model, size, widget.orientation);

      //If the size is out bounds of the minimum and maximum sizes than readjust.
      if(this.model.getMinSize().width > width || this.model.getMaxSize().width < width) {
        width = size.width;
      }

      if(this.model.getMinSize().heigth > height || this.model.getMaxSize().height < width) {
        height = size.height;
      }

      //If there is one button or no buttons than use the
      //calculated size for the widget.
      if(this.widget.getWidget().buttons.length <= 1) {
        height = size.height;
        width = size.width;
      }
      this.model.set('geometry.size', {
        width: width,
        height: height
      });

      //Adujst the margins appropriately for the new size.
      this.setMargins();
    },

    disableButtonByName: function(buttonName) {
      var buttons         = this.model.get('content.widget.buttons').clone(),
          disabledButtons = this.model.get('content.widget.disabledWidgets').clone(),
      buttonToRemove      = this.widget.getButtonIndex(buttonName),
      um                  = this.model.element.page.undoManager;

      disabledButtons.push(buttons.splice(buttonToRemove,1).first());

      um.startGroup();
      this.model.set('content.widget.buttons', buttons, um);
      this.model.set('content.widget.disabledWidgets', disabledButtons, um);
      um.endGroup();
    },
    enableButtonByName: function(buttonName) {
      var insertAtIndex     = this.getModule().getDefaultIndexByWidget(buttonName),
      disabledButtons   = this.model.get('content.widget.disabledWidgets').clone(),
      buttons           = this.model.get('content.widget.buttons').clone(),
      um                  = this.model.element.page.undoManager,
      disabledIndex     = -1;

      //Make sure we always insert in the correct index. A case where there
      //is only one button in the widget could mess up the ordering. This
      //insures that the ordering is always correct.
      if(buttons.length === 1) {
        var siblingIndex = this.getModule().getDefaultIndexByWidget(buttons.first().name);
        if(siblingIndex > insertAtIndex) {
          insertAtIndex--;
        }
      }

      //Don't forget to remove the button from the disabledButtons array.
      disabledButtons.each(function(b, i){
        if(buttonName === b.name) {
          buttons.splice(insertAtIndex, 0, b);
          disabledIndex = i;
          return;
        }
      });

      if(disabledIndex >= 0) {
        disabledButtons.splice(disabledIndex, 1);
      }

      um.startGroup();
      this.model.set('content.widget.buttons', buttons, um);
      this.model.set('content.widget.disabledWidgets', disabledButtons, um);
      um.endGroup();
    },

    //Calculate the margins in between each button in the widget when resizing
    //the width or the height.
    setMargins: function() {
      var numberOfButtons = this.widget.getButtons().length,
      orientation     = this.widget.getOrientation(),
      ele             = this.getViewDOMElement(),
      widgetButtons   = ele.getElementsBySelector('.widget'),
      modelSize       = this.model.getSize(),
      widgetSize      = this.widget.getCalculatedSize(),
      totalMargin     = 0,
      margin          = 0;

      //Go through each button and depending on the orientation type, adjust the margin.
      widgetButtons.each(function(button, index) {
        if(index > 0) {
          if('horizontal' === orientation) {//Set the left/right margins.
            totalMargin = (modelSize.width - widgetSize.width);
            margin = Math.floor(totalMargin / (numberOfButtons - 1));
            button.setStyle({ marginLeft: margin + 'px' });
          } else {//Set the top/bottom margins.
            totalMargin = (modelSize.height - widgetSize.height);
            margin = Math.floor(totalMargin / (numberOfButtons - 1));
            button.setStyle({marginTop: margin + 'px'});
          }
        }
      });
    },

    //Add an overlay to the widget in edit and preview mode to stop the click event
    //the buttons.  The overlay does not get added on published pages.
    createOverlay: function() {
      if(lp.pom.context.PUBLISH !== this.page.context) {
        this.overlay = new Element('div', {
          className: 'lp-social-widget-overlay'
        });

        //The overlay styles are shared with the video widget
        //which styles it with a color so we should set the
        //opacity.
        this.overlay.setOpacity(0.0);
        this.insert(this.overlay);
      }
    },

    //Returns the javascripts of each widget button and return it as one string of scripts.
    getAllActiveWidgetScripts: function() {
      var socialWidgetScripts = this.widget.getScripts();
      var scripts = '';
      socialWidgetScripts.each(function(script) {
        scripts += script;
      });
      return scripts;
    },

    //Add the scripts into the page.  If in edit mode than add the scripts manualy to
    //the document otherwise use page addInsertion for the scripts.
    insertWidgetScriptsIntoPage: function() {
      var socialWidgetScripts = this.widget.getButtons();
      var isActive = this.model.get('content.widget.isActive');
      socialWidgetScripts.each(function(button) {
        if(this.page.context === lp.pom.context.EDIT) {
          this.addJavascriptToDocument(button.getScript(),button.id);
        } else {
          this.addJavascriptInsertionToPage(button, isActive);
        }
      }, this);
    },

    addJavascriptInsertionToPage: function(button, isActive) {
      //Make sure that the insertion has not occured alread for this widget button.
      var isInPage = this.page.getInsertions().find(function(i){
        return i.content.include(button.id);
      });

      //If it is already in the page don't add it again.
      if(!isInPage) {
        this.page.addInsertion(button.getScript(isActive),'body:after');
      }

    },

    //Add the styles into the page.  If in edit mode than add the styles manualy to
    //the document otherwise use page addInsertion for the styles.
    insertWidgetStylesIntoPage: function() {
      var socialWidgetStyles = this.widget.getStyles();
      socialWidgetStyles.each(function(style) {
        if(this.page.context === lp.pom.context.EDIT) {
          this.addStylesToDocument(style);
        } else {
          this.page.addInsertion(style, 'head');
        }
      }, this);
    },

    //Add the scripts to the documents but first make sure that cleanup has
    //happend.  Cleanup will remove the scripts if they have been added already.
    addJavascriptToDocument: function(script) {
      this.widget.cleanup();
      $(document.body).insert(script, 'top');
    },

    //Add the stylesheets to the document head.
    addStylesToDocument: function(style) {
      if(style.length > 0) {
        $(document.getElementsByTagName('head')[0]).insert(style, 'bottom');
      }
    },

    //Make sure that scripts are removed from the page if the widget is deleted.
    destroy: function() {
      this.widget.cleanup();
    }

  }
);

lp.module.socialWidget.SocialWidgetElement.elementDefaults = {
  name:'Social Widget',
  content: {
    type: null,
    html: '',
    widget: {
      orientation: 'vertical',
      countOrientation: 'top',
      disabledWidgets: [],
      isActive: false,
      buttons: [
        {
        name: 'twitter',
        usePageTitleForTweets: true,
        tweet: '',
        isCustomURL: false,
        url: ''
      },
      {
        name: 'google',
        isCustomURL: false,
        url: ''
      },
      {
        name: 'facebook',
        verb: 'like',
        color: 'light',
        isCustomURL: false,
        url: window.location.href//facebook *must* always have a url so we use this until page saves page.
      }
      ]
    }
  },
  style:{
    opacity: 0.0
  },
  geometry:{
    position:"absolute",
    offset:{top: 0,left: 0},
    size: {width: 68, height: 203}
  },
  constraints: {
    heightResizeable: true
  }
};
