//------------------------------------------------------------------------------
jui.HelpButton = Class.create(jui.Component, {
  options: function($super,options){
    return $super($H({
      elementType: 'a'
      }).merge(options));
  },

  initialize: function($super, href, title) {
    $super({attributes:{
      href: href,
      title: title,
      className: 'help-button',
      target: '_blank'
      }});
  }
});

jui.HelpButtonCustomizable = Class.create(jui.Component, {
  options: function($super,options){
    return $super($H({
      elementType: 'a'
      }).merge(options));
  },

  initialize: function($super, options) {
    $super({attributes:{
      href: options.attributes.href || '#',
      title: options.attributes.title,
      className: options.attributes.className || 'help-button',
      target: options.attributes.target
    }});
  }
});
