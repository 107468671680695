lp.module.socialWidget.SocialWidgetElementPropertiesPanel = Class.create(
  lp.editor.panel.ElementPropertiesPanel,
  lp.editor.panel.ElementGeometryControls,
  lp.ModuleComponent,
  {
    type:'lp-pom-social-widget',

    initialize: function($super) {
      $super();
      this.handleChange();
    },

    installUI: function($super){
      $super();
      var geometryPanel = this.insert(new jui.CollapsiblePanel({title:'Geometry'}));
      this.installGeometryControls({
        insert:geometryPanel.insertContent.bind(geometryPanel)
      });
      this.addWidgetLayoutControl();
      this.addWidgetButtonControls();
    },

    updateOrientationControl: function() {
      var layout = this.getLayoutFromModel();
      this.controls.layout.select(this.getLayoutName(layout.orientation, layout.countOrientation));
    },

    syncUIControls: function($super, elm) {
      $super(elm);
      this.updateOrientationControl();
      if (elm) {
        this.bindGeometryControlsToElement(elm);
      }
    },

    pageUpdatedAndRefreshed: function($super) {
      $super();
      this.element.updateWidgetDimensionAndSize();
      this.element.updateConstraints();
    },

    handleChange: function() {
      var self = this;
      this.modelChangeHandlers.push(function(e){
        var accessor = e.data.accessor;
        if(accessor.include('content.widget')) {
          self.element.widget.setWidget(self.element.model.get('content.widget'));
          self.updateOrientationControl();
          self.handleButtonsChange();
          self.handleFacebookChange();
        }
      });
    },

    handleButtonsChange: function() {
      var buttons  = this.element.model.get('content.widget.buttons').clone(),
      disabled = this.element.model.get('content.widget.disabledWidgets').clone(),
      self     = this;

      //Handle active buttons on the social platforms panel.
      buttons.each(function(b){
        if(self.controls[b.name + '_enable'].getValue() !== true) {
          self.controls[b.name + '_enable'].setValue(true);
          self.widgetPanels[b.name].distinct();
        }
      });

      //Handle inactive buttons on the social platforms panel.
      disabled.each(function(b){
        self.controls[b.name + '_enable'].setValue(false);
        self.widgetPanels[b.name].dim();
      });
    },

    handleFacebookChange: function() {
      var verb = 'like',color = 'light';
      var buttons = this.element.model.get('content.widget.buttons').clone();
      buttons.each(function(b){
        if('facebook' === b.name) {
          verb = b.verb;
          color = b.color;
        }
      });
      this.controls.facebookVerb.select(verb);
      //this.controls.facebookColorScheme(color);
    },

    addWidgetLayoutControl: function() {
      var self             = this,
      orientationPanel = this.insert(new jui.CollapsiblePanel({title:'Layout'}));

      this.controls.layout = orientationPanel.insertContent(new jui.SocialWidgetLayoutMenu({
        label: 'Layout',
        name: 'layout',
        change: function(value) {
          var layout = self.getLayoutByName(value.data);
          self.undoGroup(function(um){
            self.element.model.set('content.widget.orientation', layout.orientation, um);
            self.element.model.set('content.widget.countOrientation', layout.countOrientation, um);
            self.updateConstraintsByLayout(layout, um);
          });
        },
        layoutButtons: [
          {id: 'horizontal_count_top'},
          {id: 'vertical_count_top'},
          {id: 'horizontal_count_right'},
          {id: 'horizontal_no_count'},
          {id: 'vertical_no_count'}
        ]
      }));
    },

    addWidgetButtonControls: function() {
      var widgets     = this.getModule().widgetDefaults,
      self        =this,
      socialPanel = this.insert(new jui.CollapsiblePanel({title: 'Social Platforms'}));

      this.widgetPanels = {};
      this.widgetFields = {};

      //This adds the controls for each widget button - Twitter, Google and Facebook
      widgets.buttons.each(function(button) {
        this.widgetPanels[button.name] = socialPanel.insert(new jui.DimmablePanel({
          title: button.name.capitalize(),
          dimmed: function() {
            self.widgetPanels[button.name].e.observe('mouseup', self.reactivate.bindAsEventListener(self,button.name));
          },
          distinctive: function() {
            self.widgetPanels[button.name].e.stopObserving('mouseup');
          }
        }));
        this.widgetFields[button.name] = button.fields;
      }, this);

      //Handle each widget and add the specific controls for them.
      $H(this.widgetPanels).keys().each(function(widget) {
        this.addEnableWidgetButtonControl(widget);
      }, this);

      this.addTwitterControls();
      this.addGoogleControls();
      this.addFacebookControls();
    },

    reactivate: function(e, buttonName) {
      var checkbox = this.controls[buttonName + '_enable'];
      //If we click on the actual enable checkbox than let it
      //go through its natural course.
      if('checkbox' !== e.target.readAttribute('type') &&
         buttonName + '_enabler' !== e.target.readAttribute('for')) {

        checkbox.setValue(true);
        this.widgetPanels[buttonName].distinct();
        this.enableButton(buttonName);
      }
    },

    //When a layout changes (horizontal, vertical) change the constraints as well.
    updateConstraintsByLayout: function(layout, undoManager) {
      if('horizontal' === layout.orientation) {
        this.element.model.set('constraints.heightResizeable', false, undoManager);
        this.element.model.set('constraints.widthResizeable', true, undoManager);
      } else {
        this.element.model.set('constraints.heightResizeable', true, undoManager);
        this.element.model.set('constraints.widthResizeable', false, undoManager);
      }
    },

    //Return the layout by its name. Either none, top or right.
    getLayoutByName: function(layoutName) {
      var layout = layoutName.split('_'),
      countOrientation  = 'none';

      if('count' !== layout[2]) {countOrientation = layout[2];}

      return {
        orientation: layout[ 0 ],
        countOrientation: countOrientation
      };
    },

    getLayoutFromModel: function() {
      var orientation    = this.element.model.get('content.widget.orientation'),
        countOrientation = this.element.model.get('content.widget.countOrientation');

      return {
        orientation: orientation,
        countOrientation: countOrientation
      };
    },

    //Adds a checkbox which allows a widget button to be enabled and disabled.  Defaults to enabled.
    addEnableWidgetButtonControl: function(widgetName) {
      var self          = this,
      titleIcon     = new Element('div', {className: 'widget-icon icon_' + widgetName}),
      titleText     = new Element('div', {className: 'widget-title-text'}).update(widgetName.capitalize()),
      titleDiv      = new Element('div').insert(titleIcon).insert(titleText);

      var titleControl  = new jui.Component({
        attributes: {className: 'widget-title'}
      }).update(titleDiv);

      this.controls[widgetName + '_enable'] = new jui.FormCheckboxInput({
        label: 'Enable',
        checked: true,
        inputId: widgetName +'_enabler',
        onclick: function(e) {
          self.performModelUpdates(function(){
            if(!e.data) {
              self.widgetPanels[widgetName].dim();
              self.disableButton(widgetName);
            } else {
              self.enableButton(widgetName);
              self.widgetPanels[widgetName].distinct();
              self.buildElement(self.element);
            }
          });
        }
      });

      titleControl.insert(this.controls[widgetName + '_enable']);

      this.widgetPanels[widgetName].insertContent(titleControl);
    },

    //disabling is a simple process.  Simply get the button index splice it out of the buttons array
    //and inject it into the disabledButtons array.
    disableButton: function(buttonName) {
      this.element.disableButtonByName(buttonName);
    },

    //Enabling a button is a little more involved.  We need to ask where it should go first by
    //querying the default widget object.  Once we see what the index should be we can start
    //the process of inserting the button back into the buttons array.  The only thing we need
    //to be careful about at this point is if there is only one item in the buttons array.  This
    //is because the indexs of the buttons array will not match the indexs of the default widget
    //that we are using for the ordering.  So there is one extra step that we take to fix this.
    enableButton: function(buttonName) {
      this.element.enableButtonByName(buttonName);
    },

    //Add the controls for Twitter to the panel.
    addTwitterControls: function() {
      var self = this;

      //Tweet message textarea.
      this.controls.tweet = new jui.FormTextArea({
        attributes: {className: 'tweet-textarea'},
        label: '',
        onblur: function() {
          self.performModelUpdates(function() {
            self.setWidgetValueByNameAndField('twitter', 'tweet', self.controls.tweet.getValue());
            self.toggleTweetMessage(false);
          });
        },
        onfocus: function(e) {
          self.requestFocus(e);
          self.controls.tweetType.select('custom');
          self.toggleTweetMessage(true);
          self.handleMessageAreaAndSetTweetType('custom');
        }
      });

      //Radio buttons to select either a custom message or the page title
      //for the tweet message.
      this.controls.tweetType = this.widgetPanels.twitter.
        insertContent(new jui.FormMultiRadioInput2({
        label: 'Tweet Message',
        name: 'tweetType',
        change: self.toggleTweetTypeField.bindAsEventListener(this, true),
        selected: self.toggleTweetTypeField.bindAsEventListener(this, false),
        radiobuttons: [
          {
          id: 'pageTitle',
          checked: true,
          label: 'Use Page Title<br/><span class="subtext">Change the title by clicking Title &amp; Meta in top toolbar.</span>'
        },
        {
          id: 'custom',
          checked: false,
          label: this.controls.tweet
        }
        ]
      }));

      //Preview tweet message button.
      this.controls.previewTweet = this.widgetPanels.twitter.insertContent(new jui.Button({
        label: 'Preview Tweet Message',
        attributes: {className: 'button tweet'},
        action: function() {
          var url         = self.element.page.page.published_url,
          useTitleMsg = 'pageTitle' === self.controls.tweetType.getValue(),
          pageTitle   = self.element.page.metaData.title,
          tweet       = useTitleMsg ? pageTitle : self.controls.tweet.getValue(),
          size        = {width:550, height:251},
          left        = (screen.width  - size.width)/2,
          top         = (screen.height - size.height)/2,
          twitterURL  = 'http://twitter.com/intent/tweet?url='+url+'&text=' + encodeURIComponent(tweet),
          tweetWindow = window.open(twitterURL,'name','width='+size.width+', height='+size.height+'');
            //Position the tweet message popup near the widget.
            tweetWindow.moveTo(left, top);
            tweetWindow.resizeTo(size.width,size.height);
            tweetWindow.focus();
          }
        }));
      },

      //Called from this.controls.tweetType this is invoked either by changing the tweet type
      //or by selecting the tweet type control. If isTitleTypeChange is true update the twitter
      //button with the new value otherwise don't do that step.  The point of this process
      //is to keep the refreshing of the widget to a minimum especially when we are only selecting.
      toggleTweetTypeField: function(e, isTitleTypeChange) {
        if(isTitleTypeChange) {
          this.handleMessageAreaAndSetTweetType(e.data);
        } else {
          this.handleMessageArea(e.data);
        }
      },

      //Set the usePageTitleForTweet field in the twitter object before toggling the textarea for the tweet.
      handleMessageAreaAndSetTweetType: function(tweetType) {
        this.setWidgetValueByNameAndField('twitter', 'usePageTitleForTweets', 'custom' !== tweetType, this.element.page.undoManager);
        this.handleMessageArea(tweetType);
      },

      //Toggle the textarea depending on if the tweetType is custom or use pageTitle.
      //If it is custom remove the default text if it is pageTitle fade the text box message.
      handleMessageArea: function(tweetType) {
        this.toggleTweetMessage('custom' === tweetType);
        this.toggleTextarea(tweetType);
      },

      //Depending on the tweetType set the textarea to either disabled or enabled.
      toggleTextarea: function(tweetType) {
        if('pageTitle' === tweetType) {
          this.controls.tweet.e.addClassName('disable');
        } else {
          this.controls.tweet.e.removeClassName('disable');
        }
      },

      //Choose to either clear the textarea or add instruction text.
      toggleTweetMessage: function(clear) {
        if(clear && (this.getModule().tweetMessageInstructionText === this.controls.tweet.getValue() ||
                     this.controls.tweet.getValue().strip() === '')) {

          this.controls.tweet.setValue('');

        } else if(!clear && this.getModule().tweetMessageInstructionText === this.controls.tweet.getValue() ||
                  this.controls.tweet.getValue().strip().length === 0) {

          this.controls.tweet.setValue(this.getModule().tweetMessageInstructionText);

        }
      },

      //Add the controls for Google to the panel.
      addGoogleControls: function() {
        var self = this;

        //Set url type dropdown for google.
        this.controls.selectGoogleURLType = this.widgetPanels.google.insertContent(new jui.FormSelect({
          label:'Set Url',
          id: 'jui-set-google-url-select',
          dropDownClassName : 'editor-info-panel-select jui-drop-down',
          width: '200px',
          selectOptions:[
          {name:"Use published page's URL", value:'publishedURL'},
          {name:'Use custom URL', value:'customURL'}
          ],
          action: function() {
            self.performModelUpdates(function() {
              var urlType = self.controls.selectGoogleURLType.getValue();
              if('publishedURL' === urlType) {
                self.setWidgetValueByNameAndField('google', 'isCustomURL', false, self.element.page.undoManager);
              } else {
                var url = self.controls.googleURL.getValue();
                //if the url is empty redefine it with the current location.
                url = url.length > 0 ? url : self.element.page.page.published_url;
                self.undoGroup(function(um) {
                  self.setWidgetValueByNameAndField('google', 'url', url, um);
                  self.setWidgetValueByNameAndField('google', 'isCustomURL', true, um);
                });
              }
              self.toggleGoogleUrlControl(urlType);
            });
          }
        }));

        this.controls.googleURL = this.widgetPanels.google.
          insertContent(new jui.FormLinkInput({
          label: 'URL',
          onblur: function(){
            self.performModelUpdates(function() {
              var url = self.controls.googleURL.getValue();
              var returnObj = self.element.widget.setURLByWidgetName('google', url);
              self.element.model.set('content.widget', returnObj);
            });
          },
          onfocus: function(e) {self.requestFocus(e);}
        }));
      },

      //Add the controls for Facebook to the panel.
      addFacebookControls: function() {
        var self = this;

        this.controls.selectFacebookURLType = this.widgetPanels.facebook.insertContent(new jui.FormSelect({
          label:'Set Url',
          id: 'jui-set-facebook-url-select',
          dropDownClassName : 'editor-info-panel-select jui-drop-down',
          width: '200px',
          selectOptions:[
            {name:"Use published page's URL", value:'publishedURL'},
            {name:'Use custom URL', value:'customURL'}
          ],
          action: function() {
            self.performModelUpdates(function() {
              var urlType = self.controls.selectFacebookURLType.getValue();
              if('publishedURL' === urlType) {
                self.setWidgetValueByNameAndField('facebook', 'isCustomURL', false, self.element.page.undoManager);
              } else {
                var url = self.controls.facebookURL.getValue();
                //if the url is empty redefine it with the current location.
                url = url.length > 0 ? url : self.element.page.page.published_url;
                self.undoGroup(function(um) {
                  self.setWidgetValueByNameAndField('facebook', 'url', url, um);
                  self.setWidgetValueByNameAndField('facebook', 'isCustomURL', true, um);
                });
              }
              self.toggleFacebookUrlControl(urlType);
            });
          }
        }));

        this.controls.facebookURL = this.widgetPanels.facebook.
          insertContent(new jui.FormLinkInput({
          label: 'URL',
          onblur: function(){
            self.performModelUpdates(function() {
              var url       = self.controls.facebookURL.getValue(),
                  returnObj = self.element.widget.setURLByWidgetName('facebook', url);

              self.element.model.set('content.widget', returnObj);
            });
          },
          onfocus: function(e) {self.requestFocus(e);}
        }));

        this.controls.facebookVerb = this.widgetPanels.facebook.insertContent(new jui.FormMultiRadioInput({
          label: 'Verb',
          name: 'verb',
          change: function(){
            self.performModelUpdates(function(){
              var verb = self.controls.facebookVerb.getValue();
              self.setWidgetValueByNameAndField('facebook', 'verb', verb);
            });
          },
          radiobuttons: [
            {id: 'like', name: 'like', label: 'Like'},
            {id: 'recommend', name: 'recommend', label: 'Recommend'}
          ]
        }));

        this.controls.facebookColorScheme = this.widgetPanels.facebook.insertContent(new jui.FormMultiRadioInput({
          label: 'Color',
          name: 'color',
          change: function(){
            self.performModelUpdates(function(){
              var color = self.controls.facebookColorScheme.getValue();
              self.setWidgetValueByNameAndField('facebook', 'color', color);
            });
          },
          radiobuttons: [
            {id: 'light', name: 'light', label: 'Light'},
            {id: 'dark', name: 'dark', label: 'Dark'}
          ]
        }));

      },

      //Either displays the facebook url depending on if the user selected to use a custom url or to
      //use the published pages url.
      toggleFacebookUrlControl: function(urlType) {
        this.controls.facebookURL['customURL' === urlType ? 'show' : 'hide']();
      },

      //Either displays the Google url depending on if the user selected to use a custom url or to
      //use the published pages url.
      toggleGoogleUrlControl: function(urlType) {
        this.controls.googleURL['customURL' === urlType ? 'show' : 'hide']();
      },

      getLayoutName: function(orientation, countOrientation) {
        return orientation + '_' + this.getCountName(countOrientation);
      },

      //Return a model friendly name of the count orientation.
      getCountName: function(countOrientation) {
        if('none'  === countOrientation) {
          return 'no_count';
        } else if('top' === countOrientation) {
          return 'count_top';
        } else if('right' === countOrientation) {
          return 'count_right';
        }
      },

      setWidgetValueByNameAndField: function(widgetName, field, value, um) {
        if(Object.isUndefined(um)) {um = this.element.page.undoManager;}
        var buttons = this.element.model.get('content.widget.buttons').clone();
        buttons.each(function(b){
          if(widgetName === b.name) {
            b[field] = value;
          }
        });
        this.element.model.set('content.widget.buttons', buttons, um);
      },

      undoGroup: function(undoBlock) {
        var um = this.element.page.undoManager;
        um.startGroup();
        undoBlock(um);
        um.endGroup();
      },

      setElement: function($super, elm) {
        $super(elm);
        if (null === this.parent) { return; }
        this.buildElement(elm);
      },

      buildElement: function(elm) {
        this.bindGeometryControlsToElement(elm);

        var orientation      = elm.model.get('content.widget.orientation'),
            countOrientation = elm.model.get('content.widget.countOrientation'),
            layout           = {orientation: orientation, countOrientation: countOrientation},
            buttons          = elm.model.get('content.widget.buttons').clone(),
            size             = elm.widget.getCalculatedSize();

        this.controls.layout.select(this.getLayoutName(orientation,countOrientation));
        this.getModule().restrictModelToSize(elm.model, size, layout.orientation);

        $H(this.widgetPanels).keys().each(function(button) {
          var isButtonEnabled = this.getModule().getWidgetIndexByName(buttons, button) > -1 ? true : false;
          this.controls[button + '_enable'].setValue(isButtonEnabled);
          this.widgetPanels[button][ isButtonEnabled ? 'distinct' : 'dim' ]();
        },this);

        //Twitter

        //Tweet Message
        var tweetMsg = elm.widget.getValueByWidgetNameAndField('twitter','tweet');
        var messageForTextArea = tweetMsg.length > 0 ? tweetMsg : this.getModule().tweetMessageInstructionText;

        //If the message in the tweet box is the default message make sure we set the
        //radio button to use the page title.
        var tweetType = elm.widget.getValueByWidgetNameAndField('twitter','usePageTitleForTweets');
        this.controls.tweetType.select(tweetType ? 'pageTitle' : 'custom');
        this.controls.tweet.setValue(decodeURIComponent(messageForTextArea.replace(/&#34;/g, '"')));
        //Google Plusone

        //URL
        var gURLType      = elm.widget.getValueByWidgetNameAndField('google', 'isCustomURL'),
            gURL          = gURLType ? elm.widget.getURLByWidgetName('google') : '',
            urlTypeGoogle = gURLType ? 'customURL' : 'publishedURL';

        this.controls.selectGoogleURLType.setSelectedValue(urlTypeGoogle);
        this.toggleGoogleUrlControl(urlTypeGoogle);
        this.controls.googleURL.setValue(gURL);

        //Facebook like

        //URL
        var fbURLType     = elm.widget.getValueByWidgetNameAndField('facebook', 'isCustomURL'),
                fbURL     = fbURLType ? elm.widget.getURLByWidgetName('facebook') : '',
                urlTypeFB = fbURLType ? 'customURL' : 'publishedURL';

        this.controls.selectFacebookURLType.setSelectedValue(urlTypeFB);
        this.toggleFacebookUrlControl(urlTypeFB);
        this.controls.facebookURL.setValue(fbURL);
        //Verb
        var verb = elm.widget.getValueByWidgetNameAndField('facebook','verb');
        this.controls.facebookVerb.select(verb);
        //Color
        var color = elm.widget.getValueByWidgetNameAndField('facebook','color');
        this.controls.facebookColorScheme.select(color);

      }
  }
);
