;(function(){
  var elementSets = window.rx_switchboard.subjects.magic.ui_events;

  var updateVisibilityIcon  = function(el) {

    var isVisible       = el.model.isVisible()
      , $visibilityIcon = jQuery('.element-properties-panel .visibility-toggle .visibility-state');

    $visibilityIcon.toggleClass('lp-hidden', !isVisible);
  };

  var toggleVisibilityMsg = function(el) {
    var isVisible = el.model.isVisible()
      , $msgPanel = jQuery('.content .visibility-toggle-message');

    $msgPanel.toggleClass('closed', isVisible);
  };

  var _handleFormButtonVisibility = function(buttonEl) {
    var form = buttonEl.getParentElement();
    if(form.isTypeOf('lp-pom-form')) {
      form.model.setVisible(!form.model.isVisible());
    }
  };

  var handleVisibilityToggle = function(el) {
    if(el.isTypeOf('lp-pom-button') && el.isFormButton()) {
      _handleFormButtonVisibility(el);
    } else {
      var visible = !el.model.isVisible();
      el.model.setVisible(visible, el.page.undoManager);
      if (!visible) {
        // to prevent the transform boxes from being used to modify the element
        // the flashbox can still be used to display the location
        window.editor.transformBox.setElement(null);
        window.editor.imageTransformBox.setElement(null);
      } else if (el.isTypeOf('lp-pom-image')) {
        window.editor.imageTransformBox.setElement(el);
      } else {
        window.editor.transformBox.setElement(el);
      }
    }

    window.editor.page.updateAndRefresh();

    updateVisibilityIcon(el);
    toggleVisibilityMsg(el);
    window.editor.setActiveElement(el, true);
  };

  var elementVisibilityWatcher = function() {
    var visibilityEvents =  elementSets
      .filter(function(e){
        return e.type === 'toggleVisibility';
      })
      .map(function(e) {
        return window.editor.page.getElementById(e.elementId);
      });

    visibilityEvents.subscribe(handleVisibilityToggle);
  };

  elementVisibilityWatcher();

})();
