lp.module.image.ImageElement = Class.create(
  lp.pom.VisibleElement,
  lp.ModuleComponent,
  {
    type:'lp-pom-image',

    initView: function(){
      /* jshint maxstatements:46 */
      var src            = this.model.get('content.asset.content_url')
        , innerContainer = this.insert(new jui.Component({attributes:{className:'lp-pom-image-container'}}))
        , imageContainer = innerContainer;

      var applyTo = {
        juiComponent: innerContainer,
        elm: innerContainer.e,
        selector: this.getCSSSelector() + ' .' + innerContainer.e.className
      };

      this.imgSrcData = {
        desktop : '',
        mobile  : ''
      };

      innerContainer.e.style.overflow = 'hidden';

      this.applyBorderTo = applyTo;
      this.applyDimensionsTo = applyTo;

      if (!this.model.exists('geometry.transform')) {
        var size = this.model.get('geometry.size');
        this.model.set('geometry.transform', {
          offset: {left:0, top:0},
          size: {width: size.width, height: size.height}
        });
        this.model.set('content.asset.size', {width: size.width, height: size.height});
        this.model.set('geometry.maintainAR', true);
        this.model.set('geometry.aspectRatio', size.width / size.height);
      }


      if (this.page.context !== lp.pom.context.EDIT) {
        var breakpoints = this.page.getBreakPoints();

        _.forEach(breakpoints, function(breakpoint) {
          if(this.model.exists('content.asset.unique_url')) {
            src = this.model.get('content.asset.unique_url');
          } else {
            // guard against old template missing unique_url
            src = this.model.get('content.asset.content_url');
          }

          this.page.currentBreakpoint = breakpoint;

          var originalSize = this.model.get('content.asset.size');
          var imageSize = this.model.get('geometry.transform.size');
          var elementSize = this.model.getSize();
          var imageOffset = this.adjustOffsetForInnerBorder(this.model.get('geometry.transform.offset'));

          /* determine if the image has been scaled and or cropped */
          if (this.hasBeenTransformed(originalSize, imageSize, elementSize)) {
            /* the image has been scaled and or cropped so include the information on the url */
            var arr = src.split('/');
            var filename = arr.pop().split('.');
            var ext = filename.pop();

            var transforms = [
              imageSize.width,
              imageSize.height,
              elementSize.width,
              elementSize.height,
              Math.abs(imageOffset.left),
              Math.abs(imageOffset.top)
            ];

            var transString = transforms.inject('', function(str, t) {
              return str + t.toPaddedString(3,36);
            });

            src = arr.concat(transforms).join('/') +
              '/' +
                filename.join('.') +
                  '_' +
                    transString +
                      '.' +
                        ext;

          }

          var imgSrc = '/publish' + src;

          this.applyImageStyle(imgSrc);
        }, this);

        var img = this.getImageSrc(src);

        this.img = new Element('img',{src: img});

        if (this.model.exists('content.alt')) {
          this.img.writeAttribute('alt', this.model.get('content.alt'));
        } else {
          //Alt attributes are required in images so default to a blank alt
          //attribute. alt=""
          this.img.writeAttribute( 'alt', '' );
        }

        if (this.model.exists('content.title')) {
          this.img.writeAttribute('title', this.model.get('content.title'));
        }

        if (this.model.exists('content.link')) {
          imageContainer = new Element('a', {href:this.page.CTAifyLink(this.model.get('content.link'))});

          var whenEmpty = (this.page.usedAs() === 'form_confirmation') ? '_parent' : '';

          var target = this.model.exists('content.target') ?
            (this.model.get('content.target') === '' ?
             whenEmpty :
               this.model.get('content.target')) :
                 whenEmpty;

          imageContainer.writeAttribute('target', target);
          innerContainer.insert(imageContainer.insert(this.img));
        }
      } else {
        this.img = new Element('img',{src:src});

        if (!(this.model.get('geometry.transform.size.width') === 0 && this.model.get('geometry.transform.size.height') === 0)) {
          this.updateImagePosition();
          this.updateImageSize();
        }
      }

      imageContainer.insert(this.img);
      if(this.shouldGenerateDynamicImageInsertion()) {
        this.writeImageHolderDivs();
      }
    },

    //If the page is responsive and the page has already been published we will generate
    //img src based off of the current breakpoint.  This is done by creating some hidden
    //divs that have the current breakpoint img src as their background.
    getImageSrc: function(src) {
      return this.shouldGenerateDynamicImageInsertion() ?
        this.getPlaceholderTransparentImg() : '/publish' + src;
    },

    shouldGenerateDynamicImageInsertion: function() {
        //If we force it to load the actual image than shortcircut.
        if(this.isForcedStaticImage()){return false;}

        return (window.lp.isResponsiveEnabled() &&
          this.model.hasBreakpointSizeOrTransformationDifferences());
    },

    //This is so when viewing the published endpoint we can tell the images to render out
    //static version of the image vs. the javascript sourced image.  This is for debugging
    //and to use this simply add lp_force_static = true into your localStorage.
    isForcedStaticImage: function() {
      return window.localStorage && window.localStorage.lp_force_static === 'true';
    },

    getPlaceholderTransparentImg: function() {
      return  '//d2xxq4ijfwetlm.cloudfront.net/m/lp-webapp/transparent.gif';
    },

    //Add div's to the page and hide them.  These div's will be used by main.js to get
    //the appropriate image for that breakpoint./s
    writeImageHolderDivs: function() {
      this.page
      .addInsertion('<div id="'+this.id+'-holder" class="lp-pom-image-holder"></div>', 'body:after');
    },

    updateImagePosition: function() {
      var offset = this.adjustOffsetForInnerBorder(this.model.get('geometry.transform.offset'));
      if(this.page.isEditMode()) {
        this.img.style.marginLeft = offset.left + 'px';
        this.img.style.marginTop = offset.top + 'px';
      }

    },

    applyImageStyle: function(imgSrc) {
      var rules = [];

      rules.push({
        selector  : this.getCSSSelector() + '-holder',
        attribute : 'background-image',
        value     : "url('"+ imgSrc +"')"
      });
      rules.push({
        selector  : this.getCSSSelector() + '-holder',
        attribute : 'position',
        value     : "absolute"
      });
      rules.push({
        selector  : this.getCSSSelector() + '-holder',
        attribute : 'left',
        value     : "-1000000"
      });
      rules.push({
        selector  : this.getCSSSelector() + '-holder',
        attribute : 'visibility',
        value     : "none"
      });

      this.applyPageStyles(rules);
    },

    getModelClass: function() {
      return lp.module.image.ImageModel;
    },

    setDimensions: function($super,dims) {
      $super(dims);
      if(this.page.context !== lp.pom.context.EDIT) {
        var options = {};
        if (this.applyDimensionsTo && this.applyDimensionsTo.selector) {
          // BUG options is not defined
          options.selector = this.applyDimensionsTo.selector + ' img';
        }
        this.applyPageStyles([
          {attribute:'width', value:dims.width+'px'},
          {attribute:'height', value:dims.height+'px'}
        ], options);
      }
    },

    hasBeenTransformed: function(originalSize, imageSize, elementSize) {
      return !this.hasANullSize(originalSize, imageSize, elementSize) &&
        this.hasDifferentSizeFromOriginal(originalSize, imageSize, elementSize);
    },

    hasANullSize: function(originalSize, imageSize, elementSize) {
      /* jshint unused:vars */
      return $A(arguments).any(function(arg){
        return !arg.width || !arg.height;
      });
    },

    hasDifferentSizeFromOriginal: function(originalSize, imageSize, elementSize) {
      return ['width', 'height'].any(function(size){
        var ogSize = originalSize[size];
        return  ogSize !== imageSize[size] || ogSize !== elementSize[size];
      });
    },

    createImageObserver: function(center) {
      center = center || false;
      var self = this;
      this.imageObserver = function() {
        self.updateImageDimensions(center);
      };

      return this.imageObserver;
    },

    replaceImage: function(asset) {
      this.page.undoManager.registerUndo({
        action: this.replaceImage,
        receiver: this,
        params: [Object.clone(this.model.get('content.asset'))]
      });
      this._replaceImage(asset);
    },

      //}
    _replaceImage: function(asset) {
      window.editor.wait();
      window.editor.imageTransformBox.hide();
      this.img.observe('load', this.createImageObserver());
      this.model.set('content.asset', {
        company_id: asset.company_id,
        uuid: asset.uuid,
        unique_url: asset.unique_url,
        content_url: asset.content_url,
        content_content_type: asset.content_content_type,
        name: asset.name
      });

      this.model.set('name', asset.name);
    },

    dblclick: function(e){
      Event.stop(e);
      var self = this;
      this.getModule().openElementBuilder(this, {
        callback:function(asset){
          if (asset && self.isAssetReplaceable(asset.uuid, asset.name)) {
            self.replaceImage(asset);
          }
        },
        selectedAsset: self.model.get('content.asset')
      });
      this.fireEvent('dblclick', e);
    },

    isAssetReplaceable: function(uuid, name) {
      return !this.isUUIDTheSame(uuid) || !this.isNameTheSame(name);
    },

    isUUIDTheSame: function(uuid) {
      return uuid === this.model.get('content.asset.uuid');
    },

    isNameTheSame: function(name) {
      return name === this.model.get('content.asset.name');
    },

    isWidthResizeable: function() {
      return true;
    },

    isHeightResizeable: function() {
      return true;
    },

    isResizeable: function() {
      return true;
    },

    updateImageDimensions: function(center) {
      center = center || false;
      var m = this.model;
      var self = this;
      this.img.stopObserving('load', this.imageObserver);
      var currentSize = m.get('geometry.size');
      this.resetImageSize();
      var assetSize = {width:this.img.width, height:this.img.height};
      var imageSize = {width:this.img.width, height:this.img.height};
      var imageOffset = {left:0, top:0};
      var ar = assetSize.width/imageSize.height;
      var elementSize, scaleFactor;
      var setModelAttributes = function(ar, assetSize, imageSize, imageOffset, elementSize) {
        m.set('geometry.aspectRatio', ar);
        m.set('content.asset.size', assetSize);
        m.set('geometry.transform.size', imageSize);
        m.set('geometry.transform.offset', imageOffset);
        m.set('geometry.size', elementSize);
        m.set('geometry.maintainAR', true);

        if (center) {
          // TODO-TR: this code was moved from elsewhere ... it might
          // be ok to just use elementSize and imageOffset here
          // instead of querying the model.
          var size = self.model.getSize();
          var offset = self.model.getOffset();
          self.model.setOffset({
            left: offset.left - Math.round(size.width / 2),
            top: offset.top - Math.round(size.height / 2)
          });
        }

        if(!self.page.getCurrentBreakpoint().default) {
          //If image is being created in the non-default breakpoint
          //we need to make sure that dimensions are transfered to
          //that breakpoint.
          self.model.syncDimensions(imageSize);
        }
        self.fireEvent('imageChanged');
        self.show();
      };

      window.editor.stopWaiting();

      if (!(currentSize.width === 0 && currentSize.height === 0) && (assetSize.width !== currentSize.width || assetSize.height !== currentSize.height)) {
        /* scale and crop to fit */

        (new jui.MultiOptionDialog({classNames:['image-too-big'], clickOutsideWillClose: false})).open({
          message: new Element('p').update('The image you have choosen is not the same size as the image being replaced. Would you like to scale the new image to fit the size of the replaced image?'),
          options:[
            {
              label: 'Don\'t Scale',
              handler: function() {
                elementSize = {width: imageSize.width, height: imageSize.height};
                setModelAttributes(ar, assetSize, imageSize, imageOffset, elementSize);
              }
            },
            {
              label: 'Scale Image to Fit',
              handler: function() {
                var currentAR = currentSize.width / currentSize.height;
                if (currentAR > ar) {
                  scaleFactor = currentSize.width / imageSize.width;
                  imageSize.width = Math.round(imageSize.width * scaleFactor);
                  imageSize.height = Math.round(imageSize.height * scaleFactor);
                  imageOffset.top = Math.round((currentSize.height - imageSize.height) / 2);
                } else {
                  scaleFactor = currentSize.height / imageSize.height;
                  imageSize.width = Math.round(imageSize.width * scaleFactor);
                  imageSize.height = Math.round(imageSize.height * scaleFactor);
                  imageOffset.left = Math.round((currentSize.width - imageSize.width) / 2);
                }
                elementSize = {width: currentSize.width, height: currentSize.height};
                setModelAttributes(ar, assetSize, imageSize, imageOffset, elementSize);
              }
            }
          ],
          onClose: function() {
            window.editor.imageTransformBox.show();
            window.editor.imageTransformBox.updatePositioning();
            // self.page.insertElement(self, {container: self.page.getElementById(self.containerId)});
          }
        });
      } else if (imageSize.width > this.page.getContentWidth()) {
        (new jui.MultiOptionDialog({classNames:['image-too-big'], clickOutsideWillClose: false})).open({
          message: new Element('p').update('The image you have chosen is wider than the page. Would you like to scale it to fit within the page or keep it full size?'),
          options:[
            {
              label: 'Use Full Size',
              handler: function() {
                elementSize = {width: imageSize.width, height: imageSize.height};
                setModelAttributes(ar, assetSize, imageSize, imageOffset, elementSize);
              }
            },
            {
              label: 'Scale Image to Fit Page',
              handler: function() {
                scaleFactor = self.page.getContentWidth() / imageSize.width;
                imageSize.width = Math.round(imageSize.width * scaleFactor);
                imageSize.height = Math.round(imageSize.height * scaleFactor);
                elementSize = {width: imageSize.width, height: imageSize.height};
                setModelAttributes(ar, assetSize, imageSize, imageOffset, elementSize);
              }
            }
          ],
          onClose: function() {
            self.page.insertElement(self, {container: self.page.getElementById(self.containerId)});
          }
        });
      } else {
        elementSize = {width: imageSize.width, height: imageSize.height};
        setModelAttributes(ar, assetSize, imageSize, imageOffset, elementSize);
        if (currentSize.width === 0 && currentSize.height === 0) {
          this.page.insertElement(this, {container: this.page.getElementById(this.containerId)});
        }
        window.editor.imageTransformBox.show();
        window.editor.imageTransformBox.updatePositioning();
      }
    },

    resetImageSize: function() {
      this.img.style.width = '';
      this.img.style.height = '';
    },

    adjustOffsetForInnerBorder: function(offset) {
      var m = this.model;
      var borderWidth = m.exists('style.border.width') ? m.get('style.border.width') : 0;
      var left = offset.left;
      var top = offset.top;

      if (m.exists('geometry.borderLocation') &&
         m.get('geometry.borderLocation') === 'inside' &&
         borderWidth > 0) {
        if (m.exists('geometry.borderApply')) {
          var borderApply = m.get('geometry.borderApply');
          left -= (borderApply.left ? borderWidth : 0);
          top -= (borderApply.top ? borderWidth : 0);
        }
        else {
          left -= borderWidth;
          top -= borderWidth;
        }
      }

      return {left:left, top:top};
    },

    updateImageSize: function() {
      var size = this.model.get('geometry.transform.size');
      //Prevent image from being set to anything less than 1px.
      if(size.width > 0 && size.height > 0) {
        if(this.page.context === lp.pom.context.EDIT) {
          this.img.style.width = size.width + 'px';
          this.img.style.height = size.height + 'px';
        }
      }
    },

    updateElementGeometry: function($super) {
      $super();
      this.updateImagePosition();
      this.updateImageSize();
    },

    removeTransformations: function() {
      var m = this.model;
      var size = m.get('content.asset.size');
      m.setSize({width:size.width, height:size.height});
      m.set('geometry.aspectRatio', size.width / size.height);
      m.set('geometry.transform.size', {width:size.width, height:size.height});
      m.set('geometry.transform.offset', {left:0, top:0});
    },

    modelChanged: function($super, e) {
      var details = $super(e);

      if (details.accessor === 'content.asset') {
        this.hide();
        this.img.src = this.page.decorateImageSrc(details.value.content_url);
      }
      else if (details.accessor === 'content.link') {
        this.fireEvent('goalChanged', this);
      }
      else if (details.accessor === 'geometry.size') {

      }
      else if (details.accessor === 'geometry.maintainAR') {
        if (details.value) {
          var m = this.model;
          m.set('geometry.aspectRatio', m.getWidth() / m.getHeight());
        }
      }
      else if (details.accessor.startsWith('geometry.transform.offset')) {
        this.updateImagePosition();
      }
      else if (details.accessor.startsWith('geometry.transform.size')) {
        this.updateImageSize();
      }
    },

    applyBorder: function($super, value) {
      $super(value);
      if (this.page.context === lp.pom.context.EDIT) {
        this.updateImagePosition();
      }
    },

    canHaveGoals: function() {
      return true;
    },

    getGoals: function() {
      if (this.model.exists('content.link') &&
          !this.model.get('content.link').startsWith('#') &&
          !this.model.get('content.link').startsWith('mailto:')) {

        return [{type:'link', url:this.model.get('content.link') }];
      }
      return [];
    }
  }
);

lp.module.image.ImageElement.elementDefaults = {
  name: 'Image',
  geometry:{
    position:"absolute",
    offset:{top:0,left:0},
    size: {width:0, height:0},
    borderLocation:'outside',
    maintainAR:true,
    transform:{
      offset:{left:0, top:0},
      size:{width:0, height:0}
    }
  }
};
