lp.module.root.RootModel = Class.create(lp.pom.VisibleElementModel, {
  initialize: function($super, element, modelData) {
    modelData = modelData || {};
    $super(element, modelData);
      this.addBreakpointModifyableAttributes({
        confirmationViewed: true,
        geometry:{
          backgroundImageApply: true
        },
        style: {
          background: {
            image: true,
            backgroundColor: true,
            backgroundPosition: true,
            backgroundRepeat: true,
            savedBackgroundColor: true,
            gradient: {
              from: true,
              to: true
            },
            fillType: true,
            autoGradient: true,
            reverseGradient: true,
            opacity: true
          }
        }
      });

      this.addBreakpointAllowDuplicateAttributes({
        confirmationViewed: true,
        geometry:{
          backgroundImageApply: true
        },
        style: {
          background: {
            savedBackgroundColor: true,
            image: true
          }
        }
      });

      this._setDefaults();
  },

  _setDefaults: function() {
    if(!this.exists('confirmationViewed')) {
      this.setOnWritableModelByBreakpoint('confirmationViewed', false, 'desktop');
      this.setOnWritableModelByBreakpoint('confirmationViewed', false, 'mobile');
    }
  },

  shouldApplyBackgroundImage: function() {
    if (this.exists('geometry.backgroundImageApply')) {
      return this.get('geometry.backgroundImageApply');
    } else {
      return true; // backwards compat
    }
  },

  // TODO-TR: LP-6616 move this up to element_model
  addBreakpointModifyableAttributes: function(object) {
    _.merge(this.breakpointModifyableAttributes, object);
  },

  addBreakpointAllowDuplicateAttributes: function(object) {
    _.merge(this.breakpointAllowDuplicateAttributes, object);
  }

});
