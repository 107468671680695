lp.module.root.RootElement = Class.create(
  lp.pom.VisibleElement,
  lp.ModuleComponent,
  {
    type:'lp-pom-root',

    initialize: function($super, page, jso, context, options){
      // JS: TODO: this should be handled by a future pom updater
      if (Object.isUndefined(jso.geometry.contentWidth)) {
        jso.geometry.contentWidth = page.getDefaultWidth();
      }

      var self = this;
      this.blockListener = function(e){
        var accessor = e.data.accessor;

        if(accessor === 'geometry.size.width') {return;}

        if (accessor.startsWith('geometry.size') ||
            accessor.startsWith('geometry.margin') ||
            accessor.startsWith('style.border') ||
            accessor.startsWith('geometry.borderLocation') ||
            accessor.startsWith('geometry.borderApply')) {
          self.fireEvent('blockResized');
          self.fireEvent('layoutChanged');
        }
      };

      this.fireBlockHidden = function() {
        self.fireEvent('blockHidden');
        self.fireEvent('layoutChanged');
      };

      this.fireBlockShown = function() {
        self.fireEvent('blockShown');
        self.fireEvent('layoutChanged');
      };

      $super(page, jso, context, options);
      this.applyDefaultStyles();
    },

    getModelClass: function() {
      return lp.module.root.RootModel;
    },

    createDefaultConstraints: function($super) {
      $super();
      this.defaultConstraints.displayable = false;
      this.defaultConstraints.renameable = false;
      this.defaultConstraints.offsetable = false;
      this.defaultConstraints.orderable = false;
      this.defaultConstraints.indexable = false;
      this.defaultConstraints.height_resizeable = false;
      this.defaultConstraints.removable = false;
      this.defaultConstraints.opacityable = true;
    },

    initView: function() {
      if (this.page.context === lp.pom.context.EDIT && this.page.usedAs() !== 'form_confirmation') {
        this.view.e.style.paddingBottom = "50px";
      }
      this.positionedContent = this.insert(new jui.Component({attributes:{className:'lp-positioned-content'}}));

    },

    modelChanged: function($super, e) {
      var details = $super(e);
      var updateStyles = false;
      if (details.accessor === 'geometry.contentWidth') {
        this.fireEvent('contentWidthChanged', details.value);
        this.fireEvent('layoutChanged');
      } else if (details.accessor.startsWith('geometry.padding')) {
        this.fireEvent('layoutChanged', details.value);
      }
      else if (details.accessor === 'style.defaults.color') {
        this.applyDefaultTextColor();
        updateStyles = true;
      }
      else if (details.accessor === 'style.defaults.linkColor') {
        this.applyDefaultLinkColor();
        updateStyles = true;
      }
      if (updateStyles) {
        this.page.style.updatePageStyles();
      }
    },

    applyDefaultTextColor: function(){
      var bodyColor = this.model.get('style.defaults.color');
      if(bodyColor.strip() !== '') {
        this.page.style.setCSSRule({selector:'body', attribute:'color', value:'#'+bodyColor});
      }
    },

    applyDefaultLinkColor: function(){
      var linkColor = this.model.get('style.defaults.linkColor');
      if(linkColor.strip() !== '') {
        this.page.style.setCSSRule({selector:'a', attribute:'color', value:'#'+linkColor});
      }
    },

    applyDefaultTopPadding: function() {
      if(!this.model.exists('geometry.padding.top')) {
        this.model.set('geometry.padding.top', 0);
      }
    },

    applyDefaultStyles: function() {
      this.applyDefaultTextColor();
      this.applyDefaultLinkColor();
      this.applyDefaultTopPadding();
      this.page.style.setCSSRule({selector:'a', attribute:'text-decoration', value:this.model.get('style.defaults.linkDecoration')});
      if (this.page.isInserted()) {
        this.page.style.updatePageStyles();
      }
    },

    applyGeometry: function($super) {
      /* jshint unused:vars */
      var s = this.view.e.style;
      var pcs = this.positionedContent.e.style;
      var m = this.model;
      var cw = m.get('geometry.contentWidth');
      var breakpoint = this.page.getCurrentBreakpoint();
      var rules = [];
      var padding = this.getTopPadding() + 'px';

      if (this.page.context === lp.pom.context.EDIT){
        pcs.top = padding;

        pcs.width = cw + 'px';
        pcs.marginLeft = -Math.round(cw / 2) + 'px';
        s.minWidth = pcs.width;
        s.maxWidth = typeof breakpoint.width === 'number' ? breakpoint.width + 'px' : '';
      } else {
        rules.push({attribute:'top', value: padding, selector:'#lp-pom-root .lp-positioned-content'});
        rules.push({attribute:'width', value: cw + 'px', selector:'#lp-pom-root .lp-positioned-content'});
        rules.push({attribute:'margin-left', value: -Math.round(cw / 2) + 'px', selector:'#lp-pom-root .lp-positioned-content'});
        rules.push({attribute:'min-width', value: cw + 'px'});

        if (this.page.context === lp.pom.context.EDIT) {
          this.applyStylesToDom(rules);
        } else {
          this.applyPageStyles(rules);
        }
      }
    },

    insertChildElement: function($super, elm, options) {
      options = options || {};
      if (!Object.isUndefined(options.containerIndex) && options.containerIndex < this.childElements.length) {
        elm.fireEvent('moved');
      }

      $super(elm, options);
      elm.addListener('hidden', this.fireBlockHidden);
      elm.addListener('shown', this.fireBlockShown);
      this.fireEvent('blockInserted', elm);
      this.fireEvent('layoutChanged');
    },

    isInserted: function() {
      return this.view.e.parentNode !== null;
    },

    removeChildElement: function($super, elm) {
      $super(elm);
      elm.removeListener('hidden', this.fireBlockHidden);
      elm.removeListener('shown', this.fireBlockShown);
      this.fireEvent('blockRemoved', elm);
      this.fireEvent('layoutChanged');
    },

    swapElementOrder: function($super, a, b) {
      $super(a,b);
      this.fireEvent('blockMoved');
      this.fireEvent('layoutChanged');
    },

    getContentWidth: function() {
      return this.model.get('geometry.contentWidth');
    },

    getPageWidth: function() {
      return this.getWidth();
    },

    getDefaultSectionSize: function() {
      return {width:this.model.get('geometry.contentWidth'), height:200};
    },

    getParentElement: function() {
      return this.parentElement || this.page;
    },

    getPageOffset: function() {
      return {left:0, top:0};
    },

    getMinZIndex: function() {
      return 1;
    },

    getMaxZIndex: function() {
      return this.getMinZIndex() + this.getDescendants(true).length - 1;
    },

    getIndexables: function() {
      return this.getDescendants(true, 2);
    },

    getDescendantsSortedByZIndex: function() {
      return this.getDescendants(true).sortBy(function(e) {
        return e.model.getZIndex();
      });
    },

    compactZIndexes: function() {
      var min = this.getMinZIndex();
      this.getDescendantsSortedByZIndex().each(function(e, i) {
        if (e.model.getZIndex() !== i+min) {
          e.model.setZIndex(i+min);
        }
      });
    },

    insertZIndex: function(minZ, gap) {
      gap = gap || 1;
      this.childElements.each(function(e) {
        e.childElements.each(function(e) {
          if (e.is('z_indexable')) {
            var current = e.model.getZIndex();
            if (current >= minZ) {
              e.model.setZIndex(current + gap);
            }
          }
        });
      });
    },

    hasSections: function() {
      return this.getSections().length > 0;
    },

    getTopPadding: function() {
      return this.model.exists('geometry.padding.top') ? this.model.get('geometry.padding.top') : 0;
    },

    getSections: function() {
      return this.childElements.select( function(c) { return c.type === 'lp-pom-block'; });
    },

    destroy: function($super) {
      $super();
      this.page.style.removeCSSRules('#'+this.id);
    }
  }
);

lp.module.root.RootElement.elementDefaults = {
  name:'New Page Root',
  style:{
    background: {
      backgroundColor: "eee",
      opacity: 100
    },
    defaults:{
      color: "000",
      linkColor: "0000ff",
      linkDecoration: "none"
    }
  },
  geometry:{
    position:"relative",
    backgroundImageApply: false,
    margin:"auto"
  }
};
