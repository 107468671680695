lp.pom.updates.add(Object.extend({

  minVersion: '2.2',
  version: '2.3',
  buttonsStates: ['up', 'hover', 'active'],
  buttonStyleDefaults: {
      fontSize:'16',
      fontWeight:'bold',
      fontFamily:'arial',
      textShadow:true,
      highlight:true,
      fillType: 'gradient',
      autoGradient: true,
      up: {
        backgroundColor:'f7941d',
        gradient:{
          type:'custom-gradient',
          from:'f7941d',
          to:'d75305'
        },
        color:'fff',
        border:{
          style:'solid',
          color:'333333',
          width:1
        }
      },
      hover: {
        auto:true
      },
      active: {
        auto:true
      }
    },

  run: function(jso) {
    if(this.checkVersion(jso)) {
      this.updateForms(jso);
      this.updateButtons(jso);
      jso.version = this.version;
    }
  },

  findForm: function(jso) {
    return jso.elements.find(function(el){ return el.type === 'lp-pom-form';});
  },

  findButtons: function(jso) {
    return jso.elements.findAll(function(el){ return el.type === 'lp-pom-button';});
  },


  updateForms: function(jso) {
    var form = this.findForm(jso);

    if (form) {
      this.fixLeftLabelAlignedForm(form);
      this.addCalculatedWidthAttributeToFormLabel(form);
      this.fixLabelColorValues(form);
    }
  },

  /* JS: see lp-1644 and lp-2691 */
  fixLeftLabelAlignedForm: function(form) {
    if (form.geometry.label.alignment === "left" &&
        form.geometry.label.minWidth === form.geometry.size.width){
      form.geometry.size.width = (form.geometry.label.minWidth * 2) +
        form.geometry.label.margin.right;
      form.geometry.label.width = form.geometry.label.minWidth;
    }
  },

  addCalculatedWidthAttributeToFormLabel: function(form) {
    if (!form.geometry.label.calculatedWidth && form.geometry.label.minWidth !== 'undefined') {
      form.geometry.label.calculatedWidth = form.geometry.label.minWidth;
    }
  },

  fixLabelColorValues: function(form) {
    if (form.style.label.color && /^\#/.test(form.style.label.color)) {
      form.style.label.color = form.style.label.color.replace(/^\#/, '');
    }
  },

  updateButtons: function(jso) {
    this.findButtons(jso).each( function(button) {

      this.addButtonFillTypeAttribute(button);
      this.addButtonTextShadowAttribute(button);

      this.buttonsStates.each(function(state){
        this.repairInvalidColorValues(button, state);
        this.addButtonState(button, state);

        this.initSolidElementDefaultsByState(button, state);
        this.initImageElementDefaultsByState(button, state);
        this.initGradientElementDefaults(button, state);

      }, this);
    }, this);
  },

  repairInvalidColorValues: function(button, state) {
    if (typeof button.style[state] === 'undefined') {
      return;
    }

    var bgColor = button.style[state].backgroundColor;

    if (typeof bgColor === 'undefined' || bgColor === 'transparent') {
      return;
    }

    if (bgColor.length < 3 || (bgColor.length > 3 && bgColor.length < 6)) {
      button.style[state].backgroundColor = 'transparent';
    }
  },

  addButtonFillTypeAttribute: function(button) {
    if (typeof button.style.fillType === 'undefined') {
      if (button.style.up.image) {
        button.style.fillType = 'image';
      } else {
        button.style.fillType = 'solid';
      }
    }
  },

  addButtonTextShadowAttribute: function(button) {
    button.style.textShadow = !!button.style.textShadow;
  },

  addButtonState: function(button, state) {
    if (typeof button.style[state] === 'undefined') {
      var defaultStylesByState = this.buttonStyleDefaults[state];
      button.style[state] = defaultStylesByState;
    }
  },

  initSolidElementDefaultsByState: function(button, state) {
    if (state === 'up') {
      if (!button.style.up.backgroundColor) {
        if (button.style.up.image) {
          button.style.up.backgroundColor = 'transparent';
        } else {
          button.style.up.backgroundColor = this.buttonStyleDefaults.up.backgroundColor;
        }
      }
      if (!button.style.up.color) {
        button.style.up.color = this.buttonStyleDefaults.up.color;
      }
    }
  },

  initImageElementDefaultsByState: function(button, state) {
    if (button.style[state].image) {
      button.style[state].auto = false;
    }
  },

  initGradientElementDefaults: function(button, state) {
    if (typeof button.style.autoGradient === 'undefined') {
      button.style.autoGradient = true;
    }
    if (button.style[state] && typeof button.style[state].gradient === 'undefined') {
      if (button.style.up.backgroundColor && button.style.up.backgroundColor !== 'transparent') {
        button.style[state].gradient = jui.ColorMath.calculateGradient(button.style.up.backgroundColor);
      } else if (button.style.up.savedBackgroundColor) {
        button.style[state].gradient = jui.ColorMath.calculateGradient(button.style.up.savedBackgroundColor);
      } else {
        button.style[state].gradient = this.buttonStyleDefaults.up.gradient;
      }
    }
  }


}, lp.pom.updater));
