//  require <jui>
jui.FormSliderInput = Class.create( jui.Component, jui.ControlModel, {
  options: function($super,options){
    return $super($H({
      attributes:{className:'form-elm slider-input'},
      slider:{}
    }).merge(options));

  },

  initialize:function( $super, options ){
    this.suppressEvents = false;
    $super( options );

    if ( this.options.onchange ) { this.addListener( 'change', this.options.onchange );}
    if ( this.options.onfocus ) {this.addListener( 'focus', this.options.onfocus );}
    if ( this.options.startTracking ) {
      this.slider.addListener( 'startTracking', this.options.startTracking );
    }
    if ( this.options.stopTracking ) {
      this.slider.addListener( 'stopTracking', this.options.stopTracking );
    }
  },

  installUI: function( $super ) {
    $super();
    this.label = this.insert( new Element('label').update(this.options.label));
    this.slider = this.insert(new jui.Slider(this.options.slider));
    this.slider.addListener('valueChanged', this);
    this.size = this.insert(new Element('input', {type:'text', className:'text slider'}));

    this.size.observe('focus', this.onFocus.bind(this));
    this.size.observe('blur', this.onBlur.bind(this));
  },

  setValue: function(v) {
    // TODO: JS: validation
    this.size.value = v;
    this.suppressEvents = true;
    this.slider.setRealValue(v);
    this.suppressEvents = false;
  },

  getValue: function() {
    return this.slider.getRealValue();
  },

  valueChanged: function() {
    var v = this.slider.getRealValue();
    this.size.value = v;
    if (!this.suppressEvents) { this.fireEvent('change', v);}
  },

  show: function($super) {
    var r =  $super();
    this.updateKnob();
    return r;
  },

  updateKnob: function() {
    this.slider.updateKnob();
  },

  onFocus: function( e ) {
    e.stop();
    this.fireEvent('focus', this);
  },

  onkeydown: function(e) {
    if(Event.KEY_RETURN === e.keyCode) {
      this.blur();
      e.stop();
    }
  },

  onBlur: function() {
    this.slider.setRealValue(this.size.getValue());
  },

  blur: function() {
    this.size.blur();
  }
});
