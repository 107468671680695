lp.editor.TextEditor = Class.create( jui.Component, jui.DynamicTagSelectable, {

  options: function($super, options){
    return $super($H({attributes:{className:'text-editor'}}).merge(options));
  },

  initialize: function($super, pageEditor, options) {
    this.pageEditor  = pageEditor;
    this.isOpen      = false;
    this.autoUpdater = null;
    this.dataReady   = false;
    this.dataPending = false;
    this.observer    = null;

    this.observerConfig = {
      attributes    : true,
      childList     : true,
      characterData : false,
      subtree       : true
    };

    this.popupMenuLeftOpen = null;

    $super(options);

    this.activeElement = null;
    this.editor        = null;
    this.editorToolbar = [
        ['Cut','Copy','Paste','PasteText','PasteFromWord'],
        ['Bold','Italic','Underline','Strike','-','Subscript','Superscript'],
        ['NumberedList','BulletedList','-','Outdent','Indent','Blockquote'],
        ['JustifyLeft','JustifyCenter','JustifyRight','JustifyBlock'],
        ['Link','Unlink','Anchor'],
        ['Table','HorizontalRule','SpecialChar'],
        '/',
        ['Format','Font','lpfontsize','lplineheight'],
        ['TextColor','BGColor'],
        ['Undo','Redo','-','SelectAll','RemoveFormat'],
        ['SpellChecker'],
        ['ShowBlocks','Source'],
        ['lpdynamictext']
    ];

    CKEDITOR.config.customConfig = '/javascripts/ckeditor/ckeditor_config.js';

    var self    = this;
    this.editor = CKEDITOR.appendTo( this.e, {
      toolbar:this.editorToolbar,
      contentsCss: [this.loadWebFonts(), '/app_assets/page_defaults.css']
    });

    var MutationObserver = window.MutationObserver ||
      window.WebKitMutationObserver || window.MozMutationObserver;

    if (typeof MutationObserver !== 'undefined') {
      this.observer = new MutationObserver(function() {
        if (self.isMode('wysiwyg') && self.isOpen && self.dataReady) {
          self.updateElementText();
          self._cleanEmptyDynamicTags();
        }
      });
    }

    if(this.editor && this.editor.name) {

      if(lp.isDynamicTextEnabled()) {
        this._addDynamicTagEventsToHtmlEditor();
      }

      CKEDITOR.instances[this.editor.name].on('instanceReady', function(e) {
        e.editor.document.getBody().addClass('cke-text');
        window.editor.getDynamicTextDialog().addListener('onDynamicTextDialogWindowClose', self);

        if(lp.isDynamicTextEnabled()) {
          $$('#editor-text-editor .cke_toolbar').last().insert({
            bottom: new Element('a', {
              id: 'text-editor-dynamic-help',
              class: 'help-button',
              title: 'You can pass in dynamic text values through your page\'s ' +
                'URL. This is useful for increasing message match and ' +
                'quality score of your PPC campaigns.'
            })
          });
        }

      });

      this.editor.on('mode', function() {
        if (self.isMode('wysiwyg') && self.isOpen) {
          self.registerObserver();
          self.updateElementText();
          self.adjustBackgroundColor();
          self.adjustTextColor();
          self.addRemoveDynamicTagButton();
        }
        else {
          self.stopUpdater();
        }
      });
    }

    this.pageEditor.addListener('elementActivated', function() {
      if (!self.isOpen || !self.dataReady){ return;}
      var elm = self.pageEditor.activeElement;
      if (elm.type === 'lp-pom-text') {
        if (elm !== self.activeElement) {
          self.updateElementText();
          self.setElement(elm);
          self.focus();
        }
      }
      else {
        self.close();
      }
    });

    this.pageEditor.addListener('elementRemoved', function(e) {
      if (!self.isOpen){ return;}
      if (e.data.element === self.activeElement) {
        self.close();
      }
    });

  },

  canUpdate: function(eventName) {
    var isKeyup = eventName === 'keyup';
    return (this.isMode('wysiwyg') && this.isOpen && this.dataReady && isKeyup);
  },

  isMode: function(mode) {
    return this.editor.mode === mode;
  },

  isInSelectDynamicTagMode: function(e) {
    return (this.isMode('wysiwyg') && (e.name === 'mouseup'));
  },

  onDynamicTextDialogWindowClose: function() {
    this.editor.focus();
  },

  showDynamicTextDialog: function() {
    this.completeSelection(true);
    var selection      = this.getEditorInstance().getSelection(),
        startElement   = selection.getStartElement().$,
        dynamicElement = this.findDynamicElement(startElement),
        properties     = {text: selection.getSelectedText(), attributes: {}};

    if(this.isDynamicElement(startElement)) {
      properties = this._getTextAndAttributesByElement(startElement);
    } else if(jQuery(startElement).prop('tagName') === 'A' && dynamicElement.length > 0) {
      properties = this._getTextAndAttributesByElement(dynamicElement);
    } else if(jQuery(startElement).prop('tagName') === 'A') {
      properties.text = jQuery(startElement).text();
    }

    window.editor.showDynamicTextDialog(
      properties.text,
      properties.attributes,
      this.insertDynamicElement.bind(this)
    );
  },

  isEventInDynamicTag: function(e) {
    if(!this.isMode('wysiwyg')){return;}

    if(e) {
      return jQuery(e.data.$.target).prop('tagName') === 'UB:DYNAMIC';
    } else {
      return this.isSelectionInDynamicTag();
    }
  },

  insertDynamicElement: function(defaultText, attributes) {
    var self = this;
    this.editor.fire('saveSnapshot');
    attributes.wrap = true;
    this.insertDynamicElementIntoSelection(defaultText, attributes, function() {
      self._sanitizeCustomTags();
    });
    this.editor.focus();
  },

  replaceDynamicContents: function(element, range, text) {
    var contents = this._replaceTextNode(range.cloneContents(), text);
    range.deleteContents();
    element.html(contents);
    return element;
  },

  addRemoveDynamicTagButton: function() {
    this._addRemoveButtonToDynamicTags();
  },


  getSelection: function() {
    if(this.getEditorInstance() && this.editor.mode !== 'wysiwyg') {return;}
    return this.getEditorInstance().getSelection().getNative();
  },

  getRange: function() {
    if (this.getSelection().rangeCount > 0){
      return this.getSelection().getRangeAt(0);
    }
  },

  getEditorInstance: function() {
    return CKEDITOR.instances[this.editor.name];
  },

  getStartElement: function() {
    return this.getEditorInstance().getSelection().getStartElement().$;
  },

  loadWebFonts: function() {
    var webFonts = lp.getWebFonts();
    webFonts.getSortedFonts().each(function(font){
      this.addFontToEditor(font);
    }, this);
    return webFonts.fontsURL();
  },

  addFontToEditor: function(font) {
    if(CKEDITOR.config.font_names) {
      CKEDITOR.config.font_names = CKEDITOR.config.font_names.concat(';' + font);
    }
  },

  installUI: function($super){
    $super();

    this.addCloseButtonToEditor();
    this.addBackgroundColorControlsToEditor();
  },

  addCloseButtonToEditor: function() {
    this.insert(new jui.Button({
      label:'Close',
      attributes:{
        id:'text-editor-close-button',
        className:'button'
      },
      action:this.close.bind(this)
    }));
  },

  addBackgroundColorControlsToEditor: function() {
    var bgc = this.insert( new Element('div', {id:'text-editor-bg-color-controls'})
      .update('<span class="label">Editor Color</div>'));

    this.bgColorControls = {
      white    : new Element('div', {id : 'text-editor-bg-color-white'}),
      black    : new Element('div', {id : 'text-editor-bg-color-black'}),
      custom   : new Element('div', {id : 'text-editor-bg-color-custom'}),
      selected : null
    };

    var self = this;
    var setter = function(e) {
      var elm  = Event.element(e);
      self.setBackgroundColor(elm.style.backgroundColor, elm);
    };

    this.insert( new Element('div', {className:'warning'})
      .update('<span class="label">We\'ve detected some errors with your text. </span>')).hide();

    bgc.insert(this.bgColorControls.white.observe('click', setter));
    bgc.insert(this.bgColorControls.black.observe('click', setter));
    bgc.insert(this.bgColorControls.custom.observe('click', setter));

    this.bgColorControls.white.style.backgroundColor = '#fff';
    this.bgColorControls.black.style.backgroundColor = '#000';
  },

  open: function(textElement) {
    if (!this.isOpen) {

      this.setAsOpen();
      this.handleErrorMessageDisplay(textElement);
      this.setElement(textElement);

      this.ensurePopupsAreRemovedAfterClose();

      this.displayAndFocus();
      this.fireEvent('textEditorOpened');
    } else if (textElement !== this.activeElement){
      this.setElement(textElement);
    }

    this.handleMenus('block');
  },

  ensurePopupsAreRemovedAfterClose: function() {
    // JS: sometimes pop menus stay open after the text editor is closed. this fixes this problem
    // see close method also
    if (this.popupMenuLeftOpen !== null) {
      this.popupMenuLeftOpen.firstChild.style.display = 'block';
    }
  },

  setAsOpen: function() {
    this.isOpen = true;
    this.editor.setReadOnly(false);
  },

  displayAndFocus: function() {
    this.show();
    this.focus();
  },

  close: function() {
    this.closeActions();

    //This is to prevent from the text editor accidently keeping the focus on close.
    this.editor.setReadOnly(true);
    this.isOpen = false;

    this.fireEvent('textEditorClosed');
    this.giveFocusToTextPanel();
    this.handleMenus('none');
  },

  closeActions: function() {
    this._sanitizeCustomTags();
    this.handleUpdaters();
    this.handleGoalChangedEvent();
    this.forceBlur();
    this.hideAndRemove();

    window.editor.editingPanel.insert(this);
  },

  giveFocusToTextPanel: function() {
    if (this.pageEditor.infoPanel.selectedTabViewItem.id === 'properties') {
      this.pageEditor.infoPanel.selectedTabViewItem.view.controls.offset.left.focus();
      window.editor.keyController.requestFocus(this.pageEditor.transformBox, true);
    }
  },

  forceBlur: function() {
    if (this.editor.focusManager.hasFocus) {
      this.editor.focusManager.forceBlur();
    }
  },

  hideAndRemove: function() {
    this.hide();
    this.remove();
  },

  handleGoalChangedEvent: function() {
    this.activeElement.fireEvent('goalChanged', this.activeElement);
    this.activeElement = null;
  },

  handleUpdaters: function() {
    this.stopUpdater();
    this.updateElementText();
    this.updateFontList();
  },

  handleMenus: function(display) {
    var ckeElements = window.document.getElementsByClassName('cke_skin_kama');
    // JS: sometimes pop menus stay open after the text editor is closed. this fixes this
    // problem
    for (var i=0, l=ckeElements.length, element; i<l; i++) {
      element = ckeElements[i];
      if (element.getAttribute('role') === 'presentation' && element.firstChild !== null){
        this.popupMenuLeftOpen = element;
        element.firstChild.style.display = display;
      }
    }
  },

  registerObserver: function() {
    if (this.observer !== null) {
      this.observer.observe(this.editor.document.getBody().$, this.observerConfig);
    }
    var self = this;
    this.editor.document.on('keyup', function(){
      if (self.isMode('wysiwyg') && self.isOpen && self.dataReady) {
        self.updateElementText();
      }
    });
  },

  autoUpdate: function() {
    if (!this.isOpen) {this.stopUpdater();}
    this.updateElementText();
  },

  //Parse out all the fonts from the text block and create an array of those fonts.  Then
  //set the activeElement with that array of matched fonts.
  updateFontList: function() {
    if (this.activeElement) {

      var fontList    = [],
          matchedList = this.getFontsText(this.getText());

      $A(matchedList).each(function(font) {
        fontList.push(this.getFontFromResult(font));
      }, this);

      fontList = fontList.flatten().uniq();
      this.activeElement.setFonts(fontList);
    }
  },

  setContentEditableOnDynamicTags: function(isEditable) {
    if (!this.isMode('wysiwyg')) {return; }
    jQuery(this.editor.document.$).find('ub\\:dynamic')
      .attr('contenteditable', isEditable);
  },

  getFontsText: function(text) {
    text = text.replace(/&#27;/g,'\'').replace(/&quot;/g,'"');
    var regex       = /(font-family:[a-zA-Z0-9, \-\'\"]*;)/gi,
        matchedList = text.match(regex);

    return matchedList;
  },

  getFontFromResult: function(font) {
    return this.cleanFontString(font.split(':')[1]);
  },

  cleanFontString: function(fontString) {
   return fontString
    .strip()
    .replace(';', '') // drop the semi-colons
    .split(',')
    .collect(this.stripWhitespace);
  },

  stripWhitespace: function(string) {
    return string.replace(/^\s+|\s+$/g, '');   // trim extra whitespaces
  },

  updateElementText: function() {
    if(this.activeElement) {
      var content = this.getText().stripScripts();
      this.activeElement.setText(this._sanitizeFromContent(content));
    }
  },

  setElement: function(e) {
    this.activeElement           = e;
    this.editor.config.bodyClass = e.isBetterLineHeight() ? 'cke-text nlh' : 'cke-text';

    this.handleErrorMessageDisplay(e);

    var t = e.getText();
    e.page.undoManager.registerUndo({
      action   : e.setText,
      receiver : e,
      params   : [t, e.page.undoManager]
    });
    this.setText(t);
  },

  stopUpdater: function() {
    if (this.autoUpdater !== null) {
      this.autoUpdater.stop();
      this.autoUpdater = null;
    }
  },

  handleErrorMessageDisplay: function(ele) {
    var model = ele.model;
    if(model.exists('content.valid') && !model.get('content.valid')) {
      this.e.down('.warning').show();
    } else {
      this.e.down('.warning').hide();
    }
  },

  setText: function(text) {
    var self = this;

    this.dataReady = false;
    this.editor.setData(text, function(){
      self.setDataReady(true);
      if (self.editor.document !== null) {
        self.registerObserver();
      }
    });
  },

  setDataReady: function(ready) {
    this.dataReady = ready;
    if (ready) {
      this.adjustBackgroundColor();
      this.adjustTextColor();
    }
  },

  getText: function() {
    return this.editor.getData().gsub('\'', '&#x27;')
        .gsub('\u2028', ' ').gsub('\u2029', ' ');
  },

  adjustTextColor: function() {
    if (!this.isMode('wysiwyg')) {return; }
    var root      = window.editor.page.getRootElement(),
        textColor = '#'+root.model.get('style.defaults.color');

    this.e.down('iframe').contentDocument.body.style.color = textColor;
  },

  adjustBackgroundColor: function() {
    if (!this.isMode('wysiwyg')) {
      return;
    }

    var self = this;

    var findBGColor = function(elm) {
      if (elm.model.exists('style.background.backgroundColor') && self.isOpaqueBG(elm)) {
        return elm.getBGColorAsRGBA();
      } else if (elm.parentElement !== null) {
        return findBGColor(elm.parentElement);
      } else {
        return 'fff';
      }
    };

    var color = findBGColor(this.activeElement);

    this.setBackgroundColor(color, this.bgColorControls.custom);
    this.bgColorControls.custom.style.backgroundColor = color;
  },

  isOpaqueBG: function(elm) {
    return (!elm.model.exists('style.background.opacity') ||
      elm.model.get('style.background.opacity') > 0) &&
      (elm.model.exists('style.background.backgroundColor') &&
      elm.model.get('style.background.backgroundColor') !== 'transparent');
  },

  setBackgroundColor: function(color, control) {
    this.e.down('iframe').contentDocument.body.style.backgroundColor = color;
    if (this.bgColorControls.selected !== null && this.bgColorControls.selected !== control) {
      this.bgColorControls.selected.removeClassName('selected');
    }
    control.addClassName('selected');
    this.bgColorControls.selected = control;
  },

  notSnappedToContainer: function(e) {
    this[e.data.axis === 'x' ? 'xSnapGuide' : 'ySnapGuide'].hide();
  },

  focus: function() {
    this._sanitizeCustomTags();
    this.pageEditor.keyController.requestFocus(this);
  },

  blur: function() {
    this.updateFontList();
    this._sanitizeCustomTags();
  },

  getSelectedElement: function() {
    var selection    = this.getEditorInstance().getSelection(),
    startElement = selection.getStartElement().$;
    return startElement;
  },

  fireCKEditorEvent: function(eventName) {
    this.editor.fire(eventName);
  },

  //PRIVATE
  _replaceTextNode: function(elements, text) {
    var temp = jQuery('<span></span>').html(elements);
    jQuery(this._getDeepestChild(temp[0])).text(text);
    return this._hasChildWithSiblings(temp) ? text : temp.html();
  },

  _hasChildWithSiblings: function(element) {
    var children   = jQuery(element).children(),
        firstChild = children.get(0);
    return (children.length > 0 && this._hasSibilingNodes(firstChild)) ? true : false;
  },

  _hasSibilingNodes: function(node) {
    return (node.previousSibling && node.previousSibling.length > 0) ||
           (node.nextSibling     && node.nextSibling.length > 0);
  },

  _getTextAndAttributesByElement: function(element) {
    return {
      attributes: this.getAttributesFromElement(element),
      text      : this._getSelectedText(element)
    };
  },

   _findOrCreateCloseTag: function(tag) {
    var spanEl  = jQuery(tag).find('span.dynamic-tag-close'),
        self    = this,
        newSpan = jQuery('<span></span>').mouseup(function(e){
          var currentTag = jQuery(e.target).parents('ub\\:dynamic');
          self.removeClickedTag(currentTag, function(){
            self.editor.fire('saveSnapshot');
            self._removeDynamicTag(tag);
          });
        })
        .addClass('dynamic-tag-close')
        .html('<img src="/images/transparent.gif" width="21" height="12" />');

    spanEl.remove();
    return newSpan;
   },

  _currentSelectedTag: function() {
    var selection = this.getEditorInstance().getSelection(),
        tag       = selection.getStartElement().$;

    if(!this.isDynamicElement(tag)) {
      tag = jQuery(tag).parent('ub\\:dynamic');
    }

    return tag;
  },

  _getAllDynamicTags: function() {
    var htmlEditor = this.getEditorInstance(),
        dTags      = jQuery(htmlEditor.document.$).find('ub\\:dynamic');
    return dTags;
  },

  _cleanEmptyDynamicTags: function() {
    if(!this.isMode('wysiwyg')){return;}
    var dTags = this._getAllDynamicTags(),
        self  = this;

    dTags.each(function(i, tag){
      self._removeTagIfEmpty(tag);
    });
  },

  _removeTagIfEmpty: function(tag) {
    var isEmpty = jQuery(tag).text().trim().length === 0;
    if(isEmpty) {
      jQuery(tag).remove();
      this.editor.fire('updateSnapshot');
    }
  },

  _setCurrentDynamicTagEditability: function(editable) {
    if(!this.isMode('wysiwyg')){return;}
    var selection    = this.getEditorInstance().getSelection(),
        startElement = selection.getStartElement().$;

    if(editable) {
      jQuery(startElement).removeAttr('contenteditable');
    } else {
      this._setDynamicTagsAsUneditable();
    }
    this.editor.fire('updateSnapshot');
  },

  _setDynamicTagsAsUneditable: function() {
    var dTags = this._getAllDynamicTags(),
        self  = this;

    dTags.each(function(i, tag) {
      if(jQuery(tag).attr('contenteditable') !== 'false') {
        jQuery(tag).attr('contenteditable', 'false');
        self.editor.fire('updateSnapshot');
      }
    });
  },

  _handleDynamicTagStyling: function(tag) {
    if(this.isWithinADynamicElement(tag)) {
      this.fireCKEditorEvent('updateSnapshot');
      this._wrapParentWithChildren(tag);
      this._cleanEmptyDynamicTags();
    }
  },

  _wrapParentWithChildren: function(tag) {
    if(this.isMode('wysiwyg')) {
      var children = jQuery(tag).children(':not(.dynamic-tag-close)'),
          self     = this;

        jQuery(children).each(function(i, child) {
          if(!self.isDynamicElement(child)) {
            var doSelect = self._isTagSelected(tag);

            self._wrapWithContents(tag, child);

            if(doSelect) {
              self.selectDynamicElement(self.getRange(), tag);
            }

            self.fireCKEditorEvent('updateSnapshot');
          }
        });

    }
  },

  _wrapWithContents: function(dynamicTag, child) {
    var clonedElement = jQuery(child).clone().empty(),
        contents      = jQuery(child).contents(),
        self          = this;
    jQuery(child).replaceWith(contents);
    contents.each(function(i, content){
      self._wrapDynamicTagIfTextNode(dynamicTag, content, clonedElement);
    });
  },

  _wrapDynamicTagIfTextNode: function(dynamicTag, content, element) {
    if(content.nodeType === 3 || !jQuery(content).hasClass('dynamic-tag-close')) {
      jQuery(dynamicTag).wrap(element);
      this.fireCKEditorEvent('updateSnapshot');
      //Start this entire process again.
      this._wrapParentWithChildren(dynamicTag);
    }
  },

  _isTagSelected: function(tag) {
    return this.getDynamicTagFromSelection()[0] === tag;
  },

  _addRemoveButtonToDynamicTags: function() {
    if(!this.isMode('wysiwyg')){return;}
    var self  = this,
        dTags = this._getAllDynamicTags();

    dTags.each(function(i, tag) {
      if(jQuery(tag).children('.dynamic-tag-close')) {
        self._findOrCreateCloseTag(tag).appendTo(tag);
      }
    });
  },

  _removeDynamicTag: function(tag) {
    jQuery(tag).find('span').remove();
    jQuery(tag).contents().unwrap();
    this.editor.fire('saveSnapshot');
  },

  _addDynamicTagEventsToHtmlEditor: function() {
    var htmlEditor = this.getEditorInstance(),
        self       = this;

    htmlEditor.on('contentDom', function(){
      self._handleSelectionEvents();
      self._removeDynamicTagEvent();
      self._handleDblClickOnDynamicTagEvent();
      self._handleModeChangeEvent(htmlEditor);
      self._handleCommandExecEvents();
      self._sanitizeCustomTags();
      self.addRemoveDynamicTagButton();
      self._handleEditorToolbarButtonClicks(htmlEditor);
      self._handleKeyboardEvents();
    });
  },

  _handleEditorToolbarButtonClicks: function(htmlEditor) {
    var self = this;
    jQuery(htmlEditor.element.$).find('.cke_button a').on('click', function(){
      self._setCurrentDynamicTagEditability(true);
      self._cleanEmptyDynamicTags();
    });
  },

  _removeDynamicTagEvent: function() {
    var self = this,
    keyboardEvent = function(e){
      self.removeOnKeyPress(e, e.data.keyCode, function(shiftKeyPressed){
        if(shiftKeyPressed) {
          self.editor.insertHtml('<br />');
        } else {
          self.editor.insertHtml('<br /><br />');
        }
        self.fireCKEditorEvent('saveSnapshot');
        e.cancel();
      });
    };

    this.editor.on('key', keyboardEvent);
  },

  _handleDblClickOnDynamicTagEvent: function() {
    var self = this;
    this.editor.document.on('dblclick', function(e) {
      if(self.isEventInDynamicTag(e)) {
        self.completeSelection(true);
        self.showDynamicTextDialog();
        e.cancel();
      }
    });
  },

  _handleModeChangeEvent: function(htmlEditor) {
    var self = this;
    htmlEditor.on('beforeSetMode', function() {
      self._sanitizeCustomTags();
    });
  },

  _handleCommandExecEvents: function() {
    var self = this;
    this.editor.on('afterCommandExec', function(e) {
      if(e.data.name === 'undo') {
        self._sanitizeCustomTags();
        self._addRemoveButtonToDynamicTags();
      }
    });

    this.editor.on('beforeCommandExec', function(e){
      if(self.isMode('wysiwyg') && (e.data.name !== 'undo' || e.data.name !== 'redo')) {
        if(self.isWithinADynamicElement(self.getSelectedElement())) {
          self._setCurrentDynamicTagEditability(true);
          self.editor.fire('updateSnapshot');
        }
      }
    });

  },

  _handleSelectionEvents: function() {
    var events = ['mousedown', 'mouseup', 'keydown', 'keyup', 'key'],
        self   = this;

    jQuery.each(events, function(i, eventName) {
      self.editor.document.on(eventName, function(e){
        if(self.canUpdate(e.name)) {
          self.updateElementText();
        }
        if(self.isInSelectDynamicTagMode(e)) {
          self.selectDynamicElement(document.createRange(), e.data.$.target);
          self._setDynamicTagsAsUneditable();
        }
        self._sanitizeCustomTags();
      });
    });
  },

  _handleKeyboardEvents: function () {
    var self = this;

    this.editor.document.on('keydown', function(e) {
      self._setDynamicTagsAsUneditable();
      self._handleSkipTag(e);
    });

    this.editor.document.on('keyup', function(e){
      self._handleSkipTag(e);
    });
  },

  _getCursorMovement: function(e) {
    var moveLeft   = e.data.$.keyCode === this.KEYCODES.left,
        moveRight  = e.data.$.keyCode === this.KEYCODES.right;
    if (moveLeft || moveRight) {
      return { left: moveLeft, right: moveRight };
    }
  },

  _moveCursorToBeginningOfTag: function(element) {
    var newElement = this._getClosestPreviousSibling(element),
        selection = this._selectElement(newElement);
    selection.collapse(newElement, 0);
    return {
      newElement: newElement,
      selection: this._selectElement(newElement)
    };
  },

  _moveCursorToEndOfTag: function(element) {
    var newElement = this._getClosestNextSibling(element),
      props = {
        newElement: newElement,
        selection: this._selectElement(newElement)
      };
    props.selection.collapseToEnd();
    return props;
  },


  _handleSkipTag: function(e) {
    var element        = this.getStartElement(),
        cursorMovement = this._getCursorMovement(e);

    if (cursorMovement && this.isWithinADynamicElement(element)) {
      if (cursorMovement.left) {
        this._moveCursorToBeginningOfTag(element);
      } else if(cursorMovement.right) {
        this._moveCursorToEndOfTag(element);
      }
      this.fireCKEditorEvent('saveSnapshot');
    }
  },

  _selectElement: function(element) {
    var range     = this.getRange().cloneRange(),
        selection = this.getSelection();
    range.selectNode(element);
    selection.removeAllRanges();
    selection.addRange(range);
    return selection;
  },

  _sanitizeCustomTags: function() {
    if(lp.isDynamicTextEnabled()) {
      var htmlEditor = this.getEditorInstance();
      if(this.isMode('wysiwyg')) {
        this._sanitizeInWysiwygMode(htmlEditor);
        this._addRemoveButtonToDynamicTags();
      } else {
        this._sanitizeInSourceMode(htmlEditor);
      }
    }
  },

  _removeHTMLRegex: function(i, oldHtml) {
    return oldHtml.replace(/(<([^>]+)>)/ig, '');
  },

  _sanitizeInWysiwygMode: function(htmlEditor) {
    if(!this.isMode('wysiwyg') || !this.dataReady){return;}

    var dTags = jQuery(htmlEditor.document.$).find('ub\\:dynamic'),
        self  = this;
    this._setAsEditableIfTagIsSelected();
    dTags.each(function(i, tag){
      self._styleIfTagHasChildren(tag);
    });
  },

  _setAsEditableIfTagIsSelected: function() {
    var selection = this.getSelection();
    if(selection) {
      var selectedTag = this.getSelectedElement();
      if(this.isDynamicElement(selectedTag)) {
        jQuery(selectedTag).removeAttr('contenteditable');
      }
    }
  },

  _styleIfTagHasChildren: function(tag) {
    if (this.isDynamicElement(tag)) {
      var children = jQuery(tag).children(':not(.dynamic-tag-close)');
      if(children.length > 0) {
        this._handleDynamicTagStyling(tag);
        jQuery(tag).html(this._removeHTMLRegex);

        this.fireCKEditorEvent('updateSnapshot');
      }
    }
  },

  _sanitizeInSourceMode: function(htmlEditor) {
    if(!htmlEditor.textarea){return;}
    var content = htmlEditor.getData();
    jQuery(htmlEditor.textarea.$).val(this._sanitizeFromContent(content));
  },

  _sanitizeFromContent: function(content) {
    var temp = jQuery('<div>');

    temp.html(content);
    temp.find('ub\\:dynamic').html(this._removeHTMLRegex);
    return temp.html();
  }

});
