/* globals Raven, define */
 ;(function(root, fn) {
   if (typeof exports === 'object') {
     var ErrorNotifier = fn();
     exports.ErrorNotifier = ErrorNotifier;
     module.exports = ErrorNotifier;
   } else if (typeof 'define' === 'function' && define.amd) {
     define(fn);
   } else {
     root.lp = root.lp || {};
     root.lp.ErrorNotifier = fn();
   }
 })(this, function() {
   var _getRavenContextForEditorError = function (ev0) {
     try {
       var ev = ev0;
       ev.tags = {
         message: ev.message,
         breakpoint: window.editor.page.getCurrentBreakpoint().name,
         active_element_type: window.editor.activeElement.getType(),
         page_used_as: window.editor.page.page.used_as
       };
       ev.extra = {
         active_element_id: window.editor.activeElement.id
       };
       return ev;
     } catch (e) {
       Raven.captureException({tags: {fn:'_addRavenContext'}}, e);
       return ev0;
     }
   };

   var ErrorNotifier = function() {
     this._switchboard    = window.rx_switchboard;
     this._rxSubscriptions = [];
     this._errorContextStack = [];
     this._userContext = {};
     return this;
   };

   ErrorNotifier.prototype = (function(){
    return {
      _initRavenGlobalContext: function() {
        this._baseContext = _.merge(
          {},
          (window.RavenBaseContext || {}),
          {
            editor_version       : window.builderVersion || 'dev',
            is_admin_user        : !!window.editor.admin,
            undo_manager_execute : false
          });

        if (window.editor.page) {
          this._baseContext.page_uuid = window.editor.page.uuid;
        }
        this._resetBaseContext();
      },

      _resetBaseContext: function () {
        Raven.setTagsContext(JSON.parse(JSON.stringify(this._baseContext)));
      },

      pushErrorContext: function(context0) {
        this._errorContextStack.push(context0);
        return this;
      },

      popErrorContext: function() {
        return this._errorContextStack.pop();
      },

      setUserContext: function(context) {
        //TODO: check if Raven is merging this data in or if it overwrites existing context
        this._userContext = JSON.parse(JSON.stringify(context));
        Raven.setUserContext(context);
        return this;
      },

      captureException: function(error, options) {
        var stackContext = _.reduce(this._errorContextStack.concat([options]), _.merge, {});
        Raven.captureException(error, stackContext);
        this._resetBaseContext();
        return this;
      },

      install: function() {
        if (window.RavenStub) {
          // This is a temp thing until we can figure out why Raven really
          // doesn't like giving up the namespace in testing.
          window.Raven = new window.RavenStub();
        } else {
          this._initRavenGlobalContext();
          if (gon.user) {
            this.setUserContext(gon.user);
          }
          var self = this;
          this._rxSubscriptions.push(
            this._switchboard.subjects.editor_errors
              //TODO: add an onError callback here:
              .subscribe(function (ed_err) {
                self.captureException(ed_err.error, _getRavenContextForEditorError(ed_err));
              }));
        }
        return this;
      }
    };
   })();

   return ErrorNotifier;
 });
