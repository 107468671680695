jui.ModalDialog = Class.create(jui.ModalPanel, {
  initialize: function($super, options) {
    this.actionButtons = {};
    options = options || {};
    this.layoutRules = options.layoutRules;
    $super(options);
  },

  installUI: function($super) {
    $super();
    var self = this;

    this.titleBar = this.insert(new jui.Component({attributes: {className: 'title-bar'}}));
    this.contentPanel = this.insert(new jui.Component({attributes: {className: 'content-panel'}}));
    this.actionBar = this.insert(new jui.Component({attributes: {className: 'action-bar'}}));
    this.title = this.titleBar.insert(new Element('h1'));

    this.titleBar.observe('mousedown', this.mouseDownListener);

    this.dialogScreen = this.insert(new jui.Component({attributes: {className: 'dialog-screen'}}));
    this.errorDialog = this.insert(new jui.Component({attributes: {className: 'error-dialog'}}));
    this.warningDialog = this.actionBar.insert(new jui.Component({attributes: {className: 'warning-dialog-text'}}));
    this.waiter = this.actionBar.insert(new jui.Component({attributes: {className: 'waiter'}}));

    this.errorText = this.errorDialog.insert(new Element('p'));
    this.warningText = this.warningDialog.insert(new Element('span'));
    this.waiterText = this.waiter.insert(new Element('span'));

    this.errorDialog.insert( new jui.Button({
      label:'OK',
       action:function() {
         self.dismissErrorDialog();
      }
    }));

    this.dialogScreen.hide();
    this.errorDialog.hide();
    this.warningDialog.hide();
    this.waiter.hide();

    if (typeof this.options.title !== 'undefined') {
      if (this.options.title === false) {
        this.contentPanel.setTop(3);
        this.titleBar.hide();
      }
      this.setTitle(this.options.title);
    }

    if (this.options.actions) {
      this.options.actions.each( function(action) {
        var button = new jui.Button({
          label: action.label,
          action: action.action
        });
        this.actionButtons[action.id] = button;
        this.actionBar.insert(button);
      }, this);
    }
  },

  setTitle: function(title) {
    this.title.update(title);
  },

  updateLayout: function($super) {
    if (this.layoutRules) {
      this.layoutRules.method.call(this, this.layoutRules.options || {});
    } else {
      $super();
    }
  },

  showErrorDialog: function(errorText) {
    this.errorText.update(errorText);
    this.dialogScreen.show();
    this.errorDialog.show();
  },

  showWarningDialog: function(errorText) {
    this.warningText.update(errorText);
    this.actionBar.addClassName('warning-dialog');
    this.warningDialog.show();
  },

  updateButton: function(whichButton, label, action) {
    this.actionButtons[whichButton].setLabel(label);
    if(action) {
      this.actionButtons[whichButton].setAction(action);
    }
  },

  hideWarningDialog: function() {
    this.actionBar.removeClassName('warning-dialog');
    this.warningDialog.hide();
  },

  showWaiter: function(waiterText) {
    this.waiterText.update(waiterText);
    this.waiter.show();
  },

  hideWaiter: function() {
    this.waiter.hide();
  },

  close: function($super) {
    $super();
    this.dismissErrorDialog();
  },

  dismissErrorDialog: function() {
    this.dialogScreen.hide();
    this.errorDialog.hide();
    this.fireEvent('errorDialogDismissed');
  }
});

jui.ModalDialog.fitToViewPort = function(options) {
  var padding = options.padding || 2;
  this.setOffset({left:padding, top:padding});
  this.setDimensions({width:document.viewport.getWidth() - padding*2, height:document.viewport.getHeight() - padding*2});
};

jui.ModalDialog.centerInViewPort = function(options) {
  if (options.size) {
    this.setDimensions(options.size);
  }

  var loc = {
   left: (document.viewport.getWidth() - this.getWidth()) / 2,
   top: (document.viewport.getHeight() - this.getHeight()) / 2
  };

  this.setOffset(loc);
};
