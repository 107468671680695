lp.editor.PageBoundaryManager = Class.create( {
  initialize: function(editor){
    this.editor = editor;
    this.visible = true;
    this.root = null;
    this.boundaries = [];
    this.undoRegistered = false;

    this.minWidth = 16;
    this.maxWidth = 100000;

    var canvasListener = this.layoutChanged.bind(this);

    this.editor.addListener('layoutChanged', canvasListener);
    this.editor.canvasBody.observe('scroll', canvasListener);

    this.editor.addListener('activeRootChanged', this);
  },

  activeRootChanged: function(e) {
    var previous = e.data.previous;
    var root = e.data.current;
    if (previous !== null) {
      previous.removeListener('layoutChanged', this);
      previous.page.removeListener('breakpointChanged', this);
    }

    root.addListener('layoutChanged', this);
    root.page.addListener('breakpointChanged', this);

    this.root = root;

    this.removeAllBoundaries();
    if (this.root.page.allowTopPadding()) {
      this.createTopPaddingBoundary();
    }

    this.updateMinMaxWidth();
    this.createPageBoundaries();
    this.positionBoundaries();

    this.setVisible(this.editor.page.settings.showPageTransformBox);
  },

  updateMinMaxWidth: function() {
    this.minWidth = this.root.page.getMinWidthForCurrentBreakpoint();
    this.maxWidth = this.root.page.getMaxWidthForCurrentBreakpoint();

    if (this.minWidth === this.maxWidth){
      this.disableWidthBoundaries();
    } else {
      this.enableWidthBoundaries();
    }
  },

  createTopPaddingBoundary: function() {
    var m = this.root.model;
    var self = this;
    var padding = m.exists('geometry.padding') ? m.get('geometry.padding') : {};

    this.boundaries.push(
      self.editor.canvasBody.insert( new lp.editor.ElementBoundaryControl({
        element: this.root,
        manager: this,
        onDrag: function(p) {
          padding.top = Math.max(0,this.startValue + p.top);
          m.set('geometry.padding', padding);
        },
        onInitDrag: function() {
          this.startValue = m.exists('geometry.padding.top') ? m.get('geometry.padding.top') : 0;
        },
        onBeforeDrag: function() {
          if (!self.undoRegistered) {
            self.undoRegistered = true;
            var um = self.root.page.undoManager;
            um.startGroup();
            um.registerUndo({
              action:m.set,
              receiver:m,
              params:['geometry.padding', jui.extend({}, padding), um]
            });
            um.endGroup();
          }
        },
        actsOn: 'padding-top'
      }))
    );
  },

  createPageBoundaries: function() {
    var m = this.root.model;
    var self = this;

    var onInitDrag = function() {
      this.startValue = m.get('geometry.contentWidth');
    };

    var onBeforeDrag = function() {
      if (!self.undoRegistered) {
        self.undoRegistered = true;
        var um = self.root.page.undoManager;
        um.startGroup();
        um.registerUndo({
          action:m.set,
          receiver:m,
          params:['geometry.contentWidth', m.get('geometry.contentWidth'), um]
        });
        um.endGroup();
      }
    };

    this.boundaries.push(
      self.editor.canvasBody.insert( new lp.editor.ElementBoundaryControl({
        element: this.root,
        manager: this,
        onDrag: function(p) {
          m.set('geometry.contentWidth', Math.min(Math.max(self.minWidth, this.startValue - p.left*2), self.maxWidth));
        },
        onInitDrag: onInitDrag,
        onBeforeDrag: onBeforeDrag,
        actsOn: 'page-left',
        axis: 'y'
      }))
    );

    this.boundaries.push(
      self.editor.canvasBody.insert( new lp.editor.ElementBoundaryControl({
        element: this.root,
        manager: self,
        onDrag: function(p) {
          m.set('geometry.contentWidth', Math.min(Math.max(self.minWidth, this.startValue + p.left*2), self.maxWidth));
        },
        onInitDrag: onInitDrag,
        onBeforeDrag: onBeforeDrag,
        actsOn: 'page-right',
        axis: 'y'
      }))
    );
  },

  removeAllBoundaries: function() {
    this.boundaries.each(function(b) {
      b.remove();
    }, this);
    this.boundaries = [];
  },

  layoutChanged: function() {
    if (this.root !== null) {
      this.positionBoundaries();
    }
  },

  breakpointChanged: function() {
    this.updateMinMaxWidth();
  },

  positionBoundaries: function() {
    var w = this.editor.canvasBody.e.clientWidth;
    var h = this.editor.canvasBody.e.clientHeight;
    var t = this.editor.canvasBody.e.scrollTop;
    var l = Math.round((this.root.getWidth() - this.root.getContentWidth()) / 2) + this.root.getLeft();

    this.boundaries.each(function(b) {
      if (b.actsOn === 'page-left') {
        b.setOffset({left: l - 5, top: t});
        b.setHeight(h);
      } else if (b.actsOn === 'page-right') {
        b.setOffset({left: l + this.root.getContentWidth() - 4, top: t});
        b.setHeight(h);
      } else if (b.actsOn === 'padding-top') {
        b.setOffset({left: 0, top: this.root.getTopPadding() - 4});
        b.setWidth(w);
      }
    }, this);
  },

  setBoundaryVisibility: function() {
    this.boundaries.each(function(b) {
      b.setVisible(this.visible && b.elm.visible());
    }, this);
  },

  setVisible: function(visible) {
    this.visible = visible;
    this.setBoundaryVisibility();
  },

  disableWidthBoundaries: function() {
    this.boundaries.each(function(b) {
      if (b.actsOn === 'page-left' || b.actsOn === 'page-right') {
        b.disable();
      }
    });
  },

  enableWidthBoundaries: function() {
    this.boundaries.each(function(b) {
      if (b.actsOn === 'page-left' || b.actsOn === 'page-right') {
        b.enable();
      }
    });
  }
});
