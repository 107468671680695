lp.module.script = {};

lp.module.script.ScriptModule = Class.create(
  lp.ModuleBase,
  lp.ModuleElementMethods,
  lp.ModuleElementBuilderMethods,
  {
    beforeCreate: function(page, defaults, options, data) {
      defaults.content.html = data.html;
    }
  }
);

lp.addModule(new lp.module.script.ScriptModule(
  {
    type:'lp-script',
    version:'1.0',
    name: 'Javascript',
    defaultName: 'Javascript',
    usageType:'non-visible-page-widget',
    namespace: lp.module.script,
    elementClassName: 'ScriptElement',
    elementBuilderClassName: 'ScriptElementBuilder'
  }
));