lp.editor.SnapManager = Class.create( jui.EventSource, jui.Options, {
  options: function($super,options){
    return $H({
      tolerance: 3
    }).merge(options);
  },

  initialize: function(editor, options){
    this.setOptions(options);
    this.editor = editor;
    this.containerSnaps = {x:{left:[], center:[], right:[]}, y:{top:[], center:[], bottom:[]}};
    this.snapToGuides = true;
    this.snapToContainer = true;

    this.editor.addListener('elementActivated', this);
    this.editor.addListener('elementDeactivated', this);
    this.editor.addListener('elementContainerChanged', this);
    this.editor.addListener('elementDragStop', this);
  },

  elementActivated: function(e) {
    var elm = e.data;
    this.clearContainers();
    if (elm.is('offsetable') && elm.parentElement !==  null) {
      this.addContainer(elm);
    }
  },

  elementDeactivated: function() {
    this.clearSnaps();
  },

  elementContainerChanged: function(e) {
    this.elementActivated(e.data);
  },

  elementDragStop: function() {
    this.fireEvent('notSnappedToContainer', {axis:'x'});
    this.fireEvent('notSnappedToContainer', {axis:'y'});
  },

  clearSnaps: function() {
    this.clearContainers();
    this.fireEvent('notSnappedToContainer', {axis:'x'});
    this.fireEvent('notSnappedToContainer', {axis:'y'});
  },

  clearContainers: function() {
    this.containerSnaps = {x:{left:[], center:[], right:[]}, y:{top:[], center:[], bottom:[]}};
  },

  addContainer: function(elm) {
    var container = elm.parentElement;
    var w = container.getContentWidth();
    var h = container.getContentHeight();

    this.containerSnaps.x.left.push(0); // left edge
    this.containerSnaps.x.center.push(Math.round(w/2)); // w centre
    this.containerSnaps.x.right.push(w); // right edge
    this.containerSnaps.y.top.push(0); // top
    this.containerSnaps.y.center.push(Math.round(h/2)); // w centre
    this.containerSnaps.y.bottom.push(h); // bottom

    if (container.model.exists('style.border.style') &&
        container.model.get('style.border.style') !== 'none') {

      var sign = container.model.get('geometry.borderLocation') === 'inside' ? 1 : -1;
      var bw = container.model.get('style.border.width') * sign;
      this.containerSnaps.x.left.push(bw); // left edge
      this.containerSnaps.x.right.push(w-bw); // right edge
      this.containerSnaps.y.top.push(bw); // top
      this.containerSnaps.y.bottom.push(h-bw); // bottom
    }

    //children snaps
    container.childElements.each(function(c) {
      if (c !== elm) {
        var o = c.model.getOffset();
        var s = c.model.getSize();
        var x = o.left;
        var y = o.top;
        var w = s.width;
        var h = s.height;

        this.containerSnaps.x.left.push(x); // left edge
        this.containerSnaps.x.center.push(x + Math.round(w/2)); // w centre
        this.containerSnaps.x.right.push(x+w); // right edge
        this.containerSnaps.y.top.push(y); // top
        this.containerSnaps.y.center.push(y+Math.round(h/2)); // w centre
        this.containerSnaps.y.bottom.push(y+h); // bottom
      }
    },this);
  },

  getSnapWithinTolerance: function(snaps, value, tolerance) {
    var snap = 0;
    for(var i=0,l=snaps.length; i<l; i++){
      snap = snaps[i];
      if (Math.abs(value-snap) <= tolerance) {
        return snap;
      }
    }
    return null;
  },

  snapPoint: function(p, axis) {
    this.snapPointToContainer(p, axis);
  },

  snapRectangle: function(r) {
    this.snapRectangleToContainer(r);
  },

  snapPointToContainer: function(p, axis) {
    axis = jui.extend({x:true, y:true}, axis);

    if (axis.x){
      var xsnap = this.getSnapWithinTolerance(this.containerSnaps.x.left.concat(this.containerSnaps.x.right), p.left, this.options.tolerance);

      if (xsnap !== null) {
        this.fireEvent('snappedToContainer', {axis:'x', snap:xsnap});
        p.left = xsnap;
      } else {
        this.fireEvent('notSnappedToContainer', {axis:'x'});
      }
    }

    if (axis.y){
      var ysnap = this.getSnapWithinTolerance(this.containerSnaps.y.top.concat(this.containerSnaps.x.bottom), p.top, this.options.tolerance);

      if (ysnap !== null) {
        this.fireEvent('snappedToContainer', {axis:'y', snap:ysnap});
        p.top = ysnap;
      } else {
        this.fireEvent('notSnappedToContainer', {axis:'y'});
      }
    }
  },

  snapRectangleToContainer: function(r) {
    var xsnaps = [];
    var ysnaps = [];
    var lr = this.containerSnaps.x.left.concat(this.containerSnaps.x.right);
    var tb = this.containerSnaps.y.top.concat(this.containerSnaps.y.bottom);

    xsnaps.push({snap:this.getSnapWithinTolerance(lr, r.left, this.options.tolerance), axis:'x', offset:0});
    xsnaps.push({snap:this.getSnapWithinTolerance(this.containerSnaps.x.center, r.left+Math.round(r.width/2), this.options.tolerance), axis:'x', offset:-Math.round(r.width/2)});
    xsnaps.push({snap:this.getSnapWithinTolerance(lr, r.left+r.width, this.options.tolerance), axis:'x', offset:-r.width});
    ysnaps.push({snap:this.getSnapWithinTolerance(tb, r.top, this.options.tolerance), axis:'y', offset:0});
    ysnaps.push({snap:this.getSnapWithinTolerance(this.containerSnaps.y.center, r.top+Math.round(r.height/2), this.options.tolerance), axis:'y', offset:-Math.round(r.height/2)});
    ysnaps.push({snap:this.getSnapWithinTolerance(tb, r.top+r.height, this.options.tolerance), axis:'y', offset:-r.height});

    // TODO: JS: This needs to be improved to allow preference to snapping on one side or another
    // or middle, probably depending on where the user clicked on the element to be dragged
    var snap;
    var snappedX = false;
    var snappedY = false;

    for (var i=0, l=xsnaps.length; i<l; i++) {
      snap = xsnaps[i];
      if (snap.snap !==  null) {
        r.left = snap.snap + snap.offset;
        this.fireEvent('snappedToContainer', {axis:'x', snap:snap.snap});
        snappedX = true;
        break;
      }
    }

    for (var j=0, m=ysnaps.length; j<m; j++) {
      snap = ysnaps[j];
      if (snap.snap !==  null) {
        r.top = snap.snap + snap.offset;
        this.fireEvent('snappedToContainer', {axis:'y', snap:snap.snap});
        snappedY = true;
        break;
      }
    }

    if (!snappedX) {
      this.fireEvent('notSnappedToContainer', {axis:'x'});
    }

    if (!snappedY) {
      this.fireEvent('notSnappedToContainer', {axis:'y'});
    }
  },

  snapPointToGuide: function() {

  }
});
