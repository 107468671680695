//------------------------------------------------------------------------------
jui.Menu = Class.create(jui.Component, {
  options: function($super,options){
    return $super($H({
      items: [],
      attributes: {
        className: 'menu'
      }
    }).merge(options));
  },

  initialize: function($super, options) {
    this.items = [];
    this.selectedItem = null;
    $super(options);
    if (this.options.action) {
      this.addListener('actionPerformed', this.options.action);
    }
    this.activeItem = null;
  },

  installUI: function($super) {
    $super();
    this.options.items.each(function(mi) {
      switch (mi.type) {
        case 'item':
          this.addItem(new jui.MenuItem(mi));
          break;
        case 'divider':
          this.addItem(new jui.MenuDivider(mi));
          break;
      }

    }, this);
  },

  addItem: function(item) {
    item.addListener('mouseup', this.itemSelected.bind(this));
    this.items.push(this.insert(item));
  },

  removeItem: function(item) {
    this.items = this.items.without(item);
  },

  activate: function() {
    // this.selectedItem = null;
    this.fireEvent('menuActivated', this);
  },

  deactivate: function() {
    this.fireEvent('menuDeactivated', this);
  },

  itemSelected: function(e) {

    if (e.data.isEnabled()) {
      var oldSelectedItem = this.selectedItem;
      this.selectedItem = e.data;

      if (this.options.actAsToggle) {
        this.selectedItem.toggle();
      }

      if (this.options.actAsRadio && oldSelectedItem !== this.selectedItem) {
        if (oldSelectedItem !== null) {
          oldSelectedItem.toggleOff();
        }
        this.selectedItem.toggleOn();
      }

      this.fireEvent('actionPerformed', this);
    }
    jui.Menu.deactivateAll();
  }
});

(function() {
  var activeMenus = [];
  var _screen = null;
  var screenMouseup = false;
  var screen = function() {
    return _screen || (function() {
      _screen = new Element('div', {className: 'menu-screen'});
      _screen.observe('mousedown', jui.Menu.deactivateAll);
      _screen.observe('mouseup', function(e) {
          if (screenMouseup) {
              jui.Menu.deactivateAll(e);
          }
      });
      return _screen;
    })();
  };

  var showScreen = function(){
    if (screen().parentNode !== document.body) {
      document.body.appendChild(screen());
    }
  };

  var hideScreen = function(){
    screen().remove();
  };

  Object.extend(jui.Menu, {
    activate: function(menu, options) {
      /* jshint unused:vars */
      screenMouseup = false;
      (function() {
        screenMouseup = true;
      }).delay(0.7);

      showScreen();
      activeMenus.push(menu);
      document.body.insert(menu.e);
      menu.activate();
    },

    deactivateAll: function() {
      activeMenus.each(function(m) {m.deactivate(); m.e.remove();});
      activeMenus = [];
      hideScreen();
    }
  });
})();

//------------------------------------------------------------------------------
jui.MenuItem = Class.create(jui.Component, jui.ControlModel, jui.ToggleModel, {
  options: function($super,options){
    return $super($H({
      menuItems: [],
      attributes: {
        className: 'menu-item'
      }
    }).merge(options));
  },

  initialize: function($super, options) {
    this.labelText = options.label || null;
    this.tag = options.tag || null;
    this.data = options.data || null;
    $super(options);
    if (this.options.action) {
      this.addListener('actionPerformed', this.options.action);
    }
  },

  installUI: function($super) {
    $super();
    var self = this;
    this.e.observe('mouseup', function() {
      self.e.removeClassName('hover');
      self.fireEvent('mouseup', self);
      if (self.isEnabled()) {
        self.fireEvent('actionPerformed', self);
      }
    });
    this.e.observe('mouseover', function(e) {
      e.stop();
      self.e.addClassName('hover');
    });
    this.e.observe('mouseout', function(e) {
      e.stop();
      self.e.removeClassName('hover');
    });
    var checkmark = this.insert(new Element('div', {className:'checkmark'}));
    checkmark.update('&#x2713;');
    if(!!this.options.warning) {
      this.insert(new Element('div', {className:'warning'}));
    }
    this.label = this.e.select('span')[0] || ((this.options.label) ? this.createLabel(this.options.label) : null);
  },

  createLabel: function(label) {
    return this.insert(new Element('span', {
      className: 'label'
    }).update(label));
  }
});

//------------------------------------------------------------------------------
jui.MenuDivider = Class.create(jui.Component, {
  options: function($super,options){
    return $super($H({
      menuItems: [],
      attributes: {
        className: 'menu-divider'
      }
    }).merge(options));
  }
});
