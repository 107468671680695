jui.AssetSortTab = Class.create(jui.Component, jui.ControlModel, {
  options: function($super, options) {
    return $super($H({
      attributes: {className: 'tab-button-options'},
      selectedByDefault: 'id'
    }).merge(options));
  },

  initialize: function($super, options) {
    this.sortTabButtons = {};
    $super(options);

    if(this.options.toggle) {
      this.addListener('toggle', this.options.toggle);
    }
  },

  installUI: function($super) {
    $super();
    this.tabButtonContainer = new jui.Component({className: 'tab-button-container'});
    this.options.sortTabButtons.each(function(st){
      var spacer = new Element('div', {className: 'spacer'});
      var ascDsc = new Element('div', {className: 'ascDsc'});
      var tabButton = new Element('div', {className: 'tab-button asc' , id: st.id});
      if(this.options.selectedByDefault === st.id) {tabButton.addClassName('selected');}
      var tabButtonContent = new Element('div', {className: 'tab-button-content'}).update(st.name);
      tabButton.insert(spacer);
      tabButton.insert(ascDsc);
      tabButton.insert(tabButtonContent);
      this.tabButtonContainer.insert(tabButton);
      tabButton.observe('click', this.toggle.bind(this));
    },this);
    this.insert(this.tabButtonContainer);
  },

  select: function(target) {
    this.deselectAll();
    target.addClassName('selected');
  },

  deselectAll: function() {
    $$('.tab-button.selected').each(function(el){
      el.removeClassName('selected');
    });
  },

  selectById: function(id) {
    var ele = this.getTabButtonByTarget(id);
    this.resetDirection(ele);
    this.select(ele);
  },

  isTargetSelected: function(targetEl) {
    return $(targetEl).hasClassName('selected');
  },

  toggle: function(e) {
    var ele = this.getTabButtonByTarget(e.target);
    if (!ele) {
      return;
    }
    //isSelected will let us know if we need to change the sort order.  If it is
    //newley selected than we sort but by the current order.
    var isSelected = this.isTargetSelected(ele);
    this.select(ele);

    if(isSelected) {
      this.setDirectionByEl(ele);
    }

    var direction = ele.hasClassName('asc') ? 'asc' : 'dsc';
    this.fireEvent('toggle', {
      action: $(ele).readAttribute('id'),
      direction: direction
    });
  },

  getTabButtonByTarget: function(target) {
    var ele = $(target);
    if (ele) {
      return ele.hasClassName('tab-button') ? ele : ele.up('.tab-button');
    } else {
      return null;
    }
  },

  setDirectionByEl: function(ele) {
    var currentDirection = ele.hasClassName('asc') ? 'asc' : 'dsc';
    var counterDirection = currentDirection === 'asc' ? 'dsc' : 'asc';

    if(ele.hasClassName(currentDirection)) {
      ele.removeClassName(currentDirection);
      ele.addClassName(counterDirection);
    } else if(ele.hasClassName(counterDirection)) {
      this.resetDirection(ele);
    }

  },

  resetDirection: function(ele) {
    if(ele.id === 'id') {
      ele.removeClassName('asc');
      ele.addClassName('dsc');
    } else {
      ele.removeClassName('dsc');
      ele.addClassName('asc');
    }
  },

  resetDirectionOnAll: function() {
    var self = this;
    $$('.tab-button').each(function(el){
      self.resetDirection(el);
    });
  }

});
