lp.module.form = {};

lp.module.form.FormModule = Class.create(
  lp.ModuleBase,
  lp.ModuleElementMethods,
  lp.ModuleElementBuilderMethods,
  lp.ModuleElementPropertiesPanelMethods,
  {
    limit: 1,

    jQueryPlugins: [
      '//d2xxq4ijfwetlm.cloudfront.net/m/lp-webapp/0.0.9/jquery-validate/1.6/jquery.validate.min.js',
      '//d2xxq4ijfwetlm.cloudfront.net/m/lp-webapp/0.0.9/jquery-validate/1.6/additional-methods.js',
      '//d2xxq4ijfwetlm.cloudfront.net/m/lp-webapp/0.0.9/ubpoverlay/jquery.ubpoverlay.js'
    ],

    stylesheets: [
      '//d2xxq4ijfwetlm.cloudfront.net/m/lp-webapp/0.0.9/ubpoverlay/jquery.ubpoverlay.css'
    ],

    javascripts: [
      '//d2xxq4ijfwetlm.cloudfront.net/m/lp-webapp/matchmedia/matchMedia.js',
      '//d2xxq4ijfwetlm.cloudfront.net/m/lp-webapp/0.0.9/lp-form/4.7/main.js'
    ],

    beforeCreate: function(page, defaults, options, fields) {
      defaults.content.fields = fields;
      page.undoManager.startGroup();
    },

    afterInsert: function(element, options) {
      if (element.page.context === lp.pom.context.EDIT) {
        element.createDependantElements();
      }
      if (options.center) {
        var size = element.model.getSize();
        element.model.setOffset({
          left: options.offset.left,
          top: options.offset.top - Math.round(size.height / 2)
        });
      }

      element.page.undoManager.endGroup();
    },

    getTransformBoxUIExtensions: function(elm) {
      /* jshint unused:vars */

      this.labelWidthIndicator = this.labelWidthIndicator ||
        new lp.module.form.LabelWidthControlTransformBoxExtension();
      return [
        {
          placement: this.labelWidthIndicator.updateUI.bind(this.labelWidthIndicator),
          component: this.labelWidthIndicator
        }
      ];
    }
  }
);

lp.addModule(new lp.module.form.FormModule(
  {
    type:'lp-pom-form',
    version:'1.7.2',
    name: 'Form',
    usageType:'page-widget',
    namespace: lp.module.form
  }
));
