lp.module.video.VideoElementPropertiesPanel = Class.create(
  lp.editor.panel.ElementPropertiesPanel,
  lp.editor.panel.ElementGeometryControls,
  lp.ModuleComponent,
  {
    type:'lp-pom-video',

     initialize: function($super) {
        $super();
      },

      installUI: function($super){
        $super();
        var self = this;

        var geometryPanel = this.insert(
          new jui.CollapsiblePanel({title:'Geometry'})
        );

        this.installGeometryControls({
          insert:geometryPanel.insertContent.bind(geometryPanel),
          sizeOptions: {
            showMaintainAR: true,
            minWidth: 10,
            minHeight: 10
          }
        });

        var contentPanel = this.insert(
          new jui.CollapsiblePanel({title:'Content'})
        );

        this.controls.editButton = contentPanel.insertContent(new jui.Button({
          label:'Edit Code',
          action:function(){
            //Called when the "Edit Code" button is clicked on the side panel.
            self.getModule().openBuilder(self.element, {
              callback:function(data){
                self.element.model.set(
                  'content.html', data.html, self.element.page.undoManager
                );
              }});
          }
        }));
      },

      syncUIControls: function($super, elm) {
        $super(elm);
        this.bindGeometryControlsToElement(elm);
      }
  }
);
