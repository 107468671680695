lp.editor.ActionHintBar = Class.create(jui.Component, {
  options: function($super,options){
    return $super($H({attributes:{className:'action-hint'}}).merge(options));
  },

  initialize: function($super, editor, options){
    this.editor = editor;
    $super(options);

    var self = this;

    // this.editor.transformBox.addListener('dragStart', function(e) {
    //   self.showHint('Drag '+e.data.elm.name+' to reposition it within '+e.data.startParent.name);
    // });


    this.editor.transformBox.addListener('parentChanged', function(e) {
      var elm = e.data;
      var startParent = e.source.startParent;

      if (elm.parentElement !== startParent) {
        self.showHint('Release the mouse button to move '+elm.name+' to  '+elm.parentElement.name+' (Hold down the Command key to keep in '+startParent.name+')');
      } else {
        self.hideHint();
        // self.showHint('Drag '+elm.name+' to reposition it within '+startParent.name);
      }

    });

    this.editor.transformBox.addListener('dragStop', function(e) {
      /* jshint unused:vars */
      self.hideHint();
    });

    var transitionListener = function() {
      self.e.removeClassName('hilite');
    };

    this.e.observe('webkitTransitionEnd', transitionListener);
    this.e.observe('transitionend', transitionListener);
  },

  showHint: function(text) {
    if (!this.e.hasClassName('open')) {
      this.e.addClassName('open');
    }

    if (!this.e.hasClassName('hilite')) {
      this.e.addClassName('hilite');
    }

    this.update(text);
  },

  hideHint: function() {
    this.clear();
    this.e.removeClassName('open');
  }
});
