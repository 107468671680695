;(function() {
  var lp = window.lp || window.LP;
  var PAGE_VERSION_NEWER_ERR = lp.PAGE_VERSION_NEWER_ERR = 1;

  var messages = lp.exceptionMessages = {};

  messages[PAGE_VERSION_NEWER_ERR] = "Page was created with newer version";

  // This is a custom Exception thrown when there is a version mismatch on POM data
  lp.POMError = function(code, message, data) {
    this.code = code;
    this.message = messages[code]+message ? '' : ': '+message;
    Error.call(this, this.message);
    this.data = data;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, lp.POMError);
    }
  };

  lp.POMError.prototype = new Error();
  lp.POMError.constructor = lp.POMError;
})();
