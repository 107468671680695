lp.module.stylesheet = {};

lp.module.stylesheet.StylesheetModule = Class.create(
  lp.ModuleBase,
  lp.ModuleElementMethods,
  lp.ModuleElementBuilderMethods,
  {
    beforeCreate: function(page, defaults, options, data) {
      defaults.content.html = data.html;
    }
  }
);

lp.addModule(new lp.module.stylesheet.StylesheetModule(
  {
    type:'lp-stylesheet',
    version:'1.0',
    name: 'Stylesheet',
    defaultName: 'Stylesheet',
    usageType:'non-visible-page-widget',
    namespace: lp.module.stylesheet
  }
));