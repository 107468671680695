(function(Jui, Dnd) {
  var TargetFinder = function(point, source, group) {
    this.point = point;
    this.source = source;
    this.group = group;
  };

  TargetFinder.prototype = (function() {
    var _isTargetRoot = function(t) {
      return !(t && t.elm);
    };

    var _isTargetVisible = function(target) {
      if(!target && !target.elm ||  _isTargetRoot(target)){return true;}

      if(target.elm.getType() === 'lp-pom-block') {
        return target.elm.visible();
      } else {
        return !target.elm.visible() ? false : _isTargetVisible({elm: target.elm.parentElement});
      }
    };

    return {
      all: function() {
        return this.group.getTargets();
      },

      target: function(group) {
        /* jshint unused:vars */
        var found = null,
          self = this;

        self.all().forEach(function(target) {
          if (target.isWithin(self.point, self.source)) {
            if (found === null) {
              found = target;
            } else {
              found = found.topMost(target);
            }
          }
        });

        return found;
      },

      isTargetVisible: function() {
        var t = this.target();
        return _isTargetRoot(t) ? true : _isTargetVisible(t);
      }
    };
  })();

  Dnd.TargetFinder = TargetFinder;
})(jui = jui || {}, jui.dnd = jui.dnd || {});
