lp.editor.FlashBox = Class.create( jui.Component, {
  options: function($super,options){
    return $super($H({attributes:{id:'flash-box'}}).merge(options));
  },

  initialize: function($super, editor, options){
    this.editor = editor;
    this.flashElementExecuter = null;
    // this.elementListener = this.elementMoved.bind(this);
    this.elmModelListener = this.elementModelChanged.bind(this);
    $super(options);
  },

  installUI: function($super) {
    $super();
    this.tbndry = this.insert( new Element('div', {className:'flash-boundary top'}));
    this.rbndry = this.insert( new Element('div', {className:'flash-boundary right'}));
    this.bbndry = this.insert( new Element('div', {className:'flash-boundary bottom'}));
    this.lbndry = this.insert( new Element('div', {className:'flash-boundary left'}));
  },

  elementModelChanged: function() {
    this.positionBoundingBox();
  },

  flash: function(elm) {
    if (!elm.is('displayable') || elm.type === 'lp-pom-root') {
      return;
    }

    this.elm = elm;

    this.elm.model.addListener('attributeChanged', this.elmModelListener);

    if (this.flashElementExecuter) {
      this.flashElementExecuter.stop();
    }

    this.editor.canvasBody.insert(this);

    this.positionBoundingBox(elm);

    var self = this;
    var startOpacity = 0.8;
    var duration = 250;

    this.e.setOpacity(startOpacity);

    var t0 = new Date().getTime(), elapsed;
    this.flashElementExecuter = new PeriodicalExecuter( function(pe) {
      elapsed = new Date().getTime() - t0;
      var opacity = Math.max(0, startOpacity * ((duration - elapsed) / duration));
      self.e.setOpacity(opacity);
      if (elapsed >= duration) {
        pe.stop();
        self.e.remove();
        self.elm.model.addListener('attributeChanged', self.elmModelListener);
        return;
      }
    }, 0.02);
  },

  positionBoundingBox: function() {
    if (this.elm === null) {
      return;
    }

    var po = this.elm.getPageOffset();

    this.setOffset({left: po.left-3, top: po.top-3});

    var w = this.elm.getContentWidth();
    var h = this.elm.getContentHeight();

    this.tbndry.style.width = (w+6)+'px';
    this.rbndry.style.height = h+'px';
    this.rbndry.style.left = (w+3)+'px';
    this.bbndry.style.width = (w+6)+'px';
    this.bbndry.style.top = (h+3)+'px';
    this.lbndry.style.height = (h)+'px';
  }
});
