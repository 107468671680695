lp.module.socialWidget.SocialWidgetModel = Class.create(lp.pom.VisibleElementModel, {
  initialize: function($super, element, modelData) {
    var self = this;
    $super(element, modelData);
    self.addBreakpointModifyableAttributes(self.uniqProperties());
  },

  uniqProperties: function() {
    return {
      geometry:{
        size: false,
        offset: true
      }
    };
  }
});
