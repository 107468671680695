(function(){
  jui.DynamicTagSelectable = {
    KEYCODES: {
      left       : 37,
      right      : 39,
      enter      : 13,
      shift      : 16,
      del        : 46,
      backspace  : 8,
      shiftLeft  : 2228261,
      shiftRight : 2228263,
      shiftEnter : 2228237
    },

    completeSelection: function(isForce) {

      if(!this.getSelection) {throw 'You must define a getSelection method.';}

      var userSelection = this.getSelection();

      if(userSelection.rangeCount === 0) {return;}

      if(isForce || this.isUncollapsedSelection(userSelection)) {
        var range     = userSelection.getRangeAt(0),
            startNode = this.dynamicNode(range.startContainer),
            endNode   = this.dynamicNode(range.endContainer);

        this._handleSelection(userSelection, startNode, endNode);
      }

      return userSelection;
    },

    dynamicNode: function(node) {
      return this.isDynamicElement(node) ? jQuery(node).get(0) :
        jQuery(node).parent('ub\\:dynamic').get(0);
    },

    isDynamicElement: function(node) {
      return (typeof node !== 'undefined' &&
        jQuery(node).prop('tagName') === 'UB:DYNAMIC');
    },

    findDynamicElement: function(element) {
      return jQuery(element).find('ub\\:dynamic');
    },

    isWithinADynamicElement: function(node) {
      return this.isDynamicElement(node)||
        this.isDynamicElement(jQuery(node).parents('ub\\:dynamic'));
    },

    getAttributesFromElement: function(element) {
      var el = jQuery(element);

      return {
        method    : el.attr('method') || '',
        parameter : el.attr('parameter') || ''
      };
    },

    isSelectionInDynamicTag: function() {
      var selection = this.getSelection(),
          anchor    = selection ? selection.anchorNode : null,
          focus     = selection ? selection.focusNode : null;

      if(anchor || focus) {
        return this.isWithinADynamicElement(anchor) ||
          this.isWithinADynamicElement(focus);
      }
    },

    getDynamicTagFromSelection: function() {
      var selection = this.getSelection();
      if(!selection) {return;}

      var node = selection.anchorNode;

      return this.isDynamicElement(node) ? jQuery(node) : jQuery(node).parents('ub\\:dynamic');
    },

    removeOnKeyPress: function(e, keyCode, removeFunction) {
      if(!keyCode) {return;}
      var shiftEnter = keyCode === this.KEYCODES.shiftEnter;
      if(keyCode === this.KEYCODES.backspace) {
        this.removeOnDelete('previous');
      } else if(keyCode === this.KEYCODES.del) {
        this.removeOnDelete('next');
      } else if(keyCode === this.KEYCODES.enter || shiftEnter) {
        this.removeOnEnter(e, shiftEnter,  removeFunction);
      }
    },

    removeOnEnter: function(e, shiftEnter, removeFunction) {
      var element = this.getSelectedElement();
      var hasDynamicElement = false;
      if(this.isDynamicElement(element)) {
        this.selectDynamicElement(element);
        hasDynamicElement = this.isDynamicElement(element);
      } else {
        hasDynamicElement = this.doesSelectionContainDynamicTags();
      }

      if(hasDynamicElement && removeFunction) {
        removeFunction(shiftEnter);
      }

    },

    doesSelectionContainDynamicTags: function() {
      var sel               = this.getSelection(),
          range             = sel.getRangeAt(0).cloneRange(),
          selectionContents = jQuery(range.cloneContents());
      return selectionContents.children('ub\\:dynamic').length > 0;
    },

    removeOnDelete: function(which) {

      if(this.isSelectionInDynamicTag()) {
        this._removeSelectedUBDynamicTag();
        return;
      }

      if(!this._isFirefox()){return;}

      var sel = this.getSelection();

      //No need for any of this code if we don't have a selection
      //or if we have an uncollapsed selection.
      if(!sel || this.isUncollapsedSelection(sel)) {return;}

      var range     = sel.getRangeAt(0),
          node      = this._searchForDynamicNodeByRange(range, which);

      //Get the next or previous sibling.
      if (!this.isDynamicElement(range.startContainer) && which === 'next') {
        node = node || range.startContainer[which + 'Sibling'];
      }

      if(!node && which === 'previous') {
        sel.modify('extend', 'left', 'character');
      } else if(!node && which === 'next') {
        sel.modify('extend', 'right', 'character');
      }
      this._selectDynamicElementForDelete(node, range, which);
    },

    insertDynamicElementIntoSelection: function(text, attributes, sanitizeCallback) {
      var dynamicElement   = jQuery(document.createElement('ub:dynamic')),
          range            = this.getRange().cloneRange(),
          startParent      = range.startContainer.parentElement,
          endParent        = range.endContainer.parentElement,
          newDynamicEl;

      if (this.isDynamicElement(startParent)){ range.setStartBefore(startParent); }
      if (this.isDynamicElement(endParent))  { range.setEndAfter(endParent); }

      dynamicElement = this.replaceDynamicContents(dynamicElement, range, text);
      dynamicElement.attr(this.appendTooltip(attributes));
      dynamicElement.attr('contenteditable', false);

      newDynamicEl = dynamicElement.get(0);
      range.insertNode(newDynamicEl);

      this._removeDynamicTags(dynamicElement);

      this.addRemoveDynamicTagButton();

      if (sanitizeCallback) { sanitizeCallback(); }

      this.selectDynamicElement(range, newDynamicEl);

      return newDynamicEl;

    },

    appendTooltip: function(attributes) {
      attributes.title = 'URL Parameter: ' + attributes.parameter;
      return attributes;
    },

    selectDynamicElement: function(range, el) {
      var sel = this.getSelection();
      if(!this.isWithinADynamicElement(el)){return;}
      try {
        range.selectNodeContents(el);
        sel.removeAllRanges();
        sel.addRange(range);
      } catch(e){}
    },

    //Removing is done this way so that way the editable element and ckeditor
    //can maintain their undo stack.
    removeClickedTag: function(tag, removeFunction) {
      try {
        var range = document.createRange();
        this.selectDynamicElement(range, tag);
        removeFunction();
      } catch(e) { }
    },

    isUncollapsedSelection: function(userSelection) {
      return !userSelection.isCollapsed;
    },

    insertTextAtSelection: function(text) {
      var selection = this.getSelection(),
          range     = selection.getRangeAt(0),
          textNode  = document.createTextNode(text);

      range.deleteContents();
      range.insertNode(textNode);
      range.setStart(textNode, textNode.length);
      range.setEnd(textNode, textNode.length);

      selection.removeAllRanges();
      selection.addRange(range);
    },

    documentFragmentToElement: function(fragment) {
      var div = document.createElement('div');
      div.appendChild(fragment.cloneNode(true));
      return div;
    },

    //PRIVATE

    _removeDynamicTags: function(el) {
      el = jQuery(el);

      var dynamicParent   = el.parents('ub\\:dynamic'),
          dynamicChildren = el.children('ub\\:dynamic');

      if (dynamicParent.length > 0) {
        el.unwrap().siblings('.dynamic-tag-close').remove();
      } else if (dynamicChildren.length > 0) {
        dynamicChildren.contents().unwrap();
      }
    },

    _getDeepestChild: function(el) {
      var ch = jQuery(el).children(':not(.dynamic-tag-close)');
      if(ch.length < 1) {return jQuery(el);}

      return this._getDeepestChild(ch[0]);
    },

    _selectDynamicElementForDelete: function(node, range, which) {
      var tempRange = range.cloneRange(),
          location,
          method;

      if(which === 'next') {
        location = 'end';
        method = 'setStart';
      } else {
        location = 'start';
        method = 'setEnd';
      }

      if(node && this.isWithinADynamicElement(node)) {
        tempRange.selectNode(node);
        tempRange[method](range[location + 'Container'], range[location + 'Offset']);

        if(tempRange.toString().length - node.textContent.length < 1) {
          this._removeSelectedTagByRangeAndNode(range, node);
        }
      }
    },

    _removeSelectedUBDynamicTag: function() {
      var selection = this.getSelection(),
          range     = selection.getRangeAt(0),
          node      = selection.anchorNode;

      this._removeSelectedTagByRangeAndNode(range, node);
    },

    _removeSelectedTagByRangeAndNode: function(range, node) {
      this.selectDynamicElement(range, node);
      var selectedElement = this.isWithinADynamicElement(node) ? node : this.getSelectedElement();
      jQuery(this._getDynamicTagFromNode(selectedElement)).remove();
    },

    _searchForDynamicNodeByRange: function(range, which) {
      var node,
          container = range.startContainer;

      var matchDynamic = function(el) {
        return (el && el.nodeName === 'UB:DYNAMIC');
      };

      if(this._isContainerEditable(container) || this._isContainerParagraph(container)) {

        node = jQuery(container).contents()[range.startOffset - 1];

        if(node && (node.nodeType === 3 || node.nodeName !== 'UB:DYNAMIC')) {
          node = this._search(node[which + 'Sibling'], matchDynamic,'lastChild');
        }
      }
      return node;
    },

    _isContainerParagraph: function(container) {
      return container.tagName === 'P';
    },

    _isContainerEditable: function(container) {
      return jQuery(container).attr('class') === 'contenteditable';
    },

    _search: function(node, searchFn, direction) {
      if (searchFn(node)) { return node; }
      if (node){
        return this._search(node[direction], searchFn, direction);
      }
    },

    _getClosestPreviousSibling: function(base) {
      if(this._isParentBlockElement(base)) { return base; }

      if (base.previousSibling) {
        return base;
      } else if (base.parentElement) {
        return this._getClosestPreviousSibling(base.parentElement);
      }
      return base;
    },

    _getClosestNextSibling: function(base) {
      if(this._isParentBlockElement(base)) { return base; }

      if (base.nextSibling && !jQuery(base).hasClass('dynamic-tag-close')) {
        return base;
      } else if (base.parentElement) {
        return this._getClosestNextSibling(base.parentElement);
      }
      return base;
    },

    _isParentBlockElement: function(node) {
      var blockElements = [
        'DIV', 'P', 'UL', 'OL', 'DD', 'DL', 'PRE',
        'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'TABLE'
      ];
      var parentNodeTagName = jQuery(node.parentElement).prop('tagName');
      return jQuery.inArray(parentNodeTagName.toUpperCase(), blockElements) > -1;
    },


    _isFirefox: function() {
      return Prototype.Browser.Gecko;
    },

    _getSelectedText: function(element) {
      return jQuery('<div>').append(jQuery(element).clone()).text();
    },

    _handleSelection: function(userSelection, startNode, endNode) {
      var currentRange = userSelection.getRangeAt(0).cloneRange(),
      range        = document.createRange();


      if (startNode) {
        var container;
        range.selectNode(startNode);
        if (range.startOffset === 0) {
          container = this._getDynamicContainer(range.startContainer);
        } else {
          container = range.startContainer;
        }
        currentRange.setStart( container, range.startOffset);

      }

      if (endNode) {
        range.selectNode(endNode);
        currentRange.setEnd(range.endContainer, range.endOffset);
      }

      userSelection.removeAllRanges();
      userSelection.addRange(currentRange);
    },

    _getDynamicContainer: function(container) {

      if (!this.isDynamicElement(container)) {
        return jQuery(container).children('ub\\:dynamic').get(0);
      }

      return container;
    },

    _getDynamicTagFromNode: function(node) {
      if(!this.isDynamicElement(node)) {
        return jQuery(node).parents('ub\\:dynamic').get(0);
      }
      return node;
    },

    _prevent: function(e) {
      if(e.cancel) {
        e.cancel();
      } else {
        e.stop();
      }
    }

  };
})();

