/* globals exports, module, define */
(function(root, fn) {
  if (typeof exports === 'object') {
    var MobileTourTemplateBuilder = fn(require('jQuery'), require('lodash'));
    exports.MobileTourTemplateBuilder = exports.MobileTourTemplateBuilder || {};
    exports.MobileTourTemplateBuilder = MobileTourTemplateBuilder;
    module.exports = exports;
  } else if (typeof 'define' === 'function' && define.amd) {
    define(['jQuery', 'lodash'], fn);
  } else {
    root.lp = root.lp || {};
    root.lp.MobileTourTemplateBuilder = fn(jQuery, _);
  }
})(this, function(jQuery, _) {
  var MobileTourTemplateBuilder = function() {};

  MobileTourTemplateBuilder.prototype.beforeRender = function() {};

  MobileTourTemplateBuilder.prototype.render = function() {
    return jQuery('#root-container-editor').append(
      '<div class="feature-tour" data-role="mobile-tour-overlay">' +
      '  <div class="tour-content overlay-dialog">' +
      '    <section class="overlay-wrapper">' +
      '      <header class="page-blocks-toolbar">' +
      '        <h2>Ready to make your page Responsive?</h2>' +
      '      </header>' +
      '      <section class="overlay-content with-footer no-bottom-padding">' +
      '        <section class="standard-form overlayed">' +
      '          <section class="overlay-slides">' +
      '            <!--Slide #1-->' +
      '            <section class="overlay-slide" style="display: block">' +
      '              <span class="right-col">' +
      '                <img src="//d2y5whbil7yeo8.cloudfront.net/builder_assets/template_images/feature-tour/step-1.png">' +
      '              </span>' +
      '              <span class="left-col">' +
      '                <div class="nav-dots">' +
      '                  <div class="dot active"></div>' +
      '                  <div class="dot"></div>' +
      '                  <div class="dot"></div>' +
      '                  <div class="dot"></div>' +
      '                </div>' +
      '                <span class="slide-header">Show the Mobile Version to Your Visitors</span>' +
      '                <span class="slide-content">' +
      '                  When you’re ready for mobile visitors to ' +
      '                  see the mobile version of your landing ' +
      '                  page, select “Show Mobile Version to ' +
      '                  Visitors” from your Page Properties Pane ' +
      '                  on the right. Then publish (or republish) ' +
      '                  your page.'+
      '                </span>' +
      '              </span>' +
      '            </section>' +
      '            <!--Slide #2-->' +
      '            <section class="overlay-slide" style="display: none">' +
      '              <span class="right-col">' +
      '                <img src="//d2y5whbil7yeo8.cloudfront.net/builder_assets/template_images/feature-tour/step-2.png">' +
      '              </span>' +
      '              <span class="left-col">' +
      '                <div class="nav-dots">' +
      '                  <div class="dot"></div>' +
      '                  <div class="dot active"></div>' +
      '                  <div class="dot"></div>' +
      '                  <div class="dot"></div>' +
      '                </div>' +
      '                <span class="slide-header">Scale the Text in Your Mobile Version</span>' +
      '                <span class="slide-content">' +
      "                  Click your text box to reveal it's " +
      '                  Properties Pane on the right. Then use ' +
      '                  the scale bar to resize the text.' +
      '                </span>' +
      '              </span>' +
      '            </section>' +
      '            <!--Slide #3-->' +
      '            <section class="overlay-slide" style="display: none">' +
      '              <span class="right-col">' +
      '                <img src="//d2y5whbil7yeo8.cloudfront.net/builder_assets/template_images/feature-tour/step-3.png">' +
      '              </span>' +
      '              <span class="left-col">' +
      '                <div class="nav-dots">' +
      '                  <div class="dot"></div>' +
      '                  <div class="dot"></div>' +
      '                  <div class="dot active"></div>' +
      '                  <div class="dot"></div>' +
      '                </div>' +
      '                <span class="slide-header">Toggle the visibility of your page contents</span>' +
      '                <span class="slide-content">' +
      '                  Click the eye in your Page Contents' +
      '                  to toggle the visibility of any object' +
      '                  on your page for either mobile or' +
      '                  desktop.' +
      '                </span>' +
      '              </span>' +
      '            </section>' +
      '            <!--Slide #4-->' +
      '            <section class="overlay-slide" style="display: none">' +
      '              <span class="right-col">' +
      '                <img src="//d2y5whbil7yeo8.cloudfront.net/builder_assets/template_images/feature-tour/step-4.png">' +
      '              </span>' +
      '              <span class="left-col">' +
      '                <div class="nav-dots">' +
      '                  <div class="dot"></div>' +
      '                  <div class="dot"></div>' +
      '                  <div class="dot"></div>' +
      '                  <div class="dot active"></div>' +
      '                </div>' +
      '                <span class="slide-header">Want to learn more about responsive?</span>' +
      '                <span class="slide-content">' +
      '                  Check out these' +
      '                  <a href="http://support.unbounce.com/forums/21418750" target="_blank">support articles</a>' +
      '                  on getting the most out of' +
      '                  mobile responsive for your ' +
      '                  campaigns.' +
      '                </span>' +
      '              </span>' +
      '            </section>' +
      '          </section>' +
      '        </section>' +
      '      </section>' +
      '      <footer>' +
      '        <div class="left-controls">' +
      '          <input id="dont-show" type="checkbox" data-role="seen-mobile-tour-checkbox">' +
      '          <label for="dont-show">Don\'t show this message again</label>' +
      '        </div>' +
      '        <div class="right-controls clearfix actions">' +
      '          <a href="javascript:void(0)" class="btn large blue right continue"><span>Continue</span></a>' +
      '          <a href="javascript:void(0)" class="btn large light right back disabled margin-right">Back</a>' +
      '        </div>' +
      '      </footer>' +
      '    </section>' +
      '    <div title="Close" class="dynamic-close" data-role="close-mobile-tour-button"></div>' +
      '  </div>' +
      '  <div class="overlay"></div>' +
      '</div>'
    );
  };

  MobileTourTemplateBuilder.prototype.afterRender = function() {
    jQuery('.nav-dots .dot').click(function() {
      var dotClick = jQuery(this).index();
      jQuery('.overlay-slide').hide().eq(dotClick).show();
      var total = jQuery(".overlay-slide").length - 1;
      var current = jQuery(".overlay-slide:visible").index();
      if(current === 0){
        jQuery('footer .back').addClass('disabled');
      }else{
        jQuery('footer .back').removeClass('disabled');
      }
      if (current === total) {
        jQuery('footer .continue span').text('Done');
      }else{
        jQuery('footer .continue span').text('Continue');
      }
    });

    jQuery('.feature-tour footer .continue').click(function() {
      var total = jQuery(".overlay-slide").length - 1;
      var current = jQuery(".overlay-slide:visible").index();
      jQuery('footer .back').removeClass('disabled');
      if (current === (total-1)) {
        jQuery('footer .continue span').text('Done');
      }else{
        jQuery('footer .continue span').text('Continue');
      }
      if (current === total) {
        jQuery('.feature-tour').remove();
      } else {
        jQuery('.overlay-slide').hide().eq(current + 1).show();
      }
    });

    jQuery('.feature-tour footer .back').click(function(){
      var current = jQuery(".overlay-slide:visible").index();
      if(current === 0){
        return;
      }
      if(current !== 0){
        jQuery('.overlay-slide').hide().eq(current - 1).show();
      }
      if(current === 1){
        jQuery('footer .back').addClass('disabled');
      }else{
        jQuery('footer .back').removeClass('disabled');
      }
      jQuery('footer .continue span').text('Continue');
    })
  };

  return MobileTourTemplateBuilder;
});
