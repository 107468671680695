lp.pom.updates.add(Object.extend({

  minVersion: '2.0',

  version: '2.1',

  run: function(jso) {
    if(this.checkVersion(jso)) {
      this.removeProtocolOnHostedScripts(jso);
      jso.version = this.version;
    }
  },

  removeProtocolOnHostedScripts: function(jso) {
    var hostedScripts = this.getHostedScripts(jso);
    hostedScripts.each(function(el){
      var newScriptPath = el.content.html.replace(/(https?:)/i, '');
      el.content.html = newScriptPath;
    });
  },

  getHostedScripts: function(jso) {
    return jso.elements.findAll(function(el){
      return el.type === 'lp-script' && el.predefined === true;
    });
  }
}, lp.pom.updater));
