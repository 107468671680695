/* globals exports, module, define */
(function(root, fn) {
  var fakeLocalStore = {
    _data: {},
    setItem: function(id, val) {
      return this._data[id] = String(val);
    },
    getItem: function(id) {
      return this._data.hasOwnProperty(id) ? this._data[id] : undefined;
    },
    removeItem: function(id) {
      return delete this._data[id];
    }
  };

  var _getStore = function() {
    return root.hasOwnProperty('localStorage') ? root.localStorage : fakeLocalStore;
  };

  var _getTempStore = function() {
    return fakeLocalStore;
  };

  if (typeof exports === 'object') {
    exports.localStore = _getStore();
    exports.tempStore = _getTempStore();

    var NewFeatureTour = fn(require('jQuery'), require('lodash'), require('localStore'), require('tempStore'), require('FakeTourTemplateBuilder'), require('NewFeatureTourValidationError'));
    exports.NewFeatureTour = NewFeatureTour;
    module.exports = exports;
  } else if (typeof 'define' === 'function' && define.amd) {
    define('localStore', [], function() {
      return _getStore();
    });

    define('tempStore', [], function() {
      return _getTempStore();
    });

    define(['jQuery', 'lodash', 'localStore', 'tempStore', 'FakeTourTemplateBuilder', 'NewFeatureTourValidationError'], fn);
  } else {
    root.lp = root.lp || {};
    root.lp.NewFeatureTour = fn(jQuery, _, _getStore(), _getTempStore(), lp.FakeTourTemplateBuilder, lp.NewFeatureTourValidationError);
  }
})(this, function(jQuery, _, Store, TempStore, FakeTourTemplateBuilder, NewFeatureTourValidationError) {
  var NewFeatureTour = function(name, options) {
    this.name = name;
    this.openSelectorRole = options.openSelectorRole;
    this.closeSelectorRole = options.closeSelectorRole;
    this.inputSelectorRole = options.inputSelectorRole;
    this.containerSelectorRole = options.containerSelectorRole;
    this.cssClass = options.cssClass;
    this.templateBuilderConstructor = _.isFunction(options.templateBuilderConstructor) ? options.templateBuilderConstructor : FakeTourTemplateBuilder;
  };

  NewFeatureTour.prototype = (function() {
    var _getDisplayEventStream = function($openElm) {
      return new Rx.Observable.fromEvent($openElm, 'click');
    };

    var _getCloseEventStream = function($closeElm) {
      return new Rx.Observable.fromEvent($closeElm, 'click');
    };

    var _getInputEventStream = function($inputElm) {
      return Rx.Observable.fromEvent($inputElm, 'change');
    };

    var _getEventStream = function() {
      var self = this;
      return Rx.Observable.merge(
        _getDisplayEventStream(this.$openElm),
        _getCloseEventStream(this.$closeElm),
        _getInputEventStream(this.$inputElm)
      ).map(function(event) {
        var res = {};
        if(_.isBoolean(event.target.checked)) { res.persistState = event.target.checked; }
        if(event.target.dataset.role === self.$openElm.data("role")) { res.tempOpen = true; }
        if(event.target.dataset.role === self.$closeElm.data("role")) { res.tempClose = true; }
        return res;
      });
    };

    var _getStorageKey = function(name) { return name; };

    var _getTempStorageKey = function(name) { return name + "-temp"; };

    var persistState = function(state) {
      Store.setItem(_getStorageKey(this.name), state);
    };

    var persistTempState = function() {
      TempStore.setItem(_getTempStorageKey(this.name), true);
    };

    var toggleView = function(state) {
      var flag = this.isVisible() && this.isTemporarilyVisible();
      this.$containerElm.toggleClass(this.cssClass, flag);
    };

    var eventNameToHandlersMap = {
      persistState: [persistState],
      tempOpen: [toggleView],
      tempClose: [persistTempState, toggleView]
    };

    var _tourToggler = function(flag) {
      var self = this;

      if (flag) {
        self._subscription = self._eventStream.subscribe(
          function(data) {
            _.each(data, function(val, key) {
              _.each(eventNameToHandlersMap[key], function(fn) {
                fn.apply(self, [val]);
              });
            });
          }
        );
      } else {
        self._subscription && self._subscription.dispose();
      }

      return self;
    };

    return {
      isValid: function() {
        this.errors = {};
        if(_.isUndefined(this.name)) {
          this.errors.name = "is missing";
        }
        if(_.isEmpty(this.$openElm)) {
          this.errors.openElm = "is missing";
        }
        if(_.isEmpty(this.$closeElm)) {
          this.errors.closeElm = "is missing";
        }
        if(_.isEmpty(this.$inputElm)) {
          this.errors.inputElm = "is missing";
        }
        if(_.isEmpty(this.$containerElm)) {
          this.errors.containerElm = "is missing";
        }
        return _.isEmpty(this.errors);
      },

      init: function() {
        this.templateBuilder = new this.templateBuilderConstructor();
        this.templateBuilder.beforeRender();
        this.templateBuilder.render();
        this.$openElm = jQuery("[data-role='" + this.openSelectorRole + "']");
        this.$closeElm = jQuery("[data-role='" + this.closeSelectorRole + "']");
        this.$inputElm = jQuery("[data-role='" + this.inputSelectorRole + "']");
        this.$containerElm = jQuery("[data-role='" + this.containerSelectorRole + "']");
        this.templateBuilder.afterRender();
        this._eventStream = this._eventStream || _getEventStream.call(this);
        return this;
      },

      enable: function() {
        _tourToggler.call(this, true);
      },

      disable: function() {
        _tourToggler.call(this, false);
      },

      isVisible: function() {
        return Store.getItem(_getStorageKey(this.name)) !== 'true';
      },

      isTemporarilyVisible: function() {
        return TempStore.getItem(_getTempStorageKey(this.name)) !== 'true';
      }
    };
  })();

  return NewFeatureTour;
});
