lp.module.text.TextElement = Class.create(
  lp.pom.VisibleElement,
  lp.ModuleComponent,
  {
    type:'lp-pom-text',

    initialize: function($super, page, jso){
      if (page.context === lp.pom.context.EDIT) {
        delete jso.geometry.size.height;
      }

      this.autoUpdateHeight = true;
      this.updatingHeight   = false;

      $super(page, jso);
    },

    getModelClass: function() {
      return lp.module.text.TextModel;
    },

    createDefaultConstraints: function($super) {
      $super();
      this.defaultConstraints.opacityable = true;
      this.defaultConstraints.scalable = true;
      this.defaultConstraints.height_resizeable = false;
    },

    initView: function(){
      var errorCaught = false;
      var error = null;
      try {
        this.update(this.model.exists('content.text') ?
                    this.model.get('content.text') : '');

        if (this.page.context === lp.pom.context.PUBLISH ||
            this.page.context === lp.pom.context.PREVIEW) {
          this.CTAifyLinks();
        } else {
          this.clearClickEventsAttachedToLinks();
        }

        if (this.isBetterLineHeight()) {
          this.getViewDOMElement().addClassName('nlh');
        }

      } catch(e) {
        errorCaught = true;
        error = e;
      }

      this.handleErrors(errorCaught, error, null);
    },

    isBetterLineHeight: function() {
      return this.model.exists('betterLineHeight') &&
        this.model.get('betterLineHeight') === true;
    },

    //Removes the onclick event added by ckeditor if the link type
    //is selected to popup.
    clearClickEventsAttachedToLinks: function() {
      this.view.e.select('a').each(function(a){
        if(a.onclick) {
          a.removeAttribute('onclick');
        }
      });
    },

    CTAifyLinks: function() {
      this.view.e.select('a').each(function(a){
        if (a.href) {
          a.href = this.page.CTAifyLink(a.href);
        }

        if (!a.target && this.page.usedAs() === 'form_confirmation') {
          a.target = '_parent';
        }
      }, this);
    },

    modelChanged: function($super, e) {
      var details = $super(e);
      if (details.accessor === 'content.text') {
        this.view.e.update();
        this.view.e.insert(details.value);
        this.view.e.style.height = null;
        if(this.page.context === lp.pom.context.EDIT) {
          this.clearClickEventsAttachedToLinks();
        }
      }
    },

    setDimensions: function($super, dims) {
      if (this.updatingHeight) {
        return;
      }

      if (this.hasBorder() && this.model.get('geometry.borderLocation') === 'inside') {
        var adjust = this.getBorderWidth() * 2;
        dims = {width:dims.width - adjust, height:dims.height - adjust};
      }

      if (this.page.context === lp.pom.context.EDIT) {
        this.setWidth(dims.width);
        this.updateHeight();
      } else {
        $super(dims);
      }
    },

    getContentHeight: function() {
      var height;
      if (this.hasBorder()) {
        height = this.getHeight() - this.getBorderWidth() * 2;
      } else {
        height = this.getHeight();
      }

      return Math.round(height * this.model.getScale());
    },

    isHeightResizeable: function() {
      return false;
    },

    updateHeight: function() {
      this.updatingHeight = true;
      this.getViewDOMElement().style.height = 'auto';
      var height = this.getHeight();
      if(height > 0) {
        this.model.setHeight(height);
      }
      this.updatingHeight = false;
    },
    getText: function() {
      return this.model.get('content.text');
    },

    getFonts: function() {
      return this.model.getValuesFromBothBreakpoints('content.fonts');
    },

    setText: function(t, undoManager) {
      var errorCaught = false;
      var error = null;
      var hasChanged = this.model.get('content.text') !== t;
      try {
        this.model.set('content.text', t, undoManager);
      } catch(e) {
        errorCaught = true;
        error = e;
      }
      this.handleErrors(errorCaught, error, hasChanged, undoManager);

      if (this.page.context === lp.pom.context.EDIT) {
        this.updateHeight();
      }
    },

    handleErrors: function(errorCaught, error, hasChanged, undoManager) {
      if(errorCaught) {
        this.model.set('content.valid', false, undoManager);
        this.model.set('content.errors', [error ? error.toString() : ''], undoManager);
      } else if(hasChanged) {
        this.model.set('content.errors', [], undoManager);
        this.model.set('content.valid', true, undoManager);
      }
    },

    setFonts: function(fonts, undoManager) {
      this.model.set('content.fonts', fonts, undoManager);
    },

    canHaveGoals: function() {
      return true;
    },

    getGoals: function() {
      return this.view.e.select('a').collect(function(a){
        if ((a.href === '' && a.name !== '') ||
            a.href.startsWith(window.location.href+'#') ||
            a.href.startsWith('mailto:')) {
          return null;
        }
        return {type:'link', url:a.href};
      }).compact();
    },

    deactivate: function() {
      this.fireEvent('goalChanged', this);
    }
  }
);

lp.module.text.TextElement.elementDefaults = {
  name: 'Text',
  betterLineHeight: true,
  style: {
    background: {
      backgroundColor: 'fff',
      opacity: 0
    }
  },
  geometry:{
    position:'absolute',
    offset:{top:0,left:0},
    size: { height:0, width:300 },
    borderLocation:'outside'
  },
  content:{
    text:'<p>Double Click to edit this text</p>',
    valid: true,
    errors: [],
    fonts: []
  }
};
