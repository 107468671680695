lp.pom.updates.add({
  minVersion:'1.1',
  version:'1.2',
  run: function(jso) {
    var current = jso.version || '0';
    if (current >= this.version) {
      return;
    }

    if (current < this.minVersion) {
      throw "Page version "+current+" can't be updated to version "+this.version;
    }

    // fixes page defaultWidth setting that maybe a string
    if (jso.settings.defaultWidth) {
      jso.settings.defaultWidth = jso.settings.defaultWidth * 1;
    }

    // fixes section widths that are stored as strings
    jso.elements.each(function(e) {
      switch (e.type) {
        case 'lp-pom-block':
          e.geometry.size.width = e.geometry.size.width * 1;
          break;
      }
    }, this);

    jso.version = this.version;
  }
});
