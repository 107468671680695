/* globals rx_switchboard */
lp.editor.panel = lp.editor.panel || {};
lp.editor.panel.ElementPropertiesPanel = Class.create(jui.Component, {
  options: function($super,options){
    return $super($H({attributes:{className:'element-properties-panel'}}).merge(options));
  },

  initialize: function($super, options) {
    this._trace = rx_switchboard.getPublisher('property_panels');

    this.controls = {};
    this.modelChangeHandlers = [];
    this.active = false;
    this.updatingModel = false;
    this.advancedPanel = new jui.CollapsiblePanel({title:'Advanced'});
    this.advancedDefaultContent = this.advancedPanel.insert(new jui.Component());
    $super(options);
    this.advancedPanel = this.insert(this.advancedPanel);
  },

  installUI: function($super) {
    this._trace({type: ".installUI", ctx: this, args: arguments});
    $super();
    this.title = this._insert(new Element('h1'));
    this.content = this._insert(new jui.Component({attributes:{className:'content'}}));
  },

  insert: function(c){
    return this.content.insert(c);
  },

  attributeChanged: function(e) {
    if (this.updatingModel) {
      return;
    }
    this.modelChangeHandlers.each(function(h) {h(e);});

    if(e.data.accessor === 'geometry.visible') {
      this.handleDisablePanel();
    }
  },

  isDisabled: function() {
    if(this.element && this.element.isVisibleOnPage) {
      return !this.element.isVisibleOnPage();
    }
    return false;
  },

  syncUIControls: function() {
    this._trace({type: ".syncUIControls", ctx: this, args: arguments});
    this.syncTitlePanel();
    this.syncAdvancedPanel();
  },

  handleDisablePanel: function() {
    this.disablePanelOverlay = this.disablePanelOverlay || this._insert(new jui.Component({
      attributes:{
        className:'disable-panel-overlay'
      }
    }));


    var _adjustForVisibilityMsg = function(el) {
      var toggleMsg = jQuery('.visibility-toggle-message');
      if(toggleMsg.length > 0) {
        jQuery(el).toggleClass('msg', !toggleMsg.hasClass('closed'));
      }
    };

    var _adjustOverflowOfContentPanel = function(el, value) {
      jQuery(el).children('.content').css('overflow-y', value);
    };

    if(this.isDisabled()) {
      this.disablePanelOverlay.show();
      _adjustForVisibilityMsg(this.disablePanelOverlay.e);
      _adjustOverflowOfContentPanel(this.e, 'hidden');
    } else {
      this.disablePanelOverlay.hide();
      _adjustOverflowOfContentPanel(this.e, 'scroll');
    }
  },

  syncTitlePanel: function() {
    this.title.update(this.element.name);
    this.title.className = this.element.type;
  },

  syncAdvancedPanel: function() {
    // TODO: get rid of string interpolation in favour of something simpler
    var pattern = '<div class="form-elm"><label>#{label}</label><input type="text" class="small" value="#{value}" readonly="readonly" /></div>';

    this.advancedDefaultContent.update(
      pattern.interpolate({label:'Id', value:'#'+this.element.id}) +
      pattern.interpolate({label:'Class', value:'.'+this.element.type}) +
      (this.element.parentElement && window.editor.admin ?
        pattern.interpolate({
          label: 'Parent',
          value: this.element.parentElement.name+': '+this.element.parentElement.id
          }) :
        '') +
      (window.editor.admin ?
        pattern.interpolate({label:'Z-Index', value:this.element.model.getZIndex() || 'undefined'}) :
        '')
      );
  },

  setElement: function(element) {
    this._trace({type: ".setElement", ctx: this, args: arguments});
    this.removeAttributeChangedListener();
    if (lp.isResponsiveEnabled()){
      this.removePageUpdatedAndRefreshedListener();
    }
    this.element = element;
    this.addAttributeChangedListener();
    if (lp.isResponsiveEnabled()){
      this.addPageUpdatedAndRefreshedListener();
    }

    if (this.parent !== null){
      this.syncUIControls(element);
    }

  },

  //breakpointChanged

  removeAttributeChangedListener: function() {
    if (this.element && this.element.model) {
      this.element.model.removeListener('attributeChanged', this);
    }
  },

  addAttributeChangedListener: function() {
    if (this.element.model){
      this.element.model.addListener('attributeChanged', this);
    }
  },

  removePageUpdatedAndRefreshedListener: function() {
    if (this.element) {
      this.element.page.removeListener('pageUpdatedAndRefreshed', this);
    }
  },

  addPageUpdatedAndRefreshedListener: function() {
    if (this.element) {
      this.element.page.addListener('pageUpdatedAndRefreshed', this);
    }
  },

  pageUpdatedAndRefreshed: function(){
    if (this.parent !== null || this.type === 'lp-pom-root') {
      this.syncUIControls(this.element);
    }
  },

  requestFocus: function(e){
    window.editor.keyController.requestFocus(e.data, this);
  },

  activate: function() {
    this.active = true;
    var elm = window.editor.activeElement;
    if (elm.type === this.type && this.element !== elm) {
      this.setElement(elm);
    }

    if(this.isCurrentElementSyncable()) {
      this.syncUIControls(this.element);
    }

    this.handleDisablePanel();

    this.fireEvent('activated');
  },

  isCurrentElementSyncable: function() {
    return !!this.element.model;
  },

  deactivate: function() {
    this.active = false;
    this.fireEvent('deactivated');
  },

  performModelUpdates: function(updates) {
    this.updatingModel = true;
    updates();
    this.updatingModel = false;
  }
});
