lp.module.script.ScriptElement = Class.create(
  lp.pom.Element,
  lp.ModuleComponent,
  {
    type:'lp-script',

    initialize: function($super, page, jso, context, options) {
      $super(page, jso, context, options);
      if (this.page.context === lp.pom.context.PUBLISH ||
          this.page.context === lp.pom.context.PREVIEW) {
        this.page.addInsertion(
          this.model.get('content.html'),
          this.model.get('placement')
        );
      }
    },

    createDefaultConstraints: function($super) {
      $super();
      this.defaultConstraints.displayable = false;
      this.defaultConstraints.copyable = false;
    }
  }
);

lp.module.script.ScriptElement.elementDefaults = {
  name: 'Javascript',
  containerId: null,
  content: {
    type: null,
    valid: true,
    errors: []
  }
};
