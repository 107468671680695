/* globals exports, module, define */
(function(root, fn) {
  if (typeof exports === 'object') {
    var FakeTourTemplateBuilder = fn(require('jQuery'), require('lodash'));
    exports.FakeTourTemplateBuilder = exports.FakeTourTemplateBuilder || {};
    exports.FakeTourTemplateBuilder = FakeTourTemplateBuilder;
    module.exports = exports;
  } else if (typeof 'define' === 'function' && define.amd) {
    define(['jQuery', 'lodash'], fn);
  } else {
    root.lp = root.lp || {};
    root.lp.FakeTourTemplateBuilder = fn(jQuery, _);
  }
})(this, function(jQuery, _) {
  var FakeTourTemplateBuilder = function() {};

  FakeTourTemplateBuilder.prototype = {
    beforeRender: function() {},
    render: function() {
      return '';
    },
    afterRender: function() {}
  }

  return FakeTourTemplateBuilder;
});
