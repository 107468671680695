lp.editor.SelectionBox = Class.create( jui.Component, {
  options: function($super,options){
    return $super($H({attributes:{className:'transform-box'}}).merge(options));
  },

  initialize: function( $super, elm, container){
    this.elm = null;
    $super();
    this.elmModelListener = this.elementModelChanged.bind(this);

    var self = this;
    var adjustmentListener = function() {
      if (self.elm !== null && self.elm.type !== 'lp-pom-root') {
        self.positionBoundingBox();
      }
    };

    window.editor.addListener('layoutChanged', adjustmentListener);

    container.insert(this);
    this.setElement(elm);
  },

  installUI: function( $super ) {
    $super();
    this.installBoundingBox();
    this.setOpacity(0.3);
  },

  installBoundingBox: function() {
    this.tbndry = this.insert( new Element('div', {className:'transform-boundary top'}));
    this.rbndry = this.insert( new Element('div', {className:'transform-boundary right'}));
    this.bbndry = this.insert( new Element('div', {className:'transform-boundary bottom'}));
    this.lbndry = this.insert( new Element('div', {className:'transform-boundary left'}));
  },

  setElement: function( elm ) {
    if (this.elm !== null) {
      // this.bmh.e.removeClassName('section');
      this.removeElementListeners();
    }

    this.elm = elm;

    if (this.elm && this.elm.is('displayable')) {
      this.addElementListeners();
      this.positionBoundingBox();
      this.show();
    } else {
      this.hide();
    }
  },

  addElementListeners: function(){
    this.elm.model.addListener('attributeChanged', this.elmModelListener);
    this.elm.addListener('parentChanged', this.elmModelListener);
  },

  removeElementListeners: function(){
    this.elm.model.removeListener('attributeChanged', this.elmModelListener);
    this.elm.removeListener('parentChanged', this.elmModelListener);
  },

  elementModelChanged: function() {
    this.positionBoundingBox();
    // this.positionGhost();
  },

  contenttWidthChanged: function() {
    if (this.elm === null || this.elm.type === 'lp-pom-root') {
      return;
    }
    this.positionBoundingBox();
  },

  positionBoundingBox: function(offset) {
    /* jshint unused:vars */
    if (this.elm === null) {
      return;
    }

    this.setOffset(this.elm.getPageOffset());

    var w = this.elm.getContentWidth();
    var h = this.elm.getContentHeight();

    this.tbndry.style.width = w+'px';
    this.rbndry.style.height = h+'px';
    this.rbndry.style.left = (w-1)+'px';
    this.bbndry.style.width = (w-1)+'px';
    this.bbndry.style.top = (h-1)+'px';
    this.lbndry.style.height = (h-1)+'px';
  }
});
