/* globals exports, module, define */
;(function(root, fn) {
  if (typeof exports === 'object') {
    var ElementProtrusionDetection = fn(require('lodash'));
    exports.ElementProtrusionDetection = exports.ElementProtrusionDetection || {};
    exports.ElementProtrusionDetection = ElementProtrusionDetection;
    module.exports = exports;
  } else if (typeof 'define' === 'function' && define.amd) {
    define(['lodash'], fn);
  } else {
    root.lp.editor.ElementProtrusionDetection = fn(_);
  }
})(this, function(_) {
  var ElementProtrusionDetection = {};

    var initBbox = {l:0, r:0};

    var _getSingleBoundingBox = function(parentBBox, elm) {
      return {
        r: (elm.l + elm.w) + parentBBox.l,
        l: elm.l + parentBBox.l,
        id: elm.id
      };
    };

    var _getBoundingBoxes = function(parentBBox, elm) {
      var ownBbox = _getSingleBoundingBox(parentBBox, elm);
      return [
        ownBbox,
        _.map(elm.children, function(child){
          return _getBoundingBoxes(ownBbox, child);
        })
      ];
    };

    var _getRelativeBoundingBoxes = function(elms) {
      return _.flatten(
        _.map(elms, function(el){
        return  _getBoundingBoxes(initBbox, el);
      }));
    };

    ElementProtrusionDetection.findProtrudingElements = function(elms, containerBBox) {
      return _.filter(_getRelativeBoundingBoxes(elms), function(elmBBox){
        return elmBBox.l < 0 || elmBBox.r > containerBBox.r;
      });
    };

  return ElementProtrusionDetection;
});
