//  require <jui>
jui.FormRadioInput = Class.create( jui.Component, jui.ControlModel, {
  options: function($super,options){
    return $super($H({
      attributes:{className:'form-elm checkbox-input'},
      label:'Checkbox'
    }).merge(options));

  },

  initialize:function( $super, options ){
    $super( options );

    if ( this.options.onclick ) {
      this.addListener( 'click', this.options.onclick );
    }
  },

  installUI: function( $super ) {
    $super();
    var checkboxAttrs = {type:'radio', className:'checkbox'};

    if (this.options.checked) {
      checkboxAttrs.checked = true;
    }

    if (this.options.name) {
      checkboxAttrs.name = true;
    }

    if (this.options.value) {
      checkboxAttrs.value = true;
    }

    this.checkbox = this.insert(new Element('input', checkboxAttrs));
    this.label = this.insert( new Element('label', {className:'right'}).update(this.options.label));

    this.checkbox.observe('click',this.onClick.bind(this));
  },

  setValue: function(bool) {
    this.checkbox.checked = bool;
  },

  getValue: function() {
    return this.checkbox.checked;
  },

  onClick: function() {
    if( this.isEnabled() ) {
      this.fireEvent('click', this);
    }
  }
});
