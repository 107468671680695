;(function(){
  var tabSelector      = '.tab.lp-form'
    , messageSelector  = '.lp-form-review-msg'
    , indicatorClass   = 'lp-form-review'
    , juiEvents        = window.rx_switchboard.subjects.jui_events
    , elementSetEvents = window.rx_switchboard.subjects.element_set;

  // ui logic:
  var _getNotificationMsgElem = function () {
    return jQuery(messageSelector);
  };

  var _addBreakpointClassToTabSelector = function(breakpointName) {
    jQuery(tabSelector).removeClass('desktop').removeClass('mobile');
    jQuery(tabSelector).addClass(breakpointName);
  };

  var _addNotification = function() {
    var $notificationEl = jQuery('<div class="lp-form-review-msg" />')
      .html('Don\'t forget, this dialog can be modifed too. <a href="#" class="close-btn">x</a></div>').show();
    $notificationEl.click(function(){
      _markNotificationViewed();
    });
    jQuery('body').append($notificationEl);
    _updateNotificationPosition();
  };

  var _shouldShowNotification = function() {
    var form = window.editor.page.getForm();

    return form &&
           form.isVisibleOnPage() &&
           form.model.isConfirmationDialogUsed();
  };
  var _updateNotificationPosition = function() {
    if(_shouldShowNotification()) {
      var formConfTab = jQuery('.tab.lp-form-review');
      if (formConfTab.length > 0) {
        var formConfTabOffset = formConfTab.offset();
        _getNotificationMsgElem().css(
          {top: Math.round(formConfTabOffset.top + formConfTab.outerHeight() + 2) + 'px',
           left: Math.round(formConfTabOffset.left) + 'px'});
      }
    } else {
      _removeNotification();
    }
  };

  var _removeNotification = function () {
    jQuery(tabSelector).removeClass(indicatorClass);
    _getNotificationMsgElem().remove();
  };

  // update logic:

  var _updatePageConfirmationTab = function(mainPageRootModel, breakpoint) {
    if(!mainPageRootModel.exists('confirmationViewed') || (!mainPageRootModel.get('confirmationViewed'))) {
      jQuery(tabSelector).addClass(indicatorClass);
      _addNotification();
    } else {
      _markNotificationViewed();
    }
  };

  var _updateConfirmationPageTabByBreakpoint = function() {
    if(window.editor.page.isMain()) {
      _removeNotification();
      if (window.editor.mainPage.hasForm()) {
        var page = window.editor.mainPage
        , rootModel      = page.getRootElement().model
        , breakpointName = page.getCurrentBreakpoint().name;
        _addBreakpointClassToTabSelector(breakpointName);
        _updatePageConfirmationTab(rootModel, breakpointName);
      }
    } else {
      _markNotificationViewed();
    }
  };

  var _markNotificationViewed = function() {
    var page = window.editor.page
      , mainPageRootModel = window.editor.mainPage.getRootElement().model;

    mainPageRootModel
      .setOnWritableModelByBreakpoint(
        'confirmationViewed',
        true,
        page.getCurrentBreakpoint()
      );

    _removeNotification();
  };

  // event subscriptions:
  var updateNeededEvents = juiEvents
      .filter(function(e) {
        return (e.type === 'elementInserted' ||
                e.type === 'elementRemoved' ||
                e.type === 'breakpointChanged'
               );
      })
      .merge(elementSetEvents
        .filter(function(e){
          return e.type === 'element_model.set' &&
            (e.accessor === 'geometry.visible' ||
            e.accessor === 'content.confirmAction');
        }));

  var updateWhenNeeded = function() {
      updateNeededEvents
       .delay(20)
       .subscribe(_updateConfirmationPageTabByBreakpoint);
  };

  var markViewedOnFormConfirmationEdits = function() {
    juiEvents
      .filter(function(e) {
        return (e.type === 'activePageChanged' && e.data.current.isFormConfirmation());
      })
      .delay(10)
      .subscribe(_markNotificationViewed);
  };

  var updateMessagePositionOnLayoutChanged = function() {
    juiEvents
      .filter(function(e) {return e.type === 'layoutChanged' || e.type === 'loaded';})
      .delay(25)
      .subscribe(_updateNotificationPosition);
  };

  if(window.lp.isResponsiveEnabled()) {
    updateWhenNeeded();
    updateMessagePositionOnLayoutChanged();
    markViewedOnFormConfirmationEdits();
  }
})();
