lp.module.box.BoxElement = Class.create(
  lp.pom.VisibleElement,
  lp.ModuleComponent,
  {
    type:'lp-pom-box',

    getModelClass: function() {
      return lp.module.box.BoxModel;
    },

    createDefaultConstraints: function($super) {
      $super();
      this.defaultConstraints.container   = true;
      this.defaultConstraints.opacityable = true;
    }
  }
);

lp.module.box.BoxElement.elementDefaults = {
  name:'Box',
  style:{
    background: {
      backgroundColor: "fff"
    },
    border:{
      style:"solid",
      width:1,
      color:"ccc"
    }
  },
  geometry:{
    position:"absolute",
    offset:{top: 0,left: 0},
    size:{width:100,height:100},
    borderLocation:'inside',
    borderApply:{top:true, right:true, bottom:true, left:true},
    layout:null
  }
};
