lp.module.button.ButtonElement = Class.create(
  lp.pom.VisibleElement,
  lp.ModuleComponent,
  {
    type:'lp-pom-button',

    options: function($super,options){
      return $super($H({elementType:'a'}).merge(options));
    },

    initialize: function($super, page, jso){
      this.states = ['up', 'hover', 'active'];
      this.gradientSpecifiers = [
        '-webkit-linear-gradient',
        '-moz-linear-gradient',
        '-ms-linear-gradient',
        '-o-linear-gradient',
        'linear-gradient'
      ];
      this.activeState = this.states[0];
      $super(page, jso, {elementType:'a'});
      this.page.addListener('elementRemoved', this);
    },

    getModelClass: function() {
      return lp.module.button.ButtonModel;
    },

    isFormButton: function() {
      var parent = this.getParentElement();
      return (parent && parent.getType() === 'lp-pom-form');
    },

    initView: function(){
      /* jshint maxcomplexity:15 */
      var script;

      if (this.isPublishContext() || this.page.context === lp.pom.context.PREVIEW) {

        switch (this.model.get('action.type')) {
          case 'url':
          case '':
            this.getViewDOMElement().href = this.model.exists('action.url') ?
                          this.page.CTAifyLink(this.model.get('action.url')) :
                          '#';

            var whenEmpty = this.page.usedAs() === 'form_confirmation' ?
                            '_parent' :
                            '';

            this.getViewDOMElement().target = !this.model.exists('action.target') ?
                            whenEmpty :
                            this.model.get('action.target') === '' ?
                              whenEmpty :
                              this.model.get('action.target');

            break;
          case 'download':
            var asset = this.model.get('action.asset');
            if (asset.protected_download) {
              script = '<'+'script>'+
                         'lp.jQuery().ready(function() {'+
                           'lp.jQuery("#'+this.id+'").live( "click", function(e) {\n'+
                             'e.preventDefault();'+
                             'e.stopPropagation();'+
                             'var pw = window.parent;'+
                             'if (pw.module.lp.form.responseData && pw.module.lp.form.responseData.protectedAssets) {'+
                               'pw.location = pw.module.lp.form.responseData.protectedAssets["'+asset.uuid+'"];'+
                             '}'+
                           '});'+
                         '});'+
                      '<\/script>';

              this.page.addInsertion(script, 'head');
              this.getViewDOMElement().href = '#';
            } else {
              var asset_url;
              if(this.isPublishContext() &&
                 this.model.exists('action.asset.unique_url') ) {
                asset_url = asset.unique_url;
              } else {
                asset_url = asset.content_url;
              }
              this.getViewDOMElement().href = asset_url.split('/').last().split('?')[0];
              this.getViewDOMElement().rel = 'download';
            }
            break;
          case 'form':
            script  = '<'+'script>'+
                        'lp.jQuery().ready(function() {'+
                          'lp.jQuery("#'+this.id+'").click( function(e) {'+
                            'e.preventDefault();'+
                            'e.stopPropagation();'+
                            'lp.jQuery("div.lp-pom-form form").submit();'+
                          '});'+
                        '});'+
                      '<\/script>';

            this.page.addInsertion(script, 'head');
            this.getViewDOMElement().href = '#';
        }
      } else {
        this.getViewDOMElement.href = '#';
      }

      this.label = this.insert(new Element('span', {className:"label"}));

      if (this.model.exists('content.label')) {
        this.label.update(this.model.get('content.label'));
      }

      // JS: TODO: this is a fix for some possible model corruption due to lp-592
      // it should probably be added to an updater
      if (this.hasBorder() && !this.model.exists('style.up.border.width')){
        this.model.set('style.up.border', {style:'none'});
      }
    },

    installModelChangeHandlers: function($super) {
      $super();
      this.addModelChangeHandler( function(accessor) {
        if (accessor === 'style'){
          this.applyStyleAttributes();
        }
      });
    },

    elementRemoved: function(e) {
      if (e.data.element.type === 'lp-pom-form') {
        var m = this.model;
        if (m.exists('action.type') && m.get('action.type') === 'form') {
          m.set('action.type', 'url');
        }
      }
    },

    hasBorder: function() {
      return this.model.exists('style.up.border.style') &&
        this.model.get('style.up.border.style') !== 'none';
    },

    getBorderWidth: function() {
      return this.model.exists('style.up.border.width') ?
        this.model.get('style.up.border.width') : 0;
    },

    adjustLabelSize: function() {
      // See the TODO-TR note in updateCSSRules!
      if (this.page.context === lp.pom.context.EDIT) {
        var h = this.label.getHeight();
        this.model.set('computations.labelHeight', h);
        this.label.style.marginTop = -Math.round(h / 2) + 'px';
      } else if (!this.model.exists('computations.labelHeight')) {
        this.model.set('computations.labelHeight', this.label.getHeight());
      }
    },

    applyStyleAttributes: function($super) {
      if(!this.visible()){return;}

      $super();
      this.updateCSSRules();
      // this must come after the other css rules as it writes inline styles on EDIT context
      this.adjustLabelSize();
    },

    getFonts: function() {
      return this.model.getValuesFromBothBreakpoints('style.fontFamily');
    },

    applyBackground: function(){

    },

    updateCSSRules: function(options) {
      options = options || {};
      options.state = options.state || 'up';
      var removeAttributes = [];
      var rules = [];

      if (this.page.context === lp.pom.context.EDIT) {
        var state = (editor.activeElement === this) ?
          this.getModule().getPropertiesPanel().activeState : this.states[0];
        var addRemove = this.getCSSRulesForState(state, false);
        rules = rules.concat(addRemove.addRules);
        removeAttributes = removeAttributes.concat(addRemove.removeAttributes);
      } else {
        this.states.each(function(state) {
          rules = rules.concat(this.getCSSRulesForState(state, true).addRules);
        }, this);
      }
      if (this.page.context !== lp.pom.context.EDIT &&
          this.model.exists('computations.labelHeight')) {
        // TODO-TR: once visible_element:applyStylesToDom supports
        // setting styles on sub elements, apply this to both contexts
        // and remove the logic in this.adjustLabelSize
        rules.push({
          selector:'#'+this.id + ' .label',
          attribute: 'margin-top',
          value: -Math.round(this.model.get('computations.labelHeight') / 2) + 'px'
        });
      }

      if (this.model.exists('style.fontSize')) {
        rules.push({
          selector:'#'+this.id,
          attribute: 'font-size',
          value: this.model.get('style.fontSize')+'px'
        });
        rules.push({
          selector:'#'+this.id,
          attribute: 'line-height',
          value: Math.round(this.model.get('style.fontSize')*1.2)+'px'
        });
      }

      if (this.model.exists('style.fontWeight')) {
        rules.push({
          selector:'#'+this.id,
          attribute: 'font-weight',
          value: this.model.get('style.fontWeight')
        });
      }

      if (this.model.exists('style.fontFamily')) {
        rules.push({
          selector:'#'+this.id,
          attribute: 'font-family',
          value: this.model.get('style.fontFamily')
        });
      }

      rules.push({
        selector:'#'+this.id,
        attribute: 'text-align',
        value: 'center'
      });

      rules.push({
        selector:'#'+this.id,
        attribute: 'background-repeat',
        value: 'no-repeat'
      });

      if (this.page.context === lp.pom.context.EDIT) {
        options.removeAttrs = removeAttributes;
        this.applyStylesToDom(rules, options);
      } else {
        this.applyPageStyles(rules, options);
      }
    },

    generateGradientByState: function(state) {
      var methodName = 'generate' + state.capitalize() + 'State';
      return this.model[methodName](this.model.get('style.' + state + '.auto'));
    },

    getCSSRulesForState: function(state, createPseudoClass) {
      var addRules = [];
      var removeAttributes = [];

      var selector = '#'+this.id+
          (state !== this.states[0] && createPseudoClass ? ':'+state : '');

      var fillType = this.getCurrentFillType();
      var autoGenerate =  this.model.exists('style.'+state+'.auto') ?
          this.model.get('style.'+state+'.auto') : false;

      var autoGradient = this.model.exists('style.autoGradient') ?
          this.model.get('style.autoGradient') : false;


      var stateStyle = autoGenerate ?
          this.generateGradientByState(state) :
          this.model.get('style.'+state);

      if (this.page.context === lp.pom.context.EDIT) {
        this.clearCSSRulesForFill(selector);
      }

      var rules;

      switch (fillType) {
        case 'solid':
          rules = this.createCSSRulesForSolidFill(stateStyle, selector, state);
          break;
        case 'gradient':
          if (autoGradient) {
            rules = this.createCSSRulesForAutoGradientFill(stateStyle, selector, state);
          } else {
            rules = this.createCSSRulesForCustomGradientFill(stateStyle, selector, state);
          }
          break;
        case 'image':
          rules = this.createCSSRulesForImageFill(stateStyle, selector);
      }

      addRules = addRules.concat(rules.addRules);
      removeAttributes = addRules.concat(rules.removeAttributes);

      if(stateStyle.color) {
        addRules.push({selector:selector, attribute: 'color', value: '#'+stateStyle.color});
      }
      if(stateStyle.border) {
        if(stateStyle.border.style) {
          addRules.push({selector:selector, attribute: 'border-style', value: stateStyle.border.style});
          addRules.push({selector:selector, attribute: 'border-width', value: stateStyle.border.width+'px'});
          addRules.push({selector:selector, attribute: 'border-color', value: '#'+stateStyle.border.color});
        }
      }

      return {addRules: addRules, removeAttributes: removeAttributes};
    },

    clearCSSRulesForFill: function(selector) {
      this.page.style.removeCSSRule({selector:selector, attribute: 'background-color'});
      this.page.style.removeCSSRule({selector:selector, attribute: 'background-image'});
      this.page.style.removeCSSRule({selector:selector, attribute: 'text-shadow'});
      this.page.style.removeCSSRule({selector:selector, attribute: 'box-shadow'});

      this.gradientSpecifiers.each( function(s) {
        // BUG??? s is never used here
        this.page.style.removeCSSRule({selector:selector, attribute:'background'});
      }, this);
    },

    createCSSRulesForSolidFill: function(style, selector, state) {
      var addRules = [];
      var removeAttributes = [];


      style.backgroundColor = typeof style.backgroundColor === 'undefined' ?
        'transparent' : style.backgroundColor;
      style.opacity = typeof style.opacity === 'undefined' ? 100 : style.opacity;

      if(style.backgroundColor === 'transparent') {
        style.opacity = 0;
        if(this.model.exists('style.up.savedBackgroundColor')) {
          style.backgroundColor = this.model.get('style.up.savedBackgroundColor');
        }
      }

      var color = jui.ColorMath.hexToRgb(jui.ColorMath.normalizeHexValue(style.backgroundColor ));
      var opacity = style.opacity / 100;
      color.push(opacity);

      addRules.push({
        selector:selector,
        attribute: 'background',
        value: 'rgba('+color.join()+')'
      });

      addRules.push({
        selector:selector,
        attribute: '-pie-background',
        value: 'rgba('+color.join()+')'
      });

      if (this.model.hasHighlight() && style.backgroundColor !== 'transparent') {
        if (state === 'active') {
          addRules.push({
            selector: selector,
            attribute:'box-shadow',
            value: this.model.calculateShadow(style.backgroundColor)
          });
        } else {
          addRules.push({
            selector: selector,
            attribute:'box-shadow',
            value: this.model.calculateHighlight(style.gradient.from, style.gradient.to)
          });
        }
      } else {
        removeAttributes.push('box-shadow');
      }

      if (this.model.hasTextShadow() && style.backgroundColor !== 'transparent') {
        addRules.push({
          attribute:'text-shadow',
          value: this.model.calculateTextShadow(style.backgroundColor)
        });
      } else {
        removeAttributes.push('text-shadow');
      }

      return {addRules: addRules, removeAttributes: removeAttributes};
    },

    createCSSRulesForAutoGradientFill: function(style, selector, state) {
      var addRules = [];
      var removeAttributes = [];

      var g = {
        to: style.reverseGradient ? style.gradient.from : style.gradient.to,
        from: style.reverseGradient ? style.gradient.to : style.gradient.from
      };

      var baseColor = style.backgroundColor;

      if (baseColor === 'transparent') {
        baseColor = style.savedBackgroundColor;
      }

      addRules.push({selector:selector, attribute: 'background-color', value: '#'+style.backgroundColor});

      this.gradientSpecifiers.each( function(s) {
        addRules.push({selector:selector, attribute:'background', value: s+'(#'+g.from+', #'+g.to+')'});
      });

      if (this.model.hasHighlight() && style.backgroundColor !== 'transparent') {
        if (state === 'active') {
          addRules.push({
            selector: selector,
            attribute:'box-shadow',
            value: this.model.calculateShadow(style.backgroundColor)
          });
        } else {
          addRules.push({
            selector: selector,
            attribute:'box-shadow',
            value: this.model.calculateHighlight(g.from, g.to)
          });
        }
      } else {
        removeAttributes.push('box-shadow');
      }

      if (this.model.hasTextShadow() && style.backgroundColor !== 'transparent') {
        addRules.push({attribute:'text-shadow', value: this.model.calculateTextShadow(style.backgroundColor)});
      } else {
        removeAttributes.push('text-shadow');
      }

      if (this.page.context === lp.pom.context.PUBLISH) {
        addRules.push({selector:selector ,attribute:'-pie-background', value: 'linear-gradient(#'+g.from+', #'+g.to+')'});
        addRules.push({attribute:'behavior', value:  'url(/PIE.htc)'});
      }

      return {addRules: addRules, removeAttributes: removeAttributes};
    },

    createCSSRulesForCustomGradientFill: function(style, selector, state) {
      var addRules = [];
      var removeAttributes = [];
      var g = style.gradient;
      var from = style.reverseGradient ? g.to : g.from;
      var to = style.reverseGradient ? g.from : g.to;

      addRules.push({selector:selector, attribute: 'background-color', value: '#'+style.backgroundColor});

      this.gradientSpecifiers.each( function(s) {
        addRules.push({selector:selector, attribute:'background', value: s+'(#'+from+', #'+to+')'});
      });

      if (this.model.hasHighlight()) {
        if (state === 'active') {
          addRules.push({
            attribute:'box-shadow',
            selector: selector,
            value: this.model.calculateShadow(from, to)
          });
        } else {
          addRules.push({
            attribute:'box-shadow',
            selector: selector,
            value: this.model.calculateHighlight(from, to)
          });
        }
      } else {
        removeAttributes.push('box-shadow');
      }

      if (this.model.hasTextShadow() && style.backgroundColor !== 'transparent') {
        addRules.push({attribute:'text-shadow', value: this.model.calculateTextShadow(g.from)});
      } else {
        removeAttributes.push('text-shadow');
      }

      if (this.page.context === lp.pom.context.PUBLISH) {
        addRules.push({selector:selector ,attribute:'-pie-background', value: 'linear-gradient(#'+g.from+', #'+g.to+')'});
        addRules.push({attribute:'behavior', value:  'url(/PIE.htc)'});
      }

      return {addRules: addRules, removeAttributes: removeAttributes};
    },

    createCSSRulesForImageFill: function(style, selector) {
      var addRules = [];
      var removeAttributes = ['text-shadow', 'box-shadow'];

      var bg = style.backgroundColor;
      var bgColor = !bg || bg === 'transparent' ? 'transparent' : bg;
      var opacity = typeof style.opacity === 'undefined' ? 100 : style.opacity;

      if(bgColor === 'transparent') {
        opacity = 0;
        if(this.model.exists('style.up.savedBackgroundColor')) {
          bgColor = this.model.get('style.up.savedBackgroundColor').replace('#', '');
        }
      }

      var color = jui.ColorMath.hexToRgb(jui.ColorMath.normalizeHexValue(bgColor));
      opacity = opacity/100;
      color.push(opacity);

      addRules.push({
        selector:selector,
        attribute: 'background-color',
        value: 'rgba('+color.join()+')'
      });

      addRules.push({
        selector:selector,
        attribute: '-pie-background-color',
        value: 'rgba('+color.join()+')'
      });

      if(style.image) {
        var imageSrc = this.isPublishContext() && style.image.unique_url ?
          style.image.unique_url : style.image.content_url;

        addRules.push({
          selector: selector,
          attribute: 'background-image',
          value: 'url('+this.page.decorateImageSrc(imageSrc)+')'
        });
      }

      return {addRules: addRules, removeAttributes: removeAttributes};
    },

    modelChanged: function($super, e) {
      /* jshint maxcomplexity:15 */
      var details = $super(e);
      if (details.accessor.startsWith('style')) {
        this.updateCSSRules();
      }

      if (this.states.map(function(s) {return 'style.'+s+'.border';}).indexOf(details.accessor) > -1) {
        this.updateDimensions();
        this.updateOffset();
        this.adjustLabelSize();
      }

      switch (details.accessor) {
        case 'content.label':
          if (details.value) {
            this.label.update(details.value);
          } else {
            this.label.update('');
          }
          this.adjustLabelSize();
          break;

        case 'style.fontSize':
        case 'style.fontWeight':
        case 'style.fontFamily':
        case 'geometry.size':
          this.adjustLabelSize();
          break;
        case 'action.url':
        case 'action.type':
        case 'action.asset':
          this.fireEvent('goalChanged', this);
          break;
      }
    },

    getCurrentFillType: function() {
      return this.model.getCurrentFillType();
    },

    isLinkEditable: function() {
      if (this.model.exists('constraints.linkEditable')) {
        return this.model.get('constraints.linkEditable');
      }
      return true;
    },

    appendURLParams: function() {
      if(this.model.exists('action.passparams')) {
        return this.model.get('action.passparams');
      }
      return false;
    },

    canHaveGoals: function() {
      return true;
    },

    getGoals: function() {
      var goals = [];
      if (this.model.get('action.type') === 'url' || this.model.get('action.type') === '') {
        if (this.model.exists('action.url') &&
            this.model.get('action.url') !== '' &&
            !this.model.get('action.url').startsWith('#') &&
            !this.model.get('action.url').startsWith('mailto:')) {
          goals = [{type:'link', url:this.model.get('action.url') }];
        }
      } else if (this.model.get('action.type') === 'download') {
        if (this.model.exists('action.asset')) {
          var asset = this.model.get('action.asset');
          if (!asset.protected_download) {
            var asset_url = this.isPublishContext() ? asset.unique_url : asset.content_url;
            goals = [{type:'file', url:asset_url.split('/').last().split('?')[0]}];
          }
        }
      }
      return goals;
    },

    destroy: function() {
      this.page.style.removeCSSRules('#'+this.id);
      this.page.removeListener('elementRemoved', this);
      this.page.style.updatePageStyles();
    },

    dblclick: function(e){
      Event.stop(e);
      this.getModule().getPropertiesPanel().controls.buttonLabel.focusAndSelect();
      this.fireEvent('dblclick', e);
    }
  }
);

lp.module.button.ButtonElement.elementDefaults = {
  name: 'Button',
  content: {
    label: 'Button'
  },
  style: {
    fontSize:'16',
    fontWeight:'bold',
    fontFamily:'arial',
    textShadow:true,
    highlight:true,
    fillType: 'gradient',
    autoGradient: true,
    up: {
      backgroundColor:'f7941d',
      opacity: 100,
      gradient:{
        type:'custom-gradient',
        from:'f7941d',
        to:'d75305'
      },
      color:'fff',
      border:{
        style:'solid',
        color:'333333',
        width:1
      }
    },
    hover: {
      auto:true
    },
    active: {
      auto:true
    }
  },
  geometry: {
    position:"absolute",
    offset:{top:0 ,left:0},
    size:{width:150,height:42},
    borderLocation:'inside',
    cornerRadius:5
  },
  action: {
    type: 'url',
    url: ''
  }
};
