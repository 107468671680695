//  require <jui>
jui.FormMultiCheckboxInput = Class.create( jui.Component, jui.ControlModel, {
  options: function($super,options){
    return $super($H({
      attributes:{className:'form-elm multi-checkbox-input'},
      label:'Choose'
    }).merge(options));

  },

  initialize:function( $super, options ){
    this.checkboxes = {};
    $super( options );

    if ( this.options.change ) {
      this.addListener( 'change', this.options.change );
    }
  },

  installUI: function( $super ) {
    $super();
    this.insert( new Element('label').update(this.options.label));

    this.options.checkboxes.each(function(c) {
      this.insert( new Element('label', {className:'mini'}).update(c.label));
      this.checkboxes[c.id] = this.insert(new Element('input', {type:'checkbox', checked:c.checked}));
      this.checkboxes[c.id].observe('click', this.change.bind(this));
    }, this);
  },

  setValue: function(values) {
    $H(values).each(function(v) {
      this.checkboxes[v.key].checked = v.value;
    }, this);
  },

  getValue: function() {
    var value = {};
    $H(this.checkboxes).keys().each(function(k) {
      value[k] = this.checkboxes[k].checked;
    },this);
    return value;
  },

  change: function() {
    this.fireEvent('change', this);
  }
});
